import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  createLibraryMethod,
  findAllLibraryMethodsAfterUpdate,
  findAllLibraryMethodsAtInit,
  findAllLibraryMethodsError,
  findAllLibraryMethodsSuccess, renameLibraryMethod,
  settingsArchiveLibraryMethod,
  settingsRestoreLibraryMethod
} from '../actions/library-method.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LibraryMethodService } from '../../services/library-method.service';
import { LibraryMethod } from '../../models/library-method';

@Injectable()
export class LibraryMethodEffects {
  constructor(
    private actions$: Actions,
    private libraryMethodService: LibraryMethodService
  ) {
  }

  findAllLibraryMethods$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllLibraryMethodsAtInit.type,
      findAllLibraryMethodsAfterUpdate.type,
    ),
    mergeMap(() => this.libraryMethodService.findAll()),
    map(libraryMethods => findAllLibraryMethodsSuccess({libraryMethods})),
    catchError(error => of(findAllLibraryMethodsError({message: error.message})))
    ) }
  );

  createLibraryMethod$ = createEffect(() => { return this.actions$.pipe(
    ofType(createLibraryMethod.type),
    mergeMap((action: ReturnType<typeof createLibraryMethod>) =>
      this.libraryMethodService.create({name: action.name} as LibraryMethod)
    ),
    map(() => findAllLibraryMethodsAfterUpdate())) }
  );

  renameLibraryMethod$ = createEffect(() => { return this.actions$.pipe(
    ofType(renameLibraryMethod.type),
    mergeMap((action: ReturnType<typeof renameLibraryMethod>) =>
      this.libraryMethodService.rename(action.libraryMethod)
    ),
    map(() => findAllLibraryMethodsAfterUpdate())) }
  );

  settingsArchiveLibraryMethod$ = createEffect(() => { return this.actions$.pipe(
    ofType(settingsArchiveLibraryMethod.type),
    mergeMap((action: ReturnType<typeof settingsArchiveLibraryMethod>) =>
      this.libraryMethodService.archive(action.libraryMethod)
    ),
    map(() => findAllLibraryMethodsAfterUpdate())) }
  );

  settingsRestoreLibraryMethod$ = createEffect(() => { return this.actions$.pipe(
    ofType(settingsRestoreLibraryMethod.type),
    mergeMap((action: ReturnType<typeof settingsRestoreLibraryMethod>) =>
      this.libraryMethodService.restore(action.libraryMethod)
    ),
    map(() => findAllLibraryMethodsAfterUpdate())) }
  );
}
