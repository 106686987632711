import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { FlowCell } from '../../../labware/models/flow-cell';
import { setLoadingType } from '../../../labware/store/flow-cells';

@Component({
  selector: 'nemo-edit-flow-cell-loading-type',
  template: `
    <label id="flow-cell-loading-type-label">Loading type:</label>
    <mat-radio-group
      (change)="typeChanged($event.value)"
      [value]="flowCell.sequencerConfiguration?.loadingType?.id"
      aria-labelledby="flow-cell-loading-type-label">
      <mat-radio-button class="flow-cell-loading-type-radio-button"
                        *ngFor="let loadingType of flowCell?.sequencerModel?.loadingTypes"
                        [value]="loadingType.id">
        {{loadingType.name}}
      </mat-radio-button>
    </mat-radio-group>
  `,
  styles: [`
    :host, mat-radio-group {
      display: flex;
      flex-direction: column;
    }
  `]
})
export class EditFlowCellLoadingTypeComponent {

  @Input() flowCell: FlowCell;

  constructor(
    private store: Store<AppState>
  ) {
  }

  typeChanged(newTypeId: number) {
    const loadingType = this.flowCell.sequencerModel.loadingTypes.find((type) => type.id === newTypeId);
    this.store.dispatch(setLoadingType({loadingType}));
  }

}
