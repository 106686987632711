import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Question } from '../../models/form';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'nemo-form-question',
  template: `
    <div class="container"
         *ngIf="enabled"
         [ngSwitch]="question.type"
         [formGroup]="sectionFormGroup"
         [ngClass]="{'form-invalid':invalid}"
    >
      <div *ngSwitchCase="'TEXT'">
        <nemo-form-question-text
          [sectionFormGroup]="sectionFormGroup"
          [question]="question"
        ></nemo-form-question-text>
      </div>
      <div *ngSwitchCase="'SEQUENCE'">
        <nemo-form-question-sequence
          [sectionFormGroup]="sectionFormGroup"
          [question]="question"
        ></nemo-form-question-sequence>
      </div>
      <div *ngSwitchCase="'MULTICHOICE'">
        <nemo-form-question-multichoice
          [sectionFormGroup]="sectionFormGroup"
          [question]="question"
        >
        </nemo-form-question-multichoice>
      </div>
      <div *ngSwitchCase="'SAMPLENAME_OPTION'">
        <nemo-form-question-samplename-option
          [sectionFormGroup]="sectionFormGroup"
          [question]="question"
        >
        </nemo-form-question-samplename-option>
      </div>
      <div *ngSwitchCase="'SAMPLENAME_LIST'">
        <nemo-form-question-samplename-list
          [sectionFormGroup]="sectionFormGroup"
          [question]="question"
        >
        </nemo-form-question-samplename-list>
      </div>
      <div *ngSwitchCase="'CHECKBOX'">
        <h4>{{question.title}}</h4>
        <div
          *ngIf="question.description"
          class="description"
        >{{question.description}}</div>
        <mat-checkbox
          [formControlName]="question.key"
        >{{question.help || 'Yes'}}</mat-checkbox>
      </div>
      <div *ngSwitchDefault>
        ERROR: cannot render question of type {{question.type}}
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: column;
               margin-bottom: 5px;
               padding-left: 3px;
               border-left: transparent 3px solid;
             }

             div.container.form-invalid {
               border-left: orangered 3px solid;
               padding-left: 3px;
             }

             mat-form-field {
               width: 100%;
             }
           `]
})

export class FormQuestionComponent implements OnInit, OnDestroy {
  @Input()
  question: Question;

  @Input()
  sectionFormGroup: UntypedFormGroup;

  invalid: boolean;
  enabled: boolean;
  statusChangeSubscription: Subscription;

  ngOnInit(): void {
    this.statusChangeSubscription = this.sectionFormGroup.get(this.question.key).statusChanges
      .subscribe(() => {
        this.invalid = this.sectionFormGroup.get(this.question.key).invalid;
        this.enabled = this.sectionFormGroup.get(this.question.key).enabled;
      });
    this.invalid = !this.sectionFormGroup.get(this.question.key).valid;
    this.enabled = this.sectionFormGroup.get(this.question.key).enabled;
  }

  ngOnDestroy(): void {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
  }

}
