import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Study } from '../models/Study';

export const STUDY_BASE_URI = environment.apiUrl + '/studies';

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  constructor(private readonly http: HttpClient) {
  }

  findAll(): Observable<Study[]> {
    return this.http.get<Study[]>(STUDY_BASE_URI);
  }

  create(study: Study): Observable<Study> {
    return this.http.post<Study>(STUDY_BASE_URI, study);
  }

  archive(study: Study): Observable<void> {
    const uri = `${STUDY_BASE_URI}/${study.id}`;
    return this.http.delete<void>(uri);
  }

  restore(study: Study): Observable<void> {
    const uri = `${STUDY_BASE_URI}/${study.id}/restore`;
    return this.http.post<void>(uri, undefined);
  }
}
