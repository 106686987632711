export enum ColumnType {
  PLAIN = 'PLAIN',
  HTML = 'HTML',
  BUTTONS = 'BUTTONS'
}

export abstract class FlexibleColumnDef<T> {
  protected constructor(
    public readonly type: ColumnType,
    public readonly key: string,
    public readonly header: string,
    public readonly isPresent: (x: T) => boolean,
    public readonly format: (x: T) => string,
    public readonly style: { [key: string]: any } = {}
  ) {
  }
}

export class FlexiblePlainColumnDef<T> extends FlexibleColumnDef<T> {
  constructor(
    key: string,
    header: string,
    isPresent: (x: T) => boolean,
    format: (x: T) => string,
    style: { [key: string]: any } = {},
    public readonly route: (x: T) => string = null,
    public readonly clazz: string[] = []
  ) {
    super(
      ColumnType.PLAIN,
      key,
      header,
      isPresent,
      format,
      style
    );
  }
}

export class FlexibleHtmlColumnDef<T> extends FlexibleColumnDef<T> {
  constructor(
    key: string,
    header: string,
    isPresent: (x: T) => boolean,
    format: (x: T) => string,
    style: { [key: string]: any } = {},
    public readonly route: (x: T) => string = null,
    public readonly clazz: string[] = []
  ) {
    super(
      ColumnType.HTML,
      key,
      header,
      isPresent,
      format,
      style
    );
  }
}

export class FlexibleButtonsColumnDef<T> extends FlexibleColumnDef<T> {
  constructor(
    key: string,
    header: string,
    isPresent: (x: T) => boolean,
    format: (x: T) => string,
    style: { [key: string]: any } = {},
    public readonly buttons: FlexibleButtonDef<T>[]
  ) {
    super(
      ColumnType.BUTTONS,
      key,
      header,
      isPresent,
      format,
      style
    );
  }
}

export class FlexibleButtonDef<T> {
  constructor(
    public readonly title: (x: T) => string,
    public readonly color: (x: T) => string = () => 'primary',
    public readonly isPresent: (x: T) => boolean,
    public readonly action: (x: T) => void = null,
    public readonly isIcon = false
  ) {
  }
}

export function someFlexibleTablePresentRow<T>(values: T[], flexibleColumnDef: FlexibleColumnDef<T>): boolean {
  return values.some((x: T) => flexibleColumnDef.isPresent(x));
}
