import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: any, args: string): any {
    if (value && args) {
      const re = new RegExp(args.replace(/\*|\\/g, '').split(' ').join('|'), 'gi');
      return value.replace(re, (x) => '<b>' + x + '</b>');
    }
    return value;
  }
}
