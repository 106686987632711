import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { renameLibraryMethod } from '../../../store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LibraryMethod } from '../../../models/library-method';


@Component({
  selector: 'nemo-library-method-edit-dialog',
  template: `
    <div class="container"
         [formGroup]="formGroup"
    >
      <h1>Edit library method name</h1>
      <span class="current">Current name: {{data['name']}}</span>
      <mat-form-field>
        <mat-label>New name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Displayed name, such as 'Illumina Nextera DNA Flex Library Prep (PN 20018705)'"
        >
      </mat-form-field>
      <div>
        <button mat-button (click)="close()">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!formGroup.valid"
          (click)="submit()"
        >Update
        </button>
      </div>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: column;
    }

    mat-form-field {
      width: 40em;
      margin-right: 1em;
    }

    .current {
      margin: 0 0 1em 1em;
    }
  `]
})
export class LibraryMethodEditDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  libraryMethodId: number;

  constructor(
    private readonly store: Store<AppState>,
    private dialogRef: MatDialogRef<LibraryMethodEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
  }

  ngOnInit() {
    this.libraryMethodId = this.data['id'];
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.data['name'], [
        Validators.required
      ])
    });

  }

  submit() {
    this.store.dispatch(renameLibraryMethod({
      libraryMethod: {
        id: this.libraryMethodId,
        name: this.formGroup.get('name').value
      } as LibraryMethod
    }));
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
