import { Component } from '@angular/core';

@Component({
  selector: 'nemo-flow-cell-pool-viewer-readonly',
  template: `
    <div class="container">
      <nemo-flow-cell-container [isReadonly]="true"></nemo-flow-cell-container>

      <div class="pool-list">
        <nemo-pool-list
          [draggingEnabled]="false"
          [searchBar]="false"
          [displayedColumns]="['icon', 'accessionCode', 'size', 'requests']"
        ></nemo-pool-list>
      </div>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: row;
      min-height: 500px;
    }
  `]
})
export class FlowCellPoolViewerReadonlyComponent {
}
