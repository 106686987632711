import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FlexibleHtmlColumnDef } from '../../model/flexible-columm-def';

@Component({
  selector: 'nemo-flexible-html-cell',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div [ngClass]="columnDefinition.clazz"
         [innerHTML]="field['display'] | trustHtml"
    ></div>
  `,
  styles: [`
  `]
})
export class FlexibleHtmlCellComponent<T> {
  @Input()
  columnDefinition: FlexibleHtmlColumnDef<T>;

  @Input()
  field: {};

}
