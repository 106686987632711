import { AfterViewChecked, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CommentEntity } from '../../models/commentText';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'nemo-comment-message-list', template: `
        <div
                class="container"
                #container
        >
            <div *ngFor="let comment of comments">
                <nemo-comment
                        [fromMe]="comment.userId === myUserId"
                        [comment]="comment"
                        [userDictionary]="userDictionary"
                >
                </nemo-comment>
            </div>
        </div>
  `, styles: [`
                  :host {
                      height: 100%;
                      overflow: auto;
                      position: relative;
                  }

                  div.container {
                      min-height: min-content;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-end;
                  }
              `]
})
export class CommentMessageListComponent implements AfterViewChecked, OnChanges {
  @Input() comments: CommentEntity[];

  @Input() myUserId: string;

  @Input() userDictionary: { [key: string]: User };

  @ViewChild('container', {static: true}) elContainer: ElementRef;

  hasRendered = false;

  constructor(private hostEl: ElementRef) {
  }

  ngAfterViewChecked(): void {
    if (!this.hasRendered) {
      this.scrollToBottom();
      this.hasRendered = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasRendered = false;
  }

  scrollToBottom() {
    const containerHeight = this.elContainer.nativeElement.offsetHeight;
    const hostHeight = this.hostEl.nativeElement.offsetHeight;
    const paddingTopStyle = this.elContainer.nativeElement.style.paddingTop;
    const paddingTop = paddingTopStyle ? parseInt(paddingTopStyle.replace('px', ''), 10) : 0;

    if ((containerHeight - paddingTop) < hostHeight) {
      this.elContainer.nativeElement.style.paddingTop = '' + (hostHeight - containerHeight + paddingTop) + 'px';
    } else {
      this.elContainer.nativeElement.style.paddingTop = paddingTopStyle;
    }
    this.hostEl.nativeElement.scrollTop = this.hostEl.nativeElement.scrollHeight;
  }

}
