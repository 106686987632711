import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { dtoToPlateQC } from './qc-plate.mapper';
import { PlateQC } from '../models/plate-qc-measures';
import { PlateQCDto } from '../dto/qc.dto';

export const QC_BASE_URI = environment.apiUrl + '/qc-measures';

@Injectable({providedIn: 'root'})
export class QcTasksService {

  constructor(private http: HttpClient) {
  }

  dismissPlateQC(accessionCode: string): Observable<PlateQC> {
    return this.http.delete<PlateQCDto>(`${QC_BASE_URI}/${accessionCode}`)
      .pipe(
        map(dtoToPlateQC)
      );
  }

  recoverPlateQC(accessionCode: string): Observable<PlateQC> {
    return this.http.post<PlateQCDto>(`${QC_BASE_URI}/recover/${accessionCode}`, {})
      .pipe(
        map(dtoToPlateQC)
      );
  }

  reimportPlateQC(accessionCode: string): Observable<PlateQC> {
    return this.http.post<PlateQCDto>(`${QC_BASE_URI}/reimport/${accessionCode}`, {})
      .pipe(
        map(dtoToPlateQC)
      );
  }
}
