import { AfterContentChecked, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenomicFormRequestService } from '../../../services/genomic-form-request.service';

@Component({
  selector: 'nemo-request-submission-form-phix',
  template: `
    <div [formGroup]="formGroup">
      <h4>PhiX</h4>
      <span class="description">For low diversity libraries, please request a PhiX spike or consult a member of the Genomics lab.</span>
      <mat-form-field appearance="fill"
                      floatLabel="auto"
                      class="numeric-field phix">
        <mat-label [class.strike-through]="phiXPercentage.value === '0'">PhiX</mat-label>
        <input matInput #phiXPercentage
               formControlName="phiXPercentage"
               type="number"
               class="right-align"
               [min]="phiXPercentageMin"
               [max]="phiXPercentageMax"
               value="0"/>
        <span matTextSuffix>%</span>
        <mat-hint>Between 0 and 100%</mat-hint>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../request-submission-form-info/request-submission-form-info.component.scss'],
  styles: [`
    mat-form-field.phix {
      width: 176px;
    }

    ::ng-deep.mat-mdc-form-field-flex {
      align-items: center;
    }

    .strike-through {
      text-decoration: line-through;
    }

    input.right-align {
      text-align: right;
    }

    mat-form-field.phix > span {
      display: block;
      padding-top: 24px;
      padding-bottom: 8px;
    }
  `]
})
export class RequestSubmissionFormPhixComponent implements AfterContentChecked {
  @Input() formGroup: FormGroup;

  phiXPercentageMin: number;
  phiXPercentageMax: number;

  constructor(
    readonly genomicsRequestService: GenomicFormRequestService,
    private cdref: ChangeDetectorRef
  ) {
    this.phiXPercentageMin = genomicsRequestService.phiXPercentageMin;
    this.phiXPercentageMax = genomicsRequestService.phiXPercentageMax;
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
}
