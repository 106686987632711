import { Component, OnInit } from '@angular/core';
import { HasGlobalContextUserRole } from '../../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { AppState } from '../../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NemoVersion } from 'src/constants/version';

@Component({
  selector: 'nemo-header-nemo-icon',
  template: `
    <div routerLink="/" class="container clickable">
      <div>
        <img src="assets/nemo-logo.png" class="logo" alt="nemo-logo"/>
      </div>
      <div class="title">
        <div class="header-title">Nemo v{{version$}}</div>
        <div class="role">{{(currentLab$|async)?.name}} {{currentUserRole$ | async}}</div>
      </div>
    </div>
  `,
  styles: [`
             :host {
               width: 300px;
             }

             div.container {
               display: flex;
               flex-direction: row;
               overflow: hidden;
             }

             img.logo {
               height: 48px;
               border: 0;
               vertical-align: middle;
             }

             div.title {
               font-family: Roboto, sans-serif;
               font-style: normal;
               padding-left: 8px;
               vertical-align: middle;
             }

             .header-title {
               display: inline-block;
               font-size: 22px;
               line-height: 24px;
               position: relative;
             }
           `]
})
export class HeaderNemoIconComponent extends HasGlobalContextUserRole {

  constructor(
    private readonly store: Store<AppState>
  ) {
    super(store);
  }

}
