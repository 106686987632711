import { BioMaterialPlateMapping } from '../../../labware/models/bio-material-plate-mapping';
import { Pool, PoolSampleMix, PoolSource } from '../../../bio/models/pool';
import { PlateCoordinates } from '../../../labware/models/plate-coordinates';
import * as _ from 'lodash';

export class EchoPooling {
  constructor(
    public readonly requestsAccessionCode: string[],
    public readonly poolAccessionCode: string,
    public readonly sourceCoordinates: PlateCoordinates,
    public readonly concentration: number | undefined, // ng/uL
    public readonly concentrationPercentage: number | undefined, // %total
    public readonly nmolePerL: number | undefined,
    public transferVolume: number,
    public targetCoordinates: PlateCoordinates
  ) {
  }
}

export function toEchoPooling(sourceMappings: BioMaterialPlateMapping<Pool>[]): EchoPooling[] {
  return sourceMappings.map(mapping =>
    new EchoPooling(
      mapping.biomaterial.listRequestAccessionCode(),
      mapping.biomaterial.accessionCode,
      mapping.plateCoordinates,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    )
  );
}

export function toEchoPoolingProcessed(bioMaterialMapping: BioMaterialPlateMapping<PoolSampleMix>[]): EchoPooling[] {
  return _.flatMap(bioMaterialMapping.map(mapping =>
    mapping.biomaterial.poolSources.map(poolSource => {
        return new EchoPooling(
          mapping.biomaterial.listRequestAccessionCode(),
          poolSource.accessionCode,
          new PlateCoordinates(poolSource.coordinates.row, poolSource.coordinates.column),
          undefined,
          undefined,
          undefined,
          poolSource.transferVolume,
          mapping.plateCoordinates,
        );
      }
    )
  ));
}

export function sortEchoPoolingBySource(echoPooling: EchoPooling[]): EchoPooling[] {
  if (echoPooling === undefined || echoPooling.length === 0) {
    return;
  }
  echoPooling.sort((a, b) => {
    if (a.sourceCoordinates.column > b.sourceCoordinates.column) {
      return 1;
    } else if (a.sourceCoordinates.column === b.sourceCoordinates.column) {
      return (a.sourceCoordinates.row >= b.sourceCoordinates.row) ? 1 : -1;
    } else {
      return -1;
    }
  }
  );
}

export function toExportForEcho(bioMaterialMapping: BioMaterialPlateMapping<PoolSampleMix>[]): object[] {
  const echoPoolings = toEchoPoolingProcessed(bioMaterialMapping);
  sortEchoPoolingBySource(echoPoolings);
  return echoPoolings.map(echoPooling => {
    return {
      'Source Well': echoPooling.sourceCoordinates.toString(),
      'Destination Well': echoPooling.targetCoordinates.toString(),
      'Transfer volume': echoPooling.transferVolume,
    };
  });
}

export function mergeToEchoPooling(echoPoolings: EchoPooling[], targetMappings: BioMaterialPlateMapping<Pool>[]): EchoPooling[] {
  return echoPoolings.map(echoPooling => {
    const bioMaterialMapping = findMatchingPoolSampleMixMapping(echoPooling, targetMappings);
    if (bioMaterialMapping) {
      const poolSource = findSourcePool(echoPooling, bioMaterialMapping);
      echoPooling.transferVolume = poolSource.transferVolume;
      echoPooling.targetCoordinates = bioMaterialMapping.plateCoordinates;
    }
    return echoPooling;
  });
}

export function findMatchingPoolSampleMixMapping(
  echoPooling: EchoPooling,
  targetMappings: BioMaterialPlateMapping<Pool>[]
): BioMaterialPlateMapping<PoolSampleMix> {
  return targetMappings.find(targetMapping =>
    targetMapping.biomaterial instanceof PoolSampleMix &&
    targetMapping.biomaterial.poolSources
      .find(poolSource => poolSource.accessionCode === echoPooling.poolAccessionCode) !== undefined
  ) as BioMaterialPlateMapping<PoolSampleMix>;
}

export function findSourcePool(
  echoPooling: EchoPooling,
  bioMaterialMapping: BioMaterialPlateMapping<PoolSampleMix>
): PoolSource {
  return bioMaterialMapping.biomaterial.poolSources.find(sourcePool =>
    sourcePool.accessionCode === echoPooling.poolAccessionCode
  );
}
