import { AbstractControl, AsyncValidator, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestService } from '../../../services/request.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { validateRequestSampleSuccess } from '../../../store';
import { SampleFileValidation } from '../../../models/plate-file-validation.model';
import { RequestSampleContainersDto } from '../../../models/genomics-request.dto';
import { dtoToSampleContainers } from '../../../services/request.mapper';
import { insertErrorInvalidRequestParsing } from '../../../../shared/nerrors/store';
import { NError } from '../../../../shared/nerrors/models/error.model';

@Injectable({
  providedIn: 'root',
})
export class SampleFileValidator implements AsyncValidator {

  constructor(
    private requestService: RequestService,
    private readonly store: Store<AppState>,
  ) {
  }

  validate = (group: UntypedFormGroup): Observable<ValidationErrors | null> => {
    const indexHeader = group.get('sampleIndexHeader').value;
    const fileControl = group.get('sampleFile');
    if (fileControl.value === '') {
      return of(null);
    }
    return this.requestService.validateSampleFile(fileControl.value, indexHeader).pipe(
      map((sampleFileValidation: SampleFileValidation) => {
        const containerDto = {
          plates: [sampleFileValidation.plate].filter((p) => p),
          pools: sampleFileValidation.pools || [],
          tubes: sampleFileValidation.tubes || []
        } as RequestSampleContainersDto;

        const containers = dtoToSampleContainers(containerDto);
        const metadataHeaders = sampleFileValidation.metadataHeaders;

        this.store.dispatch(validateRequestSampleSuccess({containers, metadataHeaders}));
        return null;
      }),
      catchError((e) => {
        const message = `Processing error: ${e.message}`;
        console.error(e);
        this.store.dispatch(insertErrorInvalidRequestParsing({error: new NError(message, e)}));
        return of({message: e.message});
      }),
    );
  }
}
