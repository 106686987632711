import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { LibraryMethod } from '../models/library-method';
import { HttpClient } from '@angular/common/http';

export const LIBRARY_METHOD_BASE_URI = environment.apiUrl + '/library-methods';

@Injectable({
  providedIn: 'root'
})
export class LibraryMethodService {

  constructor(private readonly http: HttpClient) {
  }

  findAll(): Observable<LibraryMethod[]> {
    return this.http.get<LibraryMethod[]>(LIBRARY_METHOD_BASE_URI);
  }

  create(libraryMethod: LibraryMethod): Observable<LibraryMethod> {
    const uri = LIBRARY_METHOD_BASE_URI;
    return this.http.post<LibraryMethod>(uri, libraryMethod);
  }

  rename(libraryMethod: LibraryMethod): Observable<LibraryMethod> {
    const uri = `${LIBRARY_METHOD_BASE_URI}/${libraryMethod.id}`;
    return this.http.put<LibraryMethod>(uri, libraryMethod);
  }

  archive(libraryMethod: LibraryMethod): Observable<void> {
    const uri = `${LIBRARY_METHOD_BASE_URI}/${libraryMethod.id}`;
    return this.http.delete<void>(uri);
  }

  restore(libraryMethod: LibraryMethod): Observable<void> {
    const uri = `${LIBRARY_METHOD_BASE_URI}/${libraryMethod.id}/restore`;
    return this.http.post<void>(uri, undefined);
  }
}
