import { Component, Input } from '@angular/core';

@Component({
  selector: 'nemo-request-accession-code-list',
  template: `
    <span *ngFor="let accessionCode of accessionCodes; last as isLast" routerLink="/request/{{accessionCode}}">
      <span class="clickable" [style.fontWeight]="!withinTable?'bold':'none'">
        {{accessionCode | accessionCode}}
      </span>
      <span *ngIf="isLast && !withinTable">.</span>
      <span *ngIf="!isLast">, </span>
    </span>
  `
})
export class RequestAccessionCodeListComponent {
  @Input() accessionCodes: string[];
  @Input() withinTable = false;
}
