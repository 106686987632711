import { SimpleIndexKit } from '../models/indexKit';
import {
  archiveIndexKitError,
  findAllIndexKitModels,
  findAllIndexKitModelsError,
  findAllIndexKitModelsSuccess,
  findAllIndexKits,
  findAllIndexKitsSuccess,
  findIndexKitsError,
  findIndexKitsFromIA,
  findIndexKitsSuccess,
  IndexKitAction,
  indexKitError,
  unarchiveIndexKitError
} from './index-kit.actions';
import { SimpleIndexKitModel } from '../models/indexKitModel';
import { PageInfo } from '../../shared/models/page.model';

export default interface IndexKitState {
  indexKits: SimpleIndexKit[];
  pageInfo: PageInfo | null;
  indexKitModels: SimpleIndexKitModel[];
  pending: boolean;
  message: string;
}

export const initialState: IndexKitState = {
  indexKits: [],
  pageInfo: null,
  indexKitModels: [],
  pending: false,
  message: '',
};

export function reducer(state = initialState, action: IndexKitAction) {
  switch (action.type) {
    case findAllIndexKits.type:
      return {...state, pending: true, message: ''};

    case findAllIndexKitsSuccess.type:
      return {...state, pending: false, pageInfo: action.pageInfo, indexKits: action.indexKits};

    case findIndexKitsFromIA.type:
      return {...state, pending: true, indexKits: [], message: ''};

    case findIndexKitsSuccess.type:
      return {...state, pending: false, indexKits: action.indexKits};

    case findIndexKitsError.type:
      return {...state, pending: false, message: action.message};

    case findAllIndexKitModels.type:
      return {...state, pending: true, indexKitModels: [], message: ''};

    case findAllIndexKitModelsSuccess.type:
      return {...state, pending: false, indexKitModels: action.indexKitModels};

    case findAllIndexKitModelsError.type:
      return {...state, pending: false, message: action.message};

    case indexKitError.type:
      return {...state, pending: false, message: action.message};

    case archiveIndexKitError.type:
    case unarchiveIndexKitError.type:
      return {...state, pending: false, message: action.message};

    default:
      return state;
  }
}
