import { PoolSampleMix, SimplePoolWithRequest } from '../../bio/models/pool';

/**
 * Flow cell with lane assigned
 * This shall be a immutable object, as it resides in the store
 */
export class FlowCellMapping {
  constructor(
    readonly simplePoolWithRequest: SimplePoolWithRequest,
    readonly laneNumber: number,
    readonly laneOccupancy: number
  ) {
  }

  setLaneOccupancy(laneOccupancy: number): FlowCellMapping {
    return new FlowCellMapping(
      this.simplePoolWithRequest, this.laneNumber, laneOccupancy
    );
  }
}

export class PoolWithLaneNumber {
  pool: PoolSampleMix;
  laneNumber: number;
}

export class SamplePoolParents {
  sample: string;
  pool: string;
}

export class IndexConflictWithLaneNumber {
  constructor(lane, conflicts) {
    this.laneNumber = lane;
    this.conflictingTerminiListMap = conflicts;
  }

  laneNumber: number;
  conflictingTerminiListMap: { [key: string]: SamplePoolParents[] };
}
