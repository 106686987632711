import { Component, OnInit } from '@angular/core';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { poolsSearch } from '../../../store/actions';

interface TimeRange {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'nemo-pool-search',
  template: `
    <form [formGroup]="form">
      <mat-form-field class="input">
        <input matInput placeholder="Search pools" formControlName="search">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-form-field class="input">
        <mat-select formControlName="timeRange">
          <mat-option *ngFor="let timeRange of timeRanges"  [value]="timeRange.value">
            {{timeRange.viewValue}}
          </mat-option>
        </mat-select>
        <mat-hint align="start">Only load pools created within this time range</mat-hint>
      </mat-form-field>
      <mat-checkbox class="final-checkbox" color="primary" formControlName="combined">Final Pools Only</mat-checkbox>
    </form>
  `,
  styles: [`
    :host {
      display: flex;
    }
    form {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 40px;
      justify-content: flex-end;
      margin-right: 40px;

      .input {
        width: 400px;
      }

      .final-checkbox {
        margin-top: 1em;
      }
    }
  `]
})
export class PoolSearchComponent implements OnInit {

  form: UntypedFormGroup;

  timeRanges: TimeRange[] = [
    {value: '31', viewValue: 'last 1 month'},
    {value: '62', viewValue: 'last 2 months'},
    {value: '93', viewValue: 'last 3 months'},
    {value: '183', viewValue: 'last 6 months'},
    {value: '366', viewValue: 'last 1 year'},
    {value: '9999', viewValue: 'all time'},
  ];

  constructor(private readonly fb: UntypedFormBuilder, private store: Store<AppState>) {
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.onSearchValueChange();
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      timeRange: this.fb.control('93'),
      search: this.fb.control(''),
      combined: this.fb.control(false)
    });
  }

  onSearchValueChange() {
    this.form.valueChanges.pipe(
      debounce(() => timer(400))
    ).subscribe(x => {
      const prefixListToSkip = ['pol', 'req', 'pol_', 'req_'];
      const query  = this.form.get('search').value;
      // skip to search those common prefixes
      if ((query.length > 0 && query.length < 3) || prefixListToSkip.includes(query.toLowerCase())) {
        console.log('Skip short query: [' + query + ']');
      } else {
        this.store.dispatch(poolsSearch({
          timeRange: this.form.get('timeRange').value,
            query: this.form.get('search').value,
            combined: this.form.get('combined').value
         }));
      }
    });
  }
}
