import { Component, Input } from '@angular/core';
import { CommentText } from '../../models/commentText';

@Component({
  selector: 'nemo-comment-text-content',
  template: `
      <div>
          {{comment.content}}
      </div>
  `,
  styles: []
})
export class CommentTextContentComponent {
  @Input()
  comment: CommentText;

}
