import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';

import { AppState } from '../../store/app.reducers';
import { countPoolsFromTodoPage } from '../../bio/store/actions';
import { countPlatesFromTodoPage } from '../../labware/store/plates/actions/plate-list.action';
import { TaskAvailable } from '../../tasks/models/task.model';
import { countPlateQCFromTodoPage } from '../../qc/store/actions/qc-api.actions';

@Component({
  selector: 'nemo-todo-page',
  template: `
    <nemo-todo-counters-boxes></nemo-todo-counters-boxes>
    <router-outlet></router-outlet>
  `,
  styles: [`
    nemo-todo-counters-boxes {
      padding-bottom: 10px;
      border-bottom: 1px solid #dadce0;
    }
  `]
})
export class TodoPageComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Nemo todo list');
    this.store.dispatch(countPoolsFromTodoPage());
    this.store.dispatch(countPlatesFromTodoPage({availableTask: TaskAvailable.INDEX_ASSIGNMENT}));
    this.store.dispatch(countPlatesFromTodoPage({availableTask: TaskAvailable.ECHO_POOLING_FROM}));
    this.store.dispatch(countPlateQCFromTodoPage({availableTask: TaskAvailable.QC_PLATE_ASSIGN_TO_FILE}));
  }
}
