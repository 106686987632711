import { Routes } from '@angular/router';
import { FlowCellTablePageComponent } from './components/flow-cell-table-page/flow-cell-table-page.component';
import { PlateListPageComponent } from './components/plate-table-page/plate-list-page.component';
import { FlowCellPageComponent } from './components/flow-cell-page/flow-cell-page.component';
import { PlatePageComponent } from './components/plate-page/plate-page.component';
import { AuthGuard } from '../core/auth/auth.guard';

export const labwareRoutes: Routes = [
  {path: 'flow-cells', component: FlowCellTablePageComponent, canActivate: [AuthGuard]},
  {path: 'flow-cell/:flowCellId', component: FlowCellPageComponent, canActivate: [AuthGuard]},
  {path: 'plates', component: PlateListPageComponent, canActivate: [AuthGuard]},
  {path: 'plate/:plateAccessionCode', component: PlatePageComponent, canActivate: [AuthGuard]}
];
