import { createSelector } from '@ngrx/store';
import ErrorState from './error.reducer';
import { AppState } from '../../../store/app.reducers';

export const getErrorState = (state: AppState) => state.error;

export const getErrors = createSelector(getErrorState, (state: ErrorState) => state.errors);
export const getActiveError = createSelector(getErrorState, (state: ErrorState) => state.active);


