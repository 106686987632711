import { Component, Input } from '@angular/core';
import { NRequest } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-page-title',
  template: `
    <div *ngIf="request" class="title-line">
      <div class="accession-code">{{ request.accessionCode | accessionCode }}</div>
      <hr/>
    </div>
  `, styles: [`
    div.title-line .accession-code {
      flex-grow: 0.3;
      font-family: monospace;
      font-size: 2rem;
      font-weight: bold;
    }
  `]
})
export class RequestPageTitleComponent {
  @Input() request: NRequest;
}
