import { User } from '../../user/models/user.model';
import { Plate96 } from '../../labware/models/plate-96';
import { Pool, PoolSampleMix } from '../../bio/models/pool';
import { Sample } from '../../bio/models/sample';
import { Department } from '../../company/models/Department';
import { Project } from '../../company/models/Project';
import { TaskType } from '../../tasks/models/task.model';
import { Study } from '../../company/models/Study';


/**
 * A request is associated with samples that can be either in plates or pools
 * and even multiple of them
 */
export class RequestSampleContainers {
  constructor(
    public readonly plates: Array<Plate96<Pool>>,
    public readonly pools: Array<PoolSampleMix>,
    public readonly tubes: Array<PoolSampleMix>,
  ) {
  }
}

/**
 * An actual Request with info + sample plate
 * We name it NRequest, not to conflict with the basic typescript Request
 */
export class NRequest extends RequestSampleContainers {
  constructor(
    public readonly accessionCode: string,
    public readonly requester: User,
    public readonly assignedTo: User[],
    public readonly croId: string | null,
    public readonly createdAt: Date,
    public readonly submittedAt: Date,
    public readonly libraryMethod: string,
    public readonly phiXPercentage: number,
    public readonly title: string,
    public readonly description: string,
    public readonly department: Department,
    public readonly project: Project,
    public readonly study: Study,
    plates: Array<Plate96<Pool>>,
    pools: Array<PoolSampleMix>,
    tubes: Array<PoolSampleMix>,
    public readonly numberOfSamples: number,
    public readonly stages: RequestStage[],
    public readonly archived: boolean,
    public readonly collaborator: string,
    public readonly fastqDir: string,
    public readonly samples: Array<Sample>,
  ) {
    super(plates, pools, tubes);
  }
}

export class NRequestSummary {
  constructor(
    public readonly accessionCode: string,
    public readonly requester: User,
    public readonly assignedTo: User[],
    public readonly croId: string | null,
    public readonly createdAt: Date,
    public readonly submittedAt: Date,
    public readonly libraryMethod: string,
    public readonly phiXPercentage: number,
    public readonly title: string,
    public readonly description: string,
    public readonly seqParameters: string,
    public readonly department: Department,
    public readonly project: Project,
    public readonly study: Study,
    public readonly numberOfSamples: number,
    public readonly totalRequestedNor: string,
    public readonly stages: RequestStage[],
    public readonly archived: boolean,
    public readonly tasks: TaskType[],
    public readonly collaborator: string,
    public readonly fastqDir: string
  ) {
  }
}

export class RequestStage {
  constructor(
    public readonly key: string,
    public readonly name: string,
    public readonly completedAt?: Date,
    public readonly completedBy?: User,
  ) {
  }
}

/**
 * Simple, as it does not carry the sample containers
 */
// todo remove me?
export class SimpleRequest {
  constructor(
    public accessionCode: string,
    public requesterId: string,
    public readonly assignedToIds: string[],
    public title: string,
    public description: string,
    public createdAt: Date,
    public libraryMethod: string | null,
    public readonly phiXPercentage: number
  ) {
  }
}
