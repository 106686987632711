import { FragmentAnalyzerQCMeasureDto } from '../dto/qc.dto';
import { FragmentAnalyzerQCMeasure } from '../models/fragment-analyzer-qc-measure';
import { plateCoordinatesFromDto } from '../../labware/services/plate-coordinates.mapper';
import { dtoToQCInstrumentFile } from './qc-file.mapper';

export function dtoToFragmentAnalyzerQCMeasure(dto: FragmentAnalyzerQCMeasureDto) {
  return new FragmentAnalyzerQCMeasure(
    plateCoordinatesFromDto(dto.plateCoordinates),
    dto.data,
    Boolean(dto.plotInstrumentFile) ? dtoToQCInstrumentFile(dto.plotInstrumentFile) : null);
}
