import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getGenomicsContainersPreview, getGenomicsMessage, submitNewRequest } from '../../../store';
import { AppState } from '../../../../store/app.reducers';
import { NError } from '../../../../shared/nerrors/models/error.model';
import { FileTransferService } from '../../../../core/services/file-transfer.service';
import { SampleFileValidator } from './sample-file-validator.service';
import { CreateRequestDTO } from '../../../models/genomics-request.dto';
import { RequestSampleContainers } from '../../../models/genomics-request';
import { insertErrorInvalidSubmission } from '../../../../shared/nerrors/store';
import { LibraryMethod } from '../../../../sequencing/models/library-method';
import { selectActiveLibraryMethods } from '../../../../sequencing/store';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoComponent } from '../../../../shared/shared/components/dialog-info/dialog-info.component';
import { SampleFileIndexHeaderEnum } from '../../../models/plate-file-validation.model';

@Component({
  selector: 'nemo-request-submission-form',
  templateUrl: './request-submission-form.component.html',
  styleUrls: ['./request-submission-form.component.scss']
})
export class RequestSubmissionFormComponent implements OnInit {

  formGroup: UntypedFormGroup;
  sampleGroup: UntypedFormGroup;
  requestMessage$: Observable<string>;
  submittedContainersPreview$: Observable<RequestSampleContainers>;
  selectedLibraryMethod: any;
  isPristine = true;
  is10x = false;
  param10xMin = 0;
  param10xMax = 999;

  libraryMethods$: Observable<LibraryMethod[]>;
  filteredLibraryMethodOptions$: Observable<LibraryMethod[]>;

  indexHeaderValidation = [
    'I am submitting unindexed samples',
    'My samples are i7 indexed',
    'My samples are i7 and i5 indexed',
    'I am submitting my indexes by the Index ID name'
  ];
  indexHeaderValidationIndices = [
    SampleFileIndexHeaderEnum.NO_INDEX,
    SampleFileIndexHeaderEnum.I7_ONLY,
    SampleFileIndexHeaderEnum.I7_AND_I5,
    SampleFileIndexHeaderEnum.INDEX_ID,
  ];

  //  The full list is aligned with Calico Benchiling
  //  see https://calico.benchling.com/calico_labs/registries/src_pVSWjAKd/dropdowns/sfs_S8JOHi7C
  //  or the exported version from
  //  https://console.cloud.google.com/bigquery?ws=!1m5!1m4!4m3!1scalico-benchling-bq-01!2sbenchling_01_transformed!3sdropdowns
  organismOptions: string[] = [
    'Mus musculus',
    'Homo sapiens',
    'Saccharomyces cerevisiae',
    'Caenorhabditis elegans',
    'Cricetinae',
    'Drosophila',
    'Heterocephalus glaber',
    'Cyno macaque',
    'Spodoptera frugiperda',
    'Rattus norvegicus',
    'Cricetulus griseus',
    'Aotus trivirgatus',
    'Bos taurus',
    'Callithrix argentata',
    'Callosciurus notatus',
    'Camelus dromedarius',
    'Canis lupus familiaris',
    'Carassius auratus',
    'Cavia porcellus',
    'Chlorocebus aethiops',
    'Chlorocebus pygerythrus',
    'Chlorocebus sabaeus',
    'Clarias batrachus',
    'Clupea pallasii',
    'Coturnix coturnix japonica',
    'Cricetulus migratorius',
    'Crossarchus obscurus',
    'Daboia russelii',
    'Danio rerio',
    'Dasypus novemcinctus',
    'Dermacentor parumapertus',
    'Dermacentor variabilis',
    'Didelphis virginiana',
    'Epinephelus coioides',
    'Equus burchelli',
    'Felis catus',
    'Gallus gallus',
    'Gekko gecko',
    'Ictalurus punctatus',
    'Iguana iguana',
    'Ixodes scapularis',
    'Lepomis macrochirus',
    'Lithobates catesbeiana',
    'Lithobates pipiens',
    'Macaca mulatta',
    'Marmota monax',
    'Meleagris gallopavo',
    'Meriones unguiculatus',
    'Mesocricetus auratus',
    'Morone chrysops',
    'Muntiacus muntjak',
    'Mus terricolor',
    'Mustela putorius furo',
    'Neovison vison',
    'Odocoileus hemionus',
    'Oncorhynchus mykiss',
    'Opodiphthera eucalypti',
    'Oreochromis mossambicus',
    'Oryctolagus cuniculus',
    'Oryx dammah',
    'Ovis aries',
    'Papio hamadryas',
    'Pecari tajacu',
    'Pimephales promelas',
    'Poeciliopsis lucida',
    'Potorous tridactylus',
    'Rhinella marina',
    'Saimiri boliviensis boliviensis',
    'Saimiri boliviensis peruviensis',
    'Saimiri sciureus',
    'Salmo salar',
    'Sus scrofa',
    'Sylvilagus floridanus',
    'Takifugu niphobles',
    'Takifugu rubripes',
    'Terrapene carolina',
    'Toxorhynchites amboinensis',
    'Trichoplusia ni',
    'Urocyon cinereoargenteus',
    'Xenopus laevis',
    'Rattus Rattus',
    'Fukomys damarensis',
    'Acipenser baerii',
    'Rattus',
    'Cricetulus griseus (Chinese hamster)',
  ];

  sampleTypeOptions: string[] = [
    'Total RNA',
    'Cells in RNAlater',
    'Cell pellet',
    'Fresh frozen tissue'
  ];

  constructor(
    private readonly store: Store<AppState>,
    private readonly fileTransferService: FileTransferService,
    private readonly sampleFileValidator: SampleFileValidator,
    private dialog: MatDialog
  ) {
    this.buildForm();
    // Dynamically enable/disable validators
    this.formGroup.get('isRestrictedAccessCtl')?.valueChanges.subscribe((isChecked) => {
      const needAccessEmailsCtl = this.formGroup.get('needAccessEmailsCtl');
      if (isChecked) {
        needAccessEmailsCtl?.setValidators([Validators.required, this.emailListValidator]);
      } else {
        needAccessEmailsCtl?.clearValidators();
      }
      needAccessEmailsCtl?.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this.requestMessage$ = this.store.select((getGenomicsMessage));
    this.submittedContainersPreview$ = this.store.select((getGenomicsContainersPreview));
    this.libraryMethods$ = this.store.select((selectActiveLibraryMethods));
    this.filteredLibraryMethodOptions$ = this.libraryMethods$
  }

  filterLibraryMethodOptions(query: string) {
    if (query) {
      this.filteredLibraryMethodOptions$ = this.libraryMethods$.pipe(
        map(libraryMethods => libraryMethods.filter(libraryMethod =>
            libraryMethod.name.toLowerCase().includes(query.toLowerCase())
        ))
      );
    } else {
	this.filteredLibraryMethodOptions$ = this.libraryMethods$; // Reset to all options if query is empty
    }
  }

  buildForm() {
    this.sampleGroup = new UntypedFormGroup({
        sampleIndexHeader: new UntypedFormControl(null,
          [Validators.required]
        ),
        sampleFile: new UntypedFormControl('',
          [Validators.required],
        )
      }, [], [this.sampleFileValidator.validate]
    );
    this.formGroup = new UntypedFormGroup({
      libraryMethod: new UntypedFormControl('',
        [Validators.required],
      ),
      phiXPercentage: new UntypedFormControl(0,
        [Validators.required, Validators.min(0.0), Validators.max(100.0)]),
      title: new UntypedFormControl('',
        [Validators.required, Validators.maxLength(80)]
      ),
      description: new UntypedFormControl('',
        [Validators.maxLength(5000)]
      ),
      departmentCode: new UntypedFormControl(null,
        [Validators.required],
      ),
      projectCode: new UntypedFormControl(null,
        [Validators.required],
      ),
      studyId: new UntypedFormControl(null,
        []
      ),
      refTransCtl: new UntypedFormControl(null,
        []
      ),
      organismCtl: new UntypedFormControl(null,
        []
      ),
      sampleTypeCtl: new UntypedFormControl(null,
	[]
      ),
      isRestrictedAccessCtl: new UntypedFormControl(false),
      needAccessEmailsCtl: new UntypedFormControl(null,
	[this.emailListValidator]
      ),
      sample: this.sampleGroup,
      param10xRead1: new UntypedFormControl('150',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xIndex1: new UntypedFormControl('8',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xIndex2: new UntypedFormControl('8',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xRead2: new UntypedFormControl('150',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      collaborator: new UntypedFormControl(null,
        [Validators.required],
      )
    });
  }

  emailListValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) return null; // Allow empty input when not required

    const emails = value.split(',').map((email) => email.trim());
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const invalidEmails = emails.filter((email) => !emailPattern.test(email));
    if (invalidEmails.length > 0) {
      return { invalidEmails: invalidEmails.join(', ') }; // Return invalid emails as an error
    }
    return null; // Validation passed
  }

  get sampleFile() {
    return this.sampleGroup.get('sampleFile');
  }

  get sampleFileErrors() {
    return this.sampleFile.value && this.sampleFile.errors;
  }

  get sampleFileClassName(): string {
    if (!this.isPristine && this.sampleFile.errors) {
      return 'plate-file-error';
    } else if (this.isPristine) {
      return 'plate-file-pristine';
    } else {
      return '';
    }
  }

  async onSampleFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const b64 = await this.fileTransferService.readFileAsDataURL(file);
      this.sampleGroup.patchValue({
        sampleFile: {
          file: b64,
          filename: file.name,
        },
      });
    }
  }

  onSubmit() {
    // nemo-41: 10x params requires at least 1 parameter is not 0
    // form can enforce all the parameters are between 0 to 999
    // nemo-62 all input will require demux param now.
    const paramSum = this.formGroup.value.param10xRead1
      + this.formGroup.value.param10xRead2
      + this.formGroup.value.param10xIndex1
      + this.formGroup.value.param10xIndex2;
    if (paramSum === 0) {
      const errorPayload = { error: new NError('At least one of the 10x read length parameters must be >0') };
      this.store.dispatch(insertErrorInvalidSubmission(errorPayload));
      return;
    }

    this.isPristine = false;
    if (this.formGroup.valid) {
      this.validSubmission();
    } else {
      this.invalidSubmission();
    }
  }

  validSubmission() {
    let descriptionExtended = this.formGroup.value.description;
    const isRestrictedAccessCtlVal = this.formGroup.value.isRestrictedAccessCtl ? 1 : 0;
    // append parameters for all samples

    let extendStr = '\nSequencing/Demux Parameters:\n'
      + 'Read1: ' + this.formGroup.value.param10xRead1 + '\n'
      + 'Index1: ' + this.formGroup.value.param10xIndex1 + '\n'
      + 'Index2: ' + this.formGroup.value.param10xIndex2 + '\n'
      + 'Read2: ' + this.formGroup.value.param10xRead2 + '\n'
      + 'RestricedAccess: ' + isRestrictedAccessCtlVal

    if (this.formGroup.value.isRestrictedAccessCtl && this.formGroup.value.needAccessEmailsCtl){
	extendStr += "\n" + "needAccessEmails: " + this.formGroup.value.needAccessEmailsCtl
    }

    const libraryMethod = this.formGroup.value.libraryMethod.name;
    const refTransCtlVal = this.formGroup.value.refTransCtl;
    const organismCtlVal = this.formGroup.value.organismCtl;
    const sampleTypeCtlVal = this.formGroup.value.sampleTypeCtl;


    if (this.requireReferenceTranscriptomeSetting(libraryMethod) && !refTransCtlVal) {
      throw new Error("\"Reference transcriptome for cell ranger\" field is required.");
    }

    if (this.requireOrganismSetting(libraryMethod) && !organismCtlVal) {
      throw new Error("\"Organism\" field is required.");
    }

    if (this.requireSampleTypeSetting(libraryMethod) && !sampleTypeCtlVal) {
      throw new Error("\"SampleType\" field is required.");
    }

   if (organismCtlVal == "Homo sapiens" && sampleTypeCtlVal == "Fresh frozen tissue" && isRestrictedAccessCtlVal == 0){
       throw new Error("\"Restricted Access\" field is required to be checked for Homo sapiens with Fresh frozen tissue, "  +
	   "Please confirm whether these samples are from Donor/Patient. Email calico-ngs@calicolabs.com for help");
   }


    if (refTransCtlVal) {
      descriptionExtended += extendStr + '\n' + 'RefTransPath: ' + refTransCtlVal;
    } else if (organismCtlVal && sampleTypeCtlVal){
      descriptionExtended += extendStr + '\n' + 'Organism: ' + organismCtlVal + '\n' + 'SampleType: ' + sampleTypeCtlVal;
    } else {
      descriptionExtended += extendStr;
    }

    const collaborator = this.formGroup.value.collaborator;

    const request: CreateRequestDTO = {
      libraryMethod: this.formGroup.value.libraryMethod.name,
      phiXPercentage: this.formGroup.value.phiXPercentage,
      title: this.formGroup.value.title,
      description: descriptionExtended,
      sampleFile: this.sampleGroup.value.sampleFile,
      sampleIndexHeader: this.sampleGroup.value.sampleIndexHeader,
      departmentCode: this.formGroup.value.departmentCode,
      projectCode: this.formGroup.value.projectCode,
      studyId: this.formGroup.value.studyId,
      collaborator: collaborator
    };
    // console.log("dispatch request: " + JSON.stringify(request));
    this.store.dispatch(submitNewRequest({ request }));
    this.dialog.open(DialogInfoComponent, {
      width: '500px',
      data: {
        message:
          'Submitting your request…'
      }
    });
  }

  invalidSubmission() {
    const controls = this.formGroup.controls;
    const messages = Object.keys(this.formGroup.controls)
      .map(field => ({ name: field, status: controls[field].status, errors: controls[field].errors }))
      .filter((e) => e.status !== 'VALID')
      .map((e) => e.name);
    const errorPayload = { error: new NError('Error with fields: ' + messages, {}) };
    this.store.dispatch(insertErrorInvalidSubmission(errorPayload));
  }

  onLibraryMethodChange(event) {
    this.selectedLibraryMethod = event.value;
    const refTransCtl = this.formGroup.get('refTransCtl');
    if (refTransCtl) {
      refTransCtl.setValue(null);
    }

    const organismCtl = this.formGroup.get('organismCtl');
    if (organismCtl) {
      organismCtl.setValue(null);
    }

    const sampleTypeCtl = this.formGroup.get('sampleTypeCtl');
    if (sampleTypeCtl) {
      sampleTypeCtl.setValue(null);
    }
  }

  requireReferenceTranscriptomeSetting(libraryMethod: string): boolean {
    const requiredMethods = [
      '10x Genomics Chromium Next GEM Single Cell GEX Multiome',
      '10x Genomics Chromium Next GEM Single Cell GEX 3\' v3.1',
      '10x Genomics v3 Chromium Single Cell 3\' Single Index (PN 1000175)',
      '10x Genomics GEM-X 3\' GEX'
    ];
    return requiredMethods.includes(libraryMethod);
  }

  isForBulkRnaSeq(libraryMethod: string): boolean {
    const requiredMethods = [
      'Low input - RNAseq',
      'mRNA PolyA Enrichment - RNAseq (NEBNext Ultra II Directional RNA Library Prep Kit, PN E7760L)',
      'rRNA Depletion - RNAseq (NEBNext rRNA Depletion Kit v2 Human/Mouse/Rat, PN E7400X)'
    ];

    return requiredMethods.includes(libraryMethod);
  }

  requireOrganismSetting(libraryMethod: string): boolean {
    return this.isForBulkRnaSeq(libraryMethod)
  }

  requireSampleTypeSetting(libraryMethod: string): boolean {
    return this.isForBulkRnaSeq(libraryMethod)
  }
}
