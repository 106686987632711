import {
  RequestDetailedDTO,
  RequestSampleContainersDto, RequestStageDto,
  RequestSummaryDTO,
  SamplePlateDto
} from '../models/genomics-request.dto';
import { NRequest, NRequestSummary, RequestSampleContainers, RequestStage } from '../models/genomics-request';
import { sampleDtoToBioMaterialWithMapping, samplePlateDtoToPlate96 } from '../../labware/services/plate.mapper';
import { Plate96 } from '../../labware/models/plate-96';
import PlateDimensions from '../../labware/models/plate-dimensions';
import { Pool } from '../../bio/models/pool';
import { Sample } from '../../bio/models/sample';
import { dtoToUser } from '../../user/services/user.mapper';
import { poolSampleMixDtoToPool } from '../../bio/services/bio.mapper';
import { SampleDto } from '../../bio/dto/bio.dto'

function getSeqParameters(description: string): string {
  // Regular expression to match the lines with sequencing/demux parameters
  const regex = /(Read1: \d+\nIndex1: \d+\nIndex2: \d+\nRead2: \d+)/g;

  // Find all matches
  const matches = description.match(regex);

  if (!matches) {
    return 'No sequencing parameters found.';
  }
  const lastMatch = matches[matches.length - 1];
  return lastMatch;
}

function formatSeqParameters(input: string): string {
  // Regular expression to match numbers that come after a colon and optional spaces
  const regex = /:\s*(\d+)/g;

  let matches;
  const numbers = [];

  // Use exec in a loop to find all matches along with capturing groups
  while ((matches = regex.exec(input)) !== null) {
    numbers.push(matches[1]); // matches[1] contains the first capturing group (\d+)
  }

  if (numbers.length === 0) {
    return 'No numbers found.';
  }

  // Join the captured numbers with commas
  const formattedString = numbers.join(',');

  return formattedString;
}

export function requestSummaryDTOToRequestSummary(dto: RequestSummaryDTO): NRequestSummary {
  return new NRequestSummary(
    dto.accessionCode,
    dtoToUser(dto.requester),
    dto.assignedTo.map(dtoToUser),
    dto.croId,
    new Date(dto.createdAt),
    dto.submittedAt ? (new Date(dto.submittedAt)) : null,
    dto.libraryMethod,
    dto.phiXPercentage,
    dto.title,
    dto.description,
    formatSeqParameters(getSeqParameters(dto.description)),
    dto.department,
    dto.project,
    dto.study,
    dto.numberOfSamples,
    dto.totalRequestedNor,
    dto.stages.map(requestStageDtoToRequestStage),
    dto.archived,
    dto.tasks,
    dto.collaborator,
    dto.fastqDir
  );
}

function sampleDtoToSample(dto: SampleDto): Sample {
  return new Sample(
    dto.name,
    dto.id,
    undefined,
    dto.accessionCode,
    dto.requestAccessionCode,
    dto.metadata
  );
}


export function requestDetailedDTOToRequest(dto: RequestDetailedDTO): NRequest {
  return new NRequest(
    dto.accessionCode,
    dtoToUser(dto.requester),
    dto.assignedTo.map(dtoToUser),
    dto.croId,
    new Date(dto.createdAt),
    dto.submittedAt ? (new Date(dto.submittedAt)) : null,
    dto.libraryMethod,
    dto.phiXPercentage,
    dto.title,
    dto.description,
    dto.department,
    dto.project,
    dto.study,
    dto.plates.map(samplePlateDtoToPlate96),
    dto.pools.map(poolSampleMixDtoToPool),
    dto.tubes.map(poolSampleMixDtoToPool),
    null,
    dto.stages.map(requestStageDtoToRequestStage),
    dto.archived,
    dto.collaborator,
    dto.fastqDir,
    dto.samples.map(sampleDtoToSample),
  );
}

export function requestStageDtoToRequestStage(dto: RequestStageDto): RequestStage {
  return new RequestStage(
    dto.name,
    dto.name.charAt(0).toUpperCase() + dto.name.slice(1).toLowerCase(),
    dto.completedAt,
    dto.completedBy
  );
}

export function dtoToSamplePlate(dto: SamplePlateDto): Plate96<Pool> {
  return new Plate96<Pool>(dto.id,
    dto.accessionCode,
    dto.title,
    dto.availableForTasks,
    new PlateDimensions(dto.dimensions.numberOfRows, dto.dimensions.numberOfColumns)
  )
    .setBioMaterialMappings(dto.layout.map(sampleDtoToBioMaterialWithMapping));
}

export function dtoToSampleContainers(dto: RequestSampleContainersDto): RequestSampleContainers {
  return new RequestSampleContainers(
    dto.plates.map(dtoToSamplePlate),
    dto.pools.map(poolSampleMixDtoToPool),
    dto.tubes.map(poolSampleMixDtoToPool)
  );
}
