import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import QcState from './qc.reducer';

export const getQCState = (state: AppState) => state.qc;

export const getAllQCPlateSummaries =
  createSelector(getQCState, (state: QcState) => state.all);

export const getQCTodoCounts =
  createSelector(getQCState, (state: QcState) => state.qcTodoCounts);

export const getQcResultPageInfo = createSelector(getQCState, (state: QcState) => state.pageInfo);

export const getCurrentPlateQC = createSelector(
  getQCState,
  (state: QcState) => state.currentPlateQC
);
