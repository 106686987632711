import { Component } from '@angular/core';

@Component({
  selector: 'nemo-header-bar',
  template: `
      <nemo-header-nemo-icon></nemo-header-nemo-icon>
      <nemo-search-bar></nemo-search-bar>
      <nemo-login></nemo-login>
  `,
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent {
}
