import { createSelector } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { FormRequestState } from './form-request.reducer';

export const getFormRequestState = (state: AppState) => state.formRequest;

export const selectCurrentFormRequest = createSelector(
  getFormRequestState,
  (state: FormRequestState) => state.current
);

export const selectCurrentFormRequestCompletedStageKeys = createSelector(
  selectCurrentFormRequest,
  (formRequest): string[] | null => formRequest?.stages
    .filter(stage => Boolean(stage.completedBy))
    .map(stage => stage.key)
);

export const selectFormRequests = createSelector(
  getFormRequestState,
  (state: FormRequestState) => state.all
);

export const selectIsFormRequestStorePending = createSelector(
  getFormRequestState,
  (state: FormRequestState) => state.pending
);
