import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { getCurrentPlateQC } from '../../../../qc/store/qc.selectors';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';
import { findPlateQCByAccessionCodeFromInstrumentDirectoryPage } from '../../../../qc/store/actions/qc-api.actions';
import { findAllPlatesSnippets } from '../../../../labware/store/plates/actions/plate-list.action';
import { TaskAvailable } from '../../../models/task.model';
import { selectAllPlateSnippetsForTask } from '../../../../labware/store/plates';
import { map } from 'rxjs/operators';
import { InvalidPlateQCInstrumentDirectoryError } from '../../models/invalid-plate-qc-instrument-directory-error';
import {
  getTasksPlateQCAssignmentError,
  getTasksPlateQCAssignmentPlateAccessionCode
} from '../../../store/task.selectors';
import { PlateSnippet } from '../../../../labware/dto/labware.dto';
import { PlateQC } from '../../../../qc/models/plate-qc-measures';

@Component({
  selector: 'nemo-plate-qc-assigner-page',
  template: `
    <nemo-plate-qc-assigner-page-ui
      [platesSnippets$]="platesSnippets$"
      [plateQC]="plateQC$ | async"
      [invalidAssignment]="invalidAssignment$ |async"
      [selectedPlateAccessionCode]="selectedPlateAccessionCode$ | async"
    ></nemo-plate-qc-assigner-page-ui>
  `,
  styles: []
})
export class PlateQcAssignerPageComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;

  plateQC$: Observable<PlateQC>;
  platesSnippets$: Observable<PlateSnippet[]>;

  selectedPlateAccessionCode$: Observable<string>;

  invalidAssignment$: Observable<InvalidPlateQCInstrumentDirectoryError>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.plateQC$ = this.store.select((getCurrentPlateQC));
    this.invalidAssignment$ = this.store.select((getTasksPlateQCAssignmentError));
    this.platesSnippets$ = this.store.select(selectAllPlateSnippetsForTask(TaskAvailable.QC_PLATE_ASSIGN_TO_FILE)).pipe(
      
      map(snippets => snippets || [])
    );

    this.store.dispatch(findAllPlatesSnippets());

    this.selectedPlateAccessionCode$ = this.store.
      select((getTasksPlateQCAssignmentPlateAccessionCode),
    );
    this.paramSubscription = this.route.params.subscribe(params => {
      const accessionCode = params['instrumentDirectoryAccessionCode'];
      this.titleService.setTitle(`Nemo - assign plate for  directory ${formatAccessionCode(accessionCode)}`);
      this.store.dispatch(findPlateQCByAccessionCodeFromInstrumentDirectoryPage({accessionCode}));
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }
}
