import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { selectCompanyAllLabs } from '../../../store';
import { Lab } from '../../../models/Lab';
import { archiveLab, restoreLab } from '../../../store/lab.actions';
import { MatDialog } from '@angular/material/dialog';
import { LabsEditDialogComponent } from './labs-edit.component';

@Component({
  selector: 'nemo-labs-list',
  template: `
    <div class="container">
      <table
        mat-table
        [dataSource]="labs$ | async"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="alias">
          <th mat-header-cell *matHeaderCellDef>Alias</th>
          <td mat-cell *matCellDef="let element"> {{element.alias}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="homePage">
          <th mat-header-cell *matHeaderCellDef>Home page</th>
          <td mat-cell *matCellDef="let element"> {{element.homePage}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td
            mat-cell
            *matCellDef="let element"
          >

            <button
              mat-mini-fab
              *ngIf="!element.archived"
              color="primary"
              (click)="edit(element)"
              matTooltip="edit the lab"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="!element.archived"
              color="warn"
              (click)="archive(element)"
              matTooltip="Archive the lab"
            >
              <mat-icon>archive</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="element.archived"
              color="primary"
              (click)="restore(element)"
              matTooltip="Restore the lab"
            >
              <mat-icon>unarchive</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{archived:row.archived}"
        ></tr>
      </table>
    </div>
  `,
  styles: [`
   table {
     width: 100%;
   }

   tr.archived td.mat-mdc-cell {
     color: lightgrey !important;
   }
 `]
})
export class LabsListComponent implements OnInit {
  labs$: Observable<Lab[]>;

  displayedColumns = ['alias', 'name', 'homePage', 'email', 'action'];

  constructor(
    private readonly store: Store<AppState>,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.labs$ = this.store.select(selectCompanyAllLabs);
  }

  edit(lab: Lab) {
    this.dialog.open(LabsEditDialogComponent, {data: lab});
  }

  archive(lab: Lab) {
    this.store.dispatch(archiveLab({lab}));
  }

  restore(lab: Lab) {
    this.store.dispatch(restoreLab({lab}));
  }

}
