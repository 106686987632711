import { Component, Input } from '@angular/core';
import { SequencerConfiguration } from '../../../sequencing/models/sequencer-configuration';

@Component({
  selector: 'nemo-flow-cell-sequencer-configuration-readonly',
  template: `
    <div *ngIf="configuration">
      <span *ngIf="configuration.flowCellType">Flow Cell type: {{configuration.flowCellType.name}}</span>
      <span *ngIf="configuration.loadingType">Loading type: {{configuration.loadingType.name}}</span>
    </div>
  `,
  styles: [`
    div {
      display: flex;
      flex-direction: column;
    }
  `]
})
export class FlowCellSequencerConfigurationReadonlyComponent {

  @Input() configuration: SequencerConfiguration;

}
