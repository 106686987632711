import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SimplePoolWithRequest } from '../../../bio/models/pool';
import { PoolApiService } from '../../../bio/services/pool-api.service';
import { AppState } from '../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { findFlowCellByFlowCellIdThenSelect } from '../../../labware/store/flow-cells';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nemo-lane-assigner-page',
  template: `
    <nemo-lane-assigner-container></nemo-lane-assigner-container>
  `
})
export class LaneAssignerPageComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;
  poolSubscription: Subscription;
  pools: Array<SimplePoolWithRequest>;

  constructor(
    private poolService: PoolApiService,
    private route: ActivatedRoute,
    public readonly store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.paramSubscription = this.route.params.subscribe(params => {
      const flowCellId = params['flowCellId'];
      this.titleService.setTitle(`Nemo lane assigment ${formatAccessionCode(flowCellId)}`);

      this.store.dispatch(findFlowCellByFlowCellIdThenSelect({flowCellId: flowCellId}));
      if (this.poolSubscription) {
        this.poolSubscription.unsubscribe();
      }
      this.poolSubscription = this.poolService.findAll(false)
        .subscribe((ps) => {
          this.pools = ps;
        });
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
    if (this.poolSubscription) {
      this.poolSubscription.unsubscribe();
    }
  }
}
