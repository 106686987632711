import { FlowCell } from '../models/flow-cell';
import { SimplePoolWithRequestDto } from '../../bio/dto/bio.dto';
import { FlowCellMapping } from '../models/flow-cell-mapping';
import { simplePoolWithRequestDtoToSimplePoolWithRequest } from '../../bio/services/bio.mapper';
import { SequencerModel } from '../../sequencing/models/sequencer-model';
import { SequencerConfiguration } from '../../sequencing/models/sequencer-configuration';

export class FlowCellMappingDto {
  pool: SimplePoolWithRequestDto;
  laneNumber: number;
  laneOccupancy: number;
}

export class FlowCellUpdateDto {
  laneAssignmentDtos: CreateFlowCellMappingDto[];
  concentrations: number[];
  sequencerConfiguration: SequencerConfiguration | null;
}

export class CreateFlowCellMappingDto {
  poolAccessionCode: string;
  laneNumber: number;
  laneOccupancy: number;
}


export class FlowCellDto {
  id: number;
  sequencerModel: SequencerModel;
  sequencerConfiguration: SequencerConfiguration | null;
  flowCellId: string;
  numberOfLanes: number;
  createdAt: Date;
  layout: Array<FlowCellMappingDto>;
  concentrations: number[];
  status: string;
  archived: boolean;
}

export function flowCellMappingToCreateDto(fcm: FlowCellMapping): CreateFlowCellMappingDto {
  return {
    poolAccessionCode: fcm.simplePoolWithRequest.accessionCode,
    laneNumber: fcm.laneNumber,
    laneOccupancy: fcm.laneOccupancy
  };
}

export function flowCellDtoToFlowCell(dto: FlowCellDto): FlowCell {
  return new FlowCell(
    dto.id,
    dto.flowCellId,
    dto.sequencerModel,
    dto.sequencerConfiguration,
    dto.numberOfLanes,
    new Date(dto.createdAt),
    dto.layout.map(flowCellMappingDtoToFlowCellMapping),
    dto.concentrations?.map(concentration => concentration),
    dto.status,
    dto.archived
  );
}

/**
 * to  transform a mapping Dto to a map which actually contains the pool, we somehow need the real PoolSampleMix associated with the dto ids
 */
export function flowCellMappingDtoToFlowCellMapping(dto: FlowCellMappingDto): FlowCellMapping {
  return new FlowCellMapping(
    simplePoolWithRequestDtoToSimplePoolWithRequest(dto.pool),
    dto.laneNumber,
    dto.laneOccupancy
  );
}

export function flowCellToFlowCellUpdateDto(flowCell: FlowCell): FlowCellUpdateDto {
  return {
    laneAssignmentDtos: flowCellToFLowCellLaneMappingDto(flowCell),
    concentrations: flowCell.concentrations,
    sequencerConfiguration: flowCell.sequencerConfiguration
  } as FlowCellUpdateDto;
}

export function flowCellToFLowCellLaneMappingDto(flowCell: FlowCell): CreateFlowCellMappingDto[] {
  return flowCell.layout.map(flowCellMappingToCreateDto);
}
