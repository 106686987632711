import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { Observable } from 'rxjs';

@Component({
  selector: 'nemo-echo-pooling-processed-page',
  template: `
    <div class="title">
      <h2>Echo pooling processed</h2>
    </div>

    <div class="container">
      <nemo-echo-pooling-left-zone [plateAccessionCode$]="accessionCode$"
                                   [withSelector]="false"></nemo-echo-pooling-left-zone>

      <nemo-echo-pooling-middle-zone [targetAccessionCode]="accessionCode$ | async"
                                     [processed]="true"></nemo-echo-pooling-middle-zone>
    </div>

    <h3>Echo pooling processed to {{accessionCode$ | async | accessionCode}}</h3>
    <nemo-echo-pooling-table [processed]="true"></nemo-echo-pooling-table>
  `,
  styles: [`
    .container {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }

    .title {
      border-bottom: 1px solid #dadce0;
      height: 30px;
      margin: 15px 0 0 0;
      display: flex;
      justify-content: space-between;
    }

    .title h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #555;
      line-height: 24px;
      margin: 0;
    }


    nemo-echo-pooling-left-zone {
      width: 500px;
    }
  `]
})
export class EchoPoolingProcessedPageComponent implements OnInit {

  accessionCode$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.accessionCode$ = this.route.params.pipe(
      map(params => {
        const accessionCode = params['accessionCode'];
        this.titleService.setTitle(`Nemo processed echo pooling to plate ${formatAccessionCode(accessionCode)}`);
        return accessionCode;
      })
    );
  }

}
