import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Department } from '../models/Department';

export const DEPARTMENT_BASE_URI = environment.apiUrl + '/departments';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private readonly http: HttpClient) {
  }

  findAll(): Observable<Department[]> {
    return this.http.get<Department[]>(DEPARTMENT_BASE_URI);
  }

  create(department: Department): Observable<Department> {
    return this.http.post<Department>(DEPARTMENT_BASE_URI, department);
  }

  archive(department: Department): Observable<void> {
    const uri = `${DEPARTMENT_BASE_URI}/${department.id}`;
    return this.http.delete<void>(uri);
  }

  restore(department: Department): Observable<void> {
    const uri = `${DEPARTMENT_BASE_URI}/${department.id}/restore`;
    return this.http.post<void>(uri, undefined);
  }
}
