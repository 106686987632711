import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import CommentState from './comment.reducer';

export const selectComment = createFeatureSelector< CommentState>('comment');

export const selectAllComments = createSelector(
  selectComment,
  (state) => state.comments
);

