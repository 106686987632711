import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingHomeComponent } from './components/reporting-home/reporting-home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../../material.module';


@NgModule({
  declarations: [
    ReportingHomeComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
  ],
  exports: [
    ReportingHomeComponent
  ]
})
export class ReportingSharedModule {
}
