import { Component, Input } from '@angular/core';
import { FormRequest } from '../../models/form-request';

@Component({
  selector: 'nemo-request-form-header',
  template: `
    <div class="container">
      <div class="title-line">
        <div class="accession-code">{{request?.accessionCode | accessionCode}}</div>
        <div class="assigned-users">
          <nemo-request-user-assignment-container
            [request]="request"
          ></nemo-request-user-assignment-container>
        </div>
      </div>
    </div>
  `,
  styles: [`
             div.title-line {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: flex-end;
               border-bottom: 1px solid #dadce0;
             }

             div.title-line .accession-code {
               flex-grow: 0.3;
               font-family: monospace;
               font-size: 2rem;
               font-weight: bold;
             }

             div.title-line .title-author {
               flex-grow: 0.3;
               margin-left: 50px;
               font-size: 14px;
               font-weight: 400;
               padding-bottom: 0.3em;
             }

             div.assigned-users {
               flex-grow: 1;
             }
           `]
})
export class FormRequestHeaderComponent {
  @Input()
  request: FormRequest;

  constructor() {
  }

}
