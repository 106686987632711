import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { processReArray } from '../../../labware/store/plates/actions/plate-list.action';

@Component({
  selector: 'nemo-re-array-title',
  template: `
    <div class="title">
      <h2>Re array</h2>
      <button *ngIf="!(!accessionCode || accessionCode === 'undefined')"
              mat-raised-button
              color="primary"
              (click)="validateReArray()">
        Validate re array
      </button>
    </div>
  `,
  styles: [`
    .title {
      border-bottom: 1px solid #dadce0;
      height: 30px;
      margin: 15px 0 0 0;
      display: flex;
      justify-content: space-between;
    }

    .title h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #555;
      line-height: 24px;
      margin: 0;
    }

    .title button {
      margin: -9px 10px 3px 0;
    }
  `]
})
export class ReArrayTitleComponent {

  @Input() accessionCode: string;

  constructor(private store: Store<AppState>) {
  }

  validateReArray() {
    this.store.dispatch(processReArray({accessionCode: this.accessionCode}));
  }
}
