import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { createProject, selectCompanyAllProjects } from '../../../store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { regexpInt } from '../departments/departments-add.component';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'nemo-projects-add',
  template: `
    <div class="container"
         [formGroup]="formGroup"
    >
      <mat-form-field>
        <mat-label>Project name</mat-label>
        <input
          matInput
          formControlName="name"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Project code</mat-label>
        <input
          matInput
          formControlName="code"
        >
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!formGroup.valid"
          (click)="submit()"
        >Add
        </button>
      </div>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    mat-form-field {
      width: 40em;
      margin-right: 1em;
    }
  `]
})
export class ProjectsAddComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required
      ]),
      code: new UntypedFormControl('', [
        Validators.required, Validators.pattern(regexpInt)
      ])
    });
    this.store.select(selectCompanyAllProjects).pipe(take(1), tap(() => this.formGroup.reset()));
  }

  submit() {
    this.store.dispatch(createProject({
      name: this.formGroup.get('name').value,
      code: this.formGroup.get('code').value
    }));
  }
}
