import { Component, Input, OnInit } from '@angular/core';
import { RequestSampleContainers } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-sample-containers',
  templateUrl: './request-sample-containers.component.html',
  styleUrls: ['./request-sample-containers.component.scss']
})
export class RequestSampleContainersComponent implements OnInit {

  @Input() containers: RequestSampleContainers;
  @Input() requestAccessionCode: string;
  @Input() isIcon = false;
  @Input() showActions = true;
  @Input() showOpenLinks = true;
  @Input() isLaber: boolean;
  @Input() indexHint = false;


  ngOnInit() {
    this.showActions = this.showActions && this.isLaber;
  }
}
