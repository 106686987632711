import * as _ from 'lodash';

export function samplenamesFromString(str: string): string[] {
  return _.chain(str.split(/[,\n]/))
    .map((n) => n.trim())
    .filter((n) => Boolean(n))
    .flatMap(samplenamesInterpolateRange)
    .value();
}

/**
 * CR1-> CR1
 * CR4-7 -> CR4,CR5,CR6,CR7
 */
export function samplenamesInterpolateRange(str: string): string[] {
  const regexp = /(.*?)(\d+)\s*-\s*(\d+)$/;
  const reResults = str.match(regexp);
  if (!reResults) {
    return [str];
  }
  const radix = reResults[1];
  const iFrom = parseInt(reResults[2], 10);
  const iTo = parseInt(reResults[3], 10);
  if (iTo < iFrom) {
    return [str];

  }
  const ret = [];
  for (let i = iFrom; i <= iTo; i += 1) {
    ret.push(`${radix}${i}`);
  }
  return ret;
}

export class SamplenameOption {
  constructor(public readonly values: { [key: string]: string } = {}) {
  }

  size() {
    return _.size(this.values);
  }

  isEmpty() {
    return this.size() === 0;
  }

  /**
   * for each option value, returns a list of sample names
   */
  groupByOption(): { sampleNames: string[], option: string }[] {
    return _.chain(this.values)
      .reduce(
        (acc, value, key) => {
          (acc[value] || (acc[value] = [])).push(key);
          return acc;
        },
        {}
      )
      .map((sampleNames, option) => ({option, sampleNames}))
      .value();
  }

  hasSample(sampleName: string): boolean {
    return Boolean(this.values[sampleName]);
  }

  /**Names can be added to the list
   * i.e. therte are not already in the value list
   * Else, it returns an error message
   */
  validateAdd(sampleNames: string[]): string | null {
    const duplicateSamplenames = _.filter(sampleNames, (n) => this.hasSample(n));
    return (duplicateSamplenames.length === 0) ? null : `Sample(s) already exist: ${duplicateSamplenames.join(',')}`;
  }

  /**
   * return a new SamplenameOption object with the sample + option added
   */
  add(sampleNames: string[], option: string): SamplenameOption {
    const check = this.validateAdd(sampleNames);
    if (check) {
      throw new Error(check);
    }
    const plus = {};
    sampleNames.forEach((n) => plus[n] = option);
    return new SamplenameOption({
      ...this.values,
      ...plus
    });
  }

  /**
   * get the list of smaples names with the given option
   */
  samplenamesByOption(option: string): string[] {
    const ret: string[] = [];
    _.each(this.values, (o, sn) => {
      if (o === option) {
        ret.push(sn);
      }
    });
    return _.orderBy(ret);
  }

  /**
   * return a new SamplenameOption with all sample refereing to the given option removed
   */
  removeOption(option: string): SamplenameOption {
    const removeSamplenames = this.samplenamesByOption(option);
    const newValues = {...this.values};
    removeSamplenames.forEach((n) => delete newValues[n]);
    return new SamplenameOption(newValues);
  }

}
