import { createAction, props } from '@ngrx/store';
import { Lab } from '../models/Lab';

export const findAllLabs = createAction(
  '[Init Data] find all Labs'
);

export const findAllLabsAfterUpdate = createAction(
  '[After Update] find all Labs'
);

export const findAllLabsSuccess = createAction(
  '[Lab API]  all Labs list Success',
  props<{ labs: Lab[] }>()
);

export const findAllLabsError = createAction(
  '[Lab API]  all Labs list Error',
  props<{ message: string }>()
);

export const createLab = createAction(
  '[Labs page] create new lab',
  props<{ alias: string, name: string, homePage: string , email: string }>()
);

export const updateLab = createAction(
  '[Labs page] update lab',
  props<{ alias: string, name: string, homePage: string , email: string }>()
);

export const archiveLab = createAction(
  '[Labs page] archive lab',
  props<{ lab: Lab }>()
);

export const restoreLab = createAction(
  '[Labs page] restore lab',
  props<{ lab: Lab }>()
);

export type LabAction = ReturnType<| typeof findAllLabs
  | typeof findAllLabsSuccess
  | typeof findAllLabsError>;
