import { Component, Input, OnInit } from '@angular/core';
import { Question, QuestionMultiChoice } from '../../models/form';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-submitted-form-question-multiple-choice',
  template: `
    <div
      *ngIf="!question.multiple"
      class="answer"
    >
      {{value}}
    </div>
    <div
      *ngIf="question.multiple"
      class="answer"
    >
      <ul>
        <li
          *ngFor="let v of value"
        >{{v}}</li>
      </ul>
    </div>
  `,
  styles: [`
           `]
})

export class SubmittedFormQuestionMultipleChoiceComponent {
  @Input()
  question: QuestionMultiChoice;

  @Input()
  value: string | string[];

}
