import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import * as _ from 'lodash';

import { AppState } from '../../../store/app.reducers';
import { FormStructure } from '../../models/form';
import { selectFormCurrent } from '../../store/form/form.selector';
import { UntypedFormGroup } from '@angular/forms';
import { FormValues } from '../../models/form-values';
import { FormBuilderService } from '../../services/form-builder.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'nemo-form-submit',
  template: `
    <div class="container">
      <div class="col">
        <nemo-form
          *ngIf="formStructure$ | async"
          [structure]="formStructure$ | async"
          [group]="group"
          [startCollapsed]="startCollapsed"
          [initialSectionKey]="initialSectionKey"
        ></nemo-form>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: row;
             }
           `]
})
export class FormSubmissionComponent {
  @Input()
  group: UntypedFormGroup = new UntypedFormGroup({});

  @Input()
  initialValues: FormValues = {} as FormValues;

  @Input()
  startCollapsed = false;

  @Input()
  initialSectionKey;

  formStructure$: Observable<FormStructure>;

  constructor(
    private readonly formBuilderService: FormBuilderService,
    private store: Store<AppState>
  ) {
    this.formStructure$ = this.store.select(selectFormCurrent).pipe(
      
      filter((f) => Boolean(f)),
      map((form) => {
        /*
          lodash wins again....
          >= ES 2017:
          Object.entries(obj).forEach(
              ([key, value]) => console.klog(key, value)
          );
         */
        _.each(
          this.formBuilderService.buildControls(form.structure, this.initialValues),
          (control, key) => this.group.addControl(key, control)
        );
        return form.structure;
      })
    );
  }
}
