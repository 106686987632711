<form class="search-form dense-2" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="isLoggedIn$|async">
  <mat-form-field appearance="outline">
    <input class="search-input" matInput placeholder="Find requests by accession code, requester, study, description or sample"
           [matAutocomplete]="auto"
           formControlName="search" #autoCompleteInput>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <div class="requester-options" style="display: flex" *ngIf="users.length > 0">
      <mat-option class="requester-options-title">Submitted by</mat-option>
      <mat-option *ngFor="let user of users" class="inline-option"
                  [value]="user.name" (onSelectionChange)="goToRequestListByRequester(user.id)">
        <div class="inline-option-img-container">
          <nemo-user-summary [userId]="user.id"></nemo-user-summary>
        </div>
      </mat-option>
    </div>

    <mat-option *ngFor="let request of requests"
                [value]="request.accessionCode" (onSelectionChange)="goToRequestPage(request.accessionCode)">
      <div class="request-option">
        <div class="option-left">
          <span class="request-id-option"
                [innerHTML]="request.accessionCode | accessionCode | highlight: search"></span>
          <span> - </span>
          <span class="request-id-option" [innerHTML]="request.title | highlight: search"></span>
          <br/>
          <span class="request-requester-option">{{request.requester.name}}</span>
        </div>
        <div>
          <span class="request-date-option">{{request.createdAt | date : "short"}}</span>
        </div>
      </div>
    </mat-option>
    <mat-option *ngIf="requests.length === 0" disabled="true" class="no-matching">
      No matching request.
    </mat-option>
  </mat-autocomplete>
</form>
