import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';

import { Plate96 } from '../../../models/plate-96';
import { PlateCoordinatesInterval } from '../../../models/plate-coordinates-interval';
import { PlateCoordinates } from '../../../models/plate-coordinates';
import { PlateCoordinatesSelection } from '../../../models/plate-coordinates-selection';
import { BioMaterialPlateMapping } from '../../../models/bio-material-plate-mapping';
import { BioMaterial } from '../../../../bio/models/bio-material';
import { WellData } from '../../../models/well-data';
import { Tooltip } from './tooltip';
import { PlateService } from '../../../services/plate.service';
import { Pool, PoolSamplePlaceholder } from '../../../../bio/models/pool';
import { ColorScale } from '../../../../shared/models/color-scale';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { updatePlateField } from '../../../store/plates/actions/plates-api.action';

/**
 * A 2D widget to display a Plate and eventually a Plate well selection.
 * It is only presentational. To listen to plate changes or to enable selection with the mouse,
 * see the PlateViewerContainerComponent.
 */
@Component({
  selector: 'nemo-plate-viewer',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './plate-viewer-2d.component.html',
  styleUrls: ['./plate-viewer-2d.component.scss'],
})
export class PlateViewer2DComponent implements OnInit, AfterContentInit, OnChanges {

  @ViewChild('layout', {static: true}) layoutDiv: ElementRef;

  @Input() isReadonly: boolean;
  @Input() plate: Plate96<BioMaterial>;
  @Input() wellSelection: PlateCoordinatesSelection;
  @Input() isIcon = false;
  @Input() showLegend = false;
  @Input() showOpenLink = false;
  @Input() colorStringFn: (BioMaterial) => string = null;

  // what happens when the selection changes, cf. mouse listener. Requires isReadonly=false
  @Output() updateSelection = new EventEmitter<PlateCoordinatesInterval>();

  // Instead of relying on the Plate structure, we prepare data in order to be displayable
  private wellData: WellData[];

  // the DOM reference element, for d3
  private elementRef: ElementRef;

  // the dimensions of the widget, width x height, margins, well radius, etc.
  // (nothing to do with the number of bioMaterialPlateMappings)

  private widgetDimension: any;
  // parent SVG element for the layout
  private svgRoot: any;

  // d3.js mapping from the position on the plateCoordinatesSelection to the pixel coordinate within the SVG layout
  private scales: any;

  // to color differently samples from different requests, map a requestAccessionCode to a color
  private colorScale = new ColorScale<BioMaterial>().setKeyMapper((p: Pool) => {
      return p.listRequestAccessionCode().join(';');
    }
  );

  // temporary data holder for the mouse events (on which bioMaterialPlateMappings was it downed etc.)
  private _mouseEventsHolder = {};

  private lastMousemoveCoords: PlateCoordinates | null = null;
  private tooltip = new Tooltip();

  // deducted depending on an existing plate id
  public titleEditable = true;

  constructor(
    private readonly store: Store<AppState>,
    element: ElementRef,
    public readonly plateService: PlateService,
  ) {
    this.elementRef = element;
  }

  ngOnInit() {
    this.titleEditable = this.plate.id !== null && this.plate.id !== undefined;
  }

  /**
   * ngAfterContentInit is the best place to start using D3 since by this time, the DOM is ready
   */
  ngAfterContentInit() {
    this.tooltip.create();
    // Need to init dimensions here once even if it already done in `drawPlate`
    this.widgetDimension = {};
    this.setupWidgetDimensions();
    this.setupDimensions();
    this.svgRoot = d3.select(this.elementRef.nativeElement)
      .selectAll('div.layout').append('svg')
      .attr('viewBox', '0 0 400 300');
    this.svgRoot
      .attr('height', '100%')
      .attr('width', '100%');
    // Draw the first time
    this.drawAll();
  }

  /**
   * Since we are not leveraging Angular to draw d3 elements,
   * we need to trigger redraws on update manually.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.svgRoot) {
      if (changes.plate) {
        this.setColors();
        this.setupDimensions();
        this.setupScales();
        this.drawAxes();
        this.drawPlate();
      }
      if (changes.wellSelection) {
        this.drawWellSelection();
      }
    }
  }

  updatePlateTitle(newPlateTitle: string) {
    this.store.dispatch(updatePlateField({
      accessionCode: this.plate.accessionCode,
      field: 'title',
      value: newPlateTitle
    }));
  }

  drawAll() {
    this.setColors();
    this.setupDimensions();
    this.setupScales();
    this.drawAxes();
    this.drawPlate();
    this.drawWellSelection();
  }

  private drawPlate() {
    this.drawPlateContent();
    if (!this.isReadonly) {
      this.setMouseBehavior();
    }
  }

  /**
   * Builds the `wellData` object based on the latest plate data.
   */
  wellDataFromPlate() {
    this.wellData = [];
    for (let i = 0; i < this.plate.dimensions.size(); i++) {
      let requestACs: string;

      if (!this.plate.isEmptyAt(i)) {
        const bioMaterial = this.plate.getBioMaterialAt(i);
        requestACs = ((!this.plate.isEmptyAt(i)) && (bioMaterial instanceof Pool)) ?
                     (bioMaterial as Pool).listRequestAccessionCode().join(',') :
                     '';
      }
      this.wellData[i] = {
        id: i,
        plateCoordinates: this.plate.idx2coordinate(i),
        content: !this.plate.isEmptyAt(i) && this.plate.getBioMaterialAt(i),
        isEmpty: true,
        containsSample: false,
        containsIndex: false,
        contentIsIndexed: false,
        requestAccessionCode: requestACs,
      };
    }
    this.plate.getNonEmptyBioMaterialMappings()
      .forEach((mapping: BioMaterialPlateMapping<BioMaterial>) => {
        const i = this.plate.coordinate2idx(mapping.plateCoordinates);
        const material = mapping.biomaterial;
        this.wellData[i].isEmpty = material.isEmpty;
        if (material instanceof Pool) {
          const pool = material as Pool;
          this.wellData[i].containsSample = true;
          if (pool.hasIndexedSample()) {
            this.wellData[i].contentIsIndexed = true;
          }
        } else {
          this.wellData[i].containsIndex = true;
        }
      });
  }

  /**
   * Generate enough colors to give a distinct one to each different requestAccessionCode on the plate
   */
  private setColors() {
    if (!this.showLegend) {
      return;
    }
    const uniquePoolByRequests = _.chain(this.plate.getNonEmptyBioMaterialMappings())
      .map('biomaterial')
      .uniqBy((p: Pool) => p.listRequestAccessionCode().join(';'))
      .value();
    this.colorScale = this.colorScale.updateRange(uniquePoolByRequests);
  }

  /**
   * Set the widgetDimension map, point to
   *   * height/width of parent and inner layout
   *   * margins (which might be 0 if the overall container is to small
   *   * well radius and square around
   */
  private setupDimensions() {
    this.setupInnerDimensions();
  }

  /**
   * Set the enclosing dimensions based on 'layout' div's dimensions.
   * If both dimensions were not set, use a default value (400x300px).
   * If only one was set, set the other to 3/4, resp. 4/3 of the first.
   */
  private setupWidgetDimensions() {
    const height = 300;
    const width = height * 4 / 3;

    this.widgetDimension = {
      ...this.widgetDimension,
      parent: {
        height,
        width,
      },
    };
  }

  /**
   * Set the inner dimensions (margins, well rect size, radius etc.)
   */
  private setupInnerDimensions() {
    const parentDim = this.widgetDimension.parent;

    if (this.isIcon) {
      this.widgetDimension.margins = {
        top: 0,
        left: 0,
      };
    } else {
      this.widgetDimension.margins = {
        top: 30,
        left: 30,
      };
    }
    this.widgetDimension.layout = {
      height: parentDim.height - this.widgetDimension.margins.top,
      width: parentDim.width - this.widgetDimension.margins.left,
    };

    this.widgetDimension.well = {
      height: this.widgetDimension.layout.height / this.plate.numberOfRows(),
      width: this.widgetDimension.layout.width / this.plate.numberOfColumns(),
    };
    this.widgetDimension.well.radius =
      Math.min(this.widgetDimension.well.height, this.widgetDimension.well.width) / 3;
  }

  /** Create 2 linear scales */
  private setupScales() {
    this.scales = {
      layout: {
        x: d3.scaleLinear()
          .domain([0, this.plate.numberOfColumns() - 1])
          .range([
            this.widgetDimension.margins.left + this.widgetDimension.well.width / 2,
            this.widgetDimension.parent.width - this.widgetDimension.well.width / 2,
          ]),
        y: d3.scaleLinear()
          .domain([0, this.plate.numberOfRows() - 1])
          .range([
            this.widgetDimension.margins.top + this.widgetDimension.well.height / 2,
            this.widgetDimension.parent.height - this.widgetDimension.well.height / 2,
          ]),
      },
    };
  }

  private drawAxes() {
    if (this.widgetDimension.margins.top > 0) {
      this.svgRoot.selectAll('g.axis g.tick').remove();
      const xAxis = d3.axisTop(this.scales.layout.x)
        .ticks(this.plate.numberOfColumns())
        .tickFormat((undefined, i) => '' + (i + 1))
        .tickSize(0);
      this.svgRoot.append('g')
        .classed('axis', true)
        .classed('axis-x', true)
        .attr('transform', 'translate(0, ' + this.scales.layout.y(-0.5) + ')')
        .call(xAxis);
    } else {
      this.svgRoot.selectAll('g.axis.axis-x').remove();
    }

    if (this.widgetDimension.margins.left > 0) {
      const yAxis = d3.axisLeft(this.scales.layout.y)
        .ticks(this.plate.numberOfRows())
        .tickFormat((undefined, i) => String.fromCharCode(65 + i))
        .tickSize(0);

      this.svgRoot.append('g')
        .classed('axis', true)
        .classed('axis-y', true)
        .attr('transform', 'translate(' + this.scales.layout.x(-0.5) + ', 0)')
        .call(yAxis);
    } else {
      this.svgRoot.selectAll('g.axis.axis-y').remove();
    }

    this.svgRoot.selectAll('g.axis')
      .selectAll('path')
      .style('stroke', 'none');
  }

  /**
   * Plot the content (one plate + the well, indexes etc.)
   * wrap enter/update/exit d3.js patterns at once
   */
  private drawPlateContent() {
    this.wellDataFromPlate();

    // 1/3 of the smallest dimensions     // TODO: document me
    const r = Math.min(
      this.scales.layout.x(1) - this.scales.layout.x(0),
      this.scales.layout.y(1) - this.scales.layout.y(0),
    ) / 3;
    const radius = this.widgetDimension.well.radius;

    // Clear
    this.svgRoot.selectAll('g.well').remove();

    const gWells = this.svgRoot.selectAll('g.well')
      .data(this.wellData)
      .enter()
      .append('g')
      .classed('well', true)
      .classed('is-empty', (w: WellData) => w.isEmpty)
      .classed('contains-sample', (w: WellData) => (!w.isEmpty && w.containsSample))
      .classed('contains-index', (w: WellData) => (!w.isEmpty && w.containsIndex))
      .classed('content-is-indexed', (w: WellData) => w.contentIsIndexed);

    this.drawSquareOutline(gWells);
    this.drawAssignedIndexes(gWells, r, radius);
    const wellCircles = this.drawWells(gWells, radius);
    this.drawDuplicateIndexWarnings(gWells, r, radius);

    // Show tooltip on mouse over
    const _this = this;
    wellCircles
      .on('mouseover', function (event: any, w: WellData) {
        if (!_this.plate.isEmptyAt(w.plateCoordinates)) {
          const material: BioMaterial = _this.plate.getBioMaterialAt(w.plateCoordinates);
          _this.tooltip.show(event, material, w);
        }
      })
      .on('mouseout', function () {
        _this.tooltip.hide();
      });
  }

  /** Light square outline around each well */
  private drawSquareOutline(gWells) {
    return gWells
      .append('rect')
      .classed('well', true)
      .attr('x', (w: WellData) => this.scales.layout.x(w.plateCoordinates.column - 0.5))
      .attr('y', (w: WellData) => this.scales.layout.y(w.plateCoordinates.row - 0.5))
      .attr('height', this.widgetDimension.well.height)
      .attr('width', this.widgetDimension.well.width)
      .attr('fill', 'white');
  }

  private drawAssignedIndexes(gWells, r, wellRadius) {
    return gWells
      .append('circle')
      .classed('index', true)
      .attr('cx', (w: WellData) => this.scales.layout.x(w.plateCoordinates.column) + r)
      .attr('cy', (w: WellData) => this.scales.layout.y(w.plateCoordinates.row) - r)
      .attr('r', wellRadius / 2);
  }

  /** Full circle representing a well */
  private drawWells(gWells, wellRadius) {
    const wells = gWells
      .append('circle')
      .classed('well', true)
      .attr('cx', (w: WellData) => this.scales.layout.x(w.plateCoordinates.column))
      .attr('cy', (w: WellData) => this.scales.layout.y(w.plateCoordinates.row))
      .attr('r', wellRadius);
    // Color wells with samples according to requestAccessionCode
    wells
      .filter((w: WellData) => (!w.isEmpty && w.containsSample))
      .style('fill', (w: WellData) => this.colorScale.get(w.content) || 'forestgreen');
    return wells;
  }

  /** Triangle in the top left corner of a well where the index sequence is found more than once on the same plate */
  private drawDuplicateIndexWarnings(gWells, r, wellRadius) {
    // Select wells with duplicate indexes
    const duplicates = this.plateService.findDuplicateIndexes(this.plate as Plate96<PoolSamplePlaceholder>);
    const duplicatePositions = duplicates.map(d => d.position);
    const gWarnings = gWells
      .filter((w: WellData) => w.contentIsIndexed)
      .filter((w: WellData) => duplicatePositions.includes(w.plateCoordinates.toString()))
      .append('g')
      .attr('transform', (w) => `translate(
        ${this.scales.layout.x(w.plateCoordinates.column) - r - wellRadius / 2},
        ${this.scales.layout.y(w.plateCoordinates.row) - r - wellRadius / 2})
        `);
    // Draw the triangle
    const warnTrianglePath = 'M1 21 h22 L12 2 1 21 z';
    gWarnings
      .append('path')
      .attr('d', warnTrianglePath)
      .attr('transform', 'scale(0.7)')
      .attr('fill', 'red');
    // Draw the exclamation mark
    const exclamationMarkPath = 'M1 21 m12-3 h-2 v-2 h2 v2 z m0-4 h-2 v-4 h2 v4 z';
    gWarnings
      .append('path')
      .attr('d', exclamationMarkPath)
      .attr('transform', 'scale(0.7)')
      .attr('fill', 'white');
  }

  /**
   * Update the visual of the bioMaterialPlateMappings based on selection
   */
  private drawWellSelection() {
    if (!this.svgRoot || !this.wellSelection) {
      return;
    }
    this.svgRoot.selectAll('g.well')
      .selectAll('rect')
      .attr('fill', (w) => {
        return this.wellSelection.isSelectedAt(w.plateCoordinates) ? 'lightgrey' : 'white';
      });
  }

  /**
   * React on mouse events
   * * mousedown start an area selection
   * * mousemove => trigger a selection update
   * * mouseup => release the selection holder
   */
  private setMouseBehavior() {
    this.svgRoot.selectAll('g.well')
      .on('mousedown', (e: PointerEvent, w: WellData) => {
        this._mouseEventsHolder['mousedown'] = w;
      })
      .on('mousemove', (e: PointerEvent, w: WellData) => {
        if (!this._mouseEventsHolder['mousedown']) {
          return;
        }

        // no need to update if mouse move is on the same well
        if (this.lastMousemoveCoords && this.lastMousemoveCoords.equalsTo(w.plateCoordinates)) {
          return;
        }
        this.lastMousemoveCoords = w.plateCoordinates;

        // build a new interval
        const interval = new PlateCoordinatesInterval(
          this._mouseEventsHolder['mousedown'].plateCoordinates,
          w.plateCoordinates,
        );

        // fire the update action
        this.updateSelection.emit(interval);
      })
      .on('mouseup', () => {
        delete this._mouseEventsHolder['mousedown'];
      })
      .on('click', (e: PointerEvent, w: WellData) => {
        // build a new interval
        const interval = new PlateCoordinatesInterval(
          w.plateCoordinates,
          w.plateCoordinates,
        );
        // fire the update action
        this.updateSelection.emit(interval);
      });
  }
}
