import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { PageInfo } from '../../../shared/models/page.model';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NRequestSummary } from '../../models/genomics-request';
import { findAllRequests, getRequests, getGenomicsRequestPageInfo, isGenomicsRequestPending } from '../../store';

@Component({
  selector: 'nemo-genomics-request-list-page',
  template: `
    <nemo-pending-title title="Requests" [pending]="pending$ | async"></nemo-pending-title>
    <nemo-genomics-request-list [requests]="requests$ | async"
                                [enableArchive]="true"
                                [showAssignees]="true"
                                [showDescription]="false">
    </nemo-genomics-request-list>
    <nemo-paginator [pageInfo]="pageInfo$ | async" (pageChange)="findAll($event[0], $event[1])"></nemo-paginator>
  `,
  styles: [`
  `]
})
export class RequestListPageComponent implements OnInit {

  requests$: Observable<NRequestSummary[]>;
  pending$: Observable<boolean>; // TODO virer pending
  pageInfo$: Observable<PageInfo>;

  constructor(
    private store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(`Nemo request list`);
    this.findAll(25,0);
    this.pageInfo$ = this.store.select((getGenomicsRequestPageInfo));
    this.requests$ = this.store.select((getRequests));

    this.pending$ = this.store.select((isGenomicsRequestPending));
  }

  findAll(pageSize: number, page: number) {
    this.store.dispatch(findAllRequests({pageSize, page}));
  }

}
