import {
  ColumnType,
  FlexibleButtonsColumnDef,
  FlexibleColumnDef,
  FlexibleHtmlColumnDef,
  FlexiblePlainColumnDef,
  someFlexibleTablePresentRow
} from './flexible-columm-def';
import { FlexibleRowDef } from './flexible-row-def';

export class FlexibleTableContent<T> {
  // the column key
  public readonly displayedColumns: string[];

  // a map key -> header
  public readonly displayedHeaders: { [key: string]: string };

  // a map key -> header
  public readonly displayedHeaderTypes: { [key: string]: string };

  // a list, , for each row a map key -> display value
  // a special point is passed to the original value object through '_orig'
  public readonly displayedValues: { [key: string]: any }[];

  constructor(
    private readonly rowDefinition: FlexibleRowDef<T>,
    private readonly columnDefinitions: FlexibleColumnDef<T>[],
    private readonly values: T[]
  ) {
    const displayedColumnDef = this.columnDefinitions
      .filter((cd) => someFlexibleTablePresentRow(this.values, cd));

    this.displayedColumns = displayedColumnDef.map((cd) => cd.key);

    this.displayedHeaders = displayedColumnDef.reduce((acc, cd) => ({...acc, [cd.key]: cd.header}), {});

    // phiXPercentage needs to be hardcoded to type mapping because it is called 'phix' in table header
    // and 'phiXPercentage' in table values. Other fields' header and value matches.
    this.displayedHeaderTypes = {
      'phix' :  'Number'
    };
    this.displayedValues =
      this.values ?
      this.values.map((row: T) => {
        const initVal = {_orig: row, _row: {}};

        if (this.rowDefinition && this.rowDefinition.dynamicClass) {
          initVal._row['clazz'] = this.rowDefinition.dynamicClass(row);
        }

        return displayedColumnDef.reduce((acc, cd) => {
          // build the current cell with display value,
          // and maybe the route or an action button (can't do both though)
          const currentField = {};
          if (cd.type !== ColumnType.BUTTONS) {
            currentField['display'] = (cd.isPresent(row) ? cd.format(row) : null);
            // save type to help table sorting. Only save when datatype exists.
            if (row[cd.key]) {
              this.displayedHeaderTypes[cd.key] = row[cd.key].constructor.name;
            }
            const tcd = cd as FlexiblePlainColumnDef<T> | FlexibleHtmlColumnDef<T>;
            if (tcd.route) {
              currentField['route'] = tcd.route(row);
            }
          }
          if (cd.type === ColumnType.BUTTONS) {
            const bcd = cd as FlexibleButtonsColumnDef<T>;
            currentField['buttons'] = bcd.buttons;
          }
          return {
            ...
              acc,
            [cd.key]: currentField

          };
        }, initVal);
      }) :
        [];
  }
}
