import { Component, Input, OnInit } from '@angular/core';

import { QuestionSampleNameOption } from '../../models/form';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SamplenameOption, samplenamesFromString } from '../../models/samplename-option';

export const FORM_QUESTION_SAMPLE_NAME_OPTION = 'FORM_QUESTION_SAMPLE_NAME_OPTION';

@Component({
  selector: 'nemo-form-question-samplename-option',
  template: `
    <div class="container">
      <h4>{{question.title}}
      </h4>
      <div *ngIf="question.description" class="description">{{question.description}}</div>
      <div [formGroup]="inputFromGroup" class="new-samples-input">
        <div class="sample-names">
          <mat-form-field>
            <mat-label>sample names</mat-label>
            <input matInput formControlName="sampleNames">
            <mat-hint>with commas (e.g. "CR1,CR2,CR3,CR4"), or as ranges (e.g. "CR1-4")</mat-hint>
          </mat-form-field>
        </div>
        <div class="select-option">
          <mat-form-field>
            <mat-label>Amount</mat-label>
            <mat-select formControlName="option">
              <mat-option *ngFor="let choice of question.choices"
                          [value]="choice.value"
                          [matTooltip]="choice.help"
              >{{choice.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="add-button">
          <button mat-raised-button color="primary"
            [disabled]="!(inputFromGroup.value['sampleNames'] && inputFromGroup.value['option'])"
            (click)="addSamples()"
          >Add</button>
        </div>
      </div>

      <table mat-table [dataSource]="tableValues">
        <ng-container matColumnDef="option">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element"> {{element.option}} </td>
        </ng-container>
        <ng-container matColumnDef="sampleNames">
          <th mat-header-cell *matHeaderCellDef>Sample names</th>
          <td mat-cell *matCellDef="let element"> {{element.sampleNames.join(', ')}} </td>
        </ng-container>
        <ng-container matColumnDef="count">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let element"> {{element.sampleNames.length}} </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a class="action" (click)="removeSamples(element.option)">
              <mat-icon>delete</mat-icon>
            </a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>`,
  styleUrls: [
    './form-question.component.scss',
    './form-question-sample-name-option.scss'
  ]
})
export class FormQuestionSampleNameOptionComponent implements OnInit {
  @Input()
  question: QuestionSampleNameOption;

  @Input()
  sectionFormGroup: UntypedFormGroup;

  inputFromGroup: UntypedFormGroup;

  samplenameOption: SamplenameOption;
  displayedColumns = ['sampleNames', 'option', 'count', 'delete'];
  tableValues: any[];

  ngOnInit(): void {
    this.samplenameOption = new SamplenameOption(
      this.sectionFormGroup.value[this.question.key] || {}
    );

    this.inputFromGroup = new UntypedFormGroup({
      sampleNames: new UntypedFormControl('', []),
      option: new UntypedFormControl('', [])
    });
    this.tableValues = this.samplenameOption.groupByOption();
  }

  update() {
    this.sectionFormGroup.get(this.question.key).setValue(this.samplenameOption.values);
    this.tableValues = this.samplenameOption.groupByOption();
  }

  addSamples() {
    this.samplenameOption = this.samplenameOption.add(
      samplenamesFromString(this.inputFromGroup.value['sampleNames']),
      this.inputFromGroup.value['option']
    );
    // clear inout form
    this.inputFromGroup.setValue({sampleNames: '', option: null});
    this.update();
  }

  removeSamples(option: string) {
    const removedSampleNames = this.samplenameOption.samplenamesByOption(option);
    this.samplenameOption = this.samplenameOption.removeOption(option);
    this.inputFromGroup.setValue({sampleNames: removedSampleNames.join(', '), option: option});
    this.inputFromGroup.updateValueAndValidity();
    this.update();
  }
}
