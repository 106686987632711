import * as _ from 'lodash';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Plate96 } from '../../../models/plate-96';
import { BioMaterial } from '../../../../bio/models/bio-material';
import { Observable } from 'rxjs';
import { NRequest } from '../../../../genomics-request/models/genomics-request';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { getGenomicsRequestDictionary } from '../../../../genomics-request/store';
import { map } from 'rxjs/operators';
import {
  additionalQcDataFlexibleColumnDefinitions,
  plateTableFlexibleColumnsDefinition
} from './plate-table-flexible-columns-definition';
import { FlexibleColumnDef } from '../../../../table/model/flexible-columm-def';
import { BioMaterialPlateMapping } from '../../../models/bio-material-plate-mapping';
import { Pool } from '../../../../bio/models/pool';


@Component({
  selector: 'nemo-plate-table-detail',
  template: `
    <nemo-flexible-table
      *ngIf="plate"
      [values]="plate | nonEmptyBioMaterialMappings"
      [columnDefinitions]="columnDefinitions"
    ></nemo-flexible-table>
  `,
  styles: [`
    table {
      width: 100%;
    }

    .container {
      margin-top: 80px;
    }

    .index-sequence {
      font-family: monospace;
    }
  `]
})
export class PlateTableDetailComponent implements OnInit, OnChanges {

  @Input() plate: Plate96<BioMaterial>;
  requestList$: Observable<NRequest[]>;

  columnDefinitions: FlexibleColumnDef<BioMaterialPlateMapping<Pool>>[] = plateTableFlexibleColumnsDefinition;

  constructor(
    private readonly store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.requestList$ =
      this.store.select(getGenomicsRequestDictionary).pipe(

        map(reqDict => {
          const plateRequestAC = _.chain(this.plate.getNonEmptyBioMaterialMappings())
            .map('biomaterial.requestAccessionCode')
            .uniqBy()
            .orderBy()
            .value();
          const matchedAC = _.chain(reqDict)
            .keys()
            .intersection(plateRequestAC)
            .value();
          const gotThemAll = matchedAC.length === plateRequestAC.length;
          if (gotThemAll) {
            return plateRequestAC.map(ac => reqDict[ac]);
          }
          return [];
        })
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.plate) {
      this.columnDefinitions = [
        ...plateTableFlexibleColumnsDefinition,
        ...additionalQcDataFlexibleColumnDefinitions(this.plate)
      ];
    }
  }

}
