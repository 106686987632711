import { createAction, props } from '@ngrx/store';
import { PoolWithRequest, SimplePoolWithRequest } from '../../models/pool';
import { PoolCounts } from '../../dto/bio.dto';

export const findPoolByAccessionCode = createAction(
  '[Pool Page] Find pool by accession code',
  props<{ accessionCode: string }>()
);

export const findPoolByAccessionCodeSuccess = createAction(
  '[Pool API] Find pool by accession code success',
  props<{ pool: PoolWithRequest }>()
);

export const findPoolByAccessionCodeError = createAction(
  '[Pool API] Find pool by accession code error',
  props<{ message: string }>()
);

export const poolsSearchSuccess = createAction(
  '[Pools API] Pools search success',
  props<{ pools: SimplePoolWithRequest[] }>()
);

export const poolsSearchError = createAction(
  '[Pools API] Pools search error',
  props<{ message: string }>()
);

export const poolsFindByFlowCellIdSuccess = createAction(
  '[Pools API] Find flow cell pools success',
  props<{ pools: SimplePoolWithRequest[] }>()
);

export const poolsFindByFlowCellIdError = createAction(
  '[Pools API] Find flow cell pools error',
  props<{ message: string }>()
);

export const countPoolsSuccess = createAction(
  '[Pools API] Count pools success',
  props<{ poolCounts: PoolCounts }>()
);

export const countPoolsError = createAction(
  '[Pools API] Count pools error',
  props<{ message: string }>()
);


export const poolsFindAllSuccess = createAction(
  '[Pools API] Find all success',
  props<{ pools: SimplePoolWithRequest[] }>()
);

export const poolsFindAllError = createAction(
  '[Pools API] Find all error',
  props<{ message: string }>()
);

export type PoolsApiAction = ReturnType<| typeof poolsSearchSuccess
  | typeof poolsSearchError
  | typeof findPoolByAccessionCode
  | typeof findPoolByAccessionCodeSuccess
  | typeof findPoolByAccessionCodeError
  | typeof poolsFindAllSuccess
  | typeof poolsFindAllError
  | typeof poolsFindByFlowCellIdSuccess
  | typeof poolsFindByFlowCellIdError
  | typeof countPoolsSuccess
  | typeof countPoolsError>;
