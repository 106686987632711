import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNemoIconComponent } from './components/header-nemo-icon/header-nemo-icon.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderNemoIconComponent,
  ],
  exports: [
    HeaderNemoIconComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class NavigationSharedModule {
}
