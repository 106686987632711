import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TableModule } from '../table/table.module';
import { RequestSharedModule } from '../request-shared/request-shared.module';
import { PoolListPageComponent } from './components/pool-list-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PoolsSampleTableComponent } from './components/pools-sample-table.component';
import { SamplesSequencesPipe } from './pipes/samples.pipe';
import { PoolPageComponent } from './components/pool-page/pool-page.component';
import { PoolPageTitleComponent } from './components/pool-page/pool-page-title.component';
import { PoolInfoComponent } from './components/pool-page/pool-info.component';
import { TubesSampleTableComponent } from './components/tubes-sample-table.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    SharedModule,
    TableModule,
    RequestSharedModule,
    ReactiveFormsModule,
  ],
  declarations: [
    PoolListPageComponent,
    PoolsSampleTableComponent,
    TubesSampleTableComponent,
    SamplesSequencesPipe,
    PoolPageComponent,
    PoolPageTitleComponent,
    PoolInfoComponent,
  ],
  exports: [
    PoolsSampleTableComponent,
    TubesSampleTableComponent,
  ],
})
export class BioModule {
}
