import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import {
  archiveIndexKit,
  archiveIndexKitError,
  archiveIndexKitModel,
  archiveIndexKitSuccess,
  createNewIndexKitModel,
  findAllIndexKitModels,
  findAllIndexKitModelsAfterUpdate,
  findAllIndexKitModelsAtInit,
  findAllIndexKitModelsSuccess,
  findAllIndexKits,
  findAllIndexKitsFromTaskApi,
  findAllIndexKitsSuccess,
  findIndexKitByIdThenSelect,
  findIndexKitByIdThenSelectFromIndexAssignment,
  findIndexKitByIdThenSelectFromTaskApi,
  findIndexKitsFromIA,
  findIndexKitsSuccess,
  indexKitError,
  indexKitModelError,
  restoreIndexKitModel,
  unarchiveIndexKit,
  unarchiveIndexKitError,
  unarchiveIndexKitSuccess,
  updateIndexKitModel
} from './index-kit.actions';
import { IndexKitService } from '../services/index-kit.service';
import { Page } from '../../shared/models/page.model';
import { SimpleIndexKit } from '../models/indexKit';
import { Plate96 } from '../../labware/models/plate-96';
import { Index } from '../../bio/models/barcode-index';
import { EVENT_LISTENER_ID_INDEXES } from '../../tasks/index-assignment/constants';
import { toPageInfo } from '../../shared/services/page.service';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import * as fromTable from '../../table/store';
import { changeArchiveFilter } from '../../table/store';
import { putPlate } from '../../labware/store/plates';
import { SimpleIndexKitModel } from '../models/indexKitModel';
import { IndexKitModelService } from '../services/index-kit-model.service';


@Injectable()
export class IndexKitEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private indexKitService: IndexKitService,
    private indexKitModelService: IndexKitModelService
  ) {
  }

  
  getIndexKits = createEffect(() => { return this.actions$.pipe(
    ofType(findAllIndexKits.type, findAllIndexKitsFromTaskApi.type),
    concatLatestFrom(
      () => this.store.select(fromTable.getArchiveFilter('index-kit')).pipe(

        map(checked => checked === undefined ? true : checked),
      )
    ),
    mergeMap(([action, hideArchived]) =>
      this.indexKitService.findAll(action['pageSize'], action['page'], hideArchived).pipe(
        map((page: Page<SimpleIndexKit>) => (
          findAllIndexKitsSuccess({indexKits: page.content, pageInfo: toPageInfo(page)}))
        ),
        catchError(error => {
          const message = (error.message) ? error.message : 'Impossible to load index kits';
          return of(indexKitError({message}));
        }),
      )
    )
  ) });


  getIndexKitsByLibraryMethod = createEffect(() => { return this.actions$
    .pipe(
      ofType(findIndexKitsFromIA.type),
      mergeMap((action: ReturnType<typeof findIndexKitsFromIA>) =>
	this.indexKitService.findAll(100, 0, true)
      ),
      map((page: Page<SimpleIndexKit>) => (findIndexKitsSuccess({indexKits: page.content}))),
      catchError(error => of(indexKitError({message: error.message}))),
    ) });

  /**
   * Retrieve the info for an index kit from backend,
   * then initialize a Plate instance.
   */
  
  findIndexKitByIdThenSelect = createEffect(() => { return this.actions$.pipe(
    ofType(
      findIndexKitByIdThenSelect.type,
      findIndexKitByIdThenSelectFromIndexAssignment.type,
      findIndexKitByIdThenSelectFromTaskApi.type
    ),
    mergeMap((action: ReturnType<typeof findIndexKitByIdThenSelect
      | typeof findIndexKitByIdThenSelectFromIndexAssignment
      | typeof findIndexKitByIdThenSelectFromTaskApi>) =>
      this.indexKitService.findIndexPlateById(action.id)
    ),
    map((plate: Plate96<Index>) =>
      putPlate({
        plateAccessionCode: EVENT_LISTENER_ID_INDEXES,
        plate: plate,
        readonly: false
      })
    ),
    catchError(error => {
      const message = (error.message) ? error.message : 'Impossible to load index kit';
      return of(indexKitError({message}));
    }),
  ) });

  
  changeStatusFilter = createEffect(() => { return this.actions$.pipe(
    ofType(changeArchiveFilter.type),
    filter((action: ReturnType<typeof changeArchiveFilter>) => action.tableId === 'index-kit'),
    map(() => findAllIndexKits({pageSize: 25, page: 0}))
  ) });

  
  archive = createEffect(() => { return this.actions$.pipe(
    ofType(archiveIndexKit.type),
    mergeMap((action: ReturnType<typeof archiveIndexKit>) =>
      this.indexKitService.archive(action.id).pipe(
        map(() => archiveIndexKitSuccess()),
        catchError(error => of(archiveIndexKitError({message: error.message}))),
      )
    )
  ) });

  
  unarchive = createEffect(() => { return this.actions$.pipe(
    ofType(unarchiveIndexKit.type),
    mergeMap((action: ReturnType<typeof unarchiveIndexKit>) =>
      this.indexKitService.unarchive(action.id).pipe(
        map(() => unarchiveIndexKitSuccess()),
        catchError(error => of(unarchiveIndexKitError({message: error.message}))),
      )
    )
  ) });

  
  getIndexKitModels = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllIndexKitModels.type,
      findAllIndexKitModelsAtInit.type,
      findAllIndexKitModelsAfterUpdate.type
    ),
    mergeMap(() =>
      this.indexKitModelService.findAllIndexKitModels().pipe(
        map((indexKitModels: SimpleIndexKitModel[]) =>
          findAllIndexKitModelsSuccess({indexKitModels})
        ),
        catchError(error => {
          const message = (error.message) ? error.message : 'Impossible to load index kit models';
          return of(indexKitModelError({message}));
        }),
      )
    )
  ) });

  createIndexKitModel$ = createEffect(() => { return this.actions$.pipe(
    ofType(createNewIndexKitModel.type),
    mergeMap((action: ReturnType<typeof createNewIndexKitModel>) =>
      this.indexKitModelService.createNewIndexKitModel(action.indexKitModel)
    ),
    map(() => findAllIndexKitModelsAfterUpdate())) }
  );

  updateIndexKitModel$ = createEffect(() => { return this.actions$.pipe(
    ofType(updateIndexKitModel.type),
    mergeMap((action: ReturnType<typeof updateIndexKitModel>) =>
      this.indexKitModelService.update(action.indexKitModel)
    ),
    map(() => findAllIndexKitModelsAfterUpdate())) }
  );

  archiveIndexKitModel$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveIndexKitModel.type),
    mergeMap((action: ReturnType<typeof archiveIndexKitModel>) =>
      this.indexKitModelService.archive(action.indexKitModelId)
    ),
    map(() => findAllIndexKitModelsAfterUpdate())) }
  );

  unarchiveIndexKitModel$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreIndexKitModel.type),
    mergeMap((action: ReturnType<typeof restoreIndexKitModel>) =>
      this.indexKitModelService.restore(action.indexKitModelId)
    ),
    map(() => findAllIndexKitModelsAfterUpdate())) }
  );
}
