import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AppState } from '../../../../store/app.reducers';
import { selectCompanyActiveDepartments } from '../../../store';
import { Department } from '../../../models/Department';

@Component({
  selector: 'nemo-department-select',
  template: `
    <mat-form-field [formGroup]="formGroup">
      <mat-label>Department</mat-label>
      <mat-select formControlName="departmentCode"
                  (selectionChange)="changedValue($event.value)">
        <mat-option>
          <input matInput placeholder="Search departments"
              (input)="filterOptions($event.target.value)"
              (click)="$event.stopPropagation()"
              (keydown.space)="$event.stopPropagation()" />
        </mat-option>
        <mat-option *ngFor="let dept of filteredOptions$ | async" [value]="dept.code">
          [{{ dept.code }}] {{ dept.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: ['../select.scss'],
})
export class DepartmentSelectComponent implements OnInit {
  @Input() formGroup: FormGroup;

  @Output() changeValue = new EventEmitter<number>();

  options$: Observable<Department[]>;
  filteredOptions$: Observable<Department[]>;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.options$ = this.store.select(selectCompanyActiveDepartments);
    this.filteredOptions$ = this.options$; // Initially, show all options
  }

  filterOptions(query: string) {
    if (query) {
      this.filteredOptions$ = this.options$.pipe(
        map(options => options.filter(department =>
          department.name.toLowerCase().includes(query.toLowerCase()) ||
          department.code.toString().includes(query)
        ))
      );
    } else {
      this.filteredOptions$ = this.options$; // Reset to all options if query is empty
    }
  }

  changedValue(deptCode: number) {
    this.changeValue.emit(deptCode);
  }
}
