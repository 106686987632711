import { Component, Input, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { FlexibleTableContent } from '../../../table/model/flexible-table-content';
import { FlexibleExportGroupDef } from '../../../table/model/flexible-export-group-def';
import { poolSampleTableFlexibleColumnsDefinition } from './pool-sample-table/pool-sample-table-flexible-columns-definition';
import { Sample } from '../../models/sample';
import { PoolWithRequest } from '../../models/pool';
import { FlexibleColumnDef } from '../../../table/model/flexible-columm-def';

@Component({
  selector: 'nemo-pool-export-button',
  template: `
    <nemo-flexible-export-csv
      [columnDefinitions]="columnDefinitions"
      [exportGroupDefinitions]="groupDefinitions"
      [values]="pool | extractSampleList"
    ></nemo-flexible-export-csv>
  `
})
export class PoolExportButtonComponent {

  @ViewChild('selectRequester', {static: true}) selectRequester: MatSelect;
  @Input() pool: PoolWithRequest;

  content: FlexibleTableContent<Sample>;
  columnDefinitions: FlexibleColumnDef<Sample>[] = poolSampleTableFlexibleColumnsDefinition;

  groupDefinitions: FlexibleExportGroupDef<Sample>[] = [
    new FlexibleExportGroupDef<Sample>(
      () => ['all'],
      () => true,
      () => 'All requests',
      () => `nemo-pool-${formatAccessionCode(this.pool.accessionCode)}.csv`
    ),
    new FlexibleExportGroupDef<Sample>(
      (mapping) => mapping.requestAccessionCode,
      (mapping, ac) => mapping.requestAccessionCode === ac,
      (ac) => `Request ${formatAccessionCode(ac)}`,
      (ac) => `nemo-pool-${formatAccessionCode(this.pool.accessionCode)}-${formatAccessionCode(ac)}.csv`
    )
  ];

}
