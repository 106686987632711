import { Component, Input } from '@angular/core';
import { FlowCell } from '../../../labware/models/flow-cell';
import { FlowCellApiService } from '../../../labware/services/flow-cell-api.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'nemo-request-flowcell-export',
  template: `
    <div *ngIf="requestIsComplete">
        <div class="flow-cell-export">
          Export related Flow Cells to pipeline:
        </div>
        <button mat-raised-button
                color="primary"
                class="flow-cell-export-button"
                *ngFor="let flowCell of flowCells"
                (click)="exportFlowCell(flowCell.flowCellId)">Export FlowCell {{flowCell.flowCellId}}
        </button>
      <div *ngIf="flowCells.length === 0" class="flow-cell-export">
        No Flow Cells to export yet.
      </div>
    </div>
  `,
  styles: [`
    .flow-cell-export, .flow-cell-export-button {
      margin: 1em 2em;
      width: 100%;
    }
  `]
})

export class RequestFlowCellsExportComponent {

  @Input()
  requestIsComplete: boolean;

  @Input()
  flowCells: FlowCell[];

  @Input()
  requestAccessionCode: string;

  constructor(
    private readonly flowCellApiService: FlowCellApiService
  ) {
  }

  exportFlowCell(flowCellId: string) {
    this.flowCellApiService.exportPipeline(flowCellId, this.requestAccessionCode)
      .subscribe(({filename, content}) => {
        const blob = new Blob([content], {type: 'text/csv'});
        saveAs(blob, filename);
      });
  }

}
