import { SimpleRequest } from '../../genomics-request/models/genomics-request';
import PlateDimensions from './plate-dimensions';
import { TaskAvailable } from '../../tasks/models/task.model';

/**
 * build a pool, with SimpleRequest description
 * as there are no samples, we override the size() method
 */
export class SimplePlateWithRequests {
  constructor(
    public accessionCode: string,
    public id: number,
    public title: string,
    public numberOfSamples: number,
    public numberOfIndexedSamples: number,
    public requests: SimpleRequest[],
    public dimensions: PlateDimensions,
    public occupancy: boolean[],
    public availableForTasks: TaskAvailable[],
    public archived: boolean) {
  }

  size(): number {
    return this.numberOfSamples;
  }
}
