import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from '../app-routing.module';
import { FileTransferService } from './services/file-transfer.service';
import { RequestService } from '../genomics-request/services/request.service';
import { HttpErrorInterceptor } from './http-interceptors/error-interceptor';
import { CsvWriterService } from './services/csv-writer.service';
import { PlateService } from '../labware/services/plate.service';
import { PlateApiService } from '../labware/services/plate-api.service';
import { AuthService } from './auth/auth.service';
import { CommentService } from '../comment/services/comment-service';
import { IdTokenInterceptor } from './http-interceptors/token-interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OAuthModule.forRoot()
  ],
  providers: [
    AuthService,
    FileTransferService,
    CsvWriterService,
    RequestService,
    PlateApiService,
    PlateService,
    CommentService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IdTokenInterceptor, multi: true }
  ],
  exports: [
    RouterModule,
    ReactiveFormsModule,
  ],
})
export class CoreModule {

  // Prevent reimport of the CoreModule, see https://angular.io/guide/singleton-services#prevent-reimport-of-the-coremodule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

}
