import { Component } from '@angular/core';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-settings-home',
  template: `
    <div class="container">
      <h2>Settings</h2>
      <mat-tab-group>
        <mat-tab
          *ngIf="isLab$('genomics') | async"
          label="Library Methods"
        >
          <nemo-library-method-settings></nemo-library-method-settings>
        </mat-tab>
        <mat-tab
          *ngIf="isLab$('genomics') | async"
          label="Sequencer Models"
        >
          <nemo-sequencer-model-settings></nemo-sequencer-model-settings>
        </mat-tab>
        <mat-tab
          *ngIf="isLab$('genomics') | async"
          label="Index Kit Models"
        >
          <nemo-index-kit-model-settings></nemo-index-kit-model-settings>
        </mat-tab>
        <mat-tab
          *ngIf="(isLab$('genomics') | async) === false"
          label="Forms"
        >
          <nemo-form-settings></nemo-form-settings>
        </mat-tab>
        <mat-tab
          label="Labs"
        >
          <nemo-labs></nemo-labs>
        </mat-tab>
        <mat-tab
          label="Departments"
        >
          <nemo-departments></nemo-departments>
        </mat-tab>
        <mat-tab
          label="Projects"
        >
          <nemo-projects></nemo-projects>
        </mat-tab>
      </mat-tab-group>
    </div>
  `,
  styles: [`

           `]
})
export class SettingsHomeComponent extends HasGlobalContextUserRole {

  constructor(
    private readonly store: Store<AppState>,
  ) {
    super(store);
  }

}
