import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AppState } from '../../../../store/app.reducers';
import { selectActiveStudies } from '../../../store';
import { Study } from '../../../models/Study';
import {
  HasGlobalContextUserRole,
} from '../../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { selectGlobalContextUserAndRoleAndLab } from '../../../../global-context/store/global-context.selectors';
import { UserRole } from '../../../../global-context/models/user-role';
import * as _ from 'lodash';

@Component({
  selector: 'nemo-study-select',
  template: `
    <mat-form-field [formGroup]="formGroup">
      <mat-label>Study</mat-label>
      <mat-select formControlName="studyId"
                  (selectionChange)="changedValue($event.value)">
        <mat-option>
          <input matInput placeholder="Search studys"
              (input)="filterOptions($event.target.value)"
              (click)="$event.stopPropagation()"
              (keydown.space)="$event.stopPropagation()" />
        </mat-option>
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let study of filteredOptions$ | async" [value]="study.id">
          {{ study.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: ['../select.scss'],
})
// TODO an archived study associated to a request will appear as none when editing the request, propagating null if it's modified
export class StudySelectComponent extends HasGlobalContextUserRole implements OnInit {
  @Input() formGroup: FormGroup;

  @Output() changeValue = new EventEmitter<number>();

  options$: Observable<Study[]>;
  filteredOptions$: Observable<Study[]>;

  constructor(
    private store: Store<AppState>
  ) {
    super(store);
  }

  ngOnInit() {
    this.options$ = this.store.select(selectActiveStudies).pipe(
      withLatestFrom(
        this.store.
          select((selectGlobalContextUserAndRoleAndLab)
        )
      ),
      map(([xs, userRoleLab]) => _.chain(xs)
        .orderBy('name')
        .filter((x: Study) => userRoleLab.role === UserRole.LABER || x.createdBy.id === userRoleLab.user.id)
        .value()
      )
    );
    this.filteredOptions$ = this.options$; // Initially, show all options
  }

  filterOptions(query: string) {
    if (query) {
      this.filteredOptions$ = this.options$.pipe(
        map(options => options.filter(study =>
          study.name.toLowerCase().includes(query.toLowerCase())
        ))
      );
    } else {
      this.filteredOptions$ = this.options$; // Reset to all options if query is empty
    }
  }

  changedValue(studyId: number) {
    this.changeValue.emit(studyId);
  }

}
