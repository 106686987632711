import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
  FormStructure,
  Question,
  QuestionCheckBox,
  QuestionMultiChoice, QuestionSampleNameList, QuestionSampleNameOption, QuestionSequence,
  QuestionText,
  QuestionType
} from '../models/form';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormValues } from '../models/form-values';

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {
  constructor() {
  }

  buildControls(
    form: FormStructure,
    initialValues: FormValues
  ): { [key: string]: AbstractControl } {
    const formControls = {};
    form.sections.forEach((section) => {
      const sectionControls = {};
      section.questions.forEach((question) => {
        const control = this.control(question);
        if (initialValues && initialValues[section.key]) {
          control.setValue(initialValues[section.key][question.key]);
        }
        if (initialValues && !initialValues[section.key]) {
          control.disable();
        }
        sectionControls[question.key] = control;
      });
      formControls[section.key] = new UntypedFormGroup(sectionControls);
    });
    return formControls;
  }

  control(question: Question): UntypedFormControl {
    return new UntypedFormControl('', this.validators(question));
  }

  validators(question: Question): ValidatorFn[] {
    switch (question.type) {
      case QuestionType.TEXT: {
        return this.validatorsTextInput(question as QuestionText);
      }
      case QuestionType.SEQUENCE: {
        return this.validatorsSequenceInput(question as QuestionSequence);
      }
      case QuestionType.CHECKBOX: {
        return this.validatorsCheckbox(question as QuestionCheckBox);
      }
      case QuestionType.MULTICHOICE: {
        return this.validatorsMultiChoice(question as QuestionMultiChoice);
      }
      case QuestionType.SAMPLENAME_OPTION: {
        return this.validatorsSamplenames(question as QuestionSampleNameOption);
      }
      case QuestionType.SAMPLENAME_LIST: {
        return this.validatorsSamplenames(question as QuestionSampleNameList);
      }
    }
  }

  validatorsTextInput(question: QuestionText): ValidatorFn[] {
    const validators = [];
    if (question.required) {
      validators.push(Validators.required);
    }
    if (question.maxLength) {
      validators.push(Validators.maxLength(question.maxLength));
    }
    return validators;
  }

  validatorsSequenceInput(question: QuestionSequence): ValidatorFn[] {
    const sequenceValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return {empty: true};
      }
      if (!Boolean(control.value['sequence'])) {
        return {emptySequence: true};
      }
      return null;
    };

    const validators = [];
    if (question.required) {
      validators.push(Validators.required);
      validators.push(sequenceValidator);
    }
    return validators;
  }

  validatorsCheckbox(_question: QuestionCheckBox): ValidatorFn[] {
    return [];
  }

  validatorsMultiChoice(question: QuestionMultiChoice): ValidatorFn[] {
    const validators = [];
    if (question.required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  validatorsSamplenames(question: QuestionSampleNameOption | QuestionSampleNameList): ValidatorFn[] {
    const samplesValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = Boolean(control.value) && (_.size(control.value) > 0);
      return isValid ? null : {'emptyList': true};
    };

    const validators = [];
    if (question.required) {
      validators.push(samplesValidator);
    }
    return validators;
  }

}
