<nemo-pool-search *ngIf="searchBar"></nemo-pool-search>
<table mat-table [dataSource]="pools$"
       cdkDropList
       [cdkDropListDisabled]="!draggingEnabled"
>
  <ng-container matColumnDef="accessionCode">
    <th mat-header-cell *matHeaderCellDef>accession code</th>
    <td mat-cell
        *matCellDef="let pool" class="accession-code"
        routerLink="/pool/{{pool.accessionCode}}"
        class="link"
    >
      <span class="clickable">{{pool.accessionCode | accessionCode}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell
        *matCellDef="let pool"
        routerLink="/pool/{{pool.accessionCode}}"
        class="link"
    >
      <div class="icon-container">
        <nemo-pool
            [poolAccessionCode]="pool.accessionCode"
            [poolNumberOfSamples]="pool | poolSize"
            [isIcon]="true"
            height="75px"
            width="40px"
        ></nemo-pool>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef>#samples</th>
    <td mat-cell *matCellDef="let pool">
      {{pool | poolSize}}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Creation date</th>
    <td mat-cell *matCellDef="let pool">
      {{pool.creationDate | date : "medium"}}
    </td>
  </ng-container>

  <ng-container matColumnDef="requests">
    <th mat-header-cell *matHeaderCellDef>Requests</th>
    <td mat-cell *matCellDef="let pool">
      <nemo-request-card *ngFor="let request of pool.requests" [request]="request"></nemo-request-card>
    </td>
  </ng-container>

  <ng-container matColumnDef="archive">
    <th mat-header-cell *matHeaderCellDef class="table-header">
      <nemo-archive-filter tableId="pool"></nemo-archive-filter>
    </th>
    <td mat-cell *matCellDef="let pool" class="table-line">
      <nemo-switch-button [archived]="pool.archived"
                          (event)="onSwitchButtonClick(pool.accessionCode, $event)"></nemo-switch-button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      cdkDrag
      [cdkDragData]="row"
      (cdkDragEntered)="cdkDragEnteredCallback && cdkDragEnteredCallback($event)"
      (cdkDragExited)="cdkDragExitedCallback && cdkDragExitedCallback($event)"
  >
    <div *cdkDragPreview>
      <nemo-pool
        [poolAccessionCode]="row.accessionCode"
        [poolNumberOfSamples]="row | poolSize"
        [isIcon]="true"
        height="75px"
        width="40px"
      ></nemo-pool>
    </div>
  </tr>

</table>
