import { createAction, props } from '@ngrx/store';
import { Plate96 } from '../../../models/plate-96';
import { PlateCoordinates } from '../../../models/plate-coordinates';
import { PlateCoordinatesInterval } from '../../../models/plate-coordinates-interval';
import { PlateCoordinatesSelection } from '../../../models/plate-coordinates-selection';
import { BioMaterial } from '../../../../bio/models/bio-material';


/**
 * Retrieve the info for a plate from backend,
 * then initialize a Plate component here.
 */
export const findPlateByAccessionCode = createAction(
  '[Plate Details] find plate by accession code',
  props<{ accessionCode: string }>()
);

export const findPlateByAccessionCodeThenSelect = createAction(
  '[Plates Details] find plate by accession code then select',
  props<{ accessionCode: string, putPlateIdentifier: string }>()
);

export const findPlateByAccessionCodeThenSelectFromIA = createAction(
  '[Index Assignment Page] find plate by accession code then select',
  props<{ accessionCode: string, putPlateIdentifier: string }>()
);

export const findPlateByAccessionCodeThenSelectFromTaskApi = createAction(
  '[Task API] Find plate by accession code then select',
  props<{ accessionCode: string, putPlateIdentifier: string }>()
);

export const findPlateByAccessionCodeThenSelectFromPlateApi = createAction(
  '[Plate API] Find plate by accession code then select',
  props<{ accessionCode: string, putPlateIdentifier: string }>()
);

export const findPlateByAccessionCodeThenSelectFromReArray = createAction(
  '[Re Array Page] Find plate by accession code then select',
  props<{ accessionCode: string, putPlateIdentifier: string }>()
);

export const findPlateByAccessionCodeThenSelectFromEchoPooling = createAction(
  '[Echo Pooling Page] Find plate by accession code then select',
  props<{ accessionCode: string, putPlateIdentifier: string }>()
);

export const echoPoolingImport = createAction(
  '[Echo Pooling Page] Import echo pooling',
  props<{ file: {}, targetAccessionCode: string }>()
);

export const echoPoolingImportError = createAction(
  '[Echo Pooling Page] Import echo pooling error',
  props<{ message: string }>()
);


/**
 * Initialize a Plate component
 *   * * plateAccessionCode: the target widget plate accession code (e.g. index-assignment-receiver)
 * * plate: the plate sent ot put into the widget
 * * readonly: does the widget allow to be read from, or only receive update notifications
 */
export const putPlate = createAction(
  '[Plates Details] Put plate',
  props<{ plateAccessionCode: string, plate: Plate96<BioMaterial>, readonly: boolean }>()
);

export const removePlateFromIndexAssignment = createAction(
  '[Index Assignment Page] Remove plate',
  props<{ plateAccessionCode: string }>()
);

export const removePlateFromReArray = createAction(
  '[Re Array Page] Remove plate',
  props<{ plateAccessionCode: string }>()
);

export const removePlateFromEchoPooling = createAction(
  '[Echo Pooling Page] Remove plate',
  props<{ plateAccessionCode: string }>()
);

export const clearPlateSelectionFromTaskApi = createAction(
  '[Task API] Clear plate selection',
  props<{ plateAccessionCode: string }>()
);

export const emptyPlate = createAction(
  '[Plates Details] empty bio material from plate',
  props<{ plateAccessionCode: string, plateCoordinatesSelection: PlateCoordinatesSelection }>()
);

export const setPlateSelection = createAction(
  '[Plates Details] set plate selection',
  props<{ plateAccessionCode: string, plateCoordinatesSelection: PlateCoordinatesSelection }>()
);

export const updateSetSelection = createAction(
  '[Plates Details] update a selection by setting',
  props<{ plateAccessionCode: string, pos: PlateCoordinates | PlateCoordinatesInterval, clear: boolean }>()
);

export const updateUnsetSelection = createAction(
  '[Plates Details] update a selection unsetting',
  props<{ plateAccessionCode: string, pos: PlateCoordinates | PlateCoordinatesInterval }>()
);

export type PlatesDetailsActions = ReturnType<| typeof findPlateByAccessionCodeThenSelect
  | typeof findPlateByAccessionCodeThenSelectFromTaskApi
  | typeof findPlateByAccessionCodeThenSelectFromReArray
  | typeof findPlateByAccessionCodeThenSelectFromEchoPooling
  | typeof findPlateByAccessionCodeThenSelectFromPlateApi
  | typeof findPlateByAccessionCode
  | typeof removePlateFromIndexAssignment
  | typeof removePlateFromReArray
  | typeof removePlateFromEchoPooling
  | typeof putPlate
  | typeof updateSetSelection
  | typeof updateUnsetSelection
  | typeof setPlateSelection
  | typeof clearPlateSelectionFromTaskApi
  | typeof emptyPlate
  | typeof echoPoolingImportError>;
