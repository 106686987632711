import {
  FormRequestDto, FormRequestEditionDto,
  FormRequestStageDto,
  FormRequestSummaryDto,
  FormSubmissionDto
} from '../dtos/form-request.dto';
import { FormRequest, FormRequestStage, FormRequestSummary, FormSubmission } from '../models/form-request';
import { dtoToUser } from '../../user/services/user.mapper';
import { dtoToForm } from './form.mapper';

export function dtoToFormRequest(dto: FormRequestDto): FormRequest {
  return new FormRequest(
    dto.accessionCode,
    dto.title,
    dto.description,
    dto.department,
    dto.project,
    dto.study,
    dtoToUser(dto.requester),
    dto.createdAt,
    dto.assignedTo.map(dtoToUser),
    dto.stages.map(dtoToFormRequestStage),
    dto.currentOpenStageKey,
    dtoToFormSubmission(dto.submission),
    dto.archived
  );
}

export function formRequestToEditionDto(request: FormRequest): FormRequestEditionDto {
  return {
    accessionCode: request.accessionCode,
    title: request.title,
    description: request.description,
    departmentCode: request.department && request.department.code,
    projectCode: request.project && request.project.code,
    studyId: request.study && request.study.id,
    formSubmission: {
      formId: request.submission.form.id,
      values: request.submission.values
    }
  } as FormRequestEditionDto;
}

export function dtoToFormSubmission(dto: FormSubmissionDto): FormSubmission {
  return new FormSubmission(
    dto.id,
    dtoToForm(dto.form),
    dto.values
  );
}

export function dtoToFormRequestStage(dto: FormRequestStageDto): FormRequestStage {
  return new FormRequestStage(
    dto.key,
    dto.name.charAt(0).toUpperCase() + dto.name.slice(1).toLowerCase(),
    dto.completedAt,
    dto.completedBy ? dtoToUser(dto.completedBy) : null
  );
}

export function dtoToFormRequestSummary(dto: FormRequestSummaryDto): FormRequestSummary {
  return new FormRequestSummary(
    dto.accessionCode,
    dto.title,
    dto.study,
    dto.createdAt,
    dto.requester ? dtoToUser(dto.requester) : null,
    dto.assignedTo ? dto.assignedTo.map(dtoToUser) : [],
    dto.stages.map((s) => dtoToFormRequestStage(s)),
    dto.sampleCount,
    dto.archived
  );
}
