import { createAction, props } from '@ngrx/store';
import { User } from '../../../user/models/user.model';
import { FormRequest } from '../../../form/models/form-request';
import { NRequest } from '../../../genomics-request/models/genomics-request';

export const assignUserToGenomicsRequest = createAction(
  '[Genomics Request] Assign user to request',
  props<{ user: User, request: NRequest }>()
);

export const unassignUserFromGenomicsRequest = createAction(
  '[Genomics Request] Unassign user from request',
  props<{ user: User, request: NRequest }>()
);

export const assignUserToGenomicsRequestSuccess = createAction(
  '[Genomics Request API] Assign user to request success',
  props<{ request: NRequest }>()
);

export const unassignUserFromGenomicsRequestSuccess = createAction(
  '[Genomics Request API] Unassign user from request success',
  props<{ request: NRequest }>()
);

export const assignUserToFormRequest = createAction(
  '[Form Request] Assign user to request',
  props<{ accessionCode: string, user: User }>()
);

export const unassignUserFromFormRequest = createAction(
  '[Form Request] Unassign user from request',
  props<{ accessionCode: string, user: User }>()
);

export const assignUserToFormRequestSuccess = createAction(
  '[Form Request API] Assign user to request success',
  props<{ formRequest: FormRequest }>()
);

export const unassignUserFromFormRequestSuccess = createAction(
  '[Form Request API] Unassign user from request success',
  props<{ formRequest: FormRequest }>()
);

export type CommonRequestAction = ReturnType<
  | typeof assignUserToGenomicsRequest
  | typeof unassignUserFromGenomicsRequest
  | typeof assignUserToGenomicsRequestSuccess
  | typeof unassignUserFromGenomicsRequestSuccess
  | typeof assignUserToFormRequest
  | typeof unassignUserFromFormRequest
  | typeof assignUserToFormRequestSuccess
  | typeof unassignUserFromFormRequestSuccess
  >;
