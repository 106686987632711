import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSettingsComponent } from './components/form-settings/form-settings.component';
import { FormSettingsListComponent } from './components/form-settings/form-settings-list.component';
import { MaterialModule } from '../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormRequestListComponent } from '../components/form-request-list-page/form-request-list.component';
import { TableModule } from '../../table/table.module';
import { FormSettingsAddComponent } from './components/form-settings/form-settings-add.component';


@NgModule({
  declarations: [
    FormSettingsComponent,
    FormSettingsListComponent,
    FormSettingsAddComponent,
    FormRequestListComponent,
  ],
  exports: [
    FormSettingsComponent,
    FormRequestListComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
  ]
})
export class FormSharedModule {
}
