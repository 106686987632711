import { changeArchiveFilter, TableActions } from './table.actions';

export default interface TableState {
  archiveFilter: Map<string, boolean>;
}

export const initialState: TableState = {
  archiveFilter: new Map()
};

export function reducer(state = initialState, action: TableActions) {
  switch (action.type) {

    case changeArchiveFilter.type:
      const statusFilters = state.archiveFilter;
      statusFilters[action.tableId] = action.checked;
      return {...state, statusFilters};

    default:
      return state;
  }
}
