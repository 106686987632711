import { Component, Input, OnInit } from '@angular/core';
import { FlowCellApiService } from '../../../services/flow-cell-api.service';
// import { changeFlowCellToNotified } from '../../../../labware/store/flow-cells';
// import {changeFlowCellToNotifiedError, changeFlowCellToNotifiedSuccess} from '../../../../labware/store/flow-cells/flow-cells.actions'

@Component({
  selector: 'nemo-flow-cell-notify-button',
  template: `
      <button
              mat-raised-button
              color="primary"
              (click)="click()"
      >{{ buttonText }}
      </button>`,
  styles: [
    `
      button {
        margin: 10px;
      }
    `
  ]
})
export class FlowCellNotifyButtonComponent implements OnInit {
  @Input() flowCellId: string;
  @Input() status: string;
  buttonText = 'Notify Requesters';

  constructor(private readonly flowCellApiService: FlowCellApiService) {}
  ngOnInit(): void {
    if (this.status === 'NOTIFIED') {
        this.buttonText += '(sent)';
    }
  }

 click() {
   this.flowCellApiService.notify(this.flowCellId)
     .subscribe(({retCode, message}) => {
       if (retCode === 0) {
         this.status = 'NOTIFIED';
         this.buttonText = 'Notify Requesters(sent)';
       } else {
         alert(message);
       }
    });
  }
}
