import { UserRole } from '../models/user-role';
import EntityPage from '../models/entity-page';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ContextCookie } from '../models/context-cookie';
import { Lab } from '../../company/models/Lab';
import { trimAccessionCode } from '../../shared/pipes/accession-code.pipe';

const regexpEntityPageFromUrl = new RegExp('/(request|form\\-request)/([A-Z]{3}[A-Z0-9_]+)');

// a.k.a reverse routing
export function getEntityPageFromUrl(url: string): EntityPage {
  const match = url.match(regexpEntityPageFromUrl);
  return match ?
         new EntityPage(match[2]) :
         null;
}

export function getPathFromAccessionCode(accessionCode): string {
  const trimmedAccessionCode = trimAccessionCode(accessionCode);
  const prefix = trimmedAccessionCode.substring(0, 3);
  const id = trimmedAccessionCode.substring(3);
  const pathComponent = getPathComponentFromPrefix(prefix);
  if (!pathComponent) {
    return null;
  } else {
    return pathComponent + id;
  }
}

export function getPathComponentFromPrefix(prefix): string {
  const componentMap = {
    'REQ': '/request/REQ',
    'RQF': '/form-request/RQF',
    'POL': '/pool/POL',
    'PLT': '/plate/PLT',
    'PQC': '/plate-qc/PQC'
  };
  return componentMap[prefix];
}

export function getUserFilterCriteria(userRole: UserRole, id: string): object {
  switch (userRole) {
    case UserRole.SUBMITTER:
      return {requesterId: id};

    case UserRole.LABER:
      return {assignedTo: id};
  }
}


const GLOBAL_CONTEXT_COOKIE_NAME = 'global-context';

@Injectable({providedIn: 'root'})
export class GlobalContextService {
  cookie = new ContextCookie();

  constructor(
    private readonly cookieService: CookieService,
  ) {
    this.cookie = JSON.parse(this.cookieService.get(GLOBAL_CONTEXT_COOKIE_NAME) || '{}') as ContextCookie;
  }

  setUserRole(role: string) {
    this.cookie.userRole = role;
    this.save();
  }

  setLab(lab: Lab) {
    this.cookie.lab = lab && lab.alias;
    this.save();
  }

  get() {
    return this.cookie;
  }

  save() {
    this.cookieService.set(GLOBAL_CONTEXT_COOKIE_NAME, JSON.stringify(this.cookie));
  }

}
