import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IndexSharedModule } from '../index/shared/index-shared.module';
import { LabWareSharedModule } from '../labware/shared/lab-ware-shared.module';
import { BioSharedModule } from '../bio/shared/bio-shared.module';
import { SharedSharedModule } from './shared/shared-shared.module';
import { RequestSharedModule } from '../request-shared/request-shared.module';
import { CompanySharedModule } from '../company/shared/company-shared.module';
import { CommonRequestSharedModule } from '../commons/common-request/shared/common-request-shared.module';

@NgModule({
  declarations: [],
  imports: [
    BioSharedModule,
    CommonModule,
    CommonRequestSharedModule,
    CompanySharedModule,
    FormsModule,
    IndexSharedModule,
    LabWareSharedModule,
    MatCheckboxModule,
    MaterialModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    RequestSharedModule,
    SharedSharedModule,
  ],
  exports: [
    BioSharedModule,
    CommonRequestSharedModule,
    CompanySharedModule,
    IndexSharedModule,
    LabWareSharedModule,
    RequestSharedModule,
    SharedSharedModule,
  ],
})
export class SharedModule {
}
