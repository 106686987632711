import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nemo-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss'],
})
export class LoginButtonComponent implements OnInit, OnDestroy {
  private requestedUrl: string;
  private paramSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
  }

  login() {
    this.authService.login(this.requestedUrl);
  }

  ngOnInit() {
    this.paramSubscription = this.route.queryParams
      .subscribe(params => {
        this.requestedUrl = params.requestedUrl;
      });
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }
}
