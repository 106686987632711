<table mat-table [dataSource]="echoPoolings">

  <!-- Request Accession Code -->
  <ng-container matColumnDef="request_accession_code">
    <th mat-header-cell *matHeaderCellDef>Request</th>
    <td mat-cell *matCellDef="let echoPooling">
      <a *ngFor="let ac of echoPooling.requestsAccessionCode"
         routerLink="/request/{{ac}}"
         class="link-table">
        {{ac | accessionCode}}
      </a>
    </td>
  </ng-container>

  <!-- Accession code -->
  <ng-container matColumnDef="accession_code">
    <th mat-header-cell *matHeaderCellDef>Accession code</th>
    <td mat-cell *matCellDef="let echoPooling">
      <span class="clickable" routerLink="/pool/{{echoPooling.poolAccessionCode}}">
        {{echoPooling.poolAccessionCode | accessionCode}}
      </span>
    </td>
  </ng-container>

  <!-- Source Well -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>Source well</th>
    <td mat-cell *matCellDef="let echoPooling">{{echoPooling.sourceCoordinates}}</td>
  </ng-container>

  <!-- ng/uL -->
  <ng-container matColumnDef="ng/uL">
    <th mat-header-cell *matHeaderCellDef>ng/uL</th>
    <td mat-cell *matCellDef="let echoPooling">{{echoPooling.concentration}}</td>
  </ng-container>

  <!-- % Total -->
  <ng-container matColumnDef="%total">
    <th mat-header-cell *matHeaderCellDef>% Total</th>
    <td mat-cell *matCellDef="let echoPooling">{{echoPooling.concentrationPercentage}}</td>
  </ng-container>

  <!-- nmole/L -->
  <ng-container matColumnDef="nmole/L">
    <th mat-header-cell *matHeaderCellDef>nmole/L</th>
    <td mat-cell *matCellDef="let echoPooling">{{echoPooling.nmolePerL}}</td>
  </ng-container>

  <!-- nl -->
  <ng-container matColumnDef="transfer_volume">
    <th mat-header-cell *matHeaderCellDef>Transfer volume (nl)</th>
    <td mat-cell *matCellDef="let echoPooling">{{echoPooling.transferVolume}}</td>
  </ng-container>

  <!-- destination_well -->
  <ng-container matColumnDef="destination_well">
    <th mat-header-cell *matHeaderCellDef>Destination well</th>
    <td mat-cell *matCellDef="let echoPooling">{{echoPooling.targetCoordinates}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
