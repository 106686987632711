<h1 mat-dialog-title>Add a flow cell</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field class="add-flow-cell-form" appearance="outline">
      <mat-select
        formControlName="sequencerModel"
        placeholder="select the sequencer"
      >
        <mat-option
          *ngFor="let sm of (sequencerModels$ | async)"
          [value]="sm"
        >
          {{sm.name}} ({{sm.manufacturer}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="add-flow-cell-form" appearance="outline">
      <mat-select formControlName="lanesNumber"
                  placeholder="Select the type of flow cell"
                  required
      >
        <mat-option
          *ngFor="let l of form.get('sequencerModel')?.value?.availableFlowCellLanes"
          [value]="l"
        >
          {{l}} lanes
        </mat-option>
      </mat-select>
      <mat-error align="start">You need to select a type of flow cell.</mat-error>
    </mat-form-field>

    <mat-form-field class="add-flow-cell-form" appearance="outline">
      <input matInput type="text" formControlName="flowCellId" placeholder="Enter the flow cell id">
      <mat-hint align="start">The flow cell id must be unique</mat-hint>
      <mat-icon matSuffix class="valid" *ngIf="form.get('flowCellId').valid">checked</mat-icon>
    </mat-form-field>
  </form>


  <mat-error>{{errorMessage}}</mat-error>
</div>
<div mat-dialog-actions class="right-align">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" (click)="addFlowCell()" [disabled]="!this.form.valid">Add</button>
</div>
