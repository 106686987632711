import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sample } from '../../../bio/models/sample';
import * as _ from 'lodash';
import { Pool } from '../../../bio/models/pool';
import { BioMaterialPlateMapping } from '../../models/bio-material-plate-mapping';
import { PlateCoordinates } from '../../models/plate-coordinates';
import { FlexibleColumnDef, FlexiblePlainColumnDef } from '../../../table/model/flexible-columm-def';
import { TerminusTypes } from '../../../bio/models/barcode-index';
import { formatIndexSequence } from '../../../bio/pipes/samples.pipe';


class SampleWithCoordinates {
  plateCoordinates: PlateCoordinates;
  sample: Sample;
}

function sampleMetadataFlexibleColumnDef(sampleWithCoordinates: SampleWithCoordinates[]) {
  return _.chain(sampleWithCoordinates)
    .flatMap((x: SampleWithCoordinates) => _.keys(x.sample.metadata))
    .uniq()
    .map((key: string) =>
      new FlexiblePlainColumnDef<SampleWithCoordinates>(`metadata_${key}`, key, (x) => true, (x) => x.sample.metadata[key])
    )
    .value();
}

@Component({
  selector: 'nemo-plates-sample-table',
  template: `
    <nemo-flexible-table
      [values]="sampleWithCoordinates"
      [columnDefinitions]="columnDefinitions"
    ></nemo-flexible-table>
  `
})
export class PlatesSampleTableComponent implements OnChanges {
  @Input() pools: Pool[];
  sampleWithCoordinates: SampleWithCoordinates[];
  columnDefinitions: FlexibleColumnDef<SampleWithCoordinates>[] = [];

  basicColumnDefinitions = [
    new FlexiblePlainColumnDef<SampleWithCoordinates>('sample_name',
      'Sample Name',
      (x) => Boolean(x.sample),
      (x) => x.sample.name,
      {'flex-grow': 0.4}
    ),
    new FlexiblePlainColumnDef<SampleWithCoordinates>('plate_coordinates',
      'Well',
      (x) => Boolean(x.plateCoordinates),
      (x) => x.plateCoordinates.toString(),
      {'flex': '0 0 40px'},
    ),
    new FlexiblePlainColumnDef<SampleWithCoordinates>(
      'index-model',
      'index ID',
      (x) => Boolean(x.sample && x.sample.assignedIndex && x.sample.assignedIndex.model),
      (x) => x.sample.assignedIndex.model.name,
      {'flex-grow': 0.4}
    ),
    new FlexiblePlainColumnDef<SampleWithCoordinates>(
      'index-kit-model',
      'kit Model',
      (x) => Boolean(x.sample && x.sample.assignedIndex && x.sample.assignedIndex.model),
      (x) => x.sample.assignedIndex.model.kitModel.name,
      {'flex-grow': 0.8}
    ),
    new FlexiblePlainColumnDef<SampleWithCoordinates>('i7',
      'I7',
      (x) => x.sample && x.sample.hasIndexOn(TerminusTypes.I7),
      (x) => formatIndexSequence(x.sample,
        TerminusTypes.I7),
      {'flex-grow': 0.8},
      null,
      ['index-sequence']
    ),
    new FlexiblePlainColumnDef<SampleWithCoordinates>('i5',
      'I5',
      (x) => x.sample && x.sample.hasIndexOn(TerminusTypes.I5),
      (x) => formatIndexSequence(x.sample,
        TerminusTypes.I5),
      {'flex-grow': 0.8},
      null,
      ['index-sequence']
    )
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pools']) {
      // we flatMap the sample from the plate pool, in order to have only one per line
      const layout: BioMaterialPlateMapping<Pool>[] = changes['pools'].currentValue;
      this.sampleWithCoordinates = this.flatMapSamples(layout);
      sampleMetadataFlexibleColumnDef(this.sampleWithCoordinates);

      // aggregate column def from the metadata
      this.columnDefinitions = [
        ...this.basicColumnDefinitions,
        ...sampleMetadataFlexibleColumnDef(this.sampleWithCoordinates)
      ];
    }
  }

  flatMapSamples(layout: BioMaterialPlateMapping<Pool>[]): SampleWithCoordinates[] {
    return _.flatMap(layout, (mapping: BioMaterialPlateMapping<Pool>) =>
      mapping.biomaterial.sampleList().map(sample => ({
          sample: sample,
          plateCoordinates: mapping.plateCoordinates
        })
      )
    );
  }
}
