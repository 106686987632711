import { Component, Input } from '@angular/core';
import { PoolWithRequest } from '../../models/pool';

@Component({
  selector: 'nemo-pool-info',
  template: `
      <p><strong>{{pool.title}}</strong></p>
      <p><strong>Created on:</strong> {{pool.creationDate | date : "medium"}}</p>
      <p><strong># samples:</strong> {{pool.size()}}</p>
  `
})
export class PoolInfoComponent {
  @Input() pool: PoolWithRequest;
}
