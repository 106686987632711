import { Component, Input, OnInit } from '@angular/core';
import { Question, QuestionMultiChoice, QuestionSampleNameOption } from '../../models/form';
import { FormGroup } from '@angular/forms';
import { SamplenameOption } from '../../models/samplename-option';

@Component({
  selector: 'nemo-submitted-form-question-samplename-option',
  template: `
    <table
      mat-table
      [dataSource]="tableValues"
    >
      <ng-container matColumnDef="option">
        <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.option}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sampleNames">
        <mat-header-cell *matHeaderCellDef>Sample names</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sampleNames.join(', ')}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="count">
        <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sampleNames.length}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
  `,
  styles: [`
             table {
               width: 100%;
             }
           `]
})

export class SubmittedFormQuestionSamplenameOptionComponent implements OnInit {
  @Input()
  question: QuestionSampleNameOption;

  @Input()
  value: { [key: string]: string };

  displayedColumns = ['option', 'sampleNames', 'count'];
  tableValues: any[];

  ngOnInit(): void {
    const sno = new SamplenameOption(this.value);
    this.tableValues = sno.groupByOption();
  }


}
