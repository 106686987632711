import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { requestRoutes } from './genomics-request/request.routes';
import { indexRoutes } from './index/index.routes';
import { labwareRoutes } from './labware/labware.routes';
import { tasksRoutes } from './tasks/tasks.routes';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';
import { todoPageRoutes } from './todo-page/todo-page.routes';
import { qcRoutes } from './qc/qc.routes';
import { bioRoutes } from './bio/bio.routes';
import { formRoutes } from './form/form-routes';
import { settingsRoutes } from './settings/settings.routes';
import { quickAccessRoutes } from './navigation/quick-access.routes';
import { reportingRoutes } from './reporting/reporting-routes';
import { companyRoutes } from './company/company.routes';

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false },  // <-- debugging purposes only)
    ),
    RouterModule.forChild(requestRoutes),
    RouterModule.forChild(qcRoutes),
    RouterModule.forChild(indexRoutes),
    RouterModule.forChild(labwareRoutes),
    RouterModule.forChild(bioRoutes),
    RouterModule.forChild(tasksRoutes),
    RouterModule.forChild(todoPageRoutes),
    RouterModule.forChild(formRoutes),
    RouterModule.forChild(settingsRoutes),
    RouterModule.forChild(companyRoutes),
    RouterModule.forChild(reportingRoutes),
    RouterModule.forChild(quickAccessRoutes),
    StoreModule.forRoot({
      router: routerReducer,
    }),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
