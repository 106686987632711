import {
  countPoolsError,
  countPoolsFromTodoPage,
  countPoolsSuccess,
  findAllPoolsByFlowCell,
  findPoolByAccessionCode,
  findPoolByAccessionCodeError,
  findPoolByAccessionCodeSuccess,
  findPools,
  PoolAction,
  poolColorReset,
  poolColorUpdateList,
  poolError,
  PoolsApiAction,
  poolsFindAll,
  poolsFindAllError,
  poolsFindAllFromTodoPage,
  poolsFindAllSuccess,
  poolsFindByFlowCellId,
  poolsFindByFlowCellIdError,
  poolsFindByFlowCellIdSuccess,
  PoolsListPageAction,
  poolsSearch,
  PoolsSearchBarAction,
  poolsSearchError,
  poolsSearchSuccess
} from './actions';
import { PoolCounts, poolCountsZero } from '../dto/bio.dto';

import { PoolWithRequest, SimplePoolWithRequest } from '../models/pool';
import * as d3 from 'd3';
import { ColorScale } from '../../shared/models/color-scale';


export default interface PoolState {
  pools: SimplePoolWithRequest[];
  colorScale: ColorScale<string>;
  pending: boolean;
  message: string;
  poolCounts: PoolCounts;
  currentPool: PoolWithRequest;
}

const poolColors: string[] = Array.from(d3.schemeSet3);
const n = 100;
for (let i = 0; i <= n; i++) {
  poolColors.push(d3.interpolatePlasma(i / n));
}

export const initialState: PoolState = {
  pools: [],
  pending: false,
  colorScale: new ColorScale<string>(
    {},
    poolColors,
    (accessionCode) => accessionCode
  ),
  message: '',
  poolCounts: poolCountsZero,
  currentPool: null,
};

export function reducer(
  state = initialState,
  action: PoolAction | PoolsApiAction | PoolsSearchBarAction | PoolsListPageAction
) {
  switch (action.type) {

    case findPoolByAccessionCode.type:
      return {
        ...state,
        pending: true,
        message: '',
        currentPool: null
      };

    case findPoolByAccessionCodeSuccess.type:
      return {
        ...state,
        pending: false,
        currentPool: action.pool
      };

    case findPools.type:
    case poolsSearch.type:
    case countPoolsFromTodoPage.type:
    case poolsFindAllFromTodoPage.type:
    case poolsFindByFlowCellId.type:
    case poolsFindAll.type:
      return {...state, pending: true, message: ''};

    case poolsSearchSuccess.type:
    case findAllPoolsByFlowCell.type:
    case poolsFindAllSuccess.type:
    case poolsFindByFlowCellIdSuccess.type:
      return {
        ...state,
        pending: false,
        pools: action.pools,
      };

    case countPoolsSuccess.type:
      return {
        ...state,
        pending: false,
        poolCounts: action.poolCounts,
      };

    case poolsSearchError.type:
    case poolsFindAllError.type:
    case countPoolsError.type:
    case poolsFindByFlowCellIdError.type:
    case findPoolByAccessionCodeError.type:
      return {...state, pending: false, message: action.message};

    case poolError.type:
      return {...state, pending: false, message: action.message};

    case poolColorReset.type:
      return {...state, colorScale: state.colorScale.reset()};

    case poolColorUpdateList.type:
      return {...state, colorScale: state.colorScale.updateRange(action.accessionCodes)};

    default:
      return state;
  }
}
