import { Component, Input } from '@angular/core';
import { FlowCellApiService } from '../../../services/flow-cell-api.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'nemo-flow-cell-export-pipeline-button',
  template: `
      <button
              mat-raised-button
              color="primary"
              (click)="click()"
      >Export to pipeline
      </button>`,
  styles: [
    `
          button {
            margin: 10px;
          }
        `
  ]
})
export class FlowCellExportPipelineButtonComponent {
  @Input() flowCellId: string;

  constructor(
    private readonly flowCellApiService: FlowCellApiService
  ) {
  }

  click() {
    this.flowCellApiService.exportPipeline(this.flowCellId, null)
      .subscribe(({filename, content}) => {
        const blob = new Blob([content], {type: 'text/csv'});
        saveAs(blob, filename);
      });
  }
}
