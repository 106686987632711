import { createAction, props } from '@ngrx/store';

export const changeSliderValue = createAction(
  '[Table] Change slider value',
  props<{ tableId: string, itemId: string | number, checked: boolean }>()
);

export const changeArchiveFilter = createAction(
  '[Table] Change checkbox value',
  props<{ tableId: string, checked: boolean }>()
);

export type TableActions = ReturnType<| typeof changeSliderValue
  | typeof changeArchiveFilter>;
