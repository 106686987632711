import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'nemo-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss']
})

export class EditableTextComponent implements OnChanges {
  @Input() label: string;
  @Input() type = 'text';
  @Input() initValue: string;
  @Input() isRequired = false;
  @Input() maxLength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() readonly = false;
  @Input() inputClass = 'nemo-editable-text';

  @Output() changeValue = new EventEmitter<String>();

  @ViewChild('input1', {static: true}) inputEl: ElementRef;

  savedValue: string;
  hasFocus = false;

  control: UntypedFormControl;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.control) {
      const validators = [];
      if (this.isRequired) {
        validators.push(Validators.required);
      }
      if (this.maxLength) {
        validators.push(Validators.maxLength(this.maxLength));
      }
      if (this.min !== undefined) {
        validators.push(Validators.min(this.min));
      }
      if (this.max !== undefined) {
        validators.push(Validators.max(this.max));
      }

      this.control = new UntypedFormControl('', validators);

    }
    if (changes.initValue) {
      this.initValue = changes.initValue.currentValue;
      this.savedValue = this.initValue;
      this.control.setValue(this.initValue);
    }
  }

  restore() {
    this.control.setValue(this.initValue);
    this.unFocus();
  }

  saveNewValue() {
    const newValue = this.control.value;
    if (newValue === this.savedValue) {
      return;
    }
    this.changeValue.emit(newValue);
    this.savedValue = newValue;
    this.unFocus();
  }

  unFocus() {
    setTimeout(() => this.inputEl.nativeElement.blur(), 0);
  }

  onBlur() {
    this.hasFocus = false;

  }

  onFocus() {
    this.hasFocus = true;
  }

}

