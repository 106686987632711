import { createAction, props } from '@ngrx/store';

export const poolsFindAll = createAction(
  '[Pools List Page] Find all pools'
);


export const poolsFindAllFromLaneAssignment = createAction(
  '[Lane Assignment] Find all pools'
);

export const poolsFindAllFromTodoPage = createAction(
  '[Todo Page] Find all pools'
);

export const countPoolsFromTodoPage = createAction(
  '[Todo Page] count pools'
);

export const poolsFindByFlowCellId = createAction(
  '[Flow Cell Viewer] Find flow cell pools by flow-cell id',
  props<{ flowCellId: string }>()
);


export type PoolsListPageAction = ReturnType<| typeof poolsFindAll
  | typeof poolsFindAllFromLaneAssignment
  | typeof poolsFindAllFromTodoPage
  | typeof countPoolsFromTodoPage
  | typeof poolsFindByFlowCellId>;
