import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LibraryMethod } from '../../../../sequencing/models/library-method';
import { FileTransferService } from '../../../../core/services/file-transfer.service';
import { IndexKitModelCreationDto } from '../../../models/index-kit-model.dto';
import { createNewIndexKitModel, getIndexKitModels } from '../../../store';

@Component({
  selector: 'nemo-index-kit-model-settings-add',
  template: `
    <div class="container" [formGroup]="formGroup">
      <div class="horizontal-section">
        <div class="indexkit-model-name">
          <h3 mat-subheader>1. Name</h3>
          <mat-form-field>
            <mat-label>Index kit model name</mat-label>
            <input
              matInput
              formControlName="name">
          </mat-form-field>
        </div>
        <div class="file-upload">
          <h3 mat-subheader>3. Indices file</h3>
          <button
            id="upload-index-file-button"
            type="button"
            mat-raised-button
            color="default"
            (click)="fileInput.click()">Select index file
          </button>
          <label for="upload-index-file-button">
            <span>{{ formGroup.value.indexFile?.filename }}</span>
          </label>
          <input hidden type="file" #fileInput (change)="onIndexFileChange($event)">
        </div>
        <div class="submit-new-model">
          <h3 mat-subheader>4. Add the index kit model</h3>
          <button
            id="submit-index-kit-model-button"
            mat-raised-button
            color="primary"
            [disabled]="!formGroup.valid"
            (click)="submit()"
          >Add
          </button>
        </div>
      </div>
      <div class="horizontal-section">
        <div class="library-methods">
          <h3 mat-subheader>2. Library methods</h3>
          <mat-selection-list formControlName="libraryMethodIds">
            <mat-list-option
              *ngFor="let libraryMethod of libraryMethods"
              checkboxPosition="before"
              [value]="libraryMethod.id">{{libraryMethod.name}}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: row;
    }

    div.horizontal-section {
      display: flex;
      flex-direction: column;
    }

    mat-form-field {
      width: 40em;
      margin-right: 1em;
    }

    .file-upload {
      margin: 15px 0;
    }

    .file-upload label {
      margin: 0 10px;
    }

    .submit-new-model {
      margin: 15px 0;
    }

  `]
})
export class IndexKitModelSettingsAddComponent implements OnInit, OnDestroy {
  @Input() libraryMethods: LibraryMethod[];
  listSubscription: Subscription;
  formGroup: UntypedFormGroup;

  constructor(
    private readonly store: Store<AppState>,
    private readonly fileTransferService: FileTransferService
  ) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required
      ]),
      libraryMethodIds: new UntypedFormControl('', [
        Validators.required
      ]),
      indexFile: new UntypedFormControl('', [
        Validators.required
      ])
    });
    this.listSubscription = this.store.
      select((getIndexKitModels)
    ).subscribe(() => this.formGroup.reset());
  }

  async onIndexFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const b64 = await this.fileTransferService.readFileAsDataURL(file);
      this.formGroup.patchValue({
        indexFile: {
          file: b64,
          filename: file.name,
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.listSubscription.unsubscribe();
  }

  submit() {
    const indexKitModel: IndexKitModelCreationDto = this.formGroup.value;
    this.store.dispatch(createNewIndexKitModel({indexKitModel}));
  }
}
