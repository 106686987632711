import { Component, OnDestroy, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NRequest } from '../../models/genomics-request';
import { SampleNor } from '../../../labware/models/sample-nor';
import { selectAllowedUsersForReport, selectLoggedInUserEmail } from '../../../user/store';
import {
  findRequestByAccessionCodeFromRequestPage,
  findRequestByAccessionCodePending,
  selectGenomicsCurrentRequest
} from '../../store';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { Title } from '@angular/platform-browser';
import { findTaskByRequest } from '../../../tasks/store/task.action';
import { selectTasks } from '../../../tasks/store/task.selectors';
import { Task } from 'src/app/tasks/models/task.model';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationOkComponent } from '../../../shared/shared/components/dialog-confirmation-ok/dialog-confirmation-ok.component';
import { filter, map, take, defaultIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'nemo-request-view',
  template: `
    <div *ngIf="request$ | async as request">
      <nemo-request-page-title [request]="request"></nemo-request-page-title>
      <nemo-request-stages [request]="request"
                           [showNames]="true"></nemo-request-stages>
  
      <div class="request-info">
        <nemo-request-info [request]="request"></nemo-request-info>
        <hr/>
        <nemo-request-samples [request]="request" [isLaber]="isLaber$ | async"></nemo-request-samples>
      </div>
      
      <div *ngIf="sampleNors$ | async as sampleNors">
        <nemo-sample-nors-table *ngIf="(allowedUsers$ | async).includes((userEmail$ | async)) && sampleNors.length > 0"
                                [sampleNors]="sampleNors"
                                [showRequestInfo]="false">
        </nemo-sample-nors-table>
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      max-width: 1280px;
    }
  `],
})
export class RequestPageComponent
  extends HasGlobalContextUserRole
  implements OnInit, OnDestroy {

  paramSubscription: Subscription;
  request$: Observable<NRequest>;
  sampleNors$: Observable<SampleNor[]>;
  userEmail$: Observable<String>;
  allowedUsers$: Observable<string[]>;
  tasks$: Observable<Task[]>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    super(store);
  }

  ngOnInit() {
    this.tasks$ = this.store.select(selectTasks);
    this.request$ = this.store.select(selectGenomicsCurrentRequest).pipe(
      filter(req => Boolean(req))
    );

    this.loadDetail();
    this.route.queryParams.pipe(
      take(1)
    ).subscribe(queryParams => {
      if (queryParams['submitted']) {
        this.dialog.open(DialogConfirmationOkComponent, {
          width: '500px',
          data: {
            message:
              'Your request was submitted and will be handled by our team.' +
              'You can follow progress in the right pane and will be notified by emails.'
          }
        });
      }
    });

    this.sampleNors$ = this.request$.pipe(
      // Ensure `request` is not null or undefined
      filter(req => Boolean(req) && req.samples.every(sample => sample.metadata["actual_nor"] !== undefined)),
      // Map the valid `request` to `sampleNors`
      map(req => req.samples.map(sample => ({
        sampleRefAC: sample.accessionCode,
        sampleName: sample.name,
        requestAC: req.accessionCode,
        requestedNorM: sample.metadata["Requested_reads(Million)"],
        actualNor: sample.metadata["actual_nor"],
        norHistory: sample.metadata["nor_history"],
        requestTitle: req.title,
        qcCheck: this.getNorCheckVal(sample.metadata["Requested_reads(Million)"] * 1000000, sample.metadata["actual_nor"]),
        requester: req.requester.email
      }) as SampleNor)),
      // Emit an empty array if no valid request met the conditions
      defaultIfEmpty([])
    );

    this.userEmail$ = this.store.select(selectLoggedInUserEmail).pipe(filter(userEmail => Boolean(userEmail)));
    this.allowedUsers$ = this.store.select(selectAllowedUsersForReport);
  }

  loadDetail() {
    this.paramSubscription = this.route.params.subscribe(params => {
      // Load new request may take a few seconds.
      // load an empty request first to avoid user accidentally operates on old request page
      this.store.dispatch(findRequestByAccessionCodePending());
      const accessionCode = params['accessionCode'];
      this.titleService.setTitle(`Nemo request ${formatAccessionCode(accessionCode)}`);
      this.store.dispatch(findRequestByAccessionCodeFromRequestPage({
        accessionCode: accessionCode
      }));
      this.store.dispatch(findTaskByRequest({requestAccessionCode: accessionCode}));
    });
  }

  getNorCheckVal(requestedNor, actualNor) {
    const missingActualNor = "Missing actualNor from bcl2fastq output";
    const missingRequestedNor = "Missing Requested_reads input";
    const NOR_CHECKING_RATIO = 0.8; // Set this to your required ratio

    let ret = "";
    if (requestedNor === 0) {
        ret = missingRequestedNor;
    }
    if (actualNor === 0) {
        ret += ret === "" ? missingActualNor : `, ${missingActualNor}`;
    }
    if (ret === "") {
        ret = actualNor >= requestedNor * NOR_CHECKING_RATIO ? "Pass" : "Flag";
    }
    return ret;
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }
}
