import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import UserState from './user.reducer';
import { User } from '../models/user.model';

export const getUserState = (state: AppState) => state.user;

export const selectLoggedInUser = createSelector(
  getUserState,
  (state: UserState): User | null => state.loggedIn
);

export const selectIsUserLoggedIn = createSelector(
  selectLoggedInUser,
  (user: User | null): boolean => user !== null && user.id !== null
);

export const selectLoggedInUserId = createSelector(
  selectLoggedInUser,
  (user: User | null): string | null => user?.id
);

export const selectLoggedInUserEmail = createSelector(
  selectLoggedInUser,
  (user: User | null): string | null => user?.email
);

export const selectPendingUserLoad = createSelector(
  getUserState,
  (state: UserState) => state.pendingUserLoad
);

export const selectUserFromId = (userId: string) => createSelector(
  getUserState,
  (state: UserState) => state.userDict[userId]
);

export const selectUserNameFromId = (userId: string) => createSelector(
  selectUserFromId(userId),
  (user: User | null): string => user ? user.name : ''
);

export const selectUserDictionary = createSelector(
  getUserState,
  (state: UserState) => state.userDict
);

export const selectAllUsers = createSelector(
  selectUserDictionary,
  (userDict): User[] => Object.values(userDict)
);

export const selectAllowedUsersForReport = createSelector(
  getUserState,
  (state: UserState) => state.allowedUsersForReport
);
