import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftNavbarComponent } from './components/left-navbar/left-navbar.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { NewButtonComponent } from './components/left-navbar/new-button/new-button.component';
import { RouterModule } from '@angular/router';
import { LoginModule } from '../login/login.module';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { SharedSharedModule } from '../shared/shared/shared-shared.module';
import { NavigationSharedModule } from './shared/navigation-shared.module';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { UserSharedModule } from '../user/shared/user-shared.module';
import {QuickAccessComponent} from './components/quick-access/quick-access.component';
import { NewButtonUIComponent } from './components/left-navbar/new-button/new-button-ui.component';

@NgModule({
  declarations: [
    LeftNavbarComponent,
    SearchBarComponent,
    NewButtonComponent,
    NewButtonUIComponent,
    HeaderBarComponent,
    HighlightPipe,
    QuickAccessComponent
  ],
  exports: [
    LeftNavbarComponent,
    HeaderBarComponent,
    QuickAccessComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LoginModule,
    MaterialModule,
    ReactiveFormsModule,
    CoreModule,
    SharedSharedModule,
    UserSharedModule,
    NavigationSharedModule
  ]
})
export class NavigationModule {
}
