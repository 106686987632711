import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { Sample } from '../models/sample';
import { TerminusTypes } from '../models/barcode-index';
import { FlexibleColumnDef, FlexiblePlainColumnDef } from '../../table/model/flexible-columm-def';
import { formatIndexSequence } from '../pipes/samples.pipe';

@Component({
  selector: 'nemo-pools-sample-table',
  template: `
    <nemo-flexible-table
      [values]="samples"
      [columnDefinitions]="columnDefinitions"
    ></nemo-flexible-table>
  `
})
export class PoolsSampleTableComponent implements OnInit {
  @Input() samples: Sample[];

  columnDefinitions: FlexibleColumnDef<Sample>[] = [];

  basicColumnDefinitions = [
    new FlexiblePlainColumnDef<Sample>(
      'sample_name',
      'Sample Name',
      () => true,
      (x) => x.name,
      {'flex-grow': 0.4}
    ),
    new FlexiblePlainColumnDef<Sample>(
      'index-model',
      'index ID',
      (sample) => Boolean(sample.assignedIndex.model),
      (sample) => sample.assignedIndex.model.name,
      {'flex-grow': 0.4}
    ),
    new FlexiblePlainColumnDef<Sample>(
      'index-kit-model',
      'kit Model',
      (sample) => Boolean(sample.assignedIndex.model),
      (sample) => sample.assignedIndex.model.kitModel.name,
      {'flex-grow': 1}
    ),
    new FlexiblePlainColumnDef<Sample>(
      'i7',
      'I7',
      (sample) => sample.hasIndexOn(TerminusTypes.I7),
      (sample) => formatIndexSequence(sample, TerminusTypes.I7),
      {'flex-grow': 0.4},
      null,
      ['index-sequence']
    ),
    new FlexiblePlainColumnDef<Sample>(
      'i5',
      'I5',
      (sample) => sample.hasIndexOn(TerminusTypes.I5),
      (sample) => formatIndexSequence(sample, TerminusTypes.I5),
      {'flex-grow': 0.4},
      null,
      ['index-sequence']
    ),
  ];

  ngOnInit(): void {
    this.columnDefinitions = [
      ...this.basicColumnDefinitions,
      ...this.metadataFlexibleColumnDef()
    ];
  }

  metadataFlexibleColumnDef() {
    return _.chain(this.samples)
      .flatMap((x: Sample) => _.keys(x.metadata))
      .uniq()
      .map((key: string) =>
        new FlexiblePlainColumnDef<Sample>(
          `metadata_${key}`,
          key,
          () => true,
          (x) => x.metadata[key])
      )
      .value();
  }


}
