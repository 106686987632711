import { Sample } from './sample';
import { SimpleRequest } from '../../genomics-request/models/genomics-request';
import { BioMaterial, BioMaterialTypes } from './bio-material';
import * as _ from 'lodash';
import { PlateCoordinates } from '../../labware/models/plate-coordinates';
import { FragmentAnalyzerQCMeasure } from '../../qc/models/fragment-analyzer-qc-measure';

export abstract class Pool extends BioMaterial {
  protected constructor(
    public readonly creationDate: Date,
    public readonly accessionCode?: string,
    public readonly title: string = null,
    public readonly fragmentAnalyzerQCMeasure?: FragmentAnalyzerQCMeasure,
    public readonly archived?: boolean,
  ) {
    super(accessionCode, false);
  }

  abstract size(): number;

  abstract sampleList(): Sample[];

  // unique request accession codes based on the sample list
  listRequestAccessionCode(): string[] {
    return _.chain(this.sampleList()).map('requestAccessionCode').uniq().value();
  }

  containsRequestAccessionCode(accessionCode: string): boolean {
    return this.listRequestAccessionCode().includes(accessionCode);
  }

  hasIndexedSample(): boolean {
    return this.sampleList().some((s) => s.isIndexed());
  }
}

export class PoolSamplePlaceholder extends Pool {
  readonly type = BioMaterialTypes.poolSamplePlaceholder;

  constructor(
    public readonly creationDate: Date,
    public readonly accessionCode?: string,
    public readonly title: string = null,
    public readonly sample: Sample = null,
    public readonly fragmentAnalyzerQCMeasure?: FragmentAnalyzerQCMeasure,
    public readonly archived?: boolean
  ) {
    super(creationDate, accessionCode, title, fragmentAnalyzerQCMeasure, archived);
  }

  public size(): number {
    return 1;
  }

  sampleList(): Sample[] {
    return [this.sample];
  }

  setSample(sample: Sample): PoolSamplePlaceholder {
    return new PoolSamplePlaceholder(
      this.creationDate,
      this.accessionCode,
      this.title,
      sample,
      this.fragmentAnalyzerQCMeasure,
      this.archived
    );
  }
}

export class PoolSource {
  constructor(
    public readonly accessionCode: string,
    public readonly transferVolume: number,
    public readonly coordinates: PlateCoordinates
  ) {
  }
}

/**
 * A PollSampleMix is a list of sample
 * They shall come from the same request. Although no explicit check is made.
 */
export class PoolSampleMix extends Pool {
  readonly type = BioMaterialTypes.poolSampleMix;

  constructor(
    public readonly creationDate: Date,
    public readonly accessionCode?: string,
    public readonly title: string = null,
    public readonly samples: Array<Sample> = [],
    public readonly fragmentAnalyzerQCMeasure?: FragmentAnalyzerQCMeasure,
    public readonly poolSources: Array<PoolSource> = [],
    public readonly archived?: boolean,
  ) {
    super(creationDate, accessionCode, title, fragmentAnalyzerQCMeasure, archived);
  }

  public size(): number {
    return this.samples.length;
  }

  sampleList(): Sample[] {
    return this.samples;
  }

}

/**
 * build a pool, with SimpleRequest description
 * as there are no samples, we override the size() method
 */
export class SimplePoolWithRequest extends PoolSampleMix {
  constructor(
    public readonly creationDate: Date,
    accessionCode: string,
    title: string,
    private numberOfSamples: number,
    public readonly archived: boolean,
    public requests: SimpleRequest[]
  ) {
    super(creationDate, accessionCode, title, [], null, null, archived);
  }

  size(): number {
    return this.numberOfSamples;
  }
}

export class PoolWithRequest extends Pool {
  constructor(
    public readonly accessionCode: string,
    public readonly title: string,
    public readonly creationDate: Date,
    public readonly samples: Array<Sample> = [],
    public readonly fragmentAnalyzerQCMeasure: FragmentAnalyzerQCMeasure = null,
    public readonly archived: boolean,
    public requests: SimpleRequest[]
  ) {
    super(creationDate, accessionCode, title, fragmentAnalyzerQCMeasure, archived);
  }

  readonly type: string;

  sampleList(): Sample[] {
    return this.samples;
  }

  size(): number {
    return this.samples.length;
  }
}
