import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { User } from '../../../../user/models/user.model';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'nemo-request-user-assignment-selector',
  template: `
    <mat-form-field>
      <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let user of assignedUsers"
                      [removable]="true"
                      (removed)="remove(user)">
                      {{ user.emailName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input placeholder="assign to..."
               #userInput
               [formControl]="userControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList">
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selected($event)">
        <mat-option *ngFor="let user of filteredOptions$ | async"
                    [value]="user">
          {{ user.emailName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: [`
    mat-form-field {
      width: 100%;
    }
  `]
})
export class RequestUserAssignmentSelectorComponent implements OnInit, OnChanges {
  @Input() allUsers: User[];
  @Input() assignedUsers: User[];
  @Output() removeUser = new EventEmitter<User>();
  @Output() addUser = new EventEmitter<User>();

  potentialUsers: User[];
  filteredOptions$: Observable<User[]>;

  userControl = new UntypedFormControl();

  @ViewChild('userInput', {static: true}) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;

  constructor() {
  }

  ngOnInit() {
    this.filteredOptions$ = this.userControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): User[] {
    if (!_.isString(value)) {
      return;
    }
    const filterValue = value.toLowerCase();

    if (!this.potentialUsers) {
      return [];
    }
    return this.potentialUsers.filter((optionUser: User) =>
      optionUser.emailName.toLowerCase().includes(filterValue) ||
      optionUser.name.toLowerCase().includes(filterValue)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allUsers'] || changes['assignedUsers']) {
      this._updatePotentialUsers();
    }
  }

  _updatePotentialUsers(): void {
    this.potentialUsers = _.chain(this.allUsers)
      .differenceBy(this.assignedUsers, 'id')
      .orderBy('emailName')
      .value();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const user: User = event.option.value;
    this.addUser.emit(user);

    this.userInput.nativeElement.value = '';
    this.userControl.setValue(null);
  }

  remove(user: User) {
    this.removeUser.emit(user);
  }

}
