import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { selectGenomicsContainersPreview } from '../../store';
import { Observable } from 'rxjs';
import { RequestSampleContainers } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-submission-samples',
  template: `
    <div *ngIf="(samplesContainer$ | async) as samplesContainer" class="container">
      <div *ngIf="samplesContainer.plates?.length > 0; else noPlate">
        <div class="sample-table" *ngFor="let plate of samplesContainer.plates">
          <h3>Samples</h3>
          <nemo-plates-sample-table [pools]="plate.getNonEmptyBioMaterialMappings()"></nemo-plates-sample-table>
        </div>
      </div>
      <ng-template #noPlate>
        <ng-container *ngIf="samplesContainer.pools?.length > 0">
          <div class="sample-table" *ngFor="let pool of samplesContainer.pools">
            <h3>Pool</h3>
            <nemo-pools-sample-table [samples]="pool.samples"></nemo-pools-sample-table>
          </div>
        </ng-container>
        <ng-container *ngIf="samplesContainer.tubes?.length > 0">
          <h3>{{ samplesContainer.tubes.length }} tube(s)</h3>
          <nemo-tubes-sample-table [tubes]="samplesContainer.tubes"></nemo-tubes-sample-table>
        </ng-container>
      </ng-template>
    </div>
  `,
  styles: [
    `
      div.container {
        margin-top: 1em;
      }
    `
  ]
})
export class RequestSubmissionSamplesComponent implements OnInit {

  samplesContainer$: Observable<RequestSampleContainers>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.samplesContainer$ = this.store.select(selectGenomicsContainersPreview);
  }
}
