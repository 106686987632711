import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { FormRequest } from '../../models/form-request';

@Component({
  selector: 'nemo-submitted-form',
  template: `
    <div
      class="container"
      *ngIf="formRequest"
    >
      <div>
        <p><strong>Title:</strong> {{formRequest.title}}</p>
        <p>
          <strong>Origin:</strong>
          <span *ngIf="formRequest.department" class="origin-field">
          <b>department:</b> [{{formRequest?.department.code}}] {{formRequest?.department.name}}
        </span>
          <span *ngIf=" ! formRequest?.department" class="origin-field">no department</span>
          <span *ngIf="formRequest?.project" class="origin-field">
          <b>project:</b> [{{formRequest?.project.code}}] {{formRequest?.project.name}}
        </span>
          <span *ngIf="! formRequest?.project" class="origin-field">no project</span>
          <span *ngIf="formRequest?.study" class="origin-field">
          <b>study:</b> {{formRequest?.study.name}}
        </span>
          <span *ngIf="! formRequest?.study" class="origin-field">no study</span>
        </p>
        <p><strong>Description:</strong> {{formRequest?.description}}</p>
      </div>
      <div>
        <mat-accordion
          [multi]="true"
        >
          <div *ngFor="let section of formRequest.submission.form.structure.sections">
            <nemo-submitted-form-section
              *ngIf="formRequest.submission.values[section.key]"
              [section]="section"
              [values]="formRequest.submission.values[section.key]"
              [isCompleted]="completedStageMap[section.key]"
            ></nemo-submitted-form-section>
          </div>

        </mat-accordion>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: column;
             }

             span.description {
               font-style: italic;
             }

             span.origin-field {
               margin-left: 1em;
             }
           `]
})

export class SubmittedFormComponent implements OnChanges {
  @Input()
  formRequest: FormRequest;

  @Input()
  completedStageKeys: string[];

  completedStageMap: { [key: string]: boolean } = {};

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['completedStageKeys']) {
      this.completedStageMap = {};
      _.each(this.completedStageKeys, k => this.completedStageMap[k] = true);
    }
  }

}
