import { Routes } from '@angular/router';
import { IndexAssignerPageComponent } from './index-assignment/components/index-assigner-page/index-assigner-page.component';
import { LaneAssignerPageComponent } from './lane-assignment/components/lane-assigner-page.component';
import { ReArrayPageComponent } from './re-array/components/re-array-page.component';
import { EchoPoolingPageComponent } from './echo-pooling/components/echo-pooling-page.component';
import { EchoPoolingProcessedPageComponent } from './echo-pooling/components/echo-pooling-processed-page.component';
import { AuthGuard } from '../core/auth/auth.guard';
import { PlateQcAssignerPageComponent } from './plate-qc-assignment/components/plate-qc-assigner-page/plate-qc-assigner-page.component';

export const tasksRoutes: Routes = [
  {
    path: 'tasks/lane-assign/:flowCellId',
    pathMatch: 'full',
    component: LaneAssignerPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tasks/index-assign/:plateAccessionCode',
    component: IndexAssignerPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tasks/re-array/:destinationPlateAccessionCode/:sourcePlateAccessionCode',
    component: ReArrayPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tasks/plate-qc-assign/:instrumentDirectoryAccessionCode',
    component: PlateQcAssignerPageComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'tasks/echo-pooling/:destinationPlateAccessionCode/:sourcePlateAccessionCode',
    component: EchoPoolingPageComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'tasks/echo-pooling-processed/:accessionCode',
    component: EchoPoolingProcessedPageComponent,
    canActivate: [AuthGuard]
  },
];
