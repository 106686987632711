import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PlateCoordinatesSelection } from '../../../../labware/models/plate-coordinates-selection';
import { SamplePlateIndexMapping } from '../../task-input/sample-plate-index-mapping';
import { Plate96 } from '../../../../labware/models/plate-96';
import { Index } from '../../../../bio/models/barcode-index';
import { ValidationStatusType } from '../../models/validation';
import { PoolSamplePlaceholder } from '../../../../bio/models/pool';

/**
 * This component handles the assignment of indexes to samples.
 *
 * We can see that a bit like a dedicated Angular selection component
 * interacting with the outside via assignmentChanged event
 */
@Component({
  selector: 'nemo-index-assigner',
  templateUrl: './index-assigner.component.html',
  styleUrls: ['./index-assigner.component.scss'],
})
export class IndexAssignerComponent implements OnInit, OnChanges {

  @Input() samplePlate: Plate96<PoolSamplePlaceholder>;
  @Input() indexPlate?: Plate96<Index>;

  // the selection (mouse)
  @Input() currentSelectionSamples?: PlateCoordinatesSelection;
  @Input() currentSelectionIndexes?: PlateCoordinatesSelection;

  @Output() assignmentChanged = new EventEmitter();

  samplePlateIndexMapping?: SamplePlateIndexMapping;

  // a boolean value stating if it is possible to assign index to sample
  canAssign = false;
  // an info message telling why the assign button cannot be pressed
  infoMessage: string;
  infoMessageStyle: string;

  ngOnInit(): void {
    this.setMappings(this.samplePlate);
    this.updateMappingsWithIndex(this.indexPlate);
    this.checkIfPossibleAssignment();
  }

  /**
   * D3 is out of the render loop. Redraw on changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.samplePlate) {
      this.setMappings(changes.samplePlate.currentValue);
    }
    if (changes.indexPlate) {
      this.updateMappingsWithIndex(changes.indexPlate.currentValue);
    }
    if (changes.currentSelectionSamples || changes.currentSelectionIndexes) {
      this.checkIfPossibleAssignment();
    }
  }

  /**
   * Set the value of the SamplePlateIndexMapping object we use all over the place
   */
  setMappings(samplePlate: Plate96<PoolSamplePlaceholder>) {
    if (samplePlate) {
      const prevIndexPlate = this.samplePlateIndexMapping && this.samplePlateIndexMapping.shadowIndexPlate;
      this.samplePlateIndexMapping = new SamplePlateIndexMapping(samplePlate);
      // If it already has a cached index plate, use that one
      if (prevIndexPlate) {
        this.samplePlateIndexMapping.setIndexPlate(prevIndexPlate);
      }
    }
  }

  /**
   * Add an index plate to the local SamplePlateIndexMapping
   */
  updateMappingsWithIndex(indexPlate: Plate96<Index>) {
    if (indexPlate) {
      this.samplePlateIndexMapping.setIndexPlate(indexPlate);
    }
  }

  /**
   * Based on the current selection, check if the assignment is possible
   */
  private checkIfPossibleAssignment(): void {
    if (!this.currentSelectionIndexes || this.currentSelectionIndexes.isEmpty()) {
      this.infoMessageStyle = 'error';
      this.infoMessage = null;
      this.canAssign = false;
      return;
    }

    // Disable the button if there is no mapping (yet)
    if (!this.samplePlateIndexMapping) {
      this.disableAssignButton();
      return;
      // Having no selection is valid, but we should disable the button anyway
    } else if (!this.currentSelectionIndexes || this.currentSelectionIndexes.isEmpty()) {
      this.disableAssignButton();
      return;
    }
    const validationStatus = this.samplePlateIndexMapping.validateIndexToSampleMappingFromSelection(
      this.currentSelectionIndexes,
      this.currentSelectionSamples,
    );
    switch (validationStatus.status) {
      case ValidationStatusType.OK:
        this.infoMessageStyle = 'ok';
        this.infoMessage = null;
        this.canAssign = true;
        break;
      case ValidationStatusType.WARNING:
        this.infoMessageStyle = 'warn';
        this.infoMessage = validationStatus.error.message;
        this.canAssign = true;
        break;
      case ValidationStatusType.ERROR:
        this.infoMessageStyle = 'error';
        this.infoMessage = validationStatus.error.message;
        this.canAssign = false;
        break;
    }
  }

  /**
   * Hide the assign button without any particular message
   */
  private disableAssignButton() {
    this.infoMessageStyle = 'ok';
    this.infoMessage = null;
    this.canAssign = false;
  }
}
