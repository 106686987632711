import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './components/paginator.component';
import { StatusFilterComponent } from './components/status-filter.component';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SwitchButtonComponent } from './components/switch-button.component';
import { SharedSharedModule } from '../shared/shared/shared-shared.module';
import { FlexibleTableComponent } from './components/flexible-table/flexible-table.component';
import { FlexibleExportCsvComponent } from './components/flexible-table/flexible-export-csv.component';
import { RouterModule } from '@angular/router';
import { FlexiblePlainCellComponent } from './components/flexible-table/flexible-plain-cell.component';
import { FlexibleHtmlCellComponent } from './components/flexible-table/flexible-html-cell.component';
import { FlexibleButtonCellComponent } from './components/flexible-table/flexible-button-cell.component';

@NgModule({
  declarations: [
    FlexibleExportCsvComponent,
    FlexibleTableComponent,
    PaginatorComponent,
    StatusFilterComponent,
    SwitchButtonComponent,
    FlexiblePlainCellComponent,
    FlexibleHtmlCellComponent,
    FlexibleButtonCellComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MatSlideToggleModule,
    RouterModule,
    SharedSharedModule
  ],
  exports: [
    FlexibleExportCsvComponent,
    FlexibleTableComponent,
    PaginatorComponent,
    StatusFilterComponent,
    SwitchButtonComponent,
  ],
})

export class TableModule {
}
