import { Component, Input } from '@angular/core';
import { SimplePlateWithRequests } from '../../../models/simple-plate';
import { AppState } from '../../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { archivePlate, unarchivePlate } from '../../../store/plates/actions/plate-list.action';
import {
  FlexibleButtonDef,
  FlexibleButtonsColumnDef,
  FlexibleHtmlColumnDef,
  FlexiblePlainColumnDef
} from '../../../../table/model/flexible-columm-def';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';
import { plateIndexingCompletionHtml } from '../plate-indexing-completion.component';
import { plateIconHtml } from '../plate-icon.component';
import { requestCardsHtml } from '../../../../request-shared/components/request-card.component';
import { TaskAvailable } from '../../../../tasks/models/task.model';
import { Router } from '@angular/router';
import { PlateSnippet } from '../../../dto/labware.dto';

@Component({
  selector: 'nemo-plate-list',
  template: `
    <nemo-flexible-table
      [columnDefinitions]="columnDefinitions"
      [values]="plates"
    ></nemo-flexible-table>
  `,
  styles: [
      `
            table.mat-mdc-table {
              width: 100%;
              position: relative;
            }

            td.mat-column-icon {
              padding: 0;
            }

            td.mat-mdc-cell.mat-column-icon:first-of-type {
              padding-left: 0;
            }

            .accession-code:hover, .plate-icon:hover {
              cursor: pointer;
            }
            `]
})
export class PlateListComponent {
  @Input()
  plates: SimplePlateWithRequests[];

  actionButtons = [
    new FlexibleButtonDef<PlateSnippet>(
      () => 'Re-array from',
      () => 'primary',
      (plate) => plate.availableForTasks.indexOf(TaskAvailable.RE_ARRAY_FROM) >= 0,
      (plate) => this.router.navigate([`/tasks/re-array/undefined/${plate.accessionCode}`])
    ),
    new FlexibleButtonDef<PlateSnippet>(
      () => 'Re-array to',
      () => 'primary',
      (plate) => plate.availableForTasks.indexOf(TaskAvailable.RE_ARRAY_TO) >= 0,
      (plate) => this.router.navigate([`/tasks/re-array/${plate.accessionCode}/undefined/`])
    ),
    new FlexibleButtonDef<PlateSnippet>(
      () => 'Echo pooling from',
      () => 'primary',
      (plate) => plate.availableForTasks.indexOf(TaskAvailable.ECHO_POOLING_FROM) >= 0,
      (plate) => this.router.navigate([`/tasks/echo-pooling/undefined/${plate.accessionCode}`])
    ),
    new FlexibleButtonDef<PlateSnippet>(
      () => 'Echo pooling to',
      () => 'primary',
      (plate) => plate.availableForTasks.indexOf(TaskAvailable.ECHO_POOLING_TO) >= 0,
      (plate) => this.router.navigate([`/tasks/echo-pooling/${plate.accessionCode}/undefined`])
    ),
    new FlexibleButtonDef<PlateSnippet>(
      () => 'See echo pooling',
      () => 'primary',
      (plate) => plate.availableForTasks.indexOf(TaskAvailable.ECHO_POOLING_READ) >= 0,
      (plate) => this.router.navigate([`/tasks/echo-pooling-processed/${plate.accessionCode}`])
    ),
    new FlexibleButtonDef<PlateSnippet>(
      () => 'Index assignment',
      () => 'primary',
      (plate) => plate.availableForTasks.indexOf(TaskAvailable.INDEX_ASSIGNMENT) >= 0,
      (plate) => this.router.navigate(['/tasks/index-assign/' + plate.accessionCode])
    ),
  ];

  archiveButtons = [
    new FlexibleButtonDef<PlateSnippet>(
      () => 'archive',
      () => 'warn',
      (plate) => !plate.archived,
      (plate) => this.archive(plate),
      true
    ),
    new FlexibleButtonDef<PlateSnippet>(
      () => 'unarchive',
      () => 'primary',
      (plate) => plate.archived,
      (plate) => this.restore(plate),
      true
    ),
  ];
  columnDefinitions = [
    new FlexibleHtmlColumnDef<PlateSnippet>(
      'icon',
      '',
      () => true,
      plateIconHtml,
      {'flex': '0 0 120px'},
      (plate) => `/plate/${plate.accessionCode}`
    ),
    new FlexiblePlainColumnDef<PlateSnippet>(
      'accession_code',
      'AC',
      () => true,
      (plate) => formatAccessionCode(plate.accessionCode),
      {'flex': '0 0 200px'},
      (plate) => `/plate/${plate.accessionCode}`
    ),
    new FlexiblePlainColumnDef<PlateSnippet>(
      'numberOfSamples',
      '# of samples',
      () => true,
      (plate) => plate.numberOfSamples.toString(),
      {'flex': '0 0 60px'},
      (plate) => `/plate/${plate.accessionCode}`
    ),
    new FlexibleHtmlColumnDef<PlateSnippet>(
      'indexed',
      'Indexed',
      () => true,
      plateIndexingCompletionHtml,
      {'flex': '0 0 92px'},
      (plate) => `/plate/${plate.accessionCode}`
    ),
    new FlexibleHtmlColumnDef<PlateSnippet>(
      'requests',
      'Requests',
      () => true,
      (plate) => requestCardsHtml(plate.requests), // TODO use router link instead of <a>
      {'flex-grow': '2'},
    ),
    new FlexibleButtonsColumnDef<PlateSnippet>(
      'actions',
      'What can I do?',
      () => true,
      () => '',
      {'flex': '0 0 220px'},
      this.actionButtons
    ),
    new FlexibleButtonsColumnDef<PlateSnippet>(
      'archive',
      'Archive',
      () => true,
      () => '',
      {'flex': '0 0 90px'},
      this.archiveButtons
    )
  ];
  displayedColumns: string[] = [
    'plate-icon', 'accessionCode', 'numberOfSamples', 'availableForTasks', 'numberOfIndexedSamples', 'requests', 'archived'
  ];

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
  }

  onSwitchButtonClick(plateAccessionCode: string, archive: boolean) {
    if (archive) {
      this.store.dispatch(archivePlate({accessionCode: plateAccessionCode}));
    } else {
      this.store.dispatch(unarchivePlate({accessionCode: plateAccessionCode}));
    }
  }

  archive(plate: PlateSnippet) {
    this.store.dispatch(archivePlate({accessionCode: plate.accessionCode}));
  }

  restore(plate: PlateSnippet) {
    this.store.dispatch(unarchivePlate({accessionCode: plate.accessionCode}));
  }
}
