import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { getPlateByIdentifier, getPlateList } from '../../../labware/store/plates';
import { Observable } from 'rxjs';
import { AppState } from '../../../store/app.reducers';
import { findAllPlatesFromIA } from '../../../labware/store/plates/actions/plate-list.action';
import { SimplePlateWithRequests } from '../../../labware/models/simple-plate';
import { Pool } from '../../../bio/models/pool';
import { BioMaterialPlateMapping } from '../../../labware/models/bio-material-plate-mapping';
import { Router } from '@angular/router';
import { trimAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'nemo-index-assigner-left-zone',
  template: `
    <nemo-select-plate [plates$]="plates$"
                       (changeSelectedPlate)="updateSelectedPlate($event)"
                       [selectedPlateAccessionCode]="selectedPlateAccessionCode$ | async"
    ></nemo-select-plate>

    <div class="plate-container">
      <nemo-plate-viewer-container plateAccessionCode="index-assign/samples"
                                   [isReadonly]="false"
                                   [colorStringFn]="colorStringFromRequestAC"
                                   [showLegend]="true"
      ></nemo-plate-viewer-container>
      <div class="text-selection">
        <nemo-well-selection-input plateAccessionCode="index-assign/samples"></nemo-well-selection-input>
      </div>
    </div>
  `,
  styles: [`
             :host {
               display: flex;
               flex-direction: column;
             }

             nemo-select-plate {
               height: 4em;
             }
           `]
})
export class IndexAssignerLeftZoneComponent implements OnInit {

  plates$: Observable<SimplePlateWithRequests[]>;

  selectedPlateAccessionCode$: Observable<string>;

  colorStringFromRequestAC = (mapping: BioMaterialPlateMapping<Pool>) =>
    mapping.biomaterial.listRequestAccessionCode().join(',')

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(findAllPlatesFromIA({ availableTask: 'INDEX_ASSIGNMENT'}));
    this.plates$ = this.store.select((getPlateList('INDEX_ASSIGNMENT')));

    this.selectedPlateAccessionCode$ = this.store.select(getPlateByIdentifier('index-assign/samples'))
      .pipe(
        
        filter((p) => Boolean(p)),
        map((p) => {
          return p.accessionCode;
        })
      );
  }

  updateSelectedPlate(accessionCode: string) {
    this.router.navigate(['/tasks/index-assign/' + trimAccessionCode(accessionCode)]);
  }
}
