import { Component, Input, OnInit } from '@angular/core';
import { BioMaterial } from '../../../../bio/models/bio-material';
import { CsvData, CsvWriterService } from '../../../../core/services/csv-writer.service';
import * as _ from 'lodash';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';
import { NRequest } from '../../../../genomics-request/models/genomics-request';
import { Plate96 } from '../../../../labware/models/plate-96';
import * as moment from 'moment';
import { BioMaterialPlateMapping } from '../../../../labware/models/bio-material-plate-mapping';
import { PoolSamplePlaceholder } from '../../../../bio/models/pool';
import {
  BioMaterialAssignedIndexI5Pipe,
  BioMaterialAssignedIndexI7Pipe
} from '../../../../labware/shared/pipe/get-occupancy.pipe';

@Component({
  selector: 'nemo-index-assignment-export-csv',
  templateUrl: './index-assignment-export-csv.component.html',
  styleUrls: ['./index-assignment-export-csv.component.scss']
})
export class IndexAssignmentExportCsvComponent implements OnInit {
  @Input() mappings: BioMaterialPlateMapping<PoolSamplePlaceholder>[];
  @Input() requests: NRequest[];
  @Input() plate: Plate96<PoolSamplePlaceholder>;

  requestAccessionCodes: string[];
  isUniqueRequest: boolean;

  constructor(
    private readonly csvWriter: CsvWriterService,
    private readonly bioMaterialAssignedIndexI5: BioMaterialAssignedIndexI5Pipe,
    private readonly bioMaterialAssignedIndexI7: BioMaterialAssignedIndexI7Pipe,
  ) {
  }

  ngOnInit() {
    this.requestAccessionCodes = _.chain(this.mappings)
      .map('biomaterial.requestAccessionCode')
      .uniqBy()
      .orderBy()
      .value();

    this.isUniqueRequest = this.requests && this.requests.length === 1;
  }

  exportFullToCsv(): void {
    const filename = `nemo-plate-${formatAccessionCode(this.plate.accessionCode)}-indexsheet.csv`;
    this.exportToCsv(this.mappings, filename);
  }

  exportRequestToCsv(request: NRequest): void {
    const filename = `nemo-request-${request.requester.emailName}-` +
      `${moment(request.createdAt).format('YYYMMDD')}-${formatAccessionCode(request.accessionCode)}-indexsheet.csv`;

    const mappings = this.mappings.filter((m) => m.biomaterial.sample.requestAccessionCode === request.accessionCode);
    this.exportToCsv(mappings, filename);
  }

  exportToCsv(nonEmptyBioMaterialMappings: BioMaterialPlateMapping<BioMaterial>[], filename): void {
    const data = nonEmptyBioMaterialMappings
      .map((mapping: BioMaterialPlateMapping<PoolSamplePlaceholder>) => {
        const sample = mapping.biomaterial.sample;
        const isIndexed = sample.isIndexed();
        return {
          request: formatAccessionCode(sample.requestAccessionCode),
          sample_name: sample.name,
          sample_accession_code: formatAccessionCode(sample.accessionCode),
          sample_position: mapping.plateCoordinates.toString(),
          index_kit_barcode: (isIndexed && sample.assignedIndex.kit.barcode) || '',
          index_id: (isIndexed && sample.assignedIndex.model.name) || '',
          i5: (isIndexed && this.bioMaterialAssignedIndexI5.transform(mapping) || ''),
          i7: (isIndexed && this.bioMaterialAssignedIndexI7.transform(mapping)) || '',
        };
      });
    const meta = {
      columnNames: ['request', 'sample_name', 'sample_accession_code', 'sample_position', 'index_kit_barcode', 'index_id', 'i5', 'i7'],
      task: 'index-assignment',
    };
    const csvData: CsvData = {meta, data};
    this.csvWriter.downloadAsCsv(csvData, filename);
  }
}
