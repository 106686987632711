import { createAction, props } from '@ngrx/store';
import { FlowCell } from '../../models/flow-cell';
import { PoolSampleMix } from '../../../bio/models/pool';
import { PageInfo } from '../../../shared/models/page.model';
import { FlowCellMapping, IndexConflictWithLaneNumber } from '../../models/flow-cell-mapping';
import { FlowCellType } from '../../../sequencing/models/flow-cell-type';
import { LoadingType } from '../../../sequencing/models/loading-type.model';
import { SampleNor } from '../../models/sample-nor';
import { RequestFastqDir } from '../../models/request-fastqdir';

export enum ActionTypes {
  UpdateOneFlowCell = '[FlowCell] Update one flowCell',
  UpdateListFlowCells = '[FlowCell] update a list of FlowCell',
  FindFlowCellByFlowCellIdThenSelect = '[FlowCell] find by flowCellId then select',
  AssignAddPoolToSelected = '[FlowCell] add a pool to the selected flow Cell',
  AssignAddPoolToSelectedSuccess = '[FlowCell] add a pool to the selected flow Cell  is possible',
  AssignAddPoolToSelectedError = '[FlowCell] add a pool to the selected flow Cell produces an error',
  AssignRemovePoolFromSelected = '[FlowCell] remove a pool from the selected flow Cell',
  AssignRemovePoolFromSelectedSuccess = '[FlowCell] remove a pool to the selected flow Cell  is possible',
  AssignRemovePoolFromSelectedError = '[FlowCell] remove a pool to the selected flow Cell produces an error',
  SelectFlowCell = '[FlowCell] select one',
  FlowCellError = '[FlowCell] Error',
}

/**
 * receives a list of flow cells and update the store accordingly.
 * For the moment, we get that as the list of all flow cells visible in the app.
 * Therefore, if a flow cells is not here anymore, it is removed
 */
export const updateListFlowCells = createAction(
  '[FlowCell] update a list of FlowCell',
  props<{ flowCells: FlowCell[] }>()
);

/**
 * Update one flow cell in the list
 */
export const updateOneFlowCell = createAction(
  '[FlowCell] Update one flowCell',
  props<{ flowCell: FlowCell }>()
)

export const findFlowCellByFlowCellIdThenSelect = createAction(
  '[FlowCell] find by flowCellId then select',
  props<{ flowCellId: string }>()
);

export const saveLaneMapping = createAction(
  '[FlowCell] save lane mapping for the selected flow cell',
  props<{ flowCell: FlowCell }>()
);

export const selectFlowCell = createAction(
  '[FlowCell] select one',
  props<{ flowCell: FlowCell }>()
);

export const assignAddPoolToSelected = createAction(
  ActionTypes.AssignAddPoolToSelected,
  props<{ pool: PoolSampleMix, laneNumber: number }>()
);

export const assignAddPoolToSelectedSuccess = createAction(
  ActionTypes.AssignAddPoolToSelectedSuccess,
  props<{ flowCell: FlowCell }>()
);

export const assignAddPoolToSelectedError = createAction(
  ActionTypes.AssignAddPoolToSelectedError,
  props<{ flowCell: FlowCell, conflicts: IndexConflictWithLaneNumber[] }>()
);

export const assignRemovePoolFromSelected = createAction(
  ActionTypes.AssignRemovePoolFromSelected,
  props<{ pool: PoolSampleMix, laneNumber: number }>()
);

export const assignRemovePoolFromSelectedSuccess = createAction(
  ActionTypes.AssignRemovePoolFromSelectedSuccess,
  props<{ flowCell: FlowCell }>()
);

export const assignRemovePoolFromSelectedError = createAction(
  ActionTypes.AssignRemovePoolFromSelectedError,
  props<{ flowCell: FlowCell, conflicts: IndexConflictWithLaneNumber[] }>()
);

export const flowCellError = createAction(
  ActionTypes.FlowCellError,
  props<{ message: string; }>()
);

export const findAllFlowCells = createAction(
  '[FlowCell Table] Find all flow cells',
  props<{ pageSize: number, page: number }>()
);

export const findAllFlowCellsFromAPI = createAction(
  '[FlowCell API] Find all flow cells',
  props<{ pageSize: number, page: number }>()
);

export const findAllFlowCellsForRequest = createAction(
  '[FlowCell API] Find all flow cells for request',
  props<{ requestAccessionCode: string }>()
);

export const findAllFlowCellsForRequestSuccess = createAction(
  '[FlowCell API] Find all flow cells for request success',
  props<{ flowCells: FlowCell[] }>()
);


export const findSampleNorsByFlowCellId = createAction(
  '[FlowCell API] find sample NORs by flowcellId',
  props<{ flowCellId: string }>()
);

export const findSampleNorsByFlowCellIdSuccess = createAction(
  '[FlowCell API] find sample NORs by flowcellId success',
  props<{ sampleNors: SampleNor[] }>()
);

export const findRequestFastqDirsByFlowCellId = createAction(
  '[FlowCell API] find request Fastq dirs by flowcellId',
  props<{ flowCellId: string }>()
);

export const findRequestFastqDirsByFlowCellIdSuccess = createAction(
  '[FlowCell API] find request Fastq dirs by flowcellId success',
    props<{ requestFastqDirs: RequestFastqDir[] }>()
);

export const findNorAdmins = createAction(
  '[FlowCell API] find NOR admins'
);

export const findNorAdminsSuccess = createAction(
  '[FlowCell API] find NOR admins success',
  props<{ norAdmins: string[] }>()
);


export const findAllFlowCellsForRequestError = createAction(
  '[FlowCell API] Find all flow cells for request error',
  props<{ message: string }>()
);

export const findAllFlowCellsFromTodoPage = createAction(
  '[Todo Page] Find all flow cells',
  props<{ pageSize: number, page: number }>()
);

export const findAllFlowCellsSuccess = createAction(
  '[FlowCell API] Find all flow cells success',
  props<{ flowCells: FlowCell[], pageInfo: PageInfo }>()
);

export const findAllFlowCellsError = createAction(
  '[FlowCell API] Find all flow cells error',
  props<{ message: string }>()
);

export const archiveFlowCell = createAction(
  '[FlowCell Table] Archive flow cell',
  props<{ flowCellId: string }>()
);

export const archiveFlowCellSuccess = createAction(
  '[FlowCell API] Archive flow cell success'
);

export const archiveFlowCellError = createAction(
  '[FlowCell API] Archive flow cell error',
  props<{ message: string }>()
);

export const unarchiveFlowCell = createAction(
  '[FlowCell] Unarchive flow cell',
  props<{ flowCellId: string }>()
);

export const unarchiveFlowCellSuccess = createAction(
  '[FlowCell API] Unarchive flow cell success'
);

export const unarchiveFlowCellError = createAction(
  '[FlowCell API] Unarchive flow cell error',
  props<{ message: string }>()
);

export const updateFlowCellField = createAction(
  '[Flow Cell Page] update flow cell field',
  props<{ flowCellId: string, field: string, value: string }>()
);

export const updateFlowCellFieldSuccess = createAction(
  '[API] update flow cell field error',
  props<{ flowCell: FlowCell }>()
);

export const updateFlowCellFieldError = createAction(
  '[API] flow cell field error',
  props<{ message: string }>()
);

export const changeFlowCellToDraft = createAction(
  '[Flow Cell Page] Change flow cell status to draft',
  props<{ flowCellId: string }>()
);

export const changeFlowCellToDraftSuccess = createAction(
  '[FlowCell API] Change flow cell status to draft success'
);

export const changeFlowCellToDraftError = createAction(
  '[FlowCell API] Change flow cell status to draft error',
  props<{ message: string }>()
);

export const laneAssignmentProcessed = createAction(
  '[Flow Cell Page] Lane assigment processed',
  props<{ flowCellId: string }>()
);

export const laneAssignmentProcessedSuccess = createAction(
  '[FlowCell API] Lane assignment processed success'
);

export const laneAssignmentProcessedError = createAction(
  '[FlowCell API] Lane assigment processed error',
  props<{ message: string }>()
);

export const changeLaneConcentrationAndOccupancy = createAction(
  '[Lane Assignment Page] Change lane occupancy',
  props<{ laneNumber: number, concentration: number, layout: FlowCellMapping[] }>()
);

export const changeGlobalConcentration = createAction(
  '[Lane Assignment Page] Change global concentration',
  props<{ concentration: number }>()
);

export const setFlowCellType = createAction(
  '[Lane Assignment Page] Set flow cell type',
  props<{ flowCellType: FlowCellType }>()
);

export const setLoadingType = createAction(
  '[Lane Assignment Page] Set loading type',
  props<{ loadingType: LoadingType }>()
);
