export class FormTypeDto {
  id: number;
  name: string;
  lab: string;
  createdAt: Date;
  archived: boolean;
}

export class CreateFormTypeDto {
  constructor(
    public readonly name: string,
    public readonly lab: string
  ) {}
}
