import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import PlateDimensions from '../../../models/plate-dimensions';
import { createNewPlateFromDialog } from '../../../store/plates/actions/plate-list.action';
import { TaskAvailable } from '../../../../tasks/models/task.model';

@Component({
  selector: 'nemo-add-plate-dialog',
  templateUrl: './add-plate-dialog.component.html',
  styleUrls: ['./add-plate-dialog.component.scss']
})
export class AddPlateDialogComponent implements OnInit {
  form: UntypedFormGroup;
  availableDimensions = [
    new PlateDimensions(8, 12),
    new PlateDimensions(16, 24),
  ];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<AddPlateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
  }

  ngOnInit() {
    this.form = this.buildForm();
  }


  close(): void {
    this.dialogRef.close();
  }

  buildForm(): UntypedFormGroup {
    const fb = this.fb;

    return fb.group({
      title: fb.control('',
        [Validators.required, Validators.maxLength(80)],
      ),
      dimensions: fb.control(this.availableDimensions[0],
        [Validators.required],
      ),
    });
  }

  addPlate() {
    if (!this.form.valid) {
      return;
    }
    this.store.dispatch(createNewPlateFromDialog({
      title: this.form.get('title').value,
      dimensions: this.form.get('dimensions').value,
      availableTask: this.data ? this.data['availableTask'] : null,
      sourceAccessionCode: this.data ? this.data['sourceAccessionCode'] : null
    }));
    this.close();
  }
}

export class Data {
  availableTask?: TaskAvailable;
  sourceAccessionCode?: string;
}
