import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { JSON_CONTENT } from '../../constants/http';

import * as moment from 'moment';

import { CreateFlowCellDto } from '../models/flow-cell.dto';
import { FlowCell, FlowCellNotificationResponse } from '../models/flow-cell';
import {
  FlowCellDto,
  flowCellDtoToFlowCell,
  flowCellToFLowCellLaneMappingDto,
  flowCellToFlowCellUpdateDto
} from './flow-cell.mapper';
import { PoolApiService } from '../../bio/services/pool-api.service';
import { Page } from '../../shared/models/page.model';
import { IndexConflictWithLaneNumber } from '../models/flow-cell-mapping';
import { SimplePoolWithRequest } from '../../bio/models/pool';
import { SimplePoolWithRequestDto } from '../../bio/dto/bio.dto';
import { simplePoolWithRequestDtoToSimplePoolWithRequest } from '../../bio/services/bio.mapper';
import { SampleNor } from '../models/sample-nor';
import { RequestFastqDir } from '../models/request-fastqdir';

export const FLOW_CELL_BASE_URI = environment.apiUrl + '/flow-cells';

@Injectable({
  providedIn: 'root'
})

export class FlowCellApiService {

  constructor(
    private readonly poolService: PoolApiService,
    private readonly http: HttpClient
  ) {
  }

  findSampleNorByFlowCellId(flowCellId: string): Observable<SampleNor[]> {
    const url = FLOW_CELL_BASE_URI + '/' + flowCellId + '/nors';
    const options = {headers: JSON_CONTENT};
    return this.http.get<SampleNor[]>(url, options);
  }

  findRequestFastqDirsByFlowCellId(flowCellId: string): Observable<RequestFastqDir[]> {
    const url = FLOW_CELL_BASE_URI + '/' + flowCellId + '/fastq-dirs';
    const options = {headers: JSON_CONTENT};
    return this.http.get<RequestFastqDir[]>(url, options);
  }

  findNorAdmins(): Observable<string[]> {
    const url = FLOW_CELL_BASE_URI + '/noradmins';
    const options = {headers: JSON_CONTENT};
    return this.http.get<string[]>(url, options);
  }


  findByFlowCellId(flowCellId): Observable<FlowCell> {
    const url = FLOW_CELL_BASE_URI + '/' + flowCellId;
    const options = {headers: JSON_CONTENT};
    return this.http.get<FlowCellDto>(url, options).pipe(
      map(flowCellDtoToFlowCell)
    );
  }

  findAll(
    pageSize: number,
    page: number,
    hideArchived?: boolean,
    requestAccessionCode?: string
  ): Observable<Page<FlowCell>> {
    const queryParams = {
      page: '' + page,
      size: '' + pageSize,
    };

    queryParams['hide_archived'] = hideArchived !== null && hideArchived !== undefined ? hideArchived : false;
    if (requestAccessionCode !== null && requestAccessionCode !== undefined) {
      queryParams['request_accession_code'] = requestAccessionCode;
    }

    return this.http.get<Page<FlowCellDto>>(FLOW_CELL_BASE_URI, {params: queryParams}).pipe(
      map((dtoPage: Page<FlowCellDto>) => {
        const content = dtoPage.content.map(flowCellDtoToFlowCell);
        return {...dtoPage, content};
      })
    );
  }

  findPoolsByFlowCellId(flowCellId: string): Observable<SimplePoolWithRequest[]> {
    return this.http.get<SimplePoolWithRequestDto[]>(FLOW_CELL_BASE_URI + '/' + flowCellId + '/pools').pipe(
      map((ps) => ps.map(simplePoolWithRequestDtoToSimplePoolWithRequest))
    );
  }

  exportPipeline(flowCellId: string, requestAccessionCode: string | null): Observable<any> {
    const queryParams = {};
    if (requestAccessionCode) {
      queryParams['request_accession_code'] = requestAccessionCode;
    }
    return this.http.get(
      FLOW_CELL_BASE_URI + '/' + flowCellId + '/pipeline-export',
      {
        headers: {},
        params: queryParams,
        responseType: 'text'
      }
    ).pipe(
      map((content: any) => {
        const filename = `nemo-flow-cell-${flowCellId}${requestAccessionCode ?
          '-' + requestAccessionCode : ''}_${moment().format('YMMDD_HHmmss')}.csv`;
        return {content, filename};
      })
    );
  }


  archive(flowCellId: string): Observable<void> {
    return this.http.delete<void>(FLOW_CELL_BASE_URI + '/' + flowCellId);
  }

  unarchive(flowCellId: string): Observable<void> {
    return this.http.post<void>(FLOW_CELL_BASE_URI + '/' + flowCellId + '/unarchive', null);
  }

  laneAssignmentProcessed(flowCellId: string): Observable<void> {
    return this.http.post<void>(FLOW_CELL_BASE_URI + '/' + flowCellId + '/lane-assignment-processed', null);
  }

  putFlowCellToDraft(flowCellId: string): Observable<void> {
    return this.http.post<void>(FLOW_CELL_BASE_URI + '/' + flowCellId + '/to-draft', null);
  }

  notify(flowCellId: string): Observable<FlowCellNotificationResponse> {
    return this.http.post<FlowCellNotificationResponse>(FLOW_CELL_BASE_URI + '/' + flowCellId + '/notify', null);
  }

  patchOneField(flowCellId: string, field: string, value: string): Observable<FlowCell> {
    return this.http.patch<FlowCellDto>(FLOW_CELL_BASE_URI + '/' + flowCellId, {[field]: value}).pipe(
      map(flowCellDtoToFlowCell)
    );
  }

  public addFlowCell(flowCell: CreateFlowCellDto): Observable<FlowCell> {
    return this.http.post<FlowCellDto>(FLOW_CELL_BASE_URI, flowCell).pipe(
      map(flowCellDtoToFlowCell)
    );
  }

  public saveMapping(flowCell: FlowCell): Observable<void> {
    return this.http.put<void>(`${FLOW_CELL_BASE_URI}/${flowCell.flowCellId}`, flowCellToFlowCellUpdateDto(flowCell));
  }

  /**
   * Makes a call to the backend to see if there are any conflict within some lanes
   * If yes (when an exception is returned - any not intercepted), it transforms the list of conflict
   * into an array of conflict indexed by the lane number
   */
  public validateMapping(flowCell: FlowCell): Observable<IndexConflictWithLaneNumber[]> {
    return this.http.post<void>(`${FLOW_CELL_BASE_URI}/${flowCell.flowCellId}/lanes/validate`, flowCellToFLowCellLaneMappingDto(flowCell))
      .pipe(
        map(() => {
          return null;
        }),
        catchError(exc => {
          const conflictByLane = [];
          exc.error.forEach((icwln) => conflictByLane[icwln.laneNumber] = icwln);
          return of(conflictByLane);
        })
      );
  }
}
