import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import { Plate96 } from '../../../labware/models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { PlateCoordinates } from '../../../labware/models/plate-coordinates';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';

export class ReArrayCheck {
  constructor(
    public readonly infoMessageStyle: string,
    public readonly infoMessage: string | null,
    public readonly canReArray: boolean
  ) {
  }
}

export function isReArrayPossible(
  destinationPlate: Plate96<BioMaterial>,
  destinationSelection: PlateCoordinatesSelection,
  sourcePlate: Plate96<BioMaterial>,
  sourceSelection: PlateCoordinatesSelection
): ReArrayCheck {
  if (isSelectionEmpty(destinationSelection)) {
    return new ReArrayCheck('error', null, false);
  }
  if (isSelectionEmpty(sourceSelection)) {
    return new ReArrayCheck('error', null, false);
  }

  if (areSelectionSizeEqual(destinationSelection, sourceSelection)) {
    return new ReArrayCheck(
      'error',
      `Source selection size (${destinationSelection.countSelected()}) is different from destination (${sourceSelection.countSelected()})`,
      false
    );
  }

  const errorMessage = checkWellsEmptyOrNot(destinationPlate, destinationSelection, sourcePlate, sourceSelection);
  if (errorMessage !== null) {
    return new ReArrayCheck('error', errorMessage, false);
  }

  return new ReArrayCheck(
    'success',
    null,
    true
  );
}

export function isUndoReArrayPossible(
  destinationPlate: Plate96<BioMaterial>,
  destinationSelection: PlateCoordinatesSelection
): ReArrayCheck {
  if (isSelectionEmpty(destinationSelection)) {
    return new ReArrayCheck('error', null, false);
  }

  const destinationPlateCoordinates = destinationSelection.listSelectedCoordinates();
  const errorMessageDestination = checkIfWellsAreNotEmpty(destinationPlateCoordinates, destinationPlate);
  if (errorMessageDestination.length > 0) {
    return new ReArrayCheck('error', null, false);
  }

  return new ReArrayCheck(
    'success',
    null,
    true
  );
}

function isSelectionEmpty(destinationSelection: PlateCoordinatesSelection): boolean {
  return !destinationSelection || destinationSelection.isEmpty();
}

function areSelectionSizeEqual(
  destinationSelection: PlateCoordinatesSelection,
  sourceSelection: PlateCoordinatesSelection
): Boolean {
  const destinationNbSample = destinationSelection.countSelected();
  const sourceNbSample = sourceSelection.countSelected();
  return destinationNbSample !== sourceNbSample;
}

function checkWellsEmptyOrNot(
  destinationPlate: Plate96<BioMaterial>,
  destinationSelection: PlateCoordinatesSelection,
  sourcePlate: Plate96<BioMaterial>,
  sourceSelection: PlateCoordinatesSelection
): string {
  const sourcePlateCoordinates = sourceSelection.listSelectedCoordinates();
  const errorMessageSource = checkIfWellsAreNotEmpty(sourcePlateCoordinates, sourcePlate);
  if (errorMessageSource.length > 0) {
    return errorMessageSource[0];
  }

  const destinationPlateCoordinates = destinationSelection.listSelectedCoordinates();
  const errorMessageDestination = checkIfWellsAreEmpty(destinationPlateCoordinates, destinationPlate);
  if (errorMessageDestination.length > 0) {
    return errorMessageDestination[0];
  }

  return null;
}

function checkIfWellsAreNotEmpty(plateCoordinates: PlateCoordinates[], plate: Plate96<BioMaterial>): Array<string> {
  return plateCoordinates
    .map(coordinates => {
      if (plate.isEmptyAt(coordinates)) {
        return `Selected well ${coordinates.toString()} on plate ${formatAccessionCode(plate.accessionCode)} is empty.`;
      }
    })
    .filter(message => message !== undefined);
}

function checkIfWellsAreEmpty(plateCoordinates: PlateCoordinates[], plate: Plate96<BioMaterial>): Array<string> {
  return plateCoordinates
    .map(coordinates => {
      if (!plate.isEmptyAt(coordinates)) {
        return `Selected well ${coordinates.toString()} on plate ${formatAccessionCode(plate.accessionCode)} is not empty.`;
      }
    })
    .filter(message => message !== undefined);
}
