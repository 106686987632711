import { NgModule } from '@angular/core';
import { CommonRequestSharedModule } from './shared/common-request-shared.module';
import { RequestCroAssignmentContainerComponent } from './components/request-cro-assignment-container/request-cro-assignment-container.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    RequestCroAssignmentContainerComponent
  ],
  imports: [
    CommonRequestSharedModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    RequestCroAssignmentContainerComponent,
  ],
})
export class CommonRequestModule {
}
