import { Component, Input, OnInit } from '@angular/core';
import { Question } from '../../models/form';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-submitted-form-question',
  template: `
    <div class="container">
      <div
        class="question-title"
      >{{question.title}}</div>
      <div
        class="question-answer"
        [ngSwitch]="question.type"
      >
        <div *ngSwitchCase="'TEXT'">
          <div class="answer">
            {{value}}
          </div>
        </div>
        <div *ngSwitchCase="'SEQUENCE'"
             class="answer">
          <nemo-submitted-form-question-sequence
            [question]="question"
            [value]="value"
          ></nemo-submitted-form-question-sequence>
        </div>
        <div *ngSwitchCase="'MULTICHOICE'"
             class="answer">
          <nemo-submitted-form-question-multiple-choice
            [question]="question"
            [value]="value"
          ></nemo-submitted-form-question-multiple-choice>
        </div>
        <div *ngSwitchCase="'SAMPLENAME_OPTION'"
             class="answer">
          <nemo-submitted-form-question-samplename-option
            [question]="question"
            [value]="value"
          ></nemo-submitted-form-question-samplename-option>
        </div>
        <div *ngSwitchCase="'SAMPLENAME_LIST'"
             class="answer">
          <nemo-submitted-form-question-samplename-list
            [question]="question"
            [value]="value"
          ></nemo-submitted-form-question-samplename-list>
        </div>
        <div *ngSwitchCase="'CHECKBOX'">
          <div
            *ngIf="value"
          >
            <mat-icon>check_box</mat-icon>
            {{question.title}}
          </div>
        </div>
        <div *ngSwitchDefault>
          ERROR: cannot render question of type {{question.type}}
        </div>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: row;
             }

             div.question-title {
               font-size: 100%;
               width: 30%;
             }

             div.question-answer {
               font-size: 100%;
               width: 70%;
             }

             div.answer {
               flex-grow: 0.7;
               font-weight: bold;
               margin-left: 1em;
               margin-top: 0.2em;
               margin-bottom: 0.2em;
             }
           `]
})

export class SubmittedFormQuestionComponent {
  @Input()
  question: Question;

  @Input()
  value: any;

}
