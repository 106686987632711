import { Page } from '../../shared/models/page.model';
import { PlateQC, PlateQCSummary } from '../models/plate-qc-measures';
import { PlateQCDto, PlateQCSummaryDto } from '../dto/qc.dto';
import { simplePlateWithRequestDtoToSimplePlateWithRequest } from '../../labware/services/plate.mapper';
import { dtoToFragmentAnalyzerQCMeasure } from './qc-measures.mapper';
import { dtoToQcDirectory, dtoToQCInstrumentFile } from './qc-file.mapper';


export function dtoToPlateQC(dto: PlateQCDto): PlateQC {
  return new PlateQC(
    dto.accessionCode,
    dto.layout.map(dtoToFragmentAnalyzerQCMeasure),
    dto.plate && simplePlateWithRequestDtoToSimplePlateWithRequest(dto.plate),
    dtoToQCInstrumentFile(dto.instrumentFile),
    dtoToQcDirectory(dto.instrumentDirectory),
    dto.availableForTasks
  );
}

export function dtoToPlateQCSummary(dto: PlateQCSummaryDto): PlateQCSummary {
  return new PlateQCSummary(
    dto.accessionCode,
    dto.instrumentName,
    dtoToQCInstrumentFile(dto.instrumentFile),
    dto.plateAccessionCode,
    dto.availableForTasks
  );
}

export function plateQCToSummary(plateQC: PlateQC): PlateQCSummary {
  return new PlateQCSummary(
    plateQC.accessionCode,
    plateQC.instrumentDirectory.instrument,
    plateQC.instrumentFile,
    plateQC.plate && plateQC.plate.accessionCode,
    plateQC.availableForTasks
  );
}

export function dtoToPageOfPlateQCSummary(pageDto: Page<PlateQCSummaryDto>): Page<PlateQCSummary> {
  const content = pageDto.content.map(dtoToPlateQCSummary);
  return {...pageDto, content};
}


