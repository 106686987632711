import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {  Store } from '@ngrx/store';

import { AppState } from '../../../../store/app.reducers';
import { FlowCell } from '../../../models/flow-cell';
import { Observable, Subscription } from 'rxjs';
import {
  assignAddPoolToSelected,
  assignRemovePoolFromSelected,
  getSelectedFlowCell,
  getSelectedFlowCellConflict
} from '../../../store/flow-cells';
import { IndexConflictWithLaneNumber, PoolWithLaneNumber } from '../../../models/flow-cell-mapping';
import { poolColorUpdateList } from '../../../../bio/store/actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'nemo-flow-cell-selected-container',
  templateUrl: './flow-cell-selected-container.component.html',
  styleUrls: ['./flow-cell-selected-container.component.scss']
})
export class FlowCellSelectedContainerComponent implements OnInit, OnDestroy {

  flowCell$: Observable<FlowCell>;
  conflict$: Observable<IndexConflictWithLaneNumber[]>;

  @Input() showFlowCellId = true;
  @Input() isReadonly = false;

  // Flow cell settings
  @Input() editSettings = false;
  @Input() settingsTooltip: string | null;
  @Output() settingsEdited = new EventEmitter<MapFlowCellLane>(); // Emit lane number

  @Output() poolColors = new EventEmitter<Map<string, string>>();

  flowCellSubscription: Subscription;

  constructor(public readonly store: Store<AppState>) {
  }

  ngOnInit() {
    this.flowCell$ = this.store
      .
        select((getSelectedFlowCell)
      );

    this.conflict$ = this.store
      .
        select((getSelectedFlowCellConflict)
      );

    this.flowCell$.subscribe((fc) => {
      if (!fc) {
        this.store.dispatch(poolColorUpdateList({accessionCodes: []}));
        return;
      }

      const pools = fc.getSimplePoolsWithRequest();
      this.store.dispatch(poolColorUpdateList({accessionCodes: pools.map(pool => pool.accessionCode)}));
    });
  }

  ngOnDestroy(): void {
    if (this.flowCellSubscription) {
      this.flowCellSubscription.unsubscribe();
    }
  }

  addPool(poolWithLaneNumber: PoolWithLaneNumber) {
    if (this.isReadonly) {
      return;
    }
    this.store.dispatch(assignAddPoolToSelected({
      pool: poolWithLaneNumber.pool,
      laneNumber: poolWithLaneNumber.laneNumber
    }));
  }

  removePool(poolWithLaneNumber: PoolWithLaneNumber) {
    if (this.isReadonly) {
      return;
    }
    this.store.dispatch(assignRemovePoolFromSelected({
      pool: poolWithLaneNumber.pool,
      laneNumber: poolWithLaneNumber.laneNumber
    }));
  }

  settingsEditedEvent(laneNumber) {
    this.flowCell$.pipe(take(1)).subscribe(flowCell =>
      this.settingsEdited.emit({flowCell, laneNumber})
    );
  }
}

export class MapFlowCellLane {
  flowCell: FlowCell;
  laneNumber: number;
}
