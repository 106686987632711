import { createSelector } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

const getFormState = (state: AppState) => state.form;

export const selectFormCurrent = createSelector(
  getFormState,
  (state) => state.currentForm
);

export const selectFormAllFormTypes = createSelector(
  getFormState,
  (state) => state.allFormTypes
);

export const selectAllFormTypesForCurrentLab = createSelector(
  getFormState,
  (state) => state.formTypesWithSectionsForCurrentLab
);
