import { createAction, props } from '@ngrx/store';
import PlateDimensions from '../../../models/plate-dimensions';
import { TaskAvailable } from '../../../../tasks/models/task.model';
import { PlateSnippet } from '../../../dto/labware.dto';

export const platesFindAll = createAction(
  '[Plate List Page] Find all plates',
  props<{ availableTask?: string }>()
);

export const findAllPlatesFromIA = createAction(
  '[Index Assignment Page] Find all plates',
  props<{ availableTask?: string }>()
);

export const findAllPlatesFromReArray = createAction(
  '[Re Array Page] Find all plates',
  props<{ availableTask?: string }>()
);

export const findAllPlatesToAssignQC = createAction(
  '[QC assign Page] Find all plates'
);

export const findAllPlatesFromAPI = createAction(
  '[Plate API] Find all plates',
  props<{ availableTask?: string }>()
);

export const findAllPlatesSnippets = createAction(
  '[Plate API] Find all plates snippets'
);
export const findAllPlatesSnippetsSuccess = createAction(
  '[Plate API] Find all plates snippets Success',
  props<{ snippets: PlateSnippet[] }>()
);

export const findAllPlatesFromEchoPooling = createAction(
  '[Echo Pooling Page] Find all plates',
  props<{ availableTask?: string }>()
);

export const platesFindAllFromTodoPage = createAction(
  '[Todo Page] Find all plates',
  props<{ availableTask?: string }>()
);

export const createNewPlateFromDialog = createAction(
  '[New Plate Dialog] create plate',
  props<{ title: string, dimensions: PlateDimensions, availableTask?: TaskAvailable, sourceAccessionCode?: string }>()
);

export const createNewEchoPoolingPlate = createAction(
  '[Echo Pooling Page] create echo pooling plate',
  props<{ title: string, dimensions: PlateDimensions }>()
);

export const createNewEchoPoolingTransferPlate = createAction(
  '[Re-array Page] create echo pooling transfer plate',
  props<{ title: string, dimensions: PlateDimensions, availableTask?: TaskAvailable, sourceAccessionCode?: string }>()
);


export const countPlatesFromTodoPage = createAction(
  '[Todo Page] count plates',
  props<{ availableTask?: string }>()
);

export const processReArray = createAction(
  '[Re Array Page] process re array',
  props<{ accessionCode: string }>()
);

export const processReArraySuccess = createAction(
  '[Plate API] process re array success',
  props<{ accessionCode: string }>()
);

export const processReArrayError = createAction(
  '[Plate API] process re array error',
  props<{ message: string }>()
);

export const processEchoPooling = createAction(
  '[Echo Pooling Page] process echo pooling',
  props<{ accessionCode: string }>()
);

export const processEchoPoolingSuccess = createAction(
  '[Plate API] process echo pooling success',
  props<{ accessionCode: string }>()
);

export const processEchoPoolingError = createAction(
  '[Plate API] process echo pooling error',
  props<{ message: string }>()
);

export const archivePlate = createAction(
  '[Plate Table] Archive plate',
  props<{ accessionCode: string }>()
);

export const archivePlateSuccess = createAction(
  '[Plate API] Archive plate success',
  props<{ plateSnippet: PlateSnippet }>()
);

export const archivePlateError = createAction(
  '[Plate API] Archive plate error',
  props<{ message: string }>()
);

export const unarchivePlate = createAction(
  '[Plate Table] Unarchive plate',
  props<{ accessionCode: string }>()
);

export const unarchivePlateSuccess = createAction(
  '[Plate API] Unarchive plate success',
  props<{ plateSnippet: PlateSnippet }>()
);

export const unarchivePlateError = createAction(
  '[Plate API] Unarchive plate error',
  props<{ message: string }>()
);

export type PlatesListActions = ReturnType<| typeof platesFindAll
  | typeof findAllPlatesFromIA
  | typeof findAllPlatesFromReArray
  | typeof findAllPlatesToAssignQC
  | typeof platesFindAllFromTodoPage
  | typeof findAllPlatesFromEchoPooling
  | typeof findAllPlatesFromAPI
  | typeof findAllPlatesSnippets
  | typeof findAllPlatesSnippetsSuccess
  | typeof platesFindAllFromTodoPage
  | typeof countPlatesFromTodoPage
  | typeof createNewPlateFromDialog
  | typeof processReArrayError
  | typeof processEchoPoolingError
  | typeof archivePlateSuccess
  | typeof unarchivePlateSuccess>;
