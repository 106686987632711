import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextualMenuButton } from '../../../model/contextual-menu-button';

@Component({
  selector: ' nemo-new-button-ui',
  template: `
    <div
      class="new-button-container"
    >
      <div
        *ngIf="singleButton"
        class="new-button-line"
      >
        <div *ngIf="singleButton.path;then link else dialog">here is ignored</div>

        <ng-template #link>
          <mat-divider *ngIf="singleButton.divider"></mat-divider>
          <a routerLink="{{singleButton.path}}">
            <button mat-button class="new-button">{{singleButton.title}}</button>
          </a>
        </ng-template>

        <ng-template #dialog>
          <mat-divider *ngIf="singleButton.divider"></mat-divider>
          <button mat-menu-item class="new-button" (click)="openDialog(singleButton.dialog, singleButton.width)">
            <span>{{singleButton.title}}</span>
          </button>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="!singleButton"
    >
      <div class="new-button-container">
        <div class="new-button-line">
          <button
            mat-button class="new-button"
            [matMenuTriggerFor]="menu"
          >
            New
          </button>
        </div>
      </div>

      <mat-menu
        #menu="matMenu"
        class="mat-menu-add"
      >
        <div *ngFor="let item of buttons">
          <div *ngIf="item.path;then link else dialog">here is ignored</div>

          <ng-template #link>
            <mat-divider *ngIf="item.divider"></mat-divider>
            <a routerLink="{{item.path}}">
              <button mat-menu-item class="menu-button">{{item.title}}</button>
            </a>
          </ng-template>

          <ng-template #dialog>
            <mat-divider *ngIf="item.divider"></mat-divider>
            <button mat-menu-item class="menu-button" (click)="openDialog(item.dialog, item.width)">
              <span>{{item.title}}</span>
            </button>
          </ng-template>
        </div>
      </mat-menu>
    </div>
  `,
  styleUrls: ['./new-button.component.scss']
})
export class NewButtonUIComponent implements OnChanges {
  @Input()
  buttons: ContextualMenuButton[];

  singleButton: ContextualMenuButton;

  constructor(
    private dialog: MatDialog
  ) {
  }

  openDialog(dialogType, width = 500): void {
    this.dialog.open(dialogType, {
      width: width + 'px'
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.singleButton = (this.buttons.length === 1) ? this.buttons[0] : null;
  }
}
