import { Component, Input } from '@angular/core';
import { ColorScale } from '../../../../shared/models/color-scale';

@Component({
  selector: 'nemo-plate-viewer-legend',
  template: `
    <div class="guide">
      <div *ngFor="let item of colorScale.map() | keyvalue"
           class="color-legend pool"
      >
        <span [style.color]="item.value" class="symbol">●</span>
        {{item.key | accessionCode}}
      </div>
    </div>
  `,
  styles: [`
    span.symbol {
      font-size: 2rem;
    }
  `]
})
export class PlateViewerLegendComponent {
  @Input() colorScale: ColorScale<Request>;
}
