import {
  completePlateQCAssignment,
  findTaskByRequestError,
  findTaskByRequestSuccess,
  TaskAction,
  validatePlateQCAssignment,
  validatePlateQCAssignmentError,
  validatePlateQCAssignmentInvalid,
  validatePlateQCAssignmentSuccess
} from './task.action';
import { Task } from '../models/task.model';
import { InvalidPlateQCInstrumentDirectoryError } from '../plate-qc-assignment/models/invalid-plate-qc-instrument-directory-error';

export interface TaskPlatQCAssignmentState {
  plateAccessionCode: string;
  instrumentDirectoryAccessionCode: string;
  error: InvalidPlateQCInstrumentDirectoryError;
}

const initialTaskPlatQCAssignmentState: TaskPlatQCAssignmentState = {
  plateAccessionCode: null,
  instrumentDirectoryAccessionCode: null,
  error: null
};

export default interface TaskState {
  tasks: Task[];
  message: string;
  plateQCAssignment: TaskPlatQCAssignmentState;
}

export const initialState: TaskState = {
  tasks: [],
  message: '',
  plateQCAssignment: initialTaskPlatQCAssignmentState
};

export function reducer(state = initialState, action: TaskAction) {
  switch (action.type) {

    case findTaskByRequestSuccess.type:
      return {...state, tasks: action.tasks};

    case findTaskByRequestError.type:
      return {...state, message: action.message};

    case validatePlateQCAssignment.type:
      return {
        ...state, plateQCAssignment: {
          ...state.plateQCAssignment,
          plateAccessionCode: action.plateAccessionCode,
          instrumentDirectoryAccessionCode: action.instrumentDirectoryAccessionCode,
          error: null
        }
      };

    case validatePlateQCAssignmentSuccess.type:
      return state;

    case validatePlateQCAssignmentInvalid.type:
      return {...state, plateQCAssignment: {...state.plateQCAssignment, plateAccessionCode: null, error: action.error}};

    case validatePlateQCAssignmentError.type:
      return {...state, plateQCAssignment: {...state.plateQCAssignment, error: null}};

    case completePlateQCAssignment.type:
      return {
        ...state, plateQCAssignment: {
          ...state.plateQCAssignment,
          plateAccessionCode: null,
          instrumentDirectoryAccessionCode: null,
          error: null
        }
      };


    default:
      return state;
  }
}
