import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { selectAllUsers } from '../../../../user/store';
import { User } from '../../../../user/models/user.model';
import {
  HasGlobalContextUserRole,
} from '../../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import {
  assignUserToFormRequest,
  assignUserToGenomicsRequest,
  unassignUserFromFormRequest,
  unassignUserFromGenomicsRequest,
} from '../../store/common-request.actions';
import { CommonRequest } from '../../models/common-request';
import { NRequest } from '../../../../genomics-request/models/genomics-request';

@Component({
  selector: 'nemo-request-user-assignment-container',
  template: `
    <nemo-request-user-assignment-selector *ngIf="isLaber$ | async"
                                           [allUsers]="allUsers$ | async"
                                           [assignedUsers]="request?.assignedTo || []"
                                           (removeUser)="removeUser($event)"
                                           (addUser)="addUser($event)">
    </nemo-request-user-assignment-selector>
    <div *ngIf="( isSubmitter$ | async )">
      <span class="user-assign">
        {{ assignedUserNames ? 'Assigned to ' + assignedUserNames : 'Not yet assigned' }}
      </span>
    </div>
  `,
  styles: [`
    span.user-assign {
      vertical-align: super;
    }
  `]
})
export class RequestUserAssignmentContainerComponent extends HasGlobalContextUserRole implements OnChanges {
  @Input() request: CommonRequest;
  allUsers$: Observable<User[]>;
  assignedUserNames: string;

  constructor(
    private store: Store<AppState>
  ) {
    super(store);
    this.allUsers$ = this.store.select(selectAllUsers);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['request']) {
      this.assignedUserNames = this.getAssignedUserNames();
    }
  }

  private getAssignedUserNames() {
    if (this.request && this.request.assignedTo) {
      return this.request.assignedTo.map(u => u.emailName).join(',');
    } else {
      return '';
    }
  }

  addUser(user: User) {
    if (this.request instanceof NRequest) {
      this.store.dispatch(assignUserToGenomicsRequest({
        request: this.request,
        user: user
      }));
    } else {
      this.store.dispatch(assignUserToFormRequest({
        accessionCode: this.request.accessionCode,
        user: user
      }));
    }
  }

  removeUser(user: User) {
    if (this.request instanceof NRequest) {
      this.store.dispatch(unassignUserFromGenomicsRequest({
        request: this.request,
        user: user
      }));
    } else {
      this.store.dispatch(unassignUserFromFormRequest({
        accessionCode: this.request.accessionCode,
        user: user
      }));
    }
  }
}
