import { createAction, props } from '@ngrx/store';
import { PageInfo } from '../../../shared/models/page.model';
import { InstrumentDirectory } from '../../models/instrumentDirectory';
import { TaskAvailable } from '../../../tasks/models/task.model';
import { PlateQC, PlateQCSummary } from '../../models/plate-qc-measures';

export const findAllPlateQC = createAction(
  '[QC List Page] Find all qc plates',
  props<{ pageSize: number, page: number, availableTask?: TaskAvailable }>()
);

export const findAllPlateQCFromTodoPage = createAction(
  '[TODO Page] Find all qc plates',
  props<{ pageSize: number, page: number, availableTask?: TaskAvailable }>()
);

export const findAllPlateQCSuccess = createAction(
  '[QC API] Find all plate QC success',
  props<{ plateQCs: PlateQCSummary[], pageInfo: PageInfo }>()
);

export const findAllPlateQCError = createAction(
  '[QC API] Find all Plate QC error',
);

export const findPlateQCByAccessionCodeFromInstrumentDirectoryPage = createAction(
  '[QC Plate] find by accession code from PlateQCPage',
  props<{ accessionCode: string }>()
);

export const findPlateQCByAccessionCodeSuccess = createAction(
  '[Plate QC API] Plate QC  by accessionCode success',
  props<{ plateQC: PlateQC }>()
);

export const findPlateQCByAccessionCodeError = createAction(
  '[Plate QC API] Find plate QC by accessionCode error',
  props<{ message: string }>()
);

export const countPlateQCFromTodoPage = createAction(
  '[Todo Page] count plate QC',
  props<{ availableTask?: string }>()
);

export const countPlateQCSuccess = createAction(
  '[QC API] Count plate QC success',
  props<{ qcCounts: number, availableTask?: string }>()
);

export const countPlateQCError = createAction(
  '[QC API] Count qc results error'
);

export type QcApiAction = ReturnType<| typeof findAllPlateQCSuccess
  | typeof countPlateQCSuccess
  | typeof findPlateQCByAccessionCodeFromInstrumentDirectoryPage
  | typeof findPlateQCByAccessionCodeSuccess
  | typeof findPlateQCByAccessionCodeError>;
