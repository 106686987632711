<div class="container"
     [formGroup]="formGroup">
  <mat-form-field
    class="full-width"
    appearance="fill"
    floatLabel="never"
  >
    <input matInput
           #title
           type="text"
           formControlName="title"
           placeholder="title (*)"
    />
    <mat-hint align="start">a short description</mat-hint>
    <mat-hint align="end">{{title.value.length}} / 80</mat-hint>
  </mat-form-field>

  <div>
    <h4>Request origin</h4>
    <div class="request-origin">
      <nemo-department-select
        [control]="formGroup.controls['departmentCode']"
      ></nemo-department-select>
      <nemo-project-select
        [control]="formGroup.controls['projectCode']"
      ></nemo-project-select>
    </div>
  </div>

  <!-- Comment -->
  <h4>Description</h4>

  <mat-form-field class="full-width" appearance="outline">
    <textarea rows="5" matInput #description type="text" formControlName="description"
              placeholder="Description"></textarea>
    <mat-hint align="start">General information on the request, special comments</mat-hint>
    <mat-hint align="end">{{description.value.length}} / 5000</mat-hint>
  </mat-form-field>

  <nemo-form-submit
    [group]="formGroup.controls['submission']"
    [initialValues]="formRequest?.formSubmission?.values"
    [startCollapsed]="startCollapsed"
    [initialSectionKey]="initialSectionKey"
  >

  </nemo-form-submit>
  <div
    class="submit-button"
  >
    <div class="submit-button-request-hint">
      <div
        *ngIf=" isFormInvalid"
      > (*) some form fields are either missing or invalid
      </div>
    </div>
    <button id="submit-request-button"
            type="submit"
            mat-raised-button
            [disabled]="!isSaveButtonEnabled"
            color="primary"
            (click)="onSubmit()"
    >
      {{submitButtonText}}
    </button>
  </div>
</div>
