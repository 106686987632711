import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageInfo } from '../../shared/models/page.model';

@Component({
  selector: 'nemo-paginator',
  template: `
    <mat-paginator *ngIf="pageInfo"
                   [length]="pageInfo.totalElements"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="changePage($event)">
    </mat-paginator>
  `
})
export class PaginatorComponent {

  @Input() pageInfo: PageInfo;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();

  pageSize = 25;
  pageSizeOptions = [25, 50, 100];

  changePage(page) {
    this.pageChange.emit([page.pageSize, page.pageIndex]);
  }
}
