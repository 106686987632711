import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { UserRole } from '../../../global-context/models/user-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';

@Component({
  selector: 'nemo-left-navbar',
  templateUrl: './left-navbar.component.html',
  styleUrls: ['./left-navbar.component.scss']
})
export class LeftNavbarComponent extends HasGlobalContextUserRole implements OnInit, OnDestroy {

  currentPath = '';
  nav = [];
  isLoggedIn: Observable<boolean>;

  routeEventsSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly authService: AuthService
  ) {
    super(store);
    this.nav = [{
      path: '/',
      title: 'Home',
      active: false,
      icon: 'home'
    }, {
      path: '/requests',
      title: 'Requests',
      active: false,
      icon: 'work',
      userRole: UserRole.LABER,
      lab: 'genomics'
    }, {
      path: '/form-requests',
      title: 'Requests',
      active: false,
      icon: 'work',
      userRole: UserRole.LABER,
      lab: '!genomics'
    }, {
      path: '/flow-cells',
      title: 'Flow cells',
      icon: 'view_weeks',
      active: false,
      userRole: UserRole.LABER,
      lab: 'genomics'
    }, {
      path: '/index-kits',
      title: 'Index kits',
      active: false,
      icon: 'layers',
      userRole: UserRole.LABER,
      lab: 'genomics'
    }, {
      path: '/studies',
      title: 'Studies',
      active: false,
      icon: 'label',
      divider: false,
    }, {
      path: '/pools',
      title: 'Pools',
      active: false,
      icon: 'grain',
      userRole: UserRole.LABER,
      lab: 'genomics',
      divider: true,
    }, {
      path: '/plates',
      title: 'Plates',
      active: false,
      icon: 'view_comfy',
      userRole: UserRole.LABER,
      lab: 'genomics'
    }, {
      path: '/qc',
      title: 'QC',
      active: false,
      icon: 'straighten',
      userRole: UserRole.LABER,
      lab: 'genomics'
    }, {
      path: '/settings',
      title: 'Settings',
      active: false,
      icon: 'settings_applications',
      userRole: UserRole.LABER,
      divider: true,
    }, {
      path: '/reporting',
      title: 'Reporting',
      active: false,
      icon: 'dashboard',
      userRole: UserRole.LABER,
    }
    ];
  }

  ngOnInit(): void {
    this.routeEventsSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url;
        if (url !== this.currentPath) {
          this.currentPath = url;
          this.nav.forEach(it => it.active = false);
          this.nav
            .filter(it => it.path === url)
            .forEach(it => it.active = true);
        }
      }
    });

    this.isLoggedIn = this.authService.isAuthenticated$;
  }

  ngOnDestroy(): void {
    if (this.routeEventsSubscription) {
      this.routeEventsSubscription.unsubscribe();
    }
  }
}
