import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as _ from 'lodash';

import { Form } from '../../models/form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormRequestEditionDto, FormSubmissionEditionDto } from '../../dtos/form-request.dto';

/**
 *
 * isVoid returns true for null, undefined, '', {}, []
 * It is almost like isFalsy, except that it returns true when given false
 */
export function isVoid(obj: any) {
  return obj === '' ||
    obj === undefined ||
    obj === null ||
    _.isEqual(obj, {}) ||
    _.isEqual(obj, []);
}

export function reduceNonvoid(obj: any) {
  if (!_.isPlainObject(obj)) {
    return isVoid(obj) ? undefined : obj;
  }
  const ret = {};
  _.each(obj, (v, k) => {
    if (v !== '' && v !== undefined && v !== null) {
      const reduced = reduceNonvoid(v);
      if (!isVoid(reduced)) {
        ret[k] = reduced;
      }
    }
  });
  if (_.isEqual(ret, {})) {
    return null;
  }
  return ret;
}

export function isEqualNonVoid(obj1: any, obj2: any) {
  return _.isEqual(
    reduceNonvoid(obj1),
    reduceNonvoid(obj2)
  );
}

@Component({
  selector: 'nemo-edit-form-request',
  templateUrl: 'edit-form-request.component.html',
  styles: [`
             div.container {
               max-width: 60em;
               display: flex;
               flex-direction: column;
             }

             div.submit-button {
               margin-top: 10px;
               width: 100%;
             }

             button[type="submit"] {
               width: 100%;
             }

             div.submit-button-request-hint {
               color: orangered;
             }

             mat-form-field {
               border-left: transparent 3px solid;
               padding-left: 5px;
             }

             mat-form-field.mat-form-field-invalid {
               border-left: orangered 3px solid;
             }
           `]
})
export class EditFormRequestComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  formRequest: FormRequestEditionDto = {} as FormRequestEditionDto;

  @Input()
  form: Form;

  @Input()
  submitButtonText = 'Submit request';

  @Input()
  startCollapsed = false;

  @Input()
  initialSectionKey: string;

  @Output()
  formSubmitted = new EventEmitter<FormRequestEditionDto>();

  @Output()
  isSavingNeeded = new EventEmitter<boolean>();

  formGroup: UntypedFormGroup;
  formRequestSavedValues: any;

  isLocalDataSaved = true;
  hasInitialSubmissionValueBeenRendered = false;
  isSaveButtonEnabled = false;
  isFormInvalid = false;

  valueChangedSubscription: Subscription;

  ngOnInit(): void {
    this.formRequestSavedValues = {
      title: this.formRequest && this.formRequest.title,
      description: this.formRequest && this.formRequest.description,
      departmentCode: this.formRequest && this.formRequest.departmentCode,
      projectCode: this.formRequest && this.formRequest.projectCode,
      studyId: this.formRequest && this.formRequest.studyId,
      submission: this.formRequest && this.formRequest.formSubmission && this.formRequest.formSubmission.values
    };
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!(this.formRequest && this.formGroup)) {
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.valueChangedSubscription) {
      this.valueChangedSubscription.unsubscribe();
    }
  }

  buildForm() {
    this.formGroup = new UntypedFormGroup(
      {
        title: new UntypedFormControl(this.formRequest && this.formRequest.title,
          [Validators.required, Validators.maxLength(80)]
        ),
        description: new UntypedFormControl(this.formRequest && this.formRequest.description,
          [Validators.maxLength(5000)]
        ),
        departmentCode: new UntypedFormControl(this.formRequest && this.formRequest.departmentCode),
        projectCode: new UntypedFormControl(this.formRequest && this.formRequest.projectCode),
        studyId: new UntypedFormControl(this.formRequest && this.formRequest.studyId),
        submission: new UntypedFormGroup({})
      }
    );

    // emit boolean to say the the fom values are changed or not
    if (this.valueChangedSubscription) {
      this.valueChangedSubscription.unsubscribe();
    }
    this.valueChangedSubscription = this.formGroup.valueChanges.pipe(
      filter((values) => {
        if (this.formRequest.formSubmission) {
          this.hasInitialSubmissionValueBeenRendered = this.hasInitialSubmissionValueBeenRendered ||
            isEqualNonVoid(values.submission, this.formRequest.formSubmission.values);
        } else {
          // there was not initial value to met
          this.hasInitialSubmissionValueBeenRendered = true;
        }
        return this.hasInitialSubmissionValueBeenRendered;
      }),
      map((values) => {
          this.isFormInvalid = this.formGroup.invalid;
          return isEqualNonVoid(values, this.formRequestSavedValues);
        }
      ),
      // filter(areCurrentValuesEqualToSavedOnes => areCurrentValuesEqualToSavedOnes !== this.isLocalDataSaved)
    ).subscribe((areCurrentValuesEqualToSavedOnes) => {
      this.isLocalDataSaved = areCurrentValuesEqualToSavedOnes;
      this.isSaveButtonEnabled = this.formGroup.valid && !this.isLocalDataSaved;
      this.isSavingNeeded.next(!areCurrentValuesEqualToSavedOnes);
    });
    this.isSavingNeeded.next(false);
  }

  onSubmit() {
    const formValue = this.formGroup.value;
    const dto = {
      accessionCode: this.formRequest.accessionCode,
      title: formValue['title'],
      description: formValue['description'],
      departmentCode: formValue['departmentCode'],
      projectCode: formValue['projectCode'],
      studyId: formValue['studyId'],
      formSubmission: {
        formId: this.form.id,
        values: formValue['submission'] || {}
      } as FormSubmissionEditionDto
    } as FormRequestEditionDto;

    this.formSubmitted.next(dto);
  }
}
