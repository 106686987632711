import { Form } from '../../models/form';
import { Action, createReducer, on } from '@ngrx/store';
import {
  actionFormEditLoadById,
  actionFormLoadAllFormTypes,
  actionFormLoadAllFormTypesSuccess,
  actionFormLoadByIdSuccess,
  actionFormLoadByTypeNameSuccess,
  actionFormSubmitLoadByTypeName,
  actionLoadAllFormTypesWithSectionsForLab,
  actionLoadAllFormTypesWithSectionsForLabSuccess
} from './form.actions';
import { FormType, FormTypeWithSections } from '../../models/form-type';

export interface FormState {
  currentForm: Form;
  allFormTypes: FormType[];
  formTypesWithSectionsForCurrentLab: FormTypeWithSections[];
}

export const initialState: FormState = {
  currentForm: null,
  allFormTypes: [],
  formTypesWithSectionsForCurrentLab: []
};

const formReducer = createReducer(
  initialState,
  on(actionFormLoadAllFormTypes,
    (state) => (
      {...state, allFormTypes: []}
    )),
  on(actionFormLoadAllFormTypesSuccess,
    (state, {formTypes}) => (
      {...state, allFormTypes: formTypes}
    )),
  on(actionFormSubmitLoadByTypeName,
    actionFormEditLoadById,
    (state) => (
      {...state, currentForm: null}
    )),
  on(actionFormLoadByTypeNameSuccess,
    actionFormLoadByIdSuccess,
    (state, {form}) => (
      {...state, currentForm: form}
    )),
  on(actionLoadAllFormTypesWithSectionsForLab,
    (state) => (
      {...state, formTypesWithSectionsForCurrentLab: []}
    )),
  on(actionLoadAllFormTypesWithSectionsForLabSuccess,
    (state, {formTypesWithSections}) => (
      {...state, formTypesWithSectionsForCurrentLab: formTypesWithSections}
    ))
);

export function reducer(state: FormState | undefined, action: Action) {
  return formReducer(state, action);
}
