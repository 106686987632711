import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';
import { platesSearch } from '../../store/plates/actions/plates-search-bar.action';

@Component({
  selector: 'nemo-plate-search',
  template: `
    <form [formGroup]="form" class="table-search-bar">
      <mat-form-field class="search">
        <input matInput placeholder="Search plates" formControlName="query">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-checkbox formControlName="showArchived">show archived</mat-checkbox>
    </form>
  `,
  styles: [`
      :host {
          display: flex;
      }

      form {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 40px;
          justify-content: flex-end;
          margin-right: 40px;

          .search {
              width: 400px;
          }
      }
  `]
})
export class PlateSearchComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(private readonly fb: UntypedFormBuilder, private store: Store<AppState>) {
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.onSearchValueChange();
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      query: this.fb.control(''),
      showArchived: this.fb.control(false)
    });
  }

  onSearchValueChange() {
    this.form.valueChanges.pipe(
      debounce(() => timer(500))
    ).subscribe(params => {
      this.store.dispatch(platesSearch({query: params['query'], showArchived: params['showArchived']}));
    });
  }

}
