import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import { RE_ARRAY_DESTINATION_PLATE_ID } from './re-array-left-zone.component';
import { RE_ARRAY_SOURCE_PLATE_ID } from './re-array-right-zone.component';
import { Plate96 } from '../../../labware/models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { getPlateByIdentifier, getRangeSelectionsForListenerId } from '../../../labware/store/plates';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nemo-re-array-page',
  template: `
    <nemo-re-array-title [accessionCode]="destinationPlateAccessionCode$ | async"></nemo-re-array-title>

    <div class="container">
      <!-- Source plate -->
      <nemo-re-array-left-zone [sourcePlateAccessionCode]="sourcePlateAccessionCode$ | async"
                               [plateAccessionCode$]="destinationPlateAccessionCode$"></nemo-re-array-left-zone>

      <!-- Middle zone with buttons and message -->
      <nemo-re-array-middle-zone [destinationSelection]="destinationSelection$ | async"
                                 [sourceSelection]="sourceSelection$ | async"
                                 [destinationPlate]="destinationPlate$ | async"
                                 [sourcePlate]="sourcePlate$ | async"></nemo-re-array-middle-zone>

      <!-- Destination plate -->
      <nemo-re-array-right-zone [destinationPlateAccessionCode]="destinationPlateAccessionCode$ | async"
                                [plateAccessionCode$]="sourcePlateAccessionCode$"></nemo-re-array-right-zone>
    </div>

    <h3>Destination plate {{destinationPlateAccessionCode$ | async | accessionCode}}</h3>
    <nemo-plate-table-detail [plate]="destinationPlate$ | async"></nemo-plate-table-detail>
  `,
  styles: [`
    .container {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }

    nemo-re-array-left-zone, nemo-re-array-right-zone {
      width: 455px;
    }
  `]
})
export class ReArrayPageComponent implements OnInit {

  sourcePlateAccessionCode$: Observable<string>;
  destinationPlateAccessionCode$: Observable<string>;
  destinationPlate$: Observable<Plate96<BioMaterial>>;
  destinationSelection$: Observable<PlateCoordinatesSelection>;
  sourcePlate$: Observable<Plate96<BioMaterial>>;
  sourceSelection$: Observable<PlateCoordinatesSelection>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(`Nemo re-array`);

    this.destinationPlateAccessionCode$ = this.route.params.pipe(
      map(params => {
        let destAC = params['destinationPlateAccessionCode'];
        if (destAC === 'undefined') {
          destAC = undefined;
        }
        this.titleService.setTitle(`Nemo re-array to ${formatAccessionCode(destAC)}`);
        return destAC;
      })
    );
    this.sourcePlateAccessionCode$ = this.route.params.pipe(
      map(params => {
        let sourceAC = params['sourcePlateAccessionCode'];
        if (sourceAC === 'undefined') {
          sourceAC = undefined;
        }
        return sourceAC;
      })
    );
    this.destinationSelection$ = this.store.select((getRangeSelectionsForListenerId(RE_ARRAY_DESTINATION_PLATE_ID)));
    this.sourceSelection$ = this.store.select((getRangeSelectionsForListenerId(RE_ARRAY_SOURCE_PLATE_ID)));
    this.destinationPlate$ = this.store.select((getPlateByIdentifier(RE_ARRAY_DESTINATION_PLATE_ID)));
    this.sourcePlate$ = this.store.select((getPlateByIdentifier(RE_ARRAY_SOURCE_PLATE_ID)));
  }
}
