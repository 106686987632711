import { Component, Input } from '@angular/core';
import { Section } from '../../models/form';

@Component({
  selector: 'nemo-submitted-form-section',
  template: `
    <div
      class="section"
    >
      <div
        class="section-title"
      >
        <div>
          {{section.title}}
        </div>
      </div>
      <div
        *ngFor="let question of section.questions"
        class="questions"
        [ngClass]="{isCompleted:isCompleted}"
      >
        <nemo-submitted-form-question
          *ngIf="values[question.key] !== undefined"
          [question]="question"
          [value]=values[question.key]
        >
        </nemo-submitted-form-question>
      </div>
    </div>

  `,
  styles: [`
             div.section-title {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               background: burlywood;
               padding: 0.3em;
               font-size: 150%;
               margin-top: 1em;
             }

             div.questions.isCompleted {
               background: #f0f0f0;
             }
           `]
})

export class SubmittedFormSectionComponent {
  @Input()
  section: Section;

  @Input()
  values: Map<string, any>;

  @Input()
  isCompleted: boolean;

}
