import { Component, Input } from '@angular/core';
import { AppState } from '../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { FlowCell } from '../../../labware/models/flow-cell';
import { setFlowCellType } from '../../../labware/store/flow-cells';

@Component({
  selector: 'nemo-edit-flow-cell-type',
  template: `
    <label id="flow-cell-type-label">Flow Cell Type:</label>
    <mat-radio-group
      (change)="typeChanged($event.value)"
      [value]="flowCell.sequencerConfiguration?.flowCellType?.id"
      aria-labelledby="flow-cell-type-label">
      <mat-radio-button class="flow-cell-type-radio-button"
                        *ngFor="let type of flowCell?.sequencerModel?.flowCellTypes"
                        [value]="type.id">
        {{type.name}}
      </mat-radio-button>
    </mat-radio-group>
  `,
  styles: [`
    :host, mat-radio-group {
      display: flex;
      flex-direction: column;
    }
  `]
})
export class EditFlowCellTypeComponent {

  @Input() flowCell: FlowCell;

  constructor(
    private store: Store<AppState>
  ) {
  }

  typeChanged(newTypeId: number) {
    const flowCellType = this.flowCell.sequencerModel.flowCellTypes.find((type) => type.id === newTypeId);
    this.store.dispatch(setFlowCellType({flowCellType}));
  }

}
