import {
  countPlateQCSuccess,
  findAllPlateQCSuccess,
  findPlateQCByAccessionCodeError, findPlateQCByAccessionCodeFromInstrumentDirectoryPage,
  findPlateQCByAccessionCodeSuccess,
  QcApiAction
} from './actions/qc-api.actions';
import { PageInfo } from '../../shared/models/page.model';
import {
  decrementPlateQCTodoCount,
  incrementPlateQCTodoCount,
  QcTasksAction,
  updatePlateQCInListSuccess,
  updatePlateQCSuccess
} from './actions/qc-tasks.action';
import { PlateQC, PlateQCSummary } from '../models/plate-qc-measures';
import { findPlateByAccessionCode } from '../../labware/store/plates';

export default interface QcState {
  currentPlateQC?: PlateQC;
  qcTodoCounts: number;
  all: PlateQCSummary[];
  pageInfo: PageInfo | null;
  message?: string;
}

export const initialState: QcState = {
  currentPlateQC: null,
  qcTodoCounts: null,
  all: [],
  pageInfo: null,
  message: null
};

export function reducer(state = initialState, action: QcApiAction | QcTasksAction) {
  switch (action.type) {

    case findPlateQCByAccessionCodeFromInstrumentDirectoryPage.type: {
      return {...state, currentPlateQC: null, message: null};
    }
    case findPlateQCByAccessionCodeSuccess.type: {
      return {...state, currentPlateQC: action.plateQC, message: null};
    }

    case findPlateQCByAccessionCodeError.type: {
      return {...state, currentPlateQC: null, message: action.message};
    }

    case findAllPlateQCSuccess.type: {
      return {...state, all: action.plateQCs, pageInfo: action.pageInfo};
    }

    case countPlateQCSuccess.type: {
      return {...state, qcTodoCounts: action.qcCounts};
    }

    case updatePlateQCInListSuccess.type: {
      const {plateQC} = action;
      const updatedList = state.all.map(d =>
        (d.accessionCode === plateQC.accessionCode) ? plateQC : d
      );
      return {...state, all: updatedList};
    }

    case updatePlateQCSuccess.type: {
      return {...state, currentPlateQC: action.plateQC, message: null};
    }

    case decrementPlateQCTodoCount.type: {
      return {...state, qcTodoCounts: state.qcTodoCounts - 1};
    }

    case incrementPlateQCTodoCount.type: {
      return {...state, qcTodoCounts: state.qcTodoCounts + 1};
    }

    default:
      return state;
  }
}
