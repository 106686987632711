import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { ActivatedRoute } from '@angular/router';
import { selectFormRequests } from '../../store/form-request/form-request.selectors';
import { FormRequestSummary } from '../../models/form-request';
import { actionSearchAllFormRequests } from '../../store/form-request';

@Component({
  selector: 'nemo-request-search-result',
  template: `
    <div class="title">
      <h2>Search results</h2>
    </div>

    <nemo-form-request-list
      [formRequests]="formRequests$ |async"
    ></nemo-form-request-list>

  `,
  styles: [`
    .title {
      padding-right: 8px;
      border-bottom: 1px solid #dadce0;
      height: 30px;
      margin: 15px 0 0 0;
      display: flex;
    }


    .title h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-variant-ligatures: no-contextual;
      font-size: 18px;
      color: #555;
      line-height: 24px;
      flex: auto;
      margin: 0;
    }

    .title button {
      line-height: 30px;
    }

  `]
})
export class FormRequestSearchResultComponent implements OnInit, OnDestroy {

  paramSubscription: Subscription;

  formRequests$: Observable<FormRequestSummary[]>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.formRequests$ = this.store.select((selectFormRequests));

    this.paramSubscription = this.route.params.subscribe(params => {
      this.store.dispatch(actionSearchAllFormRequests({
        requesterId: params['requester'],
        assignedTo: params['assigned_to'],
        q: params['q']
      }));
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }
}
