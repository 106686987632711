import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import {  Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as d3 from 'd3';

import { AppState } from '../../../../store/app.reducers';
import { SimplePoolWithRequest } from '../../../../bio/models/pool';
import { getPoolColorScale } from '../../../../bio/store';
import { ColorScale } from '../../../../shared/models/color-scale';
import { MatDialog } from '@angular/material/dialog';
import { EditOccupancyConcentrationDialogComponent } from '../edit-occupancy-concentration-dialog.component';
import { MapFlowCellLane } from '../../../../labware/shared/components/flow-cell-selected-container/flow-cell-selected-container.component';

@Component({
  selector: 'nemo-lane-assigner',
  templateUrl: './lane-assigner.component.html',
  styleUrls: ['./lane-assigner.component.scss']
})
export class LaneAssignerComponent implements OnInit, OnDestroy {

  @Input() pools: Array<SimplePoolWithRequest>;
  poolColorScale: ColorScale<string>;
  colorScaleSubscription: Subscription;

  constructor(public readonly store: Store<AppState>, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.colorScaleSubscription = this.store.
      select((getPoolColorScale)
    )
      .subscribe((scale) => this.poolColorScale = scale);
  }

  ngOnDestroy(): void {
    this.colorScaleSubscription.unsubscribe();
  }

  entered(event: CdkDragEnter<any>) {
    d3.select(event.container.element.nativeElement).classed('dropped-focus', true);
  }

  exited(event: CdkDragExit<any>) {
    d3.select(event.container.element.nativeElement).classed('dropped-focus', false);
  }

  editOccupancy(lane: MapFlowCellLane) {
    const layoutForLane = lane.flowCell.layout.filter(mapping =>
      mapping.laneNumber === lane.laneNumber
    );
    this.dialog.open(EditOccupancyConcentrationDialogComponent, {
      width: '600px',
      data: {layoutForLane, laneNumber: lane.laneNumber, concentration: lane.flowCell.concentrations[lane.laneNumber]}
    });
  }
}
