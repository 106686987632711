import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CreateIndexKitDto, SimpleIndexKit } from '../models/indexKit';
import { environment } from '../../../environments/environment';
import { Page } from '../../shared/models/page.model';
import { DetailedIndexKitDto } from '../models/index-kit.dto';
import { map } from 'rxjs/operators';
import { Index } from '../../bio/models/barcode-index';
import { Plate96 } from '../../labware/models/plate-96';
import { detailedIndexKitDtoToPlate96 } from './index-kit.mapper';

export const INDEX_KIT_BASE_URI = environment.apiUrl + '/index-kits';

@Injectable({
  providedIn: 'root',
})
export class IndexKitService {

  constructor(private readonly http: HttpClient) {
  }

  public findIndexPlateById(id: number): Observable<Plate96<Index>> {
    const url = INDEX_KIT_BASE_URI + '/' + id;
    return this.http.get<DetailedIndexKitDto>(url)
      .pipe(
        map(detailedIndexKitDtoToPlate96),
      );
  }

  findAll(pageSize: number, page: number, hideArchived?: boolean, plateAccessionCode?: string): Observable<Page<SimpleIndexKit>> {
    const queryParams = {
      page: '' + page,
      size: '' + pageSize,
    };

    queryParams['hide_archived'] = hideArchived !== null && hideArchived !== undefined ? hideArchived : false;
    if (plateAccessionCode !== null && plateAccessionCode !== undefined) {
      queryParams['plate_accessionCode'] = plateAccessionCode;
    }
    return this.http.get<Page<SimpleIndexKit>>(INDEX_KIT_BASE_URI, {params: queryParams});
  }

  findAllIndexKits(plateAccessionCode: string, hideArchived?: boolean): Observable<Page<SimpleIndexKit>> {
    return this.findAll(100, 0, hideArchived, plateAccessionCode);
  }

  addIndexKit(indexKit: CreateIndexKitDto): Observable<Plate96<Index>> {
    return this.http
      .post<DetailedIndexKitDto>(INDEX_KIT_BASE_URI, indexKit)
      .pipe(map(detailedIndexKitDtoToPlate96));
  }

  archive(id: number): Observable<any> {
    return this.http.delete(INDEX_KIT_BASE_URI + '/' + id);
  }

  unarchive(id: number): Observable<any> {
    return this.http.post(INDEX_KIT_BASE_URI + '/' + id + '/unarchive', null);
  }
}
