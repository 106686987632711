import { Component, Input, OnInit } from '@angular/core';
import { CommentEntity, CommentFile, CommentTask, CommentText } from '../../models/commentText';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'nemo-comment',
  template: `
      <div class="container"
           [class.from-me]="fromMe"
           [class.from-others]="!fromMe"
      >
          <div class="content-container">
              <div class="tongue">
                  <div class="tongue-inner"></div>
              </div>
              <div
                      *ngIf="!fromMe"
                      class="user-icon"
              >
                  <nemo-user-image [user]="user"></nemo-user-image>
              </div>
              <div class="content">
                  <nemo-comment-text-content
                          *ngIf="isText"
                          [comment]="comment"
                  ></nemo-comment-text-content>
                  <nemo-comment-file-content
                          *ngIf="isFile"
                          [comment]="comment"
                  ></nemo-comment-file-content>
                  <nemo-comment-task-content
                          *ngIf="isTask"
                          [comment]="comment"
                  ></nemo-comment-task-content>
              </div>
          </div>
          <div class="when">
              <span *ngIf="!fromMe">
                  {{userDictionary[comment.userId].emailName}} ●
              </span>
              {{comment.createdAt | commentDate}}
          </div>
      </div>
  `,
  styles: [`
                 div.container {
                     margin-bottom: 5px;
                 }

                 div.container.from-me {
                     margin-left: 20px;
                     margin-right: 4px;
                 }

                 div.container.from-others {
                     margin-left: 4px;
                     margin-right: 20px;
                 }

                 div.content-container {
                     position: relative;
                 }

                 div.content {
                     max-width: 15em;
                     padding: 5px;
                     border-bottom-left-radius: 5px;
                     border-bottom-right-radius: 5px;
                     overflow-wrap: break-word;
                 }

                 div.container.from-me div.content {
                     right: 8px;
                     margin-right: 16px;
                     float: right;
                     background-color: #D1D8DB;
                     border-top-left-radius: 5px;
                 }

                 div.container.from-others div.content {
                     left: 28px;
                     margin-left: 36px;
                     float: left;
                     background-color: white;
                     border-top-right-radius: 5px;
                 }

                 div.tongue {
                     top: 0px;
                     position: absolute;
                     overflow: hidden;
                     height: 16px;
                     width: 16px;
                 }

                 div.container.from-me div.tongue {
                     right: 0px;
                     background-color: #D1D8DB;
                 }

                 div.container.from-others div.tongue {
                     left: 20px;
                     background-color: white;
                 }

                 div.tongue-inner {
                     border-radius: 16px;
                     background-color: #ECEFF1;
                     height: 32px;
                     width: 32px;
                 }

                 div.container.from-me div.tongue-inner {
                     margin-right: -16px;
                 }

                 div.container.from-others div.tongue-inner {
                     margin-left: -16px;
                 }

                 div.container.from-others div.user-icon {
                     position: absolute;
                     left: 0px;
                     top: 3px;
                 }

                 div.when {
                     color: rgba(0, 0, 0, .54);
                     padding-top: 2px;
                     font-size: 80%;
                 }

                 div.container.from-me div.when {
                     float: right;
                     text-align: end;
                     padding-right: 16px;
                 }

                 div.container.from-others div.when {
                     text-align: start;
                     margin-left: 36px;
                 }
             `]
})
export class CommentComponent implements OnInit {
  @Input()
  comment: CommentEntity;

  @Input()
  fromMe: boolean;

  @Input()
  userDictionary: { [key: string]: User };

  user: User;
  isFile: boolean;
  isText: boolean;
  isTask: boolean;

  ngOnInit(): void {
    this.user = this.userDictionary[this.comment.userId];
    this.isText = (this.comment instanceof CommentText);
    this.isFile = (this.comment instanceof CommentFile);
    this.isTask = (this.comment instanceof CommentTask);
  }
}
