import { Component, Input, OnInit } from '@angular/core';
import {
  FlexibleButtonDef,
  FlexibleButtonsColumnDef,
  FlexibleColumnDef,
  FlexibleHtmlColumnDef,
  FlexiblePlainColumnDef
} from '../../table/model/flexible-columm-def';
import { formatAccessionCode } from '../../shared/pipes/accession-code.pipe';
import { formatDate } from '@angular/common';
import { NRequestSummary, RequestStage } from '../../genomics-request/models/genomics-request';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { TaskType } from '../../tasks/models/task.model';
import { FlexibleRowDef } from '../../table/model/flexible-row-def';
import { archiveRequestFromList, restoreRequestFromList } from '../actions/request-shared.actions';

const getCurrentStage = (stages: RequestStage[]) => {
  const completedStages = stages.filter((stage) => stage.completedAt != null);
  if (completedStages.length === 0) {
    return 'Unknown';
  } else {
    return completedStages[completedStages.length - 1].name;
  }
};

export function slimTaskList(tasks: TaskType[]): string[] {
  if (!Boolean(tasks) || tasks.length === 0) {
    return [];
  }
  if (tasks[tasks.length - 1] === TaskType.COMPLETE_REQUEST) {
    return [TaskType.COMPLETE_REQUEST.toString()];
  }

  const mirrorTask = {
    [TaskType.UNASSIGN_QC]: TaskType.ASSIGN_QC,
    [TaskType.REOPEN_REQUEST]: TaskType.COMPLETE_REQUEST,
    [TaskType.FLOW_CELL_TO_DRAFT]: TaskType.LANE_ASSIGNMENT
  };

  const taskRemoveReverse = tasks.reduce((acc, task) => {
    const iMax = acc.length - 1;
    if (iMax === -1) {
      return [task];
    }
    if (mirrorTask[task] === acc[iMax]) {
      acc.length = iMax;
      return acc;
    }
    acc.push(task);
    return acc;
  }, []);

  const countTasks = taskRemoveReverse.reduce((acc, task) => {
    const iMax = acc.length - 1;
    if (iMax === -1) {
      acc.push({task: task, count: 1});
      return acc;
    }
    if (acc[iMax].task === task) {
      acc[iMax].count += 1;
      return acc;
    }
    acc.push({task: task, count: 1});
    return acc;
  }, []);


  return countTasks.map(({task, count}) => (count === 1) ? task : `${task} x ${count}`);
}

const renderTasks = (req: NRequestSummary) => {
  const displayTaskString = slimTaskList(req.tasks.map(t => TaskType[t]));
  return '<div style="display:flex; flex-direction:column; align-items: flex-start; font-size: 70%; padding-bottom: 2px;">'
    + displayTaskString.map(taskStr => {
      const style = 'padding-left: 0.3em; padding-right: 0.3em; padding-top: 0.1em; padding-bottom: 0.1em;' +
        ' border-radius:3px;  margin-top:2px; background:mediumseagreen; color:white';
      return `<div style="${style}">${taskStr}</div>`;
    }).join('')
    + '</div>';
};


@Component({
  selector: 'nemo-genomics-request-list',
  template: `
    <nemo-archive-filter *ngIf="enableArchive" tableId="genomics-request"></nemo-archive-filter>
    <nemo-flexible-table
      [values]="requests"
      [columnDefinitions]="columnDefinitions"
      [rowDefinition]="rowDefinition"
    ></nemo-flexible-table>
  `,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
    }

    nemo-archive-filter {
      align-self: end;
      margin-right: 48px;
    }

    ::ng-deep mat-row.completed {
      background: rgba(64, 178, 115, 0.2);
    }

    ::ng-deep mat-row.archived {
      background: rgba(220, 220, 220, 0.3);
    }
  `]
})
export class RequestListComponent implements OnInit {

  @Input() requests: NRequestSummary[];
  @Input() enableArchive;
  @Input() showAssignees;
  @Input() showDescription;

  fontSizeFactor = '90%';

  rowDefinition = new FlexibleRowDef<NRequestSummary>(
    (req) => {
      let clazz = [];
      if (getCurrentStage(req.stages) === 'Completed') {
        clazz = ['completed'];
      }

      if (req.archived) {
        clazz = ['archived'];
      }

      return clazz;
    }
  );
  columnDefinitions: FlexibleColumnDef<NRequestSummary>[] = [
    new FlexiblePlainColumnDef<NRequestSummary>(
      'accessionCode',
      'Accession code',
      () => true,
      (m) => formatAccessionCode(m.accessionCode),
      {'flex': '0 0 110px', 'font-size': `${this.fontSizeFactor}`, 'padding-left': '18px'},
      (m) => `/request/${m.accessionCode}`,
      ['accession-code']
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'status',
      'Status',
      () => true,
      (m) => getCurrentStage(m.stages),
      {'flex': '0 0 74px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'libraryMethod',
      'Library method',
      () => true,
      (m) => m.libraryMethod,
      {'flex': '0 0 400px', 'font-size': `${this.fontSizeFactor}`, 'white-space': 'normal'},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'phix',
      'PhiX',
      (m) => m.phiXPercentage > 0,
      (m) => m.phiXPercentage + ' %',
      {'flex': '0 0 48px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'numberOfSamples',
      '# of samples',
      () => true,
      (m) => String(m.numberOfSamples),
      {'flex': '0 0 54px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'title',
      'Title',
      () => true,
      (m) => m.title,
      {'flex': '0 0 360px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'seqParameters',
      'Seq Parameters',
      (m) => m.seqParameters != null,
      (m) => m.seqParameters,
      {'flex': '0 0 120px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'totalRequestedNor',
      'Total Requested NOR (M)',
      (m) => m.totalRequestedNor != null,
      (m) => m.totalRequestedNor,
      {'flex': '0 0 80px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
//
//     new FlexiblePlainColumnDef<NRequestSummary>(
//       'study',
//       'Study',
//       (m) => m.study != null,
//       (m) => m.study.name,
//       {'flex-grow': 2, 'font-size': '95%'},
//       (m) => `/request/${m.accessionCode}`
//     ),
//     new FlexiblePlainColumnDef<NRequestSummary>(
//       'collaborator',
//       'Collaborator',
//       () => true,
//       (m) => m.collaborator,
//       {'flex-grow': 2, 'font-size': '93%'},
//       (m) => `/request/${m.accessionCode}`
//     ),
     new FlexibleHtmlColumnDef<NRequestSummary>(
      'tasks',
      'Tasks completed',
      () => true,
      (req) => renderTasks(req),
      {'flex': '0 0 150px', 'white-space': 'normal'},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'requester',
      'Requester',
      (m) => m.requester != null,
      (m) => m.requester.name,
      {'flex': '0 0 100px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'createdAt',
      'Created at',
      () => true,
      (m) => formatDate(m.createdAt, 'short', 'en-US'),
      {'flex': '0 0 100px', 'font-size': `${this.fontSizeFactor}`},
      (m) => `/request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<NRequestSummary>(
      'fastqDir',
      'Fastq file Path',
      () => true,
      (m) => m.fastqDir,
      {'flex': '0 0 320px', 'font-size': '80%'},
      (m) => `/request/${m.accessionCode}`
    )
  ];

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    if (this.showAssignees) {
      this.columnDefinitions.push(
        new FlexiblePlainColumnDef<NRequestSummary>(
          'assignees',
          'Assigned to',
          (m) => m.assignedTo.length > 0,
          (m) => m.assignedTo.map(user => user.name).join(', '),
          {'flex': '0 0 120px', 'font-size': `${this.fontSizeFactor}`},
          (m) => `/request/${m.accessionCode}`
        )
      );
    }
    if (this.showDescription) {
      this.columnDefinitions.push(
        new FlexiblePlainColumnDef<NRequestSummary>(
          'description',
          'Description',
          (m) => m.description != null,
          () => '',
          {'flex-grow': 2, 'font-size': `${this.fontSizeFactor}`},
          (m) => m.description.substr(0, Math.min(60, m.description.length)),
        )
      );
    }
    if (this.enableArchive) {
      this.columnDefinitions.push(
        new FlexibleButtonsColumnDef<NRequestSummary>(
          'archive',
          'Archive',
          () => true,
          () => '',
          {'flex': '0 0 100px'},
          [
            new FlexibleButtonDef<NRequestSummary>(
              (m) => m.archived ? 'Reopen' : 'Archive',
              (m) => m.archived ? '' : 'primary',
              () => true,
              (m) => this.toggleArchive(m)
            )
          ]
        )
      );
    }
  }

  toggleArchive(request: NRequestSummary): void {
    if (request.archived) {
      this.store.dispatch(restoreRequestFromList({accessionCode: request.accessionCode}));
    } else {
      this.store.dispatch(archiveRequestFromList({accessionCode: request.accessionCode}));
    }
  }

}
