import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectIndexKitComponent } from './components/select-index-kit.component';
import { AddIndexKitDialogComponent } from './components/add-index-kit-dialog/add-index-kit-dialog.component';
import { MaterialModule } from '../../material.module';
import { IndexKitNamePipe } from './pipes/index-kit-name.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectIndexKitItemComponent } from './components/select-index-kit-item.component';
import { LabWareSharedModule } from '../../labware/shared/lab-ware-shared.module';
import { I7Pipe } from './pipes/i7.pipe';
import { I5Pipe } from './pipes/i5.pipe';
import { QCSharedModule } from '../../qc/shared/qc-shared.module';
import { IndexKitModelSettingsComponent } from './components/index-kit-model-settings/index-kit-model-settings.component';
import { IndexKitModelSettingsAddComponent } from './components/index-kit-model-settings/index-kit-model-settings-add.component';
import { IndexKitModelSettingsListComponent } from './components/index-kit-model-settings/index-kit-model-settings-list.component';
import { IndexKitModelEditDialogComponent } from './components/index-kit-model-settings/index-kit-model-edit.component';

@NgModule({
  declarations: [
    IndexKitNamePipe,
    SelectIndexKitComponent,
    AddIndexKitDialogComponent,
    SelectIndexKitItemComponent,
    I7Pipe,
    I5Pipe,
    IndexKitModelSettingsComponent,
    IndexKitModelSettingsAddComponent,
    IndexKitModelEditDialogComponent,
    IndexKitModelSettingsListComponent
  ],
  exports: [
    SelectIndexKitComponent,
    I7Pipe,
    I5Pipe,
    IndexKitModelSettingsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    LabWareSharedModule,
    QCSharedModule
  ]
})
export class IndexSharedModule {
}
