import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { StudyService } from '../services/study.service';
import {
  archiveStudy,
  createStudy,
  findAllStudiesAfterUpdate,
  findAllStudiesAtInit,
  findAllStudiesError,
  findAllStudiesSuccess,
  restoreStudy
} from './study.actions';
import { Study } from '../models/Study';

@Injectable()
export class StudyEffects {
  constructor(
    private actions$: Actions,
    private studyService: StudyService
  ) {
  }

  findAllStudies$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllStudiesAtInit.type,
      findAllStudiesAfterUpdate.type,
    ),
    mergeMap(() => this.studyService.findAll()),
    map(studies => findAllStudiesSuccess({studies: studies})),
    catchError(error => of(findAllStudiesError({message: error.message})))
    ) }
  );

  createStudy$ = createEffect(() => { return this.actions$.pipe(
    ofType(createStudy.type),
    mergeMap((action: ReturnType<typeof createStudy>) =>
      this.studyService.create({
        name: action.name
      } as Study)
    ),
    map(() => findAllStudiesAfterUpdate())) }
  );

  archiveStudy$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveStudy.type),
    mergeMap((action: ReturnType<typeof archiveStudy>) =>
      this.studyService.archive(action.study)
    ),
    map(() => findAllStudiesAfterUpdate())) }
  );

  restoreStudy$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreStudy.type),
    mergeMap((action: ReturnType<typeof restoreStudy>) =>
      this.studyService.restore(action.study)
    ),
    map(() => findAllStudiesAfterUpdate())) }
  );
}
