import { Component, Input, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../store/app.reducers';
import * as fromTable from '../store';
import { changeArchiveFilter } from '../store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nemo-archive-filter',
  template: `
    <mat-checkbox
      color="primary"
      [checked]="checked$ | async"
      (change)="changeFilter($event)">
      Hide archived
    </mat-checkbox>
  `
})
export class StatusFilterComponent implements OnInit {

  @Input() tableId: string;
  checked$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.checked$ = this.store.select(fromTable.getArchiveFilter(this.tableId)).pipe(
      
      map(checked => checked === undefined ? true : checked)
    );
  }

  changeFilter(event) {
    this.store.dispatch(changeArchiveFilter({tableId: this.tableId, checked: event.checked}));
  }
}
