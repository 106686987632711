import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsHomeComponent } from './components/settings-home/settings-home.component';
import { MaterialModule } from '../material.module';
import { SequencingSharedModule } from '../sequencing/shared/sequencing-shared.module';
import { IndexSharedModule } from '../index/shared/index-shared.module';
import { FormSharedModule } from '../form/shared/form-shared.module';
import { CompanySharedModule } from '../company/shared/company-shared.module';


@NgModule({
  declarations: [SettingsHomeComponent],
  exports: [SettingsHomeComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SequencingSharedModule,
    IndexSharedModule,
    FormSharedModule,
    CompanySharedModule
  ]
})
export class SettingsModule {
}
