import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadButtonComponent } from '../components/download-button/download-button.component';
import { AccessionCodePipe } from '../pipes/accession-code.pipe';
import { EditableTextComponent } from '../components/editable-text/editable-text.component';
import { MaterialModule } from '../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PendingComponent } from './components/pending.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JoinWithCommaPipe } from './pipe/join-with-comma.pipe';
import { TrustHtml } from './pipe/trustHtml.pipe';
import { DialogConfirmationOkComponent } from './components/dialog-confirmation-ok/dialog-confirmation-ok.component';
import { PendingTitleComponent } from './components/pending-title.component';
import { DialogInfoComponent } from './components/dialog-info/dialog-info.component';
import { IncludesPipe } from '../pipes/array.pipe';

@NgModule({
  declarations: [
    DownloadButtonComponent,
    AccessionCodePipe,
    IncludesPipe,
    EditableTextComponent,
    PendingComponent,
    JoinWithCommaPipe,
    TrustHtml,
    DialogConfirmationOkComponent,
    DialogInfoComponent,
    PendingTitleComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    AccessionCodePipe,
    IncludesPipe,
    DownloadButtonComponent,
    EditableTextComponent,
    JoinWithCommaPipe,
    MaterialModule,
    PendingComponent,
    PendingTitleComponent,
    TrustHtml,
  ]
})
export class SharedSharedModule {
}
