import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LabService } from '../services/lab.service';
import {
  archiveLab,
  createLab,
  findAllLabs,
  findAllLabsAfterUpdate,
  findAllLabsError,
  findAllLabsSuccess,
  restoreLab, updateLab
} from './lab.actions';
import { Lab } from '../models/Lab';
import { actionLoadContextAtInit } from '../../global-context/store/global-context.actions';

@Injectable()
export class LabEffects {
  constructor(private actions$: Actions, private labService: LabService) {
  }

  findAll$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllLabs.type,
      findAllLabsAfterUpdate.type
    ),
    mergeMap(() => this.labService.findAll()),
    switchMap(labs => [
      findAllLabsSuccess({labs}),
      actionLoadContextAtInit()
    ]),
    catchError(error => of(findAllLabsError({message: error.message})))) }
  );

  createLab$ = createEffect(() => { return this.actions$.pipe(
    ofType(createLab.type),
    mergeMap((action: ReturnType<typeof createLab>) =>
      this.labService.create({
        alias: action.alias,
        name: action.name,
        homePage: action.homePage,
        email: action.email
      } as Lab)
    ),
    map(() => findAllLabsAfterUpdate())) }
  );

  updateLab$ = createEffect(() => { return this.actions$.pipe(
    ofType(updateLab.type),
    mergeMap((action: ReturnType<typeof updateLab>) =>
      this.labService.update({
        alias: action.alias,
        name: action.name,
        homePage: action.homePage,
        email: action.email
      } as Lab)
    ),
    map(() => findAllLabsAfterUpdate())) }
  );

  archiveLab$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveLab.type),
    mergeMap((action: ReturnType<typeof archiveLab>) =>
      this.labService.archive(action.lab)
    ),
    map(() => findAllLabsAfterUpdate())) }
  );

  restoreLab$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreLab.type),
    mergeMap((action: ReturnType<typeof restoreLab>) =>
      this.labService.restore(action.lab)
    ),
    map(() => findAllLabsAfterUpdate())) }
  );

}
