import { Component, Input } from '@angular/core';
import { IndexAssignmentDto } from '../../../index/models/assigned-index.dto';
import { BioMaterialPlateMapping } from '../../../labware/models/bio-material-plate-mapping';
import { Plate96 } from '../../../labware/models/plate-96';
import { Index } from '../../../bio/models/barcode-index';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import { toIndexAssignmentDto } from './mapper';
import { unassignIndexes } from '../../store/task.action';
import { PoolSamplePlaceholder } from '../../../bio/models/pool';

@Component({
  selector: 'nemo-reset-index-assignment-buttons',
  template: `
    <button mat-raised-button (click)="resetIndexAssignment()" *ngIf="isAnyIndexed()">
      Reset all assignments
    </button>

    <button mat-raised-button (click)="resetIndexAssignmentFromSelection()" *ngIf="isAnyIndexedAndSelected()">
      Reset selected assignments
    </button>
  `
})
export class ResetIndexAssignmentButtonsComponent {

  @Input() samplePlate: Plate96<PoolSamplePlaceholder>;
  @Input() indexPlate: Plate96<Index>;
  @Input() currentSelectionSamples?: PlateCoordinatesSelection;

  constructor(private store: Store<AppState>) {
  }

  isAnyIndexed(): boolean {
    // We could use the mapping but it is often undefined
    return this.samplePlate && this.samplePlate.getNonEmptyBioMaterialMappings()
      .filter((mapping) => mapping.biomaterial.sample.isIndexed())
      .length > 0;
  }

  isAnyIndexedAndSelected(): boolean {
    // We could use the mapping but it is often undefined
    return this.samplePlate && this.samplePlate.getBioMaterialMappingListFromSelection(this.currentSelectionSamples)
      .filter((mapping) => mapping.biomaterial.sample.isIndexed())
      .length > 0;
  }

  /**
   * Remove the index assignment on the current plate
   */
  resetIndexAssignment() {
    const indexAssignments = this.samplePlate
      .getNonEmptyBioMaterialMappings()
      .map((mapping: BioMaterialPlateMapping<PoolSamplePlaceholder>) => toIndexAssignmentDto(mapping));
    this.unassignIndexes(indexAssignments);
  }

  /**
   * Remove indexes from the currently selected samples
   */
  resetIndexAssignmentFromSelection() {
    const indexAssignments: IndexAssignmentDto[] = this.samplePlate
      .getBioMaterialMappingListFromSelection(this.currentSelectionSamples)
      .map((mapping: BioMaterialPlateMapping<PoolSamplePlaceholder>) => toIndexAssignmentDto(mapping));
    this.unassignIndexes(indexAssignments);
  }

  private unassignIndexes(indexAssignments: IndexAssignmentDto[]) {
    const indexKitId = (this.indexPlate) ? this.indexPlate.id : null;
    this.store.dispatch(unassignIndexes({indexAssignments, accessionCode: this.samplePlate.accessionCode, indexKitId}));
  }
}
