import { createAction, props } from '@ngrx/store';
import { Form, FormStructure } from '../../models/form';
import { TextValidationError } from '../../models/text-validation-error';

export const actionFormEditModify = createAction(
  '[From Editor] the form source is modified'
);

export const actionFormLoadLatestYaml = createAction(
  '[Form Editor] load latest form yaml',
  props<{ typeName: string }>()
);

export const actionLoadLatestYamlSuccess = createAction(
  '[Form API] load latest form yaml success',
  props<{ yaml: string }>()
);

export const actionFormValidateYaml = createAction(
  '[Form Editor] validate yaml',
  props<{ yaml: string }>()
);

export const actionFormValidateSuccess = createAction(
  '[Form API] validation Success',
  props<{ formStructure: FormStructure }>()
);

export const actionFormValidateError = createAction(
  '[Form API] validation Error',
  props<{ error: TextValidationError }>()
);

export const actionFormSave = createAction(
  '[Form Editor] save',
  props<{ typeName: string, yaml: string }>()
);

export const actionFormSaveSuccess = createAction(
  '[Form API] save Success',
  props<{ form: Form }>()
);

export const actionFormSaveError = createAction(
  '[Form API] save Error',
  props<{ error: TextValidationError }>()
);

export type FormEditorActions = ReturnType<typeof actionFormEditModify
  | typeof actionFormLoadLatestYaml
  | typeof actionLoadLatestYamlSuccess
  | typeof actionFormValidateYaml
  | typeof actionFormValidateSuccess
  | typeof actionFormValidateError
  | typeof actionFormSave
  | typeof actionFormSaveSuccess
  | typeof actionFormSaveError>;
