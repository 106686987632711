import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { Observable } from 'rxjs';
import { Lab } from '../../../../company/models/Lab';
import { selectAllLabsAsMap } from '../../../../company/store';

@Component({
  selector: 'nemo-form-settings',
  template: `
    <div class="container">
      <h3>Add a new form type</h3>
      <nemo-form-settings-add></nemo-form-settings-add>
      <h3>Edit the list</h3>
      <nemo-form-settings-list [labDict]="labDict$ | async"></nemo-form-settings-list>
    </div>
  `,
  styles: [`
               table {
                 width: 100%;
               }
             `]
})
export class FormSettingsComponent implements OnInit {

  labDict$: Observable<{ [alias: string]: Lab }>;

  constructor(
  private readonly store: Store<AppState>
) {
  }

  ngOnInit() {
    this.labDict$ = this.store.
      select((selectAllLabsAsMap)
    );
  }

}
