import { DetailedIndexKitDto, IndexDto } from '../models/index-kit.dto';
import { BioMaterialPlateMapping } from '../../labware/models/bio-material-plate-mapping';
import { Index } from '../../bio/models/barcode-index';
import { Plate96 } from '../../labware/models/plate-96';
import { PlateCoordinates } from '../../labware/models/plate-coordinates';
import { indexSequencesFromDto } from '../models/index-model.dto';
import { IndexKitSummary } from '../models/indexKit';
import { indexModelFromDto } from '../../labware/services/plate-index.mapper';

/**
 * Make an index-to-plate mapping out of an Index DTO and the kit number.
 */
export function indexDtoToBioMaterialPlateMapping(indexDto: IndexDto): BioMaterialPlateMapping<Index> {
  const {row, column} = indexDto.model.plateCoordinates;
  const plateCoordinates = new PlateCoordinates(row, column);
  const indexKitSummary: IndexKitSummary = indexDto.kitSummary ? {
    id: indexDto.kitSummary.id,
    barcode: indexDto.kitSummary.barcode
  } : null;
  const index = new Index(
    indexDto.id, indexKitSummary, indexDto.used, indexModelFromDto(indexDto.model), indexSequencesFromDto(indexDto.sequences)
  );
  return new BioMaterialPlateMapping<Index>(index, plateCoordinates);
}

/**
 * Make an index plate out of the detailed description of a kit.
 */
export function detailedIndexKitDtoToPlate96(indexKit: DetailedIndexKitDto): Plate96<Index> {
  return new Plate96<Index>(indexKit.id, indexKit.barcode, `index kit ${indexKit.barcode} from ${indexKit.indexKitModel}`, [])
    .setBioMaterialMappings(indexKit.indexes
      .map(index => indexDtoToBioMaterialPlateMapping(index))
    );
}
