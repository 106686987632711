import { PlateCoordinates } from './plate-coordinates';
import PlateDimensions from './plate-dimensions';
import { PlateCoordinatesSelection } from './plate-coordinates-selection';
import { BioMaterialPlateMapping } from './bio-material-plate-mapping';
import { BioMaterial } from '../../bio/models/bio-material';
import { EmptyPlateAtCoordinatesError } from '../labware-errors';
import { TaskAvailable } from '../../tasks/models/task.model';

/**
 * a Plate96 holds 8 x 12 wells, each of which can contain biological material.
 * The class is immutable, so any setter should create a new instance and internal variable made readonly
 * or private for those where readonly is not possible (array...)
 */
export class Plate96<T extends BioMaterial> {

  readonly _bioMaterialContents: T[];

  constructor(
    public readonly id: number,
    public readonly accessionCode: string,
    public readonly title: string,
    public availableForTasks: TaskAvailable[],
    public readonly dimensions = new PlateDimensions(8, 12),
    bioMaterialContents: T[] = [],
  ) {
    this._bioMaterialContents = [...bioMaterialContents];

  }

  clone(): Plate96<T> {
    return new Plate96(this.id,
      this.accessionCode,
      this.title,
      this.availableForTasks,
      this.dimensions,
      this._bioMaterialContents
    );
  }

  /**
   * Retrieve bio material at a given position on the plate,
   * either by plate coordinate or by linear index (both 0-based).
   * @throws an EmptyPlateAtCoordinatesError from the PlateCoordinates conversion if out of bounds or plate empty at this position
   */
  public getBioMaterialAt(pos: number | PlateCoordinates): T {
    const idx = (pos instanceof PlateCoordinates) ? this.coordinate2idx(pos) : pos;
    if (!this._bioMaterialContents[idx]) {
      throw new EmptyPlateAtCoordinatesError(this.accessionCode, this.idx2coordinate(idx));
    }

    return this._bioMaterialContents[idx];
  }

  /**
   * @throws an exception from the PlateCoordinates conversion if out of bounds or plate empty at this position
   */
  public getBioMaterialMappingAt(pos: number | PlateCoordinates): BioMaterialPlateMapping<T> {
    const idx = this.indexOrCoords2Index(pos);
    return new BioMaterialPlateMapping(this.getBioMaterialAt(idx), this.idx2coordinate(idx));
  }

  public setBioMaterialAt(bioMaterial: T, pos: number | PlateCoordinates): Plate96<T> {
    const newBioMaterialContents = [...this._bioMaterialContents];
    const idx = this.indexOrCoords2Index(pos);
    newBioMaterialContents[idx] = bioMaterial;

    return new Plate96(
      this.id,
      this.accessionCode,
      this.title,
      this.availableForTasks,
      this.dimensions,
      newBioMaterialContents
    );
  }

  public emptyAt(pos: number | PlateCoordinates): Plate96<T> {
    const newBioMaterialContents = [...this._bioMaterialContents];
    const idx = this.indexOrCoords2Index(pos);
    delete newBioMaterialContents[idx];

    return new Plate96(
      this.id,
      this.accessionCode,
      this.title,
      this.availableForTasks,
      this.dimensions,
      newBioMaterialContents
    );
  }

  public emptyAtSelection(selection: PlateCoordinatesSelection): Plate96<T> {
    const newBioMaterialContents = [...this._bioMaterialContents];
    selection.listSelectedCoordinates()
      .forEach((c) => delete newBioMaterialContents[this.coordinate2idx(c)]);

    return new Plate96(
      this.id,
      this.accessionCode,
      this.title,
      this.availableForTasks,
      this.dimensions,
      newBioMaterialContents
    );
  }

  /**
   * set the material on a plate (remove former one if any)
   * @return a new Plate96 instance
   */
  public setBioMaterialMappings(bioMaterialMappings: BioMaterialPlateMapping<T>[]): Plate96<T> {
    return new Plate96<T>(
      this.id,
      this.accessionCode,
      this.title,
      this.availableForTasks,
      this.dimensions,
      []
    ).updateBioMaterialMappings(bioMaterialMappings);
  }

  /**
   * Update some bio material for a plate (don't modify the former material at other positions)
   * I.e. it will add or replace the ones at the given positions.
   * The other will remain untouched
   * @return a new Plate96 instance
   */
  public updateBioMaterialMappings(bioMaterialMappings: BioMaterialPlateMapping<T>[]): Plate96<T> {
    const newBioMaterialContents = [...this._bioMaterialContents];

    bioMaterialMappings.forEach((bmwc) => newBioMaterialContents[this.coordinate2idx(bmwc.plateCoordinates)] = bmwc.biomaterial);
    return new Plate96(
      this.id,
      this.accessionCode,
      this.title,
      this.availableForTasks,
      this.dimensions,
      newBioMaterialContents
    );
  }

  public getNonEmptyBioMaterialMappings(): BioMaterialPlateMapping<T>[] {
    return this._bioMaterialContents
      .map((bm, i) => ({bm: bm, i: i}))
      .filter(p => p.bm)
      .map((p) => new BioMaterialPlateMapping(p.bm, this.idx2coordinate(p.i)));
  }

  public isEmptyAt(coords: number | PlateCoordinates): boolean {
    const idx = this.indexOrCoords2Index(coords);
    return this._bioMaterialContents[idx] == null || this._bioMaterialContents[idx].isEmpty;
  }

  public getBioMaterialMappingListFromSelection(coordsSelection: PlateCoordinatesSelection): BioMaterialPlateMapping<T>[] {
    return coordsSelection.listSelectedCoordinates()
      .filter((coords) => !this.isEmptyAt(coords))
      .map((coords) => this.getBioMaterialMappingAt(coords));
  }

  // Demether...
  public numberOfColumns() {
    return this.dimensions.numberOfColumns;
  }

  public numberOfRows() {
    return this.dimensions.numberOfRows;
  }

  private indexOrCoords2Index(indexOrCoords: number | PlateCoordinates): number {
    return (indexOrCoords instanceof PlateCoordinates) ? this.coordinate2idx(indexOrCoords) : indexOrCoords;
  }

  /**
   * Transform a coordinates linear index into a row/column coordinate
   * @param linearIndex the linear coordinate
   * @return a PlateCoordinates (column, row)
   * @throws if linearIndex is out of bounds
   */
  idx2coordinate(linearIndex: number): PlateCoordinates {
    if (linearIndex < 0 || linearIndex >= this.dimensions.size()) {
      const boundsString = this.dimensions.toString();
      const errorMessage = `Index ${linearIndex} out of bounds ${boundsString}`;
      throw new Error(errorMessage);
    }
    return new PlateCoordinates(linearIndex % this.numberOfRows(), Math.floor(linearIndex / this.numberOfRows()));
  }

  /**
   * Transform a column, row coordinates into the coordinates list index
   * @param coords the column,row coordinate
   * @return an integer
   * @throws if coords is out of bounds
   */
  coordinate2idx(coords: PlateCoordinates): number {
    if (coords.column < 0 || coords.row < 0 || coords.column >= this.numberOfColumns() || coords.row >= this.numberOfRows()) {
      const boundsString = this.dimensions.toString();
      const coordsString = `(${coords.row},${coords.column})`;
      const errorMessage = `Plate Coordinates ${coordsString} out of bounds ${boundsString}`;
      throw new Error(errorMessage);
    }
    return coords.column * this.numberOfRows() + coords.row;
  }
}
