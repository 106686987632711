import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommentService } from '../services/comment-service';
import {
  actionAddCommentFile,
  actionCommentPostedError,
  actionCommentPostedSuccess,
  actionFileAddedError,
  actionFileAddedSuccess,
  actionNewComment,
  findAllCommentsByEntity,
  findAllCommentsByEntityError,
  findAllCommentsByEntitySuccess,
} from './comment.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CommentEffects {
  constructor(
    private actions$: Actions,
    private messageService: CommentService
  ) {
  }

  newComment$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionNewComment.type),
    mergeMap((action: ReturnType<typeof actionNewComment>) => this.messageService.newMessage(action.content, action.entityPage)),
    map((commentText) => actionCommentPostedSuccess({comment: commentText})),
    catchError((error) => of(actionCommentPostedError({message: error.message})))
  ) });

  findAllCommentsByEntity$ = createEffect(() => { return this.actions$.pipe(
    ofType(findAllCommentsByEntity.type),
    mergeMap((action: ReturnType<typeof findAllCommentsByEntity>) => this.messageService.findAllCommentByEntity(action.entityPage)),
    map((comments) => findAllCommentsByEntitySuccess({comments: comments})),
    catchError((error) => of(findAllCommentsByEntityError({message: error.message})))
  ) });

  newCommentFile$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionAddCommentFile.type),
    mergeMap((action: ReturnType<typeof actionAddCommentFile>) => this.messageService.addFile(action.file, action.entityPage)),
    map((commentFile) => actionFileAddedSuccess({comment: commentFile})),
    catchError((error) => of(actionFileAddedError({message: error.message})))
  ) });

}
