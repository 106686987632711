import { InstrumentFile } from './instrumentFile';

export class InstrumentDirectory {

  constructor(
    public readonly accessionCode: string,
    public readonly instrument: string,
    public readonly path: string,
    public readonly createdAt: Date,
    public readonly files: InstrumentFile[],
  ) {
  }
}
