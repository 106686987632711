import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import SequencingState from './sequencing.reducer';
import { LibraryMethod } from '../models/library-method';
import { SequencerModel } from '../models/sequencer-model';

export const selectSequencingState = (state: AppState) => state.sequencing;
export const selectAllLibraryMethods = createSelector(
  selectSequencingState,
  (state: SequencingState) => state.libraryMethods.slice().sort(
    (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
);
export const selectActiveLibraryMethods = createSelector(selectAllLibraryMethods,
  (ls: LibraryMethod[]) => ls.filter(l => !l.archived)
);
export const selectAllSequencerModels = createSelector(
  selectSequencingState,
  (state: SequencingState) => state.sequencerModels.slice().sort(
    (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
);
export const selectActiveSequencerModels = createSelector(
  selectAllSequencerModels,
  (ls: SequencerModel[]) => ls.filter(l => !l.archived)
);
