import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormEditorComponent } from './components/form-editor/form-editor.component';
import { FormEditorYamlComponent } from './components/form-editor/form-editor-yaml.component';
import { MaterialModule } from '../material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from './components/form/form.component';
import { FormSectionComponent } from './components/form/form-section.component';
import { FormQuestionComponent } from './components/form/form-question.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormQuestionMultiChoiceComponent } from './components/form/form-question-multi-choice.component';
import { FormQuestionTextComponent } from './components/form/form-question-text.component';
import { FormSubmissionComponent } from './components/form-submission/form-submission.component';
import { EditFormRequestComponent } from './components/form-submission/edit-form-request.component';
import { CompanySharedModule } from '../company/shared/company-shared.module';
import { FormRequestListPageComponent } from './components/form-request-list-page/form-request-list-page.component';
import { TableModule } from '../table/table.module';
import { SharedModule } from '../shared/shared.module';
import { UserSharedModule } from '../user/shared/user-shared.module';
import { FormRequestViewPageComponent } from './components/form-request-view/form-request-view-page.component';
import { FormRequestHeaderComponent } from './components/form-request-view/form-request-header.component';
import { FormRequestStagesComponent } from './components/form-request-view/form-request-stages.component';
import { SubmittedFormComponent } from './components/submitted-form/submitted-form.component';
import { SubmittedFormSectionComponent } from './components/submitted-form/submitted-form-section.component';
import { SubmittedFormQuestionComponent } from './components/submitted-form/submitted-form-question.component';
import {
  SubmittedFormQuestionMultipleChoiceComponent
} from './components/submitted-form/submitted-form-question-multiple-choice.component';
import { NewFormRequestComponent } from './components/form-submission/new-form-request.component';
import { AcceptFormRequestComponent } from './components/form-submission/accept-form-request.component';
import { FormSharedModule } from './shared/form-shared.module';
import { FormQuestionSampleNameOptionComponent } from './components/form/form-question-sample-name-option.component';
import {
  SubmittedFormQuestionSamplenameOptionComponent
} from './components/submitted-form/submitted-form-question-samplename-option.component';
import { FormQuestionSampleNameListComponent } from './components/form/form-question-sample-name-list.component';
import {
  SubmittedFormQuestionSamplenameListComponent
} from './components/submitted-form/submitted-form-question-samplename-list.component';
import { FormRequestSearchResultComponent } from './components/form-request-search-results/form-request-search-result.component';
import { FormQuestionSequenceComponent } from './components/form/form-question-sequence.component';
import { SubmittedFormQuestionSequenceComponent } from './components/submitted-form/submitted-form-question-sequence.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  declarations: [
    EditFormRequestComponent,
    FormComponent,
    FormEditorComponent,
    FormEditorYamlComponent,
    FormQuestionComponent,
    FormQuestionMultiChoiceComponent,
    FormQuestionSampleNameOptionComponent,
    FormQuestionSampleNameListComponent,
    FormQuestionSequenceComponent,
    FormQuestionTextComponent,
    FormRequestHeaderComponent,
    FormRequestListPageComponent,
    FormRequestSearchResultComponent,
    FormRequestStagesComponent,
    FormRequestViewPageComponent,
    FormSectionComponent,
    FormSubmissionComponent,
    AcceptFormRequestComponent,
    NewFormRequestComponent,
    SubmittedFormComponent,
    SubmittedFormQuestionComponent,
    SubmittedFormQuestionMultipleChoiceComponent,
    SubmittedFormQuestionSamplenameListComponent,
    SubmittedFormQuestionSamplenameOptionComponent,
    SubmittedFormQuestionSequenceComponent,
    SubmittedFormSectionComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    CompanySharedModule,
    FormSharedModule,
    MaterialModule,
    MatExpansionModule,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    UserSharedModule,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } }
  ]
})
export class FormModule {
}
