import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Lab } from '../models/Lab';

export const LAB_BASE_URI = environment.apiUrl + '/labs';

@Injectable({
  providedIn: 'root'
})
export class LabService {

  constructor(private readonly http: HttpClient) {
  }

  findAll(): Observable<Lab[]> {
    return this.http.get<Lab[]>(LAB_BASE_URI);
  }

  create(lab: Lab): Observable<Lab> {
    return this.http.post<Lab>(LAB_BASE_URI, lab);
  }

  update(lab: Lab): Observable<Lab> {
    return this.http.put<Lab>(LAB_BASE_URI, lab);
  }

  archive(lab: Lab): Observable<void> {
    const uri = `${LAB_BASE_URI}/${lab.id}`;
    return this.http.delete<void>(uri);
  }

  restore(lab: Lab): Observable<void> {
    const uri = `${LAB_BASE_URI}/${lab.id}/restore`;
    return this.http.post<void>(uri, undefined);
  }
}
