import { Component, OnDestroy, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { Observable, Subscription } from 'rxjs';
import { selectAllFormTypesForCurrentLab } from '../../../form/store/form/form.selector';
import { FormTypeWithSections } from '../../../form/models/form-type';
import { actionLoadAllFormTypesWithSectionsForLab } from '../../../form/store/form';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'nemo-form-section-shortcuts',
  template: `
    <div
      *ngFor="let type of formTypesWithSections$ | async"
      class="type"
    >
      <div
        *ngIf="type.sections.length>1"
        class="multi-section"
      >
        <div
          class="title"
          matTooltip="{{type.description}}"
        >⇒<a routerLink="/new-form-request/{{type.name}}">{{type.title}}</a></div>
        <ul>
          <li *ngFor="let section of type.sections"
              matTooltip="{{section.description}}"
          >
            ➾<a routerLink="/new-form-request/{{type.name}}" [queryParams]="{section: section.key}">
            {{section.title}}</a>
          </li>
        </ul>
      </div>

    </div>
  `,
  styles: [`
             :host {
               font-family: Calibri, Roboto, sans-serif;
               font-size: 1.2em;
             }

             div.multi-section div.title {
               font-size: 110%;
             }

             div.multi-section ul {
               margin-block-start: 0em;
               list-style-type: none;
             }
           `]
})
export class FormSectionShortcutsComponent extends HasGlobalContextUserRole implements OnInit, OnDestroy {

  formTypesWithSections$: Observable<FormTypeWithSections[]>;

  labSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.formTypesWithSections$ = this.store.
      select((selectAllFormTypesForCurrentLab)
    );

    this.labSubscription = this.currentLab$.pipe(
      filter(theLab => Boolean(theLab)),
      take(1)
    ).subscribe(
      (theLab) => this.store.dispatch(actionLoadAllFormTypesWithSectionsForLab({lab: theLab.alias}))
    );
  }

  ngOnDestroy(): void {
    if (this.labSubscription) {
      this.labSubscription.unsubscribe();
    }
  }

}
