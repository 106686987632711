import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { poolsFindAll } from '../store/actions';
import { Title } from '@angular/platform-browser';
import { isPoolStorePending } from '../store';
import { Observable } from 'rxjs';

@Component({
  selector: 'nemo-pool-list-page',
  template: `
    <nemo-pending-title title="Pools" [pending]="pending$ | async"></nemo-pending-title>
    <nemo-pool-list [searchBar]="true"></nemo-pool-list>
  `
})
export class PoolListPageComponent implements OnInit {

  pending$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.pending$ = this.store.select(isPoolStorePending);
    this.titleService.setTitle('Nemo pool list');
    this.findAll();
  }

  findAll() {
    this.store.dispatch(poolsFindAll());
  }
}
