import {Injectable} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';

import {environment} from '../../../environments/environment';

const REPORTING_BASE_URI = environment.apiUrl + '/reporting';
const QURERY_USERS_URI = environment.apiUrl + '/reporting/allowed-users';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  constructor(
    private readonly http: HttpClient
  ) {
  }

  exportRequestDump() {
    return this.http.get(
      REPORTING_BASE_URI,
      {
        headers: {},
        responseType: 'text'
      }
    ).pipe(
      map((content: any) => {
        const filename = `nemo-reporting-requests_${moment().format('YMMDD_HHmmss')}.csv`;
        return {content, filename};
      }),
      take(1)
    );
  }

  getUserList(): Observable<string[]> {
    return this.http.get<string[]>(
      QURERY_USERS_URI,
      {
        headers: {},
        responseType: 'json'
      }
    );
  }
}
