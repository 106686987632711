import { createSelector } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';


const getFormEditorState = (state: AppState) => state.formEditor;

export const selectFormEditorYaml = createSelector(
  getFormEditorState,
  (state) => state.yaml
);
export const selectFormEditorStructure = createSelector(
  getFormEditorState,
  (state) => state.formStructure
);
export const selectFormEditorError = createSelector(
  getFormEditorState,
  (state) => state.error
);

export const selectFormIsModified = createSelector(
  getFormEditorState,
  (state) => state.isModified
);

export const selectFormIsValid = createSelector(
  getFormEditorState,
  (state) => state.isValid
);

export const selectFormIsSaved = createSelector(
  getFormEditorState,
  (state) => state.isSaved
);
