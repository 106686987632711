import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Cro } from '../../../../genomics-request/models/cro-request.model';
import { CommonRequest } from '../../models/common-request';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { assignCroToGenomicsRequest, selectAllCros } from '../../../../genomics-request/store';

@Component({
  selector: 'nemo-request-cro-assignment-container',
  template: `
    <div class="cro-assignment-container">
      <mat-form-field *ngIf="request">
        <mat-label>Assigned to</mat-label>
        <mat-select [value]="request.croId || 0" (selectionChange)="assignCro($event.value)">
          <mat-option *ngFor="let cro of allCro$ | async"
                      [value]="cro.id || 0">
            {{ cro.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button style="display: none;" mat-flat-button color="primary">
        Export Default Sample ID SSF
        <mat-icon>download</mat-icon>
      </button>
      <button style="display: none;" mat-stroked-button color="primary" [disabled]="true">
        Export Anonymized
        <mat-icon>lock_outline</mat-icon>
      </button>
    </div>
  `,
  styles: [`
    .cro-assignment-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      margin: 16px 0;
    }
    
    button {
      min-width: 272px;
    }
  `]
})
export class RequestCroAssignmentContainerComponent {
  @Input() request: CommonRequest;
  allCro$: Observable<Cro[]>;

  constructor(
    private store: Store<AppState>
  ) {
    this.allCro$ = this.store.select(selectAllCros);
  }

  assignCro(croId: string | null) {
    this.store.dispatch(assignCroToGenomicsRequest({ croId: croId || null, accessionCode: this.request.accessionCode }));
  }
}
