import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {  Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash';

import {
  selectFormEditorStructure,
  selectFormEditorYaml, selectFormIsModified,
  selectFormIsSaved, selectFormIsValid
} from '../../store/editor/form-editor.selector';
import { AppState } from '../../../store/app.reducers';
import {
  actionFormEditModify,
  actionFormLoadLatestYaml,
  actionFormSave,
  actionFormValidateYaml
} from '../../store/editor';
import { FormStructure } from '../../models/form';
import { FormBuilderService } from '../../services/form-builder.service';

@Component({
  selector: 'nemo-form-editor',
  template: `
    <div class="container">
      <div class="col">
        <nemo-form-editor-yaml
          *ngIf="yaml$ | async"
          [yaml]="yaml$ | async"
          (modifiedYaml)="update($event)"
        >
        </nemo-form-editor-yaml>
      </div>
      <div class="buttons">
        <button
          *ngIf="!isPristineValidated"
          mat-raised-button
          color="primary"
          (click)="validate()"
        >
          <mat-icon>spellcheck</mat-icon>
          check syntax
        </button>
        <button
          *ngIf="(!isPristineSaved) && (isValid$ | async)"
          mat-raised-button
          color="primary"
          (click)="save()"
        >
          <mat-icon>save</mat-icon>
          save
        </button>
      </div>
      <div class="col">
        <nemo-form
          *ngIf="structure$ | async"
          [group]="group"
          [structure]="structure$ | async"
        ></nemo-form>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               position: relative;
               display: flex;
               height: 100%;
               flex-direction: row;
               overflow: hidden;
             }

             div.col {
               width: 50%;
               height: 100%;
               overflow: auto;
               margin: 0 4px;
             }

             div.buttons {
               position: absolute;
               top: 6px;
               right: 52%;
               opacity: 70%;
             }
           `]
})
export class FormEditorComponent implements OnInit, OnDestroy {
  yaml$: Observable<string>;
  modifiedYaml: string;
  private unsubscribe$ = new Subject<void>();
  structure$: Observable<FormStructure>;

  paramSubscription: Subscription;

  pristineValidatedSubscription: Subscription;
  pristineSavedSubscription: Subscription;
  isPristineValidated = false;
  isPristineSaved = false;

  isModified$: Observable<boolean>;
  isValid$: Observable<boolean>;
  isSaved$: Observable<boolean>;

  typeName: string;
  group = new UntypedFormGroup({});

  constructor(
    private readonly formBuilderService: FormBuilderService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.typeName = params['typeName'];
      this.store.dispatch(actionFormLoadLatestYaml({typeName: this.typeName}));
    });

    this.yaml$ = this.store.
      select((selectFormEditorYaml)
    );

    // this is not the cleanest piece.
    // the goal is to get the first yaml and resend if for validation & form structure building
    // But this back and forth at least ensures that the validation is going through before the user starts editing.
    // in that sense, it appears more robust than loading json + yaml at once from the backend
    this.yaml$.pipe(
      filter(x => Boolean(x)),
      take(1),
    ).subscribe((yaml) => {
      this.modifiedYaml = yaml;
      this.store.dispatch(actionFormValidateYaml({yaml}));
    });

    this.structure$ = this.store.select(selectFormEditorStructure).pipe(
      
      filter((s) => Boolean(s)),
      map((structure) => {
        this.group = new UntypedFormGroup({});
        const controls = this.formBuilderService.buildControls(structure, null);
        _.each(
          controls,
          (control, key) => this.group.addControl(key, control)
        );
        return structure;
      })
    );

    this.pristineSavedSubscription = this.store.select(selectFormIsSaved).pipe(
      
      filter((b) => b)
    ).subscribe(() => {
      this.isPristineSaved = true;
    });

    this.pristineValidatedSubscription = this.store.select(selectFormIsValid).pipe(
      
      filter((b) => b)
    ).subscribe(() => {
      this.isPristineValidated = true;
    });

    this.isModified$ = this.store.
      select((selectFormIsModified)
    );

    this.isValid$ = this.store.
      select((selectFormIsValid)
    );

    this.isSaved$ = this.store.
      select((selectFormIsSaved)
    );

  }


  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  update(yaml) {
    this.modifiedYaml = yaml;
    let shootActionFormEditModify = false;
    if (this.isPristineValidated) {
      this.isPristineValidated = false;
      shootActionFormEditModify = true;
    }
    if (this.isPristineSaved) {
      this.isPristineSaved = false;
      shootActionFormEditModify = true;
    }
    if (shootActionFormEditModify) {
      this.store.dispatch(actionFormEditModify());
    }
  }

  validate() {
    this.store.dispatch(actionFormValidateYaml({yaml: this.modifiedYaml}));
  }

  save() {
    this.store.dispatch(actionFormSave({typeName: this.typeName, yaml: this.modifiedYaml}));
  }
}
