import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { FormService } from '../../services/form.service';
import {
  actionFormCreateNewFormType,
  actionFormEditLoadById,
  actionFormLoadAllFormTypes,
  actionFormLoadAllFormTypesAfterCreation,
  actionFormLoadAllFormTypesSuccess,
  actionFormLoadByIdSuccess,
  actionFormLoadByTypeNameSuccess,
  actionFormSubmitLoadByTypeName,
  actionLoadAllFormTypesWithSectionsForLab,
  actionLoadAllFormTypesWithSectionsForLabSuccess
} from './form.actions';
import { FormTypeService } from '../../services/form-type.service';
import { CreateFormTypeDto } from '../../dtos/form-type.dto';
import { select, Store } from '@ngrx/store';
import { selectGlobalContextLab } from '../../../global-context/store/global-context.selectors';
import { AppState } from '../../../store/app.reducers';

@Injectable()
export class FormEffects {
  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private formService: FormService,
    private formTypeService: FormTypeService,
  ) {
  }

  loadAllFormTypes$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFormLoadAllFormTypes, actionFormLoadAllFormTypesAfterCreation),
    mergeMap((action: ReturnType<typeof actionFormLoadAllFormTypes>) => {
        return this.formTypeService.loadAll().pipe(
          map(formTypes => actionFormLoadAllFormTypesSuccess({formTypes}))
        );
      }
    )
  ) });

  loadByTypeName$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFormSubmitLoadByTypeName),
    mergeMap((action: ReturnType<typeof actionFormSubmitLoadByTypeName>) => {
        const {typeName} = action;
        return this.formService.loadByTypeName(typeName).pipe(
          map(form => actionFormLoadByTypeNameSuccess({form}))
        );
      }
    )
  ) });

  loadById$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFormEditLoadById),
    mergeMap((action: ReturnType<typeof actionFormEditLoadById>) => {
        const {id} = action;
        return this.formService.loadById(id).pipe(
          map(form => actionFormLoadByIdSuccess({form}))
        );
      }
    )
  ) });

  createNewFormType$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFormCreateNewFormType),
    mergeMap((action: ReturnType<typeof actionFormCreateNewFormType>) => {
        const createFormTypeDto = new CreateFormTypeDto(action.name, action.lab);
        return this.formTypeService.create(createFormTypeDto);
      }
    ),
    map(() => actionFormLoadAllFormTypesAfterCreation())
  ) });

  actionLoadAllFormTypesWithSectionsForCurrentLab$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionLoadAllFormTypesWithSectionsForLab.type),
    mergeMap((action: ReturnType<typeof actionLoadAllFormTypesWithSectionsForLab>) => {
        return this.formService.loadSectionsForLab(action.lab)
      }
    ),
      map((formTypesWithSections) => {
	  return actionLoadAllFormTypesWithSectionsForLabSuccess({formTypesWithSections})
      }
      )
  ) });

}
