import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FlexibleButtonDef, FlexibleButtonsColumnDef } from '../../model/flexible-columm-def';

@Component({
  selector: 'nemo-flexible-button-cell',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div *ngFor="let button of buttons"
    >
      <button
        *ngIf="!button.isIcon"
        mat-raised-button
        [color]="button.color(item)"
        (click)="button.action(item)"
      >{{button.title(item)}}</button>
      <button
        *ngIf="button.isIcon"
        [color]="button.color(item)"
        (click)="button.action(item)"
        mat-mini-fab
      >
        <mat-icon>{{button.title(item)}}</mat-icon>
      </button>
    </div>
  `,
  styles: [`
             :host {
               display: flex;
               flex-direction: row;
             }
           `]
})
export class FlexibleButtonCellComponent<T> implements OnInit {
  @Input()
  columnDefinition: FlexibleButtonsColumnDef<T>;

  @Input()
  field: {};

  @Input()
  item: T;

  buttons: FlexibleButtonDef<T>[];

  ngOnInit() {
    const allButtons = this.field['buttons'] as FlexibleButtonDef<T>[];
    this.buttons = allButtons.filter((button) => button.isPresent(this.item));
  }

}
