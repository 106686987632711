import { createSelector } from '@ngrx/store';
import PlatesState from './plates.reducer';
import { AppState } from '../../../store/app.reducers';
import { TaskAvailable } from '../../../tasks/models/task.model';

export const getPlatesState = (state: AppState) => state.plates;

export const getPlates = createSelector(getPlatesState, (state: PlatesState) => state.plates);
export const getPlateByIdentifier = (identifier) =>
  createSelector(getPlatesState, (state: PlatesState) => state.plates[identifier]);

export const getRangeSelections = createSelector(getPlatesState, (state: PlatesState) => state.rangeSelections);
export const getRangeSelectionsByIdentifier = (identifier) =>
  createSelector(getPlatesState, (state: PlatesState) => state.rangeSelections[identifier]);

export const getRangeSelectionsForListenerId = (listenerId) => {
  return createSelector(getPlatesState, (state: PlatesState) => state.rangeSelections &&
    state.rangeSelections[listenerId]);
};

export const getPlateForListenerId = (listenerId) => {
  return createSelector(getPlatesState, (state: PlatesState) => state.plates && state.plates[listenerId]);
};

export const getPlateList = (key?: string) => {
  const mapKey = key === undefined ? 'all' : key;
  return createSelector(getPlatesState, (state: PlatesState) => state.plateList[mapKey]);
};
export const getPlateCounts = (key?: string) => {
  const mapKey = key === undefined ? 'all' : key;
  return createSelector(getPlatesState, (state: PlatesState) => state.plateCounts[mapKey]);
};

export const getCurrentPlate = createSelector(getPlatesState, (state: PlatesState) => state.currentPlate);
export const isPlateStorePending = createSelector(getPlatesState, (state: PlatesState) => state.pending);

export const selectAllPlateSnippetsForTask = (task: TaskAvailable) => {
  return createSelector(getPlatesState, (state: PlatesState) =>
    state.snippets ?
    state.snippets.filter(p => p.availableForTasks.indexOf(task) >= 0) :
      []
  );
};

