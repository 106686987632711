export const BioMaterialTypes = {
  poolSamplePlaceholder: 'POOL_SAMPLE_PLACEHOLDER',
  poolSampleMix: 'POOL_SAMPLE_MIX',
  index: 'INDEX'
};

export abstract class BioMaterial {
  abstract readonly type: string;

  protected constructor(
    public readonly name: string,
    public readonly isEmpty: boolean
  ) {
  }
}
