import { Component, Input } from '@angular/core';

@Component({
  selector: 'nemo-pending',
  template: `
    <span *ngIf="pending">
      <mat-spinner [diameter]="16"></mat-spinner> loading data
    </span>
  `,
  styles: [`
    mat-spinner {
      margin-right: 5px;
    }

    span {
      color: #1967d2;
      display: flex;
    }
  `]
})
export class PendingComponent {
  @Input() pending: boolean;
}
