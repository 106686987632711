import { Component, Input } from '@angular/core';
import { User } from '../../models/user.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'nemo-user-image',
  template: `
    <img *ngIf="user"
         [src]="domSanitizer.bypassSecurityTrustUrl(user.picture)"
         [alt]="user.name"
         class="profile-picture"/>
  `,
  styles: [`
    :host {
      height: inherit;
    }

    .profile-picture {
      max-width: 33px;
      border-radius: 100%;
      margin-right: 8px;
      vertical-align: middle;
    }
  `]
})
export class UserImageComponent {
  @Input() user: User;

  constructor(
    private domSanitizer: DomSanitizer
  ) {
  }
}
