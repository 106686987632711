import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { MaterialModule } from '../material.module';
import { RequestSharedModule } from '../request-shared/request-shared.module';
import { SharedSharedModule } from '../shared/shared/shared-shared.module';
import { GlobalContextSharedModule } from '../global-context/shared/global-context-shared/global-context-shared.module';
import { RequestModule } from '../genomics-request/request.module';
import { FormSharedModule } from '../form/shared/form-shared.module';
import { HomeComponent } from './components/home.component';
import { HomeTitleComponent } from './components/home-title/home-title.component';
import { LabPresentationComponent } from './components/lab-presentation/lab-presentation.component';
import { HomeGenomicsRequestsComponent } from './components/home-request-list/home-genomics-requests.component';
import { HomeFormRequestsComponent } from './components/home-request-list/home-form-requests.component';
import { FormSectionShortcutsComponent } from './components/home-title/form-section-shortcuts.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeTitleComponent,
    LabPresentationComponent,
    HomeGenomicsRequestsComponent,
    HomeFormRequestsComponent,
    FormSectionShortcutsComponent,
  ],
  imports: [
    CommonModule,
    GlobalContextSharedModule,
    HomeRoutingModule,
    MaterialModule,
    RequestSharedModule,
    FormSharedModule,
    SharedSharedModule,
    RequestModule,
  ]
})
export class HomeModule {
}
