import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksListComponent } from './components/tasks-list.component';
import { TaskTypePipe } from './pipe/task-type.pipe';
import { TasksListItemComponent } from './components/tasks-list-item.component';
import { RouterModule } from '@angular/router';
import { RequestAccessionCodeListComponent } from './components/request-accession-code-list.component';
import { SharedModule } from '../../shared/shared.module';
import { ExportSelectButtonComponent } from './components/export-select-button.component';
import { MaterialModule } from '../../material.module';
import { ExportButtonComponent } from './components/export-button.component';
import { UserSharedModule } from '../../user/shared/user-shared.module';

@NgModule({
  declarations: [
    TasksListComponent,
    TaskTypePipe,
    TasksListItemComponent,
    RequestAccessionCodeListComponent,
    ExportSelectButtonComponent,
    ExportButtonComponent
  ],
  exports: [
    TasksListComponent,
    ExportSelectButtonComponent,
    ExportButtonComponent,
    RequestAccessionCodeListComponent,
    TaskTypePipe
  ],
  imports: [
    CommonModule,
    UserSharedModule,
    RouterModule,
    SharedModule,
    MaterialModule,
  ]
})
export class TasksSharedModule {
}
