import { Routes } from '@angular/router';
import { RequestSubmissionPageComponent } from './components/request-submission/request-submission-page.component';
import { RequestPageComponent } from './components/request-page/request-page.component';
import { RequestSearchResultComponent } from './components/request-search-result/request-search-result.component';
import { RequestListPageComponent } from './components/request-list-page/request-list-page.component';
import { AuthGuard } from '../core/auth/auth.guard';

export const requestRoutes: Routes = [
  {path: 'request/:accessionCode', component: RequestPageComponent, canActivate: [AuthGuard]},
  {path: 'new-request', component: RequestSubmissionPageComponent, canActivate: [AuthGuard]},
  {path: 'requests', component: RequestListPageComponent, canActivate: [AuthGuard]},
  {path: 'requests/search', component: RequestSearchResultComponent, canActivate: [AuthGuard]},
];
