import { Component } from '@angular/core';

@Component({
  selector: 'nemo-labs',
  template: `
    <div class="container">
      <h3>Add a new lab</h3>
      <nemo-labs-add></nemo-labs-add>
      <h3>Existing labs</h3>
      <nemo-labs-list></nemo-labs-list>
    </div>
  `,
  styles: [`
               table {
                 width: 100%;
               }
             `]
})
export class LabsComponent {
  constructor() {
  }

}
