import { Component } from '@angular/core';

@Component({
  selector: 'nemo-library-method-settings',
  template: `
    <div class="container">
      <h3>Add a new library method</h3>
      <nemo-library-method-settings-add></nemo-library-method-settings-add>
      <h3>Edit the list</h3>
      <nemo-library-method-settings-list></nemo-library-method-settings-list>
    </div>
  `,
  styles: [`
               table {
                 width: 100%;
               }
             `]
})
export class LibraryMethodSettingsComponent {
  constructor() {
  }

}
