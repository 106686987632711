import { Task } from '../../tasks/models/task.model';

// have to name differently that Comment, which looks like some native type
export abstract class CommentEntity {
  constructor(
    public readonly userId: string,
    public readonly createdAt: Date,
    public readonly entityAccessionCode: string
  ) {
  }
}

export class CommentText extends CommentEntity {
  constructor(
    public readonly userId: string,
    public readonly createdAt: Date,
    public readonly entityAccessionCode: string,
    public readonly content: string,
  ) {
    super(userId, createdAt, entityAccessionCode);
  }
}

export class CommentFile extends CommentEntity {
  constructor(
    public readonly userId: string,
    public readonly createdAt: Date,
    public readonly entityAccessionCode: string,
    public readonly filename: string,
    public readonly gcsUrl: string,
  ) {
    super(userId, createdAt, entityAccessionCode);
  }
}

export class CommentTask extends CommentEntity {
  constructor(
    public readonly userId: string,
    public readonly createdAt: Date,
    public readonly entityAccessionCode: string,
    public readonly task: Task
  ) {
    super(userId, createdAt, entityAccessionCode);
  }
}
