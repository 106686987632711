import { Component, Input } from '@angular/core';
import { changeFlowCellToDraft } from '../../store/flow-cells';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-flow-cell-change-to-draft-button',
  template: `
    <button mat-stroked-button
            color="primary"
            (click)="changeToDraft()">
      Revert to draft
    </button>
  `
})
export class FlowCellChangeToDraftButtonComponent {

  @Input() flowCellId: string;

  constructor(private store: Store<AppState>) {
  }

  changeToDraft() {
    this.store.dispatch(changeFlowCellToDraft({flowCellId: this.flowCellId}));
  }
}
