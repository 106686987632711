import { Component, OnInit } from '@angular/core';
import { LibraryMethod } from '../../../models/library-method';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { selectAllLibraryMethods, settingsArchiveLibraryMethod, settingsRestoreLibraryMethod } from '../../../store';
import { MatDialog } from '@angular/material/dialog';
import { LibraryMethodEditDialogComponent } from './library-method-edit.component';

@Component({
  selector: 'nemo-library-method-settings-list',
  template: `
    <div class="container">
      <table
        mat-table
        [dataSource]="libraryMethods$ | async"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Library method</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-mini-fab
              color="primary"
              (click)="edit(element)"
              matTooltip="edit the library method name"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="!element.archived"
              color="warn"
              (click)="archive(element)"
              matTooltip="Archive the library method"
            >
              <mat-icon>archive</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="element.archived"
              color="primary"
              (click)="restore(element)"
              matTooltip="Restore the library method"
            >
              <mat-icon>unarchive</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns;"
          [ngClass]="{archived:row.archived}"
        ></tr>
      </table>
    </div>
  `,
  styles: [`
    table {
      width: 100%;
    }

    tr.archived td.mat-mdc-cell {
      color: lightgrey !important;
    }
  `]
})
export class LibraryMethodSettingsListComponent implements OnInit {
  libraryMethods$: Observable<LibraryMethod[]>;

  displayedColumns = ['name', 'action'];

  constructor(
    private readonly store: Store<AppState>,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.libraryMethods$ = this.store.
      select((selectAllLibraryMethods)
    );
  }

  edit(libraryMethod: LibraryMethod) {
    this.dialog.open(LibraryMethodEditDialogComponent, {data: libraryMethod});
  }

  archive(libraryMethod: LibraryMethod) {
    this.store.dispatch(settingsArchiveLibraryMethod({libraryMethod}));
  }

  restore(libraryMethod: LibraryMethod) {
    this.store.dispatch(settingsRestoreLibraryMethod({libraryMethod}));
  }

}
