import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { getGenomicsContainersPreview } from '../../store';
import { Observable } from 'rxjs';
import { RequestSampleContainers } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-submission-samples',
  template: `
    <div class="container">
      <div *ngIf="(samplesContainer$ | async)?.plates?.length===0">
        <div class="sample-table" *ngFor="let pool of (samplesContainer$ | async)?.pools">
          <div class="sample-table-title">
            Pool
          </div>
          <nemo-pools-sample-table [samples]="pool.samples"></nemo-pools-sample-table>
        </div>
      </div>
      <div class="sample-table" *ngFor="let plate of (samplesContainer$ | async)?.plates">
        <div class="sample-table-title">
          Samples
        </div>
        <nemo-plates-sample-table [pools]="plate.getNonEmptyBioMaterialMappings()"></nemo-plates-sample-table>
      </div>
    </div>
  `,
  styles: [
    `
          div.container {
            margin-top: 1em;
          }
    
          div.sample-table-title {
            width: 100%;
            text-align: left;
            font-weight: bold;
            font-size: 1.3rem;
          }
        `
  ]
})
export class RequestSubmissionSamplesComponent implements OnInit {

  samplesContainer$: Observable<RequestSampleContainers>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.samplesContainer$ = this.store.select((getGenomicsContainersPreview));
  }
}
