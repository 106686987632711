import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {  Store } from '@ngrx/store';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  archivePool,
  archivePoolError,
  archivePoolSuccess,
  countPoolsFromTodoPage,
  countPoolsSuccess,
  findPoolByAccessionCode,
  findPoolByAccessionCodeError,
  findPoolByAccessionCodeSuccess,
  poolsFindAll,
  poolsFindAllError,
  poolsFindAllFromLaneAssignment,
  poolsFindAllFromTodoPage,
  poolsFindAllSuccess,
  poolsFindByFlowCellId,
  poolsFindByFlowCellIdError,
  poolsFindByFlowCellIdSuccess,
  poolsSearch,
  PoolsSearchBarAction,
  poolsSearchError,
  poolsSearchSuccess,
  unarchivePool,
  unarchivePoolError,
  unarchivePoolSuccess
} from './actions';
import { PoolApiService } from '../services/pool-api.service';
import * as fromTable from '../../table/store';
import { changeArchiveFilter } from '../../table/store';
import { AppState } from '../../store/app.reducers';
import { FlowCellApiService } from '../../labware/services/flow-cell-api.service';

@Injectable()
export class PoolsApiEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private poolApiService: PoolApiService,
    private flowCellApiService: FlowCellApiService,
  ) {
  }


  
  findPoolByAccessionCode = createEffect(() => { return this.actions$.pipe(
    ofType(findPoolByAccessionCode.type),
    mergeMap((action: ReturnType<typeof findPoolByAccessionCode>) =>
      this.poolApiService.findByAccessionCde(action.accessionCode)
    ),
    map(pool => findPoolByAccessionCodeSuccess({pool})),
    catchError(error => of(findPoolByAccessionCodeError({message: error.message}))),
  ) });

  
  findAllPools = createEffect(() => { return this.actions$.pipe(
    ofType(
      poolsFindAll.type,
      poolsFindAllFromTodoPage.type,
      poolsFindAllFromLaneAssignment.type),
    concatLatestFrom(
      () => this.store.select(fromTable.getArchiveFilter('pool')).pipe(
        
        map(checked => checked === undefined ? true : checked),
        map(checked => checked ? !checked : null)  // if checked, return not archived pools
      )
    ),
    mergeMap(([, showArchived]) =>
      this.poolApiService.findAll(showArchived).pipe(
        map(pools => poolsFindAllSuccess({pools})),
        catchError(error => of(poolsFindAllError({message: error.message}))),
      )
    )
  ) });

  
  findPoolsByFlowCellId$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      poolsFindByFlowCellId.type
    ),
    mergeMap((action: ReturnType<typeof poolsFindByFlowCellId>) =>
      this.flowCellApiService.findPoolsByFlowCellId(action.flowCellId)
    ),
    map(pools => poolsFindByFlowCellIdSuccess({pools})),
    catchError((error) => of(poolsFindByFlowCellIdError({message: error.message})))
  ) });

  
  count = createEffect(() => { return this.actions$.pipe(
    ofType(countPoolsFromTodoPage.type),
    mergeMap(() =>
      this.poolApiService.count().pipe(
        map(poolCounts => countPoolsSuccess({poolCounts})),
        catchError(error => of(poolsFindAllError({message: error.message}))),
      )
    )
  ) });

  
  searchPools = createEffect(() => { return this.actions$.pipe(
    ofType(poolsSearch.type),
    mergeMap((action: PoolsSearchBarAction) =>
      this.poolApiService.search(action.query, action.combined, action.timeRange).pipe(
        map(pools => poolsSearchSuccess({pools})),
        catchError(error => of(poolsSearchError({message: error.message}))),
      )
    )
  ) });

  
  changeArchiveFilter = createEffect(() => { return this.actions$.pipe(
    ofType(changeArchiveFilter.type),
    filter((action: ReturnType<typeof changeArchiveFilter>) => action.tableId === 'pool'),
    map(() => poolsFindAll())
  ) });

  
  archive = createEffect(() => { return this.actions$.pipe(
    ofType(archivePool.type),
    mergeMap((action: ReturnType<typeof archivePool>) =>
      this.poolApiService.archive(action.accessionCode).pipe(
        map(() => archivePoolSuccess()),
        catchError(error => of(archivePoolError({message: error.message})))
      )
    )
  ) });

  
  unarchive = createEffect(() => { return this.actions$.pipe(
    ofType(unarchivePool.type),
    mergeMap((action: ReturnType<typeof unarchivePool>) =>
      this.poolApiService.unarchive(action.accessionCode).pipe(
        map(() => unarchivePoolSuccess()),
        catchError(error => of(unarchivePoolError({message: error.message})))
      )
    )
  ) });
}
