import { createAction, props } from '@ngrx/store';
import { SequencerModel } from '../../models/sequencer-model';
import { CreateSequencerModelDto } from '../../dto/create-sequencer-model.dto';

export const findAllSequencerModelsAtInit = createAction(
  '[Init Data] find all sequencer models'
);
export const findAllSequencerModelsAfterUpdate = createAction(
  '[After update] find all sequencer models'
);

export const findAllSequencerModelsSuccess = createAction(
  '[sequencer model API]  all sequencer model list Success',
  props<{ sequencerModels: SequencerModel[] }>()
);

export const findAllSequencerModelsError = createAction(
  '[sequencer model API]  all sequencer model Error',
  props<{ message: string }>()
);

export const createSequencerModel = createAction(
  '[Settings page] create new sequencer model',
  props<{ sequencer: CreateSequencerModelDto }>()
);

export const archiveSequencerModel = createAction(
  '[Settings page] archive sequencer models',
  props<{ sequencerModel: SequencerModel }>()
);

export const restoreSequencerModel = createAction(
  '[Settings page] restore sequencer models',
  props<{ sequencerModel: SequencerModel }>()
);
