import { FormType } from './form-type';
import { User } from '../../user/models/user.model';

export enum QuestionType {
  TEXT = 'TEXT',
  SEQUENCE = 'SEQUENCE',
  CHECKBOX = 'CHECKBOX',
  MULTICHOICE = 'MULTICHOICE',
  SAMPLENAME_OPTION = 'SAMPLENAME_OPTION',
  SAMPLENAME_LIST = 'SAMPLENAME_LIST'
}

export class QuestionShowCondition {
  constructor(
    public readonly dependsOnKey: string,
    public readonly hideIfValueIn: string[]
  ) {
  }
}

export abstract class Question {
  protected constructor(
    public readonly type: QuestionType,
    public readonly key: string,
    public readonly title: string,
    public readonly description: string,
    public readonly help: string,
    public readonly required: boolean,
    public readonly showCondition: QuestionShowCondition
  ) {
  }
}

export class QuestionText extends Question {
  constructor(
    key: string,
    title: string,
    description: string,
    help: string,
    required: boolean,
    showCondition: QuestionShowCondition,
    public readonly multiLine: boolean,
    public readonly maxLength: number
  ) {
    super(
      QuestionType.TEXT,
      key,
      title,
      description,
      help,
      required,
      showCondition
    );
  }
}

export class QuestionSequence extends Question {
  readonly maxLength = 25000;

  constructor(
    key: string,
    title: string,
    description: string,
    help: string,
    required: boolean,
    showCondition: QuestionShowCondition
  ) {
    super(
      QuestionType.SEQUENCE,
      key,
      title,
      description,
      help,
      required,
      showCondition
    );
  }
}

export class QuestionCheckBox extends Question {
  constructor(
    key: string,
    title: string,
    description: string,
    help: string,
    showCondition: QuestionShowCondition,
    public readonly defaultValue: boolean
  ) {
    super(
      QuestionType.CHECKBOX,
      key,
      title,
      description,
      help,
      true,
      showCondition
    );
  }
}

export class QuestionOption {
  constructor(
    public readonly value: string,
    public readonly help: string
  ) {
  }
}

export class QuestionMultiChoice extends Question {
  constructor(
    key: string,
    title: string,
    description: string,
    help: string,
    required: boolean,
    showCondition: QuestionShowCondition,
    public readonly multiple: boolean,
    public readonly choices: QuestionOption[],
    public readonly freeText: boolean,
    public readonly maxLength: number
  ) {
    super(
      QuestionType.MULTICHOICE,
      key,
      title,
      description,
      help,
      required,
      showCondition
    );
  }
}

export class QuestionSampleNameOption extends Question {
  constructor(
    key: string,
    title: string,
    description: string,
    help: string,
    required: boolean,
    public readonly choices: QuestionOption[],
    public readonly maxLength: number
  ) {
    super(
      QuestionType.SAMPLENAME_OPTION,
      key,
      title,
      description,
      help,
      required,
      null
    );
  }
}


export class QuestionSampleNameList extends Question {
  constructor(
    key: string,
    title: string,
    description: string,
    help: string,
    required: boolean
  ) {
    super(
      QuestionType.SAMPLENAME_LIST,
      key,
      title,
      description,
      help,
      required,
      null
    );
  }
}

export class Section {
  constructor(
    public readonly key: string,
    public readonly title: string,
    public readonly description: string,
    public readonly questions: Question[]
  ) {
  }
}

export class FormStructure {
  constructor(
    public readonly title: string,
    public readonly description: string,
    public readonly sections: Section[]
  ) {
  }
}

export class Form {
  constructor(
    public readonly id: number,
    public readonly  type: FormType,
    public readonly  createdBy: User,
    public readonly  createdAt: Date,
    public readonly  structure: FormStructure,
  ) {
  }
}
