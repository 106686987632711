<h1 mat-dialog-title>Add an index kit plate</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field class="add-index-form" appearance="outline">
      <mat-select formControlName="indexKitModel" [(value)]="selectedIndexKitModel"
                  placeholder="Select an index kit model">
        <mat-option *ngFor="let index of indexKitsModel$ | async" [value]="index">
          {{index.name}}
        </mat-option>
      </mat-select>
      <mat-error align="start">You need to select an index kit model.</mat-error>
    </mat-form-field>

    <mat-form-field class="add-index-form" appearance="outline">
      <input matInput type="text" formControlName="barcode" placeholder="Enter a unique Index kit's barcode">
      <mat-hint align="start">The barcode must be unique</mat-hint>
      <mat-icon matSuffix class="valid" *ngIf="barcode.valid">checked</mat-icon>
    </mat-form-field>
  </form>


  <mat-error>{{errorMessage}}</mat-error>
</div>
<div mat-dialog-actions class="right-align">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" (click)="addIndexKit()" [disabled]="!this.form.valid">Add</button>
</div>
