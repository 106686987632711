import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SimplePlateWithRequests } from '../../../labware/models/simple-plate';
import { findPlateByAccessionCodeThenSelectFromReArray, getPlateList, removePlateFromReArray } from '../../../labware/store/plates';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Router } from '@angular/router';
import { findAllPlatesFromReArray } from '../../../labware/store/plates/actions/plate-list.action';
import { AddPlateDialogComponent } from '../../../labware/shared/components/add-plate-dialog/add-plate-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TaskAvailable } from '../../models/task.model';
import PlateDimensions from '../../../labware/models/plate-dimensions';
import { createNewEchoPoolingTransferPlate } from '../../../labware/store/plates/actions/plate-list.action';

import * as moment from 'moment';
export const RE_ARRAY_DESTINATION_PLATE_ID = 're-array/destination';

@Component({
  selector: 'nemo-re-array-left-zone',
  template: `
    <div *ngIf="withSelector" class="header">
      <button mat-stroked-button (click)="createPlate()" class="add-plate">New plate</button>

      <nemo-select-plate [plates$]="plates$"
                         (changeSelectedPlate)="updateSelectedPlate($event)"
                         [selectedPlateAccessionCode]="_plateAccessionCode"
                         inputPlaceHolder="Select target plate"></nemo-select-plate>
    </div>

    <div *ngIf="_plateAccessionCode">
      <nemo-plate-viewer-container [plateAccessionCode]="destinationPlateIdentifier"
                                   [isReadonly]="false"
                                   [showLegend]="true"
      ></nemo-plate-viewer-container>
      <div class="text-selection">
        <nemo-well-selection-input [plateAccessionCode]="destinationPlateIdentifier"></nemo-well-selection-input>
      </div>
    </div>
  `,
  styles: [`
    .header {
      display: flex;
    }

    .add-plate {
      width: 95px;
      height: 40px;
      margin-top: 3px;
      margin-right: 5px;
    }

    nemo-select-plate {
      flex: auto;
    }
  `]
})
export class ReArrayLeftZoneComponent implements OnInit {

  _plateAccessionCode: string;
  destinationPlateIdentifier = RE_ARRAY_DESTINATION_PLATE_ID;

  @Input() plateAccessionCode$: Observable<string>;
  @Input() sourcePlateAccessionCode: string;
  @Input() withSelector = true;
  plates$: Observable<SimplePlateWithRequests[]>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(findAllPlatesFromReArray({
      availableTask: TaskAvailable.RE_ARRAY_TO
    }));
    this.plates$ = this.store.select((getPlateList(TaskAvailable.RE_ARRAY_TO)));

    this.plateAccessionCode$.subscribe((ac) => {
      if (!Boolean(ac)) {
        this.store.dispatch(removePlateFromReArray({plateAccessionCode: this.destinationPlateIdentifier}));
        return;
      }
      this._plateAccessionCode = ac;
      this.store.dispatch(findPlateByAccessionCodeThenSelectFromReArray({
        accessionCode: ac,
        putPlateIdentifier: this.destinationPlateIdentifier
      }));
    });
  }

  updateSelectedPlate(accessionCode: string) {
    this.router.navigate(['/tasks/re-array/' + accessionCode + '/' + this.sourcePlateAccessionCode]);
  }

  createPlate(): void {
    if (this.sourcePlateAccessionCode.endsWith('_I')) {
      this.store.dispatch(createNewEchoPoolingTransferPlate({
          title: 'Echo pooling target - ' + this.sourcePlateAccessionCode + ' - ' + moment().format('MMMM Do YYYY, h:mm a'),
          dimensions: new PlateDimensions(16, 24),
          availableTask: TaskAvailable.RE_ARRAY_TO,
          sourceAccessionCode: this.sourcePlateAccessionCode
      }));
    } else {
      this.dialog.open(AddPlateDialogComponent, {
        data: {
          availableTask: TaskAvailable.RE_ARRAY_TO,
          sourceAccessionCode: this.sourcePlateAccessionCode
        },
        width: '500px'
      });
    }
  }
}
