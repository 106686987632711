/**
 * a User, as built by the authentication service
 */

export class User {
  id?: string;
  name: string;
  email?: string;
  picture?: string;
  emailName?: string;

  /**
   * constructor is not that pretty, but the idea is to be able to pass optional arguments
   * @param args a map with id?, name, email?, picture?
   */
  constructor(args: {
    id?: string,
    name: string,
    email?: string,
    picture?: string
  }) {
    this.id = args.id;
    this.name = args.name;
    this.email = args.email;
    this.picture = args.picture;
    this.emailName = this.email ? this.email.replace(/@.*/, '') : undefined;
  }
}
