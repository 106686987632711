import { Component, Input, OnInit } from '@angular/core';
import {
  saveLaneMapping,
  selectHasSelectedFlowCellConflict,
  selectHasSelectedFlowCellZeroConcentrationLanes,
  selectIsSelectedFlowCellSaved,
  selectIsSelectedFlowCellSequencerConfigurationStateImproper,
} from '../../../labware/store/flow-cells';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { FlowCell } from '../../../labware/models/flow-cell';
import { Observable } from 'rxjs';

@Component({
  selector: 'nemo-lane-assigner-save-button',
  template: `
    <button mat-flat-button
            color="primary"
            (click)="saveAssignment()"
            [disabled]="(isFlowCellBackendMirror$ | async)
            || (hasIndexConflict$ | async)
            || (hasZeroConcentrationLanes$ | async)
            || (hasImproperSequencerConfiguration$ | async)">
      Save Flow Cell
    </button>
  `,
  styles: [`
    button {
      font-size: 180%;
      margin-left: 3em;
    }
  `]
})
export class LaneAssignerSaveButtonComponent implements OnInit {

  @Input() flowCell: FlowCell;
  isFlowCellBackendMirror$: Observable<boolean>;
  hasIndexConflict$: Observable<boolean>;
  hasZeroConcentrationLanes$: Observable<boolean>;
  hasImproperSequencerConfiguration$: Observable<boolean>;

  constructor(public readonly store: Store<AppState>) {
  }

  ngOnInit() {
    this.isFlowCellBackendMirror$ = this.store.select(selectIsSelectedFlowCellSaved);
    this.hasIndexConflict$ = this.store.select(selectHasSelectedFlowCellConflict);
    this.hasZeroConcentrationLanes$ = this.store.select(selectHasSelectedFlowCellZeroConcentrationLanes);
    this.hasImproperSequencerConfiguration$ = this.store.select(selectIsSelectedFlowCellSequencerConfigurationStateImproper);
  }

  saveAssignment() {
    this.store.dispatch(saveLaneMapping({flowCell: this.flowCell}));
  }
}
