import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import TaskState, { TaskPlatQCAssignmentState } from './task.reducer';

export const getTaskState = (state: AppState) => state.task;

export const getTasks = createSelector(getTaskState, (state: TaskState) => state.tasks);

export const getTasksPlateQCAssignment = createSelector(getTaskState, (state: TaskState) => state.plateQCAssignment);

export const getTasksPlateQCAssignmentError = createSelector(
  getTasksPlateQCAssignment,
  (state: TaskPlatQCAssignmentState) => state.error
);


export const getTasksPlateQCAssignmentPlateAccessionCode = createSelector(
  getTasksPlateQCAssignment,
  (state: TaskPlatQCAssignmentState) => state.plateAccessionCode
);
