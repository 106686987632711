import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryMethodSettingsComponent } from './components/library-method-settings/library-method-settings.component';
import { MaterialModule } from '../../material.module';
import { LibraryMethodSettingsListComponent } from './components/library-method-settings/library-method-settings-list.component';
import { LibraryMethodSettingsAddComponent } from './components/library-method-settings/library-method-settings-add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SequencerModelSettingsAddComponent } from './components/sequencer-model-settings/sequencer-model-settings-add.component';
import { SequencerModelSettingsListComponent } from './components/sequencer-model-settings/sequencer-model-settings-list.component';
import { SequencerModelSettingsComponent } from './components/sequencer-model-settings/sequencer-model-settings.component';
import { LibraryMethodEditDialogComponent } from './components/library-method-settings/library-method-edit.component';


@NgModule({
  declarations: [
    LibraryMethodSettingsComponent,
    LibraryMethodSettingsListComponent,
    LibraryMethodSettingsAddComponent,
    LibraryMethodEditDialogComponent,
    SequencerModelSettingsComponent,
    SequencerModelSettingsAddComponent,
    SequencerModelSettingsListComponent
  ],
  exports: [
    LibraryMethodSettingsComponent,
    SequencerModelSettingsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class SequencingSharedModule {
}
