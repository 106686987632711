import { createAction, props } from '@ngrx/store';
import { SimplePoolWithRequest } from '../../models/pool';

export const findPools = createAction(
  '[Pool] Find all pools'
);

export const findAllPoolsByFlowCell = createAction(
  '[Flow cell details] Pools list for flow cell',
  props<{ pools: SimplePoolWithRequest[] }>()
);


export const poolError = createAction(
  '[Pool] Error',
  props<{ message: string }>()
);

export const poolColorReset = createAction(
  '[Pool] reset color scale'
);

export const poolColorUpdateList = createAction(
  '[Pool] update list of colored pools',
  props<{ accessionCodes: string[] }>()
);

export const archivePool = createAction(
  '[Pool Table] Archive pool',
  props<{ accessionCode: string }>()
);

export const archivePoolSuccess = createAction(
  '[Pool API] Archive pool success'
);

export const archivePoolError = createAction(
  '[Pool API] Archive pool error',
  props<{ message: string }>()
);

export const unarchivePool = createAction(
  '[Pool] Unarchive pool',
  props<{ accessionCode: string }>()
);

export const unarchivePoolSuccess = createAction(
  '[Pool API] Unarchive pool success'
);

export const unarchivePoolError = createAction(
  '[Pool API] Unarchive pool error',
  props<{ message: string }>()
);

export type PoolAction = ReturnType<| typeof findPools
  | typeof findAllPoolsByFlowCell
  | typeof poolError
  | typeof poolColorReset
  | typeof poolColorUpdateList
  | typeof unarchivePoolError
  | typeof archivePoolError>;
