import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Form } from '../../models/form';
import { FormRequestEditionDto } from '../../dtos/form-request.dto';
import { actionCompleteFormRequestStage, actionModifyFormRequest } from '../../store/form-request';

@Component({
  selector: 'nemo-accept-form-request',
  template: `
    <nemo-edit-form-request
      [form]="form"
      [formRequest]="formRequest"
      submitButtonText="Save your changes"
      (formSubmitted)="submit($event)"
      (isSavingNeeded)="changeSaveNeeded($event)"
    ></nemo-edit-form-request>
    <div
      class="accept-button"
    >
      <div class="accept-button-request-hint"
           *ngIf="isSavingNeeded"
      > (*) Save the changed data before accepting the request
      </div>
      <div>
        <button
          [disabled]="isSavingNeeded"
          mat-raised-button
          color="primary"
          (click)="completeAcceptance()"
        >
          Accept the Request and Proceed
        </button>
      </div>
    </div>
  `,
  styles: [`
    div.accept-button {
      margin-top: 10px;
    }

    div.accept-button-request-hint {
      color: royalblue;
    }

    button {
      width: 100%
    }
  `]
})
export class AcceptFormRequestComponent {
  @Input()
  formRequest: FormRequestEditionDto;

  @Input()
  form: Form;

  isSavingNeeded = false;

  constructor(
    private store: Store<AppState>,
  ) {
  }


  submit(formRequestEdition: FormRequestEditionDto) {
    this.store.dispatch(actionModifyFormRequest({
      formRequestEdition: formRequestEdition
    }));
  }

  changeSaveNeeded(b) {
    this.isSavingNeeded = b;
  }

  completeAcceptance() {
    this.store.dispatch(actionCompleteFormRequestStage({
      accessionCode: this.formRequest.accessionCode,
      stageKey: 'ACCEPTED'
    }));
  }
}
