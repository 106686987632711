import { NRequest, NRequestSummary, RequestSampleContainers } from '../models/genomics-request';
import {
  archiveRequestFromListSuccess,
  changeShowDetail,
  clearValidateRequestSample,
  completeStage,
  completeStageSuccess,
  findAllRequests,
  findAllRequestsFromHome,
  findAllRequestsSuccess,
  findRequestByAccessionCodeError,
  findRequestByAccessionCodePending,
  findRequestByAccessionCodeSuccess,
  findRequestError,
  reopenStage,
  reopenStageSuccess, restoreRequestFromListSuccess, searchAllRequests, searchAllRequestsSuccess,
  submitNewRequestError,
  submitNewRequestPending,
  submitNewRequestSuccess,
  updateRequestDictionary, updateRequestSuccess,
  validateRequestSampleSuccess
} from './genomics-request.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  assignUserToGenomicsRequestSuccess,
  unassignUserFromGenomicsRequestSuccess
} from '../../commons/common-request/store/common-request.actions';
import { PageInfo } from '../../shared/models/page.model';

export default interface GenomicsRequestState {
  containersPreview?: RequestSampleContainers;
  metadataHeaders?: String[];
  currentRequest?: NRequest;
  message: string;  // TODO too wide: document/rename me
  pageInfo: PageInfo | null;
  pending: boolean;
  requestDict: { [key: string]: NRequest };
  requests: NRequestSummary[];
  showDetail: Boolean;
}

export const initialState: GenomicsRequestState = {
  containersPreview: null,
  metadataHeaders: null,
  currentRequest: null,
  message: '',
  pageInfo: null,
  pending: false,
  requestDict: {},
  requests: [],
  showDetail: false
};

const genomicsRequestReducer = createReducer(
  initialState,
  on(
    submitNewRequestPending,
    completeStage,
    reopenStage,
    (state) => (
      {...state, pending: true, message: ''}
  )),
  on(
    findAllRequests,
    findAllRequestsFromHome,
    searchAllRequests,
    (state) => (
      {...state, pending: true, requests: [], message: ''}
  )),
  on(findAllRequestsSuccess,
    searchAllRequestsSuccess,
     (state, {requests, pageInfo}): GenomicsRequestState => (
	 {...state, pending: false, pageInfo: pageInfo, requests: requests}
  )),
  on(
    findRequestByAccessionCodeSuccess,
    completeStageSuccess,
    reopenStageSuccess,
    assignUserToGenomicsRequestSuccess,
    unassignUserFromGenomicsRequestSuccess,
    updateRequestSuccess,
    (state, {request}) => (
      {...state, pending: false, currentRequest: request}
  )),
  on(
    findRequestError,
    submitNewRequestError,
    findRequestByAccessionCodeError,
    (state, {message}) => (
      {...state, pending: false, message: message}
  )),
  on(changeShowDetail, (state) => (
    {...state, showDetail: !state.showDetail}
  )),
  on(findRequestByAccessionCodePending, (state) => (
    {...state, pending: true, currentRequest: null, message: ''}
  )),
  on(updateRequestDictionary, (state, {request}) => (
    {...state, requestDict: {...state.requestDict, [request.accessionCode]: request}}
  )),
  on(submitNewRequestSuccess, (state) => (
    {...state, pending: false, message: 'Request added with success'}
  )),
  on(clearValidateRequestSample, (state) => (
    {...state, containersPreview: null, message: null, metadataHeaders: []}
  )),
  on(validateRequestSampleSuccess, (state, {containers, metadataHeaders}) => (
    {...state, containersPreview: containers, metadataHeaders: metadataHeaders, message: 'Sample data are valid'}
  )),
  on(archiveRequestFromListSuccess, (state, {accessionCode}) => {
    const newRequestMap = toggleArchivedInRequestList(state, accessionCode, true);
    return {...state, requests: newRequestMap};
  }),
  on(restoreRequestFromListSuccess, (state, {accessionCode}) => {
    const newRequestMap = toggleArchivedInRequestList(state, accessionCode, false);
    return {...state, requests: newRequestMap};
  })
);

function toggleArchivedInRequestList(
  state: GenomicsRequestState,
  accessionCode: string,
  archived: boolean
): NRequestSummary[] {
  return state.requests.map(request => {
    if (request.accessionCode === accessionCode) {
      return {...request, archived};
    } else {
      return request;
    }
  });
}

export function reducer(state: GenomicsRequestState | undefined, action: Action) {
  return genomicsRequestReducer(state, action);
}
