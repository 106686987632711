import {User} from '../models/user.model';
import {
  loadAllUserDetailsFromApiError,
  loadAllUserDetailsFromApiSuccess,
  loadUserDetailsError,
  loadUserDetailsPending,
  loadUserDetailsSuccess,
  storeLoggedInUser,
  UsersActions,
  findAllowedUsersForReport,
  findAllowedUsersForReportSuccess
} from './actions/user.actions';

export default interface UserState {
  loggedIn: User | null;
  pendingUserLoad: { [key: string]: boolean };
  userDict: { [key: string]: User };
  errorMessage: string;
  allowedUsersForReport: string[];
}


export const initialState: UserState = {
  loggedIn: null,
  pendingUserLoad: {},
  userDict: {},
  errorMessage: null,
  allowedUsersForReport: []
};

export function reducer(state: UserState = initialState, action: UsersActions) {
  switch (action.type) {
    case storeLoggedInUser.type: {
      const {user} = action;
      return {...state, loggedIn: user};
    }
    case loadUserDetailsPending.type: {
      const {userId} = action;
      return {...state, pendingUserLoad: {...state.pendingUserLoad, [userId]: true}};
    }
    case loadUserDetailsSuccess.type: {
      const {user} = action;
      return {
        ...state,
        userDict: {...state.userDict, [user.id]: user},
        pendingUserLoad: {...state.pendingUserLoad, [user.id]: false}
      };
    }
    case loadUserDetailsError.type: {
      const {userId, message} = action;
      return {
        ...state,
        userDict: {...state.userDict, [userId]: null},
        pendingUserLoad: {...state.pendingUserLoad, [userId]: false},
        errorMessage: message
      };
    }
    case loadAllUserDetailsFromApiSuccess.type: {
      const {users} = action;
      const newUserDict = {...state.userDict};
      users.map((u) => newUserDict[u.id] = u);
      return {...state, userDict: newUserDict, errorMessage: null};
    }
    case loadAllUserDetailsFromApiError.type: {
      return {...state, errorMessage: action.message};
    }
    case findAllowedUsersForReport.type: {
      return {...state, allowedUsersForReport: []};
    }
    case findAllowedUsersForReportSuccess.type: {
      return {...state, allowedUsersForReport: action.allowedUsersForReport};
    }
    default:
      return state;
  }
}
