import * as d3 from 'd3-selection';
import { WellData } from '../../../models/well-data';
import { BioMaterial, BioMaterialTypes } from '../../../../bio/models/bio-material';
import { Index } from '../../../../bio/models/barcode-index';
import { PoolSampleMix, PoolSamplePlaceholder } from '../../../../bio/models/pool';


export class Tooltip {

  tooltip: any;

  private static formatSample(wellData: WellData, content: PoolSamplePlaceholder): string {
    const sample = content.sample;
    let text = sample.name;
    if (sample.isIndexed()) {
      const assignedIndex = sample.assignedIndex;
      const name = assignedIndex.model ? assignedIndex.model.name : assignedIndex.sequences.map(
        (seq) => seq.terminus + ':' + seq.sequence).join('<br />');
      text += ' + ' + name;
      text += '<br />(' + wellData.plateCoordinates.toString() + ')';
    }
    return text;
  }

  private static formatPoolSampleMix(wellData: WellData, content: PoolSampleMix): string {
    return content.accessionCode + '<br/>' +
      content.listRequestAccessionCode().join(',') +
      '<br />(' + wellData.plateCoordinates.toString() + ')';
  }

  private static formatIndex(content: Index): string {
    return content.model.name;
  }

  static formatContent(wellData: WellData, content: BioMaterial) {
    if (!content) {
      return '';
    }
    switch (content.type) {
      case BioMaterialTypes.poolSamplePlaceholder: {
        return Tooltip.formatSample(wellData, content as PoolSamplePlaceholder);
      }
      case BioMaterialTypes.poolSampleMix: {
        return Tooltip.formatPoolSampleMix(wellData, content as PoolSampleMix);
      }
      case BioMaterialTypes.index: {
        return Tooltip.formatIndex(content as Index);
      }
      default: {
        return '';
      }
    }
  }

  create() {
    this.tooltip = d3.select('body')
      .append('div')
      .attr('class', 'tooltip mat-tooltip')
      .style('opacity', 0);
  }

  show(event, content: BioMaterial, wellData: WellData) {
    this.tooltip
      .transition()
      .duration(100)
      .style('opacity', .9);
    this.tooltip
      .style('left', (event.pageX) + 'px')
      .style('top', (event.pageY - 28) + 'px');
    const text = Tooltip.formatContent(wellData, content);
    this.tooltip
      .html(text);
  }

  hide() {
    this.tooltip.transition()
      .duration(200)
      .style('opacity', 0);
  }
}
