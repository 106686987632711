import { Component, Input, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { getPlateByIdentifier } from '../../../../labware/store/plates';
import { AppState } from '../../../../store/app.reducers';
import { combineLatest, Observable } from 'rxjs';
import { Plate96 } from '../../../../labware/models/plate-96';
import { ECHO_POOLING_SOURCE_PLATE_ID } from '../echo-pooling-right-zone.component';
import { ECHO_POOLING_DESTINATION_PLATE_ID } from '../echo-pooling-left-zone.component';
import { map } from 'rxjs/operators';
import { BioMaterialPlateMapping } from '../../../../labware/models/bio-material-plate-mapping';
import { Pool, PoolSampleMix } from '../../../../bio/models/pool';
import {
  EchoPooling,
  mergeToEchoPooling, sortEchoPoolingBySource,
  toEchoPooling,
  toEchoPoolingProcessed
} from '../../services/echo-pooling.service';

@Component({
  selector: 'nemo-echo-pooling-table',
  templateUrl: './echo-pooling-table.component.html',
  styleUrls: ['./echo-pooling-table.component.scss']
})
export class EchoPoolingTableComponent implements OnInit {

  echoPoolings: EchoPooling[];
  sourceMappings$: Observable<BioMaterialPlateMapping<Pool>[]>;
  targetMappings$: Observable<BioMaterialPlateMapping<Pool>[]>;
  displayedColumns: string[] = [
    'request_accession_code',
    'accession_code',
    'position',
    'ng/uL',
    '%total',
    'nmole/L',
    'transfer_volume',
    'destination_well'
  ];

  @Input() processed = false;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.sourceMappings$ = this.store.select(getPlateByIdentifier(ECHO_POOLING_SOURCE_PLATE_ID)).pipe(
      
      map((plate: Plate96<Pool>) => plate ? plate.getNonEmptyBioMaterialMappings() : undefined)
    );
    this.targetMappings$ = this.store.select(getPlateByIdentifier(ECHO_POOLING_DESTINATION_PLATE_ID)).pipe(
      
      map((plate: Plate96<Pool>) => plate ? plate.getNonEmptyBioMaterialMappings() : undefined)
    );

    combineLatest([this.sourceMappings$, this.targetMappings$])
      .subscribe(([sourceMappings, targetMappings]) => {
          if (this.processed && targetMappings) {
            this.echoPoolings = toEchoPoolingProcessed(targetMappings as BioMaterialPlateMapping<PoolSampleMix>[]);
          } else if (sourceMappings && targetMappings) {
            const echoPoolings = toEchoPooling(sourceMappings);
            this.echoPoolings = mergeToEchoPooling(echoPoolings, targetMappings);
          } else if (sourceMappings) {
            this.echoPoolings = toEchoPooling(sourceMappings);
          }
          // sort echo pooling by source position column
          sortEchoPoolingBySource(this.echoPoolings);
        }
      );
  }
}
