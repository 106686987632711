import { Component, Input, OnInit } from '@angular/core';
import { WellSelectionListener } from './well-selection-listener';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../store/app.reducers';
import {
  isSelectionStringValid,
  parseSelection,
  PlateCoordinatesSelection
} from '../../../models/plate-coordinates-selection';
import PlateDimensions from '../../../models/plate-dimensions';
import { setPlateSelection } from '../../../store/plates';


@Component({
  selector: 'nemo-well-selection-input',
  templateUrl: './well-selection-input.component.html',
  styleUrls: ['./well-selection-input.component.scss']
})
/**
 * If well can be selected and displayed on a graphical plate, they can also be displayed on a text field
 * And here is the widget to do so.
 *
 * The constructor will pass the function to be executed whenever the selection is updated (onSelectionUpdate)
 *
 * TODO: be able to input a selection through this component
 */
export class WellSelectionInputComponent extends WellSelectionListener implements OnInit {

  @Input() plateAccessionCode: string;

  wellSelection: PlateCoordinatesSelection;
  selectionString = '';
  countSelected = 0;
  errorMessage: string;

  constructor(
    public store: Store<AppState>,
  ) {
    // tell the inherited WellSelectionListener what to do when a new plateCoordinatesSelection is received
    super((wellSelection: PlateCoordinatesSelection) => {
      this.wellSelection = wellSelection;
      this.selectionString = wellSelection.toString();
      this.countSelected = wellSelection.countSelected();
    }, store);
  }

  ngOnInit() {
    this.updateWellListener();
  }

  updateSelectionString(value: string) {
    if (!isSelectionStringValid(value, this.wellSelection.dimension)) {
      this.errorMessage = 'not a valid range';
      return;
    }
    this.errorMessage = undefined;
    // TODO get the dimensions from the plate, somehow
    const wellSelection = parseSelection(value, new PlateDimensions(8, 12));
    this.store.dispatch(setPlateSelection({
      plateAccessionCode: this.plateAccessionCode,
      plateCoordinatesSelection: wellSelection
    }));
  }

}
