import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

import { PoolWithRequest, SimplePoolWithRequest } from '../models/pool';
import { PoolCounts, PoolWithRequestDto, SimplePoolWithRequestDto } from '../dto/bio.dto';

import { UserService } from '../../user/services/user.service';
import { poolWithRequestDtoToPoolWithRequest, simplePoolWithRequestDtoToSimplePoolWithRequest } from './bio.mapper';

export const POOL_BASE_URI = environment.apiUrl + '/pools';

@Injectable({
  providedIn: 'root'
})
export class PoolApiService {

  constructor(
    private userService: UserService,
    private readonly http: HttpClient
  ) {
  }

  findByAccessionCde(accessionCode: string): Observable<PoolWithRequest> {
    return this.http.get<PoolWithRequestDto>(POOL_BASE_URI + '/' + accessionCode).pipe(
      map(poolWithRequestDtoToPoolWithRequest)
    );
  }

  findAll(showArchived: boolean | null): Observable<SimplePoolWithRequest[]> {
    const queryParams = {};

    if (showArchived !== null && showArchived !== undefined) {
      queryParams['showArchived'] = showArchived;
    }

    return this.http.get<SimplePoolWithRequestDto[]>(POOL_BASE_URI, {params: queryParams})
      .pipe(map(poolDtos => poolDtos.map(simplePoolWithRequestDtoToSimplePoolWithRequest)));
  }

  count(): Observable<PoolCounts> {
    return this.http.get<PoolCounts>(POOL_BASE_URI + '/count');
  }

  search(query: string, combined: boolean, timeRange: string): Observable<SimplePoolWithRequest[]> {
    const defaultTimeRange = '93';
    if ((query == null || query.length <= 0) && combined === false && timeRange === defaultTimeRange) {
      return this.findAll(null);
    }

    const uri = POOL_BASE_URI + '?q=' + query + '&combined=' + combined + '&days=' + timeRange;
    return this.http.get<SimplePoolWithRequestDto[]>(uri).pipe(
      map(poolsDto =>
        poolsDto.map(poolDto => simplePoolWithRequestDtoToSimplePoolWithRequest(poolDto))
      )
    );
  }

  archive(accessionCode: string): Observable<void> {
    return this.http.delete<void>(POOL_BASE_URI + '/' + accessionCode);
  }

  unarchive(accessionCode: string): Observable<void> {
    return this.http.post<void>(POOL_BASE_URI + '/' + accessionCode + '/unarchive', null);
  }
}
