<div class="container">

  <!-- Samples plate -->
  <nemo-index-assigner-left-zone></nemo-index-assigner-left-zone>

  <!-- Middle zone with buttons and message -->
  <nemo-index-assigner-middle-zone [samplePlate]="samplePlate"
                                   [indexPlate]="indexPlate"
                                   [canAssign]="canAssign"
                                   [currentSelectionSamples]="currentSelectionSamples"
                                   [currentSelectionIndexes]="currentSelectionIndexes"
                                   [samplePlateIndexMapping]="samplePlateIndexMapping"
                                   [infoMessage]="infoMessage"
                                   [infoMessageStyle]="infoMessageStyle"></nemo-index-assigner-middle-zone>

  <!-- Index plate -->
  <nemo-index-assigner-right-zone></nemo-index-assigner-right-zone>
</div>
