import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';
import { selectSelectedAndExistingFlowCellConflict } from '../../../labware/store/flow-cells';
import { IndexConflictWithLaneNumber } from '../../../labware/models/flow-cell-mapping';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nemo-lane-assignment-conflict-viewer',
  template: `
    <div *ngIf="hasConflicts$ | async">
      <h2>Index conflicts</h2>

      <div class="conflicts-list">
        <nemo-lane-assignment-conflict-item *ngFor="let conflict of conflicts$ | async"
                                            [conflict]="conflict"></nemo-lane-assignment-conflict-item>
      </div>
    </div>
  `,
  styles: [`
    .conflicts-list {
      display: flex;
      margin-bottom: 1em;
    }
  `]
})
export class LaneAssignmentConflictViewerComponent implements OnInit {

  conflicts$: Observable<IndexConflictWithLaneNumber[]>;
  hasConflicts$: Observable<boolean>;

  constructor(
    public readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.conflicts$ = this.store.select(selectSelectedAndExistingFlowCellConflict)
    this.hasConflicts$ = this.conflicts$.pipe(map(cs => cs.length > 0));
  }
}
