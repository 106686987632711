import { Pipe, PipeTransform } from '@angular/core';
import { SimpleRequest } from '../../genomics-request/models/genomics-request';

@Pipe({
  name: 'requestsAccessionCode'
})
export class RequestsAccessionCodePipe implements PipeTransform {

  transform(requests: SimpleRequest[]): string[] {
    if (requests) {
      return requests.map(request => request.accessionCode);
    }
  }
}
