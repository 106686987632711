import * as _ from 'lodash';
import { FlowCellMapping } from '../models/flow-cell-mapping';
import { SimpleRequest } from '../../genomics-request/models/genomics-request';

export function getAllRequestsFromFlowCellLayout(layout: Array<FlowCellMapping>): SimpleRequest[] {
  return _.chain(layout)
    .flatMap((mapping) => mapping.simplePoolWithRequest.requests)
    .uniqBy('accessionCode')
    .value();
}


