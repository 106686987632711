import { createAction, props } from '@ngrx/store';
import { Task } from '../models/task.model';
import { IndexAssignmentDto } from '../../index/models/assigned-index.dto';
import { ReArrayDto } from '../re-array/services/rearray-api.service';
import { PlateCoordinates } from '../../labware/models/plate-coordinates';
import { InvalidPlateQCInstrumentDirectoryError } from '../plate-qc-assignment/models/invalid-plate-qc-instrument-directory-error';


/* re array */
export const reArray = createAction(
  '[Re Array Page] Re array',
  props<{ plateAccessionCode: string, reArrayDtos: Array<ReArrayDto> }>()
);

export const reArrayError = createAction(
  '[Task API] Re array error',
  props<{ message: string }>()
);

export const undoReArray = createAction(
  '[Re Array Page] Undo re array',
  props<{ plateAccessionCode: string, plateCoordinates: Array<PlateCoordinates> }>()
);

export const undoReArrayError = createAction(
  '[Re Array Page] Undo re array error',
  props<{ message: string }>()
);

/* Finding task by request */

export const findTaskByRequest = createAction(
  '[Request Page] Find all tasks by request',
  props<{ requestAccessionCode: string }>()
);

export const findTaskByRequestSuccess = createAction(
  '[Task API] Find all tasks by request success',
  props<{ tasks: Task[] }>()
);

export const findTaskByRequestError = createAction(
  '[Task API] Find all tasks by request error',
  props<{ message: string }>()
);

/* Index assignment */

export const assignIndexes = createAction(
  '[Index Assignment Page] Assign indexes',
  props<{ indexAssignments: IndexAssignmentDto[], accessionCode: string, indexKitId: number }>()
);

export const unassignIndexes = createAction(
  '[Index Assignment Page] Unassign indexes',
  props<{ indexAssignments: IndexAssignmentDto[], accessionCode: string, indexKitId?: number }>()
);

export const completeIndexAssignment = createAction(
  '[Task API] Complete index assignment',
  props<{ accessionCode: string }>()
);

export const completeIndexAssignmentSuccess = createAction(
  '[Task API] Complete index assignment success',
  props<{ accessionCode: string }>()
);

export const completeIndexAssignmentError = createAction(
  '[Task API] Complete index assignment error',
  props<{ message: string }>()
);

/* assign QC */

export const validatePlateQCAssignment = createAction(
  '[QC assign Page] validate index assignment',
  props<{ plateAccessionCode: string, instrumentDirectoryAccessionCode: string }>()
);
export const validatePlateQCAssignmentSuccess = createAction(
  '[Task API] validate index assignment success'
);

export const validatePlateQCAssignmentInvalid = createAction(
  '[Task API] validate index assignment invalid',
  props<{ error: InvalidPlateQCInstrumentDirectoryError }>()
);

export const validatePlateQCAssignmentError = createAction(
  '[Task API] validate index assignment error',
  props<{ message: string }>()
);

export const completePlateQCAssignment = createAction(
  '[QC assign Page] complete assign plate to QC instrument directory',
  props<{ plateAccessionCode: string, instrumentDirectoryAccessionCode: string }>()
);

export const completePlateQCAssignmentSuccess = createAction(
  '[Task API] assign plate to QC instrument directory success',
  props<{ accessionCode: string }>()
);

export const completePlateQCAssignmentError = createAction(
  '[Task API] assign plate to QC instrument directory',
  props<{ message: string }>()
);

export const unassignPlateQCFromInstrumentDirectoryPage = createAction(
  '[Instrument directory  Page]  unassign QC from plate',
  props<{ plateAccessionCode: string, instrumentDirectoryAccessionCode: string }>()
);

export const unassignPlateQCFromInstrumentDirectoryList = createAction(
  '[Instrument directory  List]  unassign QC from plate',
  props<{ plateAccessionCode: string, instrumentDirectoryAccessionCode: string }>()
);

export const unassignPlateQCSuccess = createAction(
  '[Task API] unassign plate QC success',
  props<{ accessionCode: string }>()
);

export const unassignPlateQCError = createAction(
  '[Task API] unassign plate QC success',
  props<{ message: string }>()
);


export type TaskAction = ReturnType<| typeof findTaskByRequestSuccess
  | typeof findTaskByRequestError
  | typeof reArrayError
  | typeof undoReArrayError
  | typeof validatePlateQCAssignment
  | typeof validatePlateQCAssignmentSuccess
  | typeof validatePlateQCAssignmentInvalid
  | typeof validatePlateQCAssignmentError
  | typeof completePlateQCAssignment
  | typeof completePlateQCAssignmentSuccess
  | typeof completePlateQCAssignmentError
  | typeof unassignPlateQCFromInstrumentDirectoryPage
  | typeof unassignPlateQCFromInstrumentDirectoryList
  | typeof unassignPlateQCSuccess
  | typeof unassignPlateQCError>;
