<div class="left-navbar" *ngIf="isLoggedIn|async">
  <nemo-new-button></nemo-new-button>

  <a *ngFor="let item of nav">
    <mat-divider
      *ngIf="item.divider && (isWithinContext$(item.userRole, item.lab) | async)"
      class="divider"
    ></mat-divider>
    <div
      *ngIf="(isWithinContext$(item.userRole, item.lab) | async)"
      routerLink="{{item.path}}"
      class="navbar-item"
      [ngClass]="{'active-navbar-item': item.active}"
    >
      <div class="navbar-item-text">
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.title}}</span>
      </div>
    </div>
  </a>
</div>
