import { createAction, props } from '@ngrx/store';
import { Plate96 } from '../../../models/plate-96';
import { Pool } from '../../../../bio/models/pool';

export const countPlatesError = createAction(
  '[Plates API] Count plates error',
  props<{ message: string }>()
);

export const newPlateSuccess = createAction(
  '[Plates API] new plate success',
  props<{ plate: Plate96<Pool> }>()
);

export const navigateToEchoPooling = createAction(
  '[Plates API] navigate to echo pooling',
  props<{ destinationAccessionCode: string, sourceAccessionCode: string }>()
);

export const navigateToReArray = createAction(
  '[Plates API] navigate to re array',
  props<{ destinationAccessionCode: string, sourceAccessionCode: string }>()
);

export const newPlateError = createAction(
  '[Plates API] new plate error',
  props<{ message: string }>()
);

export type PlateApiAction = ReturnType<| typeof countPlatesError
  | typeof newPlateSuccess
  | typeof newPlateError>;
