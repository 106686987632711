import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { TaskDto } from '../dtos/task.dto';
import { toTasks } from './task.mapper';
import { Observable } from 'rxjs';
import { Task } from '../models/task.model';
import { Page } from '../../shared/models/page.model';
import { UserService } from '../../user/services/user.service';

export const TASK_BASE_URI = environment.apiUrl + '/tasks';

@Injectable({providedIn: 'root'})
export class TaskApiService {

  constructor(private http: HttpClient, private userService: UserService) {
  }

  findByRequest(requestAccessionCode: string): Observable<Task[]> {
    const queryParams = {requestAccessionCode};
    return this.http.get<Page<TaskDto>>(TASK_BASE_URI, {params: queryParams}).pipe(
      mergeMap(taskDtos => this.userService.findAll().pipe(
        map(users => ({users, taskDtos}))
      )),
      map(data => toTasks(data.taskDtos.content, data.users))
    );
  }
}
