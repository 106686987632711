import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.auth.issuer,
  redirectUri: window.location.origin,
  clientId: environment.auth.clientId,
  strictDiscoveryDocumentValidation: environment.auth.strictDiscoveryDocumentValidation,
  scope: environment.auth.scope,
  silentRefreshRedirectUri: environment.auth.silentRefreshRedirectUri,
  showDebugInformation: environment.auth.showDebugInformation,
  timeoutFactor: environment.auth.timeoutFactor,
  useIdTokenHintForSilentRefresh: true
};
