import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { ReportingService } from '../../../services/reporting-service';
import { selectAllowedUsersForReport, selectLoggedInUserEmail } from '../../../../user/store';
import { saveAs } from 'file-saver';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'nemo-reporting-home',
  template: `<h2>Reporting</h2>
  <iframe *ngIf="(allowedUsers$ | async).includes((userEmail$ | async))" width=80% height=100%
          src="https://lookerstudio.google.com/embed/reporting/3acdccd2-f8a7-4a8f-8c1e-71d1e6571fa7/page/p7jcD"
          allowfullscreen></iframe>
  <button mat-raised-button
          color="primary"
          (click)="downloadRequestDump()">Download all requests</button>
  `,
  styles: [`
    iframe {
      display: block;
      margin-bottom: 16px;
      border: 0;
    }
  `]
})
export class ReportingHomeComponent implements OnInit {
  userEmail$: Observable<String>;
  allowedUsers$: Observable<String[]>;

  constructor(
    private readonly reportingService: ReportingService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.userEmail$ = this.store.select(selectLoggedInUserEmail).pipe(filter(email => Boolean(email)));
    this.allowedUsers$ = this.store.select(selectAllowedUsersForReport);
  }

  downloadRequestDump() {
    this.reportingService.exportRequestDump()
      .subscribe(({filename, content}) => {
        const blob = new Blob([content], {type: 'text/csv'});
        saveAs(blob, filename);
      });
  }
}
