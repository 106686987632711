import { createSelector } from '@ngrx/store';
import GenomicsRequestState from './genomics-request.reducer';
import { AppState } from '../../store/app.reducers';

export const getGenomicsRequestState = (state: AppState) => state.genomicsRequest;

export const selectIsGenomicsRequestPending = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.pending
);
export const selectShowDetail = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.showDetail
);
export const selectRequests = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.requests
);
export const selectGenomicsCurrentRequest = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.currentRequest
);
export const selectGenomicsMessage = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.message
);
export const selectGenomicsContainersPreview = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.containersPreview
);
export const selectGenomicsRequestDictionary = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.requestDict
);
export const selectGenomicsImportedMetadata = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.metadataHeaders
);

export const selectGenomicsRequestPageInfo = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.pageInfo
);

export const selectAllCros = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.cros
);
