import {
  Pool,
  PoolSampleMix,
  PoolSamplePlaceholder,
  PoolSource,
  PoolWithRequest,
  SimplePoolWithRequest
} from '../models/pool';
import {
  PoolSampleMixDto,
  PoolSamplePlaceholderDto,
  PoolWithRequestDto,
  SampleDto,
  SimplePoolWithRequestDto
} from '../dto/bio.dto';
import { SimpleRequest } from '../../genomics-request/models/genomics-request';
import { Sample } from '../models/sample';
import { Index } from '../models/barcode-index';
import { assignedIndexDtoToIndex } from '../../labware/services/plate-index.mapper';
import { dtoToFragmentAnalyzerQCMeasure } from '../../qc/services/qc-measures.mapper';

export function simplePoolWithRequestDtoToSimplePoolWithRequest(dto: SimplePoolWithRequestDto) {
  return new SimplePoolWithRequest(
    dto.creationDate,
    dto.accessionCode,
    dto.title,
    dto.numberOfSamples,
    dto.archived,
    dto.requests.map((reqDto) => new SimpleRequest(
      reqDto.accessionCode,
      reqDto.requesterId,
      [...reqDto.assignedToIds],
      reqDto.title,
      reqDto.description,
      reqDto.createdAt,
      reqDto.libraryMethod,
      reqDto.phiXPercentage
    ))
  );
}

/**
 * unmarshall a pool Dto
 * As we can receive either a mix or a simple placeholder based on the presence of the 'samples' atrtibutes (mix).
 * The  placeholder has a 'sample' single element
 */
export function poolDtoToPool(dto: PoolSampleMixDto | PoolSamplePlaceholderDto): Pool {
  if (dto['samples']) {
    return poolSampleMixDtoToPool(dto as PoolSampleMixDto);
  }
  return poolSamplePlaceholderDtoToPool(dto as PoolSamplePlaceholderDto);
}

export function poolSampleMixDtoToPool(dto: PoolSampleMixDto): PoolSampleMix {
  return new PoolSampleMix(
    dto.creationDate,
    dto.accessionCode,
    dto.title,
    dto.samples.map(sampleDtoToSample),
    dto.fragmentAnalyzerQc && dtoToFragmentAnalyzerQCMeasure(dto.fragmentAnalyzerQc),
    dto.poolSources
    ? dto.poolSources.map(poolSource =>
      new PoolSource(poolSource.accessionCode, poolSource.transferVolume, poolSource.coordinates)
    )
    : [],
    dto.archived
  );
}

export function poolSamplePlaceholderDtoToPool(dto: PoolSamplePlaceholderDto): PoolSamplePlaceholder {
  return new PoolSamplePlaceholder(
    dto.creationDate,
    dto.accessionCode,
    dto.title,
    sampleDtoToSample(dto.sample),
    dto.fragmentAnalyzerQc && dtoToFragmentAnalyzerQCMeasure(dto.fragmentAnalyzerQc),
    dto.archived
  );
}

export function sampleDtoToSample(sampleDto: SampleDto): Sample {
  const index: Index = sampleDto.assignedIndex ? assignedIndexDtoToIndex(sampleDto.assignedIndex) : null;
  return new Sample(
    sampleDto.name,
    sampleDto.id,
    index,
    sampleDto.accessionCode,
    sampleDto.requestAccessionCode,
    sampleDto.metadata
  );
}

export function poolWithRequestDtoToPoolWithRequest(dto: PoolWithRequestDto): PoolWithRequest {
  return new PoolWithRequest(
    dto.accessionCode,
    dto.title,
    dto.creationDate,
    dto.samples.map(sampleDtoToSample),
    dto.fragmentAnalyzerQc && dtoToFragmentAnalyzerQCMeasure(dto.fragmentAnalyzerQc),
    dto.archived,
    dto.requests
  );
}
