import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePlateDto, PlateCounts } from '../dto/plate.dto';
import { environment } from '../../../environments/environment';
import { Plate96 } from '../models/plate-96';
import { SamplePlateDto } from '../../genomics-request/models/genomics-request.dto';
import { JSON_CONTENT } from '../../constants/http';
import { map } from 'rxjs/operators';
import { samplePlateDtoToPlate96 } from './plate.mapper';
import { Pool } from '../../bio/models/pool';

export const PLATE_BASE_URI = environment.apiUrl + '/plates';

@Injectable({providedIn: 'root'})
export class PlateApiService {

  constructor(private http: HttpClient) {
  }

  findByAccessionCode(ac: string): Observable<Plate96<Pool>> {
    return this.http
      .get<SamplePlateDto>(PLATE_BASE_URI + '/' + ac, {headers: JSON_CONTENT})
      .pipe(map(samplePlateDtoToPlate96));
  }

  createPlate(newPlate: CreatePlateDto): Observable<Plate96<Pool>> {
    return this.http
      .post<SamplePlateDto>(PLATE_BASE_URI, newPlate, {headers: JSON_CONTENT})
      .pipe(map(samplePlateDtoToPlate96));
  }

  patchOneField(accessionCode: string, field: string, value: string): Observable<Plate96<Pool>> {
    return this.http.patch<SamplePlateDto>(PLATE_BASE_URI + '/' + accessionCode, {[field]: value})
      .pipe(
        map(samplePlateDtoToPlate96)
      );
  }

  count(): Observable<PlateCounts> {
    return this.http.get<PlateCounts>(PLATE_BASE_URI + '/count');
  }
}
