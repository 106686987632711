import { Component } from '@angular/core';

@Component({
  selector: 'nemo-projects',
  template: `
    <div class="container">
      <h3>Add a new project</h3>
      <nemo-projects-add></nemo-projects-add>
      <h3>Existing projects</h3>
      <nemo-projects-list></nemo-projects-list>
    </div>
  `,
  styles: [`
               table {
                 width: 100%;
               }
             `]
})
export class ProjectsComponent {
  constructor() {
  }

}
