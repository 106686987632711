import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'nemo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  isLoggedIn: Observable<boolean>;
  pictureUrl: Observable<string>;

  constructor(
    private authService: AuthService,
  ) {
    this.isLoggedIn = this.authService.isAuthenticated$;
    this.pictureUrl = this.authService.pictureUrl;
  }
}
