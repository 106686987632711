import { createAction, props } from '@ngrx/store';
import { Project } from '../models/Project';
import { Department } from '../models/Department';

export const findAllProjects = createAction(
  '[Init Data] find all Projects'
);

export const findAllProjectsAfterUpdate = createAction(
  '[After update] find all projects'
);

export const findAllProjectsSuccess = createAction(
  '[Project API]  all Projects list Success',
  props<{ projects: Project[] }>()
);

export const findAllProjectsError = createAction(
  '[Project API]  all Projects list Error',
  props<{ message: string }>()
);

export const createProject = createAction(
  '[Projects page] create new project',
  props<{ name: string, code: number }>()
);

export const archiveProject = createAction(
  '[Projects page] archive project',
  props<{ project: Project }>()
);

export const restoreProject = createAction(
  '[Projects page] restore project',
  props<{ project: Project }>()
);

export type ProjectAction = ReturnType<| typeof findAllProjects
  | typeof findAllProjectsSuccess
  | typeof findAllProjectsError>;
