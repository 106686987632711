import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Section } from '../../models/form';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'nemo-form-section',
  template: `
    <mat-expansion-panel
      class="section"
      [expanded]="!disabled"
      [hideToggle]="true"
      (afterExpand)="expandPanel()"
      (afterCollapse)="collapsePanel()"
    >
      <mat-expansion-panel-header
        [ngClass]="{disabled:disabled}"
      >
        <mat-panel-title>
          {{section.title}}
        </mat-panel-title>
        <mat-panel-description>
          <span>&nbsp;</span>
          <mat-slide-toggle
            color="primary"
            [checked]="!disabled"
          ></mat-slide-toggle>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="description">
        {{section.description}}
      </div>
      <nemo-form-question
        *ngFor="let question of section.questions"
        [question]="question"
        [sectionFormGroup]="sectionFormGroup"
      >

      </nemo-form-question>
    </mat-expansion-panel>

  `,
  styles: [`
    mat-panel-title {
      min-height: 2em;
    }
             .mat-expansion-panel-header-description {
               justify-content: space-between;
               align-items: center;
             }

             .mat-expansion-panel-header {
               background: burlywood;
             }

             .mat-expansion-panel-header.mat-expanded:hover {
               background: chocolate;
             }

             .mat-expansion-panel-header.disabled {
               background: lightgrey;
             }
           `]
})

export class FormSectionComponent implements OnInit, OnDestroy {
  @Input()
  section: Section;

  @Input()
  sectionFormGroup: UntypedFormGroup;

  @Input()
  startCollapsed = false;

  disabled = false;

  showConditionSubscriptions: Subscription[];

  ngOnInit(): void {
    if ((this.sectionFormGroup && this.sectionFormGroup.disabled) || this.startCollapsed) {
      this.collapsePanel();
    }

    this.showConditionSubscriptions = this.section.questions
      .filter(question => Boolean(question.showCondition))
      .map(question =>
        this.sectionFormGroup.get(question.showCondition.dependsOnKey)
          .valueChanges
          .subscribe(newVal => {
            if (_.indexOf(question.showCondition.hideIfValueIn, newVal) >= 0) {
              this.sectionFormGroup.get(question.key).disable();
            } else {
              this.sectionFormGroup.get(question.key).enable();
            }
          })
      );
  }

  ngOnDestroy(): void {
    this.showConditionSubscriptions.forEach(sub => sub.unsubscribe());
  }


  expandPanel() {
    this.disabled = false;
    this.sectionFormGroup.enable();
  }

  collapsePanel() {
    this.disabled = true;
    this.sectionFormGroup.disable();
  }

}
