import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as csvWriter from 'papaparse';


export interface CsvData {
  meta?: { // never used, should remove
    columnNames?: string[];
  };
  data: object[];
}

/**
 * To specify column names instead of using the keys,
 * pass the data as arrays instead of objects,
 * and pass an object { fields, data } to `unparse`.
 */
@Injectable({
  providedIn: 'root',
})
export class CsvWriterService {

  downloadAsCsv(csvData: CsvData, filename: string) {
    const csv = this.toCsvFormat(csvData);
    const blob = new Blob([csv], {type: 'text/csv'});
    saveAs(blob, filename);
  }

  toCsvFormat(csvData: CsvData): string {
    const rowData = csvData.data;
    const config = {
      quotes: false,
      header: true,
      skipEmptyLines: true,
    };
    return csvWriter.unparse(rowData, config);
  }

}
