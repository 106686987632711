import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import CompanyState from './company.reducer';
import { Study } from '../models/Study';
import { Department } from '../models/Department';
import { Project } from '../models/Project';

export const getCompanyState = (state: AppState) => state.company;

export const selectCompanyAllDepartments = createSelector(
  getCompanyState,
  (state: CompanyState) => state.departments.slice()
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
);

export const selectCompanyActiveDepartments = createSelector(
  selectCompanyAllDepartments,
  (depts: Department[]) => depts.filter(dept => !dept.archived)
);

export const selectCompanyAllProjects = createSelector(
  getCompanyState,
  (state: CompanyState) => state.projects.slice()
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
);

export const selectCompanyActiveProjects = createSelector(
  selectCompanyAllProjects,
  (projects: Project[]) => projects.filter(project => !project.archived)
);

export const selectCompanyAllStudies = createSelector(
  getCompanyState,
  (state: CompanyState) => state.studies
);

export const selectActiveStudies = createSelector(
  selectCompanyAllStudies,
  (studies: Study[]) => studies.filter(study => !study.archived)
);

export const selectCompanyAllLabs = createSelector(
  getCompanyState,
  (state: CompanyState) => state.labs.slice()
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
);

export const selectAllLabsAsMap = createSelector(
  selectCompanyAllLabs,
  (labs) => labs.reduce((acc, lab) => {
    acc[lab.alias] = lab;
    return acc;
  }, {})
);
