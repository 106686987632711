import { createSelector } from '@ngrx/store';
import PlatesState from './plates.reducer';
import { AppState } from '../../../store/app.reducers';
import { TaskAvailable } from '../../../tasks/models/task.model';
import { PlateSnippet } from '../../dto/labware.dto';
import { Plate96 } from '../../models/plate-96';
import { Pool, PoolSamplePlaceholder } from '../../../bio/models/pool';
import { Index } from '../../../bio/models/barcode-index';
import { getAllPoolsByRequestsAccessionCode } from '../../services/plate.service';
import { toExportForEcho } from '../../../tasks/echo-pooling/services/echo-pooling.service';

export const getPlatesState = (state: AppState) => state.plates;

export const selectPlateByIdentifier = (identifier: string) => createSelector(
  getPlatesState,
  (state: PlatesState) => state.plates[identifier]
);

export const selectAccessionCodeByIdentifier = (identifier: string) => createSelector(
  selectPlateByIdentifier(identifier),
  (plate) => plate ? plate.accessionCode : undefined
);

export const selectNonEmptyBioMaterialMappingsFromPlateByIdentifier = (identifier: string) => createSelector(
  selectPlateByIdentifier(identifier),
  (plate) => plate ? (plate as Plate96<Pool>).getNonEmptyBioMaterialMappings() : undefined
);

export const selectPoolSamplePlateByIdentifier = (identifier: string) => createSelector(
  selectPlateByIdentifier(identifier),
  (plate) => plate as Plate96<PoolSamplePlaceholder>
);

export const selectIndexPlateByIdentifier = (identifier: string) => createSelector(
  selectPlateByIdentifier(identifier),
  (plate) => plate as Plate96<Index>
);

export const selectAllPoolsFromPlateByIdentifier = (identifier: string) => createSelector(
  selectPlateByIdentifier(identifier),
  (plate) => getAllPoolsByRequestsAccessionCode(plate as Plate96<Pool>, null)
);

export const selectEchoPoolingExportFromPlateByIdentifier = (identifier: string) => createSelector(
  selectAllPoolsFromPlateByIdentifier(identifier),
  toExportForEcho
);

export const selectRangeSelections = createSelector(
  getPlatesState,
  (state: PlatesState) => state.rangeSelections
);

export const selectRangeSelectionsByIdentifier = (identifier: string) => createSelector(
  getPlatesState,
  (state: PlatesState) => state.rangeSelections[identifier]
);

export const selectRangeSelectionsForListenerId = (listenerId: string) => createSelector(
  getPlatesState,
  (state: PlatesState) => state.rangeSelections && state.rangeSelections[listenerId]
);

export const selectPlateList = (key?: string) => createSelector(
  getPlatesState,
  (state: PlatesState) => state.plateList[key === undefined ? 'all' : key] as any // TODO Fix type error
);

export const selectPlateCounts = (key?: string) => createSelector(
  getPlatesState,
  (state: PlatesState) => state.plateCounts[key === undefined ? 'all' : key]
);

export const selectCurrentPlate = createSelector(
  getPlatesState,
  (state: PlatesState) => state.currentPlate
);

export const selectIsPlateStorePending = createSelector(
  getPlatesState,
  (state: PlatesState) => state.pending
);

export const selectAllPlateSnippetsForTask = (task: TaskAvailable) => createSelector(
  getPlatesState,
  (state: PlatesState) => {
    if (state.snippets) {
      return state.snippets.filter(p => isAvailableForTasks(p, task));
    }
    return [];
  }
);

const isAvailableForTasks = (p: PlateSnippet, task: TaskAvailable) => {
  return p.availableForTasks.indexOf(task) >= 0;
}
