import { Action, createReducer, on } from '@ngrx/store';
import { UserRole } from '../models/user-role';
import {
  actionChangeLab,
  actionSetEntityPage,
  actionSetEntityPageToNull,
  actionChangeUserRole
} from './global-context.actions';
import EntityPage from '../models/entity-page';
import { Lab } from '../../company/models/Lab';

export default interface GlobalContextState {
  role: UserRole;
  lab: Lab;
  currentPage: EntityPage;
}

export const initialState: GlobalContextState = {
  role: null,
  lab: null,
  currentPage: null
};

const globalContextReducer = createReducer(
  initialState,
  on(actionChangeUserRole, (state, {role}) => ({...state, role})),
  on(actionChangeLab, (state, {lab}) => ({...state, lab})),
  on(actionSetEntityPage, (state, {entityPage}) => ({...state, currentPage: entityPage})),
  on(actionSetEntityPageToNull, (state) => ({...state, currentPage: null})),
);

export function reducer(state: GlobalContextState | undefined, action: Action) {
  return globalContextReducer(state, action);
}
