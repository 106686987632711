import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user.model';

export const loggedInSuccess = createAction(
  '[Boot] Logged in',
  props<{ user: User }>()
);

export const storeLoggedInUser = createAction(
  '[User API] Store logged in user',
  props<{ user: User }>()
);

export const pingBackendLoggedInUser = createAction(
  '[User API] Ping logged in user to the backend',
  props<{ user: User }>()
);

export const pingBackendLoggedInUserSuccess = createAction(
  '[User API] Ping logged in user to the backend was a success'
);

export const pingBackendLoggedInUserError = createAction(
  '[User API] Ping logged in user to the backend was an error',
  props<{ message: string }>()
);

export const loadAllUserDetailsFromInit = createAction(
  '[Init Data] load all user details'
);

export const loadAllUserDetailsFromApiSuccess = createAction(
  '[User API] load all user details success',
  props<{ users: User[] }>()
);

export const loadAllUserDetailsFromApiError = createAction(
  '[User API] load all user details error',
  props<{ message: string }>()
);

export const loadUserDetailsFromRequestList = createAction(
  '[Request List] load query one user details',
  props<{ userId: string }>()
);

export const loadUserDetailsFromUserSummary = createAction(
  '[User Summary] load query one user details',
  props<{ userId: string }>()
);

export const loadUserDetailsPending = createAction(
  '[Users] load one user details pending',
  props<{ userId: string }>()
);

export const loadUserDetailsSuccess = createAction(
  '[Users] load one user details success',
  props<{ user: User }>()
);

export const loadUserDetailsAlready = createAction(
  '[Users] load one user details already loaded',
  props<{ user: User }>()
);

export const loadUserDetailsError = createAction(
  '[Users] load one user details error',
  props<{ userId: string, message: string }>()
);


export const findAllowedUsersForReport = createAction(
  '[Init Data] find Allowed Users For Report'
);

export const findAllowedUsersForReportSuccess = createAction(
  '[AllowedUserForReport API] find Allowed Users For Report success',
    props<{ allowedUsersForReport: string[] }>()
);

export type UsersActions = ReturnType<| typeof loggedInSuccess
  | typeof storeLoggedInUser
  | typeof pingBackendLoggedInUser
  | typeof pingBackendLoggedInUserSuccess
  | typeof pingBackendLoggedInUserError
  | typeof loadUserDetailsFromRequestList
  | typeof loadUserDetailsFromUserSummary
  | typeof loadAllUserDetailsFromInit
  | typeof loadAllUserDetailsFromApiSuccess
  | typeof loadAllUserDetailsFromApiError
  | typeof loadUserDetailsPending
  | typeof loadUserDetailsSuccess
  | typeof loadUserDetailsAlready
  | typeof loadUserDetailsError
  | typeof findAllowedUsersForReport
  | typeof findAllowedUsersForReportSuccess>;

