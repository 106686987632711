import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { insertErrorFromHttpInterceptor } from '../../shared/nerrors/store';
import { NError } from '../../shared/nerrors/models/error.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';

/**
 * HTTP interceptor for common errors that prints useful messages to console
 * and reports readable status messages to the end-user.
 * The request/error itself is forwarded untouched.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  ignoreHttpStatusCode = {
    409: true, // CONFLICT
    422: true, // UNPROCESSABLE_ENTITY
    200: true  // OK
  };

  constructor(private readonly store: Store<AppState>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const status = error['status'];
        if (status && this.ignoreHttpStatusCode[status]) {
          throw error;
        }
        this.handleError(error);
        return of(error);
      }) as any);
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.handleStatus0(error);
    } else if (error.status === 401) {
      this.handleStatus401(error);
    } else if (error.status === 403) {
      this.handleStatus403(error);
    } else {
      // The response body may contain clues as to what went wrong
      console.error(
        `Backend returned HTTP status ${error.status}
           error message: ${error.message}
          `);
      console.error('Content: ', error);

      const message = this.buildErrorMessage(error);
      this.store.dispatch(insertErrorFromHttpInterceptor({error: new NError(message, error)}));
    }
  }

  /** Handle No Response status */
  private handleStatus0(error: HttpErrorResponse) {
    console.error(`Could not connect to server (status ${error.status}). Have you started it ?`);
    this.dispatchErrorMessage('No response from server');
  }

  /** Handle Unauthorized status */
  private handleStatus401(error: HttpErrorResponse) {
    console.error(`Unauthorized (status ${error.status}).`);
    this.dispatchErrorMessage('Unauthorized');
  }

  /** Handle Forbidden status */
  private handleStatus403(error: HttpErrorResponse) {
    console.error(`Forbidden (status ${error.status}).`);
    this.dispatchErrorMessage('You don\'t have access to this resource');
  }

  private dispatchErrorMessage(message: string) {
    this.store.dispatch(insertErrorFromHttpInterceptor({error: new NError(message, {})}));
  }

  /**
   * Build an error message out of various situations
   */
  private buildErrorMessage(error: HttpErrorResponse): string {
    if (typeof (error.error) === 'string') {
      return error.error;
    } else if (error.error instanceof SyntaxError) {
      return error.error.message;
    } else {
      if (error.error && error.error.message !== 'No message available') {
        return error.error.message;
      } else {
        return error.message;
      }
    }
  }
}
