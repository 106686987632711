import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { Subscription } from 'rxjs';
import { getPoolColor } from '../../../store';

@Component({
  selector: 'nemo-pool',
  templateUrl: './pool.component.html',
  styleUrls: ['./pool.component.scss', '../../../../shared/styles/commons.scss']
})
export class PoolComponent implements OnInit, OnDestroy {
  @Input() poolAccessionCode: string;
  @Input() poolNumberOfSamples: number;
  @Input() poolTitle = '';

  yPos: number;
  textClass: string;
  requestClass: string;
  rndX: Array<number>;
  indexBeadRadius: number;

  @Input() requestLabel?: string;
  @Input() isIcon = false;

  @Input() height = '100%';
  @Input() width = '100%';

  @Input() showOpenLink = false;

  color: string;

  colorSubscription: Subscription;

  constructor(
    public readonly store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.colorSubscription = this.store.
      select((getPoolColor(this.poolAccessionCode))
    ).subscribe((c) => this.color = c);

    this.requestClass = 'request-icon';
    if (this.requestLabel != null) {
      this.requestClass += ` request-label-${this.requestLabel}`;
    }
    this.textClass = this.poolNumberOfSamples >= 10 ? 'two-digits' : 'one-digit';
    this.yPos = this.poolNumberOfSamples >= 10 ? 0.35 : 0.5;

    this.rndX = _.range(0, this.poolNumberOfSamples).map(() => 1 - 2 * Math.random());

    if (this.poolNumberOfSamples === 0) {
      this.indexBeadRadius = 1;
    } else {
      this.indexBeadRadius = 0.05 / (1 + Math.log10(this.poolNumberOfSamples));
    }
  }

  ngOnDestroy(): void {
    this.colorSubscription.unsubscribe();
  }
}
