import { Component, Input } from '@angular/core';
import { CommentFile } from '../../models/commentText';

@Component({
  selector: 'nemo-comment-file-content',
  template: `
      <div>
          <a href="{{comment.gcsUrl}}"
             TARGET="__NEMO_GCS_"
          >
              <small>
                  <mat-icon>attach_file</mat-icon>
              </small>
              {{comment.filename}}</a>
      </div>
  `,
  styles: []
})
export class CommentFileContentComponent {
  @Input()
  comment: CommentFile;

}
