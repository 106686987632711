import { Routes } from '@angular/router';
import { AuthGuard } from '../core/auth/auth.guard';
import { QuickAccessComponent } from './components/quick-access/quick-access.component';
import { QuickAccessResolver } from './resolver/quick-access.resolver';
import { HomeComponent } from '../home/components/home.component';

export const quickAccessRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: '**', component: QuickAccessComponent, resolve: {resolvedPath: QuickAccessResolver}, canActivate: [AuthGuard]}
];
