import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project } from '../models/Project';
import { environment } from '../../../environments/environment';

export const PROJECT_BASE_URI = environment.apiUrl + '/projects';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(private readonly http: HttpClient) {
  }

  findAll(): Observable<Project[]> {
    return this.http.get<Project[]>(PROJECT_BASE_URI);
  }

  create(project: Project): Observable<Project> {
    return this.http.post<Project>(PROJECT_BASE_URI, project);
  }

  archive(project: Project): Observable<void> {
    const uri = `${PROJECT_BASE_URI}/${project.id}`;
    return this.http.delete<void>(uri);
  }

  restore(project: Project): Observable<void> {
    const uri = `${PROJECT_BASE_URI}/${project.id}/restore`;
    return this.http.post<void>(uri, undefined);
  }
}
