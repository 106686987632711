import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {  Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { AppState } from '../../../store/app.reducers';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { FormRequest } from '../../models/form-request';
import { selectCurrentFormRequest } from '../../store/form-request/form-request.selectors';
import { actionFindFormRequestByAccessionCodeFromRequestPage } from '../../store/form-request';
import { FormRequestEditionDto } from '../../dtos/form-request.dto';
import { formRequestToEditionDto } from '../../services/form-request.mapper';
import { Form } from '../../models/form';
import { actionFormEditLoadById } from '../../store/form';
import { selectFormCurrent } from '../../store/form/form.selector';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { DialogConfirmationOkComponent } from '../../../shared/shared/components/dialog-confirmation-ok/dialog-confirmation-ok.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nemo-request-form-view-page',
  template: `
    <div class="container"
         *ngIf="request$ | async"
    >
      <nemo-request-form-header
        [request]="request$ | async"
      ></nemo-request-form-header>

      <div
        class="stages"
      >
        <nemo-request-form-stages
          [request]="request$ | async"
          [isLaber]="isLaber$ | async"
          [showNames]="true"
        ></nemo-request-form-stages>
      </div>

      <div
        *ngIf="requestEdition && (isLaber$ | async); then editMode else viewMode"
      >
      </div>
      <ng-template #editMode>
        <nemo-accept-form-request
          [formRequest]="requestEdition"
          [form]="form$|async"
        ></nemo-accept-form-request>

      </ng-template>
      <ng-template #viewMode>
        <div
          class="submission"
        >
          <nemo-submitted-form
            [formRequest]="(request$ | async)"
            [completedStageKeys]="completedStageKeys$ | async"
          ></nemo-submitted-form>
        </div>
      </ng-template>
    </div>
  `,
  styles: [`
             div.container {
               height: 100%;
             }

             div.stages {
               margin-top: 1em;
             }
           `]
})
export class FormRequestViewPageComponent extends HasGlobalContextUserRole implements OnInit, OnDestroy {
  request$: Observable<FormRequest>;
  completedStageKeys$: Observable<string[]>;
  accessionCode: string;

  form$: Observable<Form>;
  requestEdition: FormRequestEditionDto;

  paramSubscription: Subscription;
  requestSubscription: Subscription;


  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title,
    private dialog: MatDialog,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.request$ = this.store.
      select((selectCurrentFormRequest)
    );

    this.completedStageKeys$ = this.store.select(selectCurrentFormRequest).pipe(

      filter(r => Boolean(r)),
      map(r => r.stages.filter(s => Boolean(s.completedBy)).map(s => s.key))
    );

    this.requestSubscription = this.request$
      .pipe(
        filter((req) => Boolean(req))
      )
      .subscribe(req => {
        this.accessionCode = req.accessionCode;
        if (req.currentOpenStageKey === 'ACCEPTED') {

          this.requestEdition = formRequestToEditionDto(req);
          this.store.dispatch(actionFormEditLoadById({id: req.submission.form.id}));
          this.form$ = this.store.
            select((selectFormCurrent)
          );
        } else {
          this.requestEdition = null;
        }
      });

    this.paramSubscription = this.route.params.subscribe(params => {
      const accessionCode = params['accessionCode'];
      this.titleService.setTitle(`Nemo form request ${formatAccessionCode(accessionCode)}`);
      this.store.dispatch(actionFindFormRequestByAccessionCodeFromRequestPage({accessionCode}));
    });

    this.route.queryParams.pipe(
      take(1)
    ).subscribe(queryParams => {
      if (queryParams['submitted']) {
        this.dialog.open(DialogConfirmationOkComponent, {
          width: '500px',
          data: {
            message:
              'Your request was submitted and will be handled by our team.' +
              'You can follow progress in the right pane and will be notified by emails.'
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
    this.requestSubscription.unsubscribe();
  }

}
