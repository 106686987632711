import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { dtoToFormType } from './form.mapper';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormType } from '../models/form-type';
import { CreateFormTypeDto, FormTypeDto } from '../dtos/form-type.dto';

export const FORM_TYPE_BASE_URI = environment.apiUrl + '/form-types';

@Injectable({
  providedIn: 'root'
})
export class FormTypeService {

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  loadAll(): Observable<FormType[]> {
    return this.httpClient.get<FormTypeDto[]>(
      FORM_TYPE_BASE_URI
    ).pipe(
      map((list) => list.map(dtoToFormType))
    );
  }

  create(formTypeDto: CreateFormTypeDto): Observable<FormType> {
    return this.httpClient.post<FormTypeDto>(
      FORM_TYPE_BASE_URI, formTypeDto
    ).pipe(
      map(dtoToFormType)
    );
  }

}
