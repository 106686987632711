import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-request-submission-form-sample-type',
  template: `
    <div [formGroup]="formGroup">
      <h4>Sample type*</h4>
      <div>
        <mat-radio-group *ngIf="selectedLibraryMethod.submissionType === 'DNA'; else showOptionForRNA"
                         formControlName="sampleTypeCtl">
          <mat-radio-button *ngFor="let option of dnaSampleTypeOptions" [value]="option">{{ option }}</mat-radio-button>
        </mat-radio-group>
        <ng-template #showOptionForRNA>
          <mat-radio-group formControlName="sampleTypeCtl">
            <mat-radio-button *ngFor="let option of rnaSampleTypeOptions" [value]="option">{{ option }}</mat-radio-button>
          </mat-radio-group>
        </ng-template>
      </div>
    </div>
  `,
  styleUrls: ['../request-submission-form-info/request-submission-form-info.component.scss'],
})
export class RequestSubmissionFormSampleTypeComponent {
  @Input() formGroup: FormGroup;
  @Input() selectedLibraryMethod: any;

  sampleTypeOptions: string[] = [
    'Cell Pellet',
    'Fresh Frozen Tissue',
    'Other (please specify in comments)'
  ]

  dnaSampleTypeOptions: string[] = [
    ...this.sampleTypeOptions,
    'Genomic DNA',
  ].sort();

  rnaSampleTypeOptions: string[] = [
    ...this.sampleTypeOptions,
    'Total RNA',
    'Cells in Preservative/RLT buffer',
  ].sort();
}
