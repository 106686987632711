import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

import { Page } from '../../shared/models/page.model';
import { dtoToPageOfPlateQCSummary, dtoToPlateQC } from './qc-plate.mapper';
import { TaskAvailable } from '../../tasks/models/task.model';
import { PlateQC, PlateQCSummary } from '../models/plate-qc-measures';
import { PlateQCDto, PlateQCSummaryDto } from '../dto/qc.dto';

export const QC_BASE_URI = environment.apiUrl + '/qc-measures';

@Injectable({providedIn: 'root'})
export class QcApiService {

  constructor(private http: HttpClient) {
  }

  findAllPlateQC(pageSize: number, page: number, availableForTask: TaskAvailable): Observable<Page<PlateQCSummary>> {
    const queryParams = {};

    if (pageSize !== null) {
      queryParams['size'] = pageSize;
    }
    if (page !== null) {
      queryParams['page'] = page;
    }
    if (availableForTask) {
      queryParams['available_for'] = availableForTask;
    }

    return this.http.get<Page<PlateQCSummaryDto>>(QC_BASE_URI, {params: queryParams}).pipe(
      map(dtoToPageOfPlateQCSummary)
    );
  }

  findPlateQCByAccessionCode(accessionCode: string): Observable<PlateQC> {
    return this.http.get<PlateQCDto>(`${QC_BASE_URI}/${accessionCode}`).pipe(
      map(dtoToPlateQC)
    );
  }

  count(availableTask: string | undefined): Observable<number> {
    const queryParams = {};
    if (availableTask) {
      queryParams['available_for'] = availableTask;
      queryParams['with_plate_qc'] = true;
    }
    return this.http.get<number>(QC_BASE_URI + '/count', {params: queryParams});
  }
}
