import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import EntityPage from '../../global-context/models/entity-page';
import { Observable } from 'rxjs';
import { CommentDto, CommentFileCreationDto, CommentTextCreationDto } from '../dtos/comment-dtos';
import { CommentEntity, CommentFile, CommentText } from '../models/commentText';
import { dtoToCommentFile, dtoToCommentText, toComments } from './comment-mapper';
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from '../../user/services/user.service';
import { FileDto } from '../../shared/models/file-dto';

export const COMMENT_BASE_URI = environment.apiUrl + '/comment';
export const COMMENT_TEXT_BASE_URI = COMMENT_BASE_URI + '/texts';
export const COMMENT_FILE_BASE_URI = COMMENT_BASE_URI + '/files';

@Injectable({providedIn: 'root'})
export class CommentService {
  x: CommentTextCreationDto;

  constructor(private http: HttpClient, private userService: UserService) {
  }

  newMessage(content: string, entityPage: EntityPage): Observable<CommentText> {
    return this.http.post<CommentDto>(COMMENT_TEXT_BASE_URI, {
      entityAccessionCode: entityPage.accessionCode,
      content: content
    } as CommentTextCreationDto).pipe(
      map(dto => dtoToCommentText(dto))
    );
  }

  findAllCommentByEntity(entityPage: EntityPage): Observable<CommentEntity[]> {
    return this.http.get<CommentDto[]>(`${COMMENT_BASE_URI}/${entityPage.accessionCode}`).pipe(
      mergeMap(commentDtos => this.userService.findAll().pipe(
        map(users => ({users, commentDtos}))
      )),
      map(data => toComments(data.commentDtos, data.users))
    );
  }

  addFile(fileDto: FileDto, entityPage: EntityPage): Observable<CommentFile> {
    return this.http.post<CommentDto>(COMMENT_FILE_BASE_URI, {
      entityAccessionCode: entityPage.accessionCode,
      file: fileDto.file,
      filename: fileDto.filename
    } as CommentFileCreationDto).pipe(
      map(dto => dtoToCommentFile(dto))
    );
  }

}
