<h4 *ngIf="!question.required">
  <mat-slide-toggle
    [checked]="isEnabled"
    (change)="toggleChange($event)"
  >
    {{question.title}}
  </mat-slide-toggle>
</h4>
<h4 *ngIf="question.required">
  {{question.title}}
</h4>
<div
  [ngClass]="{hide: !isEnabled}"
>
  <div
    *ngIf="question.description"
    class="description"
  >{{question.description}}</div>

  <mat-radio-group
    *ngIf="!question.multiple"
    [formControl]="controlChoice"
    (ngModelChange)="changeChoice($event)"
  >
    <div
      *ngFor="let choice of question.choices"
    >
      <mat-radio-button
        class=""
        value="{{choice.value}}"
        matTooltip="{{choice.help}}"
        color="primary"
      >
        {{choice.value}}
      </mat-radio-button>

    </div>
    <div
      *ngIf="question.freeText"
      class="free-text"
    >
      <mat-radio-button
        color="primary"
        value="FORM_QUESTION_MULTI_CHOICE_FREE_TEXT_OPTION"
        (click)="freeTextButtonClick()"
      >
      </mat-radio-button>
      <mat-form-field
        class="free-text"
      >
        <mat-label>Or</mat-label>
        <input
            #freeTextInput
            [formControl]="controlFreeText"
            (ngModelChange)="changeFreeTextRadio($event)"
            (click)="changeFreeTextRadio(freeTextInput.value)"
            matInput
        >
        <mat-hint
          *ngIf="question.maxLength > 0"
          align="end"
        >{{freeTextInput.value.length}} / {{question.maxLength}}</mat-hint>
      </mat-form-field>
    </div>
  </mat-radio-group>

  <div
    *ngIf="question.multiple"
  >
    <mat-selection-list
      [formControl]="controlChoice"
      (ngModelChange)="changeChoice($event)"
    >
      <mat-list-option
        *ngFor="let choice of question.choices"
        [ngClass]="{choices:true}"
        [value]="choice.value"
        matTooltip="{{choice.help}}"
        checkboxPosition="before"
        color="primary"
      >{{choice.value}}
      </mat-list-option>

      <mat-list-option
        *ngIf="question.freeText"
        [ngClass]="{'free-text':true}"
        checkboxPosition="before"
        [value]="multipleFreeText"
        [selected]="multipleFreeText"
        color="primary"
      >
        <mat-form-field
          class="free-text"
        >
          <mat-label>Or</mat-label>
          <input
            [formControl]="controlFreeText"
            (ngModelChange)="changeFreeTextMultiple($event)"
            matInput
          >
          <mat-hint
            *ngIf="question.maxLength > 0"
            align="end"
          >{{multipleFreeText?.length}} / {{question.maxLength}}</mat-hint>
        </mat-form-field>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>

