<div class="container">
  <div class="open-link"
       *ngIf="showOpenLink"
  >
    <a [routerLink]="'/plate/' + plate.accessionCode">
      open
    </a>
    <mat-icon
      [routerLink]="'/plate/' + plate.accessionCode"
    >open_in_new
    </mat-icon>
  </div>

  <nemo-editable-text label="title"
                      [initValue]="plate.title"
                      isRequired="true"
                      maxLength="80"
                      [readonly]="!titleEditable"
                      (changeValue)="updatePlateTitle($event)"
  ></nemo-editable-text>
  <div class="layout" #layout></div>
  <div class="legend"
       *ngIf="showLegend"
  >
    <nemo-plate-viewer-legend
      [colorScale]="colorScale"
    ></nemo-plate-viewer-legend>
  </div>

</div>
