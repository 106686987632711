import { Component, Input, OnInit } from '@angular/core';
import {
  FlexibleButtonDef,
  FlexibleButtonsColumnDef,
  FlexibleColumnDef,
  FlexiblePlainColumnDef
} from '../../table/model/flexible-columm-def';
import { formatAccessionCode } from '../../shared/pipes/accession-code.pipe';
import { formatDate } from '@angular/common';
import {
  dismissPlateQCFromList,
  dismissPlateQCFromPage,
  recoverPlateQCFromList,
  recoverPlateQCFromPage
} from '../store/actions/qc-tasks.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { TaskAvailable } from '../../tasks/models/task.model';
import {
  unassignPlateQCFromInstrumentDirectoryList,
  unassignPlateQCFromInstrumentDirectoryPage
} from '../../tasks/store/task.action';
import { Router } from '@angular/router';
import { PlateQCSummary } from '../models/plate-qc-measures';
import { PlateQcTasksButtonsContext } from '../shared/components/plate-qc-tasks-buttons.component';

@Component({
  selector: 'nemo-qc-table',
  template: `
    <nemo-flexible-table
      [values]="plateQCSummaries"
      [columnDefinitions]="columnDefinitions"
    ></nemo-flexible-table>
  `,
  styles: []
})
export class QcTableComponent implements OnInit {

  @Input() plateQCSummaries: PlateQCSummary[];

  @Input() isLabMember: boolean;

  columnDefinitions: FlexibleColumnDef<PlateQCSummary>[] = [
    new FlexiblePlainColumnDef<PlateQCSummary>(
      'instrument',
      'Instrument',
      () => true,
      (m) => m.instrumentName,
      {'flex': '0 0 80px'},
      (m) => `/plate-qc/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<PlateQCSummary>(
      'fileType',
      'type',
      () => true,
      (m) => m.instrumentFile.fileType,
      {'flex': '0 0 80px'},
      (m) => `/plate-qc/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<PlateQCSummary>(
      'accession code',
      'AC',
      () => true,
      (m) => formatAccessionCode(m.accessionCode),
      {'flex': '0 0 90px'},
      (m) => `/plate-qc/${m.accessionCode}`,
      ['accession-code']
    ),
    new FlexiblePlainColumnDef<PlateQCSummary>(
      'createdAt',
      'Created at',
      () => true,
      (m) => formatDate(m.instrumentFile.createdAt, 'short', 'en-US'),
      {'flex': '0 0 150px'},
      (m) => `/plate-qc/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<PlateQCSummary>(
      'path',
      'Path',
      () => true,
      (m) => m.instrumentFile.path,
      null,
      (m) => `/plate-qc/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<PlateQCSummary>(
      'plateAccessionCode',
      'Assigned plate',
      (m) => m.plateAccessionCode !== null,
      (m) => formatAccessionCode(m.plateAccessionCode),
      {'flex': '0 0 120px'},
      (m) => (m.plateAccessionCode !== null) ? `/plate/${m.plateAccessionCode}` : `/plate-qc/${m.accessionCode}`,
      ['accession-code']
    ),

  ];

  context = PlateQcTasksButtonsContext.PLATE_QC_LIST;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (this.isLabMember) {
      const actionButtons = [
        new FlexibleButtonDef<PlateQCSummary>(
          () => 'Dismiss',
          () => 'primary',
          (m) => this.isTaskAvailable(m, TaskAvailable.QC_PLATE_DISMISS),
          (m) => this.dismiss(m)
        ),
        new FlexibleButtonDef<PlateQCSummary>(
          () => 'Recover',
          () => 'primary',
          (m) => this.isTaskAvailable(m, TaskAvailable.QC_PLATE_RECOVER),
          (m) => this.recover(m)
        ),
        new FlexibleButtonDef<PlateQCSummary>(
          () => 'Assign to plate',
          () => 'primary',
          (m) => this.isTaskAvailable(m, TaskAvailable.QC_PLATE_ASSIGN_TO_FILE),
          (m) => this.assignToPlate(m)
        ),
        new FlexibleButtonDef<PlateQCSummary>(
          () => 'Unassign from plate',
          () => 'primary',
          (m) => this.isTaskAvailable(m, TaskAvailable.QC_PLATE_UNASSIGN_FROM_FILE),
          (m) => this.unassignFromPlate(m)
        )
      ];
      this.columnDefinitions.push(
        new FlexibleButtonsColumnDef<PlateQCSummary>(
          'actions',
          'What can I do?',
          () => true,
          () => '',
          {'flex': '0 0 220px'},
          actionButtons
        )
      );
    }
  }

  isTaskAvailable(plateQCSummary: PlateQCSummary, task: TaskAvailable): boolean {
    return plateQCSummary.availableForTasks.indexOf(task) > -1;
  }

  dismiss(plateQCSummary: PlateQCSummary): void {
    const action = (this.context === PlateQcTasksButtonsContext.PLATE_QC_LIST) ?
                   dismissPlateQCFromList :
                   dismissPlateQCFromPage;

    this.store.dispatch(action({accessionCode: plateQCSummary.accessionCode}));
  }

  recover(plateQCSummary: PlateQCSummary): void {
    const action = (this.context === PlateQcTasksButtonsContext.PLATE_QC_LIST) ?
                   recoverPlateQCFromList :
                   recoverPlateQCFromPage;

    this.store.dispatch(action({accessionCode: plateQCSummary.accessionCode}));
  }

  assignToPlate(plateQCSummary: PlateQCSummary): void {
    this.router.navigate(['/tasks/plate-qc-assign/' + plateQCSummary.accessionCode]);
  }

  unassignFromPlate(plateQCSummary: PlateQCSummary): void {
    const action = (this.context === PlateQcTasksButtonsContext.PLATE_QC_LIST) ?
                   unassignPlateQCFromInstrumentDirectoryList :
                   unassignPlateQCFromInstrumentDirectoryPage;

    this.store.dispatch(action({
      plateAccessionCode: plateQCSummary.plateAccessionCode,
      instrumentDirectoryAccessionCode: plateQCSummary.accessionCode
    }));
  }

}
