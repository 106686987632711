import { PlateCoordinates } from '../../../labware/models/plate-coordinates';

/** the mapping from one index plate to a sample plate coordinates
 *  Warning: this class is mutable
 */
export class OneIndexToSampleCoordinatesMapping {
  constructor(
    readonly indexPlateCoordinates: PlateCoordinates,
    readonly samplePlateCoordinates: PlateCoordinates
  ) {
  }
}


export class IndexToSampleLayout {
  constructor(
    public mappings: OneIndexToSampleCoordinatesMapping[] = []
  ) {
  }

  public add(mapping: OneIndexToSampleCoordinatesMapping) {
    this.mappings.push(mapping);
  }

  public size() {
    return this.mappings.length;
  }
}
