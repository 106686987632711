import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { selectFormEditorError } from '../../store/editor/form-editor.selector';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TextValidationError } from '../../models/text-validation-error';

@Component({
  selector: 'nemo-form-editor-yaml',
  templateUrl: './form-editor-yaml.component.html',
  styleUrls: ['./form-editor-yaml.component.scss']
})
export class FormEditorYamlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() yaml: string;

  @Output() modifiedYaml = new EventEmitter<string>();

  @ViewChild('ta', {static: true}) elTextArea: ElementRef;
  @ViewChild('elLineNumbers', {static: true}) elLineNumbers: ElementRef;
  @ViewChild('displayError', {static: true}) elDisplayError: ElementRef;

  lineNumbers: number[];
  previousLineCount = -1;
  validationError: TextValidationError = null;

  form: UntypedFormGroup;

  errorSubscription: Subscription;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.errorSubscription = this.store.select(selectFormEditorError).pipe(
      
      filter(e => Boolean(e))
    ).subscribe((e) => this.highlightError(e));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['yaml']) {
      this.form = this.buildForm();
      this.setLineNumbers();
    }
  }


  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
  }

  buildForm() {
    return this.formBuilder.group({
      yaml: this.formBuilder.control(this.yaml,
        // [Validators.required],
      ),
    });
  }

  countLines(): number {
    return (this.form.get('yaml').value.match(/\n/g) || []).length;
  }

  setLineNumbers() {
    const countLines = this.countLines();
    this.lineNumbers = _.range(countLines + 1)
      .map((i) => i);
    this.previousLineCount = countLines;
  }

  keyHit(key: KeyboardEvent) {
    if (key.code === 'MetaLeft') {
      return;
    }
    this.changedYaml();

    if (this.validationError) {
      this.clearError();
    }
  }

  changedYaml() {
    const newLineCount = this.countLines();
    if (newLineCount !== this.previousLineCount) {
      this.setLineNumbers();
    }

    this.modifiedYaml.emit(this.form.get('yaml').value);
  }

  highlightError(e: TextValidationError) {
    this.setCaret(e.problemMark.line, e.problemMark.column);
    this.showError(e);
  }


  setCaret(line: number, column: number) {
    const index = lineColumnToIndex(line, column, this.yaml);
    const input = this.elTextArea.nativeElement;
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(index, index);
    } else if (input.createTextRange) {
      const range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', index);
      range.moveStart('character', index);
      range.select();
    }
  }

  showError(e: TextValidationError) {
    const elLine = document.getElementById(`line-${e.problemMark.line}`);
    elLine.scrollTo(0, 0);

    this.validationError = e;
    this.elDisplayError.nativeElement.style.top = `${elLine.offsetTop + 15}px`;
  }

  clearError() {
    if (!this.validationError) {
      return;
    }
    this.validationError = null;
  }
}

/**
 * get the index position based on line/column on a text
 * must therefore count the <newline>
 */
export function lineColumnToIndex(line: number, column: number, text: string): number {
  const lines = text.split('\n');
  const textLength = text.length;
  if (line > lines.length + 1) {
    return textLength;
  }

  const lineOffset = _.chain(lines)
    .take(line)
    .map((l) => l.length + 1) // must count <newline>
    .sum()
    .value();
  return lineOffset + Math.min(column, lines[line].length);
}
