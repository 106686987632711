import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { NError } from './models/error.model';
import { insertErrorFromGlobalHandler } from './store';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private store: Store<any>;

  constructor(
    private injector: Injector
  ) {
  }

  handleError(error) {
    if (!this.store) {
      this.store = this.injector.get(Store);
    }
    console.error(error);
    this.store.dispatch(insertErrorFromGlobalHandler({error: new NError(error.message, error)}));

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    // Alex: well, if an error is try/catch, it does not come into the ErrorHandler cycle
    // problem might come if we have multiple ErrorHandler...
    // throw error;
  }
}
