import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  RequestUserAssignmentSelectorComponent
} from '../components/request-user-assignment-viewer/request-user-assignment-selector.component';
import {
  RequestUserAssignmentContainerComponent
} from '../components/request-user-assignment-container/request-user-assignment-container.component';
import { UserSharedModule } from '../../../user/shared/user-shared.module';
import { SharedSharedModule } from '../../../shared/shared/shared-shared.module';
import { MaterialModule } from '../../../material.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    RequestUserAssignmentSelectorComponent,
    RequestUserAssignmentContainerComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedSharedModule,
    UserSharedModule,
  ],
  exports: [
    RequestUserAssignmentSelectorComponent,
    RequestUserAssignmentContainerComponent
  ]
})
export class CommonRequestSharedModule {
}
