import { Action, createReducer, on } from '@ngrx/store';
import { Graph } from '../models/graph';
import { findGraphByEntityAccessionCodeFromPage, findGraphByEntityAccessionCodeSuccess } from './graph.actions';

export default interface GraphState {
  dictionary: { [key: string]: Graph };
}

export const initialState: GraphState = {
  dictionary: {}
};

const globalGraphReducer = createReducer(
  initialState,
  on(findGraphByEntityAccessionCodeFromPage, (state, {accessionCode}) =>
    ({...state, dictionary: {...state.dictionary, [accessionCode]: null}})),
  on(findGraphByEntityAccessionCodeSuccess, (state, {accessionCode, graph}) =>
    ({...state, dictionary: {...state.dictionary, [accessionCode]: graph}}))
);

export function reducer(state: GraphState | undefined, action: Action) {
  return globalGraphReducer(state, action);
}
