import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';

import { FormRequestSummary } from '../../../form/models/form-request';
import { selectFormRequests } from '../../../form/store/form-request/form-request.selectors';
import { UserRole } from '../../../global-context/models/user-role';
import {
  actionFindAllFormRequestsByAssignedTo,
  actionFindAllFormRequestsByRequester
} from '../../../form/store/form-request';
import { User } from '../../../user/models/user.model';
import { Lab } from '../../../company/models/Lab';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'nemo-home-form-requests',
  template: `
    <div class="requests">
      <h2 *ngIf="isSubmitter$ | async">My requests</h2>
      <h2 *ngIf="isLaber$ | async">Requests assigned to me</h2>
      <mat-checkbox
        color="primary"
        checked="true"
        (change)="changeFilter($event)">
        Hide archived
      </mat-checkbox>
    </div>
    <nemo-form-request-list
      [formRequests]="formRequests$ | async"
      [showAssignees]="false"
    ></nemo-form-request-list>
  `,
  styles: [`
    .requests {
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `]
})
export class HomeFormRequestsComponent extends HasGlobalContextUserRole implements OnInit, OnChanges {

  @Input() context: { user: User, role: UserRole, lab: Lab };

  hideArchived = true;

  formRequests$: Observable<FormRequestSummary[]>;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.formRequests$ = this.store.select((selectFormRequests));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshList();
  }

  changeFilter(event: MatCheckboxChange) {
    this.hideArchived = event.checked;
    this.refreshList();
  }

  refreshList(): void {
    if (this.context) {
      if (this.context.role === UserRole.LABER) {
        this.store.dispatch(actionFindAllFormRequestsByAssignedTo({
          lab: this.context.lab,
          assignedTo: this.context.user,
          hideArchived: this.hideArchived
        }));
      }
      if (this.context.role === UserRole.SUBMITTER) {
        this.store.dispatch(actionFindAllFormRequestsByRequester({
          lab: this.context.lab,
          requester: this.context.user,
          hideArchived: this.hideArchived
        }));
      }
    }
  }
}

