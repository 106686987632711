import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class FileTransferService {

  /**
   * Use file reader API to read a file as base64
   */
  async readFileAsDataURL(file: File): Promise<any> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException(`Error while parsing input file "${file.name}"`));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }

}
