import { createSelector } from '@ngrx/store';
import GenomicsRequestState from './genomics-request.reducer';
import { AppState } from '../../store/app.reducers';

export const getGenomicsRequestState = (state: AppState) => state.genomicsRequest;

export const isGenomicsRequestPending = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.pending
);
export const getShowDetail = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.showDetail
);
export const getRequests = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.requests
);
export const getGenomicsCurrentRequest = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.currentRequest
);
export const getGenomicsMessage = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.message
);
export const getGenomicsContainersPreview = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.containersPreview
);
export const getGenomicsRequestDictionary = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.requestDict
);
export const getGenomicsImportedMetadata = createSelector(
  getGenomicsRequestState,
  (state: GenomicsRequestState) => state.metadataHeaders
);

export const getGenomicsRequestPageInfo = createSelector(getGenomicsRequestState, (state: GenomicsRequestState) => state.pageInfo);
