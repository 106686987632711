import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartmentSelectComponent } from './component/department-select/department-select.component';
import { MaterialModule } from '../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ProjectSelectComponent } from './component/project-select/project-select.component';
import { StudiesComponent } from './component/study/studies.component';
import { StudyAddComponent } from './component/study/study-add.component';
import { StudyListComponent } from './component/study/study-list.component';
import { StudySelectComponent } from './component/study-select/study-select.component';
import { ProjectsAddComponent } from './component/projects/projects-add.component';
import { ProjectsListComponent } from './component/projects/projects-list.component';
import { ProjectsComponent } from './component/projects/projects.component';
import { DepartmentsListComponent } from './component/departments/departments-list.component';
import { DepartmentsAddComponent } from './component/departments/departments-add.component';
import { DepartmentsComponent } from './component/departments/departments.component';
import { LabsListComponent } from './component/labs/labs-list.component';
import { LabsComponent } from './component/labs/labs.component';
import { LabsAddComponent } from './component/labs/labs-add.component';
import { LabsEditDialogComponent } from './component/labs/labs-edit.component';
import {CollaboratorSelectComponent} from './component/collaborator-select/collaborator-select.component';

@NgModule({
  declarations: [
    DepartmentSelectComponent,
    ProjectSelectComponent,
    StudySelectComponent,
    StudiesComponent,
    StudyAddComponent,
    StudyListComponent,
    ProjectsComponent,
    ProjectsAddComponent,
    ProjectsListComponent,
    DepartmentsComponent,
    DepartmentsAddComponent,
    DepartmentsListComponent,
    LabsComponent,
    LabsAddComponent,
    LabsEditDialogComponent,
    LabsListComponent,
    CollaboratorSelectComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    DepartmentSelectComponent,
    ProjectSelectComponent,
    StudySelectComponent,
    StudiesComponent,
    ProjectsComponent,
    DepartmentsComponent,
    LabsComponent,
    CollaboratorSelectComponent,
  ]
})
export class CompanySharedModule {
}
