import { Component, Input } from '@angular/core';

@Component({
  selector: 'nemo-flow-cell-container',
  template: `
    <div class="flow-cell-container">
      <nemo-flow-cell-selected-container [showFlowCellId]="false"
                                         id="flow-cell-drop"
                                         [isReadonly]="isReadonly"
      ></nemo-flow-cell-selected-container>
    </div>
  `,
  styles: [`
    .flow-cell-container {
      width: 300px;
      height: 100%;
    }
  `]
})
export class FlowCellContainerComponent {
  @Input() isReadonly = false;
}
