import { TaskDto } from '../dtos/task.dto';
import { Task } from '../models/task.model';
import { User } from '../../user/models/user.model';

export function toTasks(taskDtos: TaskDto[], users: User[]): Task[] {
  const sortedUsers = {};
  users.map((u) => sortedUsers[u.id] = u);

  return taskDtos.map(taskDto =>
    toTask(taskDto, sortedUsers[taskDto.userId])
  );
}

export function toTask(task: TaskDto, user: User): Task {
  return new Task(task.action, task.requestsAccessionCode, task.payload, task.date, user);
}
