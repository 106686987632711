import { Component, OnInit } from '@angular/core';
import * as fromIndex from '../../store';
import { findAllIndexKits } from '../../store';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';
import { PageInfo } from '../../../shared/models/page.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nemo-index-kit-table-page',
  template: `
    <nemo-pending-title [title]="'Index kits'"></nemo-pending-title>
    <nemo-index-kit-table></nemo-index-kit-table>
    <nemo-paginator [pageInfo]="pageInfo$ | async" (pageChange)="findAll($event[0], $event[1])"></nemo-paginator>
  `
})
export class IndexKitTablePageComponent implements OnInit {

  pageInfo$: Observable<PageInfo>;

  constructor(
    private store: Store<AppState>,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Nemo index kit list');
    this.findAll(25, 0);
    this.pageInfo$ = this.store.select((fromIndex.getPageInfo));
  }

  findAll(pageSize: number, page: number) {
    this.store.dispatch(findAllIndexKits({pageSize, page}));
  }
}
