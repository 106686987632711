import { Component } from '@angular/core';

@Component({
  selector: 'nemo-todo-select-card',
  template: `
    <h2>Select an action</h2>
  `,
  styleUrls: ['./todo-select-card.component.scss']
})
export class TodoSelectCardComponent {
}
