import {
  FormDto,
  FormStructureDto,
  QuestionDto,
  QuestionOptionDto,
  QuestionShowConditionDto,
  SectionDto
} from '../dtos/form.dto';
import {
  Form,
  FormStructure,
  Question,
  QuestionCheckBox,
  QuestionMultiChoice,
  QuestionOption,
  QuestionSampleNameList,
  QuestionSampleNameOption,
  QuestionSequence,
  QuestionShowCondition,
  QuestionText,
  QuestionType,
  Section
} from '../models/form';
import { FormTypeDto } from '../dtos/form-type.dto';
import { FormType } from '../models/form-type';
import { dtoToUser } from '../../user/services/user.mapper';

export function dtoToQuestionOption(dto: QuestionOptionDto): QuestionOption {
  return new QuestionOption(
    dto.value,
    dto.help
  );
}

export function dtoToQuestionShowCondition(dto: QuestionShowConditionDto): QuestionShowCondition {
  if (!dto) {
    return null;
  }
  return new QuestionShowCondition(dto.dependsOnKey, dto.hideIfValueIn);
}

export function dtoToQuestion(dto: QuestionDto): Question {
  switch (dto.type) {
    case QuestionType.TEXT: {
      return new QuestionText(
        dto.key,
        dto.title,
        dto.description,
        dto.help,
        dto.required,
        dtoToQuestionShowCondition(dto.showCondition),
        dto.multiLine || false,
        dto.maxLength
      );
    }
     case QuestionType.SEQUENCE: {
      return new QuestionSequence(
        dto.key,
        dto.title,
        dto.description,
        dto.help,
        dto.required,
        dtoToQuestionShowCondition(dto.showCondition)
      );
    }
    case QuestionType.MULTICHOICE: {
      return new QuestionMultiChoice(
        dto.key,
        dto.title,
        dto.description,
        dto.help,
        dto.required,
        dtoToQuestionShowCondition(dto.showCondition),
        dto.multiple,
        dto.choices.map(dtoToQuestionOption),
        dto.freeText,
        dto.maxLength
      );
    }
    case QuestionType.SAMPLENAME_OPTION: {
      return new QuestionSampleNameOption(
        dto.key,
        dto.title,
        dto.description,
        dto.help,
        dto.required,
        dto.choices.map(dtoToQuestionOption),
        dto.maxLength
      );
    }
    case QuestionType.SAMPLENAME_LIST: {
      return new QuestionSampleNameList(
        dto.key,
        dto.title,
        dto.description,
        dto.help,
        dto.required
      );
    }
    case QuestionType.CHECKBOX: {
      return new QuestionCheckBox(
        dto.key,
        dto.title,
        dto.description,
        dto.help,
        dtoToQuestionShowCondition(dto.showCondition),
        dto.defaultValue
      );
    }
    default: {
      throw new Error(`Cannot parse dto type [${dto.type}]`);
    }
  }
}

export function dtoToSection(dto: SectionDto): Section {
  return new Section(
    dto.key,
    dto.title,
    dto.description,
    dto.questions.map(dtoToQuestion)
  );
}

export function dtoToFormStructure(dto: FormStructureDto): FormStructure {
  return new FormStructure(
    dto.title,
    dto.description,
    dto.sections.map(dtoToSection)
  );
}


export function dtoToFormType(dto: FormTypeDto): FormType {
  return new FormType(
    dto.id,
    dto.name,
    dto.lab,
    dto.createdAt,
    dto.archived
  );
}

export function dtoToForm(dto: FormDto): Form {
  return new Form(
    dto.id,
    dtoToFormType(dto.type),
    dtoToUser(dto.createdBy),
    dto.createdAt,
    dtoToFormStructure(dto.structure)
  );
}
