import { createAction, props } from '@ngrx/store';
import { Form } from '../../models/form';
import { FormType, FormTypeWithSections } from '../../models/form-type';

export const actionFormLoadAllFormTypes = createAction(
  '[Form List] load all form types'
);

export const actionFormLoadAllFormTypesSuccess = createAction(
  '[Form API] load all form types success',
  props<{ formTypes: FormType[] }>()
);

export const actionFormSubmitLoadByTypeName = createAction(
  '[Form Submit] load by type name',
  props<{ typeName: string }>()
);

export const actionFormEditLoadById = createAction(
  '[Form Edit] load by id',
  props<{ id: number }>()
);

export const actionFormLoadByTypeNameSuccess = createAction(
  '[Form API] load by typeName success',
  props<{ form: Form }>()
);

export const actionFormLoadByIdSuccess = createAction(
  '[Form API] load by id success',
  props<{ form: Form }>()
);

export const actionFormCreateNewFormType = createAction(
  '[Form Settings] create new form type',
  props<{ lab: string, name: string }>()
);

export const actionFormLoadAllFormTypesAfterCreation = createAction(
  '[Form List] load all form types after creation'
);

export const actionLoadAllFormTypesWithSectionsForLab = createAction(
  '[Home Page] load all form types with sections for lab',
  props<{ lab: string }>()
);

export const actionLoadAllFormTypesWithSectionsForLabSuccess = createAction(
  '[Home Page] load all form types with sections success',
  props<{ formTypesWithSections: FormTypeWithSections[] }>()
);


export type FormActions = ReturnType<typeof actionFormLoadAllFormTypes
  | typeof actionFormLoadAllFormTypesSuccess
  | typeof actionFormSubmitLoadByTypeName
  | typeof actionFormEditLoadById
  | typeof actionFormLoadByTypeNameSuccess
  | typeof actionFormLoadByIdSuccess>;
