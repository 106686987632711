import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibraryMethod, SubmissionType } from '../../../../sequencing/models/library-method';
import { FormGroup } from '@angular/forms';
import { selectActiveLibraryMethodsBySubmissionType, selectAllLibraryMethods } from '../../../../sequencing/store';
import { AppState } from '../../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'nemo-request-submission-form-library-method',
  template: `
    <div [formGroup]="formGroup">
      <h4>Submission type*</h4>
      
      <mat-form-field *ngIf="simpleOptionList">
        <mat-label>Library Method</mat-label>
        <mat-select formControlName="libraryMethod"
                    (selectionChange)="onLibraryMethodChange($event.value)">
          <mat-option *ngFor="let libraryMethod of libraryMethods$ | async"
                      [value]="libraryMethod">
            {{ libraryMethod.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-accordion *ngIf="!simpleOptionList" displayMode="flat"
                     [multi]="false"
                     [ngClass]="{ 'library-method-selected': !!selectedLibraryMethod }">
        <mat-expansion-panel *ngFor="let submissionType of (libraryMethodsBySubmissionType$ | async) | submissionTypeKeyValue"
                             (expandedChange)="selectedSubmissionTypeChanged()">
          <mat-expansion-panel-header>{{ submissionType.key }}</mat-expansion-panel-header>
          <mat-radio-group formControlName="libraryMethod"
                           name="library-methods"
                           class="library-method-radio-group"
                           (change)="onLibraryMethodChange($event.value)">
            <mat-radio-button *ngFor="let libraryMethods of submissionType.value"
                              [value]="libraryMethods">
              {{ libraryMethods.name }}
            </mat-radio-button>
          </mat-radio-group>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  `,
  styleUrls: ['../request-submission-form-info/request-submission-form-info.component.scss'],
  styles: [`
    mat-form-field {
      width: 100%;
    }
    
    mat-accordion.library-method-selected mat-expansion-panel {
      color: lightgrey;
    }

    mat-accordion.library-method-selected mat-expansion-panel.mat-expanded {
      color: initial;
    }

    mat-expansion-panel label {
      display: block;
      margin: 0 10px 8px;
    }

    mat-radio-group.library-method-radio-group {
      flex-direction: row;
      flex-wrap: wrap;
    }

    mat-radio-button {
      width: 100%;
      max-width: 480px;
      min-width: 50%;
      flex-grow: 1;
    }
  `],
})
export class RequestSubmissionFormLibraryMethodComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() simpleOptionList: boolean = false;
  @Input() selectedLibraryMethod: LibraryMethod | null;
  @Output() selectedLibraryMethodChange: EventEmitter<LibraryMethod | null> = new EventEmitter<LibraryMethod | null>();

  libraryMethods$: Observable<LibraryMethod[]>;
  libraryMethodsBySubmissionType$: Observable<Map<SubmissionType, LibraryMethod[]>>;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.libraryMethods$ = this.store.select(selectAllLibraryMethods);
    this.libraryMethodsBySubmissionType$ = this.store.select(selectActiveLibraryMethodsBySubmissionType);
  }

  selectedSubmissionTypeChanged() {
    this.selectedLibraryMethodChange.emit(null);
  }

  onLibraryMethodChange(selectedLibraryMethod: LibraryMethod) {
    this.selectedLibraryMethodChange.emit(selectedLibraryMethod);
  }
}
