import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { IndexKitModelService } from '../../../index/services/index-kit-model.service';

/**
 * Presentational button that downloads the file located at `href`.
 * @field text: the text inside the button
 * @field gDocUrl: the path of the Google docs url
 */
@Component({
  selector: 'nemo-help-link',
  template: `
    <a *ngIf="plateExampleFile" [href]="plateExampleUrl"
       class="help-link">
      Plate example
    </a>

    <a *ngIf="poolExampleFile" [href]="poolExampleUrl"
       class="help-link">
      Pool example
    </a>

    <a [href]="gDocUrl"
       class="help-link"
       target="__NEMO_HELP__"
    >
      <mat-icon class="help-icon">help</mat-icon>
      {{text}}
    </a>

    <a *ngIf="displayIndexDownload"
      class="help-link href-like"
      color="primary"
      (click)="downloadIndexList()"
    ><mat-icon class="help-icon">cloud_download</mat-icon>
      Available indexes
    </a>
  `,
  styles: [`
    :host {
      float: right;
    }

    .help-link {
      margin-left: 15px;
      font-weight: normal;
    }

    .help-icon {
      padding: 4px 0;
      margin-right: 5px;
      vertical-align: middle;
    }

    a.href-like{
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  `]
})
export class HelpLinkComponent implements OnInit {

  @Input() plateExampleFile: string;
  @Input() poolExampleFile: string;
  @Input() text: string;
  @Input() gDocPath: string;

  @Input() displayIndexDownload = false;
  plateExampleUrl: string;
  poolExampleUrl: string;
  gDocUrl: string;

  constructor(
    private readonly indexKitModelService: IndexKitModelService,
  ) {
  }

  ngOnInit(): void {
    if (this.plateExampleFile) {
      this.plateExampleUrl = 'https://storage.cloud.google.com/nemo-example-files-prod/' + this.plateExampleFile;
    }
    if (this.poolExampleFile) {
      this.poolExampleUrl = 'https://storage.cloud.google.com/nemo-example-files-prod/' + this.poolExampleFile;
    }
    this.gDocUrl = 'https://docs.google.com/' + this.gDocPath;
  }

  downloadIndexList() {
    this.indexKitModelService.exportIndexSequenceList()
      .subscribe(({filename, content}) => {
        const blob = new Blob([content], {type: 'text/csv'});
        saveAs(blob, filename);
      });
  }
}
