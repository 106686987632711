import { FlowCellMapping } from '../models/flow-cell-mapping';
import { formatAccessionCode } from '../../shared/pipes/accession-code.pipe';
import { SimpleRequest } from '../../genomics-request/models/genomics-request';
import { Injectable } from '@angular/core';
import { AppState } from '../../store/app.reducers';
import {  Store } from '@ngrx/store';
import { getUserFromId } from '../../user/store';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FlowCellExportService {

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  static getFileName(flowCellId: string, requestAccessionCode?: string) {
    return `nemo-flow-cell-${flowCellId}-${requestAccessionCode ?
                                           formatAccessionCode(requestAccessionCode) + '-' : ''}lane-assignment-sheet.csv`;
  }

  private static filterByRequestIfNotNull(mapping: FlowCellMapping, requestAccessionCode?: string): Boolean {
    return requestAccessionCode
           ? FlowCellExportService.containsRequest(mapping.simplePoolWithRequest.requests, requestAccessionCode)
           : true;
  }

  private static containsRequest(requests: SimpleRequest[], requestAccessionCode: string): boolean {
    for (const request of requests) {
      if (request.accessionCode === requestAccessionCode) {
        return true;
      }
    }
    return false;
  }

  formatData(layout: Array<FlowCellMapping>, requestAccessionCode?: string) {
    return layout
      .filter(mapping => FlowCellExportService.filterByRequestIfNotNull(mapping, requestAccessionCode))
      .map(mapping => this._formatData(mapping));
  }

  private _formatData(mapping: FlowCellMapping): object {
    return {
      lane_number: mapping.laneNumber + 1,
      lane_occupancy: mapping.laneOccupancy * 100 + '%',
      pools_AC: formatAccessionCode(mapping.simplePoolWithRequest.accessionCode),
      pools_number_of_sample: mapping.simplePoolWithRequest.size(),
      request: mapping.simplePoolWithRequest.requests
        .map(request => formatAccessionCode(request.accessionCode))
        .join(' '),
      request_title: mapping.simplePoolWithRequest.requests
        .map(request => request.title)
        .join(' '),
      PhiX: mapping.simplePoolWithRequest.requests
        .map(request => request.phiXPercentage)
        .join(' '),
      requester: this.getRequestersName(mapping)
    };
  }

  private getRequestersName(mapping: FlowCellMapping): string {
    return mapping.simplePoolWithRequest.requests
      .map(request => {
          let requesterName = null;
          this.store.select(getUserFromId(request.requesterId)).pipe(
            
            map(user => user.name)
          ).subscribe(userName => {
            requesterName = userName;
          });
          return requesterName;
        }
      )
      .join(' ');
  }
}
