import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentContainerComponent } from './components/comment-container/comment-container.component';
import { CommentThreadComponent } from './components/comment-container/comment-thread.component';
import { CommentMessageListComponent } from './components/comment-container/comment-message-list.component';
import { CommentMessageNewComponent } from './components/comment-container/comment-message-new.component';
import { MaterialModule } from '../material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { CommentEffects } from './store/comment.effects';
import { CommentDatePipe } from './pipes/comment-date.pipe';
import { UserSharedModule } from '../user/shared/user-shared.module';
import { RouterModule } from '@angular/router';
import { CommentComponent } from './components/comment-container/comment.component';
import { CommentFileContentComponent } from './components/comment-container/comment-file-content.component';
import { CommentTaskContentComponent } from './components/comment-container/comment-task-content.component';
import { CommentTextContentComponent } from './components/comment-container/comment-text-content.component';
import { SharedSharedModule } from '../shared/shared/shared-shared.module';
import { TasksSharedModule } from '../tasks/shared/tasks-shared.module';
import { CommentFileNewButtonComponent } from './components/comment-container/comment-file-new.component';
import { RequestSharedModule } from '../request-shared/request-shared.module';


@NgModule({
  declarations: [
    CommentContainerComponent,
    CommentThreadComponent,
    CommentMessageListComponent,
    CommentMessageNewComponent,
    CommentComponent,
    CommentTextContentComponent,
    CommentFileContentComponent,
    CommentTaskContentComponent,
    CommentDatePipe,
    CommentFileNewButtonComponent
  ],
  exports: [
    CommentContainerComponent,
    CommentThreadComponent,
    CommentMessageListComponent,
    CommentMessageNewComponent,
    CommentDatePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    UserSharedModule,
    SharedSharedModule,
    TasksSharedModule,
    MaterialModule,
    BrowserAnimationsModule,
    EffectsModule.forFeature([CommentEffects]),
    RequestSharedModule
  ]
})
export class CommentModule {
}
