import { Index, IndexModel } from '../../bio/models/barcode-index';
import { IndexKitSummaryDto } from '../../index/models/index-kit.dto';
import { IndexKitSummary } from '../../index/models/indexKit';
import { AssignedIndexDto } from '../../index/models/assigned-index.dto';
import { indexSequencesFromDto } from '../../index/models/index-model.dto';
import { PlateCoordinatesDto } from '../dto/plate-coordinates.dto';
import { plateCoordinatesFromDto } from './plate-coordinates.mapper';
import { IndexKitModelDto } from '../../index/models/index-kit-model.dto';
import { indexKitModelFromDto } from '../../index/services/index-kit-model-mapper';

export class IndexModelDto {
  id: number;
  name: string;
  plateCoordinates: PlateCoordinatesDto;
  kitModel: IndexKitModelDto;
}

export function indexKitSummaryFromDto(dto: IndexKitSummaryDto): IndexKitSummary {
  return {
    id: dto.id,
    barcode: dto.barcode
  };
}

export function indexModelFromDto(indexModelDto: IndexModelDto): IndexModel {
  return {
    id: indexModelDto.id,
    name: indexModelDto.name,
    plateCoordinates: plateCoordinatesFromDto(indexModelDto.plateCoordinates),
    kitModel: indexKitModelFromDto(indexModelDto.kitModel)
  };
}

export function assignedIndexDtoToIndex(assignedIndex: AssignedIndexDto): Index {
  return new Index(
    assignedIndex.id,
    assignedIndex.kit && indexKitSummaryFromDto(assignedIndex.kit),
    true,
    assignedIndex.model && indexModelFromDto(assignedIndex.model),
    indexSequencesFromDto(assignedIndex.sequences),
  );
}
