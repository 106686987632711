import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NRequest } from '../../models/genomics-request';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { findAllFlowCellsForRequest, selectFlowCells } from '../../../labware/store/flow-cells';
import { Observable } from 'rxjs';
import { FlowCell } from '../../../labware/models/flow-cell';
import { UpdateRequestDTO } from '../../models/genomics-request.dto';
import { updateRequest } from '../../store';
import { UntypedFormGroup } from '@angular/forms';
import { selectLibraryMethodByName } from '../../../sequencing/store';
import { GenomicFormRequestService } from '../../services/genomic-form-request.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'nemo-request-info',
  template: `
    <div *ngIf="request">
      <div class="left-pane" *ngIf="request.stages[1].completedAt === null">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="submit-form">
          <nemo-request-submission-form-info [formGroup]="formGroup" [displaySubmissionTypes]="false"/>
  
          <button mat-raised-button
                  id="submit-request-button"
                  type="submit"
                  color="primary"
                  [disabled]="formGroup.pristine || !formGroup.valid">
            Update
          </button>

          <p>All fields with * are required</p>
        </form>
      </div>
      <div class="left-pane" *ngIf="request.stages[1].completedAt !== null">
        
        <p><strong>Title:</strong> {{request.title}}</p>
        <p>
          <strong>Origin:</strong>
          <span *ngIf="request.department" class="origin-field">
            <b>department:</b> [{{request.department.code}}] {{request.department.name}}
          </span>
          <span *ngIf=" ! request.department" class="origin-field">no department</span>
          <span *ngIf="request.project" class="origin-field">
            <b>project:</b> [{{request.project.code}}] {{request.project.name}}
          </span>
          <span *ngIf="! request.project" class="origin-field">no project</span>
          <span *ngIf="request.study" class="origin-field">
            <b>study:</b> {{request.study.name}}
          </span>
          <span *ngIf="! request.study" class="origin-field">no study</span>
        </p>
        <p><strong>Library method:</strong> {{request.libraryMethod}}</p>
        <p><strong>phiX:</strong> {{request.phiXPercentage}} %</p>
        <p><strong>Description:</strong> {{request.description}}</p>
        <p><strong>Fastq file path:</strong> {{request.fastqDir}}</p>
        
        <nemo-request-cro-assignment-container *ngIf="request.stages[2].completedAt === null"
                                               [request]="request"></nemo-request-cro-assignment-container>
        
      </div>
      
      <div *ngIf="request.stages[request?.stages.length - 1].completedAt !== null" class="right-pane">
        <nemo-request-flowcell-export
          [flowCells]="flowCells$ | async"
          [requestIsComplete]="request.stages[request?.stages.length - 1].completedAt !== null"
          [requestAccessionCode]="request.accessionCode">
        </nemo-request-flowcell-export>
      </div>
    </div>
  `,
  styleUrls: ['../request-submission/request-submission-form-info/request-submission-form-info.component.scss']
})
export class RequestInfoComponent implements OnInit, OnChanges {

  @Input() request: NRequest;
  flowCells$: Observable<FlowCell[]>;
  formGroup: UntypedFormGroup;

  constructor(
    private store: Store<AppState>,
    private readonly genomicFormRequestService: GenomicFormRequestService,
  ) {
    this.formGroup = new UntypedFormGroup(
      this.genomicFormRequestService.buidlBasicFormRequestControls()
    );
    this.flowCells$ = this.store.select(selectFlowCells);
  }

  ngOnInit() {
    this.updateFormControlsWithRequest(this.request);
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.request && this.request.stages[2].completedAt) {
      this.store.dispatch(findAllFlowCellsForRequest({
        requestAccessionCode: this.request.accessionCode
      }));
    }
    this.updateFormControlsWithRequest(this.request);
  }

  updateFormControlsWithRequest(request: NRequest) {
    if (this.request) {
      this.store.select(selectLibraryMethodByName(request.libraryMethod))
        .pipe(filter(libraryMethod => Boolean(libraryMethod)), take(1))
        .subscribe(libraryMethod => {
          this.formGroup.get('libraryMethod').setValue(libraryMethod);
          this.formGroup.get('title').setValue(request.title);
          this.formGroup.get('description').setValue(request.description);
          this.formGroup.get('phiXPercentage').setValue(request.phiXPercentage);
          if (this.request.department) {
            this.formGroup.get('departmentCode').setValue(request.department.code);
          }
          if (this.request.project) {
            this.formGroup.get('projectCode').setValue(request.project.code);
          }
          if (this.request.study) {
            this.formGroup.get('studyId').setValue(request.study.id);
          }
          if (this.request.collaborator) {
            this.formGroup.get('collaborator').setValue(request.collaborator);
          }
          this.formGroup.markAsPristine();
        });
    }
  }

  onSubmit() {
    const newValues = new UpdateRequestDTO(
      this.request.accessionCode,
      this.formGroup.get('title').value,
      this.formGroup.get('phiXPercentage').value,
      this.formGroup.get('libraryMethod').value?.name,
      this.formGroup.get('description').value,
      this.formGroup.get('departmentCode').value,
      this.formGroup.get('projectCode').value,
      this.formGroup.get('studyId').value,
      this.formGroup.get('collaborator').value
    );
    this.store.dispatch(updateRequest({request: newValues}));
    this.formGroup.markAsPristine();
  }
}
