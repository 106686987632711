<mat-form-field
  class="full-width"
  appearance="fill"
  [class.numeric]="type === 'number'"
>
  <span class="{{inputClass}} label" matPrefix>{{label}}: </span>
  <input
    #input1
    type="{{type}}"
    matInput
    [formControl]="control"
    (keyup.enter)="saveNewValue()"
    (keyup.escape)="restore()"
    maxlength="{{maxLength}}"
    readonly="{{readonly}}"
    min="{{min}}"
    max="{{max}}"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [class]="inputClass"
  >

  <span
    matSuffix
  >
      <mat-icon
        class="icon-button save"
        (click)="saveNewValue()"
        *ngIf="savedValue !== control.value"
        [class.disabled]="control.errors"
      >save
      </mat-icon>
      <mat-icon
        class="icon-button"
        (click)="restore()"
        *ngIf="initValue !== control.value"
      >undo
      </mat-icon>
      </span>
  <mat-hint
    align="end"
    *ngIf="maxLength && hasFocus"
  >{{control?.value?.length}} / {{maxLength}}</mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{label}} cannot be empty
  </mat-error>
</mat-form-field>
