import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { FormService } from '../../services/form.service';
import {
  actionFormLoadLatestYaml,
  actionFormSave,
  actionFormSaveSuccess,
  actionFormValidateError,
  actionFormValidateSuccess,
  actionFormValidateYaml,
  actionLoadLatestYamlSuccess
} from './form-editor.actions';
import { insertErrorUncaughtFormValidation } from '../../../shared/nerrors/store';
import { NError } from '../../../shared/nerrors/models/error.model';
import { TextValidationError } from '../../models/text-validation-error';

@Injectable()
export class FormEditorEffects {
  constructor(
    private actions$: Actions,
    private formService: FormService
  ) {
  }

  load$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFormLoadLatestYaml),
    mergeMap((action: ReturnType<typeof actionFormLoadLatestYaml>) => {
      const {typeName} = action;
      return this.formService.loadLatestYaml(typeName);
    }),
    switchMap(yaml => [
      actionLoadLatestYamlSuccess({yaml}),
      actionFormValidateYaml({yaml})
    ])
  ) });

  validateYaml$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFormValidateYaml),
    mergeMap((action: ReturnType<typeof actionFormValidateYaml>) => {
        const {yaml} = action;
        return this.formService.validateYaml(yaml).pipe(
          map(formStructure => actionFormValidateSuccess({formStructure})),
          catchError((err) => {
            if (err.status === 422) { // UNPROCESSABLE_ENTITY
              const validationError = new TextValidationError(err.error.problemMark, err.error.message);
              return of(actionFormValidateError({error: validationError}));
            }
            return of(insertErrorUncaughtFormValidation({error: new NError(err.message, err)}));
          })
        );
      }
    ),
    catchError((err) => {
      return of(insertErrorUncaughtFormValidation({error: new NError(err.message, err)}));
    })
  ) });

  create$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFormSave),
    mergeMap((action: ReturnType<typeof actionFormSave>) => {
        const {typeName, yaml} = action;
        return this.formService.save(typeName, yaml).pipe(
          map(form => actionFormSaveSuccess({form}))
        );
      }
    )
  ) });
}
