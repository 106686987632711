import { FragmentAnalyzerQCMeasure } from './fragment-analyzer-qc-measure';
import PlateDimensions from '../../labware/models/plate-dimensions';
import { SimplePlateWithRequests } from '../../labware/models/simple-plate';
import { InstrumentFile } from './instrumentFile';
import { InstrumentDirectory } from './instrumentDirectory';
import { TaskAvailable } from '../../tasks/models/task.model';

export class PlateQC {
  public readonly dimensions = new PlateDimensions(8, 12);
  public readonly occupancy: boolean[];

  constructor(
    public readonly accessionCode,
    public measures: FragmentAnalyzerQCMeasure[],
    public readonly plate: SimplePlateWithRequests,
    public readonly instrumentFile: InstrumentFile,
    public readonly instrumentDirectory: InstrumentDirectory,
    public readonly     availableForTasks: TaskAvailable[]
  ) {
    this.occupancy = [];
    for (let i = 0; i < this.dimensions.size(); i++) {
      this.occupancy[i] = false;
    }
    this.measures.forEach(qc => this.occupancy[qc.plateCoordinates.column *
    this.dimensions.numberOfRows +
    qc.plateCoordinates.row] = true);
  }
}


export class PlateQCSummary {
  constructor(
    public readonly   accessionCode: string,
    public readonly instrumentName: string,
    public readonly instrumentFile: InstrumentFile,
    public readonly plateAccessionCode: string,
    public readonly  availableForTasks: TaskAvailable[]
  ) {
  }
}
