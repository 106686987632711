import { Component, Input } from '@angular/core';
import { NRequest } from '../../models/genomics-request';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { completeStage, reopenStage } from '../../store';

@Component({
  selector: 'nemo-request-stages',
  templateUrl: '../../../shared/shared/components/display-stages/display-stages.component.html',
  styleUrls: ['../../../shared/shared/components/display-stages/display-stages.component.scss']
})
export class RequestStagesComponent {

  @Input() request: NRequest;
  @Input() isLaber: boolean;
  @Input() showNames = false;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  toggleCompletion(stage: string, alreadyCompleted: boolean) {
    if (alreadyCompleted) {
      this.store.dispatch(reopenStage({accessionCode: this.request.accessionCode, stage}));
    } else {
      this.store.dispatch(completeStage({accessionCode: this.request.accessionCode, stage}));
    }
  }

}
