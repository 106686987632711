import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedSharedModule } from '../../shared/shared/shared-shared.module';
import { RouterModule } from '@angular/router';
import { TableModule } from '../../table/table.module';
import { PlateQcTasksButtonsComponent } from './components/plate-qc-tasks-buttons.component';

@NgModule({
  declarations: [
    PlateQcTasksButtonsComponent
  ],
  exports: [
    PlateQcTasksButtonsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedSharedModule,
    RouterModule,
    TableModule
  ],
  providers: []
})
export class QCSharedModule {
}
