import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'nemo-todo-card',
  template: `
    <mat-card appearance="outlined" routerLink="/todo/{{id}}" [class.active-card]="active">
      <mat-card-title>{{title}}</mat-card-title>

      <mat-card-content *ngIf="available && sampleCount !== undefined && requestCount !== undefined">
        <strong>{{requestCount}} requests</strong> with <strong>{{sampleCount}} samples</strong>
      </mat-card-content>

      <mat-card-content *ngIf="available && sampleCount === undefined && requestCount === undefined">
        <br/>
      </mat-card-content>

      <mat-card-content *ngIf="!available">Not yet available</mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./todo-card.component.scss']
})
export class TodoCardComponent implements OnInit {

  @Input() id: string;
  @Input() title: string;
  @Input() requestCount: number | undefined;
  @Input() sampleCount: number | undefined;
  @Input() available = true; // TODO: remove, this is just to display all tasks

  active = false;
  regexp: RegExp;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.regexp = new RegExp(`.*${this.id}$`);
    this.setActive();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.setActive());
  }

  setActive() {
    this.active = this.regexp.test(this.router.url);
  }
}
