import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { archivePool, unarchivePool } from '../../store/actions';
import { PoolWithRequest } from '../../models/pool';

@Component({
  selector: 'nemo-pool-page-title',
  template: `
    <div class="accession-code">{{accessionCode | accessionCode}}</div>
    <nemo-pending [pending]="pending"></nemo-pending>
    <nemo-switch-button [archived]="pool?.archived"
                        (event)="onSwitchButtonClick(accessionCode, $event)"></nemo-switch-button>
    <nemo-pool-export-button [pool]="pool"></nemo-pool-export-button>
  `,
  styles: [`
    :host {
      border-bottom: 1px solid #dadce0;
      height: 30px;
      margin: 15px 0 0 0;
      display: flex;
      justify-content: space-between;
    }

    .accession-code {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      color: #555;
      line-height: 24px;
      margin: 0;
      flex-grow: 0.3;
      font-weight: bold;
    }

    nemo-pending {
      flex: auto;
    }

    nemo-switch-button {
      margin: -9px 10px 3px 0;
    }

    nemo-pool-export-button {
      margin: -9px 10px 3px 0;
      float: right;
    }
  `]
})
export class PoolPageTitleComponent {

  @Input() accessionCode: string;
  @Input() pending: boolean;
  @Input() pool: PoolWithRequest;

  constructor(
    private store: Store<AppState>
  ) {
  }

  onSwitchButtonClick(accessionCode: string, archive: boolean) {
    if (archive) {
      this.store.dispatch(archivePool({accessionCode}));
    } else {
      this.store.dispatch(unarchivePool({accessionCode}));
    }
  }
}
