import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { RequestPageComponent } from './components/request-page/request-page.component';
import { TasksModule } from '../tasks/tasks.module';
import { MaterialModule } from '../material.module';
import {
  RequestSubmissionFormComponent,
} from './components/request-submission/request-submission-form/request-submission-form.component';
import { LabWareModule } from '../labware/labware.module';
import { RequestSubmissionPageComponent } from './components/request-submission/request-submission-page.component';
import {
  SampleFileValidator,
} from './components/request-submission/request-submission-form/sample-file-validator.service';
import { requestRoutes } from './request.routes';
import { RequestSearchResultComponent } from './components/request-search-result/request-search-result.component';
import { RequestListPageComponent } from './components/request-list-page/request-list-page.component';
import { SharedModule } from '../shared/shared.module';
import {
  RequestSampleContainersComponent,
} from './components/request-sample-containers/request-sample-containers.component';
import { BioModule } from '../bio/bio.module';
import {
  RequestSubmissionSamplesComponent,
} from './components/request-submission/request-submission-samples.component';
import { TasksSharedModule } from '../tasks/shared/tasks-shared.module';
import { RequestPageTitleComponent } from './components/request-page/request-page-title.component';
import { RequestInfoComponent } from './components/request-page/request-info.component';
import { RequestSamplesComponent } from './components/request-page/request-samples.component';
import { UserSharedModule } from '../user/shared/user-shared.module';
import { GraphSharedModule } from '../graph/shared/graph-shared.module';
import {
  RequestSubmissionMetadataComponent,
} from './components/request-submission/request-submission-metadata.component';
import { RequestFlowCellsExportComponent } from './components/request-page/request-flow-cells-export.component';
import { RequestSharedModule } from '../request-shared/request-shared.module';
import { RequestStagesComponent } from './components/request-page/request-stages.component';
import { RequestSampleIndexHintComponent } from './components/request-sample-index-hint';
import { TableModule } from '../table/table.module';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  RequestSubmissionFormRefTranscriptomeComponent,
} from './components/request-submission/request-submission-form-ref-transcriptome/request-submission-form-ref-transcriptome.component';
import {
  RequestSubmissionFormLibraryMethodComponent,
} from './components/request-submission/request-submission-form-library-method/request-submission-form-library-method.component';
import {
  RequestSubmissionFormPhixComponent,
} from './components/request-submission/request-submission-form-phix/request-submission-form-phix.component';
import {
  RequestSubmissionFormSpeciesComponent,
} from './components/request-submission/request-submission-form-species/request-submission-form-species.component';
import {
  RequestSubmissionFormSampleTypeComponent,
} from './components/request-submission/request-submission-form-sample-type/request-submission-form-sample-type.component';
import {
  RequestSubmissionFormDemuxParametersComponent,
} from './components/request-submission/request-submission-form-demux-parameters/request-submission-form-demux-parameters.component';
import {
  RequestSubmissionFormFileUploadComponent,
} from './components/request-submission/request-submission-form-file-upload/request-submission-form-file-upload.component';
import {
  SubmissionTypeKeyValuePipe,
} from './components/request-submission/request-submission-form-library-method/request-submission-form-library-method.pipe';
import { CommonRequestModule } from '../commons/common-request/common-request.module';
import {
  RequestSubmissionFormInfoComponent,
} from './components/request-submission/request-submission-form-info/request-submission-form-info.component';

@NgModule({
  declarations: [
    RequestFlowCellsExportComponent,
    RequestInfoComponent,
    RequestListPageComponent,
    RequestPageComponent,
    RequestPageTitleComponent,
    RequestSampleContainersComponent,
    RequestSamplesComponent,
    RequestSearchResultComponent,
    RequestStagesComponent,
    RequestSubmissionFormComponent,
    RequestSubmissionMetadataComponent,
    RequestSubmissionPageComponent,
    RequestSubmissionSamplesComponent,
    RequestSampleIndexHintComponent,
    RequestSubmissionFormRefTranscriptomeComponent,
    RequestSubmissionFormLibraryMethodComponent,
    RequestSubmissionFormPhixComponent,
    RequestSubmissionFormSpeciesComponent,
    RequestSubmissionFormSampleTypeComponent,
    RequestSubmissionFormSampleTypeComponent,
    RequestSubmissionFormDemuxParametersComponent,
    RequestSubmissionFormFileUploadComponent,
    RequestSubmissionFormInfoComponent,
  ],
  imports: [
    BioModule,
    BrowserAnimationsModule,
    CommonModule,
    GraphSharedModule,
    HttpClientModule,
    LabWareModule,
    MaterialModule,
    ReactiveFormsModule,
    RequestSharedModule,
    RouterModule.forChild(requestRoutes),
    SharedModule,
    TableModule,
    TasksModule,
    TasksSharedModule,
    UserSharedModule,
    MatExpansionModule,
    SubmissionTypeKeyValuePipe,
    CommonRequestModule,
  ],
  exports: [
  ],
  providers: [
    SampleFileValidator,
  ]
})
export class RequestModule {
}
