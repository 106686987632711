import { Component, Input } from '@angular/core';
import { IndexConflictWithLaneNumber } from '../../../labware/models/flow-cell-mapping';

@Component({
  selector: 'nemo-lane-assignment-conflict-item',
  template: `
    <h3>Lane {{conflict.laneNumber + 1}}</h3>

    <div class="termini-list">
      <div *ngFor="let entry of conflict.conflictingTerminiListMap | keyvalue" class="termini">
        <span class="terminus-sequence">{{entry.key}}</span>
        <br/>found in
        <span *ngFor="let parents of entry.value" class="flow-cell-id">
          <br/>&nbsp;{{parents.sample}} ({{parents.pool | accessionCode}})
        </span>
      </div>
    </div>
  `,
  styles: [`
    div.termini-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    div.termini {
      margin-left: 1em;
      margin-right: 1em;
    }

    .terminus-sequence {
      font-family: monospace;
    }
  `]
})
export class LaneAssignmentConflictItemComponent {
  @Input() conflict: IndexConflictWithLaneNumber;
}
