import { Component, Input, OnInit } from '@angular/core';
import { SimplePlateWithRequests } from '../../models/simple-plate';
import { PlateSnippet } from '../../dto/labware.dto';


export function plateIndexingCompletionHtml(plate: PlateSnippet): string {
  let ratioCompleted: number;
  let text: string;
  let progressBackgroundColor: string;
  let textColor = 'black';

  if (plate.numberOfSamples === 0) {
    ratioCompleted = 0;
    text = 'empty';
  } else if (plate.numberOfIndexedSamples === 0) {
    ratioCompleted = 0;
    text = 'none index';
  } else if (plate.numberOfIndexedSamples === plate.numberOfSamples) {
    ratioCompleted = 1;
    text = 'indexed';
    progressBackgroundColor = 'blue';
    textColor = 'white';
  } else {
    ratioCompleted = plate.numberOfIndexedSamples / plate.numberOfSamples;
    text = plate.numberOfIndexedSamples + '/' + plate.numberOfSamples;
    progressBackgroundColor = 'orange';
  }

  return `
<div style="
   position: relative;
   height: 1.4em;
   width: 100%;
   background-color: lightgrey;
   border-radius: 4px;
   min-width: 6em;
   ">
      <div style="
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         border-radius: 4px;
         opacity: 0.8;
         width:${ratioCompleted * 100}%;
         background-color: ${progressBackgroundColor};
         "></div>
      <div style="
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         text-align: center;
         font-weight: bold;
         color: ${textColor}
         ">${text}</div>
</div>
`;
}

@Component({
  selector: 'nemo-plate-indexing-completion',
  template: `
    <div class="background"
         [routerLink]="indexRoute"
         [style.cursor]="indexRoute?'pointer':'inherit'"
    >
      <div class="progress"
           [style.background-color]="progressBackgroundColor"
           [style.width]="(ratioCompleted*100)+'%'"
      ></div>
      <div class="text"
           [style.color]="textColor"
      >{{text}}</div>
    </div>
  `,
  styles: [`
             div.background {
               position: relative;
               height: 1.4em;
               width: 100%;
               background-color: lightgrey;
               border-radius: 4px;
               min-width: 5em;
             }

             div.progress {
               position: absolute;
               top: 0;
               left: 0;
               height: 100%;
               border-radius: 4px;
               opacity: 0.8;
             }

             div.text {
               position: absolute;
               top: 0;
               left: 0;
               height: 100%;
               width: 100%;
               text-align: center;
               font-weight: bold;
             }
           `]
})
export class PlateIndexingCompletionComponent implements OnInit {

  @Input() plate: SimplePlateWithRequests;

  ratioCompleted: number;
  text: string;
  progressBackgroundColor: string;
  textColor = 'black';

  indexRoute: string;

  ngOnInit(): void {
    if (this.plate.requests.length === 1) {
      this.indexRoute = '/tasks/index-assign/' + this.plate.accessionCode;
    }

    if (this.plate.numberOfSamples === 0) {
      this.ratioCompleted = 0;
      this.text = 'empty';
      return;
    }

    if (this.plate.numberOfIndexedSamples === 0) {
      this.ratioCompleted = 0;
      this.text = 'none index';
      return;
    }

    if (this.plate.numberOfIndexedSamples === this.plate.numberOfSamples) {
      this.ratioCompleted = 1;
      this.text = 'indexed';
      this.progressBackgroundColor = 'blue';
      this.textColor = 'white';
      return;
    }

    this.ratioCompleted = this.plate.numberOfIndexedSamples / this.plate.numberOfSamples;
    this.text = this.plate.numberOfIndexedSamples + '/' + this.plate.numberOfSamples;
    this.progressBackgroundColor = 'orange';
  }
}
