import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { actionFormLoadAllFormTypes } from '../../../store/form';
import { selectFormAllFormTypes } from '../../../store/form/form.selector';
import { FormType } from '../../../models/form-type';
import { Lab } from '../../../../company/models/Lab';

@Component({
  selector: 'nemo-form-settings-list',
  template: `
    <div class="container">
      <table
        mat-table
        [dataSource]="all$ | async"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="lab">
          <th mat-header-cell *matHeaderCellDef>Lab</th>
          <td mat-cell *matCellDef="let element"> {{getLabNameFromAlias(element.lab)}} </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell
              *matCellDef="let element"
          >
            <button
              mat-mini-fab
              color="primary"
              routerLink="/form/edit/{{element.name}}"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{archived:row.archived}"
        ></tr>
      </table>
    </div>
  `,
  styles: [`
             table {
               width: 100%;
             }

             tr.archived td.mat-mdc-cell {
               color: lightgrey !important;
             }
           `]
})
export class FormSettingsListComponent implements OnInit {

  @Input() labDict: { [alias: string]: Lab };
  all$: Observable<FormType[]>;

  displayedColumns = ['name', 'lab', 'edit'];

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.all$ = this.store.
      select((selectFormAllFormTypes)
    );
    this.store.dispatch(actionFormLoadAllFormTypes());
  }

  getLabNameFromAlias(alias: string) {
    return this.labDict[alias] ? this.labDict[alias].name : '-';
  }

}
