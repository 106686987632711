import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestCardComponent } from './components/request-card.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { PhiXComponent } from './components/phi-x.component';
import { SharedSharedModule } from '../shared/shared/shared-shared.module';
import { RequestsAccessionCodePipe } from './pipe/requests-accession-code.pipe';
import { RequestsTitlesPipe } from './pipe/requests-title.pipe';
import { RequestsPhixPipe } from './pipe/requests-phix.pipe';
import { UserSharedModule } from '../user/shared/user-shared.module';
import { RequestListComponent } from './components/request-list.component';
import { TableModule } from '../table/table.module';

@NgModule({
  declarations: [
    RequestCardComponent,
    PhiXComponent,
    RequestsAccessionCodePipe,
    RequestsTitlesPipe,
    RequestsPhixPipe,
    RequestListComponent,
  ],
  exports: [
    RequestCardComponent,
    PhiXComponent,
    RequestsAccessionCodePipe,
    RequestsPhixPipe,
    RequestsTitlesPipe,
    RequestListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    SharedSharedModule,
    TableModule,
    UserSharedModule
  ]
})
export class RequestSharedModule {
}
