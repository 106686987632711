import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RequestService } from '../../../genomics-request/services/request.service';
import { NRequestSummary } from '../../../genomics-request/models/genomics-request';
import { UserService } from '../../../user/services/user.service';
import { UserDTO } from '../../../user/models/user.dto';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormRequestSummary } from '../../../form/models/form-request';
import { FormRequestService } from '../../../form/services/form-request.service';
import { Page } from '../../../shared/models/page.model';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent extends HasGlobalContextUserRole implements OnInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;
  form: UntypedFormGroup;
  requests: FormRequestSummary[] | NRequestSummary[] = [];
  users: UserDTO[] = [];
  search = '';
  isGenomics = false;
  labSubscription: Subscription;

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private requestService: RequestService,
    private formRequestService: FormRequestService,
    private userService: UserService,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router
  ) {
    super(store);
    this.isLoggedIn$ = this.authService.isAuthenticated$;
  }

  ngOnInit() {
    this.labSubscription = this.isLab$('genomics').subscribe(isGenomics =>
      this.isGenomics = isGenomics
    );
    this.form = this.buildForm();
    this.onSearchValueChange();
  }

  ngOnDestroy(): void {
    if (this.labSubscription) {
      this.labSubscription.unsubscribe();
    }
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      search: this.fb.control('')
    });
  }

  onSearchValueChange() {
    this.form.get('search').valueChanges.pipe(
      debounceTime(400)
    ).subscribe(search => {
      if (search) {
        this.search = search;
        if (this.isGenomics) {
          this.searchGenomicsRequests(search);
        } else {
          this.searchFormRequests(search);
        }
        this.searchUsers(search);
      }
    });
  }

  searchGenomicsRequests(search: string) {
    this.requestService
      .search(search)
      .subscribe((requests: Page<NRequestSummary>) => {
        this.requests = requests.content;
      });
  }

  searchFormRequests(search: string) {
    this.formRequestService
      .search(search)
      .subscribe((requests: FormRequestSummary[]) => {
        this.requests = requests;
      });
  }

  searchUsers(search: string) {
    this.userService
      .search(search)
      .subscribe((users: UserDTO[]) => {
        this.users = users;
      });
  }

  goToRequestListByRequester(search: string) {
    this.reset();
    this.router.navigate([this.isGenomics ? '/requests/search' : '/form-requests/search', {requester: search}]);
  }

  goToRequestPage(accessionCode: string) {
    this.reset();
    this.router.navigate([(this.isGenomics ? '/request/' : '/form-request/') + accessionCode]);
  }

  onSubmit() {
    const search = this.form.get('search').value;
    this.reset();
    this.router.navigate([this.isGenomics ? '/requests/search' : '/form-requests/search', {q: search}]);
  }

  reset() {
    this.autoComplete.closePanel();
    this.form.get('search').setValue('');
    this.requests = [];
    this.users = [];
  }
}
