import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nemo-dialog-confirmation-ok',
  template: `
      <div mat-dialog-content>
          {{data['message']}}
      </div>
      <div
              mat-dialog-actions
              align="end"
      >
          <button mat-raised-button
                  color="primary"
                  (click)="onClick()"
                  cdkFocusInitial
          >Ok
          </button>
      </div>
  `,
  styles: [
    `
        `
  ]
})
export class DialogConfirmationOkComponent {
  @Input() message: string;

  @Input() route: string;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
  }

  onClick() {
    this.dialog.closeAll();
  }
}
