import {
  clearPlateSelectionFromTaskApi,
  echoPoolingImportError,
  emptyPlate,
  findPlateByAccessionCode,
  findPlateByAccessionCodeThenSelect,
  findPlateByAccessionCodeThenSelectFromEchoPooling,
  findPlateByAccessionCodeThenSelectFromPlateApi,
  findPlateByAccessionCodeThenSelectFromReArray,
  findPlateByAccessionCodeThenSelectFromTaskApi,
  PlatesDetailsActions,
  putPlate,
  removePlateFromEchoPooling,
  removePlateFromIndexAssignment,
  removePlateFromReArray,
  setPlateSelection,
  updateSetSelection,
  updateUnsetSelection
} from './actions/plates-details.actions';
import { PlateCoordinatesSelection } from '../../models/plate-coordinates-selection';
import { Plate96 } from '../../models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { countPlatesError } from './actions/plate-api.actions';
import { PlateCounts } from '../../dto/plate.dto';
import { SimplePlateWithRequests } from '../../models/simple-plate';
import {
  countPlatesSuccess,
  findPlateByAccessionCodeError,
  findPlateByAccessionCodeSuccess,
  PlatesApiActions,
  platesFindAllError,
  platesFindAllSuccess,
  platesSearchError,
  platesSearchSuccess,
  updatePlateFieldSuccess
} from './actions/plates-api.action';
import {
  archivePlateSuccess,
  findAllPlatesFromAPI,
  findAllPlatesFromEchoPooling,
  findAllPlatesFromIA,
  findAllPlatesFromReArray, findAllPlatesSnippets, findAllPlatesSnippetsSuccess,
  findAllPlatesToAssignQC,
  platesFindAll,
  platesFindAllFromTodoPage,
  PlatesListActions,
  processEchoPoolingError,
  processReArrayError, unarchivePlateSuccess
} from './actions/plate-list.action';
import { PageInfo } from '../../../shared/models/page.model';
import { PlateSnippet } from '../../dto/labware.dto';

/**
 * The platesStates handle the plates universe in the app:
 *   * plates: the plates themselves, referenced by an plateAccessionCode
 *   * rangeSelections: the selected bioMaterialPlateMappings, referenced by the same plateAccessionCode
 *
 * Plate & WellSection objects are immutable. All operations return a new instance, in order to be store friendly
 */
export default interface PlatesState {
  plates: { [key: string]: Plate96<BioMaterial> };
  rangeSelections: {};
  message: string;
  plateCounts: Map<string, PlateCounts>;
  plateList: Map<string, PlateSnippet[]>;
  snippets: PlateSnippet[];
  currentPlate: Plate96<BioMaterial> | null; // current plate for details screen, c.f. request reducer
  pending: boolean;
}

export const initialState: PlatesState = {
  plates: {},
  rangeSelections: {},
  plateList: new Map<string, PlateSnippet[]>(),
  snippets: [],
  plateCounts: new Map<string, PlateCounts>(),
  message: '',
  currentPlate: null,
  pending: false
};

export function reducer(state = initialState, action: PlatesDetailsActions | PlatesApiActions | PlatesListActions) {
  switch (action.type) {

    case platesFindAll.type:
    case findAllPlatesFromIA.type:
    case findAllPlatesFromReArray.type:
    case platesFindAllFromTodoPage.type:
    case findAllPlatesFromEchoPooling.type:
    case findAllPlatesFromAPI.type:
    case findAllPlatesToAssignQC.type:
      return {
        ...state,
        pending: true,
        message: ''
      };

    case findPlateByAccessionCodeThenSelect.type:
    case findPlateByAccessionCodeThenSelectFromTaskApi.type:
    case findPlateByAccessionCodeThenSelectFromReArray.type:
    case findPlateByAccessionCodeThenSelectFromEchoPooling.type:
    case findPlateByAccessionCodeThenSelectFromPlateApi.type:
    case findPlateByAccessionCode.type:
      return {
        ...state,
        pending: true,
        currentPlate: null,
        message: ''
      };

    case findAllPlatesSnippets.type:
      return {...state, snippets: null};

    case findAllPlatesSnippetsSuccess.type:
      return {...state, snippets: action.snippets};

    case countPlatesSuccess.type: {
      const key = action.availableTask === undefined ? 'all' : action.availableTask;
      const plateCounts = state.plateCounts;
      plateCounts[key] = action.plateCounts;
      return {
        ...state,
        pending: false,
        plateCounts: plateCounts,
      };
    }

    case countPlatesError.type:
      return {
        ...state,
        pending: false,
        message: action.message
      };

    // if the readonly is set, we then also need to register a PlateCoordinatesSelection
    case putPlate.type: {
      const {plateAccessionCode, plate, readonly} = action;

      if (readonly) {
        return {...state, plates: {...state.plates, [plateAccessionCode]: plate}};
      } else {
        return {
          ...state,
          plates: {...state['plates'], [plateAccessionCode]: plate},
          rangeSelections: {
            ...state.rangeSelections,
            [plateAccessionCode]: new PlateCoordinatesSelection(plate.dimensions),
          },
        };
      }
    }

    case findPlateByAccessionCodeSuccess.type:
    case updatePlateFieldSuccess.type: {
      return {
        ...state,
        currentPlate: action.plate,
        pending: false
      };
    }

    case removePlateFromReArray.type:
    case removePlateFromEchoPooling.type:
    case removePlateFromIndexAssignment.type: {
      const {plateAccessionCode} = action;
      const {...plates} = state.plates;
      const {...rangeSelections} = state.rangeSelections;

      delete plates[plateAccessionCode];
      delete rangeSelections[plateAccessionCode];

      return {...state, plates, rangeSelections};
    }

    case emptyPlate.type: {
      const {plateAccessionCode, plateCoordinatesSelection} = action;

      const {...newPlates} = state.plates;
      newPlates[plateAccessionCode] =
        newPlates[plateAccessionCode].emptyAtSelection(plateCoordinatesSelection);

      return {...state, plates: newPlates};
    }


    case setPlateSelection.type: {
      const {plateAccessionCode, plateCoordinatesSelection} = action;

      return {
        ...state,
        rangeSelections: {
          ...state['rangeSelections'],
          [plateAccessionCode]: plateCoordinatesSelection,
        },
      };
    }

    case updateSetSelection.type: {
      const {plateAccessionCode, pos, clear} = action;

      const prevSelection = state.rangeSelections[plateAccessionCode];

      // if clear is passed to the payload, then we reset the selection
      const newSelection = clear ?
                           prevSelection.clearSelection().select(pos) :
                           prevSelection.select(pos);

      return {
        ...state,
        rangeSelections: {
          ...state['rangeSelections'],
          [plateAccessionCode]: newSelection,
        },
      };
    }

    case updateUnsetSelection.type: {
      const {plateAccessionCode, pos} = action;

      return {
        ...state,
        rangeSelections: {
          ...state['rangeSelections'],
          [plateAccessionCode]: state.rangeSelections[plateAccessionCode].unselect(pos),
        },
      };

    }

    case clearPlateSelectionFromTaskApi.type: {
      const plateAccessionCode = action.plateAccessionCode;

      return {
        ...state,
        rangeSelections: {
          ...state['rangeSelections'],
          [plateAccessionCode]: state.rangeSelections[plateAccessionCode].clearSelection(),
        },
      };
    }
    case platesSearchSuccess.type:
    case platesFindAllSuccess.type: {
      const key = action.mapKey === undefined ? 'all' : action.mapKey;
      const plateList = state.plateList;
      plateList[key] = action.plates;
      return {
        ...state,
        pending: false,
        plateList: plateList
      };
    }

    case archivePlateSuccess.type:
    case unarchivePlateSuccess.type: {
      const key = 'all';
      const plate = action.plateSnippet;
      const updatedList = state.plateList[key].map((p: PlateSnippet) =>
        (p.accessionCode === plate.accessionCode) ? plate : p
      );
      return {
        ...state,
        plateList: {...state.plateList, [key]: updatedList}
      };
    }

    case platesSearchError.type:
    case platesFindAllError.type:
    case findPlateByAccessionCodeError.type:
    case processReArrayError.type:
    case processEchoPoolingError.type:
    case echoPoolingImportError.type:
      return {...state, pending: false, message: action.message};

    default:
      return state;
  }
}
