import { Pipe, PipeTransform } from '@angular/core';
import { Pool } from '../../models/pool';

@Pipe({
  name: 'poolSize'
})
export class PoolSizePipe implements PipeTransform {

  transform(pool: Pool): number {
    if (pool) {
      return pool.size();
    }
  }
}
