import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { AppState } from '../../../store/app.reducers';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nemo-echo-pooling-page',
  template: `
    <nemo-echo-pooling-title></nemo-echo-pooling-title>

    <div class="container">
      <nemo-echo-pooling-left-zone [sourcePlateAccessionCode]="sourcePlateAccessionCode$ | async"
                                   [plateAccessionCode$]="destinationPlateAccessionCode$"></nemo-echo-pooling-left-zone>

      <nemo-echo-pooling-middle-zone
        [targetAccessionCode]="destinationPlateAccessionCode$ | async"></nemo-echo-pooling-middle-zone>

      <nemo-echo-pooling-right-zone [destinationPlateAccessionCode]="destinationPlateAccessionCode$ | async"
                                    [plateAccessionCode$]="sourcePlateAccessionCode$"></nemo-echo-pooling-right-zone>
    </div>

    <h3>Echo pooling from {{sourcePlateAccessionCode$ | async | accessionCode}}</h3>
    <nemo-echo-pooling-table></nemo-echo-pooling-table>
  `,
  styles: [`
    .container {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }

    nemo-echo-pooling-right-zone, nemo-echo-pooling-left-zone {
      width: 455px;
    }
  `]
})
export class EchoPoolingPageComponent implements OnInit {

  sourcePlateAccessionCode$: Observable<string>;
  destinationPlateAccessionCode$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.destinationPlateAccessionCode$ = this.route.params.pipe(
      map(params => {
        let destAC = params['destinationPlateAccessionCode'];
        if (destAC === 'undefined') {
          destAC = undefined;
        }
        return destAC;
      })
    );
    this.sourcePlateAccessionCode$ = this.route.params.pipe(
      map(params => {
        let sourceAC = params['sourcePlateAccessionCode'];
        if (sourceAC === 'undefined') {
          sourceAC = undefined;
        }
        this.titleService.setTitle(`Nemo echo pooling plate ${formatAccessionCode(sourceAC)}`);
        return sourceAC;
      })
    );
  }
}
