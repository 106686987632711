import { SimplePoolWithRequest } from '../../bio/models/pool';
import { FlowCellMapping } from './flow-cell-mapping';
import { SequencerModel } from '../../sequencing/models/sequencer-model';
import { SequencerConfiguration } from '../../sequencing/models/sequencer-configuration';

/**
 * Flow cell with lane assigned
 * This shall be a immutable object, as it resides in the store
 */
export class FlowCell {
  constructor(
    public readonly id: number,
    public readonly flowCellId: string,
    public readonly sequencerModel: SequencerModel,
    public readonly sequencerConfiguration: SequencerConfiguration | null,
    public readonly numberOfLanes: number,
    public readonly createdAt: Date,
    public layout: Array<FlowCellMapping>,
    public concentrations: number[],
    public status: string,
    public archived: boolean) {
  }

  private getFlowCellMappingIfExists(poolAccessionCode: string, laneNumber: number): FlowCellMapping | undefined {
    return this.layout
      .filter((m) => m.laneNumber === laneNumber)
      .find((m) => m.simplePoolWithRequest.accessionCode === poolAccessionCode);
  }

  public getSimplePoolsWithRequest(): SimplePoolWithRequest[] {
    return this.layout
      .map(mapping => mapping.simplePoolWithRequest)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.accessionCode.localeCompare(b.accessionCode));
  }

  /**
   * Add a pool in the given lane number and adapt the existing laneOccupancy if any
   * It will throw if the pool (AC) is assigned twice to the same lane
   * @return a new FlowCell for immutability
   */
  addPool(pool: SimplePoolWithRequest, laneNumber: number): FlowCell {
    if (this.getFlowCellMappingIfExists(pool.accessionCode, laneNumber) !== undefined) {
      throw Error(`Cannot assign pool [${pool.accessionCode}] twice to lane ${laneNumber + 1}`);
    }
    const nbInLane = this.layout.filter((m) => m.laneNumber === laneNumber).length;
    const ratio = 1 / (nbInLane + 1);
    const newLayout = this.layout.map((m) => (m.laneNumber === laneNumber) ? m.setLaneOccupancy(m.laneOccupancy * (1.0 - ratio)) : m);
    newLayout.push(new FlowCellMapping(pool, laneNumber, ratio));
    return new FlowCell(
      this.id,
      this.flowCellId,
      this.sequencerModel,
      this.sequencerConfiguration,
      this.numberOfLanes,
      this.createdAt,
      newLayout,
      this.concentrations,
      this.status,
      this.archived
    );
  }

  /**
   * remove a pool (AC based) from a given lane.
   * laneOccupancy for the remaining pools must be scaled linearly accordingly
   * @throws if the pool is not present
   * @return a new FlowCell for immutability
   */
  removePool(pool: SimplePoolWithRequest, laneNumber: number): FlowCell {
    const mapping = this.getFlowCellMappingIfExists(pool.accessionCode, laneNumber);
    if (mapping === undefined) {
      throw Error(`Cannot remove pool [${pool.accessionCode}] from lane ${laneNumber + 1} as it is not present`);
    }

    const ratio = mapping.laneOccupancy;
    const newLayout = this.layout
      .filter((m) => !((m.laneNumber === laneNumber) && (m.simplePoolWithRequest.accessionCode === pool.accessionCode)))
      .map((m) => (m.laneNumber !== laneNumber) ? m : m.setLaneOccupancy(m.laneOccupancy / (1.0 - ratio)));
    return new FlowCell(
      this.id,
      this.flowCellId,
      this.sequencerModel,
      this.sequencerConfiguration,
      this.numberOfLanes,
      this.createdAt,
      newLayout,
      this.concentrations,
      this.status,
      this.archived
    );
  }
}

export class FlowCellNotificationResponse {
    retCode: number;
    message: string;
}
