<div class="line" [ngClass]="{names: showNames}">
  <div *ngFor="let stage of request?.stages; let i=index" class="container">
    <div class="stage-container">
      <div *ngIf="i !== 0" class="spacer"></div>
      <div class="stage">
        <div class="name"
             [ngClass]="{completed: (stage.completedAt)}">
          {{stage.name}}
        </div>
        <button *ngIf="i !== 0"
                mat-raised-button
                color="{{stage.completedAt ? 'warn' : 'primary'}}"
                (click)="toggleCompletion(stage.key, stage.completedAt !== null)">
          {{stage.completedAt ? 'Reopen' : 'Validate'}}
        </button>
      </div>
    </div>
    <div *ngIf="showNames"
         class="text">
      <div *ngIf="stage.completedBy"
           class="stage-completed-by"
      >by {{stage.completedBy.name}}</div>
      <div *ngIf="stage.completedAt"
           class="stage-completed-at"
      >{{stage.completedAt | date :'short'}}</div>
    </div>
  </div>
</div>
