import {Component, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import { ColumnType, FlexibleColumnDef } from '../../model/flexible-columm-def';
import { FlexibleTableContent } from '../../model/flexible-table-content';
import { FlexibleRowDef } from '../../model/flexible-row-def';
import {MatSort, Sort} from '@angular/material/sort';

@Component({
  selector: 'nemo-flexible-table',
  encapsulation: ViewEncapsulation.None,
  template: `
    <mat-table
      *ngIf="content"
      [dataSource]="sortedData"
      matSort (matSortChange)="sortData($event)"
      class="table dense-2"
    >
      <ng-container *ngFor="let cd of columnDefinitions"
                    matColumnDef="{{cd.key}}"
      >
        <mat-header-cell *matHeaderCellDef
                         mat-sort-header
                         [ngStyle]="cd.style"
        >{{cd.header}}</mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [ngClass]="{routing:row[cd.key]['route']}"
          [ngStyle]="cd.style"
          [routerLink]="row[cd.key]['route']"
          [ngSwitch]="cd.type"
          class="flexible-cell"
        >
          <nemo-flexible-button-cell *ngSwitchCase="BUTTONS"
                                     [columnDefinition]="cd"
                                     [field]="row[cd.key]"
                                     [item]="row['_orig']"
          ></nemo-flexible-button-cell>
          <nemo-flexible-plain-cell *ngSwitchCase="PLAIN"
                                    [columnDefinition]="cd"
                                    [field]="row[cd.key]"
          ></nemo-flexible-plain-cell>
          <nemo-flexible-html-cell *ngSwitchCase="HTML"
                                   [columnDefinition]="cd"
                                   [field]="row[cd.key]"
          ></nemo-flexible-html-cell>
          <div *ngSwitchDefault>
            ERROR: cannot render flexible cell of type {{cd.type}}
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="content.displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: content.displayedColumns;"
        [ngClass]="row['_row']['clazz']"
      ></mat-row>
    </mat-table>`,
  styles: [`

      mat-header-cell {
          border-left: #F9E9E7 1px solid;
          padding: 0 6px !important;
      }

      mat-header-cell:first-of-type {
          border-left: none;
      }

      mat-cell {
          padding: 0 6px !important;
          border-left: #F9E9E7 1px solid;
          line-height: normal !important;
      }

      mat-row:hover {
          background: #e0e0e0;
      }

      mat-cell:first-of-type {
          border-left: none;
      }

      mat-cell.routing {
          cursor: pointer;
      }
  `]
})
export class FlexibleTableComponent<T> implements OnChanges {
  @Input()
  rowDefinition: FlexibleRowDef<T>;

  @Input()
  columnDefinitions: FlexibleColumnDef<T>[];

  @Input()
  values: T[];

  content: FlexibleTableContent<T>;

  sortedData: { [key: string]: any }[];

  @ViewChild(MatSort) sort: MatSort;

  BUTTONS = ColumnType.BUTTONS;
  PLAIN = ColumnType.PLAIN;
  HTML = ColumnType.HTML;

  ngOnChanges(changes: SimpleChanges): void {
    if (!(this.columnDefinitions && this.values)) {
      return;
    }
    this.content = new FlexibleTableContent(this.rowDefinition, this.columnDefinitions, this.values);
    this.sortedData = this.content.displayedValues.slice();
  }

  // customized sorting function. convert "any" type to string or number
  sortData(sort: Sort) {
    const data = this.sortedData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    // for debug only:
    // alert(JSON.stringify(this.content.displayedHeaderTypes));
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      // Fix the case when some of the values are null
      if (a[sort.active].display === null) { a[sort.active].display = ''; }
      if (b[sort.active].display === null) { b[sort.active].display = ''; }

      // Number and Data will be automatically sorted; For number, remove special characters in regular expression
      if (sort.active in this.content.displayedHeaderTypes && this.content.displayedHeaderTypes[sort.active] === 'Number') {
        const aNum: string = !a[sort.active].display || a[sort.active].display === '' ? '' : a[sort.active].display.replace(/[\s%]/g, '');
        const bNum: string = !b[sort.active].display || b[sort.active].display === '' ? '' : b[sort.active].display.replace(/[\s%]/g, '');
        return compare(+aNum, +bNum, isAsc);
      } else if (sort.active in this.content.displayedHeaderTypes && this.content.displayedHeaderTypes[sort.active] === 'Date')  {
        const aNum = new Date(a[sort.active].display).valueOf();
        const bNum = new Date(b[sort.active].display).valueOf();
        return compare(+aNum, +bNum, isAsc);
      } else {
        return compare((a[sort.active].display + ' ').toLowerCase(), (b[sort.active].display + ' ').toLowerCase(), isAsc);
      }
    });
  }

}

function compare<T extends number | string>(a: T, b: T, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
