import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { completeIndexAssignment } from '../../store/task.action';
import { Plate96 } from '../../../labware/models/plate-96';
import { PoolSamplePlaceholder } from '../../../bio/models/pool';

@Component({
  selector: 'nemo-index-assigner-title',
  template: `
    <div class="title">
      <h2>Index Assignment</h2>
      <button *ngIf="samplePlate"
              mat-raised-button
              color="primary"
              (click)="completeIndexAssignment()">
        Complete index assignment
      </button>
    </div>
  `,
  styles: [`
    .title {
      border-bottom: 1px solid #dadce0;
      height: 30px;
      margin: 15px 0 0 0;
      display: flex;
      justify-content: space-between;
    }

    .title h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #555;
      line-height: 24px;
      margin: 0;
    }

    .title button {
      margin: -9px 10px 3px 0;
    }
  `]
})
export class IndexAssignerTitleComponent {

  @Input() samplePlate: Plate96<PoolSamplePlaceholder>;

  constructor(private store: Store<AppState>) {
  }

  completeIndexAssignment() {
    this.store.dispatch(completeIndexAssignment({accessionCode: this.samplePlate.accessionCode}));
  }
}
