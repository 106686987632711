import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { clearErrorFromRouter } from '../../shared/nerrors/store';
import { map } from 'rxjs/operators';

@Injectable()
export class NavigationEffects {

  constructor(
    private actions$: Actions,
  ) {
  }

  
  navigatedToClearError$ = createEffect(() => { return this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    map(() => clearErrorFromRouter())
  ) });
}
