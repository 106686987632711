import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Form } from '../../models/form';
import { selectFormCurrent } from '../../store/form/form.selector';
import { actionFormSubmitLoadByTypeName } from '../../store/form';
import { ActivatedRoute } from '@angular/router';
import { FormRequestEditionDto } from '../../dtos/form-request.dto';
import { actionSubmitFormRequest } from '../../store/form-request';
import { Title } from '@angular/platform-browser';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'nemo-new-form-request',
  template: `
    <nemo-edit-form-request
      [form]="form$ | async"
      [startCollapsed]="true"
      [initialSectionKey]="initialSectionKey$ | async"
      (formSubmitted)="submit($event)"
      submitButtonText="Submit a new request"
    ></nemo-edit-form-request>
  `,
  styles: []
})
export class NewFormRequestComponent implements OnInit, OnDestroy {
  form$: Observable<Form>;
  paramSubscription: Subscription;
  initialSectionKey$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.paramSubscription = this.route.params.subscribe(params => {
      const typeName = params['typeName'];
      this.store.dispatch(actionFormSubmitLoadByTypeName({typeName}));
      this.titleService.setTitle(`Nemo - submit a ${typeName} Request`);
    });

    this.form$ = this.store.select(selectFormCurrent).pipe(
      
      filter((f) => Boolean(f)),
      take(1)
    );

    this.initialSectionKey$ = this.route.queryParams.pipe(
      map(queryParams => queryParams['section']),
      filter(section => section != null)
    );
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }

  submit(formRequestCreation: FormRequestEditionDto) {
    this.store.dispatch(actionSubmitFormRequest({formRequestEdition: formRequestCreation}));
  }
}
