import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { getUserFromId } from '../../store';

@Component({
  selector: 'nemo-user-summary',
  template: `
    <nemo-user-image [user]="user$ | async"></nemo-user-image>
    {{(user$ | async)?.name}}
  `,
  styles: [`
    :host {
      height: inherit;
      line-height: 48px;
      align-items: center;
      display: flex;
    }
  `
  ]
})
export class UserSummaryComponent implements OnInit {

  @Input() userId: string;
  user$: Observable<User>;

  constructor(
    private userService: UserService,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.user$ = this.store.select((getUserFromId(this.userId)));
  }
}
