import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';

import {
  findPlateByAccessionCodeThenSelectFromIA,
  removePlateFromIndexAssignment
} from '../../../../labware/store/plates';
import { EVENT_LISTENER_ID_INDEXES, EVENT_LISTENER_ID_SAMPLES } from '../../constants';
import { AppState } from '../../../../store/app.reducers';
import { Title } from '@angular/platform-browser';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';

@Component({
  selector: 'nemo-index-assigner-page',
  templateUrl: './index-assigner-page.component.html',
  styleUrls: ['./index-assigner-page.component.scss']
})
export class IndexAssignerPageComponent implements OnInit, OnDestroy {

  paramSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.paramSubscription = this.route.params.subscribe(params => {
      const plateAccessionCode = params['plateAccessionCode'];
      this.titleService.setTitle(`Nemo index assigment ${formatAccessionCode(plateAccessionCode)}`);

      this.store.dispatch(removePlateFromIndexAssignment({plateAccessionCode: EVENT_LISTENER_ID_SAMPLES}));
      this.store.dispatch(removePlateFromIndexAssignment({plateAccessionCode: EVENT_LISTENER_ID_INDEXES}));
      this.store.dispatch(findPlateByAccessionCodeThenSelectFromIA({
        accessionCode: plateAccessionCode,
        putPlateIdentifier: EVENT_LISTENER_ID_SAMPLES
      }));
    });
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }
}
