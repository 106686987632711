import { User } from '../models/user.model';
import { UserDTO } from '../models/user.dto';

export function dtoToUser(dto: UserDTO): User {
  return dto ? new User({
    id: dto.id,
    name: dto.name,
    email: dto.email,
    picture: dto.picture
  }) : null;
}
