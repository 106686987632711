import { Component, Input } from '@angular/core';
import { FlowCellMapping } from '../../models/flow-cell-mapping';

@Component({
  selector: 'nemo-lane-assignment-table',
  template: `
    <b>Lane & Pool Info</b>
    <table mat-table [dataSource]="_layout">
      <ng-container matColumnDef="lane">
        <th mat-header-cell *matHeaderCellDef>Lane</th>
        <td mat-cell *matCellDef="let mapping"> {{mapping.laneNumber + 1}} </td>
      </ng-container>
      <ng-container matColumnDef="lane_occupancy">
        <th mat-header-cell *matHeaderCellDef>Lane occupancy</th>
        <td mat-cell *matCellDef="let mapping">{{mapping.laneOccupancy * 100 | number:0}}%</td>
      </ng-container>
      <ng-container matColumnDef="pool_ac">
        <th mat-header-cell *matHeaderCellDef>Pool's AC</th>
        <td mat-cell *matCellDef="let mapping"
            class="accession-code">{{mapping.simplePoolWithRequest.accessionCode | accessionCode}}</td>
      </ng-container>
      <ng-container matColumnDef="pool_samples">
        <th mat-header-cell *matHeaderCellDef>Pool #samples</th>
        <td mat-cell *matCellDef="let mapping">{{mapping.simplePoolWithRequest.numberOfSamples}}</td>
      </ng-container>
      <ng-container matColumnDef="requests">
        <th mat-header-cell *matHeaderCellDef>Requests</th>
        <td mat-cell *matCellDef="let mapping">
          <nemo-request-accession-code-list
            [accessionCodes]="mapping.simplePoolWithRequest.requests | requestsAccessionCode"
            [withinTable]="true"></nemo-request-accession-code-list>
        </td>
      </ng-container>
      <ng-container matColumnDef="request-title">
        <th mat-header-cell *matHeaderCellDef>Request title</th>
        <td mat-cell *matCellDef="let mapping">
          <p>{{mapping.simplePoolWithRequest.requests | requestsTitles | joinWithComma}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="phix">
        <th mat-header-cell *matHeaderCellDef>PhiX</th>
        <td mat-cell *matCellDef="let mapping">
          <p> {{mapping.simplePoolWithRequest.requests | requestsPhix | joinWithComma}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="requester">
        <th mat-header-cell *matHeaderCellDef>Requester</th>
        <td mat-cell *matCellDef="let mapping">
          <nemo-user-summary *ngFor="let request of mapping.simplePoolWithRequest.requests"
                             [userId]="request.requesterId"></nemo-user-summary>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `,
  styles: [`
    table {
      width: 100%;
    }
  `]
})
export class LaneAssignmentTableComponent {

  _layout: Array<FlowCellMapping>;
  displayedColumns: string[] = [
    'lane',
    'lane_occupancy',
    'pool_ac',
    'pool_samples',
    'requests',
    'request-title',
    'phix',
    'requester'
  ];

  // To sort layout by lane number ASC
  @Input() set layout(value: Array<FlowCellMapping>) {
    if (value) {
      this._layout = value.slice().sort(((n1, n2) => n1.laneNumber - n2.laneNumber));
    }
  }
}
