import * as _ from 'lodash';

import { Plate96 } from '../../../labware/models/plate-96';
import { PlateCoordinates } from '../../../labware/models/plate-coordinates';
import { Index, IndexModel } from '../../../bio/models/barcode-index';
import { IndexToSampleLayout, OneIndexToSampleCoordinatesMapping } from '../models/index-to-sample-layout';
import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import { BioMaterialPlateMapping } from '../../../labware/models/bio-material-plate-mapping';
import {
  buildErrorValidationStatus,
  buildOkValidationStatus,
  buildWarningValidationStatus,
  ValidationError,
  ValidationErrorType,
  ValidationStatus,
  ValidationStatusType
} from '../models/validation';
import { PoolSamplePlaceholder } from '../../../bio/models/pool';

interface IndexAlreadyUsedValidation {
  isDuplicate: boolean;
  index?: IndexModel;
  at?: PlateCoordinates;
}

/**
 * For one sample plate, assign indexes.
 * The index plate can change over time, but not the sample at this stage
 *
 * The goal is to trigger a index to Sample assignment
 *
 * Warning: This class is mutable, as it holds a temporary state
 */
export class SamplePlateIndexMapping {
  originalIndexPlate: Plate96<Index>;

  // shadow plates handles what would be the target plates after the assignment
  // to be sure we do not pick twice or empty index/samples
  // at first, they are copy of the original index/sample plate, but they will evolve, while original remain untouched
  shadowIndexPlate: Plate96<Index>;
  shadowSamplePlate: Plate96<PoolSamplePlaceholder>;

  layout: IndexToSampleLayout = new IndexToSampleLayout();

  constructor(
    readonly originalSamplePlate: Plate96<PoolSamplePlaceholder>,
  ) {
    this.shadowSamplePlate = this.originalSamplePlate.clone();
  }

  setIndexPlate(plate: Plate96<Index>) {
    this.originalIndexPlate = plate;
    this.shadowIndexPlate = this.originalIndexPlate.clone();
  }

  /**
   * Return the selection of all coordinates on the plate that have a sample without an index
   */
  public unassignedSamplePlateSelection(): PlateCoordinatesSelection {
    const coords = this.shadowSamplePlate.getNonEmptyBioMaterialMappings()
      .filter((bmm) => !bmm.biomaterial.sample.isIndexed())
      .map((bmm) => bmm.plateCoordinates);
    return new PlateCoordinatesSelection(this.shadowSamplePlate.dimensions)
      .fromCoordinatesList(coords);
  }

  /**
   * From two selections of same length, assign indexes to samples.
   * The two selections must be of same length and each pair of index/sample must be ok for the validator
   */
  public addIndexToSampleMappingFromSelectionOrThrow(
    indexPlateSelection: PlateCoordinatesSelection,
    samplePlateSelection: PlateCoordinatesSelection,
  ): void {
    const validation = this.validateIndexToSampleMappingFromSelection(indexPlateSelection, samplePlateSelection);
    if (validation.status === ValidationStatusType.ERROR) {
      throw Error(validation.error.message);
    }

    // assign the indexes to the samples
    // we simply pair index and sample selections one by one and call the atomic assignment
    _.chain(indexPlateSelection.listSelectedCoordinates())
      .zip(samplePlateSelection.listSelectedCoordinates())
      .each((pair) => {
          const indexCoords = pair[0];
          const sampleCoords = pair[1];
          this.addOneIndexToSampleMappingOrThrow(indexCoords, sampleCoords);
        },
      )
      .value();
  }

  /**
   * Assign an index to a sample and throw if validation is not correct.
   */
  public addOneIndexToSampleMappingOrThrow(
    indexPlateCoordinates: PlateCoordinates,
    samplePlateCoordinates: PlateCoordinates,
  ): void {
    // check we can do it
    const validation = this.validateAddOneIndexToSampleMapping(indexPlateCoordinates, samplePlateCoordinates);
    if (validation.status === ValidationStatusType.ERROR) {
      throw Error(validation.error.message);
    }
    // add mapping to layout
    this.layout.add(new OneIndexToSampleCoordinatesMapping(indexPlateCoordinates, samplePlateCoordinates));

    // read data from original plates
    const samplePool = this.originalSamplePlate.getBioMaterialAt(samplePlateCoordinates);
    const sample = samplePool.sample;
    const index = this.originalIndexPlate.getBioMaterialAt(indexPlateCoordinates);

    // update shadow
    const indexedSample = sample.assignIndex(index);
    this.shadowSamplePlate = this.shadowSamplePlate.setBioMaterialAt(samplePool.setSample(indexedSample), samplePlateCoordinates);
    this.shadowIndexPlate = this.shadowIndexPlate.emptyAt(indexPlateCoordinates);
  }

  /**
   * Validator at the whole selection level, typically before the assignment is done in order to prevent it
   * or warn the user.
   * Check if the number of selected indexes corresponds to the size of the samples selection
   * It also triggers a validation for each pair (index, sample) of the assignment.
   */
  public validateIndexToSampleMappingFromSelection(
    indexPlateSelection: PlateCoordinatesSelection,
    samplePlateSelection: PlateCoordinatesSelection,
  ): ValidationStatus {

    if (!(indexPlateSelection && samplePlateSelection)) {
      return buildOkValidationStatus();
    }
    const nbIndex = indexPlateSelection.countSelected();
    const nbSample = samplePlateSelection.countSelected();
    if (nbIndex !== nbSample) {
      return buildErrorValidationStatus(
        new ValidationError(ValidationErrorType.INCOHERENT_INDEX_SAMPLE_SELECTION_SIZE,
          `Index selection size (${nbIndex}) is different from sample (${nbSample})`,
        ));
    }
    const invalid = _.zip(indexPlateSelection.listSelectedCoordinates(), samplePlateSelection.listSelectedCoordinates())
      .map((p) => this.validateAddOneIndexToSampleMapping(p[0], p[1]))
      .find((vs) => !vs.isValid);
    return invalid || buildOkValidationStatus();
  }

  /**
   * Check if the same index has already been used (e.g. from another index kit).
   * It takes one coordinate on an index kit, from a selection that we are trying to assign.
   * Return {
   *  `isDuplicate`,
   *  `at`: the coordinates of the duplicate on the samples plate,
   *  `index`: the model of the duplicate index,
   * }.
   */
  public validateIndexAlreadyUsed(indexPlateCoordinates: PlateCoordinates)
    : IndexAlreadyUsedValidation {
    // the index we are trying to assign
    const indexAtCoordinate = this.shadowIndexPlate.getBioMaterialAt(indexPlateCoordinates);
    const samplePools = this.shadowSamplePlate.getNonEmptyBioMaterialMappings();
    // the indexes already assigned on the whole samples plate
    const indexOnSamples = samplePools
      .filter((bmm) => bmm.biomaterial.sample.isIndexed())
      .map((bmm) => bmm.biomaterial.sample.assignedIndex);
    const sampleIdx = indexOnSamples.findIndex(index => index.model.id === indexAtCoordinate.model.id);
    if (sampleIdx >= 0) {
      const samplePool: BioMaterialPlateMapping<PoolSamplePlaceholder> = samplePools[sampleIdx];
      return {
        isDuplicate: true,
        at: samplePool.plateCoordinates,
        index: indexAtCoordinate.model,
      };
    }
    return {
      isDuplicate: false,
      at: null,
      index: null,
    };
  }

  /**
   * Validator at the single coordinate level.
   * For a given pair of coordinates (index,sample) to assign, check edge cases such as
   * - the selected position on the sample plate is empty
   * - the selected position on the index plate is empty
   * - an assignment to a sample that is already indexed
   * - an assignment of an index that has already been used
   */
  public validateAddOneIndexToSampleMapping(
    indexPlateCoordinates: PlateCoordinates,
    samplePlateCoordinates: PlateCoordinates
  ): ValidationStatus {
    // check if index well is empty
    if (this.shadowIndexPlate.isEmptyAt(indexPlateCoordinates)) {
      const errorMessage = `Index plate is empty at ${indexPlateCoordinates.toString()}`;
      const error = new ValidationError(ValidationErrorType.INDEX_PLATE_IS_EMPTY_AT_COORDINATES, errorMessage);
      return buildErrorValidationStatus(error);
    }

    // check if sample well is empty
    if (this.shadowSamplePlate.isEmptyAt(samplePlateCoordinates)) {
      const errorMessage = `Sample plate is empty at ${samplePlateCoordinates.toString()}`;
      const error = new ValidationError(ValidationErrorType.SAMPLE_PLATE_IS_EMPTY_AT_COORDINATES, errorMessage);
      return buildErrorValidationStatus(error);
    }

    const candidateSampleWithCoordinates = this.shadowSamplePlate.getBioMaterialMappingAt(samplePlateCoordinates);
    if (candidateSampleWithCoordinates.biomaterial.sample.isIndexed()) {
      return buildErrorValidationStatus(new ValidationError(
        ValidationErrorType.SAMPLE_PLATE_IS_ALREADY_INDEXED_AT_COORDINATES,
        `Sample is already indexed on plate at ${candidateSampleWithCoordinates.plateCoordinates.toString()}`,
      ));
    }

    const validateIndexAlreadyUsed = this.validateIndexAlreadyUsed(indexPlateCoordinates);
    if (validateIndexAlreadyUsed.isDuplicate) {
      const position: PlateCoordinates = validateIndexAlreadyUsed.at;
      return buildWarningValidationStatus(new ValidationError(
        ValidationErrorType.DUPLICATE_INDEX,
        `Warning: index ${validateIndexAlreadyUsed.index.name} was already used at ${position.toString()}`,
      ));
    }

    return buildOkValidationStatus();
  }
}
