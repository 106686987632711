import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { formatDate } from '@angular/common';

import { FormRequestStage, FormRequestSummary, SampleCount } from '../../models/form-request';
import {
  FlexibleButtonDef,
  FlexibleButtonsColumnDef,
  FlexibleColumnDef,
  FlexibleHtmlColumnDef,
  FlexiblePlainColumnDef
} from '../../../table/model/flexible-columm-def';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { archiveFormRequestFromList, restoreFormRequestFromList } from '../../store/form-request';
import { AppState } from '../../../store/app.reducers';
import { FlexibleRowDef } from '../../../table/model/flexible-row-def';

const renderStagesProgressBar = (stages: FormRequestStage[]) => stages.map(stage => {
  let style = 'float:left;width:1em;height:0.5em; margin-right:1px;';
  if (stage.completedBy) {
    style += 'background:mediumseagreen;';
  } else {
    style += 'background:lightgrey;';
  }
  return `<div style="${style}">&nbsp</div>`;
}).join('');

const regexpSkipStageList = /^(NEW|ACCEPTED|COMPLETED)$/;
const renderStagesList = (stages: FormRequestStage[], sampleCount: SampleCount) =>
  '<div style="display:flex; flex-direction:row; font-size: 70%;">'
  + stages
    .filter(stage => !stage.key.match(regexpSkipStageList))
    .map(stage => {
      let style = 'padding-left: 0.3em; padding-right: 0.3em; padding-top: 0.1em; padding-bottom: 0.1em;' +
        ' border-radius:3px; margin-right:5px; text-align:center;';
      if (stage.completedBy) {
        style += 'background:mediumseagreen; color:white';
      } else {
        style += 'background:lightgrey; color:black';
      }
      let text = stage.name;
      if (sampleCount.bySection[stage.key]) {
        text += ` (${sampleCount.bySection[stage.key]})`;
      }
      return `<div style="${style}">${text}</div>`;
    }).join('')
  + '</div>';

const renderStages = (stages: FormRequestStage[], sampleCount: SampleCount) => `
<div style="display:flex; flex-direction:column">
  <div style="margin-bottom:5px;">${renderStagesProgressBar(stages)}</div>
  <div>${renderStagesList(stages, sampleCount)}</div>
</div>
`;


@Component({
  selector: 'nemo-form-request-list',
  template: `
    <nemo-archive-filter *ngIf="enableArchive" tableId="form-request"></nemo-archive-filter>
    <nemo-flexible-table
      *ngIf="formRequests"
      [values]="formRequests"
      [columnDefinitions]="columnDefinitions"
      [rowDefinition]="rowDefinition"
    ></nemo-flexible-table>
  `,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
    }

    nemo-archive-filter {
      align-self: end;
      margin-right: 48px;
    }

    ::ng-deep mat-row.completed {
      background: rgba(64, 178, 115, 0.2);
    }

    ::ng-deep mat-row.archived {
      background: rgba(220, 220, 220, 0.3);
    }
  `]
})
export class FormRequestListComponent implements OnInit {

  @Input() formRequests: FormRequestSummary[];
  @Input() enableArchive;
  @Input() showAssignees;

  rowDefinition = new FlexibleRowDef<FormRequestSummary>(
    (req) => {
      let clazz = [];
      if (this.isRequestCompleted(req)) {
        clazz = ['completed'];
      }

      if (req.archived) {
        clazz = ['archived'];
      }

      return clazz;
    }
  );

  columnDefinitions: FlexibleColumnDef<FormRequestSummary>[] = [
    new FlexiblePlainColumnDef<FormRequestSummary>(
      'accessionCode',
      'AC',
      () => true,
      (m) => formatAccessionCode(m.accessionCode),
      {'flex': '0 0 100px', 'font-size': '95%'},
      (m) => `/form-request/${m.accessionCode}`,
      ['accession-code']
    ),
    new FlexiblePlainColumnDef<FormRequestSummary>(
      'title',
      'Title',
      () => true,
      (m) => m.title,
      null,
      (m) => `/form-request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<FormRequestSummary>(
      'study',
      'Study',
      (m) => m.study != null,
      (m) => m.study.name,
      {'flex': '0 0 120px', 'font-size': '95%'},
      (m) => `/form-request/${m.accessionCode}`
    ),
    new FlexibleHtmlColumnDef<FormRequestSummary>(
      'stages-progress',
      'Stages',
      () => true,
      (req) => renderStages(req.stages, req.sampleCount),
      null,
      (m) => `/form-request/${m.accessionCode}`,
      ['progress-bar']
    ),
    new FlexiblePlainColumnDef<FormRequestSummary>(
      'sample-count',
      '#samples',
      () => true,
      (req) => `${req.sampleCount.total}`,
      {'flex': '0 0 50px'},
      (m) => `/form-request/${m.accessionCode}`,
      ['sample-count']
    ),
    new FlexiblePlainColumnDef<FormRequestSummary>(
      'createdAt',
      'Created at',
      () => true,
      (m) => formatDate(m.createdAt, 'short', 'en-US'),
      {'flex': '0 0 120px', 'font-size': '95%'},
      (m) => `/form-request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<FormRequestSummary>(
      'requester',
      'Requester',
      (m) => m.requester != null,
      (m) => m.requester.name,
      {'flex': '0 0 140px', 'font-size': '95%'},
      (m) => `/form-request/${m.accessionCode}`
    ),
    new FlexiblePlainColumnDef<FormRequestSummary>(
      'assignees',
      'Assigned to',
      (m) => this.showAssignees && m.assignedTo.length > 0,
      (m) => m.assignedTo.map(user => user.name).join(', '),
      {'flex': '0 0 140px', 'font-size': '95%'},
      (m) => `/form-request/${m.accessionCode}`
    )
  ];

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    if (this.enableArchive) {
      this.columnDefinitions.push(
        new FlexibleButtonsColumnDef<FormRequestSummary>(
          'archive',
          'Archive',
          () => true,
          () => '',
          {'flex': '0 0 100px'},
          [
            new FlexibleButtonDef<FormRequestSummary>(
              (m) => m.archived ? 'Reopen' : 'Archive',
              (m) => m.archived ? '' : 'primary',
              () => true,
              (m) => this.toggleArchive(m)
            )
          ]
        )
      );
    }
  }

  toggleArchive(request: FormRequestSummary): void {
    if (request.archived) {
      this.store.dispatch(restoreFormRequestFromList({accessionCode: request.accessionCode}));
    } else {
      this.store.dispatch(archiveFormRequestFromList({accessionCode: request.accessionCode}));
    }
  }

  isRequestCompleted(request: FormRequestSummary): boolean {
    return Boolean(request.stages.find(stage => stage.key === 'COMPLETED' && stage.completedBy));
  }
}
