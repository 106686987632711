import { Pipe, PipeTransform } from '@angular/core';
import { Sample } from '../models/sample';
import { Index, IndexSequence, TerminusTypes } from '../models/barcode-index';

export function formatIndexSequence(sample: Sample, termini: TerminusTypes): string {
  const index: Index = sample.assignedIndex;
  if (!index) {
    return '';
  }
  return index.terminiSequences(termini).join(';');
}

@Pipe({
  name: 'samplesSequences'
})
export class SamplesSequencesPipe implements PipeTransform {

  transform(sample: Sample, termini: TerminusTypes): any {
    return formatIndexSequence(sample, termini);
  }
}
