<table mat-table [dataSource]="flowCells">
  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef><span>Flow cell</span></th>
    <td mat-cell *matCellDef="let flowCell"
        routerLink="/flow-cell/{{flowCell.flowCellId}}">
      <div class="icon-container">
        <nemo-flowcell-viewer [flowCell]="flowCell" isReadonly="true" class="is-icon"
                              isIcon="true"></nemo-flowcell-viewer>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="flowCellId">
    <th mat-header-cell *matHeaderCellDef><span>flow cell id</span></th>
    <td mat-cell *matCellDef="let flowCell"
        routerLink="/flow-cell/{{flowCell.flowCellId}}"><span
      class="accession-code">{{flowCell.flowCellId}}</span></td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef><span>created</span></th>
    <td mat-cell *matCellDef="let flowCell"><span>{{flowCell.createdAt | date : "short"}}</span></td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="table-header"><span>Status</span></th>
    <td mat-cell *matCellDef="let flowCell" class="table-line"><span>{{flowCell.status}}</span></td>
  </ng-container>

  <ng-container matColumnDef="archive">
    <th mat-header-cell *matHeaderCellDef class="table-header">
      <nemo-archive-filter tableId="flow-cell"></nemo-archive-filter>
    </th>
    <td mat-cell *matCellDef="let flowCell" class="table-line">
      <nemo-switch-button [archived]="flowCell.archived"
                          (event)="onSwitchButtonClick(flowCell.flowCellId, $event)"></nemo-switch-button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headers"></tr>
  <tr mat-row *matRowDef="let row; columns: headers;"
      class="table-row"></tr>
</table>
