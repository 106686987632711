import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { selectCompanyAllStudies } from '../../../store';
import { Study } from '../../../models/Study';
import { archiveStudy, restoreStudy } from '../../../store/study.actions';

@Component({
  selector: 'nemo-study-list',
  template: `
    <div class="container">
      <table
        mat-table
        [dataSource]="studies$ | async"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef> Created by</th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy.name}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Archive</th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <button
              mat-mini-fab
              *ngIf="!element.archived"
              color="warn"
              (click)="archive(element)"
              matTooltip="Archive the study"
            >
              <mat-icon>archive</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="element.archived"
              color="primary"
              (click)="restore(element)"
              matTooltip="Restore the study"
            >
              <mat-icon>unarchive</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{archived:row.archived}"
        ></tr>
      </table>
    </div>
  `,
  styles: [`
    table {
      width: 100%;
    }

    tr.archived td.mat-mdc-cell {
      color: lightgrey !important;
    }
  `]
})
export class StudyListComponent implements OnInit {
  studies$: Observable<Study[]>;

  displayedColumns = ['name', 'createdBy', 'action'];

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.studies$ = this.store.
      select((selectCompanyAllStudies)
    );
  }

  archive(study: Study) {
    this.store.dispatch(archiveStudy({study}));
  }

  restore(study: Study) {
    this.store.dispatch(restoreStudy({study}));
  }

}
