import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlowCellMapping } from '../../../labware/models/flow-cell-mapping';
import { changeLaneConcentrationAndOccupancy } from '../../../labware/store/flow-cells';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-edit-occupancy-concentration-dialog',
  template: `
    <h1 mat-dialog-title>Edit concentration and occupancy on lane {{data.laneNumber + 1}}</h1>
    <div mat-dialog-content>
      <form [formGroup]="form" class="text-center">
        <mat-form-field appearance="fill" floatLabel="auto" class="full-width">
          <input matInput type="number"
                 id="concentration"
                 formControlName="concentration"
                 class="align-end"
                 max="3.0" min="0.5" step="0.01">
          <span matPrefix>
              <strong>concentration</strong>
            </span>
          <span matSuffix>nM</span>
        </mat-form-field>
        <div *ngFor="let mapping of data.layoutForLane">
          <mat-form-field appearance="fill" floatLabel="never" class="full-width">
            <input matInput type="number"
                   id="occupancy-{{mapping.simplePoolWithRequest.accessionCode}}"
                   placeholder="occupancy-{{mapping.simplePoolWithRequest.accessionCode}}"
                   [formControlName]="mapping.simplePoolWithRequest.accessionCode"
                   class="align-end"
                   max="100" min="0" step="5">
            <span matPrefix class="accession-code">
              <strong>{{mapping.simplePoolWithRequest.accessionCode | accessionCode}}</strong>
            </span>
            <span matSuffix>%</span>
          </mat-form-field>
        </div>
      </form>
      <mat-error>{{errorMessage}}</mat-error>
    </div>
    <div mat-dialog-actions class="right-align">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" (click)="changeConcentrationAndOccupancy()"
              [disabled]="!this.form.valid">Valid
      </button>
    </div>
  `
})
export class EditOccupancyConcentrationDialogComponent implements OnInit {

  form: UntypedFormGroup;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditOccupancyConcentrationDialogComponent>
  ) {
  }

  ngOnInit() {
    this.errorMessage = '';
    this.form = this.buildForm(this.data.layoutForLane, this.data.concentration);
    console.log(this.data);
  }

  buildForm(layout: Array<FlowCellMapping>, concentration: number): UntypedFormGroup {
    const fb = this.fb;

    const controlsConfig = {};
    layout.forEach(mapping =>
      controlsConfig[mapping.simplePoolWithRequest.accessionCode] = fb.control(
        Math.round(mapping.laneOccupancy * 100),
        [Validators.required]
      )
    );
    controlsConfig['concentration'] = fb.control(
      concentration > 0 ? concentration : null,
      [Validators.required, Validators.min(0.50), Validators.max(3.00)]
    );

    return fb.group(controlsConfig);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeConcentrationAndOccupancy() {
    const concentration = this.form.get('concentration').value;
    if (concentration < 0.5 || concentration > 3) {
      this.errorMessage = 'The concentration must be between 0.50 and 3.00';
      return;
    }
    let totalOccupancy = 0;
    this.data.layoutForLane.map(mapping => {
      totalOccupancy += this.form.get(mapping.simplePoolWithRequest.accessionCode).value;
    });
    if (totalOccupancy > 100) {
      this.errorMessage = 'The total occupancy is higher than 100%';
      return;
    } else {
      const layout = this.data.layoutForLane.map(mapping =>
        mapping.setLaneOccupancy(this.form.get(mapping.simplePoolWithRequest.accessionCode).value / 100)
      );
      this.store.dispatch(changeLaneConcentrationAndOccupancy({
        laneNumber: this.data.laneNumber,
        concentration,
        layout
      }));
      this.dialogRef.close();
    }
  }

}
