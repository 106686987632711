import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import PoolState from './pool.reducer';

export const getPoolState = (state: AppState) => state.pool;

export const getPools = createSelector(getPoolState, (state: PoolState) => state.pools);
export const getPoolCounts = createSelector(getPoolState, (state: PoolState) => state.poolCounts);
export const getPoolColorScale = createSelector(getPoolState, (state: PoolState) => state.colorScale);
export const isPoolStorePending = createSelector(getPoolState, (state: PoolState) => state.pending);
export const getCurrentPool = createSelector(getPoolState, (state: PoolState) => state.currentPool);

export const getPoolColor = (poolAccessionCode: string) =>
  createSelector(getPoolState, (state: PoolState) => state.colorScale && state.colorScale.get(poolAccessionCode));
