<div *ngIf="request" class="line" [ngClass]="{names: showNames}">
  <div *ngFor="let stage of request.stages; let i=index; let first=first"
       class="container">
    <div class="stage-container">
      <div *ngIf="!first" class="spacer"></div>
      <div class="stage">
        <div class="name" [ngClass]="{completed: (stage.completedAt)}">
          {{stage.name}}
        </div>
        <ng-container *ngIf="!first">
          <button *ngIf="stage.completedAt else notCompleted"
                  mat-raised-button color="warn"
                  (click)="reopenStage(stage.key)">
            Reopen
          </button>
          <ng-template #notCompleted>
            <button mat-raised-button color="primary"
                    [disabled]="!request.stages[i-1].completedAt"
                    (click)="validateStage(stage.key)">
              Validate
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <div *ngIf="showNames" class="text">
      <p *ngIf="stage.completedBy" class="stage-completed-by">by {{stage.completedBy.name}}</p>
      <p *ngIf="stage.completedAt" class="stage-completed-at">{{stage.completedAt | date :'short'}}</p>
    </div>
  </div>
</div>
