import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { SamplePlateIndexMapping } from '../task-input/sample-plate-index-mapping';
import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import { Plate96 } from '../../../labware/models/plate-96';
import { Index } from '../../../bio/models/barcode-index';
import { assignIndexes } from '../../store/task.action';
import { PoolSamplePlaceholder } from '../../../bio/models/pool';

@Component({
  selector: 'nemo-index-assignment-button',
  template: `
    <button mat-raised-button
            color="primary"
            [disabled]="!canAssign"
            (click)="saveIndexAssignment()">
      <mat-icon>arrow_backward</mat-icon>
      Assign
    </button>

    <br/>
    <br/>

    <mat-hint [ngClass]="infoMessageStyle"
              *ngIf="infoMessage">
      {{infoMessage}}
    </mat-hint>
  `
})
export class IndexAssignmentButtonComponent {

  @Input() samplePlate: Plate96<PoolSamplePlaceholder>;
  @Input() indexPlate?: Plate96<Index>;
  @Input() currentSelectionSamples?: PlateCoordinatesSelection;
  @Input() currentSelectionIndexes?: PlateCoordinatesSelection;
  @Input() canAssign: boolean;
  @Input() samplePlateIndexMapping?: SamplePlateIndexMapping;
  @Input() infoMessage: string;
  @Input() infoMessageStyle: string;

  constructor(private store: Store<AppState>) {
  }

  /**
   * Take the indexes out of the index plate and trigger events to add them to the samples plate
   */
  saveIndexAssignment() {
    if (!this.samplePlateIndexMapping) {
      return;
    }
    // register the transition index -> samples
    this.samplePlateIndexMapping.addIndexToSampleMappingFromSelectionOrThrow(
      this.currentSelectionIndexes,
      this.currentSelectionSamples,
    );

    const indexAssignments = this.samplePlateIndexMapping.layout.mappings.map(mapping => {
      const sample = this.samplePlate.getBioMaterialAt(mapping.samplePlateCoordinates).sample;
      const index = this.indexPlate.getBioMaterialAt(mapping.indexPlateCoordinates);
      return {sampleAccessionCode: sample.accessionCode, indexId: index.id};
    });

    this.store.dispatch(assignIndexes({
      indexAssignments,
      accessionCode: this.samplePlate.accessionCode,
      indexKitId: this.indexPlate.id
    }));
  }
}
