import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {getPathFromAccessionCode} from '../../global-context/services/global-context.service';

@Injectable({ providedIn: 'root' })
export class QuickAccessResolver  {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> | string {
    const accessionCode = state.url.substring(1);
    return getPathFromAccessionCode(accessionCode);
  }

}
