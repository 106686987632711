import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProjectService } from '../services/project.service';
import {
  archiveProject,
  createProject,
  findAllProjects,
  findAllProjectsAfterUpdate,
  findAllProjectsError,
  findAllProjectsSuccess, restoreProject
} from './project.actions';
import { Project } from '../models/Project';

@Injectable()
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    private projectService: ProjectService
  ) {
  }

  findAll$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllProjects.type,
      findAllProjectsAfterUpdate.type
    ),
    mergeMap(() => this.projectService.findAll()),
    map(projects => findAllProjectsSuccess({projects})),
    catchError(error => of(findAllProjectsError({message: error.message})))) }
  );

  createProject$ = createEffect(() => { return this.actions$.pipe(
    ofType(createProject.type),
    mergeMap((action: ReturnType<typeof createProject>) =>
      this.projectService.create({
        code: action.code,
        name: action.name
      } as Project)
    ),
    map(() => findAllProjectsAfterUpdate())) }
  );

  archiveProject$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveProject.type),
    mergeMap((action: ReturnType<typeof archiveProject>) =>
      this.projectService.archive(action.project)
    ),
    map(() => findAllProjectsAfterUpdate())) }
  );

  restoreProject$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreProject.type),
    mergeMap((action: ReturnType<typeof restoreProject>) =>
      this.projectService.restore(action.project)
    ),
    map(() => findAllProjectsAfterUpdate())) }
  );
}
