import { Component, Input } from '@angular/core';
import { FlowCell } from '../../../models/flow-cell';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { archiveFlowCell, unarchiveFlowCell } from '../../../store/flow-cells';

@Component({
  selector: 'nemo-flow-cell-table',
  templateUrl: './flow-cell-table.component.html',
  styleUrls: ['./flow-cell-table.component.scss']
})
export class FlowCellTableComponent {

  headers: string[] = ['icon', 'flowCellId', 'createdAt', 'status', 'archive'];

  @Input() flowCells: FlowCell[];

  constructor(private store: Store<AppState>) {
  }

  onSwitchButtonClick(flowCellId: string, archive: boolean) {
    if (archive) {
      this.store.dispatch(archiveFlowCell({flowCellId}));
    } else {
      this.store.dispatch(unarchiveFlowCell({flowCellId}));
    }
  }
}
