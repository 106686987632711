import { Component, Input, OnInit } from '@angular/core';
import { Sample } from '../../bio/models/sample';
import { TerminusTypes } from '../../bio/models/barcode-index';
import * as _ from 'lodash';

@Component({
  selector: 'nemo-request-sample-index-hint',
  template: `
    <div>
      <span>i7: <mat-icon *ngIf="hint?.hasI7">verified</mat-icon></span>
      <span>i5: <mat-icon *ngIf="hint?.hasI5">verified</mat-icon></span>
    </div>
    <div *ngIf="hint?.hasOtherIndexColumns"><mat-icon>warning</mat-icon> unknown extra index, check below</div>
  `,
  styles: [`
    div {
      display: flex;
    }
    div span {
      display: flex;
      align-items: center;
    }
  `]
})
export class RequestSampleIndexHintComponent implements OnInit {

  @Input() samples: Sample[];
  hint: IndexHint;

  ngOnInit() {
    this.hint = this.samples.reduce((sampleAcc: IndexHint, sample) => {
      const hasI7 = sampleAcc.hasI7 || sample.hasIndexOn(TerminusTypes.I7);
      const hasI5 = sampleAcc.hasI5 || sample.hasIndexOn(TerminusTypes.I5);
      // disable extra index column check per Twaritha Vijay's request
      // const hasOtherIndexColumns = _.keys(sample.metadata).reduce(
      //  (keyAcc: boolean, key: string) => keyAcc || key.toLowerCase().indexOf('index') >= 0, false
      // );
      const hasOtherIndexColumns = false;
      return {
        hasI7,
        hasI5,
        hasOtherIndexColumns
      } as IndexHint;
    }, {
      hasI7: false,
      hasI5: false,
      hasOtherIndexColumns: false
    } as IndexHint);
  }
}

interface IndexHint {
  hasI7: boolean;
  hasI5: boolean;
  hasOtherIndexColumns: boolean;
}
