import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import {
  actionChangeLab,
  actionChangeUserRole,
  actionLoadContextAtInit,
  actionSetEntityPage,
  actionSetEntityPageToNull
} from './global-context.actions';
import { getEntityPageFromUrl, GlobalContextService } from '../services/global-context.service';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { UserRole } from '../models/user-role';
import { Lab } from '../../company/models/Lab';
import { selectCompanyAllLabs } from '../../company/store';

@Injectable()
export class GlobalContextEffects {

  constructor(
    private globalContextService: GlobalContextService,
    private actions$: Actions,
    private store$: Store<AppState>,
  ) {
  }

  
  routeNavigated = createEffect(() => { return this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    map((action: RouterNavigationAction) => {
      const entityPage = getEntityPageFromUrl(action.payload.routerState.url);
      return entityPage ? actionSetEntityPage({entityPage}) : actionSetEntityPageToNull();
    })
  ) });

  init$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionLoadContextAtInit.type),
    concatLatestFrom(() => this.store$.
      select((selectCompanyAllLabs)
    )),
    mergeMap(([, labs]) => {
      const cookie = this.globalContextService.get();
      const cookieLab: Lab = labs.find(l => l.alias === cookie.lab);

      const actions = [];
      if (cookie.userRole) {
        actions.push(actionChangeUserRole({role: cookie.userRole as UserRole}));
      }
      if (cookieLab) {
        actions.push(actionChangeLab({lab: cookieLab}));
      }
      return actions;
    })
    ) }
  );

  cookieSaveUserRole$ =
    createEffect(() => { return this.actions$.pipe(
      ofType(actionChangeUserRole.type),
      tap((action: ReturnType<typeof actionChangeUserRole>) => this.globalContextService.setUserRole(action.role))) },
      {dispatch: false}
    );
  cookieSaveLab$ =
    createEffect(() => { return this.actions$.pipe(
      ofType(actionChangeLab.type),
      tap((action: ReturnType<typeof actionChangeLab>) => this.globalContextService.setLab(action.lab))) },
      {dispatch: false}
    );
}
