import { Component, Input, OnInit } from '@angular/core';
import { Question } from '../../models/form';
import { FormGroup } from '@angular/forms';
import { formatAminoAcidSequence } from '../form/form-question-sequence.component';

@Component({
  selector: 'nemo-submitted-form-question-sequence',
  template: `
    <div class="answer">
      <pre>{{displayedSequence}}</pre>
    </div>

  `,
  styles: []
})

export class SubmittedFormQuestionSequenceComponent implements OnInit {
  @Input()
  question: Question;

  @Input()
  value: { [key: string]: string };

  displayedSequence: string;

  ngOnInit(): void {
    this.displayedSequence = formatAminoAcidSequence(this.value && this.value['sequence']);
  }


}
