import { Component } from '@angular/core';

@Component({
  selector: 'nemo-root',
  template: `
      <nemo-header-bar></nemo-header-bar>

      <div class="app-container">
          <nemo-left-navbar></nemo-left-navbar>
          <div class="center-panel">
              <router-outlet></router-outlet>
          </div>
          <nemo-comment-container></nemo-comment-container>
      </div>

      <nemo-error-flash></nemo-error-flash>
  `,
  styles: [
    `
              .app-container {
                  display: flex;
                  flex-direction: row;
                  height: calc(100vh - 63px);
              }

              .center-panel {
                  flex-grow: 1;
                  overflow-y: auto;
              }
        `
  ]
})
export class AppComponent {
  title = 'Nemo';
}
