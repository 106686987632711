import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SimplePlateWithRequests } from '../../labware/models/simple-plate';
import {  Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { getPlateList } from '../../labware/store/plates';
import { TaskAvailable } from '../../tasks/models/task.model';
import { platesFindAllFromTodoPage } from '../../labware/store/plates/actions/plate-list.action';

@Component({
  selector: 'nemo-todo-echo-pooling',
  template: `
    <nemo-pending-title title="Plates ready for echo pooling"></nemo-pending-title>
    <nemo-plate-list *ngIf="(plates$ | async)?.length > 0" [plates]="plates$ | async"></nemo-plate-list>
  `
})
export class TodoEchoPoolingComponent implements OnInit {

  plates$: Observable<SimplePlateWithRequests[]>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.findAll();
    this.plates$ = this.store.select((getPlateList(TaskAvailable.ECHO_POOLING_FROM)));
  }

  findAll() {
    this.store.dispatch(platesFindAllFromTodoPage({availableTask: TaskAvailable.ECHO_POOLING_FROM}));
  }
}
