import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Page } from '../../shared/models/page.model';
import { toPageInfo } from '../../shared/services/page.service';
import { QcApiService } from '../services/qc-api.service';
import {
  countPlateQCError,
  countPlateQCFromTodoPage,
  countPlateQCSuccess,
  findAllPlateQC,
  findAllPlateQCError,
  findAllPlateQCFromTodoPage,
  findAllPlateQCSuccess,
  findPlateQCByAccessionCodeError,
  findPlateQCByAccessionCodeFromInstrumentDirectoryPage,
  findPlateQCByAccessionCodeSuccess
} from './actions/qc-api.actions';
import {
  decrementPlateQCTodoCount,
  dismissPlateQCError,
  dismissPlateQCFromList,
  dismissPlateQCFromPage,
  incrementPlateQCTodoCount,
  recoverplateQCError,
  recoverPlateQCFromList,
  recoverPlateQCFromPage,
  reimportPlateQCFromPage,
  updatePlateQCInListSuccess,
  updatePlateQCSuccess
} from './actions/qc-tasks.action';
import { QcTasksService } from '../services/qc-tasks.service';
import { PlateQC, PlateQCSummary } from '../models/plate-qc-measures';
import { plateQCToSummary } from '../services/qc-plate.mapper';

@Injectable()
export class QcEffects {

  constructor(
    private actions$: Actions,
    private qcApiService: QcApiService,
    private qcTasksService: QcTasksService,
  ) {
  }

  
  findAllQcResults = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllPlateQC.type,
      findAllPlateQCFromTodoPage.type
    ),
    mergeMap((action: ReturnType<typeof findAllPlateQC>) =>
      this.qcApiService.findAllPlateQC(action.pageSize, action.page, action.availableTask).pipe(
        map((page: Page<PlateQCSummary>) =>
          findAllPlateQCSuccess({plateQCs: page.content, pageInfo: toPageInfo(page)})
        ),
        catchError(() => of(findAllPlateQCError()))
      )
    ),
  ) });

  
  findInstrumentDirectoryByAccessionCode = createEffect(() => { return this.actions$.pipe(
    ofType(findPlateQCByAccessionCodeFromInstrumentDirectoryPage.type),
    mergeMap((action: ReturnType<typeof findPlateQCByAccessionCodeFromInstrumentDirectoryPage>) =>
      this.qcApiService.findPlateQCByAccessionCode(action.accessionCode)
        .pipe(
          map((plateQC: PlateQC) =>
              findPlateQCByAccessionCodeSuccess({plateQC: plateQC}),
            catchError(error => {
                const message = (error.message) ?
                  error.message :
                  'Impossible to get plate QC by accession code ' + action.accessionCode;
                return of(findPlateQCByAccessionCodeError({message}));
              }
            )
          )
        )
    )
  ) });

  
  count = createEffect(() => { return this.actions$.pipe(
    ofType(countPlateQCFromTodoPage.type),
    mergeMap((action: ReturnType<| typeof countPlateQCFromTodoPage>) =>
      this.qcApiService.count(action.availableTask).pipe(
        map(qcCounts => countPlateQCSuccess({qcCounts, availableTask: action.availableTask})),
        catchError(() => of(countPlateQCError()))
      )
    )
  ) });

  
  dismissPlateQCFromList$ = createEffect(() => { return this.actions$.pipe(
    ofType(dismissPlateQCFromList.type),
    mergeMap((action: ReturnType<typeof dismissPlateQCFromList>) =>
      this.qcTasksService.dismissPlateQC(action.accessionCode)
    ),
    mergeMap((plateQC: PlateQC) => [
        updatePlateQCInListSuccess({plateQC: plateQCToSummary(plateQC)}),
        decrementPlateQCTodoCount()
      ]
    ),
    catchError(error => {
      const message = (error.message) ?
        error.message :
        'Impossible to dismiss instrument directory';
      return of(dismissPlateQCError({message}));
    })
  ) });

  
  reimportPlateQCFromPage$ = createEffect(() => { return this.actions$.pipe(
    ofType(reimportPlateQCFromPage.type),
    mergeMap((action: ReturnType<typeof reimportPlateQCFromPage>) =>
      this.qcTasksService.reimportPlateQC(action.accessionCode)
    ),
    map((plateQC: PlateQC) =>
      updatePlateQCSuccess({plateQC})
    )
  ) });

  
  dismissPlateQCFromPage$ = createEffect(() => { return this.actions$.pipe(
    ofType(dismissPlateQCFromPage.type),
    mergeMap((action: ReturnType<typeof dismissPlateQCFromPage>) =>
      this.qcTasksService.dismissPlateQC(action.accessionCode)
    ),
    mergeMap((plateQC: PlateQC) => [
        updatePlateQCSuccess({plateQC}),
        decrementPlateQCTodoCount()
      ]
    ),
    catchError(error => {
      const message = (error.message) ?
        error.message :
        'Impossible to dismiss instrument directory';
      return of(dismissPlateQCError({message}));
    })
  ) });

  
  recoverPlateQCFromList$ = createEffect(() => { return this.actions$.pipe(
    ofType(recoverPlateQCFromList.type),
    mergeMap((action: ReturnType<typeof recoverPlateQCFromList>) =>
      this.qcTasksService.recoverPlateQC(action.accessionCode)
    ),
    mergeMap((plateQC: PlateQC) => [
        updatePlateQCInListSuccess({plateQC: plateQCToSummary(plateQC)}),
        incrementPlateQCTodoCount()
      ]
    ),
    catchError(error => {
      const message = (error.message) ?
        error.message :
        'Impossible to recover instrument directory';
      return of(recoverplateQCError({message}));
    })
  ) });

  
  recoverPlateQCFromPage$ = createEffect(() => { return this.actions$.pipe(
    ofType(recoverPlateQCFromPage.type),
    mergeMap((action: ReturnType<typeof recoverPlateQCFromPage>) =>
      this.qcTasksService.recoverPlateQC(action.accessionCode)
    ),
    mergeMap((plateQC: PlateQC) => [
        updatePlateQCSuccess({plateQC}),
        incrementPlateQCTodoCount()
      ]
    ),
    catchError(error => {
      const message = (error.message) ?
        error.message :
        'Impossible to recover instrument directory';
      return of(recoverplateQCError({message}));
    })
  ) });
}
