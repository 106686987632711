import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { getCurrentPool, isPoolStorePending } from '../../store';
import { Observable, Subscription } from 'rxjs';
import { PoolWithRequest } from '../../models/pool';
import { findPoolByAccessionCode } from '../../store/actions';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { FlexibleColumnDef } from '../../../table/model/flexible-columm-def';
import { Sample } from '../../models/sample';
import {
  poolSampleTableFlexibleColumnsDefinition
} from '../../shared/components/pool-sample-table/pool-sample-table-flexible-columns-definition';

@Component({
  selector: 'nemo-pool-page',
  template: `
    <nemo-pool-page-title [accessionCode]="accessionCode"
                          [pending]="pending$ | async"
                          [pool]="pool$ | async"></nemo-pool-page-title>

    <div class="container">
      <nemo-pool-info *ngIf="pool$ | async"
                      [pool]="pool$ | async"></nemo-pool-info>
      <nemo-pool *ngIf="pool$ | async"
                 [poolAccessionCode]="(pool$ | async)?.accessionCode"
                 [poolNumberOfSamples]="pool$ | async | poolSize"
                 [isIcon]="true"
                 height="150px"
                 width="100%"></nemo-pool>
    </div>

    <nemo-flexible-table *ngIf="pool$ | async"
                         [values]="(pool$ | async)?.samples"
                         [columnDefinitions]="columnDefinitions"></nemo-flexible-table>
  `,
  styles: [`
    .container {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }
  `]
})
export class PoolPageComponent implements OnInit, OnDestroy {

  pending$: Observable<boolean>;
  pool$: Observable<PoolWithRequest>;
  accessionCode: string;
  paramSubscription: Subscription;
  columnDefinitions: FlexibleColumnDef<Sample>[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.pending$ = this.store.select(isPoolStorePending);
    this.pool$ = this.store.select(getCurrentPool);

    this.paramSubscription = this.route.params.subscribe(params => {
      this.accessionCode = params['poolAccessionCode'];
      this.titleService.setTitle('Nemo pool ' + formatAccessionCode(this.accessionCode));
      this.store.dispatch(findPoolByAccessionCode({accessionCode: this.accessionCode}));
    });

    this.columnDefinitions = poolSampleTableFlexibleColumnsDefinition;
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }
}
