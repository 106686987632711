<div class="container pool-drag-boundary" cdkDropListGroup>

  <!-- these are some basics to get you started -- modify as you see fit. -->
  <div class="flow-cell-container">
    <nemo-flow-cell-selected-container [showFlowCellId]="false"
                                       [editSettings]="true"
                                       settingsTooltip="Edit lane occupancy / concentration"
                                       (settingsEdited)="editOccupancy($event)"
                                       id="flow-cell-drop"></nemo-flow-cell-selected-container>
  </div>

  <div class="pool-list">
    <nemo-pool-list [draggingEnabled]="true"
                    [cdkDragEnteredCallback]="entered"
                    [cdkDragExitedCallback]="exited"
                    [displayedColumns]="['icon', 'accessionCode', 'size', 'requests']"></nemo-pool-list>
  </div>
</div>
