import { Component, Input } from '@angular/core';
import { FlowCell } from '../../../labware/models/flow-cell';
import { MatDialog } from '@angular/material/dialog';
import { EditGlobalConcentrationDialogComponent } from './edit-global-concentration-dialog.component';

@Component({
  selector: 'nemo-lane-assigner-header',
  template: `
    <div class="description">
      Assign pools to lanes by dragging and dropping pool tubes.
      <br/>
      Do not forget to save your assignments to have them recorded.
    </div>
    <button mat-flat-button
            color="primary"
            (click)="setGlobalConcentration()">
      Set concentration for all lanes
    </button>
    <nemo-edit-flow-cell-type *ngIf="flowCell.sequencerModel.flowCellTypes.length"
                              [flowCell]="flowCell"
    ></nemo-edit-flow-cell-type>
    <nemo-edit-flow-cell-loading-type *ngIf="flowCell.sequencerModel.loadingTypes.length"
                                      [flowCell]="flowCell"
    ></nemo-edit-flow-cell-loading-type>
    <nemo-lane-assigner-save-button
      [flowCell]="flowCell"
    ></nemo-lane-assigner-save-button>
  `,
  styles: [`
    :host {
      display: flex;
      align-items: flex-start;
    }

    div.description {
      font-style: italic;
      margin-bottom: 1em;
    }

    button, nemo-edit-flow-cell-type, nemo-edit-flow-cell-loading-type {
      margin-left: 2em;
    }
  `]
})
export class LaneAssignerHeaderComponent {

  @Input() flowCell: FlowCell;

  constructor(
    private dialog: MatDialog
  ) {
  }

  setGlobalConcentration() {
    let globalConcentration = this.flowCell.concentrations[0];
    for (let i = 1; globalConcentration > 0 && i < this.flowCell.concentrations.length; i++) {
      if (this.flowCell.concentrations[i] !== globalConcentration) {
        globalConcentration = 0;
      }
    }
    this.dialog.open(EditGlobalConcentrationDialogComponent, {
      width: '500px',
      data: {concentration: globalConcentration}
    });
  }

}
