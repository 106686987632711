<div class="title-line">
  <div class="accession-code">{{this.plateAccessionCode | accessionCode}}</div>
  <nemo-pending [pending]="pending$ | async"></nemo-pending>
  <nemo-plate-export-button [plate]="plate$ | async"></nemo-plate-export-button>
</div>

<div class="container">
  <div class="plate-container">
    <nemo-plate-viewer *ngIf="plate$ | async"
                       [plate]="plate$ | async"
                       [isReadonly]="true"
                       [colorStringFn]="colorStringFn"
                       [showLegend]="true"
    ></nemo-plate-viewer>
  </div>

  <div class="task-container">
    <h3>Available for tasks</h3>
    <nemo-plate-tasks-buttons [plateAccessionCode]="plateAccessionCode"
                              [availableForTasks]="(plate$ | async)?.availableForTasks"></nemo-plate-tasks-buttons>
  </div>
</div>

<nemo-request-index-assignment-table [plate]="plate$ | async"></nemo-request-index-assignment-table>
