<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="submit-form">
  <nemo-request-submission-form-info [formGroup]="formGroup"/>

  <ng-container *ngIf="!!selectedLibraryMethod">

    <nemo-request-submission-form-file-upload [formGroup]="sampleGroup"
                                              [isPristine]="isPristine"
                                              (sampleFileChange)="sampleFileChange($event)"/>

    <button id="submit-request-button"
            type="submit"
            mat-raised-button
            [disabled]="!formGroup.valid"
            color="primary">
      Submit request
    </button>
  </ng-container>

  <p>All fields with * are required</p>
</form>
