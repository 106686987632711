import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { completePlateQCAssignment, validatePlateQCAssignment } from '../../../store/task.action';
import { InvalidPlateQCInstrumentDirectoryError } from '../../models/invalid-plate-qc-instrument-directory-error';
import { PlateQC } from '../../../../qc/models/plate-qc-measures';
import { PlateSnippet } from '../../../../labware/dto/labware.dto';

@Component({
  selector: 'nemo-plate-qc-assigner-page-ui',
  template: `
    <h1>Assign a plate to the QC file</h1>
    <div class="target-plate">
      <div class="target-plate-column">

        <nemo-select-plate-snippet
          [platesSnippets$]="platesSnippets$"
          (changeSelectedPlate)="updateSelectedPlate($event)"
          inputPlaceHolder="Select target plate"></nemo-select-plate-snippet>
      </div>
      <div class="target-plate-column assignment-status">
        <nemo-tasks-plate-qc-assignment-error
          *ngIf="invalidAssignment"
          [error]="invalidAssignment"
        ></nemo-tasks-plate-qc-assignment-error>
        <div class="assign-button"
             *ngIf="selectedPlateAccessionCode && !invalidAssignment"
        >
          <button
            matTooltip="Assign QC measures to sample plate {{selectedPlateAccessionCode}}"
            mat-raised-button
            color="primary"
            (click)="completeAssignment()"
          >Assign QC measures to {{selectedPlateAccessionCode | accessionCode}}</button>
        </div>
      </div>


    </div>
    <hr/>
    <div *ngIf="plateQC">

      <div class="title">
        <span class="accession-code">{{plateQC.accessionCode|accessionCode}}</span>
        Plate QC ({{plateQC.instrumentFile.fileType}})
      </div>
      <div class="path">{{plateQC.instrumentFile.path}}</div>
    </div>

    <nemo-plate-qc
      *ngIf="plateQC"
      [plateQC]="plateQC"
    ></nemo-plate-qc>
  `,
  styles: [`
    div.title {
      font-size: 1.2rem;
      font-weight: bold;
    }

    div.target-plate {
      display: flex;
      flex-direction: row;
      width: 100%
    }

    div.target-plate-column {
      flex-grow: 1;
    }

    div.assignment-status {
      max-width: 50%;
      margin-left: 2em;
    }

    div.assign-button {
      text-align: center;
    }
  `]
})
export class PlateQcAssignerPageUIComponent {

  @Input()
  plateQC: PlateQC;
  @Input()
  platesSnippets$: Observable<PlateSnippet[]>;
  @Input()
  invalidAssignment: InvalidPlateQCInstrumentDirectoryError;
  @Input()
  selectedPlateAccessionCode: string;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  updateSelectedPlate(accessionCode: string) {
    this.store.dispatch(validatePlateQCAssignment({
      plateAccessionCode: accessionCode,
      instrumentDirectoryAccessionCode: this.plateQC.accessionCode
    }));
  }

  completeAssignment() {
    this.store.dispatch(completePlateQCAssignment({
      plateAccessionCode: this.selectedPlateAccessionCode,
      instrumentDirectoryAccessionCode: this.plateQC.accessionCode
    }));
  }
}
