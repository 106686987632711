import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import { QuestionMultiChoice } from '../../models/form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export const FORM_QUESTION_MULTI_CHOICE_FREE_TEXT_OPTION = 'FORM_QUESTION_MULTI_CHOICE_FREE_TEXT_OPTION';

export function getChoicesAndFreeTextValues(
  question: QuestionMultiChoice,
  initialValue: string | string[]
) {
  if (!question.required && !initialValue) {
    return {
      disabled: true,
      freeText: null,
      choices: null
    };
  }

  if (question.multiple) {
    const freeTextInitial = _.difference(initialValue, _.map(question.choices, 'value'))[0];
    if (freeTextInitial) {
      return {
        disabled: false,
        freeText: freeTextInitial,
        choices: [...initialValue]
      };
    }
    return {
      disabled: false,
      freeText: null,
      choices: initialValue
    };
  }


  // so we have only one radio button.
  // it can be within the proposed choices or free text
  const initVal = initialValue as string;
  if (_.find(question.choices, (c) => c.value === initVal)) {
    return {
      disabled: false,
      freeText: null,
      choices: initVal
    };
  }
  return {
    disabled: false,
    freeText: initVal,
    choices: initVal && FORM_QUESTION_MULTI_CHOICE_FREE_TEXT_OPTION
  };

}

@Component({
  selector: 'nemo-form-question-multichoice',
  templateUrl: 'form-question-multi-choice.component.html',
  styleUrls: [
    './form-question.component.scss',
    './form-question-multi-choice.component.scss'
  ]
})
export class FormQuestionMultiChoiceComponent implements OnInit {
  @Input()
  question: QuestionMultiChoice;

  @Input()
  sectionFormGroup: UntypedFormGroup;

  @ViewChild('freeTextInput') freeTextInput: ElementRef;

  isEnabled = true;

  controlChoice: UntypedFormControl;
  controlFreeText: UntypedFormControl;

  value: string;
  multipleFreeText: string;

  ngOnInit(): void {
    this.controlChoice = new UntypedFormControl('');

    if (this.question.freeText) {
      this.controlFreeText = new UntypedFormControl('',
        Validators.maxLength(this.question.maxLength));
    }

    this.setInitialValue(this.sectionFormGroup.get(this.question.key).value);
  }

  setInitialValue(initialValue: string | string[]) {
    const initControlValues = getChoicesAndFreeTextValues(this.question, initialValue);

    if (initControlValues.disabled) {
      this.disableQuestion({init: true});
      return;
    }
    if (initControlValues.freeText) {
      this.controlFreeText.setValue(initControlValues.freeText);
      if (this.question.multiple) {
        this.multipleFreeText = initControlValues.freeText;
      }
    }
    this.controlChoice.setValue(initControlValues.choices);
  }

  private setValue(v: string) {
    this.value = v;
    this.sectionFormGroup.controls[this.question.key].setValue(v);
  }

  changeChoice(evt) {
    if (evt === null || evt === FORM_QUESTION_MULTI_CHOICE_FREE_TEXT_OPTION) {
      return;
    }
    this.setValue(evt);
  }

  freeTextButtonClick() {
    setTimeout(() => this.freeTextInput.nativeElement.focus(), 0);
  }

  changeFreeTextRadio(evt) {
    if (evt === null) {
      return;
    }
    this.controlChoice.setValue(evt ? FORM_QUESTION_MULTI_CHOICE_FREE_TEXT_OPTION : null);
    this.setValue(evt);
  }

  changeFreeTextMultiple(evt) {
    if (evt === null) {
      return;
    }
    this.multipleFreeText = evt.trim() || null;
  }

  enableQuestion() {
    this.sectionFormGroup.controls[this.question.key].enable();
    this.isEnabled = true;
  }

  disableQuestion(opts = {}) {
    opts = {init: false, ...opts};
    this.sectionFormGroup.controls[this.question.key].disable({emitEvent: !opts['init']});
    this.isEnabled = false;
  }

  toggleChange(evt: MatSlideToggleChange) {
    if (evt.checked) {
      this.enableQuestion();
    } else {
      this.disableQuestion();
    }
  }
}
