import { Component, Input, OnInit } from '@angular/core';
import { Question, QuestionMultiChoice, QuestionSampleNameOption } from '../../models/form';
import { FormGroup } from '@angular/forms';
import { SamplenameOption } from '../../models/samplename-option';

@Component({
  selector: 'nemo-submitted-form-question-samplename-list',
  template: `
    <div class="answer">
      {{sampleNames}}
    </div>
  `,
  styles: [`
           `]
})

export class SubmittedFormQuestionSamplenameListComponent implements OnInit {
  @Input()
  question: QuestionSampleNameOption;

  @Input()
  value: string[];

  sampleNames: string;

  ngOnInit(): void {
    this.sampleNames = (this.value || []).join(', ');
  }


}
