import { Component, Input, ViewChild } from '@angular/core';
import { FlowCellMapping } from '../../models/flow-cell-mapping';
import { CsvWriterService } from '../../../core/services/csv-writer.service';
import { MatSelect } from '@angular/material/select';
import { SimpleRequest } from '../../../genomics-request/models/genomics-request';
import { getAllRequestsFromFlowCellLayout } from '../../services/flow-cell.service';
import { FlowCellExportService } from '../../services/flow-cell-export.service';

@Component({
  selector: 'nemo-lane-assignment-export-button',
  template: `
    <mat-form-field>
      <mat-label>Export assignment to CSV</mat-label>
      <mat-select #selectRequester>
        <mat-option (click)="exportToCsv(null)">All requests</mat-option>
        <mat-option *ngFor="let request of this.getAllRequests()"
                    (click)="exportToCsv(request.accessionCode)">{{request.accessionCode| accessionCode}}</mat-option>
      </mat-select>
    </mat-form-field>
  `
})
export class LaneAssignmentExportButtonComponent {

  @ViewChild('selectRequester', {static: true}) selectRequester: MatSelect;
  @Input() flowCellId: string;
  @Input() layout: Array<FlowCellMapping>;

  constructor(
    private readonly csvWriter: CsvWriterService,
    private readonly exportService: FlowCellExportService
  ) {
  }

  getAllRequests(): SimpleRequest[] {
    return getAllRequestsFromFlowCellLayout(this.layout);
  }

  exportToCsv(requestAccessionCode: string) {
    this.selectRequester.value = null;
    const filename = FlowCellExportService.getFileName(this.flowCellId, requestAccessionCode);
    const data = this.exportService.formatData(this.layout, requestAccessionCode);
    this.csvWriter.downloadAsCsv({data}, filename);
  }
}
