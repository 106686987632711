import { Injectable } from '@angular/core';
import { LibraryMethod, SubmissionType } from '../../sequencing/models/library-method';
import {
  AbstractControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class GenomicFormRequestService {

  titleMaxLength = 80;

  phiXPercentageMin = 0.0;
  phiXPercentageMax = 100.0;

  param10xMin = 0;
  param10xMax = 999;

  descriptionMaxLength = 5000;

  buidlBasicFormRequestControls(): any {
    return {
      title: new UntypedFormControl('', [Validators.required, Validators.maxLength(this.titleMaxLength)]),
      departmentCode: new UntypedFormControl(null, [Validators.required]),
      projectCode: new UntypedFormControl(null, [Validators.required]),
      studyId: new UntypedFormControl(null, []),
      collaborator: new UntypedFormControl(null, [Validators.required]),
      libraryMethod: new UntypedFormControl(null, [Validators.required]),
      phiXPercentage: new UntypedFormControl(0, [Validators.min(this.phiXPercentageMin), Validators.max(this.phiXPercentageMax)]),
      description: new UntypedFormControl('', [Validators.maxLength(this.descriptionMaxLength)])
    };
  }

  buildParam10xFormControls(): any {
    return {
      param10xRead1: new UntypedFormControl('150',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xIndex1: new UntypedFormControl('8',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xIndex2: new UntypedFormControl('8',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xRead2: new UntypedFormControl('150',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)])
    };
  }
}