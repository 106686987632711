import * as _ from 'lodash';
import { FlexibleColumnDef, FlexiblePlainColumnDef } from '../../../../table/model/flexible-columm-def';
import { TerminusTypes } from '../../../../bio/models/barcode-index';
import { BioMaterialPlateMapping } from '../../../models/bio-material-plate-mapping';
import { Pool } from '../../../../bio/models/pool';
import {
  formatBioMaterialAssignedIndex,
  formatBioMaterialAssignedIndexI5,
  formatBioMaterialAssignedIndexI7,
  formatBioMaterialAssignedIndexKit,
  formatBioMaterialAssignedIndexPosition,
  formatBioMaterialCoordinates,
  formatBioMaterialNames,
  formatBioMaterialVolUl,
  formatBioMaterialRequestedReads,
  formatSampleRequestAccessionCodes,
  NonEmptyBioMaterialMappingsPipe
} from '../../pipe/get-occupancy.pipe';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';
import { Plate96 } from '../../../models/plate-96';
import { BioMaterial } from '../../../../bio/models/bio-material';
import { FragmentAnalyzerQCMeasure } from '../../../../qc/models/fragment-analyzer-qc-measure';

function hasIndexModel(mapping: BioMaterialPlateMapping<Pool>): boolean {
  return mapping.biomaterial
    .sampleList()
    .filter(s => s.isIndexed())
    .map(s => s.assignedIndex)
    .filter(index => index.model)
    .length !== 0;
}

function hasIndexSequencesI5(mapping: BioMaterialPlateMapping<Pool>): boolean {
  return mapping.biomaterial
    .sampleList()
    .filter(s => s.isIndexed())
    .map(s => s.assignedIndex)
    .some(index => index.sequences.some(seq => seq.terminus === TerminusTypes.I5));
}

function hasIndexSequencesI7(mapping: BioMaterialPlateMapping<Pool>): boolean {
  return mapping.biomaterial
    .sampleList()
    .filter(s => s.isIndexed())
    .map(s => s.assignedIndex)
    .some(index => index.sequences.some(seq => seq.terminus === TerminusTypes.I7));
}

export const plateTableFlexibleColumnsDefinition = [
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'request_accession_code',
    'Request',
    () => true,
    (mapping) => formatSampleRequestAccessionCodes(mapping),
    {'flex': '0 0 120px'},
    (mapping) => {
      const accessionCodes = mapping.biomaterial.listRequestAccessionCode();
      return accessionCodes.length === 1 ? `/request/${accessionCodes[0]}` : null;
    }
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'sample_name',
    'Sample name',
    () => true,
    (mapping) => formatBioMaterialNames(mapping),
    {'flex': '0 0 180px'}
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'vol_ul',
    'Vol(uL)',
    () => true,
    (mapping) => formatBioMaterialVolUl(mapping),
    {'flex': '0 0 80px'}
  ),

  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'requested_reads',
    'Requested_reads(Million)',
    () => true,
    (mapping) => formatBioMaterialRequestedReads(mapping),
    {'flex': '0 0 140px'}
  ),

  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'sample_accession_code',
    'Pool AC',
    () => true,
    (mapping) => formatAccessionCode(mapping.biomaterial.accessionCode),
    {'flex': '0 0 240px'}
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'sample_position',
    'Well',
    () => true,
    (mapping) => formatBioMaterialCoordinates(mapping),
    {'flex': '0 0 40px'}
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'index_kit',
    'Index barcode',
    hasIndexModel,
    (mapping) => formatBioMaterialAssignedIndexKit(mapping)
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>('index',
    'Index name',
    hasIndexModel,
    (mapping) => formatBioMaterialAssignedIndex(mapping),
    {'flex': '0 0 140px'}
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'index_coordinate',
    'Index well',
    hasIndexModel,
    (mapping) => formatBioMaterialAssignedIndexPosition(mapping)
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'i5',
    'I5',
    hasIndexSequencesI5,
    (mapping) => formatBioMaterialAssignedIndexI5(mapping)
  ),
  new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
    'i7',
    'I7',
    hasIndexSequencesI7,
    (mapping) => formatBioMaterialAssignedIndexI7(mapping)
  )
];

export function additionalQcDataFlexibleColumnDefinitions(
  plate: Plate96<BioMaterial>
): FlexibleColumnDef<BioMaterialPlateMapping<Pool>>[] {
  const bioMaterialPipe = new NonEmptyBioMaterialMappingsPipe();
  const nonEmptyMappings = bioMaterialPipe.transform(plate);
  const qcMeasures = _.chain(nonEmptyMappings)
    .filter((x: BioMaterialPlateMapping<Pool>) =>
      x.biomaterial != null && x.biomaterial.fragmentAnalyzerQCMeasure != null
    )
    .map(x => x.biomaterial.fragmentAnalyzerQCMeasure)
    .value();

  return qcDataColumnOrder(qcMeasures).map((key: string) =>
    new FlexiblePlainColumnDef<BioMaterialPlateMapping<Pool>>(
      `qc_${key}`,
      key,
      () => true,
      (x) => {
        const value = x.biomaterial.fragmentAnalyzerQCMeasure.data[key];
        return Array.isArray(value) ? (value.join('\n')) : value;
      },
      null,
      null,
      ['qc-values']
    )
  );
}

export function isQCValueNumberS(value: any): boolean {
  if (Array.isArray(value)) {
    return !value.find(x => !isQCValueNumberS(x));
  }
  if (value === '' || value === null || value === undefined) {
    return false;
  }
  return !isNaN(Number(value));
}

export function formatQCValue(value: any): string | string[] {
  if (Array.isArray(value)) {
    return value.map(_formatQCValue);
  }
  return _formatQCValue(value);
}

function _formatQCValue(value: any): string {
  if (value === '' || value === null || value === undefined) {
    return '';
  }
  const number = Number(value);
  if (isNaN(number)) {
    return value.toString();
  }
  if (number === Math.round(number)) {
    return '' + number;
  }
  if (number < 0.01) {
    return number.toExponential(1);
  }
  const str = number.toFixed(2);
  return str.replace(/(.*\..*?)0*$/, '\$1');
}

export function qcDataColumnOrder(qcMeasures: FragmentAnalyzerQCMeasure[]): string[] {
  if (qcMeasures.length === 0) {
    return [];
  }
  return _.chain(qcMeasures[0].data)
    .map((v, k) => ({key: k, value: v}))
    .sortBy([
      ({value}) => isQCValueNumberS(value) ? 1 : 0,
      'key'
    ])
    .map('key')
    .value();
}
