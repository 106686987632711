<h1 mat-dialog-title>Add a plate</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field
      class="full-width"
      appearance="fill"
      floatLabel="never"
    >
      <input matInput
             #title
             type="text"
             formControlName="title"
             placeholder="title"

      />
      <mat-hint align="start">a short description</mat-hint>
      <mat-hint align="end">{{title.value.length}} / 80</mat-hint>
    </mat-form-field>

    <mat-form-field class="select-dimensions" appearance="outline">
      <mat-select formControlName="dimensions"
                  placeholder="Select the type of flow cell">
        <mat-option *ngFor="let dim of availableDimensions" [value]="dim">{{dim.numberOfRows}}
          x {{dim.numberOfColumns}}</mat-option>
      </mat-select>
      <mat-error align="start">You need to select a type of flow cell.</mat-error>
    </mat-form-field>
  </form>

</div>
<div mat-dialog-actions class="right-align">
  <button mat-button (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="addPlate()" [disabled]="!this.form.valid">Add</button>
</div>
