import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateFlowCellDto } from '../../../models/flow-cell.dto';
import { FlowCell } from '../../../models/flow-cell';
import { updateOneFlowCell } from '../../../store/flow-cells';
import { FlowCellApiService } from '../../../services/flow-cell-api.service';
import { Observable } from 'rxjs';
import { SequencerModel } from '../../../../sequencing/models/sequencer-model';
import { selectActiveSequencerModels } from '../../../../sequencing/store';

@Component({
  selector: 'nemo-add-flow-cell-dialog',
  templateUrl: './add-flow-cell-dialog.component.html',
  styleUrls: ['./add-flow-cell-dialog.component.scss']
})
export class AddFlowCellDialogComponent implements OnInit {

  sequencerModels$: Observable<SequencerModel[]>;

  errorMessage = '';
  form: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<AddFlowCellDialogComponent>,
    private flowCellApiService: FlowCellApiService
  ) {
  }

  ngOnInit() {
    this.errorMessage = '';
    this.form = this.buildForm();
    this.sequencerModels$ = this.store.
      select((selectActiveSequencerModels)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  buildForm(): UntypedFormGroup {
    const fb = this.fb;

    return fb.group({
      sequencerModel: fb.control('',
        [Validators.required],
      ),
      flowCellId: fb.control('',
        [Validators.required],
      ),
      lanesNumber: fb.control('',
        [Validators.required],
      ),
    });
  }

  addFlowCell() {
    if (this.form.valid) {
      const createFlowCell = {
        sequencerModelId: this.form.get('sequencerModel').value.id,
        numberOfLanes: this.form.get('lanesNumber').value,
        flowCellId: this.form.get('flowCellId').value.trim()
      } as CreateFlowCellDto;

      this.flowCellApiService.addFlowCell(createFlowCell)
        .subscribe(
          (flowCell: FlowCell) => {
            this.store.dispatch(updateOneFlowCell({flowCell}));
            this.dialogRef.close();
          },
          error => {
            this.errorMessage = error.message || 'Impossible to create a flow cell';
          });
    }
  }
}
