import { Component, Input, OnInit } from '@angular/core';
import { QuestionMultiChoice, QuestionText } from '../../models/form';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'nemo-form-question-text',
  template: `
    <h4>{{question.title}}
      <span
        *ngIf="!question.required"
        class="optional-tag"
      > - optional</span>
    </h4>
    <div
      *ngIf="question.description"
      class="description"
    >{{question.description}}</div>
    <div
      [formGroup]="sectionFormGroup"
    >
      <mat-form-field
        *ngIf="question['multiLine']"
      >
          <textarea
            matInput
            #text
            [formControlName]="question.key"
            [matTextareaAutosize]="true"
            rows="5"
          ></textarea>
        <mat-hint
          align="start"
        >{{question.help}}
        </mat-hint>
        <mat-hint
          *ngIf="question.maxLength > 0"
          align="end"
        >{{text.value.length}} / {{question.maxLength}}</mat-hint>
      </mat-form-field>
      <mat-form-field
        *ngIf="!question['multiLine']"
      >
        <input
          matInput
          #text
          [formControlName]="question.key"
        >
        <mat-hint
          align="start"
        >{{question.help}}
        </mat-hint>
        <mat-hint
          *ngIf="question.maxLength > 0"
          align="end"
        >{{text.value.length}} / {{question.maxLength}}</mat-hint>
      </mat-form-field>
    </div>
  `,
  styleUrls: [
    './form-question.component.scss',
    './form-question-text.component.scss'
  ]
})
export class FormQuestionTextComponent {
  @Input()
  question: QuestionText;

  @Input()
  sectionFormGroup: UntypedFormGroup;
}
