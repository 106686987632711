import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'trustHtml'
})
export class TrustHtml implements PipeTransform {
  constructor(
    private domSanitizer: DomSanitizer
  ) {
  }

  transform(value: any): SafeHtml {
    if (value) {
      return this.domSanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
