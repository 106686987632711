import { Component, Input, OnInit } from '@angular/core';
import { QuestionMultiChoice, QuestionSequence, QuestionText } from '../../models/form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export function formatAminoAcidSequence(
  seq: string,
  chunkLength = 10,
  nbChunkPerLine = 6
): string {

  if (!seq) {
    return '';
  }
  const cleanSeq = seq.replace(/\s+/g, '');
  let formattedSeq = '';
  for (let i = 0; i < cleanSeq.length; i += chunkLength) {
    let sep = (i % (chunkLength * nbChunkPerLine) === 0) ? '\n' : ' ';
    if (i === 0) {
      sep = '';
    }
    formattedSeq += sep + cleanSeq.substr(i, chunkLength);
  }
  return formattedSeq;
}

@Component({
  selector: 'nemo-form-question-sequence',
  template: `
    <h4>{{question.title}}
      <span
        *ngIf="!question.required"
        class="optional-tag"
      > - optional</span>
    </h4>
    <div
      *ngIf="question.description"
      class="description"
    >{{question.description}}</div>
    <mat-form-field
    >
          <textarea
            matInput
            #sequence
            [formControl]="controlSequence"
            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"
            rows="10"
            (change)="setValue()"
            placeholder="Paste your sequence here"
          ></textarea>
    </mat-form-field>
  `,
  styleUrls: [
    './form-question.component.scss',
    './form-question-sequence.component.scss'
  ]
})
export class FormQuestionSequenceComponent implements OnInit {
  @Input()
  question: QuestionSequence;

  @Input()
  sectionFormGroup: UntypedFormGroup;

  controlSequence: UntypedFormControl;

  ngOnInit(): void {
    this.controlSequence = new UntypedFormControl('', [Validators.required]);

    this.setInitialValue(this.sectionFormGroup.get(this.question.key).value);

  }

  setInitialValue(aaSequence: { [key: string]: string }) {
    const { sequence} = { sequence: '', ...aaSequence};
    this.controlSequence.setValue(formatAminoAcidSequence(sequence));
  }

  setValue() {
    this.sectionFormGroup.controls[this.question.key].setValue(
      {
        sequence: this.controlSequence.value.replace(/\s+/g, '')
      }
    );
    this.controlSequence.setValue(formatAminoAcidSequence(this.controlSequence.value));
  }

}
