<div class="text-editor" [formGroup]="form">
    <div #elLineNumbers class="line-numbers">
      <div
        *ngFor="let i of lineNumbers"
        id="line-{{i}}"
        class="line-number"
      >
        {{i + 1}}
      </div>
    </div>
    <mat-form-field
      floatLabel="never"
      appearance="none"
    >
              <textarea
                #ta
                formControlName="yaml"
                matInput
                matTextareaAutosize
                wrap="off"
                placeholder="Edit the yaml"
                (keyup)="keyHit($event)"
              ></textarea>
    </mat-form-field>
    <div
      #displayError
      class="validation-error-wrapper"
    >
      <div
        *ngIf="validationError"
        class="validation-error"
      >
        <div>{{validationError.message}}</div>
        <div>
          <button
            mat-icon-button
            (click)="clearError()"
          >
            <mat-icon
            >close
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
