import { createSelector } from '@ngrx/store';

import { AppState } from '../../../store/app.reducers';
import FlowCellsState from './flow-cells.reducer';

export const getFlowCellsState = (state: AppState) => state.flowCells;
export const getSelectedFlowCell = createSelector(getFlowCellsState, (state: FlowCellsState) => state.selected);

export const getFlowCellSampleNors = createSelector(getFlowCellsState, (state: FlowCellsState) => state.sampleNors);
export const getFlowCellRquestFastqDirs = createSelector(getFlowCellsState, (state: FlowCellsState) => state.requestFastqDirs);
export const getNorAdmins = createSelector(getFlowCellsState, (state: FlowCellsState) => state.norAdmins);

export const isSelectedFlowCellSaved = createSelector(getFlowCellsState, (state: FlowCellsState) => state.selectedIsSaved);
export const getSelectedFlowCellConflict = createSelector(getFlowCellsState, (state: FlowCellsState) => state.selectedConflicts || []);
export const getSelectedFlowCellZeroConcentrationLanes = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.selected.concentrations
    .reduce((indices, concentration, index) =>
      concentration <= 0 ? [...indices, index] : indices, new Array<number>())
);

export const isSelectedFlowCellSequencerConfigurationStateImproper = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => {
    const sequencer = state.selected.sequencerModel;
    const conf = state.selected.sequencerConfiguration;
    if (sequencer.flowCellTypes.length > 0) {
      if (conf === null || typeof conf.flowCellType === 'undefined') {
        return true;
      }
    }
    if (sequencer.loadingTypes.length > 0) {
      if (conf === null || typeof conf.loadingType === 'undefined') {
        return true;
      }
    }
    return false;
  }
);

export const getFlowCells = createSelector(getFlowCellsState, (state: FlowCellsState) => state.flowCells);
export const getFlowCellPageInfo = createSelector(getFlowCellsState, (state: FlowCellsState) => state.pageInfo);
