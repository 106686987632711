import { Component, Input } from '@angular/core';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-flow-cell-change-status-button',
  template: `
      <div
              *ngIf="isLaber$ | async"
      >
          <nemo-flow-cell-change-to-draft-button *ngIf="status !== 'DRAFT'"
                                                 [flowCellId]="flowCellId"></nemo-flow-cell-change-to-draft-button>

          <nemo-lane-assigner-complete-button *ngIf="status === 'DRAFT'"
                                              [flowCellId]="flowCellId"></nemo-lane-assigner-complete-button>
      </div>`
})
export class FlowCellChangeStatusButtonComponent extends HasGlobalContextUserRole {
  @Input() flowCellId: string;
  @Input() status: string;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }
}
