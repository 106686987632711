import { SimpleRequest } from '../models/genomics-request';

export function simpleRequestDTOToSimpleRequest(reqDto): SimpleRequest {
  return new SimpleRequest(
    reqDto.accessionCode,
    reqDto.requesterId,
    [],
    reqDto.title,
    reqDto.description,
    reqDto.createdAt,
    reqDto.libraryMethod,
    reqDto.phiXPercentage
  );
}
