import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import {
  findPlateByAccessionCodeThenSelectFromReArray,
  getPlateList,
  removePlateFromEchoPooling
} from '../../../labware/store/plates';
import { TaskAvailable } from '../../models/task.model';
import {
  createNewEchoPoolingPlate,
  findAllPlatesFromEchoPooling
} from '../../../labware/store/plates/actions/plate-list.action';
import { SimplePlateWithRequests } from '../../../labware/models/simple-plate';
import { Router } from '@angular/router';
import PlateDimensions from '../../../labware/models/plate-dimensions';
import * as moment from 'moment';

export const ECHO_POOLING_DESTINATION_PLATE_ID = 'echo-pooling/destination';

@Component({
  selector: 'nemo-echo-pooling-left-zone',
  template: `
    <div *ngIf="withSelector" class="header">
      <button mat-stroked-button (click)="createPlate()" class="add-plate">New plate</button>
      <nemo-select-plate [plates$]="plates$"
                         (changeSelectedPlate)="updateSelectedPlate($event)"
                         [selectedPlateAccessionCode]="_plateAccessionCode"
                         inputPlaceHolder="Select target plate"></nemo-select-plate>
    </div>

    <div *ngIf="_plateAccessionCode">
      <nemo-plate-viewer-container [plateAccessionCode]="destinationPlateIdentifier"
                                   [isReadonly]="true"></nemo-plate-viewer-container>
    </div>
  `,
  styles: [`
    .header {
      display: flex;
    }

    .add-plate {
      width: 95px;
      height: 40px;
      margin-top: 3px;
      margin-right: 5px;
    }

    nemo-select-plate {
      flex: auto;
    }
  `]
})
export class EchoPoolingLeftZoneComponent implements OnInit {

  _plateAccessionCode: string;
  destinationPlateIdentifier = ECHO_POOLING_DESTINATION_PLATE_ID;

  @Input() plateAccessionCode$: Observable<string>;
  @Input() sourcePlateAccessionCode: string;
  @Input() withSelector = true;
  plates$: Observable<SimplePlateWithRequests[]>;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.store.dispatch(findAllPlatesFromEchoPooling({
      availableTask: TaskAvailable.ECHO_POOLING_TO
    }));
    this.plates$ = this.store.select((getPlateList(TaskAvailable.ECHO_POOLING_TO)));

    this.plateAccessionCode$.subscribe((ac) => {
      if (!Boolean(ac)) {
        this.store.dispatch(removePlateFromEchoPooling({plateAccessionCode: this.destinationPlateIdentifier}));
        return;
      }
      this._plateAccessionCode = ac;
      this.store.dispatch(findPlateByAccessionCodeThenSelectFromReArray({
        accessionCode: ac,
        putPlateIdentifier: this.destinationPlateIdentifier
      }));
    });
  }

  updateSelectedPlate(accessionCode: string) {
    this.router.navigate(['/tasks/echo-pooling/' + accessionCode + '/' + this.sourcePlateAccessionCode]);
  }

  createPlate(): void {
    this.store.dispatch(createNewEchoPoolingPlate({
      title: 'Echo pooling target - ' + this.sourcePlateAccessionCode + ' - ' + moment().format('MMMM Do YYYY, h:mm a'),
      dimensions: new PlateDimensions(16, 24)
    }));
  }
}
