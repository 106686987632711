import { PlateCoordinates } from '../../../labware/models/plate-coordinates';

export class InvalidPlateQCInstrumentDirectoryError {
  constructor(
    public readonly plateAccessionCode: string,
    public readonly instrumentDirectoryAccessionCode: string,
    public readonly unassignedPlateCoordinates: PlateCoordinates[],
    public readonly unassignedInstrumentDirectoryCoordinates: PlateCoordinates[]
  ) {
  }
}
