import GenomicsRequestState, * as fromRequest from '../genomics-request/store/genomics-request.reducer';
import ErrorState, * as fromError from '../shared/nerrors/store/error.reducer';
import PlatesState, * as fromPlates from '../labware/store/plates/plates.reducer';
import FlowCellsState, * as fromFlowCells from '../labware/store/flow-cells/flow-cells.reducer';
import IndexKitState, * as fromIndexKits from '../index/store/index-kit.reducer';
import PoolState, * as fromPool from '../bio/store/pool.reducer';
import TableState, * as fromTable from '../table/store/table.reducer';
import UserState, * as fromUser from '../user/store/user.reducer';
import TaskState, * as fromTask from '../tasks/store/task.reducer';
import CompanyState, * as fromCompany from '../company/store/company.reducer';
import QcState, * as fromQc from '../qc/store/qc.reducer';
import GlobalContextState, * as fromGlobalContext from '../global-context/store/global-context.reducer';
import { ActionReducerMap } from '@ngrx/store';
import CommentState, * as fromCommentReducer from '../comment/store/comment.reducer';
import GraphState, * as fromGraphReducer from '../graph/store/graph.reducer';
import SequencingState, * as fromSequencingReducer from '../sequencing/store/sequencing.reducer';
import * as fromFormEditor from '../form/store/editor';
import * as fromFormRequest from '../form/store/form-request';
import * as fromForm from '../form/store/form';

export interface AppState {
  comment: CommentState;
  company: CompanyState;
  error: ErrorState;
  flowCells: FlowCellsState;
  form: fromForm.FormState;
  formEditor: fromFormEditor.FormEditorState;
  formRequest: fromFormRequest.FormRequestState;
  genomicsRequest: GenomicsRequestState;
  globalContext: GlobalContextState;
  graph: GraphState;
  indexKit: IndexKitState;
  plates: PlatesState;
  pool: PoolState;
  qc: QcState;
  sequencing: SequencingState;
  table: TableState;
  task: TaskState;
  user: UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  comment: fromCommentReducer.reducer,
  company: fromCompany.reducer,
  error: fromError.reducer,
  flowCells: fromFlowCells.reducer,
  form: fromForm.reducer,
  formEditor: fromFormEditor.reducer,
  formRequest: fromFormRequest.reducer,
  genomicsRequest: fromRequest.reducer,
  globalContext: fromGlobalContext.reducer,
  graph: fromGraphReducer.reducer,
  indexKit: fromIndexKits.reducer,
  plates: fromPlates.reducer,
  pool: fromPool.reducer,
  qc: fromQc.reducer,
  sequencing: fromSequencingReducer.reducer,
  table: fromTable.reducer,
  task: fromTask.reducer,
  user: fromUser.reducer,
};
