import { Component, Input } from '@angular/core';
import { Plate96 } from '../../../labware/models/plate-96';
import { Index } from '../../../bio/models/barcode-index';
import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import { SamplePlateIndexMapping } from '../task-input/sample-plate-index-mapping';
import { PoolSamplePlaceholder } from '../../../bio/models/pool';

@Component({
  selector: 'nemo-index-assigner-middle-zone',
  template: `
    <div class="message selectPlateMessage" *ngIf="!indexPlate">Select an index plate</div>

    <!-- Assign button -->
    <nemo-index-assignment-button class="command"
                                  *ngIf="indexPlate"
                                  [samplePlate]="samplePlate"
                                  [indexPlate]="indexPlate"
                                  [canAssign]="canAssign"
                                  [currentSelectionSamples]="currentSelectionSamples"
                                  [currentSelectionIndexes]="currentSelectionIndexes"
                                  [samplePlateIndexMapping]="samplePlateIndexMapping"
                                  [infoMessage]="infoMessage"
                                  [infoMessageStyle]="infoMessageStyle"></nemo-index-assignment-button>

    <!-- Reset button -->
    <nemo-reset-index-assignment-buttons [samplePlate]="samplePlate"
                                         [indexPlate]="indexPlate"
                                         [currentSelectionSamples]="currentSelectionSamples"></nemo-reset-index-assignment-buttons>
  `,
  styles: [`
    :host {
      width: 200px;
      max-height: 400px;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 15px;
    }
  `]
})
export class IndexAssignerMiddleZoneComponent {

  @Input() samplePlate: Plate96<PoolSamplePlaceholder>;
  @Input() indexPlate?: Plate96<Index>;
  @Input() currentSelectionSamples?: PlateCoordinatesSelection;
  @Input() currentSelectionIndexes?: PlateCoordinatesSelection;
  @Input() samplePlateIndexMapping?: SamplePlateIndexMapping;
  @Input() canAssign: boolean;
  @Input() infoMessage: string;
  @Input() infoMessageStyle: string;

}
