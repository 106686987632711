import { createAction, props } from '@ngrx/store';
import { CreateRequestDTO, RequestDetailedDTO, UpdateRequestDTO } from '../models/genomics-request.dto';
import { NRequest, NRequestSummary, RequestSampleContainers } from '../models/genomics-request';
import { PageInfo } from '../../shared/models/page.model';
import { Cro } from '../models/cro-request.model';

export const changeShowDetail = createAction(
  '[Genomics Request] Change show detail',
);

export const findRequestByAccessionCodeFromRequestPage = createAction(
  '[Genomics Request Page] Find request by accessionCode',
  props<{ accessionCode: string }>()
);

export const findAllRequestByAccessionCodesFromAPI = createAction(
  '[Plate API] Find all requests by accessionCode',
  props<{ accessionCodes: string[] }>()
);

export const findRequestByAccessionCodePending = createAction(
  '[Genomics Request] Find request by accessionCode pending',
);

export const findRequestByAccessionCodeSuccess = createAction(
  '[Genomics Request] Find request by accessionCode success',
  props<{ request: NRequest }>()
);

export const findRequestByAccessionCodeError = createAction(
  '[Genomics Request] Find request by accessionCode error',
  props<{ message: string }>()
);

export const updateRequestDictionary = createAction(
  '[Genomics Request] Update request dictionary',
  props<{ request: NRequest }>()
);

export const findAllRequests = createAction(
  '[Genomics Request] Find all requests with pagination',
  props<{ pageSize: number, page: number }>()
);

export const searchAllRequests = createAction(
  '[Genomics Request] search requests',
  props<{ requesterId?: string, assignedTo?: string, q?: string }>()
);

export const findAllRequestsFromHome = createAction(
  '[Home Page] Find assigned/requested genomics requests',
  props<{ requesterId?: string, assignedTo?: string, hideArchived?: boolean }>()
);

export const findAllRequestsSuccess = createAction(
  '[Genomics Request] Find all requests success',
  props<{ requests: NRequestSummary[],  pageInfo: PageInfo }>()
);

export const searchAllRequestsSuccess = createAction(
  '[Genomics Request] Search all requests success',
  props<{ requests: NRequestSummary[], pageInfo: PageInfo }>()
);

export const findRequestError = createAction(
  '[Genomics Request] Find all requests error',
  props<{ message: string }>()
);

export const submitNewRequest = createAction(
  '[Genomics Request creation form] submit a new request request',
  props<{ request: CreateRequestDTO }>()
);

export const submitNewRequestPending = createAction(
  '[Genomics Request API] new request pending'
);

export const submitNewRequestSuccess = createAction(
  '[Genomics Request API] new requests success',
  props<{ request: RequestDetailedDTO }>()
);

export const submitNewRequestError = createAction(
  '[Genomics Request API] new requests error',
  props<{ message: string }>()
);

export const clearValidateRequestSample = createAction(
  '[Genomics Request Submission Form] clear the request sample validation'
);

export const validateRequestSampleSuccess = createAction(
  '[Genomics Request Submission API] Validate request sample file success',
  props<{ containers: RequestSampleContainers, metadataHeaders: String[] }>()
);

export const updateRequestFieldFromRequestPage = createAction(
  '[Genomics Request Page] update request field',
  props<{ accessionCode: string, field: string, value: string }>()
);

export const updateRequestFieldError = createAction(
  '[Genomics Request Page] update request field error',
  props<{ message: string }>()
);

export const updateRequest = createAction(
  '[Genomics Request Page] update request',
  props<{ request: UpdateRequestDTO }>()
);

export const updateRequestSuccess = createAction(
  '[Genomics Request API] update request success',
  props<{ request: NRequest }>()
);

export const completeStage = createAction(
  '[Genomics Request Page] complete stage',
  props<{ accessionCode: string, stage: string }>()
);

export const completeStageSuccess = createAction(
  '[Genomics Request API] complete stage success',
  props<{ request: NRequest }>()
);

export const reopenStage = createAction(
  '[Genomics Request Page] reopen stage',
  props<{ accessionCode: string, stage: string }>()
);

export const reopenStageSuccess = createAction(
  '[Genomics Request API] reopen stage success',
  props<{ request: NRequest }>()
);

export const archiveRequest = createAction(
  '[Genomics Request API] archive request',
  props<{ accessionCode: string }>()
);

export const restoreRequest = createAction(
  '[Genomics Request API] restore request',
  props<{ accessionCode: string }>()
);

export const archiveRequestSuccess = createAction(
  '[Genomics Request API] archive request success',
  props<{ accessionCode: string }>()
);

export const restoreRequestSuccess = createAction(
  '[Genomics Request API] restore request success',
  props<{ accessionCode: string }>()
);

export const archiveRequestFromListSuccess = createAction(
  '[Genomics Request API] archive request from list success',
  props<{ accessionCode: string }>()
);

export const restoreRequestFromListSuccess = createAction(
  '[Genomics Request API] restore request from list success',
  props<{ accessionCode: string }>()
);

export const findAllCros = createAction(
  '[Genomics Request] Find all CROs',
);

export const findAllCrosSuccess = createAction(
  '[Genomics Request] Find all CROs success',
  props<{ cros: Cro[] }>()
);

export const assignCroToGenomicsRequest = createAction(
  '[Genomics Request] Assign CRO to genomics request',
  props<{ croId: string | null, accessionCode: string }>()
);

export const assignCroToGenomicsRequestSuccess = createAction(
  '[Genomics Request API] Assign CRO to genomics request success',
  props<{ request: NRequest }>()
);