import { Component, Input } from '@angular/core';
import { FormStructure } from '../../models/form';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-form',
  template: `
    <div
      class="container"
    >
      <h2>{{structure.title}}</h2>
      <span class="description">
        {{structure.description}}
      </span>
      <div [formGroup]="group">
        <mat-accordion
          [multi]="true"
        >
          <div *ngFor="let section of structure.sections">
            <nemo-form-section
              [section]="section"
              [sectionFormGroup]="this.group.controls[section.key]"
              [startCollapsed]="initialSectionKey !== section.key && startCollapsed"
            ></nemo-form-section>
          </div>

        </mat-accordion>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: column;
             }

             span.description {
               font-style: italic;
             }
           `]
})

export class FormComponent {
  @Input()
  structure: FormStructure;

  @Input()
  group: UntypedFormGroup;

  @Input()
  startCollapsed = false;

  @Input()
  initialSectionKey;

}
