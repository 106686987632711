import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PageInfo } from '../../shared/models/page.model';
import { AppState } from '../../store/app.reducers';
import { Title } from '@angular/platform-browser';
import { findAllPlateQC } from '../store/actions/qc-api.actions';
import { getAllQCPlateSummaries, getQcResultPageInfo } from '../store/qc.selectors';
import { InstrumentDirectory } from '../models/instrumentDirectory';
import { PlateQCSummary } from '../models/plate-qc-measures';

@Component({
  selector: 'nemo-qc-table-page',
  template: `
    <nemo-pending-title title="QC results"></nemo-pending-title>
    <nemo-qc-table [plateQCSummaries]="plateQCSummaries$ | async"
                   [isLabMember]="true"
    ></nemo-qc-table>
    <nemo-paginator [pageInfo]="pageInfo$ | async" (pageChange)="findAll($event[0], $event[1])"></nemo-paginator>
  `
})
export class QcTablePageComponent implements OnInit {

  plateQCSummaries$: Observable<PlateQCSummary[]>;
  pageInfo$: Observable<PageInfo>;

  constructor(
    private store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Nemo QC list');
    this.findAll(25, 0);
    this.pageInfo$ = this.store.select((getQcResultPageInfo));
    this.plateQCSummaries$ = this.store.select((getAllQCPlateSummaries));
  }

  findAll(pageSize: number, page: number) {
    this.store.dispatch(findAllPlateQC({pageSize, page}));
  }
}
