import { InstrumentDirectoryDto, InstrumentFileDto } from '../dto/instrument-directory.dto';
import { InstrumentFile } from '../models/instrumentFile';
import { InstrumentDirectory } from '../models/instrumentDirectory';

export function dtoToQCInstrumentFile(fdto: InstrumentFileDto): InstrumentFile {
  return new InstrumentFile(
    fdto.accessionCode,
    fdto.directoryAccessionCode,
    fdto.path,
    fdto.fileType,
    fdto.createdAt,
    fdto.gcsPublicUrl
  );
}

export function dtoToQcDirectory(qcResultDto: InstrumentDirectoryDto): InstrumentDirectory {
  return new InstrumentDirectory(
    qcResultDto.accessionCode,
    qcResultDto.instrument,
    qcResultDto.path,
    qcResultDto.createdAt,
    qcResultDto.files ? qcResultDto.files.map(dtoToQCInstrumentFile) : null
  );
}
