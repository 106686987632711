import { Component } from '@angular/core';

@Component({
  selector: 'nemo-departments',
  template: `
    <div class="container">
      <h3>Add a new department</h3>
      <nemo-departments-add></nemo-departments-add>
      <h3>Existing departments</h3>
      <nemo-departments-list></nemo-departments-list>
    </div>
  `,
  styles: [`
               table {
                 width: 100%;
               }
             `]
})
export class DepartmentsComponent {
  constructor() {
  }

}
