import { Component, Input } from '@angular/core';
import { NRequest } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-samples',
  template: `
    <h3>Samples</h3>
    <div class="sample-containers">
      <nemo-request-sample-containers [requestAccessionCode]="request?.accessionCode"
                                      [containers]="request"
                                      [isLaber]="isLaber"
      ></nemo-request-sample-containers>
    </div>
    <hr/>
  `,
  styles: [`
    div.sample-containers {
      display: flex;
      flex-direction: row;
    }
  `]
})
export class RequestSamplesComponent {
  @Input() request: NRequest;
  @Input() isLaber: boolean;
}
