<nemo-flowcell-viewer *ngIf="flowCell$ | async"
                      [flowCell]="flowCell$ | async"
                      [conflicts]="conflict$ | async"
                      [isReadonly]="isReadonly"
                      (poolColors)="poolColors"
                      [hideLegend]="true"
                      (addPool)="addPool($event)"
                      (removePool)="removePool($event)"
                      [editSettings]="editSettings"
                      [settingsTooltip]="settingsTooltip"
                      (settingsEdited)="settingsEditedEvent($event)"
></nemo-flowcell-viewer>

<div *ngIf="showFlowCellId" class="flow-cell-id accession-code">
  {{(flowCell$ | async)?.flowCellId}}
</div>
