import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

/**
 * Send the ID token with every HTTP request
 */
@Injectable()
export class IdTokenInterceptor implements HttpInterceptor {

  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();
    if (!url.startsWith(environment.apiUrl)) {
      return next.handle(req);
    }
    const token = this.authStorage.getItem('id_token');
    if (token) {
      const header = 'Bearer ' + token;
      const headers = req.headers.set('Authorization', header);
      req = req.clone({headers});
    }
    return next.handle(req).pipe(catchError(err => this.errorHandler.handleError(err)));
  }
}
