import { Index, TerminusTypes } from './barcode-index';
import { NRequest } from '../../genomics-request/models/genomics-request';
import * as _ from 'lodash';

/**
 * a biological sample, which can carry a Index
 * changing property such as the index generate a new object (immutability)
 */
export class Sample {

  constructor(
    public readonly name: string,
    public readonly id?: number,
    public readonly assignedIndex?: Index,
    public readonly accessionCode?: string,
    public readonly requestAccessionCode?: NRequest['accessionCode'],
    public readonly metadata?: { [key: string]: any }
  ) {
  }

  /**
   * @return true if the sample has been associated with an index
   */
  public isIndexed(): boolean {
    return !!this.assignedIndex;
  }

  public hasIndexOn(termini: TerminusTypes): boolean {
    return Boolean(this.assignedIndex) && this.assignedIndex.hasTermini(termini);
  }

  public assignIndex(assignedIndex: Index): Sample {
    return new Sample(this.name, this.id, assignedIndex, this.accessionCode, this.requestAccessionCode);
  }

  public unAssignIndex(): Sample {
    return new Sample(this.name, this.id, null, this.accessionCode, this.requestAccessionCode);
  }
}

export function uniqueRequestAccessionCodeFromSampleLists(samples: Array<Sample>): Array<string> {
  return _.chain(samples)
    .map('requestAccessionCode')         // get the 'requestAccessionCode' property
    .filter()                 // filter the not falsy (thus the non empty/null requestAccessionCode
    .uniqBy()                 // keep only one of each of the request Ids
    .sortBy()                 // order them
    .value();                 // that's only the _ (lodash) call to actually execute the chain of commands
}
