import { PlateCoordinates } from '../../labware/models/plate-coordinates';
import { InstrumentFile } from './instrumentFile';

export class FragmentAnalyzerQCMeasure {
  constructor(
    public readonly plateCoordinates: PlateCoordinates,
    public readonly data?: { [key: string]: any },
    public readonly plotInstrumentFile?: InstrumentFile
  ) {
  }
}
