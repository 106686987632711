import PlateDimensions from '../../labware/models/plate-dimensions';
import { Plate96 } from '../models/plate-96';
import { PlateCoordinates } from '../models/plate-coordinates';
import { PoolWithCoordinatesDto, SamplePlateDto } from '../../genomics-request/models/genomics-request.dto';
import { BioMaterialPlateMapping } from '../models/bio-material-plate-mapping';
import { SimplePlateWithRequestsDto } from '../dto/labware.dto';
import { SimplePlateWithRequests } from '../models/simple-plate';
import { simpleRequestDTOToSimpleRequest } from '../../genomics-request/services/simple-request.mapper';
import { Pool } from '../../bio/models/pool';
import { poolDtoToPool } from '../../bio/services/bio.mapper';

export function sampleDtoToBioMaterialWithMapping(poolDto: PoolWithCoordinatesDto): BioMaterialPlateMapping<Pool> {
  const plateCoordinates = new PlateCoordinates(poolDto.plateLocation.coordinates.row, poolDto.plateLocation.coordinates.column);
  const pool = poolDtoToPool(poolDto.content);
  return new BioMaterialPlateMapping<Pool>(pool, plateCoordinates);
}

export function samplePlateDtoToPlate96(plate: SamplePlateDto): Plate96<Pool> {
  const dimensions = new PlateDimensions(plate.dimensions.numberOfRows, plate.dimensions.numberOfColumns);
  return new Plate96<Pool>(
    plate.id,
    plate.accessionCode,
    plate.title,
    plate.availableForTasks,
    dimensions,
  )
    .setBioMaterialMappings(plate.layout.map(sampleDtoToBioMaterialWithMapping));
}

export function plateDimensionsDtoToPlateDimensions(dto: { numberOfRows: number, numberOfColumns: number }): PlateDimensions {
  return new PlateDimensions(
    dto.numberOfRows,
    dto.numberOfColumns
  );
}

export function simplePlateWithRequestDtoToSimplePlateWithRequest(dto: SimplePlateWithRequestsDto): SimplePlateWithRequests {
  return new SimplePlateWithRequests(
    dto.accessionCode,
    dto.id,
    dto.title,
    dto.numberOfSamples,
    dto.numberOfIndexedSamples,
    dto.requests.map(simpleRequestDTOToSimpleRequest),
    plateDimensionsDtoToPlateDimensions(dto.dimensions),
    dto.occupancy,
    dto.availableForTasks,
    dto.archived
  );
}
