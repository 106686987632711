/**
 * just column/row for coordinates within a plate
 *
 * The class come with toString/string parsing function
 */
export class PlateCoordinates {
  constructor(public readonly row: number, public readonly column: number) {
  }

  public equalsTo(other: PlateCoordinates): boolean {
    return this.column === other.column && this.row === other.row;
  }

  /**
   * check if the current coordinates is strictly before the other, in the plate assignment order
   * @param other the coordinate to be compared to
   */
  public before(other: PlateCoordinates): boolean {
    if (this.column === other.column) {
      return this.row < other.row;
    }
    return this.column < other.column;
  }

  public clone(): PlateCoordinates {
    return new PlateCoordinates(this.row, this.column);
  }

  /**
   * build a string in the form 'B7'
   */
  public toString(): string {
    return String.fromCharCode(65 + this.row) + (this.column + 1);
  }
}

const reParsing = /^\s*([A-Ha-h])\s*([\d]+)\s*$/;

export function isCoordinatesStringValid(str: String): boolean {
  return Boolean(str.match(reParsing));
}

export function parseCoordinates(str: String): PlateCoordinates {
  const m = str.match(reParsing);
  if (!m) {
    throw new Error(`Cannot parse coordinates from "${str}"`);
  }
  const col = parseInt(m[2], 10) - 1;
  const row = m[1].toUpperCase().charCodeAt(0) - 65;
  return new PlateCoordinates(row, col);
}
