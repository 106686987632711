import { Component, Input } from '@angular/core';
import { SimplePlateWithRequests } from '../../models/simple-plate';

@Component({
  selector: 'nemo-select-plate-item',
  template: `
    <nemo-plate-icon [dimensions]="plate.dimensions"
                     [occupancy]="plate.occupancy"
                     [small]="true"
                     class="dropdown"
    ></nemo-plate-icon>
    <div>
      <p class="plate-line">
        <strong class="accession-code">{{plate.accessionCode | accessionCode}}</strong>
        ({{plate.numberOfSamples}} samples and {{plate.numberOfIndexedSamples}} indexed)
      </p>
      <p class="description-line">
        <span>{{plate.title}}</span>
      </p>
      <p class="description-line">
        <span *ngFor="let request of plate.requests; last as isLast" class="accession-code">
          {{request.accessionCode | accessionCode}}
          <span *ngIf="!isLast">, </span>
        </span>
      </p>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
    }

    div {
      margin-top: auto;
      margin-bottom: auto;
    }

    .plate-line {
      font: normal 14px Roboto, sans-serif;
      margin: 0;
    }

    .description-line {
      font: normal 12px Roboto, sans-serif;
      margin: 0;
    }

    nemo-plate-icon.dropdown {
      zoom: 33%;
      margin-right:1em;
    }
  `]
})
export class SelectPlateItemComponent {
  @Input() plate: SimplePlateWithRequests;
}
