import { createAction, props } from '@ngrx/store';
import { NError } from '../models/error.model';

export enum ActionTypes {
  InsertError = '[Error] Add Error',
}

export const insertErrorFromHttpInterceptor = createAction(
  '[Http Interceptor] add error',
  props<{ error: NError }>()
);

export const insertErrorFromGlobalHandler = createAction(
  '[Http Interceptor] add error',
  props<{ error: NError }>()
);

export const insertErrorInvalidSubmission = createAction(
  '[Request subsmission] add error',
  props<{ error: NError }>()
);
export const insertErrorInvalidRequestParsing = createAction(
  '[Request subsmission] file parsing error error',
  props<{ error: NError }>()
);
export const insertErrorUncaughtFormValidation = createAction(
  '[Form validation] uncaught form validation',
  props<{ error: NError }>()
);

export const clearErrorFromLoggedIn = createAction(
  '[Logged in] clear error'
);

export const clearErrorFromRouter = createAction(
  '[From Router] clear error'
);

export const clearErrorFromSnackBarClose = createAction(
  '[From Snackbar] clear error'
);

export type ErrorActions = ReturnType<typeof insertErrorFromHttpInterceptor
  | typeof insertErrorFromGlobalHandler
  | typeof insertErrorInvalidSubmission
  | typeof insertErrorInvalidRequestParsing
  | typeof insertErrorUncaughtFormValidation
  | typeof clearErrorFromSnackBarClose
  | typeof clearErrorFromRouter
  | typeof clearErrorFromLoggedIn>  ;
