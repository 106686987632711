import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { actionAddCommentFile } from '../../store/comment.actions';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { FileDto } from '../../../shared/models/file-dto';
import EntityPage from '../../../global-context/models/entity-page';

@Component({
  selector: 'nemo-comment-file-new-button',
  template: `
    <div class="new-button-line">
      <button mat-button class="new-button" (click)="fileInput.click()">Add file</button>
      <input hidden type="file" #fileInput (change)="onFileAdded($event)">
    </div>
  `,
  styles: [`
    .new-button-line {
      margin: 4px 20px;
    }

    .new-button {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
      font-family: 'Source Sans Pro', Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
      font-size: .875rem;
      letter-spacing: .25px;
      align-items: center;
      background-color: #fff;
      border-radius: 24px;
      color: #3c4043;
      display: inline-flex;
      font-weight: 600;
      height: 48px;
      min-width: 56px;
      padding: 0 24px 0 0;
      border: 0;
      cursor: pointer;
      transition: color 200ms cubic-bezier(0.4, 0.0, 0.2, 1), background 200ms cubic-bezier(0.4, 0.0, 0.2, 1),
      box-shadow 400ms cubic-bezier(0.4, 0.0, 0.2, 1);
    }

    .new-button:hover {
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
      background-color: #f8f9fa;
    }

    .new-button::before {
      background-image: url(https://www.gstatic.com/images/icons/material/colored_icons/1x/create_32dp.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 32px;
      content: '';
      display: block;
      height: 48px;
      min-width: 56px;
    }
  `]
})
export class CommentFileNewButtonComponent extends HasGlobalContextUserRole {

  @Input() entityPage: EntityPage;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private readonly fileTransferService: FileTransferService
  ) {
    super(store);
  }

  async onFileAdded(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const b64 = await this.fileTransferService.readFileAsDataURL(file);
      const addedFile: FileDto = {
        file: b64,
        filename: file.name
      };
      this.store.dispatch(actionAddCommentFile({entityPage: this.entityPage, file: addedFile}));
    }

  }

}
