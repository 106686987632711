import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { updateLab } from '../../../store/lab.actions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'nemo-labs-edit-dialog',
  template: `
    <div class="container"
         [formGroup]="formGroup"
    >
      <h1>Editing {{data['alias']}} lab</h1>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Displayed lab name, such as 'Biochemistry'"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Home page</mat-label>
        <input
          matInput
          formControlName="homePage"
          placeholder="The intranet group home page"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Where new request emails are delivered"
        >
      </mat-form-field>
      <div>
        <button mat-button (click)="close()">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!formGroup.valid"
          (click)="submit()"
        >Update
        </button>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: column;
               gap: 8px;
             }

             mat-form-field {
               width: 40em;
               margin-right: 1em;
             }
           `]
})
export class LabsEditDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private readonly store: Store<AppState>,
    private dialogRef: MatDialogRef<LabsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      alias: new UntypedFormControl(this.data['alias']),
      name: new UntypedFormControl(this.data['name'], [
        Validators.required
      ]),
      homePage: new UntypedFormControl(this.data['homePage'], [
        Validators.required
      ]),
      email: new UntypedFormControl(this.data['email'], [
        Validators.required, Validators.email
      ])
    });

  }

  submit() {
    this.store.dispatch(updateLab({
      alias: this.formGroup.get('alias').value,
      name: this.formGroup.get('name').value,
      homePage: this.formGroup.get('homePage').value,
      email: this.formGroup.get('email').value
    }));
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
