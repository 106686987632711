import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DepartmentService } from '../services/department.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  archiveDepartment,
  createDepartment,
  findAllDepartments, findAllDepartmentsAfterUpdate,
  findAllDepartmentsError,
  findAllDepartmentsSuccess, restoreDepartment
} from './department.actions';
import { Department } from '../models/Department';

@Injectable()
export class DepartmentEffects {
  constructor(private actions$: Actions, private departmentService: DepartmentService) {
  }

  findAll$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllDepartments.type,
      findAllDepartmentsAfterUpdate.type
    ),
    mergeMap(() => this.departmentService.findAll()),
    map(departments => findAllDepartmentsSuccess({departments})),
    catchError(error => of(findAllDepartmentsError({message: error.message})))) }
  );

  createDepartment$ = createEffect(() => { return this.actions$.pipe(
    ofType(createDepartment.type),
    mergeMap((action: ReturnType<typeof createDepartment>) =>
      this.departmentService.create({
        code: action.code,
        name: action.name
      } as Department)
    ),
    map(() => findAllDepartmentsAfterUpdate())) }
  );

  archiveDepartment$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveDepartment.type),
    mergeMap((action: ReturnType<typeof archiveDepartment>) =>
      this.departmentService.archive(action.department)
    ),
    map(() => findAllDepartmentsAfterUpdate())) }
  );

  restoreDepartment$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreDepartment.type),
    mergeMap((action: ReturnType<typeof restoreDepartment>) =>
      this.departmentService.restore(action.department)
    ),
    map(() => findAllDepartmentsAfterUpdate())) }
  );

}
