export default class PlateDimensions {
  constructor(
    public readonly numberOfRows: number,
    public readonly numberOfColumns: number
  ) {
  }

  /**
   * the total number of bioMaterialPlateMappings
   */
  size() {
    return this.numberOfColumns * this.numberOfRows;
  }

  /**
   * build a string in format 'B7'
   */
  public toString() {
    return `${this.numberOfColumns} x ${this.numberOfRows}`;
  }
}
