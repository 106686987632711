import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'nemo-quick-access',
  template: `
      <div>redirecting...</div>
  `
})
export class QuickAccessComponent implements OnInit {

  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.route.data.pipe(take(1)).subscribe(data => {
      const path = data.resolvedPath || '/';
      this.router.navigate([path], { replaceUrl: true });
    });
  }

}
