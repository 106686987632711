import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { selectEntityPage } from '../../../global-context/store/global-context.selectors';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import EntityPage from '../../../global-context/models/entity-page';

@Component({
  selector: 'nemo-comment-container',
  template: `
      <div
              *ngIf="entityPage$|async"
              class="container"
      >
          <nemo-comment-thread
                  [entityPage]="entityPage$ | async"
          ></nemo-comment-thread>
      </div>
  `,
  styles: [`
      div.container {
          width: 20em;
          height: 100%;
          border-left-style: solid;
          border-left-width: 1px;
          border-left-color: #5F6368;
      }

      nemo-comment-thread {
          height: 100%;
      }
  `]
})
export class CommentContainerComponent implements OnInit {
  entityPage$: Observable<EntityPage>;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.entityPage$ = this.store.
      select((selectEntityPage)
    );
  }
}
