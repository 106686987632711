import { createAction, props } from '@ngrx/store';
import { NRequest } from '../../genomics-request/models/genomics-request';

export const archiveRequestFromList = createAction(
  '[Genomics Request List] archive request',
  props<{ accessionCode: string }>()
);

export const restoreRequestFromList = createAction(
  '[Genomics Request List] restore request',
  props<{ accessionCode: string }>()
);
