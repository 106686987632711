import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  archiveSequencerModel,
  createSequencerModel,
  findAllSequencerModelsAfterUpdate,
  findAllSequencerModelsAtInit,
  findAllSequencerModelsError,
  findAllSequencerModelsSuccess,
  restoreSequencerModel
} from '../actions/sequencer-model.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SequencerModelService } from '../../services/sequencer-model.service';
import { SequencerModel } from '../../models/sequencer-model';

@Injectable()
export class SequencerModelEffects {
  constructor(
    private actions$: Actions,
    private sequencerModelService: SequencerModelService
  ) {
  }

  findAllSequencerModels$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllSequencerModelsAtInit.type,
      findAllSequencerModelsAfterUpdate.type,
    ),
    mergeMap(() => this.sequencerModelService.findAll()),
    map(SequencerModels => findAllSequencerModelsSuccess({sequencerModels: SequencerModels})),
    catchError(error => of(findAllSequencerModelsError({message: error.message})))
    ) }
  );

  createSequencerModel$ = createEffect(() => { return this.actions$.pipe(
    ofType(createSequencerModel.type),
    mergeMap((action: ReturnType<typeof createSequencerModel>) =>
      this.sequencerModelService.create(action.sequencer)
    ),
    map(() => findAllSequencerModelsAfterUpdate())) }
  );

  settingsArchiveSequencerModel$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveSequencerModel.type),
    mergeMap((action: ReturnType<typeof archiveSequencerModel>) =>
      this.sequencerModelService.archive(action.sequencerModel)
    ),
    map(() => findAllSequencerModelsAfterUpdate())) }
  );

  settingsRestoreSequencerModel$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreSequencerModel.type),
    mergeMap((action: ReturnType<typeof restoreSequencerModel>) =>
      this.sequencerModelService.restore(action.sequencerModel)
    ),
    map(() => findAllSequencerModelsAfterUpdate())) }
  );
}
