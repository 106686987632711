import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import SequencingState from './sequencing.reducer';
import { LibraryMethod, SubmissionType } from '../models/library-method';
import { SequencerModel } from '../models/sequencer-model';

export const selectSequencingState = (state: AppState) => state.sequencing;
export const selectAllLibraryMethods = createSelector(
  selectSequencingState,
  (state: SequencingState) => state.libraryMethods.slice().sort(
    (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
);
export const selectActiveLibraryMethods = createSelector(
  selectAllLibraryMethods,
  (ls: LibraryMethod[]) => ls.filter(l => !l.archived)
);
export const selectLibraryMethodByName = (name: string) => createSelector(
  selectAllLibraryMethods,
  (ls: LibraryMethod[]) => ls.find(l => l.name === name)
);
export const selectActiveLibraryMethodsBySubmissionType = createSelector(
  selectActiveLibraryMethods,
  (ls: LibraryMethod[]) => {
    return ls.reduce((acc, method) => {
      const submissionType = method.submissionType || SubmissionType.Other;
      if (!acc.has(submissionType)) {
        acc.set(submissionType, []);
      }
      acc.get(submissionType)?.push(method);
      return acc;
    }, new Map<SubmissionType, LibraryMethod[]>())
  }
);
export const selectAllSequencerModels = createSelector(
  selectSequencingState,
  (state: SequencingState) => state.sequencerModels.slice().sort(
    (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
);
export const selectActiveSequencerModels = createSelector(
  selectAllSequencerModels,
  (ls: SequencerModel[]) => ls.filter(l => !l.archived)
);
