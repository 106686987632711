import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { laneAssignmentProcessed, selectHasSelectedFlowCellConflict } from '../../../labware/store/flow-cells';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'nemo-lane-assigner-complete-button',
  template: `
    <button mat-stroked-button
            color="primary"
            (click)="laneAssignmentCompleted()">
      Complete lane assignment
    </button>
  `
})
export class LaneAssignerCompleteButtonComponent implements OnInit {

  @Input() flowCellId: string;
  hasIndexConflict$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.hasIndexConflict$ = this.store.select(selectHasSelectedFlowCellConflict);
  }

  laneAssignmentCompleted() {
    this.store.dispatch(laneAssignmentProcessed({flowCellId: this.flowCellId}));
  }
}
