import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { updateFlowCellField } from '../../store/flow-cells';
import { FlowCell } from '../../models/flow-cell';

@Component({
  selector: 'nemo-flow-cell-title',
  template: `
    <div class="flow-cell-id"
    >
      <nemo-editable-text label="Flow cell ID"
                          [initValue]="flowCell.flowCellId"
                          [readonly]="flowCell.status !== 'DRAFT'"
                          isRequired="true"
                          maxLength="80"
                          (changeValue)="updateFlowCellId($event)"></nemo-editable-text>
      <div class="sequencer">
        <div class="instrument">{{flowCell.sequencerModel.name}} ({{flowCell.sequencerModel.manufacturer}})</div>
        <nemo-flow-cell-sequencer-configuration-readonly *ngIf="flowCell.status !== 'DRAFT'"
                                                         [configuration]="flowCell?.sequencerConfiguration"
        ></nemo-flow-cell-sequencer-configuration-readonly>
      </div>
    </div>
    <nemo-lane-assignment-export-button *ngIf="flowCell.layout?.length > 0 && flowCell.status !== 'DRAFT'"
                                        [flowCellId]="flowCell.flowCellId"
                                        [layout]="flowCell.layout"></nemo-lane-assignment-export-button>
    <nemo-flow-cell-export-pipeline-button
      *ngIf="flowCell.status === 'PROCESSED' || flowCell.status === 'NOTIFIED'"
      [flowCellId]="flowCell.flowCellId"
    ></nemo-flow-cell-export-pipeline-button>

    <nemo-flow-cell-notify-button
      *ngIf="flowCell.status === 'PROCESSED' || flowCell.status === 'NOTIFIED'"
      [flowCellId]="flowCell.flowCellId"
      [status]="flowCell.status"
    ></nemo-flow-cell-notify-button>

    <nemo-flow-cell-change-status-button [flowCellId]="flowCell.flowCellId"
                                         [status]="flowCell.status"></nemo-flow-cell-change-status-button>
  `,
  styles: [`
               :host {
                 display: flex;
               }

               div.flow-cell-id {
                 width: 100%;
                 margin-top: 10px;
               }

               nemo-editable-text {
                 font-size: 18px;
                 color: #555;
                 width: 100%;
               }

               div.instrument {
                 font-family: Roboto;
                 font-size: 130%;
                 margin-bottom: 0.5em;
               }

               nemo-lane-assignment-export-button {
                 margin: 10px 10px 10px 10px;
               }

               nemo-flow-cell-change-status-button {
                 margin: 10px 10px 10px 10px;
               }

               .sequencer {
                 display: flex;
                 flex-direction: row;
               }

               nemo-flow-cell-sequencer-configuration-readonly {
                 margin-left: 3em;
               }
             `]
})
export class FlowCellTitleComponent {

  @Input()
  flowCell: FlowCell;

  constructor(public readonly store: Store<AppState>) {
  }

  updateFlowCellId(newFlowCellId: string) {
    this.store.dispatch(updateFlowCellField({
      flowCellId: this.flowCell.flowCellId,
      field: 'flowCellId',
      value: newFlowCellId.trim()
    }));
  }
}
