import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { changeShowDetail, selectShowDetail, selectRequests, searchAllRequests, selectAllCros } from '../../store';
import { ActivatedRoute } from '@angular/router';
import { NRequestSummary } from '../../models/genomics-request';
import { Cro } from '../../models/cro-request.model';

@Component({
  selector: 'nemo-request-search-result',
  templateUrl: './request-search-result.component.html',
  styleUrls: ['./request-search-result.component.scss']
})
export class RequestSearchResultComponent implements OnInit, OnDestroy {

  paramSubscription: Subscription;

  genomicsRequests$: Observable<NRequestSummary[]>;
  showDetailedTable$: Observable<Boolean>;
  cros$: Observable<Cro[]>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.genomicsRequests$ = this.store.select(selectRequests);
    this.showDetailedTable$ = this.store.select(selectShowDetail);
    this.cros$ = this.store.select(selectAllCros);

    this.paramSubscription = this.route.params.subscribe(params => {
      this.store.dispatch(searchAllRequests({
        requesterId: params['requester'],
        assignedTo: params['assigned_to'],
        q: params['q']
      }));
    });
  }

  changeShowDetail() {
    this.store.dispatch(changeShowDetail());
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }
}
