import { Page, PageInfo } from '../models/page.model';

export function toPageInfo(page: Page<any>): PageInfo {
  return {
    empty: page.empty,
    first: page.first,
    last: page.last,
    number: page.number,
    numberOfElements: page.numberOfElements,
    size: page.size,
    totalElements: page.totalElements,
    totalPages: page.totalPages,
  };
}
