import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { JSON_CONTENT } from '../../constants/http';
import { SampleFileDto } from '../models/plate-file-dto.model';
import { SampleFileValidation } from '../models/plate-file-validation.model';
import { NRequest, NRequestSummary } from '../models/genomics-request';
import {
  CreateRequestDTO,
  RequestDetailedDTO,
  RequestSummaryDTO,
  UpdateRequestDTO,
} from '../models/genomics-request.dto';
import { requestDetailedDTOToRequest, requestSummaryDTOToRequestSummary } from './request.mapper';
import { User } from '../../user/models/user.model';
import { Page } from '../../shared/models/page.model';
import { Cro } from '../models/cro-request.model';

export const GENOMICS_REQUEST_BASE_URI = environment.apiUrl + '/genomics/requests';
export const CRO_BASE_URI = environment.apiUrl + '/cros';

function convertRequestId(input: string): string {
    // NEMO-200, for old naming convention(before middle of 2023) ,
    // when users input something like "REQ_4GE" or "REQ_4GE6", we want to remove "_" to align with it's internal expression REQ4GE6
    if (/^REQ_[a-z0-9]{3,}$/i.test(input)) {
	return input.replace(/_/g, "");
    }
    return input;
}

@Injectable({
  providedIn: 'root',
})
export class RequestService {

  constructor(private readonly http: HttpClient) {
  }

  search(research: string): Observable<Page<NRequestSummary>> {
    const queryStr = convertRequestId(research);
    const uri = GENOMICS_REQUEST_BASE_URI + '?q=' + queryStr;
      return this.http.get<Page<RequestSummaryDTO>>(uri)
	.pipe(map((dtoPage: Page<RequestSummaryDTO>) => {
            const content = dtoPage.content.map(requestSummaryDTOToRequestSummary);
            return {...dtoPage, content};
        }))
  }

  findAll(pageSize: number, page: number, requesterId?: string, assignedTo?: string, q?: string, hideArchived?: boolean): Observable<Page<NRequestSummary>> {
    const queryParams = {
      page: '' + page,
      size: '' + pageSize,
    };

    if (q && q.length > 0) {
      return this.search(q);
    }
   if (requesterId) {
      queryParams['requester'] = requesterId;
    }
    if (assignedTo) {
      queryParams['assigned_to'] = assignedTo;
    }
    queryParams['hide_archived'] = hideArchived !== null && hideArchived !== undefined ? hideArchived : false;

    return this.http.get<Page<RequestSummaryDTO>>(GENOMICS_REQUEST_BASE_URI, {params: queryParams})
	.pipe(map((dtoPage: Page<RequestSummaryDTO>) => {
            const content = dtoPage.content.map(requestSummaryDTOToRequestSummary);
            return {...dtoPage, content};
        })
      );
  }

  updateRequest(request: UpdateRequestDTO): Observable<NRequest> {
    return this.http.put<RequestDetailedDTO>(GENOMICS_REQUEST_BASE_URI, request, {headers: JSON_CONTENT}).pipe(
      map(requestDetailedDTOToRequest)
    );
  }

  patchOneField(accessionCode: string, field: string, value: string): Observable<NRequest> {
    return this.http.patch<RequestDetailedDTO>(GENOMICS_REQUEST_BASE_URI + '/' + accessionCode, {[field]: value})
      .pipe(
        map(requestDetailedDTOToRequest)
      );
  }

  findByAccessionCode(accessionCode: string): Observable<NRequest> {
    const queryParams = {};
    queryParams['hide_archived'] = false;
    return this.http
      .get<RequestDetailedDTO>(GENOMICS_REQUEST_BASE_URI + '/' + accessionCode, {params: queryParams})
      .pipe(
        map(requestDetailedDTOToRequest)
      );
  }

  submitRequest(request: CreateRequestDTO): Observable<RequestDetailedDTO> {
    return this.http.post<RequestDetailedDTO>(GENOMICS_REQUEST_BASE_URI, request, {headers: JSON_CONTENT});
  }

  validateSampleFile(sampleFile: SampleFileDto, indexHeaderValidation: number): Observable<SampleFileValidation> {
    const params = new HttpParams().append('index_header', indexHeaderValidation.toString());
    const url = `${GENOMICS_REQUEST_BASE_URI}/validate/sample`;
    return this.http.post<SampleFileValidation>(url, sampleFile, {headers: JSON_CONTENT, params});
  }

  assignUser(request: NRequest, user: User): Observable<NRequest> {
    const url = `${GENOMICS_REQUEST_BASE_URI}/${request.accessionCode}/users/${user.id}`;
    return this.http.put<RequestDetailedDTO>(url, {})
      .pipe(
        map(requestDetailedDTOToRequest)
      );
  }

  unassignUser(request: NRequest, user: User): Observable<NRequest> {
    const url = `${GENOMICS_REQUEST_BASE_URI}/${request.accessionCode}/users/${user.id}`;
    return this.http.delete<RequestDetailedDTO>(url)
      .pipe(
        map(requestDetailedDTOToRequest)
      );
  }

  completeStage(accessionCode: string, stage: string) {
    const url = `${GENOMICS_REQUEST_BASE_URI}/${accessionCode}/complete`;
    const queryParams = {};
    queryParams['stage'] = stage;
    return this.http.post<RequestDetailedDTO>(url, {}, { params: queryParams }).pipe(
      map(requestDetailedDTOToRequest)
    );
  }

  reopenStage(accessionCode: string, stage: string) {
    const url = `${GENOMICS_REQUEST_BASE_URI}/${accessionCode}/complete`;
    const queryParams = {};
    queryParams['stage'] = stage;
    return this.http.delete<RequestDetailedDTO>(url, { params: queryParams }).pipe(
      map(requestDetailedDTOToRequest)
    );
  }

  archive(accessionCode: string): Observable<void> {
    return this.http.put<void>(GENOMICS_REQUEST_BASE_URI + '/' + accessionCode + '/archive', null);
  }

  restore(accessionCode: string): Observable<void> {
    return this.http.put<void>(GENOMICS_REQUEST_BASE_URI + '/' + accessionCode + '/restore', null);
  }

  findAllCros(): Observable<Cro[]> {
    return this.http.get<Cro[]>(CRO_BASE_URI).pipe(
      map(cros => cros.slice().sort((a, b) => a.name.localeCompare(b.name))),
      map(cros => [{ id: null, name: 'In-house' }, ...cros ])
    );
  }

  assignCro(accessionCode: string, croId: string | null): Observable<NRequest> {
    return this.http.put<RequestDetailedDTO>(GENOMICS_REQUEST_BASE_URI + '/' + accessionCode + '/assignToCro', { croId })
      .pipe(map(requestDetailedDTOToRequest));
  }
}
