import { SamplePlateDto } from './genomics-request.dto';
import { PoolSampleMixDto } from '../../bio/dto/bio.dto';

/**
 * What is returned by the server after a call to validate the plate file
 */
export interface SampleFileValidation {
  plate?: SamplePlateDto;
  pools: PoolSampleMixDto[];
  metadataHeaders: String[];
}

export enum SampleFileIndexHeaderEnum {
  NO_INDEX,
  I7_ONLY,
  I7_AND_I5,
  INDEX_ID
}
