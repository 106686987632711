import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Form, FormStructure } from '../models/form';
import { FormDto, FormStructureDto } from '../dtos/form.dto';
import { dtoToForm, dtoToFormStructure } from './form.mapper';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormTypeWithSections } from '../models/form-type';

export const FORM_BASE_URI = environment.apiUrl + '/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  loadByTypeName(typeName: string): Observable<Form> {
    return this.httpClient.get<FormDto>(
      `${FORM_BASE_URI}/${typeName}/latest`
    ).pipe(
      map(dtoToForm)
    );
  }

  loadById(id: number): Observable<Form> {
    return this.httpClient.get<FormDto>(
      `${FORM_BASE_URI}/${id}`
    ).pipe(
      map(dtoToForm)
    );
  }

  loadSectionsForLab(lab: string): Observable<FormTypeWithSections[]> {
    const params = {};
    params['lab'] = lab;
    return this.httpClient.get<FormTypeWithSections[]>(
      `${FORM_BASE_URI}/sections`,
      {params}
    );
  }

  loadLatestYaml(typeName: string): Observable<string> {
    return this.httpClient.get(
      `${FORM_BASE_URI}/${typeName}/latest`,
      {
        params: {format: 'yaml'},
        headers: {},
        responseType: 'text'
      }
    );
  }

  validateYaml(yaml: string): Observable<FormStructure> {
    return this.httpClient.post<FormStructureDto>(
      `${FORM_BASE_URI}/validate`,
      yaml
    ).pipe(
      map(dtoToFormStructure)
    );
  }

  save(typeName: string, yaml: string): Observable<Form> {
    return this.httpClient.post<FormDto>(
      `${FORM_BASE_URI}`,
      {typeName, yaml}
    ).pipe(
      map(dtoToForm)
    );
  }
}
