import * as _ from 'lodash';

import { isCoordinatesStringValid, parseCoordinates, PlateCoordinates } from './plate-coordinates';
import PlateDimensions from './plate-dimensions';
import { isIntervalStringValid, parseInterval, PlateCoordinatesInterval } from './plate-coordinates-interval';

function initMatricePlate384() {
  const dimensions = new PlateDimensions(16, 24);
  return new Array(384)
    .fill(1)
    .map((value, index) => {
      const row = Math.trunc(index % dimensions.numberOfRows);
      const column = Math.trunc(index / dimensions.numberOfRows);
      const isColumnPair = Math.trunc(column % 2) === 0;
      const isRowPair = Math.trunc(row % 2) === 0;
      if (isColumnPair && isRowPair) {
        return 1;
      } else if (isColumnPair && !isRowPair) {
        return 2;
      } else if (!isColumnPair && isRowPair) {
        return 3;
      } else {
        return 4;
      }
    });
}

const matrice384 = initMatricePlate384();

/**
 * contain a selection of bioMaterialPlateMappings on a plate.
 * Selection/unselection are stated via a position (PlateCoordinates) or an interval (PlateCoordinatesInterval)
 * All modifications (selection/unselection create a new object, in order to be state friendly
 *
 * toString/parse is producing a format such as
 * A1-B4,C4,D5-D6
 */
export class PlateCoordinatesSelection {
  private _selectedCoordinates: boolean[];

  constructor(
    public readonly dimension: PlateDimensions,
    selectedCoordinates: boolean[] | null = null
  ) {
    this.initSelection(selectedCoordinates, dimension);
  }

  // either initialize with false values or make a copy of the passed bioMaterialPlateMappings parameter
  private initSelection(selectedCoordinates: boolean[] | null, dimension: PlateDimensions) {
    if (selectedCoordinates === null) {
      this._selectedCoordinates = [];
      for (let i = 0; i < dimension.size(); i++) {
        this._selectedCoordinates[i] = false;
      }
    } else {
      // if plate 384, not all selected wells should really be selected
      if (dimension.numberOfColumns === 24 && dimension.numberOfRows === 16) {
        let value = null;
        selectedCoordinates = selectedCoordinates.map((isNotEmpty, index) => {
          if (value === null && isNotEmpty) {
            value = matrice384[index];
          }
          return matrice384[index] === value && isNotEmpty;
        });
      }
      this._selectedCoordinates = [...selectedCoordinates];
    }
  }

  public fromCoordinatesList(coords: PlateCoordinates[]): PlateCoordinatesSelection {
    return coords.reduce((selection, c) => selection.select(c),
      new PlateCoordinatesSelection(this.dimension));
  }

  /**
   * clear all the bioMaterialPlateMappings
   * @return another PlateCoordinatesSelection object
   */
  clearSelection(): PlateCoordinatesSelection {
    return new PlateCoordinatesSelection(this.dimension);
  }

  /**
   * select coordinates at a given position or interval of positions
   * @param pos PlateCoordinates | PlateCoordinatesInterval
   * @return another PlateCoordinatesSelection object
   */
  public select(pos: PlateCoordinates | PlateCoordinatesInterval): PlateCoordinatesSelection {
    if (pos instanceof PlateCoordinates) {
      return this.selectAt(pos);
    } else {
      return this.selectRange(pos.from, pos.to);
    }
  }

  /**
   * unselect coordinates at a given position or interval of positions
   * @param pos PlateCoordinates | PlateCoordinatesInterval
   * @return another PlateCoordinatesSelection object
   */
  public unselect(pos: PlateCoordinates | PlateCoordinatesInterval): PlateCoordinatesSelection {
    if (pos instanceof PlateCoordinates) {
      return this.unselectAt(pos);
    } else {
      return this.unselectRange(pos.from, pos.to);
    }
  }

  public countSelected(): number {
    return _.chain(this._selectedCoordinates)
      .filter()
      .size()
      .value();
  }

  public isEmpty(): boolean {
    return _.find(this._selectedCoordinates) === undefined;
  }

  public listSelectedCoordinates(): PlateCoordinates[] {
    const coords = [];
    this._selectedCoordinates.forEach((isSelected, i) => {
      if (isSelected) {
        coords[coords.length] = this.idx2coords(i);
      }
    });

    return coords;
  }

  /**
   * return the selection in the form of
   *  * ''
   *  * 'A7,B2;
   *  * 'A7,B2-B7'
   *  * 'A7,B2-D1,F3'
   */
  toString(): string {
    // let's collect the continuous selection inteval
    const intervals = [];
    let iInterv = -1;
    let withInterv = false;
    this._selectedCoordinates.forEach((isSelected, i) => {
      if (!isSelected) {
        if (withInterv) {
          iInterv = -1;
        }
        withInterv = false;
        return;
      }
      if (withInterv) {
        // running into a continuous interval, so update the bound
        intervals[iInterv].to = i;
        return;
      }

      // new continuous interval
      // add another block
      withInterv = true;
      iInterv = intervals.length;
      intervals[iInterv] = {from: i, to: i};
    });

    let buf = '';
    intervals.forEach((interv, i) => {
      if (i > 0) {
        buf += ',';
      }
      if (interv.from === interv.to) {
        buf += this.idx2coords(interv.from).toString();
      } else {
        buf += this.idx2coords(interv.from).toString() + '-' + this.idx2coords(interv.to).toString();
      }
    });
    return buf;
  }

  private coords2idx(coords: PlateCoordinates): number {
    return coords.column * this.dimension.numberOfRows + coords.row;
  }

  private idx2coords(linearIndex: number): PlateCoordinates {
    if (linearIndex < 0 || linearIndex >= this.dimension.numberOfRows * this.dimension.numberOfColumns) {
      throw new Error('index ' + linearIndex + ' out of bounds ' + this.dimension.numberOfColumns + ' x ' + this.dimension.numberOfRows);
    }

    return new PlateCoordinates(linearIndex % this.dimension.numberOfRows, Math.floor(linearIndex / this.dimension.numberOfRows));
  }

  /**
   * set a given coordinates to be selected
   * @param coords plateCoordinatesSelection column, row position
   */
  private selectAt(coords: PlateCoordinates): PlateCoordinatesSelection {
    return this.setSelectAt(coords, true);
  }

  /**
   * set a given coordinate NOT to be selected
   * @param coords plateCoordinatesSelection column, row position
   */
  private unselectAt(coords: PlateCoordinates) {
    return this.setSelectAt(coords, false);
  }

  private setSelectAt(coords: PlateCoordinates, isSelected: boolean): PlateCoordinatesSelection {
    const selected = [...this._selectedCoordinates];
    selected[this.coords2idx(coords)] = isSelected;
    return new PlateCoordinatesSelection(
      this.dimension,
      selected
    );
  }

  /**
   * select a range of bioMaterialPlateMappings ,the two boundaries being included
   * @param from the starting position
   * @param to the last position
   */
  private selectRange(from: PlateCoordinates, to: PlateCoordinates): PlateCoordinatesSelection {
    return this.setSelectRange(from, to, true);
  }

  /**
   * unselect a range of bioMaterialPlateMappings, the two boundaries being included
   * @param from selection start
   * @param to selection last position
   */
  private unselectRange(from: PlateCoordinates, to: PlateCoordinates): PlateCoordinatesSelection {
    return this.setSelectRange(from, to, false);
  }

  private setSelectRange(from: PlateCoordinates, to: PlateCoordinates, value: boolean): PlateCoordinatesSelection {
    const selected = [...this._selectedCoordinates];

    for (let i = this.coords2idx(from); i <= this.coords2idx(to); i++) {
      selected[i] = value;
    }

    return new PlateCoordinatesSelection(
      this.dimension,
      selected
    );
  }

  /**
   * check if a coordinate at a given coordinated is selected
   * @param coords column, row position
   */
  isSelectedAt(coords: PlateCoordinates): boolean {
    // !! operator is to cast to boolean
    return !!this._selectedCoordinates[this.coords2idx(coords)];
  }


}

/**
 * @param coords the raw,column coordinates
 * @param dim the overlay dimensions
 * @return true if the value is within the given dimensions
 */
export function isCoordinatesWithinBound(coords: PlateCoordinates, dim: PlateDimensions): boolean {
  return coords.row >= 0 &&
    coords.row < dim.numberOfRows &&
    coords.column >= 0 &&
    coords.column < dim.numberOfColumns;
}

/**
 * check the validity of a selection string, (such as ' A4-B5,D7,A11-F11'
 * we can also check for values being within the dimensions boundaries
 */
export function isSelectionStringValid(str: String, dim: PlateDimensions): boolean {
  return str.split(',').every((s) => {
    if (!(isCoordinatesStringValid(s) || isIntervalStringValid(s))) {
      return false;
    }
    // if it is a coordinate, does it lie within the dimensions?
    if (isCoordinatesStringValid(s)) {
      const coords = parseCoordinates(s);
      return isCoordinatesWithinBound(coords, dim);
    }
    // then it's an interval, do both interval ends lie within the dimensions?
    const pwi = parseInterval(s);
    return isCoordinatesWithinBound(pwi.from, dim) && isCoordinatesWithinBound(pwi.to, dim);
  });
}


/**
 * apply the reverse of toString() (modulo some equivalent strings)
 * @param str: string -  like ('A2-F3,D6,A11-H11')
 * @param dim: PlateDimensions -  we need the dimensions to infer the plate overall selection scope
 */
export function parseSelection(str: String, dim: PlateDimensions): PlateCoordinatesSelection {
  if (!dim) {
    throw new Error('must provide a PlateDimensions as a second parameter to parse');
  }

  if (!isSelectionStringValid(str, dim)) {
    throw new Error(`Cannot parse selection from "${str}"`);
  }

  return str.split(',')
    .map((s) => isCoordinatesStringValid(s) ? parseCoordinates(s) : parseInterval(s))
    .reduce(
      (acc: PlateCoordinatesSelection, pos: PlateCoordinates | PlateCoordinatesInterval) => acc.select(pos),
      new PlateCoordinatesSelection(dim)
    );
}
