import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphDependencyComponent } from './components/graph-dependency/graph-dependency.component';
import { GraphDependencyContainerComponent } from './components/graph-dependency/graph-dependency-container.component';
import { GlobalContextSharedModule } from '../../global-context/shared/global-context-shared/global-context-shared.module';


@NgModule({
  declarations: [GraphDependencyComponent, GraphDependencyContainerComponent],
  exports: [
    GraphDependencyContainerComponent
  ],
  imports: [
    CommonModule,
    GlobalContextSharedModule
  ]
})
export class GraphSharedModule {
}
