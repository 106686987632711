import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PoolCounts } from '../../bio/dto/bio.dto';
import {  Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import * as fromPool from '../../bio/store';
import { PlateCounts } from '../../labware/dto/plate.dto';
import { getPlateCounts } from '../../labware/store/plates';
import { TaskAvailable } from '../../tasks/models/task.model';
import { getQCTodoCounts } from '../../qc/store/qc.selectors';

@Component({
  selector: 'nemo-todo-counters-boxes',
  template: `
    <nemo-todo-card id="qc-measures"
                    title="QC measures ({{(qcResultsCounts$ | async)}})"></nemo-todo-card>

    <nemo-todo-card title="Normalization (N plates)" [available]="false"></nemo-todo-card>

    <nemo-todo-card id="index-assignment"
                    title="Index Assignment ({{(IAPlateCounts$ | async)?.totalPlateCount}} plates)"
                    [requestCount]="(IAPlateCounts$ | async)?.totalRequestCount"
                    [sampleCount]="(IAPlateCounts$ | async)?.totalSampleCount"></nemo-todo-card>

    <nemo-todo-card id="echo-pooling"
                    title="Echo pooling ({{(EchoPlateCounts$ | async)?.totalPlateCount}} plates)"
                    [requestCount]="(EchoPlateCounts$ | async)?.totalRequestCount"
                    [sampleCount]="(EchoPlateCounts$ | async)?.totalSampleCount"></nemo-todo-card>

    <nemo-todo-card id="lane-assignment"
                    title="Lane Assignment ({{(poolCounts$ | async)?.totalPoolCount}} pools)"
                    [requestCount]="(poolCounts$ | async)?.totalRequestCount"
                    [sampleCount]="(poolCounts$ | async)?.totalSampleCount"></nemo-todo-card>

    <nemo-todo-card title="Sequencing (N flow cells)" [available]="false"></nemo-todo-card>
  `,
  styles: [`
    :host {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  `]
})
export class TodoCountersBoxesComponent implements OnInit {

  poolCounts$: Observable<PoolCounts>;
  IAPlateCounts$: Observable<PlateCounts>;
  EchoPlateCounts$: Observable<PlateCounts>;
  qcResultsCounts$: Observable<number>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.poolCounts$ = this.store.select((fromPool.getPoolCounts));
    this.IAPlateCounts$ = this.store.select((getPlateCounts(TaskAvailable.INDEX_ASSIGNMENT)));
    this.EchoPlateCounts$ = this.store.select((getPlateCounts(TaskAvailable.ECHO_POOLING_FROM)));
    this.qcResultsCounts$ = this.store.select((getQCTodoCounts));
  }
}
