import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { selectPoolColor } from '../../../store';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'nemo-pool',
  templateUrl: './pool.component.html',
  styleUrls: ['./pool.component.scss', '../../../../shared/styles/commons.scss']
})
export class PoolComponent implements OnInit {
  @Input() poolAccessionCode: string;
  @Input() poolNumberOfSamples: number;
  @Input() poolTitle = '';


  @Input() requestLabel?: string;
  requestClass: string;
  @Input() isIcon = false;

  @Input() height = '100%';
  @Input() width = '100%';

  @Input() showOpenLink = false;

  rndX: Array<number>;
  indexBeadRadius: number;
  color: string;

  constructor(
    public readonly store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.store.select(selectPoolColor(this.poolAccessionCode)).pipe(take(1), map(color => this.color = color));

    this.requestClass = 'request-icon';
    if (this.requestLabel != null) {
      this.requestClass += ` request-label-${this.requestLabel}`;
    }

    this.rndX = _.range(0, this.poolNumberOfSamples).map(() => 1 - 2 * Math.random());

    if (this.poolNumberOfSamples === 0) {
      this.indexBeadRadius = 1;
    } else {
      this.indexBeadRadius = 0.05 / (1 + Math.log10(this.poolNumberOfSamples));
    }
  }
}
