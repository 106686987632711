import { parseCoordinates, PlateCoordinates } from './plate-coordinates';

/**
 * A continuous interval between two bounds
 * The class come with toString/string parsing function
 *
 * example"A1-B7"
 *
 */
export class PlateCoordinatesInterval {
  public from: PlateCoordinates;
  public to: PlateCoordinates;

  /**
   * build an interval between two coordinates
   * it from is after, then the order is corrected
   *
   * @param from start position
   * @param to last position
   */
  constructor(
    from: PlateCoordinates,
    to: PlateCoordinates
  ) {
    if (from.before(to)) {
      this.from = from;
      this.to = to;
    } else {
      this.from = to;
      this.to = from;
    }
  }

  equalsTo(other: PlateCoordinatesInterval) {
    return this.from.equalsTo(other.from) && this.to.equalsTo(other.to);
  }

  toString(): string {
    return `${this.from.toString()}-${this.to.toString()}`;
  }
}

const reParsing = /^\s*([A-Ha-h]\s*[\d]+)\s*\-\s*([A-Ha-h]\s*[\d]+)\s*$/;

export function isIntervalStringValid(str: String): boolean {
  return !!str.match(reParsing);
}

export function parseInterval(str: String): PlateCoordinatesInterval {
  const m = str.match(reParsing);
  if (!m) {
    throw new Error(`Cannot parse interval from "${str}"`);
  }
  const from = parseCoordinates(m[1]);
  const to = parseCoordinates(m[2]);
  return new PlateCoordinatesInterval(from, to);
}

