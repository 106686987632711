import { Component, Input, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { getPools } from '../../../store';
import { Observable } from 'rxjs';
import { SimplePoolWithRequest } from '../../../models/pool';
import { CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import { archivePool, unarchivePool } from '../../../store/actions';

@Component({
  selector: 'nemo-pool-list',
  templateUrl: './pool-list.component.html',
  styleUrls: ['./pool-list.component.scss']
})
export class PoolListComponent implements OnInit {
  @Input() searchBar = true;

  pools$: Observable<SimplePoolWithRequest[]>;

  defaultDisplayedColumns: string[] = ['icon', 'accessionCode', 'size', 'date', 'requests', 'archive'];

  @Input() draggingEnabled = false;
  @Input() cdkDragEnteredCallback: (e: CdkDragEnter<any>) => void;
  @Input() cdkDragExitedCallback: (e: CdkDragExit<any>) => void;
  @Input() displayedColumns = this.defaultDisplayedColumns;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.pools$ = this.store.select((getPools));
  }

  onSwitchButtonClick(accessionCode: string, archive: boolean) {
    if (archive) {
      this.store.dispatch(archivePool({accessionCode}));
    } else {
      this.store.dispatch(unarchivePool({accessionCode}));
    }
  }
}
