import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { processEchoPooling } from '../../../labware/store/plates/actions/plate-list.action';
import { getPlateByIdentifier } from '../../../labware/store/plates';
import { Observable } from 'rxjs';
import { ECHO_POOLING_DESTINATION_PLATE_ID } from './echo-pooling-left-zone.component';
import { Plate96 } from '../../../labware/models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { map, take } from 'rxjs/operators';
import { TaskAvailable } from '../../models/task.model';

@Component({
  selector: 'nemo-echo-pooling-title',
  template: `
    <div class="title">
      <h2>Echo pooling</h2>
      <button *ngIf="accessionCode$ | async"
              mat-raised-button
              color="primary"
              [disabled]="disabled"
              (click)="processEchoPooling()">
        Process echo pooling
      </button>
    </div>
  `,
  styles: [`
    .title {
      border-bottom: 1px solid #dadce0;
      height: 30px;
      margin: 15px 0 0 0;
      display: flex;
      justify-content: space-between;
    }

    .title h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #555;
      line-height: 24px;
      margin: 0;
    }

    .title button {
      margin: -9px 10px 3px 0;
    }
  `]
})
export class EchoPoolingTitleComponent implements OnInit {

  disabled = false;
  accessionCode$: Observable<string>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.accessionCode$ = this.store.select(getPlateByIdentifier(ECHO_POOLING_DESTINATION_PLATE_ID)).pipe(
      
      map((plate: Plate96<BioMaterial>) => {
        if (plate && plate.availableForTasks.includes(TaskAvailable.ECHO_POOLING_TO)) {
          return plate.accessionCode;
        }
      })
    );
  }

  processEchoPooling() {
    this.disabled = true;
    this.accessionCode$.pipe(take(1)).subscribe(accessionCode =>
      this.store.dispatch(processEchoPooling({accessionCode}))
    );
  }
}
