import { createAction, props } from '@ngrx/store';
import EntityPage from '../../global-context/models/entity-page';
import { CommentEntity } from '../models/commentText';
import { FileDto } from '../../shared/models/file-dto';


export const findAllCommentsByEntity = createAction(
  '[Comment Thread] find all comments by entity',
  props<{ entityPage: EntityPage }>()
);

export const findAllCommentsByEntitySuccess = createAction(
  '[Comment API] find all comments by entity success',
  props<{ comments: CommentEntity[] }>()
);

export const findAllCommentsByEntityError = createAction(
  '[Comment API] find all comments by entity error',
  props<{ message: string }>()
);

export const actionNewComment = createAction(
  '[Comment Thread] new Comment',
  props<{ entityPage: EntityPage, content: string }>()
);

export const actionCommentPostedSuccess = createAction(
  '[Comment API] Comment posted success',
  props<{ comment: CommentEntity }>()
);

export const actionCommentPostedError = createAction(
  '[Comment API] Comment posted error',
  props<{ message: string }>()
);

export const actionAddCommentFile = createAction(
  '[Comment Thread] new file',
  props<{ entityPage: EntityPage, file: FileDto }>()
);

export const actionFileAddedSuccess = createAction(
  '[Comment API] File added success',
  props<{ comment: CommentEntity }>()
);

export const actionFileAddedError = createAction(
  '[Comment API] Add file error',
  props<{ message: string }>()
);

export type CommentActions = ReturnType<typeof actionNewComment
  | typeof actionCommentPostedSuccess
  | typeof actionCommentPostedError
  | typeof actionFileAddedSuccess
  | typeof actionFileAddedError
  | typeof findAllCommentsByEntity
  | typeof findAllCommentsByEntitySuccess
  | typeof findAllCommentsByEntityError>;
