import { Pipe, PipeTransform } from '@angular/core';

export function formatAccessionCode(accessionCode: string): string {
  if (!Boolean(accessionCode)) {
    return '--';
  }
  if (accessionCode.startsWith('REQ_') || accessionCode.startsWith('PLT_') || accessionCode.startsWith('POL_')) {
    return accessionCode;
  }
  const trimmed = trimAccessionCode(accessionCode);
  const prefix = trimmed.substr(0, 3);
  let suffix = trimmed.substr(3);
  if (suffix.length % 4 > 0) {
    suffix = '0'.repeat(4 - (suffix.length % 4)) + suffix;
  }
  let ret = prefix;
  for (let i = 0; i < suffix.length; i += 4) {
    ret += '_' + suffix.substr(i, 4);
  }
  return ret;
}


export function trimAccessionCode(accessionCodeString: string): string {
  if (!Boolean(accessionCodeString) || accessionCodeString.trim() === '<not set>') {
    return '';
  }
  if (accessionCodeString.startsWith('REQ_') || accessionCodeString.startsWith('PLT_') || accessionCodeString.startsWith('POL_')) {
    return accessionCodeString;
  }

  const acTrimmed = accessionCodeString.trim().toUpperCase();
  if (!acTrimmed.match(/^[A-Z][A-Z][A-Z]/)) {
    return accessionCodeString;
  }
  const prefix = acTrimmed.substring(0, 3);

  const suffix = acTrimmed.substring(3)
    .trim()
    .replace(/_/g, '')
    .replace(/^0+/, '');

  return `${prefix}${suffix}`;
}


@Pipe({
  name: 'accessionCode'
})
export class AccessionCodePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return formatAccessionCode(value);
  }

}
