import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { FormRequestSummary } from '../../models/form-request';
import { selectFormRequests, selectIsFormRequestStorePending } from '../../store/form-request/form-request.selectors';
import { actionFindAllFormRequests } from '../../store/form-request';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';

@Component({
  selector: 'nemo-form-request-list-page',
  template: `
    <nemo-pending-title title="Form requests" [pending]="pending$ | async"></nemo-pending-title>
    <nemo-form-request-list [formRequests]="formRequests$ | async"
                            [enableArchive]="true"
                            [showAssignees]="true"
    ></nemo-form-request-list>
  `,
  styles: [`

           `]
})
export class FormRequestListPageComponent extends HasGlobalContextUserRole implements OnInit {

  formRequests$: Observable<FormRequestSummary[]>;
  pending$: Observable<boolean>;

  constructor(
    private store$: Store<AppState>,
    private titleService: Title
  ) {
    super(store$);
  }

  ngOnInit() {
    this.titleService.setTitle(`Nemo form request list`);
    this.formRequests$ = this.store$.select((selectFormRequests));
    this.pending$ = this.store$.select((selectIsFormRequestStorePending));
    this.store$.dispatch(actionFindAllFormRequests());
  }

}
