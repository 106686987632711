import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { isGenomicsRequestPending } from '../../../genomics-request/store';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';

@Component({
  selector: 'nemo-home-title',
  template: `
    <nemo-lab-presentation></nemo-lab-presentation>

    <nemo-change-context></nemo-change-context>

    <div *ngIf="hasLab$() | async">
      <div *ngIf="isLab$('genomics') | async" class="new-button-line">
        <button
          mat-button
          class="new-button"
          [routerLink]="'/new-request'">Submit a new request
        </button>
      </div>
    </div>
  `,
  styleUrls: [
    './home-title.component.scss',
    './home-title-new-buttons.component.scss',
  ]
})
export class HomeTitleComponent extends HasGlobalContextUserRole implements OnInit {

  pending$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.pending$ = this.store.select((isGenomicsRequestPending));
  }
}
