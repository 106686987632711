import * as _ from 'lodash';
import * as d3 from 'd3';

/**
 * a color scale from object of class T towards a predefined list of colors
 */
export class ColorScale<T> {

  constructor(
    private mapped: { [key: string]: string } = {},
    public colorTotalRange: string[] = Array.from(d3.schemeSet1),
    private keyMapper: ((t: T) => string) = (t) => t.toString()
  ) {
  }

  setKeyMapper(f: (t: T) => string): ColorScale<T> {
    return new ColorScale<T>(this.mapped, this.colorTotalRange, f);
  }

  /**
   * get the color associated to one object
   */
  get(t: T): string {
    return this.mapped[this.keyMapper(t)];
  }


  /**
   * get the map of all object key to their color
   */
  map(): { [key: string]: string } {
    return {...this.mapped};
  }


  /**
   * update the list of objects to be mapped to colors
   * * already attributes object will keep their colors
   * * object which have disappear wil release their color
   * * the next available colors is attribute to new ones
   */
  updateRange(ts: T[]) {

    const newKeys = {};
    ts.forEach((t) => newKeys[this.keyMapper(t)] = true);

    // previously attributed colors
    const prevMap = {};
    ts.map(this.keyMapper)
      .filter((k) => this.mapped[k] !== undefined)
      .forEach((k) => prevMap[k] = this.mapped[k]);

    const newObj = ts.filter((t) => this.get(t) === undefined);

    return new ColorScale(
      prevMap,
      this.colorTotalRange,
      this.keyMapper
    ).add(newObj);
  }

  reset(): ColorScale<T> {
    return this.updateRange([]);
  }

  /**
   * add a object or a list in the domain
   */
  add(ts: T | T[]): ColorScale<T> {
    if (_.isArray(ts)) {
      return _.reduce(ts, (acc, t1) => acc.add(t1), this);
    }

    const t = ts as T;
    if (this.get(t) !== undefined) {
      return this;
    }
    const col = this.nextUnusedColor();
    return new ColorScale<T>(
      {...this.mapped, [this.keyMapper(t)]: col},
      this.colorTotalRange,
      this.keyMapper,
    );
  }

  /**
   * remove a object or a list from the domain
   */
  remove(ts: T | T[]): ColorScale<T> {
    if (_.isArray(ts)) {
      return _.reduce(ts, (acc, t1) => acc.remove(t1), this);
    }

    const t = ts as T;
    if (this.get(t) === undefined) {
      return this;
    }

    const newMap = {...this.mapped};
    delete newMap[this.keyMapper(t)];
    return new ColorScale<T>(
      newMap,
      this.colorTotalRange,
      this.keyMapper,
    );
  }


  domain(): string[] {
    return _.keys(this.mapped);
  }

  range(): string[] {
    return _.values(this.mapped);
  }


  nextUnusedColor(): string {
    const col = _.chain(this.colorTotalRange)
      .difference(this.range())
      .first()
      .value();

    if (!col) {
      throw new Error(`ran out of colors ${this.mapped} / ${this.colorTotalRange}`);
    }
    return col;
  }

}
