import { InvalidPlateQCInstrumentDirectoryError } from '../models/invalid-plate-qc-instrument-directory-error';
import { InvalidPlateQCInstrumentDirectoryErrorDto } from '../dtos/invalid-plate-qc-instrument-directory-error.dto';
import { PlateCoordinates } from '../../../labware/models/plate-coordinates';

export function invalidPlateQCInstrumentDirectoryErrorFromDto(
  dto: InvalidPlateQCInstrumentDirectoryErrorDto
): InvalidPlateQCInstrumentDirectoryError {
  return new InvalidPlateQCInstrumentDirectoryError(
    dto.plateAC,
    dto.instrumentDirectoryAC,
    dto.unassignedInstrumentDirectoryCoordinates.map(p => new PlateCoordinates(p.row, p.column)),
    dto.unassignedPlateCoordinates.map(p => new PlateCoordinates(p.row, p.column))
  );
}
