import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoleSelectorComponent } from './components/change-context/user-role-selector.component';
import { MaterialModule } from '../../../material.module';
import { RequestSharedModule } from '../../../request-shared/request-shared.module';
import {
  EntityRoutePipe,
  EntityRoutesPipe,
  FlowCellRoutePipe,
  PlateRoutePipe,
  RequestRoutePipe
} from '../pipes/entity-routes.pipe';
import { SharedSharedModule } from '../../../shared/shared/shared-shared.module';
import { ChangeContextComponent } from './components/change-context/change-context.component';
import { ContextSwitchButtonComponent } from './components/change-context/context-switch-button.component';
import { LabSelectorComponent } from './components/change-context/lab-selector.component';


@NgModule({
  declarations: [
    UserRoleSelectorComponent,
    LabSelectorComponent,
    EntityRoutesPipe,
    RequestRoutePipe,
    PlateRoutePipe,
    FlowCellRoutePipe,
    EntityRoutePipe,
    ContextSwitchButtonComponent,
    ChangeContextComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RequestSharedModule,
    SharedSharedModule
  ],
  exports: [
    ChangeContextComponent,
  ]
})

export class GlobalContextSharedModule {
}
