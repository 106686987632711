import { CommentDto } from '../dtos/comment-dtos';
import { CommentEntity, CommentFile, CommentTask, CommentText } from '../models/commentText';
import { toTask } from '../../tasks/services/task.mapper';
import { User } from '../../user/models/user.model';

export function dtoToCommentText(dto: CommentDto): CommentText {
  return new CommentText(
    dto.userId,
    dto.createdAt,
    dto.entityAccessionCode,
    dto.content
  );
}

export function dtoToCommentFile(dto: CommentDto): CommentFile {
  return new CommentFile(
    dto.userId,
    dto.createdAt,
    dto.entityAccessionCode,
    dto.filename,
    dto.gcsUrl
  );
}

export function dtoToCommentTask(dto: CommentDto, user: User): CommentTask {
  return new CommentTask(
    dto.userId,
    dto.createdAt,
    dto.entityAccessionCode,
    toTask(dto.task, user)
  );
}

export function dtoToComment(dto: CommentDto, user: User): CommentEntity {
  if (dto.type === 'TEXT') {
    return dtoToCommentText(dto);
  } else if (dto.type === 'FILE') {
    return dtoToCommentFile(dto);
  } else if (dto.type === 'TASK') {
    return dtoToCommentTask(dto, user);
  }
}

export function toComments(comments: CommentDto[], users: User[]): CommentEntity[] {
  const sortedUsers = {};
  users.map((u) => sortedUsers[u.id] = u);

  return comments.map(commentDto =>
    dtoToComment(commentDto, sortedUsers[commentDto.userId])
  );
}
