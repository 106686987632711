import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { Sample } from '../models/sample';
import { TerminusTypes } from '../models/barcode-index';
import { FlexibleColumnDef, FlexiblePlainColumnDef } from '../../table/model/flexible-columm-def';
import { formatIndexSequence } from '../pipes/samples.pipe';
import { PoolSampleMix } from '../models/pool';

@Component({
  selector: 'nemo-tubes-sample-table',
  template: `
    <nemo-flexible-table
        [values]="tubes"
        [columnDefinitions]="columnDefinitions"
    ></nemo-flexible-table>
  `
})
export class TubesSampleTableComponent implements OnInit {
  @Input() tubes: PoolSampleMix[];

  columnDefinitions: FlexibleColumnDef<PoolSampleMix>[] = [];

  basicColumnDefinitions = [
    new FlexiblePlainColumnDef<PoolSampleMix>(
      'tube_id',
      'Tube ID',
      (sample) => Boolean(sample.samples[0].metadata['Tube ID']),
      (sample) => sample.samples[0].metadata['Tube ID'],
      {'flex-grow': 0.5}
    ),
    new FlexiblePlainColumnDef<PoolSampleMix>(
      'sample-name',
      'Sample Name',
      (sample) => Boolean(sample.samples[0].name),
      (sample) => sample.samples[0].name,
      {'flex-grow': 1}
    ),
    new FlexiblePlainColumnDef<PoolSampleMix>(
      'accession-code',
      'Accession Code',
      (sample) => Boolean(sample.samples[0].accessionCode),
      (sample) => sample.samples[0].accessionCode,
      {'flex-grow': 1}
    ),
  ];

  ngOnInit(): void {
    this.columnDefinitions = [
      ...this.basicColumnDefinitions,
      ...this.metadataFlexibleColumnDef()
    ];
  }

  metadataFlexibleColumnDef() {
    return _.chain(this.tubes)
      .flatMap((x: Sample) => _.keys(x.metadata))
      .uniq()
      .map((key: string) =>
        new FlexiblePlainColumnDef<Sample>(
          `metadata_${key}`,
          key,
          () => true,
          (x) => x.metadata[key])
      )
      .value();
  }

}
