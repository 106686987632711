import { createAction, props } from '@ngrx/store';
import { UserRole } from '../models/user-role';
import EntityPage from '../models/entity-page';
import { Lab } from '../../company/models/Lab';

export const actionChangeUserRole = createAction(
  '[Home Page] Change user role',
  props<{ role: UserRole }>()
);

export const actionChangeLab = createAction(
  '[Home Page] Change lab',
  props<{ lab: Lab }>()
);

export const actionSetEntityPage = createAction(
  '[Global Context] set entity Page',
  props<{ entityPage: EntityPage }>()
);

export const actionSetEntityPageToNull = createAction(
  '[Global Context] set entity Page to null'
);

export const actionLoadContextAtInit = createAction(
  '[Init] load context'
);

export type GlobalContextActions = ReturnType<typeof actionChangeUserRole
  | typeof actionSetEntityPage
  | typeof actionSetEntityPageToNull
  | typeof actionLoadContextAtInit>;
