import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Plate96 } from '../../models/plate-96';
import { MatSelect } from '@angular/material/select';
import { Pool } from '../../../bio/models/pool';
import { BioMaterialPlateMapping } from '../../models/bio-material-plate-mapping';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { FlexibleTableContent } from '../../../table/model/flexible-table-content';
import { FlexibleExportGroupDef } from '../../../table/model/flexible-export-group-def';
import {
  additionalQcDataFlexibleColumnDefinitions,
  plateTableFlexibleColumnsDefinition
} from './plate-table-detail/plate-table-flexible-columns-definition';
import { FlexibleColumnDef } from '../../../table/model/flexible-columm-def';

@Component({
  selector: 'nemo-plate-export-button',
  template: `
    <nemo-flexible-export-csv
      [columnDefinitions]="columnDefinitions"
      [exportGroupDefinitions]="groupDefinitions"
      [values]="plate | nonEmptyBioMaterialMappings"
    ></nemo-flexible-export-csv>
  `
})
export class PlateExportButtonComponent implements OnChanges {

  @ViewChild('selectRequester', {static: true}) selectRequester: MatSelect;
  @Input() plate: Plate96<Pool>;

  content: FlexibleTableContent<BioMaterialPlateMapping<Pool>>;
  columnDefinitions: FlexibleColumnDef<BioMaterialPlateMapping<Pool>>[] = plateTableFlexibleColumnsDefinition;

  groupDefinitions: FlexibleExportGroupDef<BioMaterialPlateMapping<Pool>>[] = [
    new FlexibleExportGroupDef<BioMaterialPlateMapping<Pool>>(
      () => ['all'],
      () => true,
      () => 'All requests',
      () => `nemo-plate-${formatAccessionCode(this.plate.accessionCode)}.csv`
    ),
    new FlexibleExportGroupDef<BioMaterialPlateMapping<Pool>>(
      (mapping) => mapping.biomaterial.listRequestAccessionCode(),
      (mapping, ac) => mapping.biomaterial.listRequestAccessionCode().find(x => x === ac) !== undefined,
      (ac) => `Request ${formatAccessionCode(ac)}`,
      (ac) => `nemo-plate-${formatAccessionCode(this.plate.accessionCode)}-${formatAccessionCode(ac)}.csv`
    )
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.plate) {
      this.columnDefinitions = [
        ...plateTableFlexibleColumnsDefinition,
        ...additionalQcDataFlexibleColumnDefinitions(this.plate)
      ];
    }
  }

}

