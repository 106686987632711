import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SequencerModel } from '../models/sequencer-model';
import { CreateSequencerModelDto } from '../dto/create-sequencer-model.dto';

export const SEQUENCER_MODEL_BASE_URI = environment.apiUrl + '/sequencer-models';

@Injectable({
  providedIn: 'root'
})
export class SequencerModelService {

  constructor(private readonly http: HttpClient) {
  }

  findAll(): Observable<SequencerModel[]> {
    return this.http.get<SequencerModel[]>(SEQUENCER_MODEL_BASE_URI);
  }

  create(sequencerModel: CreateSequencerModelDto): Observable<SequencerModel> {
    return this.http.post<SequencerModel>(SEQUENCER_MODEL_BASE_URI, sequencerModel);
  }

  archive(sequencerModel: SequencerModel): Observable<void> {
    const uri = `${SEQUENCER_MODEL_BASE_URI}/${sequencerModel.id}`;
    return this.http.delete<void>(uri);
  }

  restore(sequencerModel: SequencerModel): Observable<void> {
    const uri = `${SEQUENCER_MODEL_BASE_URI}/${sequencerModel.id}/restore`;
    return this.http.post<void>(uri, undefined);
  }
}
