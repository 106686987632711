import { Component, Input, OnInit } from '@angular/core';
import { InvalidPlateQCInstrumentDirectoryError } from '../models/invalid-plate-qc-instrument-directory-error';
import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import PlateDimensions from '../../../labware/models/plate-dimensions';

@Component({
  selector: 'nemo-tasks-plate-qc-assignment-error',
  template: `
      <div class="title">Plate/QC assignment is invalid</div>
      <div class="unassigned-coordinates"
           *ngIf="plateUnassignedCoordinates"
      >
          <strong>Unassigned on sample plate: </strong> {{plateUnassignedCoordinates}}
      </div>
      <div class="unassigned-coordinates"
           *ngIf="instrumentDirectoryUnassignedCoordinates"
      >
          <strong>Unassigned from QC: </strong> {{instrumentDirectoryUnassignedCoordinates}}
      </div>
  `,
  styles: [`
      div.title {
          color: red;
          font-size: 1.3rem;
          margin-bottom: 15px;
      }
  `]
})
export class PlateQCAssignmentErrorComponent implements OnInit {
  @Input() error: InvalidPlateQCInstrumentDirectoryError;

  plateUnassignedCoordinates: string;
  instrumentDirectoryUnassignedCoordinates: string;

  ngOnInit(): void {
    this.plateUnassignedCoordinates = this.error.unassignedPlateCoordinates ?
                                      new PlateCoordinatesSelection(new PlateDimensions(8, 12))
                                        .fromCoordinatesList(this.error.unassignedPlateCoordinates)
                                        .toString()
                                                                            : null;
    this.instrumentDirectoryUnassignedCoordinates = this.error.unassignedInstrumentDirectoryCoordinates ?
                                                    new PlateCoordinatesSelection(new PlateDimensions(8, 12))
                                                      .fromCoordinatesList(this.error.unassignedInstrumentDirectoryCoordinates)
                                                      .toString()
                                                                                                        : null;
  }
}
