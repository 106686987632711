import { BioMaterial } from '../../bio/models/bio-material';
import { PlateCoordinates } from './plate-coordinates';

/**
 * a simple holder for a bio material + plate coordinates
 * The object is used in assigning a bio material to a plate coordinates and should have limited lifetime
 * the object is store proof
 */

export class BioMaterialPlateMapping<T extends BioMaterial> {
  constructor(
    public readonly biomaterial: T,
    public readonly plateCoordinates: PlateCoordinates
  ) {
  }
}
