import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PlateCoordinatesSelection } from '../../../labware/models/plate-coordinates-selection';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { isReArrayPossible, isUndoReArrayPossible, ReArrayCheck } from '../services/rearray.service';
import { Plate96 } from '../../../labware/models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { reArray, undoReArray } from '../../store/task.action';
import * as _ from 'lodash';
import { Pool } from '../../../bio/models/pool';
import { ReArrayDto } from '../services/rearray-api.service';

@Component({
  selector: 'nemo-re-array-middle-zone',
  template: `
    <button mat-raised-button
            color="primary"
            [disabled]="!reArrayCheck.canReArray"
            (click)="reArray()">
      <mat-icon>arrow_backward</mat-icon>
      Re-array
    </button>

    <button mat-raised-button
            color="warn"
            [disabled]="!undoReArrayCheck.canReArray"
            (click)="undoReArray()">Remove samples
    </button>

    <mat-hint [ngClass]="reArrayCheck.infoMessageStyle"
              *ngIf="reArrayCheck.infoMessage">
      {{reArrayCheck.infoMessage}}
    </mat-hint>
  `,
  styles: [`
    :host {
      width: 200px;
      max-height: 400px;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 15px;
    }
  `]
})
export class ReArrayMiddleZoneComponent implements OnInit, OnChanges {

  @Input() sourcePlate: Plate96<Pool>;
  @Input() sourceSelection: PlateCoordinatesSelection;
  @Input() destinationPlate: Plate96<BioMaterial>;
  @Input() destinationSelection: PlateCoordinatesSelection;

  reArrayCheck: ReArrayCheck;
  undoReArrayCheck: ReArrayCheck;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.isReArrayPossible();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.destinationSelection || changes.sourceSelection) {
      this.isReArrayPossible();
    }
  }

  isReArrayPossible() {
    this.reArrayCheck = isReArrayPossible(
      this.destinationPlate,
      this.destinationSelection,
      this.sourcePlate,
      this.sourceSelection
    );

    this.undoReArrayCheck = isUndoReArrayPossible(
      this.destinationPlate,
      this.destinationSelection
    );
  }

  reArray() {
    this.reArrayCheck = new ReArrayCheck('info', 'pending...', false);
    const poolsAccessionCode = this.sourceSelection.listSelectedCoordinates()
      .map(coordinates => this.sourcePlate.getBioMaterialAt(coordinates).accessionCode);

    const destinationCoordinates = this.destinationSelection.listSelectedCoordinates();
    const reArrayDtos = _
      .zip(poolsAccessionCode, destinationCoordinates)
      .map(pair => new ReArrayDto(pair[0], pair[1]));

    this.store.dispatch(reArray({
      plateAccessionCode: this.destinationPlate.accessionCode,
      reArrayDtos: reArrayDtos
    }));
  }

  undoReArray() {
    this.reArrayCheck = new ReArrayCheck('info', 'pending...', false);
    const destinationCoordinates = this.destinationSelection.listSelectedCoordinates();

    this.store.dispatch(undoReArray({
      plateAccessionCode: this.destinationPlate.accessionCode,
      plateCoordinates: destinationCoordinates
    }));
  }
}
