import { NError } from '../models/error.model';
import {
  clearErrorFromLoggedIn,
  clearErrorFromRouter,
  clearErrorFromSnackBarClose,
  ErrorActions,
  insertErrorFromGlobalHandler,
  insertErrorFromHttpInterceptor,
  insertErrorInvalidRequestParsing,
  insertErrorInvalidSubmission,
  insertErrorUncaughtFormValidation
} from './error.actions';

export default interface ErrorState {
  active?: NError;
  errors: NError[];
}

export const initialState: ErrorState = {
  active: null,
  errors: [],
};

export function reducer(state = initialState, action: ErrorActions) {
  switch (action.type) {

    case insertErrorFromHttpInterceptor.type:
    case insertErrorFromGlobalHandler.type:
    case insertErrorInvalidSubmission.type:
    case insertErrorInvalidRequestParsing.type:
    case insertErrorUncaughtFormValidation.type:
      const active = action.error;
      const errors = state.errors.concat(state.errors, action.error);
      return {...state, active, errors};
    case clearErrorFromRouter.type:
    case clearErrorFromSnackBarClose.type:
    case clearErrorFromLoggedIn.type:
      return {...state, active: null, errors: []};
    default:
      return state;
  }
}
