import { Component, Input } from '@angular/core';
import { CsvData, CsvWriterService } from '../../../core/services/csv-writer.service';
import { getExportFilename } from '../services/export.services';

@Component({
  selector: 'nemo-export-button',
  template: `
    <button mat-raised-button
            color="primary"
            (click)="exportToCsv(null)"
            [disabled]="disabled">
      <span *ngIf="number">{{number}}. </span>
      {{title}}
    </button>
  `,
  styles: [`
    button {
      width: 100%;
    }
  `]
})
export class ExportButtonComponent {

  @Input() data: object[];
  @Input() accessionCode: string;
  @Input() tableName: string;
  @Input() tableColumns: string[];
  @Input() title: string;
  @Input() number: number = null;
  @Input() disabled = false;

  constructor(
    private readonly csvWriter: CsvWriterService
  ) {
  }

  exportToCsv(requestAccessionCode: string) {
    const filename = getExportFilename(this.tableName, this.accessionCode, requestAccessionCode);
    const meta = {columnNames: this.tableColumns, task: this.tableName};
    const csvData: CsvData = {meta, data: this.data};
    this.csvWriter.downloadAsCsv(csvData, filename);
  }
}
