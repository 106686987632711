import { createAction, props } from '@ngrx/store';
import { Department } from '../models/Department';

export const findAllDepartments = createAction(
  '[Init Data] find all Departments'
);

export const findAllDepartmentsAfterUpdate = createAction(
  '[After Update] find all Departments'
);

export const findAllDepartmentsSuccess = createAction(
  '[Department API]  all Departments list Success',
  props<{ departments: Department[] }>()
);

export const findAllDepartmentsError = createAction(
  '[Department API]  all Departments list Error',
  props<{ message: string }>()
);

export const createDepartment = createAction(
  '[Departments page] create new department',
  props<{ name: string, code: number }>()
);

export const archiveDepartment = createAction(
  '[Departments page] archive department',
  props<{ department: Department }>()
);

export const restoreDepartment = createAction(
  '[Departments page] restore department',
  props<{ department: Department }>()
);

export type DepartmentAction = ReturnType<| typeof findAllDepartments
  | typeof findAllDepartmentsSuccess
  | typeof findAllDepartmentsError>;
