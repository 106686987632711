import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserImageComponent } from './components/user-image.component';
import { UserSummaryComponent } from './components/user-summary.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';


@NgModule({
  declarations: [UserImageComponent, UserSummaryComponent],
  exports: [
    UserImageComponent,
    UserSummaryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
  ]
})
export class UserSharedModule {
}
