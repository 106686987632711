import { Component, Input, OnInit } from '@angular/core';
import { CommentTask } from '../../models/commentText';
import { isStage } from '../../../tasks/models/task.model';

@Component({
  selector: 'nemo-comment-task-content',
  template: `
    <div>
      <p class="task-info">
        <strong>{{comment.task.user.name}} </strong>
        {{comment.task.action | taskType}}
        <span *ngIf="isRequestTaskType"> the request</span>
        <span *ngIf="comment.task.payload.stage"> {{comment.task.payload.stage.name}}
          <strong class="clickable"
                  routerLink="/flow-cell/{{comment.task.payload.flowCellId}}"> {{comment.task.payload.flowCellId}} </strong>
        </span>
        <span *ngIf="comment.task.payload.flowCellId"> on flow cell
          <strong class="clickable accession-code"
                  routerLink="/flow-cell/{{comment.task.payload.flowCellId}}"> {{comment.task.payload.flowCellId}} </strong>
        </span>
        <span *ngIf="comment.task.payload.plateAccessionCode"> plate
          <strong class="clickable accession-code"
                  routerLink="/plate/{{comment.task.payload.plateAccessionCode}}">
            {{comment.task.payload.plateAccessionCode | accessionCode}}
          </strong>
        </span>

        <span *ngIf="comment.task.payload.sourcePlateAccessionCode">
          from plate <strong
          class="clickable accession-code"
          routerLink="/plate/{{comment.task.payload.sourcePlateAccessionCode}}">
          {{comment.task.payload.sourcePlateAccessionCode | accessionCode}} </strong>
        </span>

        <span *ngIf="comment.task.payload.targetPlateAccessionCode">
          onto plate <strong
          class="clickable accession-code"
          routerLink="/plate/{{comment.task.payload.targetPlateAccessionCode}}">
          {{comment.task.payload.targetPlateAccessionCode | accessionCode}} </strong>
        </span>
    </div>
  `,
  styles: []
})
export class CommentTaskContentComponent implements OnInit {
  @Input()
  comment: CommentTask;

  isRequestTaskType: boolean;

  ngOnInit(): void {
    this.isRequestTaskType = isStage(this.comment.task.action);
  }

}
