import { Component, Input } from '@angular/core';
import { SimpleRequest } from '../../genomics-request/models/genomics-request';

@Component({
  selector: 'nemo-phi-x',
  template:
    `
        <mat-chip-listbox *ngIf="request.phiXPercentage > 0">
          <mat-chip-option color="primary" selected>phiX= {{request.phiXPercentage}}%</mat-chip-option>
        </mat-chip-listbox>
      `,
  styles: [`
    :host {
      margin-right: 1em;
    }
  `]
})
export class PhiXComponent {
  @Input() request: SimpleRequest;
}
