import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import {
  selectAccessionCodeByIdentifier,
  selectEchoPoolingExportFromPlateByIdentifier,
} from '../../../labware/store/plates';
import { ECHO_POOLING_DESTINATION_PLATE_ID } from './echo-pooling-left-zone.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'nemo-echo-pooling-export-for-echo',
  template: `
    <nemo-export-button tableName="for-echo"
                        title="Export for Echo"
                        [accessionCode]="accessionCode$ | async"
                        [data]="formatedData$ | async"
                        [tableColumns]="exportTableColumns"
                        [disabled]="(formatedData$ | async) === undefined || (formatedData$ | async) === null"
                        [number]="processed ? null : 3"></nemo-export-button>
  `
})
export class EchoPoolingExportForEchoComponent implements OnInit {

  @Input() processed = false;
  accessionCode$: Observable<string>;
  formatedData$: Observable<object[]>;
  exportTableColumns: string[] = ['Source Well', 'Destination Well', 'Transfer volume'];

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.accessionCode$ = this.store.select(selectAccessionCodeByIdentifier(ECHO_POOLING_DESTINATION_PLATE_ID))
      .pipe(filter(accessionCode => Boolean(accessionCode)));
    this.formatedData$ = this.store.select(selectEchoPoolingExportFromPlateByIdentifier(ECHO_POOLING_DESTINATION_PLATE_ID));
  }
}
