import { Component, Input } from '@angular/core';

@Component({
  selector: 'nemo-pending-title',
  template: `
    <div class="title">
      <h2>{{title}}</h2>
      <nemo-pending [pending]="pending"></nemo-pending>
    </div>
  `,
  styles: [`
    .title {
      border-bottom: 1px solid #dadce0;
      height: 30px;
      margin: 15px 0 0 0;
      display: flex;
    }

    .title h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #555;
      line-height: 24px;
      margin: 0 100px 0 0;
    }

    nemo-pending {
      flex: auto;
    }
  `]
})
export class PendingTitleComponent {
  @Input() title: string;
  @Input() pending = false;
}
