import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nemo-dialog-info',
  template: `
    <div mat-dialog-content>
      {{data['message']}}
    </div>
  `,
  styles: [
      `
          `
  ]
})
export class DialogInfoComponent {
  @Input() message: string;

  @Input() route: string;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
  }
}
