import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { ErrorHandler, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
// Modules
import { MaterialModule } from './material.module';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { AppRoutingModule } from './app-routing.module';
import { LabWareModule } from './labware/labware.module';
import { RequestModule } from './genomics-request/request.module';
import { IndexModule } from './index/index.module';
import { TasksModule } from './tasks/tasks.module';
// Components
import { AppComponent } from './app.component';
import { reducers } from './store/app.reducers';
import { GenomicsRequestEffects } from './genomics-request/store/genomics-request.effects';
import { environment } from '../environments/environment';
import { GlobalErrorHandler } from './shared/nerrors/GlobalErrorHandler';
import { ErrorFlashComponent } from './shared/nerrors/components/error-flash/error-flash.component';
import { IndexKitEffects } from './index/store/index-kit.effects';
import { PlatesEffects } from './labware/store/plates/plates.effects';
import { FlowCellsEffects } from './labware/store/flow-cells/flow-cells.effects';
import { PoolsApiEffects } from './bio/store/pools-api.effects';
import { TodoPageModule } from './todo-page/todo-page.module';
import { PlatesApiEffects } from './labware/store/plates/plates-api.effects';
import { UserEffects } from './user/store/user.effects';
import { TaskEffects } from './tasks/store/task.effects';
import { DepartmentEffects } from './company/store/department.effects';
import { ProjectEffects } from './company/store/project.effects';
import { NavigationEffects } from './navigation/store/navigation.effects';
import { SharedSharedModule } from './shared/shared/shared-shared.module';
import { QcModule } from './qc/qc.module';
import { QcEffects } from './qc/store/qc.effects';
import { TableModule } from './table/table.module';
import { NavigationModule } from './navigation/navigation.module';
import { HomeModule } from './home/home.module';
import { CommentModule } from './comment/comment.module';
import { GlobalContextEffects } from './global-context/store/global-context.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommentEffects } from './comment/store/comment.effects';
import { GraphEffects } from './graph/store/graph.effects';
import { FormEditorEffects } from './form/store/editor/form-editor.effects';
import { FormModule } from './form/form.module';
import { LibraryMethodEffects } from './sequencing/store/effects/library-method.effects';
import { SettingsModule } from './settings/settings.module';
import { CookieService } from 'ngx-cookie-service';
import { SequencerModelEffects } from './sequencing/store/effects/sequencer-model.effects';
import { FormEffects } from './form/store/form/form.effects';
import { FormRequestEffects } from './form/store/form-request/form-request.effects';
import { ReportingModule } from './reporting/reporting.module';
import { StudyEffects } from './company/store/study-effects.service';
import { LabEffects } from './company/store/lab.effects';

@NgModule({
  declarations: [
    AppComponent,
    ErrorFlashComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    FormModule,
    HomeModule,
    IndexModule,
    LabWareModule,
    LoginModule,
    MaterialModule,
    QcModule,
    ReportingModule,
    RequestModule,
    SettingsModule,
    SharedSharedModule,
    TasksModule,
    TodoPageModule,
    EffectsModule.forRoot([
      CommentEffects,
      DepartmentEffects,
      FlowCellsEffects,
      FormEditorEffects,
      FormEffects,
      FormRequestEffects,
      GlobalContextEffects,
      GraphEffects,
      IndexKitEffects,
      LabEffects,
      LibraryMethodEffects,
      NavigationEffects,
      PlatesApiEffects,
      PlatesEffects,
      PoolsApiEffects,
      ProjectEffects,
      QcEffects,
      GenomicsRequestEffects,
      SequencerModelEffects,
      TaskEffects,
      UserEffects,
      StudyEffects,
    ]),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: true,
      },
    }),
    TableModule,
    NavigationModule,
    CommentModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    CookieService
  ]
})
export class AppModule {
}
