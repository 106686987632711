import { Component, Input } from '@angular/core';
import { TaskAvailable } from '../../../tasks/models/task.model';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-plate-tasks-buttons',
  template: `
      <div
              *ngIf="isLaber$ | async"
      >
          <button *ngIf="rearrayTo"
                  routerLink="/tasks/re-array/{{plateAccessionCode}}/undefined/"
                  matTooltip="Plate {{plateAccessionCode | accessionCode}} will be the destination plate"
                  mat-raised-button color="primary">
              Re-array to (or validate)
          </button>

          <button *ngIf="rearray"
                  routerLink="/tasks/re-array/undefined/{{plateAccessionCode}}/"
                  matTooltip="Plate {{plateAccessionCode | accessionCode}} will be the source plate"
                  mat-raised-button color="primary">
              Re-array from
          </button>

          <button *ngIf="echoPoolingFrom"
                  routerLink="/tasks/echo-pooling/undefined/{{plateAccessionCode}}"
                  mat-raised-button color="primary">
              Echo pooling
          </button>

          <button *ngIf="echoPoolingTo"
                  routerLink="/tasks/echo-pooling/{{plateAccessionCode}}/undefined"
                  mat-raised-button color="primary">
              Echo pooling to
          </button>

          <button *ngIf="echoPoolingProcessed"
                  routerLink="/tasks/echo-pooling-processed/{{plateAccessionCode}}"
                  mat-raised-button color="primary">
              See echo pooling
          </button>

          <button *ngIf="indexAssignment"
                  routerLink="/tasks/index-assign/{{plateAccessionCode}}"
                  matTooltip="Assign indexes to {{plateAccessionCode | accessionCode}}'s samples."
                  mat-raised-button color="primary">
              Index assignment
          </button>
      </div>
  `,
  styles: [`
                 button {
                     margin-right: 10px;
                 }
             `]
})
export class PlateTasksButtonsComponent extends HasGlobalContextUserRole {

  indexAssignment = false;
  rearray = false;
  rearrayTo = false;
  echoPoolingFrom = false;
  echoPoolingTo = false;
  echoPoolingProcessed = false;

  @Input() plateAccessionCode: string;

  @Input() set availableForTasks(value: TaskAvailable[]) {
    if (value) {
      value.forEach(task => {
        switch (task) {
          case TaskAvailable.INDEX_ASSIGNMENT:
            // we don't allow index assignment o/p for request plate
            if (!this.plateAccessionCode.startsWith('PLT_REQ')) {
              this.indexAssignment = true;
            }
            return;

          case TaskAvailable.RE_ARRAY_TO:
            this.rearrayTo = true;
            return;

          case TaskAvailable.RE_ARRAY_FROM:
            this.rearray = true;
            return;

          case TaskAvailable.ECHO_POOLING_FROM:
            this.echoPoolingFrom = true;
            return;

          case TaskAvailable.ECHO_POOLING_TO:
            this.echoPoolingTo = true;
            return;

          case TaskAvailable.ECHO_POOLING_READ:
            this.echoPoolingProcessed = true;
            return;

          default:
            return;
        }
      });
    }
  }

  constructor(
    private readonly store: Store<AppState>
  ) {
    super(store);
  }
}
