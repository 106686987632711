import { Component, Input, OnInit } from '@angular/core';
import PlateDimensions from '../../models/plate-dimensions';
import { PlateSnippet } from '../../dto/labware.dto';

export function plateIconHtml(plate: PlateSnippet): string {
  const nbRow = plate.dimensions.numberOfRows;
  const nbCol = plate.dimensions.numberOfColumns;
  let str = '';
  for (let r = 0; r < nbRow; r++) {
    for (let c = 0; c < nbCol; c++) {
      const indexOccupancy = c * nbRow + r;
      str += plate.occupancy[indexOccupancy] ? '●' : '○';
    }
    str += '\n';
  }

  return `
<pre style="
            margin-bottom:5px;
            margin-top: 5px;
            line-height: 76%;
            font-size:${100 * 8 / nbRow}%;
            letter-spacing: 0rem;
            "
            >${str}</pre>
`;
}

@Component({
  selector: 'nemo-plate-icon',
  template: `
    <pre>{{strRender}}</pre>
  `,
  styles: [`
             :host {
               display: flex;
               padding-bottom: 5px;
               padding-top: 5px;
             }

             pre {
               line-height: 76%;
               letter-spacing: 0rem;
             }

           `]
})
export class PlateIconComponent implements OnInit {

  @Input() dimensions: PlateDimensions;
  @Input() occupancy: boolean[];
  @Input() small = false;

  columns: number[];
  strRender: string;

  ngOnInit() {
    this.columns = new Array(this.dimensions.numberOfColumns).fill(1).map((_, index) => index);
    const nbRow = this.dimensions.numberOfRows;
    const nbCol = this.dimensions.numberOfColumns;
    let str = '';
    for (let r = 0; r < nbRow; r++) {
      for (let c = 0; c < nbCol; c++) {
        const indexOccupancy = c * nbRow + r;
        str += this.occupancy[indexOccupancy] ? '●' : '○';
      }
      str += '\n';
    }
    this.strRender = str;
  }

}
