import { createAction, props } from '@ngrx/store';
import { SimplePlateWithRequests } from '../../../models/simple-plate';
import { PlateCounts } from '../../../dto/plate.dto';
import { PageInfo } from '../../../../shared/models/page.model';
import { Plate96 } from '../../../models/plate-96';
import { BioMaterial } from '../../../../bio/models/bio-material';
import { PlateSnippet } from '../../../dto/labware.dto';

// mapKey is the key of Map<string Plate[]> in the store
export const platesSearchSuccess = createAction(
  '[Plates API] Plates search success',
  props<{ plates: PlateSnippet[], mapKey?: string }>()
);

export const countPlatesSuccess = createAction(
  '[Plates API] Count plates success',
  props<{ plateCounts: PlateCounts, availableTask?: string }>()
);

export const platesSearchError = createAction(
  '[Plates API] Plates search error',
  props<{ message: string }>()
);

export const countPlatesError = createAction(
  '[Plates API] Count plates error',
  props<{ message: string }>()
);

// mapKey is the key of Map<string Plate[]> in the store
export const platesFindAllSuccess = createAction(
  '[Plates API] Find all success',
  props<{ plates: PlateSnippet[], mapKey?: string }>()
);

export const platesFindAllError = createAction(
  '[Plates API] Find all error',
  props<{ message: string }>()
);

export const findPlateByAccessionCodeSuccess = createAction(
  '[Plates API] find plate by accession code success',
  props<{ plate: Plate96<BioMaterial> }>()
);

export const findPlateByAccessionCodeError = createAction(
  '[Plates API] find plate by accession code error',
  props<{ message: string }>()
);

export const navigateToPlate = createAction(
  '[Plates API] navigate to plate',
  props<{ accessionCode: string }>()
);

export const updatePlateField = createAction(
  '[Plate Page] update plate field',
  props<{ accessionCode: string, field: string, value: string }>()
);

export const updatePlateFieldSuccess = createAction(
  '[API] update plate field success',
  props<{ plate: Plate96<BioMaterial> }>()
);

export const updatePlateFieldError = createAction(
  '[API] plate field error',
  props<{ message: string }>()
);


export type PlatesApiActions = ReturnType<| typeof platesSearchSuccess
  | typeof findPlateByAccessionCodeSuccess
  | typeof countPlatesSuccess
  | typeof countPlatesError
  | typeof platesSearchError
  | typeof platesFindAllSuccess
  | typeof platesFindAllError
  | typeof findPlateByAccessionCodeError
  | typeof updatePlateField
  | typeof updatePlateFieldSuccess
  | typeof updatePlateFieldError>;
