import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PageInfo } from '../../shared/models/page.model';
import {  Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { findAllPlateQCFromTodoPage } from '../../qc/store/actions/qc-api.actions';
import { getAllQCPlateSummaries, getQcResultPageInfo } from '../../qc/store/qc.selectors';
import { TaskAvailable } from '../../tasks/models/task.model';
import { PlateQCSummary } from '../../qc/models/plate-qc-measures';

@Component({
  selector: 'nemo-todo-qc-result',
  template: `
    <nemo-pending-title title="Qc results to assign"></nemo-pending-title>
    <nemo-qc-table [plateQCSummaries]="qcResults$ | async"></nemo-qc-table>
    <nemo-paginator [pageInfo]="pageInfo$ | async" (pageChange)="findAll($event[0], $event[1])"></nemo-paginator>
  `
})
export class TodoQcResultComponent implements OnInit {

  qcResults$: Observable<PlateQCSummary[]>;
  pageInfo$: Observable<PageInfo>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.findAll(25, 0);
    this.pageInfo$ = this.store.select((getQcResultPageInfo));
    this.qcResults$ = this.store.select((getAllQCPlateSummaries));
  }

  findAll(pageSize: number, page: number) {
    this.store.dispatch(findAllPlateQCFromTodoPage({
      pageSize,
      page,
      availableTask: TaskAvailable.QC_PLATE_ASSIGN_TO_FILE
    }));
  }
}
