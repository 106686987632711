import { createAction, props } from '@ngrx/store';
import { LibraryMethod } from '../../models/library-method';

export const findAllLibraryMethodsAtInit = createAction(
  '[Init Data] find all library methods'
);
export const findAllLibraryMethodsAfterUpdate = createAction(
  '[After update] find all library methods'
);

export const findAllLibraryMethodsSuccess = createAction(
  '[Library method API]  all library method list Success',
  props<{ libraryMethods: LibraryMethod[] }>()
);

export const findAllLibraryMethodsError = createAction(
  '[Library method API]  all library method Error',
  props<{ message: string }>()
);

export const createLibraryMethod = createAction(
  '[Settings page] create new library method',
  props<{ name: string }>()
);

export const renameLibraryMethod = createAction(
  '[Settings page] rename library method',
  props<{ libraryMethod: LibraryMethod }>()
);

export const settingsArchiveLibraryMethod = createAction(
  '[Settings page] archive library methods',
  props<{ libraryMethod: LibraryMethod }>()
);

export const settingsRestoreLibraryMethod = createAction(
  '[Settings page] restore library methods',
  props<{ libraryMethod: LibraryMethod }>()
);
