import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolListComponent } from './components/pools-list/pool-list.component';
import { PoolSearchComponent } from './components/pools-list/pool-search.component';
import { MaterialModule } from '../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedSharedModule } from '../../shared/shared/shared-shared.module';
import { PoolComponent } from './components/pool/pool.component';
import { RequestSharedModule } from '../../request-shared/request-shared.module';
import { TableModule } from '../../table/table.module';
import { RouterModule } from '@angular/router';
import { PoolSizePipe } from './pipes/pool-size.pipe';
import { PoolExportButtonComponent } from './components/pool-export-button.component';
import { PoolSampleListPipe } from './pipes/pool-sample-list.pipe';

@NgModule({
  declarations: [
    PoolComponent,
    PoolExportButtonComponent,
    PoolListComponent,
    PoolSampleListPipe,
    PoolSearchComponent,
    PoolSizePipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedSharedModule,
    RequestSharedModule,
    TableModule,
    RouterModule
  ],
  exports: [
    PoolComponent,
    PoolExportButtonComponent,
    PoolListComponent,
    PoolSampleListPipe,
    PoolSizePipe,
  ]
})

export class BioSharedModule {
}
