import { Pipe, PipeTransform } from '@angular/core';
import { TaskType } from '../../models/task.model';

@Pipe({
  name: 'taskType'
})
export class TaskTypePipe implements PipeTransform {

  transform(value: any): string {
    return TaskType[value];
  }
}
