<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="submit-form">
  <mat-form-field
    class="full-width"
    appearance="fill"
  >
    <input matInput
           #title
           type="text"
           formControlName="title"/>
    <mat-label>title (*Required)</mat-label>
    <mat-hint align="start">a short description</mat-hint>
    <mat-hint align="end">{{title.value.length}} / 80</mat-hint>
  </mat-form-field>

  <div>
    <h4>Request origin</h4>
    <div class="request-origin">
      <nemo-department-select
        [control]="formGroup.controls['departmentCode']"
      ></nemo-department-select>
      <nemo-project-select
        [control]="formGroup.controls['projectCode']"
      ></nemo-project-select>
      <nemo-study-select
        [control]="formGroup.controls['studyId']"
      ></nemo-study-select>
      <nemo-collaborator-select
        [control]="formGroup.controls['collaborator']"
      ></nemo-collaborator-select>
    </div>
  </div>

  <!-- Library method selection -->
  <div>
    <h4>Preparation</h4>
    <mat-form-field
      class="full-width library-method-field"
      appearance="fill">
      <mat-select formControlName="libraryMethod"
		  (selectionChange)="onLibraryMethodChange($event)"
                  [(value)]="selectedLibraryMethod">
        <mat-option>
          <input matInput placeholder="Search library methods"
		 (input)="filterLibraryMethodOptions($event.target.value)"
		 (click)="$event.stopPropagation()"
		 (keydown.space)="$event.stopPropagation()" />
        </mat-option>
        <mat-option *ngFor="let libraryMethod of filteredLibraryMethodOptions$ | async" [value]="libraryMethod">
          {{libraryMethod.name}}
        </mat-option>
      </mat-select>
      <mat-hint align="start">For downstream analysis</mat-hint>
      <mat-label>Library method (*Required)</mat-label>
    </mat-form-field>
  </div>

  <div *ngIf="selectedLibraryMethod && requireReferenceTranscriptomeSetting(selectedLibraryMethod.name)">
    <mat-form-field
      class="full-width library-method-field"
      appearance="fill"
    >
      <mat-label>Reference transcriptome for cell ranger</mat-label>
      <mat-select formControlName="refTransCtl"
                  placeholder="Reference transcriptome (Required)"
      >
        <mat-option [value]="'/data/novaseq-2021/reference_transcriptome/human/refdata-gex-GRCh38-2020-A'">Human</mat-option>
        <mat-option [value]="'/data/novaseq-2021/reference_transcriptome/mouse/refdata-gex-mm10-2020-A'">Mouse</mat-option>
        <mat-option [value]="'/data/novaseq-2021/reference_transcriptome/rat/Rat_rDNA'">Rat</mat-option>
        <mat-option [value]="'/data/novaseq-2021/reference_transcriptome/yeast/SacCer3_mRNA_large_UTRs_rDNA'">Yeast</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="selectedLibraryMethod && requireOrganismSetting(selectedLibraryMethod.name)">
    <mat-form-field
      class="full-width library-method-field"
      appearance="fill">
      <mat-label>Species (Required)</mat-label>
      <mat-select formControlName="organismCtl"
		  placeholder="Species (Required)">
	<mat-option *ngFor="let option of organismOptions" [value]="option">
        {{ option }}
	</mat-option>
      </mat-select>
    </mat-form-field>
    <span class="param10x">If there are mutiple species/organism values in samples, please overwrite it in samples csv file by adding column "organism".</span>
  </div>

  <div *ngIf="selectedLibraryMethod && requireSampleTypeSetting(selectedLibraryMethod.name)">
    <mat-form-field
      class="full-width library-method-field"
      appearance="fill">
      <mat-label>Sample types (Required)</mat-label>
      <mat-select formControlName="sampleTypeCtl"
		  placeholder="Sample types (Required)">
	<mat-option *ngFor="let option of sampleTypeOptions" [value]="option">
        {{ option }}
	</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- PhiX -->
  <div class="phix">
    <mat-form-field
      appearance="fill"
      floatLabel="auto"
      class="numeric-field"
    >
      <input matInput
             formControlName="phiXPercentage"
             #phiXPercentage
             type="number"
             class="right-align"
             min="0"
             max="100"
             value="0"
      />
      <mat-label [class.strike-through]="phiXPercentage.value === '0'">PhiX</mat-label>
      <span matSuffix>%</span>
      <mat-hint>a concentration between 0 and 100%</mat-hint>
    </mat-form-field>
    <span class="phix-description">Standard RNA-Seq submissions should not need PhiX. For low diversity libraries, please request a PhiX spike or consult a member of the Genomics lab.</span>
  </div>

  <!-- add parameters for 10x genomics samples -->
  <div>
    <h4>Sequencing/demux Parameters (*Required)</h4>
    <mat-form-field
      appearance="fill"
      floatLabel="auto"
      class="numeric-field margin-right-10"
    >
      <input matInput
             formControlName="param10xRead1"
             type="number"
             class="right-align"
             min="1"
             max="999"
      />
      <mat-label>Read 1</mat-label>
    </mat-form-field>
    <mat-form-field
      appearance="fill"
      floatLabel="auto"
      class="numeric-field margin-right-10"
    >
      <input matInput
             formControlName="param10xIndex1"
             type="number"
             class="right-align"
             min="1"
             max="999"
      />
      <mat-label>Index 1</mat-label>
    </mat-form-field>
    <mat-form-field
      appearance="fill"
      floatLabel="auto"
      class="numeric-field margin-right-10"
    >
      <input matInput
             formControlName="param10xIndex2"
             type="number"
             class="right-align"
             min="1"
             max="999"
      />
      <mat-label>Index 2</mat-label>
    </mat-form-field>
    <mat-form-field
      appearance="fill"
      floatLabel="auto"
      class="numeric-field margin-right-10"
    >
      <input matInput
             formControlName="param10xRead2"
             type="number"
             class="right-align"
             min="1"
             max="999"
      />
      <mat-label>Read 2</mat-label>
    </mat-form-field>
    <span class="param10x">Input a number between 0 to 999.</span>
  </div>

  <!-- Library method selection -->

  <!-- Comment -->
  <h4>Description</h4>

  <mat-form-field class="full-width" appearance="outline">
    <textarea rows="5" matInput #description type="text" formControlName="description"
              placeholder="Description"></textarea>
    <mat-hint align="start">General information on the request, special comments</mat-hint>
    <mat-hint align="end">{{description.value.length}} / 5000</mat-hint>
  </mat-form-field>


  <!-- File upload -->
  <div [formGroup]="sampleGroup" class="file-upload">
    <h4>Samples</h4>

    <div class="sample-index-header">
      <mat-radio-group formControlName="sampleIndexHeader">
        <mat-radio-button *ngFor="let i of indexHeaderValidationIndices"
                          [value]="i">{{indexHeaderValidation[i]}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <button
      id="upload-plate-file-button"
      type="button"
      mat-raised-button
      [ngClass]="sampleFileClassName"
      color="default"
      (click)="fileInput.click()"
    >
      Select sample file
    </button>
    <label for="upload-plate-file-button">
      <span>{{ sampleGroup.value.sampleFile.filename }}</span>
    </label>
    <input hidden type="file" #fileInput (change)="onSampleFileChange($event)">
    <nemo-help-link gDocPath="drive/folders/17z9biba3N_hqUa71N7FqFmqJGHHsrHwh?usp=sharing"
                    plateExampleFile="request-a-simple-plate-with-nor.csv"
                    poolExampleFile="request-a-simple-pool-with-nor.csv"
                    text="sample file formats"
                    displayIndexDownload="true"></nemo-help-link>
    <mat-icon matSuffix class="valid" *ngIf="submittedContainersPreview$ | async">checked</mat-icon>
    <mat-icon matSuffix class="error" *ngIf="sampleFileErrors?.message">clear</mat-icon>

    <div *ngIf="submittedContainersPreview$ | async" class="miniature">
      <nemo-request-sample-containers [showActions]="false"
                                      [showOpenLinks]="false"
                                      [containers]="submittedContainersPreview$ | async"
                                      [isIcon]="false"
                                      [indexHint]="true"
      ></nemo-request-sample-containers>
    </div>
    <div class="lab-presentation">
      <p>
        <mat-icon [ngStyle]="{'color':'red'}">notification_important</mat-icon>
        Two new columns have been added to the sample file template:
      <ol>
        <li><b>Requested_reads(Million)</b>: Number of reads requested in millions(default: 25M).</li>
        <li><b>Vol(uL) </b>: Sample volume in microliters(<b>required</b>).</li>
      </ol>
    </div>
  </div>

  <!-- Submit -->
  <button
    id="submit-request-button"
    type="submit"
    mat-raised-button
    [disabled]="!formGroup.valid"
    color="primary"
  >
    Submit request
  </button>
</form>
