import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinWithComma'
})
export class JoinWithCommaPipe implements PipeTransform {

  transform(value: any[]): string {
    if (value) {
      return value.join(', ');
    }
  }
}
