import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.reducers';
import { Observable } from 'rxjs';
import { actionChangeLab } from '../../../../store/global-context.actions';
import { HasGlobalContextUserRole } from '../has-global-context-user-role';
import { Lab } from '../../../../../company/models/Lab';
import { selectCompanyAllLabs } from '../../../../../company/store';

@Component({
  selector: 'nemo-lab-selector',
  template: `
    <div class="container">
      <div class="sentence">
        I am working with the
      </div>
      <mat-button-toggle-group>
        <mat-button-toggle
          *ngFor="let lab of labs$ | async"
          [value]="[lab]"
          [checked]="(currentLab$|async) === lab"
          (click)="changeLab(lab)"
        >{{lab.name}} lab
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: row;
               align-items: center;
               font-size: 125%;
             }

             div.sentence {
               padding-right: 1em;
             }

             /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
             mat-button-toggle.mat-button-toggle-checked {
               color: black
             }

             /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
             mat-button-toggle {
               color: lightslategrey
             }
           `]
})
export class LabSelectorComponent extends HasGlobalContextUserRole implements OnInit {

  labs$: Observable<Lab[]>;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.labs$ = this.store.select(selectCompanyAllLabs);
  }

  changeLab(lab: Lab) {
    this.store.dispatch(actionChangeLab({lab}));
  }


}
