import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

export function formatCommentDateFormat(date: Date, now: Date): string {
  const momDate = moment(date);
  const momNow = moment(now);

  const momNowMidnight = moment(now).hour(0).minute(0).second(0).millisecond(0);
  if (momDate.isAfter(momNowMidnight)) {
    return momDate.format('h:mmA');
  }

  const momNowMidnightWeekAgo = moment(momNowMidnight).subtract(1, 'week');
  if (momDate.isAfter(momNowMidnightWeekAgo)) {
    return momDate.format('ddd, h:mmA');
  }

  const momNowNewYearEve = moment(momNowMidnight).month(0).date(1);
  if (momDate.isAfter(momNowNewYearEve)) {
    return momDate.format('MMM D, h:mmA');
  }

  return momDate.format('MMM D YYYY');
}

@Pipe({
  name: 'commentDate'
})
export class CommentDatePipe implements PipeTransform {
  now = new Date();

  transform(value: Date, ...args: any[]): any {
    return formatCommentDateFormat(value, this.now);
  }
}
