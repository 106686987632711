import { Routes } from '@angular/router';
import { AuthGuard } from '../core/auth/auth.guard';
import { FormEditorComponent } from './components/form-editor/form-editor.component';
import { FormRequestListPageComponent } from './components/form-request-list-page/form-request-list-page.component';
import { FormRequestViewPageComponent } from './components/form-request-view/form-request-view-page.component';
import { NewFormRequestComponent } from './components/form-submission/new-form-request.component';
import { RequestSearchResultComponent } from '../genomics-request/components/request-search-result/request-search-result.component';
import { FormRequestSearchResultComponent } from './components/form-request-search-results/form-request-search-result.component';

export const formRoutes: Routes = [
  {path: 'form/edit/:typeName', component: FormEditorComponent, canActivate: [AuthGuard]},
  {path: 'new-form-request/:typeName', component: NewFormRequestComponent, canActivate: [AuthGuard]},
  {path: 'form-requests', component: FormRequestListPageComponent, canActivate: [AuthGuard]},
  {path: 'form-request/:accessionCode', component: FormRequestViewPageComponent, canActivate: [AuthGuard]},
  {path: 'form-requests/search', component: FormRequestSearchResultComponent, canActivate: [AuthGuard]},
];
