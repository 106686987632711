import { createAction, props } from '@ngrx/store';
import { SimpleIndexKit } from '../models/indexKit';
import { PageInfo } from '../../shared/models/page.model';
import { SimpleIndexKitModel } from '../models/indexKitModel';
import { IndexKitModelCreationDto, IndexKitModelUpdateDto } from '../models/index-kit-model.dto';

/**
 * Retrieve the info for an index kit from backend,
 * then initialize a Plate instance.
 */
export const findIndexKitByIdThenSelect = createAction(
  '[IndexKit] Find index kit by id then select',
  props<{ id: number }>()
);

export const findIndexKitByIdThenSelectFromIndexAssignment = createAction(
  '[Index Assignment Page] Find index kit by id then select',
  props<{ id: number }>()
);

export const findIndexKitByIdThenSelectFromTaskApi = createAction(
  '[Task API] Find index kit by id then select',
  props<{ id: number }>()
);

export const findAllIndexKits = createAction(
  '[IndexKit] Find all index kits',
  props<{ pageSize: number, page: number }>()
);

export const findAllIndexKitsFromTaskApi = createAction(
  '[Task API] Find all index kits',
  props<{ pageSize: number, page: number }>()
);

export const findAllIndexKitsSuccess = createAction(
  '[IndexKit] Find all index kits success',
  props<{ indexKits: SimpleIndexKit[], pageInfo: PageInfo }>()
);

export const findIndexKitsFromIA = createAction(
  '[Index Assignment Page] Find index kits',
  props<{ plateAccessionCode: string }>()
);

export const findIndexKitsSuccess = createAction(
  '[IndexKit API] Find index kits success',
  props<{ indexKits: SimpleIndexKit[]; }>()
);

export const findIndexKitsError = createAction(
  '[IndexKit API] Find index kits error',
  props<{ message: string }>()
);

export const indexKitError = createAction(
  '[IndexKit] Error',
  props<{ message: string }>()
);

export const archiveIndexKit = createAction(
  '[Index Kit Page] Archive index kit',
  props<{ id: number }>()
);

export const archiveIndexKitSuccess = createAction(
  '[Index Kit API] Archive index kit success'
);

export const archiveIndexKitError = createAction(
  '[Index Kit API] Archive index kit error',
  props<{ message: string }>()
);

export const unarchiveIndexKit = createAction(
  '[Index Kit Page] Unarchive index kit',
  props<{ id: number }>()
);

export const unarchiveIndexKitSuccess = createAction(
  '[Index Kit API] Unarchive index kit success'
);

export const unarchiveIndexKitError = createAction(
  '[Index Kit API] Unarchive index kit error',
  props<{ message: string }>()
);

export type IndexKitAction = ReturnType<| typeof findIndexKitByIdThenSelect
  | typeof findIndexKitsFromIA
  | typeof findIndexKitsSuccess
  | typeof findIndexKitsError
  | typeof findAllIndexKits
  | typeof findAllIndexKitsSuccess
  | typeof indexKitError
  | typeof archiveIndexKitError
  | typeof unarchiveIndexKitError
  | typeof findAllIndexKitModels
  | typeof findAllIndexKitModelsSuccess
  | typeof findAllIndexKitModelsError
  | typeof createNewIndexKitModel
  | typeof archiveIndexKitModel
  | typeof restoreIndexKitModel>;

/**
 * Index kit model actions
 */

export const findAllIndexKitModels = createAction(
  '[IndexKitModel] Find all index kit models'
);

export const findAllIndexKitModelsSuccess = createAction(
  '[IndexKitModel] Find all index kit models success',
  props<{ indexKitModels: SimpleIndexKitModel[] }>()
);

export const findAllIndexKitModelsError = createAction(
  '[IndexKitModel] Find all index kit models error',
  props<{ message: string }>()
);

export const findAllIndexKitModelsAtInit = createAction(
  '[Init Data] Find all index kit models'
);

export const findAllIndexKitModelsAfterUpdate = createAction(
  '[After update] find all index kit models'
);

export const indexKitModelError = createAction(
  '[IndexKitModel] Error',
  props<{ message: string }>()
);

export const createNewIndexKitModel = createAction(
  '[Settings page] create new index kit model',
  props<{ indexKitModel: IndexKitModelCreationDto }>()
);

export const updateIndexKitModel = createAction(
  '[Settings page] update index kit model',
  props<{ indexKitModel: IndexKitModelUpdateDto }>()
);

export const archiveIndexKitModel = createAction(
  '[Settings page] archive index kit model',
  props<{ indexKitModelId: number }>()
);

export const restoreIndexKitModel = createAction(
  '[Settings page] restore index kit model',
  props<{ indexKitModelId: number }>()
);
