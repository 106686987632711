import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectGenomicsMessage, submitNewRequest } from '../../../store';
import { AppState } from '../../../../store/app.reducers';
import { NError } from '../../../../shared/nerrors/models/error.model';
import { FileTransferService } from '../../../../core/services/file-transfer.service';
import { SampleFileValidator } from './sample-file-validator.service';
import { CreateRequestDTO } from '../../../models/genomics-request.dto';
import { insertErrorInvalidSubmission } from '../../../../shared/nerrors/store';
import { LibraryMethod, SubmissionType } from '../../../../sequencing/models/library-method';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoComponent } from '../../../../shared/shared/components/dialog-info/dialog-info.component';
import { GenomicFormRequestService } from '../../../services/genomic-form-request.service';

@Component({
  selector: 'nemo-request-submission-form',
  templateUrl: './request-submission-form.component.html',
  styleUrls: ['./request-submission-form.component.scss']
})
export class RequestSubmissionFormComponent implements OnInit {

  formGroup: UntypedFormGroup;
  sampleGroup: UntypedFormGroup;
  requestMessage$: Observable<string>;
  isPristine = true;

  constructor(
    private readonly store: Store<AppState>,
    private readonly genomicFormRequestService: GenomicFormRequestService,
    private readonly fileTransferService: FileTransferService,
    private readonly sampleFileValidator: SampleFileValidator,
    private dialog: MatDialog
  ) {
    this.buildForm();
    this.createDynamicValidatorChanges();
  }

  ngOnInit() {
    this.requestMessage$ = this.store.select(selectGenomicsMessage);
  }
  
  buildForm() {
    this.sampleGroup = new UntypedFormGroup({
        sampleIndexHeader: new UntypedFormControl(null, [Validators.required]),
        sampleFile: new UntypedFormControl('', [Validators.required])
      }, [], [this.sampleFileValidator.validate]
    );
    this.formGroup = new UntypedFormGroup({
      ...this.genomicFormRequestService.buidlBasicFormRequestControls(),
      ...this.genomicFormRequestService.buildParam10xFormControls(),
      sample: this.sampleGroup,
      refTransCtl: new UntypedFormControl(null, []),
      organismCtl: new UntypedFormControl(null, []),
      sampleTypeCtl: new UntypedFormControl(null, []),
      isRestrictedAccessCtl: new UntypedFormControl(false),
      needAccessEmailsCtl: new UntypedFormControl(null, [ this.emailListValidator ])
    })
  }

  createDynamicValidatorChanges() {
    this.formGroup.get('isRestrictedAccessCtl')?.valueChanges.subscribe((required) => {
      const needAccessEmailsCtl = this.formGroup.get('needAccessEmailsCtl');
      this.setValidator(needAccessEmailsCtl, required, this.emailListValidator);
    });
    this.formGroup.get('libraryMethod').valueChanges.subscribe((libraryMethod) => {
      [
        [
          this.formGroup.get('refTransCtl'),
          this.requireReferenceTranscriptomeSetting(libraryMethod)
        ],
        [
          this.formGroup.get('organismCtl'),
          this.requireSpeciesSetting(libraryMethod)
        ],
        [
          this.formGroup.get('sampleTypeCtl'),
          this.requireSampleTypeSetting(libraryMethod)
        ],
      ].forEach(([ abstractControl, required ]: [ AbstractControl, boolean ]) =>
        this.setValidator(abstractControl, required)
      );
    });
  }

  get selectedLibraryMethod(): LibraryMethod | null | undefined {
    return this.formGroup.get('libraryMethod').value;
  }

  async sampleFileChange(file: File) {
    const b64 = await this.fileTransferService.readFileAsDataURL(file);
    this.sampleGroup.patchValue({
      sampleFile: {
        file: b64,
        filename: file.name,
      },
    });
  }

  onSubmit() {
    // nemo-41: 10x params requires at least 1 parameter is not 0
    // form can enforce all the parameters are between 0 to 999
    // nemo-62 all input will require demux param now.
    const paramSum = this.formGroup.get('param10xRead1').value
      + this.formGroup.get('param10xRead2').value
      + this.formGroup.get('param10xIndex1').value
      + this.formGroup.get('param10xIndex2').value;
    if (paramSum === 0) {
      const errorPayload = { error: new NError('At least one of the 10x read length parameters must be >0') };
      this.store.dispatch(insertErrorInvalidSubmission(errorPayload));
      return;
    }

    this.isPristine = false;
    if (this.formGroup.valid) {
      this.validSubmission();
    } else {
      this.invalidSubmission();
    }
  }

  validSubmission() {
    let descriptionExtended = this.formGroup.get('description').value;
    const isRestrictedAccessCtlTxt = this.formGroup.get('isRestrictedAccessCtl').value ? "yes" : "no";
    // append parameters for all samples

    let extendStr = '\nSequencing/Demux Parameters:\n'
      + 'Read1: ' + this.formGroup.get('param10xRead1').value + '\n'
      + 'Index1: ' + this.formGroup.get('param10xIndex1').value + '\n'
      + 'Index2: ' + this.formGroup.get('param10xIndex2').value + '\n'
      + 'Read2: ' + this.formGroup.get('param10xRead2').value + '\n'
      + 'RestrictedAccess: ' + isRestrictedAccessCtlTxt

    if (this.formGroup.get('isRestrictedAccessCtl').value && this.formGroup.get('needAccessEmailsCtl').value){
	extendStr += "\n" + "needAccessEmails: " + this.formGroup.get('needAccessEmailsCtl').value
    }

    const refTransCtlVal = this.formGroup.get('refTransCtl').value;
    const organismCtlVal = this.formGroup.get('organismCtl').value;
    const sampleTypeCtlVal = this.formGroup.get('sampleTypeCtl').value;


    if (organismCtlVal == "Homo sapiens" && sampleTypeCtlVal == "Fresh frozen tissue" && isRestrictedAccessCtlTxt == "no") {
      throw new Error("\"Restricted Access\" field is required to be checked for Homo sapiens with Fresh frozen tissue, " +
        "Please confirm whether these samples are from Donor/Patient. Email calico-ngs@calicolabs.com for help");
    }

    if (refTransCtlVal) {
      descriptionExtended += extendStr + '\n' + 'RefTransPath: ' + refTransCtlVal;
    } else if (organismCtlVal && sampleTypeCtlVal){
      descriptionExtended += extendStr + '\n' + 'Organism: ' + organismCtlVal + '\n' + 'SampleType: ' + sampleTypeCtlVal;
    } else {
      descriptionExtended += extendStr;
    }

    const request: CreateRequestDTO = {
      libraryMethod: this.formGroup.get('libraryMethod').value?.name ?? null,
      phiXPercentage: this.formGroup.get('phiXPercentage').value,
      title: this.formGroup.get('title').value,
      description: descriptionExtended,
      sampleFile: this.sampleGroup.get('sampleFile').value,
      sampleIndexHeader: this.sampleGroup.get('sampleIndexHeader').value,
      departmentCode: this.formGroup.get('departmentCode').value,
      projectCode: this.formGroup.get('projectCode').value,
      studyId: this.formGroup.get('studyId').value,
      collaborator: this.formGroup.get('collaborator').value // \.get\('$1'\)\.value
    };
    this.store.dispatch(submitNewRequest({ request }));
    this.dialog.open(DialogInfoComponent, {
      width: '500px',
      data: {
        message:
          'Submitting your request…'
      }
    });
  }

  invalidSubmission() {
    const controls = this.formGroup.controls;
    const messages = Object.keys(this.formGroup.controls)
      .map(field => ({ name: field, status: controls[field].status, errors: controls[field].errors }))
      .filter((e) => e.status !== 'VALID')
      .map((e) => e.name);
    const errorPayload = { error: new NError('Error with fields: ' + messages, {}) };
    this.store.dispatch(insertErrorInvalidSubmission(errorPayload));
  }

  setValidator(abstractControl: AbstractControl, required: boolean, value: any = null) {
    if (required) {
      if (value) {
        abstractControl.setValidators([ Validators.required, value ]);
      } else {
        abstractControl.setValidators([ Validators.required ]);
      }
    } else {
      abstractControl.clearValidators();
    }
    abstractControl.updateValueAndValidity();
  }

  emailListValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) return null; // Allow empty input when not required

    const emails = value.split(',').map((email: string) => email.trim());
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const invalidEmails = emails.filter((email: string) => !emailPattern.test(email));
    if (invalidEmails.length > 0) {
      return { invalidEmails: invalidEmails.join(', ') }; // Return invalid emails as an error
    }
    return null; // Validation passed
  }

  requireReferenceTranscriptomeSetting(libraryMethod: LibraryMethod | null): boolean {
    const requiredSubmissionTypes = [
      SubmissionType.SingleCell,
      SubmissionType.SpatialTranscriptomics,
    ];
    return this.isSubmissionTypeOneOf(libraryMethod, requiredSubmissionTypes);
  }

  requireSpeciesSetting(libraryMethod: LibraryMethod | null): boolean {
    return this.isForDNAorRNA(libraryMethod)
  }

  requireSampleTypeSetting(libraryMethod: LibraryMethod | null): boolean {
    return this.isForDNAorRNA(libraryMethod)
  }

  isForDNAorRNA(libraryMethod: LibraryMethod | null): boolean {
    const requiredSubmissionTypes = [
      SubmissionType.DNA,
      SubmissionType.RNA,
    ];
    return this.isSubmissionTypeOneOf(libraryMethod, requiredSubmissionTypes);
  }

  isSubmissionTypeOneOf(libraryMethod: LibraryMethod | null, submissionTypes: SubmissionType[]): boolean {
    return submissionTypes.includes(<SubmissionType>libraryMethod?.submissionType);
  }
}
