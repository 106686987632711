import { Component, Input } from '@angular/core';
import { FileTransferService } from '../../../core/services/file-transfer.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { echoPoolingImport } from '../../../labware/store/plates';

@Component({
  selector: 'nemo-echo-pooling-middle-zone',
  template: `
    <nemo-echo-pooling-global-export *ngIf="!processed" [processed]="processed"></nemo-echo-pooling-global-export>

    <button *ngIf="!processed"
            mat-raised-button
            color="primary"
            [disabled]="!targetAccessionCode"
            (click)="fileInput.click()">
      2. Import my calculations
    </button>
    <input hidden type="file" #fileInput (change)="onImportFileChange($event)">

    <nemo-echo-pooling-export-for-echo [processed]="processed"></nemo-echo-pooling-export-for-echo>
  `,
  styles: [`
    :host {
      width: 200px;
      max-height: 400px;
      display: flex;
      flex-flow: column;
      margin: auto 0;
      align-items: center;
      padding: 0 15px;
    }

    nemo-echo-pooling-global-export, nemo-echo-pooling-export-for-echo, button {
      width: 215px;
      margin-bottom: 20px;
    }
  `]
})
export class EchoPoolingMiddleZoneComponent {

  @Input() targetAccessionCode: string | null;
  @Input() processed = false;

  constructor(
    private store: Store<AppState>,
    private readonly fileTransferService: FileTransferService,
  ) {
  }

  async onImportFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const b64 = await this.fileTransferService.readFileAsDataURL(file);
      this.store.dispatch(echoPoolingImport({
        file: {file: b64, filename: file.name},
        targetAccessionCode: this.targetAccessionCode
      }));
    }
  }
}
