import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SimpleIndexKitModel } from '../models/indexKitModel';
import { Index } from '../../bio/models/barcode-index';
import { Plate96 } from '../../labware/models/plate-96';
import { IndexKitModelCreationDto, IndexKitModelUpdateDto } from '../models/index-kit-model.dto';
import { map, take } from 'rxjs/operators';

export const INDEX_KIT_MODEL_BASE_URI = environment.apiUrl + '/index-kit-models';

@Injectable({
  providedIn: 'root',
})
export class IndexKitModelService {

  constructor(private readonly http: HttpClient) {
  }

  findAllIndexKitModels(): Observable<SimpleIndexKitModel[]> {
    return this.http.get<SimpleIndexKitModel[]>(INDEX_KIT_MODEL_BASE_URI);
  }

  createNewIndexKitModel(indexKitModel: IndexKitModelCreationDto): Observable<Plate96<Index>> {
    return this.http.post<any>(INDEX_KIT_MODEL_BASE_URI, indexKitModel);
  }

  update(indexKitModel: IndexKitModelUpdateDto): Observable<SimpleIndexKitModel> {
    return this.http.put<any>(`${INDEX_KIT_MODEL_BASE_URI}/${indexKitModel.id}`, indexKitModel);
  }

  archive(id: number): Observable<any> {
    return this.http.delete(INDEX_KIT_MODEL_BASE_URI + '/' + id);
  }

  restore(id: number): Observable<any> {
    return this.http.post(INDEX_KIT_MODEL_BASE_URI + '/' + id + '/restore', null);
  }

  exportIndexSequenceList() {
    return this.http.get(
      INDEX_KIT_MODEL_BASE_URI + '/sequences',
      {
        headers: {},
        responseType: 'text'
      }
    ).pipe(
      map((content: any) => {
        const filename = `nemo-indexes.csv`;
        return {content, filename};
      }),
      take(1)
    );
  }
}
