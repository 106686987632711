<div class="plateContainer">
  <mat-card appearance="outlined" *ngFor="let plate of containers?.plates" class="plate">
    <nemo-plate-viewer [plate]="plate"
                       [isReadonly]="true"
                       [isIcon]="isIcon"
                       [showOpenLink]="showOpenLinks"></nemo-plate-viewer>
    <nemo-request-sample-index-hint *ngIf="indexHint"
                                    [samples]="plate | plateSamples"></nemo-request-sample-index-hint>
    <div class="ac center" *ngIf="!isIcon">{{plate.accessionCode | accessionCode}}</div>
    <div class="center" *ngIf="showActions">
      <nemo-plate-tasks-buttons [plateAccessionCode]="plate.accessionCode"
                                [availableForTasks]="plate.availableForTasks"></nemo-plate-tasks-buttons>
    </div>
  </mat-card>
</div>

<div class="poolContainer">
  <mat-card *ngFor="let pool of containers?.pools"
            appearance="outlined"
            class="plate">

    <nemo-pool [poolAccessionCode]="pool.accessionCode"
               [poolNumberOfSamples]="pool | poolSize"
               [poolTitle]="pool.title"
               [isIcon]="isIcon"
               [showOpenLink]="showOpenLinks"
               height="128"></nemo-pool>
    <nemo-request-sample-index-hint *ngIf="indexHint"
                                    [samples]="pool.samples"></nemo-request-sample-index-hint>
    <div class="ac" *ngIf="!isIcon">{{ pool.accessionCode | accessionCode }}</div>
    <div *ngIf="!isIcon" class="center">
    </div>
  </mat-card>
</div>
