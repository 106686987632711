import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlowCellChangeStatusButtonComponent } from './components/flow-cell-page/flow-cell-change-status-button.component';
import { TasksModule } from '../tasks/tasks.module';
import { FlowCellChangeToDraftButtonComponent } from './components/flow-cell-page/flow-cell-change-to-draft-button.component';
import { FlowCellTablePageComponent } from './components/flow-cell-table-page/flow-cell-table-page.component';
import { PlateListPageComponent } from './components/plate-table-page/plate-list-page.component';
import { PlateSearchComponent } from './components/plate-table-page/plate-search.component';
import { FlowCellPageComponent } from './components/flow-cell-page/flow-cell-page.component';
import { FlowCellTitleComponent } from './components/flow-cell-page/flow-cell-title.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { labwareRoutes } from './labware.routes';
import { RequestSharedModule } from '../request-shared/request-shared.module';
import { TableModule } from '../table/table.module';
import { FlowCellContainerComponent } from './components/flow-cell-page/flow-cell-container.component';
import { PlatesSampleTableComponent } from './components/plates-sample-table/plates-sample-table.component';
import { LaneAssignmentTableComponent } from './components/flow-cell-page/lane-assignment-table.component';
import { SampleNorsTableComponent } from './components/flow-cell-page/sample-nors-table.component';
import { RequestFastqDirsTableComponent } from './components/flow-cell-page/request-fastqdirs-table.component';
import { LaneAssignmentExportButtonComponent } from './components/flow-cell-page/lane-assignment-export-button.component';
import { FlowCellPoolViewerReadonlyComponent } from './components/flow-cell-page/flow-cell-pool-viewer-readonly.component';
import { PlatePageComponent } from './components/plate-page/plate-page.component';
import { TasksSharedModule } from '../tasks/shared/tasks-shared.module';
import { UserSharedModule } from '../user/shared/user-shared.module';
import {
  FlowCellSequencerConfigurationReadonlyComponent
} from './components/flow-cell-page/flow-cell-sequencer-configuration-readonly.component';

@NgModule({
  declarations: [
    FlowCellTablePageComponent,
    FlowCellPoolViewerReadonlyComponent,
    FlowCellSequencerConfigurationReadonlyComponent,
    PlateListPageComponent,
    PlateSearchComponent,
    PlatesSampleTableComponent,
    FlowCellPageComponent,
    FlowCellTitleComponent,
    FlowCellChangeStatusButtonComponent,
    FlowCellChangeToDraftButtonComponent,
    FlowCellContainerComponent,
    LaneAssignmentTableComponent,
    LaneAssignmentExportButtonComponent,
    PlatePageComponent,
    SampleNorsTableComponent,
    RequestFastqDirsTableComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule.forChild(labwareRoutes),
    SharedModule,
    MatPaginatorModule,
    MatCheckboxModule,
    TableModule,
    RequestSharedModule,
    TasksModule,
    TasksSharedModule,
    UserSharedModule
  ],
  exports: [
    PlatesSampleTableComponent,
    SampleNorsTableComponent,
  ]
})
export class LabWareModule {
}
