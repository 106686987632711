import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SampleFileIndexHeaderEnum } from '../../../models/plate-file-validation.model';
import { selectGenomicsContainersPreview } from '../../../store';
import { Observable } from 'rxjs';
import { RequestSampleContainers } from '../../../models/genomics-request';
import { AppState } from '../../../../store/app.reducers';
import { Store } from '@ngrx/store';
import { IndexKitModelService } from '../../../../index/services/index-kit-model.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'nemo-request-submission-form-file-upload',
  template: `
    <div [formGroup]="formGroup" class="file-upload">
      <h4>Submission Options*</h4>
      <div class="sample-index-header">
        <mat-radio-group formControlName="sampleIndexHeader">
          <mat-radio-button *ngFor="let sampleOption of sampleOptions"
                            [value]="sampleOption.value">
            <div>{{ sampleOption.title }}</div>
            <div *ngIf="sampleIndexHeader.value === sampleOption.value" class="mat-radio-actions">
              <button *ngFor="let template of sampleOption.templates"
                      type="button" mat-button
                      color="primary"
                      (click)="exampleClick(template.link)">
                <mat-icon>download</mat-icon>
                {{ template.title }}
              </button>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="upload-file-actions">
        <div>
          <button mat-stroked-button
                  color="primary"
                  type="button"
                  [ngClass]="sampleFileClassName"
                  (click)="fileInput.click()"
                  [disabled]="sampleIndexHeader.value === null">
            <mat-icon>file_upload</mat-icon>
            Import sample file
          </button>
          <span class="file-name">{{ sampleFile.value['filename'] }}</span>
          <mat-icon matSuffix class="valid" *ngIf="submittedContainersPreview$ | async">checked</mat-icon>
          <mat-icon matSuffix class="error" *ngIf="sampleFileErrors?.message">clear</mat-icon>
        </div>
        <div>
          <button type="button" mat-button color="primary" (click)="templatesClick()">
            <mat-icon>folder</mat-icon>
            Example files
          </button>
          <button type="button" mat-button color="primary" (click)="indexesClick()">
            <mat-icon>toc</mat-icon>
            Available indexes
          </button>
        </div>
      </div>
      <input hidden type="file" #fileInput (change)="onSampleFileChanged($event)">
      <div *ngIf="submittedContainersPreview$ | async" class="miniature">
        <nemo-request-sample-containers [showActions]="false"
                                        [showOpenLinks]="false"
                                        [containers]="submittedContainersPreview$ | async"
                                        [isIcon]="false"
                                        [indexHint]="true"
        ></nemo-request-sample-containers>
      </div>
    </div>
  `,
  styleUrls: [
    './request-submission-form-file-upload.component.scss',
    '../request-submission-form/request-submission-form.component.scss'
  ],
})
export class RequestSubmissionFormFileUploadComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() isPristine: boolean;
  @Output() sampleFileChange: EventEmitter<File> = new EventEmitter<File>();

  submittedContainersPreview$: Observable<RequestSampleContainers>;
  sampleOptions = [
    {
      title: 'I am submitting samples (DNA/RNA/Tissue, etc)',
      value: SampleFileIndexHeaderEnum.NO_INDEX,
      templates: [
        {
          title: 'Plate template file',
          link: 'I am submitting samples (DNA_RNA_Tissue, etc) - plate.csv'
        },
        {
          title: 'Tube template file',
          link: 'I am submitting samples (DNA_RNA_Tissue, etc) - tube.csv'
        }
      ],
    },
    {
      title: 'My libraries are single indexed (i7)',
      value: SampleFileIndexHeaderEnum.I7_ONLY,
      templates: [
        {
          title: 'Template file',
          link: '2My libraries are single indexed (i7) Template.csv'
        },
      ],
    },
    {
      title: 'My libraries are dual indexed (i7+i5)',
      value: SampleFileIndexHeaderEnum.I7_AND_I5,
      templates: [
        {
          title: 'Template file',
          link: 'My libraries are dual indexed (i7+i5) template.csv'
        },
      ],
    },
    {
      title: 'I am submitting libraries by the index name',
      value: SampleFileIndexHeaderEnum.INDEX_ID,
      templates: [
        {
          title: 'Template file',
          link: 'I am submitting libraries by the index name template.csv'
        },
      ]
    }
  ];

  constructor(
    private store: Store<AppState>,
    private readonly indexKitModelService: IndexKitModelService,
  ) {
  }

  ngOnInit() {
    this.submittedContainersPreview$ = this.store.select(selectGenomicsContainersPreview);
  }

  templatesClick() {
    window.open(`https://docs.google.com/drive/folders/17z9biba3N_hqUa71N7FqFmqJGHHsrHwh?usp=sharing`, '_blank');
  }

  exampleClick(example: string) {
    window.open(`/assets/template-files/${example}`, '_blank');
  }

  indexesClick() {
    this.indexKitModelService.exportIndexSequenceList()
      .subscribe(({filename, content}) => {
        const blob = new Blob([content], {type: 'text/csv'});
        saveAs(blob, filename);
      });
  }

  get sampleIndexHeader() {
    return this.formGroup.get('sampleIndexHeader');
  }

  get sampleFile() {
    return this.formGroup.get('sampleFile');
  }

  get sampleFileErrors() {
    return this.sampleFile.value && this.sampleFile.errors;
  }

  get sampleFileClassName(): string {
    if (!this.isPristine && this.sampleFileErrors) {
      return 'plate-file-error';
    } else {
      return '';
    }
  }

  async onSampleFileChanged(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      this.sampleFileChange.emit(event.target.files[0]);
    }
  }
}
