import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { RequestService } from '../services/request.service';
import {
  archiveRequest,
  archiveRequestFromListSuccess,
  archiveRequestSuccess,
  assignCroToGenomicsRequest,
  assignCroToGenomicsRequestSuccess,
  completeStage,
  completeStageSuccess,
  findAllCros,
  findAllCrosSuccess,
  findAllRequestByAccessionCodesFromAPI,
  findAllRequests,
  findAllRequestsFromHome,
  findAllRequestsSuccess,
  findRequestByAccessionCodeFromRequestPage,
  findRequestByAccessionCodeSuccess,
  findRequestError,
  reopenStage,
  reopenStageSuccess,
  restoreRequest,
  restoreRequestFromListSuccess,
  restoreRequestSuccess,
  searchAllRequests,
  submitNewRequest,
  submitNewRequestError,
  submitNewRequestSuccess,
  updateRequest,
  updateRequestDictionary,
  updateRequestFieldError,
  updateRequestFieldFromRequestPage,
  updateRequestSuccess,
} from './genomics-request.actions';
import { of } from 'rxjs';
import { NRequest, NRequestSummary } from '../models/genomics-request';
import { Router } from '@angular/router';
import { findPools } from '../../bio/store/actions';
import { findAllCommentsByEntity } from '../../comment/store/comment.actions';
import EntityPage from '../../global-context/models/entity-page';
import {
  assignUserToGenomicsRequest,
  assignUserToGenomicsRequestSuccess,
  unassignUserFromGenomicsRequest,
  unassignUserFromGenomicsRequestSuccess,
} from '../../commons/common-request/store/common-request.actions';
import { Page } from '../../shared/models/page.model';
import { toPageInfo } from '../../shared/services/page.service';
import { archiveRequestFromList, restoreRequestFromList } from '../../request-shared/actions/request-shared.actions';
import { Store } from '@ngrx/store';
import { changeArchiveFilter, selectIsArchivedOrDefaultTrue } from '../../table/store';
import { AppState } from '../../store/app.reducers';
import { Cro } from '../models/cro-request.model';

@Injectable()
export class GenomicsRequestEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private requestService: RequestService,
    private store: Store<AppState>
  ) {
  }

  loadRequests$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllRequests.type,
    ),
    concatLatestFrom(() => this.store.select(selectIsArchivedOrDefaultTrue('genomics-request'))),
    mergeMap(([action, hideArchived]) =>
	this.requestService.findAll(action['pageSize'], action['page'], null, null, null, hideArchived)
    ),
    map((page: Page<NRequestSummary>) =>
      findAllRequestsSuccess({requests: page.content, pageInfo: toPageInfo(page)})
    )
  ) });

  changeArchiveFilter$ = createEffect(() => { return this.actions$.pipe(
    ofType(changeArchiveFilter.type),
    filter((action: ReturnType<typeof changeArchiveFilter>) => action.tableId === 'genomics-request'),
    map(() => findAllRequests({pageSize: 25, page: 0}))
  ) });

  loadRequestsFromHome$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllRequestsFromHome.type
    ),
    mergeMap((action: ReturnType<typeof findAllRequestsFromHome>) => {
      const {requesterId, assignedTo} = action;
      const hideArchived = action.hideArchived != null ? action.hideArchived : true;
	return this.requestService.findAll(1000, 0, requesterId, assignedTo, null, hideArchived);
    }),
    map((page: Page<NRequestSummary>) =>
      findAllRequestsSuccess({requests: page.content, pageInfo: toPageInfo(page)})	    
    )
  ) });

  loadRequestsThroughSearch$ = createEffect(() => { return this.actions$.pipe(
    ofType(searchAllRequests.type),
      mergeMap((action: ReturnType<typeof searchAllRequests>) =>
	  this.requestService.findAll(1000, 0, action.requesterId, action.assignedTo, action.q)
    ),
    map((page: Page<NRequestSummary>) =>
      findAllRequestsSuccess({requests: page.content, pageInfo: toPageInfo(page)})
    )
  ) });

  loadRequestByAccessionCodeOnRequestPage$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      findRequestByAccessionCodeFromRequestPage.type,
    ),
    mergeMap((action: ReturnType<typeof findRequestByAccessionCodeFromRequestPage>) =>
      this.requestService.findByAccessionCode(action.accessionCode)
    ),
    map((request: NRequest) =>
      findRequestByAccessionCodeSuccess({request: request})
    )
  ) });

  findAllRequestByAccessionCodes$ = createEffect(() => { return this.actions$.pipe(
    ofType(findAllRequestByAccessionCodesFromAPI.type),
    switchMap((action: ReturnType<typeof findAllRequestByAccessionCodesFromAPI>) => {
      return action.accessionCodes.map(ac => this.requestService.findByAccessionCode(ac));
    }),
    mergeMap((x) => {
        return x.pipe(
          map(request => {
            return updateRequestDictionary({request: request});
          })
        );
      }
    ),
    catchError(error => {
      const message = (error.message) ? error.message : 'Impossible to get request by accession code  list';
      return of(findRequestError({message}));
    })
  ) });

  insertRequests$ = createEffect(() => { return this.actions$.pipe(
    ofType(submitNewRequest.type),
    mergeMap((action: ReturnType<typeof submitNewRequest>) =>
      this.requestService.submitRequest(action.request)
        .pipe(
          switchMap((newRequest) => [
            findPools(),
            submitNewRequestSuccess({request: newRequest})
          ]),
          catchError(() => of(submitNewRequestError({message: 'Error'}))),
        ),
    ),
  ) });

  successRequestSubmission$ = createEffect(() => { return this.actions$.pipe(
    ofType(submitNewRequestSuccess.type),
    tap((action: ReturnType<typeof submitNewRequestSuccess>) => {
        this.router.navigate(['/request/' + action.request.accessionCode], {queryParams: {submitted: true}});
      }
    )
  ) }, {dispatch: false});


  updateRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(updateRequest.type),
    mergeMap((action: ReturnType<typeof updateRequest>) =>
      this.requestService.updateRequest(action.request)
    ),
    map((request: NRequest) =>
      updateRequestSuccess({request})
    )
  ) });

  updateRequestField$ = createEffect(() => { return this.actions$
    .pipe(
      ofType(updateRequestFieldFromRequestPage.type),
      mergeMap((action: ReturnType<typeof updateRequestFieldFromRequestPage>) =>
        this.requestService.patchOneField(action.accessionCode, action.field, action.value)
          .pipe(
            switchMap((request: NRequest) => [
              findRequestByAccessionCodeSuccess({request: request}),
            ]),
            catchError(error => {
              const message = (error.message) ? error.message : 'Impossible to get request by accession code ' + action.accessionCode;
              return of(updateRequestFieldError({message}));
            }),
          ),
      ),
    ) });

  assignUserToRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(assignUserToGenomicsRequest.type),
    mergeMap((action: ReturnType<typeof assignUserToGenomicsRequest>) => {
      const {request, user} = action;
      return this.requestService.assignUser(request, user);
    }),
    map((newRequest: NRequest) =>
      assignUserToGenomicsRequestSuccess({request: newRequest})
    )
  ) });

  unassignUserFromRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(unassignUserFromGenomicsRequest.type),
    mergeMap((action: ReturnType<typeof unassignUserFromGenomicsRequest>) => {
      const {request, user} = action;
      return this.requestService.unassignUser(request, user);
    }),
    map((newRequest: NRequest) =>
      unassignUserFromGenomicsRequestSuccess({request: newRequest})
    )
  ) });


  markRequestAsComplete$ = createEffect(() => { return this.actions$.pipe(
    ofType(completeStage.type),
    mergeMap((action: ReturnType<typeof completeStage>) =>
      this.requestService.completeStage(action.accessionCode, action.stage)
    ),
    switchMap((request) => [
      completeStageSuccess({request}),
      findAllCommentsByEntity({entityPage: new EntityPage(request.accessionCode)})
    ])
  ) });

  markRequestAsIncomplete$ = createEffect(() => { return this.actions$.pipe(
    ofType(reopenStage.type),
    mergeMap((action: ReturnType<typeof reopenStage>) =>
      this.requestService.reopenStage(action.accessionCode, action.stage)
    ),
    switchMap((request) => [
      reopenStageSuccess({request}),
      findAllCommentsByEntity({entityPage: new EntityPage(request.accessionCode)})
    ])
  ) });

  archiveRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveRequest.type),
    mergeMap((action: ReturnType<typeof archiveRequest>) => {
      const {accessionCode} = action;
      return this.requestService.archive(accessionCode).pipe(
        map(() => accessionCode)
      );
    }),
    map((accessionCode) => archiveRequestSuccess({accessionCode}))
  ) });

  archiveRequestFromList$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveRequestFromList.type),
    mergeMap((action: ReturnType<typeof archiveRequestFromList>) => {
      const {accessionCode} = action;
      return this.requestService.archive(accessionCode).pipe(
        map(() => accessionCode)
      );
    }),
    map((accessionCode) => archiveRequestFromListSuccess({accessionCode}))
  ) });

  restoreRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreRequest.type),
    mergeMap((action: ReturnType<typeof restoreRequest>) => {
      const {accessionCode} = action;
      return this.requestService.restore(accessionCode).pipe(
        map(() => accessionCode)
      );
    }),
    map((accessionCode) => restoreRequestSuccess({accessionCode}))
  ) });

  restoreRequestFromList$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreRequestFromList.type),
    mergeMap((action: ReturnType<typeof restoreRequestFromList>) => {
      const {accessionCode} = action;
      return this.requestService.restore(accessionCode).pipe(
        map(() => accessionCode)
      );
    }),
    map((accessionCode) => restoreRequestFromListSuccess({accessionCode}))
  ) });

  loadAllCro$ = createEffect(() => { return this.actions$.pipe(
    ofType(findAllCros.type),
    mergeMap(() => this.requestService.findAllCros()),
    map((cros: Cro[]) => findAllCrosSuccess({ cros }))
  ) });

  assignCroToRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(assignCroToGenomicsRequest.type),
    mergeMap((action: ReturnType<typeof assignCroToGenomicsRequest>) =>
      this.requestService.assignCro(action.accessionCode, action.croId)
    ),
    map((request: NRequest) =>
      assignCroToGenomicsRequestSuccess({ request })
    )
  ) });

}
