import { createAction, props } from '@ngrx/store';
import { Graph } from '../models/graph';

export const findGraphByEntityAccessionCodeFromPage = createAction(
  '[Entity Page] find the connected graph QUERY',
  props<{ accessionCode: string }>()
);

export const findGraphByEntityAccessionCodeSuccess = createAction(
  '[API] find the connected graph SUCCESS',
  props<{ accessionCode: string, graph: Graph }>()
);

export const findGraphByEntityAccessionCodeError = createAction(
  '[API] find the connected graph ERROR',
  props<{ message: string }>()
);

export type GraphActions = ReturnType<typeof findGraphByEntityAccessionCodeFromPage
  | typeof findGraphByEntityAccessionCodeSuccess
  | typeof findGraphByEntityAccessionCodeError>;
