import { Routes } from '@angular/router';
import { QcTablePageComponent } from './components/qc-table-page.component';
import {
  PlateQcMeasuresPageComponent
} from './components/instrument-directory/instrument-directory-page/plate-qc-measures-page.component';
import { AuthGuard } from '../core/auth/auth.guard';

export const qcRoutes: Routes = [
  {
    path: 'qc',
    component: QcTablePageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plate-qc/:accessionCode',
    component: PlateQcMeasuresPageComponent,
    canActivate: [AuthGuard]
  }
];
