import { Routes } from '@angular/router';
import { TodoPageComponent } from './components/todo-page.component';
import { TodoIndexAssignmentComponent } from './components/todo-index-assignment.component';
import { TodoLaneAssignmentComponent } from './components/todo-lane-assignment.component';
import { TodoSelectCardComponent } from './components/todo-select-card/todo-select-card.component';
import { TodoEchoPoolingComponent } from './components/todo-echo-pooling.component';
import { TodoQcResultComponent } from './components/todo-qc-result.component';
import { AuthGuard } from '../core/auth/auth.guard';

export const todoPageRoutes: Routes = [
  {
    path: 'todo',
    component: TodoPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: TodoSelectCardComponent,
      }, {
        path: 'index-assignment',
        component: TodoIndexAssignmentComponent,
      }, {
        path: 'echo-pooling',
        component: TodoEchoPoolingComponent,
      }, {
        path: 'lane-assignment',
        component: TodoLaneAssignmentComponent,
      }, {
        path: 'qc-measures',
        component: TodoQcResultComponent,
      }
    ]
  },
];
