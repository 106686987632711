import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  actionCompleteFormRequestStage,
  actionCompleteFormRequestStageSuccess,
  actionFindAllFormRequests,
  actionFindAllFormRequestsByAssignedTo,
  actionFindAllFormRequestsByAssignedToSuccess,
  actionFindAllFormRequestsByRequester,
  actionFindAllFormRequestsByRequesterSuccess,
  actionFindAllFormRequestsError,
  actionFindAllFormRequestsSuccess,
  actionFindFormRequestByAccessionCodeFromRequestPage,
  actionFindFormRequestByAccessionCodeSuccess,
  actionModifyFormRequest,
  actionModifyFormRequestSuccess,
  actionReopenFormRequestStage,
  actionReopenFormRequestStageSuccess,
  actionSearchAllFormRequests,
  actionSubmitFormRequest,
  actionSubmitFormRequestSuccess,
  archiveFormRequestFromList,
  archiveFormRequestFromListSuccess,
  restoreFormRequestFromList,
  restoreFormRequestFromListSuccess
} from './form-request.actions';
import { FormRequestService } from '../../services/form-request.service';
import { FormRequest, FormRequestSummary } from '../../models/form-request';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { findAllCommentsByEntity } from '../../../comment/store/comment.actions';
import EntityPage from '../../../global-context/models/entity-page';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { FormRequestListFilter } from '../../models/form-request-list-filter';
import {
  assignUserToFormRequest,
  assignUserToFormRequestSuccess,
  unassignUserFromFormRequest,
  unassignUserFromFormRequestSuccess
} from '../../../commons/common-request/store/common-request.actions';
import { changeArchiveFilter, getArchiveFilter } from '../../../table/store';
import { selectGlobalContextLab } from '../../../global-context/store/global-context.selectors';

@Injectable()
export class FormRequestEffects {
  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private formRequestService: FormRequestService
  ) {
  }

  create$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionSubmitFormRequest),
    mergeMap((action: ReturnType<typeof actionSubmitFormRequest>) => {
        const {formRequestEdition} = action;
        return this.formRequestService.create(formRequestEdition);
      }
    ),
    map(formRequest => actionSubmitFormRequestSuccess({formRequest}))
  ) });

  createSuccess$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionSubmitFormRequestSuccess),
    tap((action: ReturnType<typeof actionSubmitFormRequestSuccess>) => {
      this.router.navigate(
        ['/form-request/' + action.formRequest.accessionCode],
        {queryParams: {submitted: true}}
      );
    })
    ) },
    {dispatch: false}
  );

  modify$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionModifyFormRequest),
    mergeMap((action: ReturnType<typeof actionModifyFormRequest>) => {
        const {formRequestEdition} = action;
        return this.formRequestService.modify(formRequestEdition);
      }
    ),
    map(formRequest => actionModifyFormRequestSuccess({formRequest}))
  ) });

  modifySuccess$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionModifyFormRequestSuccess),
    map((action: ReturnType<typeof actionModifyFormRequestSuccess>) =>
      actionFindFormRequestByAccessionCodeFromRequestPage({accessionCode: action.formRequest.accessionCode})
    )
  ) });


  findByAccessionCode$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFindFormRequestByAccessionCodeFromRequestPage),
    mergeMap((action: ReturnType<typeof actionFindFormRequestByAccessionCodeFromRequestPage>) => {
        return this.formRequestService.findByAccessionCode(action.accessionCode);
      }
    ),
    map((formRequest: FormRequest) =>
      actionFindFormRequestByAccessionCodeSuccess({formRequest})
    ),
    catchError(error => {
      console.error(error);
      return of(actionFindAllFormRequestsError({message: error.message}));
    })
  ) });

  loadComments$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      actionFindFormRequestByAccessionCodeSuccess,
      actionCompleteFormRequestStageSuccess
    ),
    map((action: ReturnType<typeof actionFindFormRequestByAccessionCodeSuccess>) =>
      findAllCommentsByEntity({entityPage: new EntityPage(action.formRequest.accessionCode)})
    )
  ) });

  findAll$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFindAllFormRequests),
    concatLatestFrom(
      () => this.store$.select(selectGlobalContextLab).pipe(
        
        map(lab => lab)
      )
    ),
    concatLatestFrom(
      () => this.store$.select(getArchiveFilter('form-request')).pipe(
        
        map(checked => checked === undefined ? true : checked)
      )
    ),
    mergeMap(([[, lab], hideArchived]) => {
        return this.formRequestService.findAll({
          lab,
          hideArchived
        } as FormRequestListFilter);
      }
    ),
    map((formRequests: FormRequestSummary[]) => actionFindAllFormRequestsSuccess({formRequests})),
    catchError(error => {
      return of(actionFindAllFormRequestsError({message: error.message}));
    })
  ) });

  searchAllRequests$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionSearchAllFormRequests.type),
    mergeMap((action: ReturnType<typeof actionSearchAllFormRequests>) =>
      this.formRequestService.find(action.requesterId, action.assignedTo, action.q)
    ),
    map((formRequests: FormRequestSummary[]) => actionFindAllFormRequestsSuccess({formRequests})),
  ) });

  changeArchiveFilter$ = createEffect(() => { return this.actions$.pipe(
    ofType(changeArchiveFilter.type),
    filter((action: ReturnType<typeof changeArchiveFilter>) => action.tableId === 'form-request'),
    map(() => actionFindAllFormRequests())
  ) });

  findAllByRequester$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFindAllFormRequestsByRequester),
    mergeMap((action: ReturnType<typeof actionFindAllFormRequestsByRequester>) => {
        return this.formRequestService.findAll({
          lab: action.lab,
          requester: action.requester,
          hideArchived: action.hideArchived
        } as FormRequestListFilter);
      }
    ),
    map((formRequests: FormRequestSummary[]) => actionFindAllFormRequestsByRequesterSuccess({formRequests})),
    catchError(error => {
      return of(actionFindAllFormRequestsError({message: error.message}));
    })
  ) });

  findAllByAssignedTo$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionFindAllFormRequestsByAssignedTo),
    mergeMap((action: ReturnType<typeof actionFindAllFormRequestsByAssignedTo>) => {
        return this.formRequestService.findAll({
          lab: action.lab,
          assignedTo: action.assignedTo,
          hideArchived: action.hideArchived
        } as FormRequestListFilter);
      }
    ),
    map((formRequests: FormRequestSummary[]) => actionFindAllFormRequestsByAssignedToSuccess({formRequests})),
    catchError(error => {
      return of(actionFindAllFormRequestsError({message: error.message}));
    })
  ) });

  completeStage$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionCompleteFormRequestStage),
    mergeMap((action: ReturnType<typeof actionCompleteFormRequestStage>) => {
      return this.formRequestService.complete(action.accessionCode, action.stageKey);
    }),
    map((formRequest: FormRequest) =>
      actionCompleteFormRequestStageSuccess({formRequest})
    )
  ) });

  reopenStage$ = createEffect(() => { return this.actions$.pipe(
    ofType(actionReopenFormRequestStage),
    mergeMap((action: ReturnType<typeof actionReopenFormRequestStage>) =>
      this.formRequestService.reopen(action.accessionCode, action.stageKey)
    ),
    map((formRequest: FormRequest) =>
      actionReopenFormRequestStageSuccess({formRequest})
    )
  ) });

  assignUserToRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(assignUserToFormRequest.type),
    mergeMap((action: ReturnType<typeof assignUserToFormRequest>) => {
      const {accessionCode, user} = action;
      return this.formRequestService.assignUser(accessionCode, user);
    }),
    map((formRequest: FormRequest) =>
      assignUserToFormRequestSuccess({formRequest})
    )
  ) });

  unassignUserFromRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(unassignUserFromFormRequest.type),
    mergeMap((action: ReturnType<typeof unassignUserFromFormRequest>) => {
      const {accessionCode, user} = action;
      return this.formRequestService.unassignUser(accessionCode, user);
    }),
    map((formRequest: FormRequest) =>
      unassignUserFromFormRequestSuccess({formRequest})
    )
  ) });

  archiveRequestFromList$ = createEffect(() => { return this.actions$.pipe(
    ofType(archiveFormRequestFromList.type),
    mergeMap((action: ReturnType<typeof archiveFormRequestFromList>) => {
      const {accessionCode} = action;
      return this.formRequestService.archive(accessionCode).pipe(
        map(() => accessionCode)
      );
    }),
    map((accessionCode) => archiveFormRequestFromListSuccess({accessionCode}))
  ) });

  restoreRequestFromList$ = createEffect(() => { return this.actions$.pipe(
    ofType(restoreFormRequestFromList.type),
    mergeMap((action: ReturnType<typeof restoreFormRequestFromList>) => {
      const {accessionCode} = action;
      return this.formRequestService.restore(accessionCode).pipe(
        map(() => accessionCode)
      );
    }),
    map((accessionCode) => restoreFormRequestFromListSuccess({accessionCode}))
  ) });


}
