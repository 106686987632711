import { LibraryMethod } from '../models/library-method';
import { Action, createReducer, on } from '@ngrx/store';
import { findAllLibraryMethodsSuccess } from './actions/library-method.actions';
import { SequencerModel } from '../models/sequencer-model';
import { findAllSequencerModelsSuccess } from './actions/sequencer-model.actions';

export default interface SequencingState {
  libraryMethods: LibraryMethod[];
  sequencerModels: SequencerModel[];
}
export const initialState: SequencingState = {
  libraryMethods: [],
  sequencerModels: []

};


const _reducer = createReducer(
  initialState,
  on(findAllLibraryMethodsSuccess, (state, {libraryMethods}) => ({...state, libraryMethods})),
  on(findAllSequencerModelsSuccess, (state, {sequencerModels}) => ({...state, sequencerModels})),
);


export function reducer(state: SequencingState | undefined, action: Action) {
  return _reducer(state, action);
}
