import { createAction, props } from '@ngrx/store';
import { Study } from '../models/Study';

export const findAllStudiesAtInit = createAction(
  '[Init Data] find all studies'
);
export const findAllStudiesAfterUpdate = createAction(
  '[After update] find all studies'
);

export const findAllStudiesSuccess = createAction(
  '[Study API]  find all studies list Success',
  props<{ studies: Study[] }>()
);

export const findAllStudiesError = createAction(
  '[Studies API]  find all studies Error',
  props<{ message: string }>()
);

export const createStudy = createAction(
  '[Studies page] create new study',
  props<{ name: string }>()
);

export const archiveStudy = createAction(
  '[Studies page] archive study',
  props<{ study: Study }>()
);

export const restoreStudy = createAction(
  '[Studies page] restore study',
  props<{ study: Study }>()
);

export type StudyAction = ReturnType<| typeof findAllStudiesAtInit
  | typeof findAllStudiesAfterUpdate
  | typeof findAllStudiesSuccess
  | typeof findAllStudiesError>;
