<div class="title">
  <h2>
    Search results
  </h2>

  <div *ngIf="showDetailedTable;then btnSummary else btnDetail"></div>

  <ng-template #btnSummary>
    <button mat-button *ngIf="showDetailedTable" (click)="changeShowDetail()">
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#202124" d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z"/>
      </svg>
    </button>
  </ng-template>

  <ng-template #btnDetail>
    <button mat-button els="!showDetailedTable" (click)="changeShowDetail()">
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#202124"
              d="M3,13H5V11H3V13M3,17H5V15H3V17M3,9H5V7H3V9M7,13H21V11H7V13M7,17H21V15H7V17M7,7V9H21V7H7Z"/>
      </svg>
    </button>
  </ng-template>
</div>

<nemo-genomics-request-list
  [requests]="genomicsRequests$ |async"
></nemo-genomics-request-list>
