import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import UserState from './user.reducer';


export const getUserState = (state: AppState) => state.user;

export const getLoggedInUser =
  createSelector(getUserState, (state: UserState) => state.loggedIn);


export const getPendingUserLoad =
  createSelector(getUserState, (state: UserState) => state.pendingUserLoad);

export const getUserFromId = (userId: string) =>
  createSelector(getUserState, (state: UserState) => state.userDict[userId]);

export const getUserDictionary =
  createSelector(getUserState, (state: UserState) => state.userDict);

export const getAllowedUsersForReport =
  createSelector(getUserState, (state: UserState) => state.allowedUsersForReport);
