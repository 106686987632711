import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as fromIndexKit from '../../store';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';
import { SimpleIndexKit } from '../../models/indexKit';
import { UntypedFormControl } from '@angular/forms';
import { flatMap, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'nemo-select-index-kit',
  template: `
    <mat-form-field class="index-kit-select" appearance="outline" *ngIf="(indexKits$ | async).length >= 1">
      <input type="text" #autoCompleteInput matInput [matAutocomplete]="auto" [formControl]="indexKitCtrl"
             placeholder="Select an existing index kit plate">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectIndexKit($event)" [displayWith]="displayBarcode">
      <mat-option *ngFor="let indexKit of filteredIndexKits$ | async" [value]="indexKit">
        <nemo-select-index-kit-item [indexKit]="indexKit"></nemo-select-index-kit-item>
      </mat-option>
      <mat-option *ngIf="(filteredIndexKits$ |async).length === 0" disabled="true" class="no-matching">
        No matching index kit.
      </mat-option>
    </mat-autocomplete>
  `,
  styles: [`
    .index-kit-select {
      width: 35em;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    mat-option {
      height: unset;
    }
  `]
})
export class SelectIndexKitComponent implements OnInit {

  @Output() selectedIndexKitChange = new EventEmitter();

  indexKitCtrl = new UntypedFormControl();
  filteredIndexKits$: Observable<SimpleIndexKit[]>;
  indexKits$: Observable<SimpleIndexKit[]>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.indexKits$ = this.store.select((fromIndexKit.getIndexKits));

    this.filteredIndexKits$ = this.indexKitCtrl.valueChanges
      .pipe(
        startWith(''),
        flatMap(indexKit => {
          return indexKit ? this._filterIndexKit(indexKit) : this.indexKits$;
        })
      );
  }

  private _filterIndexKit(value: string | SimpleIndexKit): Observable<SimpleIndexKit[]> {
    if (typeof value === 'object') {
      return this.indexKits$;
    }
    const filterValue = value.toLowerCase();
    return this.indexKits$.pipe(
      map((indexKits: SimpleIndexKit[]) =>
        indexKits.filter((indexKit: SimpleIndexKit) => indexKit.barcode.toLowerCase().indexOf(filterValue) === 0)
      ),
    );
  }

  selectIndexKit(event) {
    const kit = event.option.value;
    this.selectedIndexKitChange.emit(kit);
  }

  displayBarcode(value: SimpleIndexKit | null) {
    return value ? value.barcode : null;
  }
}
