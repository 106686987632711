import { PlateCoordinates } from '../../../labware/models/plate-coordinates';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PLATE_BASE_URI } from '../../../labware/services/plate-api.service';
import { JSON_CONTENT } from '../../../constants/http';

@Injectable({providedIn: 'root'})
export class RearrayApiService {

  constructor(private http: HttpClient) {
  }

  reArray(plateAccessionCode: string, reArrayDtos: Array<ReArrayDto>): Observable<void> {
    const url = PLATE_BASE_URI + '/' + plateAccessionCode + '/re-array';
    return this.http.post<void>(url, reArrayDtos, {headers: JSON_CONTENT});
  }

  undoReArray(plateAccessionCode: string, plateCoordinates: Array<PlateCoordinates>): Observable<void> {
    const url = PLATE_BASE_URI + '/' + plateAccessionCode + '/re-array';
    return this.http.request<void>('delete', url, {headers: JSON_CONTENT, body: plateCoordinates});
  }
}

export class ReArrayDto {
  constructor(
    public readonly poolAccessionCode: string,
    public readonly coordinates: PlateCoordinates
  ) {
  }
}
