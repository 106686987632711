import { IndexSequence, parseTerminusType } from '../../bio/models/barcode-index';

export class IndexSequenceDto {
  terminus: string;
  sequence: string;
}

export function indexSequencesFromDto(sequences: IndexSequenceDto[]) {
  return sequences.map(seq => {
    return new IndexSequence(parseTerminusType(seq.terminus), seq.sequence);
  });
}
