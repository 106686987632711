import { Component, Input } from '@angular/core';
import { TaskAvailable } from '../../../tasks/models/task.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import {
  dismissPlateQCFromList,
  dismissPlateQCFromPage,
  recoverPlateQCFromList,
  recoverPlateQCFromPage, reimportPlateQCFromPage
} from '../../store/actions/qc-tasks.action';
import {
  unassignPlateQCFromInstrumentDirectoryList,
  unassignPlateQCFromInstrumentDirectoryPage
} from '../../../tasks/store/task.action';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { PlateQC } from '../../models/plate-qc-measures';

export enum PlateQcTasksButtonsContext {
  PLATE_QC_LIST,
  PLATE_QC_PAGE
}

@Component({
  selector: 'nemo-plate-qc-tasks-buttons',
  template: `
    <div
      *ngIf="isLaber$ | async"
    >
      <button *ngIf="reimportable"
              (click)="reimport()"
              matTooltip="Directory {{plateQC.accessionCode | accessionCode}} contains incomplete QC data"
              mat-raised-button color="accent">
        Reimport
      </button>
      <button *ngIf="dismissable"
              (click)="dismiss()"
              matTooltip="Directory {{plateQC.accessionCode | accessionCode}} is flagged as not containing relevant QC data"
              mat-raised-button color="primary">
        Dismiss
      </button>

      <button *ngIf="recoverable"
              (click)="recover()"
              matTooltip="Directory {{plateQC.accessionCode | accessionCode}} will be brought back active"
              mat-raised-button color="primary">
        Recover
      </button>
      <button *ngIf="assignableToPlate"
              mat-raised-button color="primary"
              matTooltip="Select a plate to be assigned to directory {{plateQC.accessionCode | accessionCode}}"
              routerLink="/tasks/plate-qc-assign/{{plateQC.accessionCode}}"
      >
        Assign to plate
      </button>
      <button *ngIf="unassignableFromPlate"
              (click)="unassignFromPlate()"
              matTooltip="Cancel the directory/plate assignment for  {{plateQC.accessionCode | accessionCode}}"
              mat-raised-button color="primary">
        unassign from plate
      </button>
    </div>

  `,
  styles: [`
    button {
      margin-right: 10px;
    }
  `]
})
export class PlateQcTasksButtonsComponent extends HasGlobalContextUserRole {

  reimportable = false;
  dismissable = false;
  recoverable = false;
  assignableToPlate = false;
  unassignableFromPlate = false;

  @Input() plateQC: PlateQC;

  @Input() context: PlateQcTasksButtonsContext;

  @Input() set availableForTasks(value: TaskAvailable[]) {
    this.reimportable = false;
    this.dismissable = false;
    this.recoverable = false;
    this.assignableToPlate = false;
    this.unassignableFromPlate = false;

    if (value) {
      value.forEach(task => {
        switch (task) {
          case TaskAvailable.QC_PLATE_REIMPORT:
            this.reimportable = true;
            return;
          case TaskAvailable.QC_PLATE_DISMISS:
            this.dismissable = true;
            return;
          case TaskAvailable.QC_PLATE_RECOVER:
            this.recoverable = true;
            return;
          case TaskAvailable.QC_PLATE_ASSIGN_TO_FILE:
            this.assignableToPlate = true;
            return;
          case TaskAvailable.QC_PLATE_UNASSIGN_FROM_FILE:
            this.unassignableFromPlate = true;
            return;
          default:
            return;
        }
      });
    }
  }

  constructor(
    private store: Store<AppState>
  ) {
    super(store);
  }

  reimport() {
    this.store.dispatch(reimportPlateQCFromPage({accessionCode: this.plateQC.accessionCode}));
  }

  dismiss() {
    const action = (this.context === PlateQcTasksButtonsContext.PLATE_QC_LIST) ?
      dismissPlateQCFromList :
      dismissPlateQCFromPage;

    this.store.dispatch(action({accessionCode: this.plateQC.accessionCode}));
  }

  recover() {
    const action = (this.context === PlateQcTasksButtonsContext.PLATE_QC_LIST) ?
      recoverPlateQCFromList :
      recoverPlateQCFromPage;

    this.store.dispatch(action({accessionCode: this.plateQC.accessionCode}));
  }

  unassignFromPlate() {
    const action = (this.context === PlateQcTasksButtonsContext.PLATE_QC_LIST) ?
      unassignPlateQCFromInstrumentDirectoryList :
      unassignPlateQCFromInstrumentDirectoryPage;

    this.store.dispatch(action({
      plateAccessionCode: this.plateQC.plate.accessionCode,
      instrumentDirectoryAccessionCode: this.plateQC.accessionCode
    }));

  }
}
