import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash';

import { QuestionSampleNameOption } from '../../models/form';
import { samplenamesFromString } from '../../models/samplename-option';

@Component({
  selector: 'nemo-form-question-samplename-list',
  template: `
    <div class="container">
      <h4>{{question.title}}
      </h4>
      <div
        *ngIf="question.description"
        class="description"
      >{{question.description}}</div>
      <div
        [formGroup]="inputFromGroup"
        class="new-samples-input"
      >
        <div class="sample-names">
          <div class="add-button">
            <mat-slide-toggle
              formControlName="sameAsAbove"
              (change)="sameAsAbove()"
              labelPosition="before"
              color="primary"
            >Same as above
            </mat-slide-toggle>
          </div>
          <mat-form-field
            [ngClass]="{'hide-sample-names':hideSamplenameTextarea}"
          >
            <mat-label>Sample names</mat-label>
            <textarea
              matInput
              formControlName="sampleNames"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="5"
              (change)="changeTextArea()"
            ></textarea>
            <mat-hint align="start"
            >with commas or on new lines (e.g. "CR1,CR2,CR3,CR4"), or as ranges (e.g. "CR1-4")
            </mat-hint>

          </mat-form-field>
        </div>
      </div>

    </div>`,
  styleUrls: [
    './form-question.component.scss',
    './form-question-sample-name-list.scss'
  ]
})
export class FormQuestionSampleNameListComponent implements OnInit {
  @Input()
  question: QuestionSampleNameOption;

  @Input()
  sectionFormGroup: UntypedFormGroup;

  inputFromGroup: UntypedFormGroup;

  hideSamplenameTextarea = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(private _ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.inputFromGroup = new UntypedFormGroup({
      sampleNames: new UntypedFormControl('', []),
      sameAsAbove: new UntypedFormControl(false, [])
    });

    const sampleNames = this.sectionFormGroup.value[this.question.key] || [];
    this.inputFromGroup.get('sampleNames').setValue(sampleNames.join(','));
  }

  sameAsAbove() {
    this.hideSamplenameTextarea = this.inputFromGroup.value['sameAsAbove'];
    if (this.inputFromGroup.value['sameAsAbove']) {
      this.sectionFormGroup.get(this.question.key).setValue(['SAME_AS_ABOVE']);
    } else {
      this.changeTextArea();
    }
  }

  changeTextArea() {
    const text = this.inputFromGroup.value['sampleNames'];
    const sampleNames = _.uniq(samplenamesFromString(text));
    this.sectionFormGroup.get(this.question.key).setValue(sampleNames);

    this.inputFromGroup.get('sampleNames').setValue(sampleNames.join(', '));
  }
}
