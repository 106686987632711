import { Component, Input } from '@angular/core';
import { SimplePlateWithRequests } from '../../models/simple-plate';
import { PlateSnippet } from '../../dto/labware.dto';

@Component({
  selector: 'nemo-select-plate-snippet-item',
  template: `
    <nemo-plate-icon [dimensions]="plateSnippet.dimensions"
                     [occupancy]="plateSnippet.occupancy"
                     [small]="true"
                     class="dropdown"
    ></nemo-plate-icon>
    <div>
      <p class="plate-line">
        <strong class="accession-code">{{plateSnippet.accessionCode | accessionCode}}</strong>
        ({{plateSnippet.numberOfSamples}} samples and {{plateSnippet.numberOfIndexedSamples}} indexed)
      </p>
      <p class="description-line">
        <span>{{plateSnippet.title}}</span>
      </p>
      <p class="description-line">
        <span *ngFor="let request of plateSnippet.requests; last as isLast"
              class="accession-code">
          {{request.accessionCode | accessionCode}} by {{request.requesterName}}
          on {{request.createdAt | date : "short"}}
          <br *ngIf="!isLast"/>
        </span>
      </p>
    </div>
  `,
  styles: [`
             :host {
               display: flex;
             }

             div {
               margin-top: auto;
               margin-bottom: auto;
             }

             .plate-line {
               font: normal 14px Roboto, sans-serif;
               margin: 0;
             }

             .description-line {
               font: normal 12px Roboto, sans-serif;
               margin: 0;
             }

             nemo-plate-icon.dropdown {
               zoom: 33%;
               margin-right: 1em;
             }
           `]
})
export class SelectPlateSnippetItemComponent {
  @Input() plateSnippet: PlateSnippet;
}
