/**
 * holds the Index
 * in JS, it is better not to name a class index...
 */
import { BioMaterial, BioMaterialTypes } from './bio-material';
import { IndexKitSummary } from '../../index/models/indexKit';
import { PlateCoordinates } from '../../labware/models/plate-coordinates';
import { SimpleIndexKitModel } from '../../index/models/indexKitModel';

export class Index extends BioMaterial {
  readonly type = BioMaterialTypes.index;

  constructor(
    public readonly id: number,
    public readonly kit: IndexKitSummary | null,
    public readonly used: boolean,
    public readonly model: IndexModel | null,
    public readonly sequences: IndexSequence[]
  ) {
    super(id?.toString(), used);
  }

  hasTermini(termini: TerminusTypes) {
    return Boolean(this.sequences.find(x => x.terminus === termini));
  }

  /**
   * return sequences nucleotides
   */
  terminiSequences(termini: TerminusTypes): string[] {
    return this.sequences
      .filter(x => x.terminus === termini)
      .map(x => x.sequence);
  }
}

export class IndexModel {
  id: number;
  name: string;
  plateCoordinates: PlateCoordinates;
  kitModel: SimpleIndexKitModel;
}

export class IndexSequence {
  constructor(
    public readonly terminus: TerminusTypes,
    public readonly sequence: string,
  ) {
  }

  toString() {
    return this.sequence;
  }
}

export enum TerminusTypes {
  I5 = 'I5',
  I7 = 'I7',
}

export class IndexTermini {
  i7Sequence: string | null;
  i5Sequence: string | null;
}

export function parseTerminusType(str: string): TerminusTypes {
  return <TerminusTypes>TerminusTypes[str.toUpperCase()];
}
