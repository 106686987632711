<div class="container">
  <div *ngIf="showOpenLink" class="open-link">
    <a [routerLink]="'/pool/' + poolAccessionCode">open</a>
    <mat-icon [routerLink]="'/pool/' + poolAccessionCode">open_in_new</mat-icon>
  </div>
  <div class="title">{{ poolTitle }}</div>
  <div class="layout">
    <svg [attr.height]="height"
         [attr.width]="width"
         [attr.viewBox]="'-0.2 0 0.4 ' + (isIcon ? 1.01 : 1.3)"
         preserveAspectRatio="xMidYMin meet">

      <circle cx="0" cy="0.83" r="0.15" class="tube-stroke"></circle>
      <line x1="-0.15" x2="-0.15" y1="0.83" y2="0.02" class="tube-stroke"></line>
      <line x1="0.15" x2="0.15" y1="0.83" y2="0.02" class="tube-stroke"></line>
      <line x1="0.15" x2="0.2" y1="0.02" y2="0.02" class="tube-stroke"></line>
      <line x1="-0.15" x2="-0.2" y1="0.02" y2="0.02" class="tube-stroke"></line>

      <circle cx="0" cy="0.83" r="0.15" class="tube-background"></circle>
      <rect x="-0.15" y="0.02" width="0.3" height="0.81" class="tube-background"></rect>

      <circle cx="0" cy="0.83" r="0.15" [ngClass]="requestClass" [style.fill]="color"></circle>
      <rect x="-0.15" y="0.12" width="0.3" height="0.71" [ngClass]="requestClass" [style.fill]="color"></rect>

      <circle *ngFor="let r of rndX; index as i"
              [attr.r]="indexBeadRadius"
              [attr.cx]="r*0.1"
              [attr.cy]="0.15+0.76*i/poolNumberOfSamples"
              fill="orange"
              class="request-background"></circle>

      <text *ngIf="!isIcon" y="1.38">{{ poolNumberOfSamples }}</text>
    </svg>
  </div>
</div>

