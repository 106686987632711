import { Component, Input } from '@angular/core';
import { PlateQcTasksButtonsContext } from '../../../shared/components/plate-qc-tasks-buttons.component';
import { PlateQC } from '../../../models/plate-qc-measures';

@Component({
  selector: 'nemo-plate-qc-measures-header',
  template: `
    <div class="container">
      <div>
        <div class="title">Plate QC ({{plateQC.instrumentFile.fileType}}) <span
          class="accession-code">{{plateQC.accessionCode|accessionCode}}</span></div>
        <div class="path">{{plateQC.instrumentFile.path}}</div>

        <div
          *ngIf="plateQC.plate"
          class="assigned-to-plate"
        >
          <mat-icon>straighten</mat-icon>
          This directory has been assigned to plate
          <a routerLink="/{{plateQC.plate && plateQC.plate.accessionCode}}">
            {{(plateQC.plate && plateQC.plate.accessionCode) | accessionCode}}
          </a>
        </div>
      </div>
      <div>
        <nemo-plate-qc-tasks-buttons
          [plateQC]="plateQC"
          [availableForTasks]="plateQC.availableForTasks"
          [context]="context"
        ></nemo-plate-qc-tasks-buttons>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
             }

             div.title {
               font-size: 1.5rem;
               font-weight: bolder;
             }

             div.assigned-to-plate {
               margin-bottom: 0.2rem;
               margin-top: 0.2rem;
               font-size: 1.3rem;
               font-weight: bolder;
             }
           `]
})
export class PlateQcMeasuresHeaderComponent {
  @Input()
  plateQC: PlateQC;

  context = PlateQcTasksButtonsContext.PLATE_QC_PAGE;
}
