import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowCellViewerComponent } from './components/flowcell-viewer/flow-cell-viewer.component';
import { WellSelectionInputComponent } from './components/well-selection-input/well-selection-input.component';
import { PlateIconComponent } from './components/plate-icon.component';
import { PlateViewer2DComponent } from './components/plate-viewer/plate-viewer-2d.component';
import { PlateViewerLegendComponent } from './components/plate-viewer/plate-viewer-legend.component';
import { AddFlowCellDialogComponent } from './components/add-flow-cell-dialog/add-flow-cell-dialog.component';
import { MaterialModule } from '../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PlateViewerContainerComponent } from './components/plate-viewer-container/plate-viewer-container.component';
import { FlowCellSelectedContainerComponent } from './components/flow-cell-selected-container/flow-cell-selected-container.component';
import { AddPlateDialogComponent } from './components/add-plate-dialog/add-plate-dialog.component';
import { SelectPlateComponent } from './components/select-plate.component';
import { SelectPlateItemComponent } from './components/select-plate-item.component';
import { SharedSharedModule } from '../../shared/shared/shared-shared.module';
import { PlateListComponent } from './components/plate-list/plate-list.component';
import { PlateIndexingCompletionComponent } from './components/plate-indexing-completion.component';
import { RequestSharedModule } from '../../request-shared/request-shared.module';
import { RouterModule } from '@angular/router';
import { FlowCellTableComponent } from './components/flow-cell-table/flow-cell-table.component';
import { TableModule } from '../../table/table.module';
import { PlateTableDetailComponent } from './components/plate-table-detail/plate-table-detail.component';
import {
  BioMaterialAccessionCodePipe,
  BioMaterialAssignedIndexI5Pipe,
  BioMaterialAssignedIndexI7Pipe,
  BioMaterialAssignedIndexKitPipe,
  BioMaterialAssignedIndexPipe,
  BioMaterialAssignedIndexPositionPipe,
  BioMaterialCoordinatesPipe,
  BioMaterialNamesPipe,
  GetOccupancyPipe,
  PlateSamplesPipe,
  NonEmptyBioMaterialMappingsPipe,
  RequestAccessionCodePipe,
  SampleRequestAccessionCodesPipe
} from './pipe/get-occupancy.pipe';
import { PlateExportButtonComponent } from './components/plate-export-button.component';
import { PlateTasksButtonsComponent } from './components/plate-tasks-buttons.component';
import {
  FlowCellExportPipelineButtonComponent
} from './components/flow-cell-export/flow-cell-export-pipeline-button.component';
import {
  FlowCellNotifyButtonComponent
} from './components/flow-cell-notify/flow-cell-notify-button.component';
import { SelectPlateSnippetComponent } from './components/select-plate-snippet.component';
import { SelectPlateSnippetItemComponent } from './components/select-plate-snippet-item.component';

@NgModule({
  declarations: [
    AddFlowCellDialogComponent,
    AddPlateDialogComponent,
    BioMaterialAccessionCodePipe,
    BioMaterialAssignedIndexI5Pipe,
    BioMaterialAssignedIndexI7Pipe,
    BioMaterialAssignedIndexKitPipe,
    BioMaterialAssignedIndexPipe,
    BioMaterialAssignedIndexPositionPipe,
    BioMaterialCoordinatesPipe,
    BioMaterialNamesPipe,
    FlowCellExportPipelineButtonComponent,
    FlowCellNotifyButtonComponent,
    FlowCellSelectedContainerComponent,
    FlowCellTableComponent,
    FlowCellViewerComponent,
    GetOccupancyPipe,
    NonEmptyBioMaterialMappingsPipe,
    NonEmptyBioMaterialMappingsPipe,
    PlateExportButtonComponent,
    PlateIconComponent,
    PlateIndexingCompletionComponent,
    PlateListComponent,
    PlateSamplesPipe,
    PlateTableDetailComponent,
    PlateTasksButtonsComponent,
    PlateViewer2DComponent,
    PlateViewerContainerComponent,
    PlateViewerLegendComponent,
    RequestAccessionCodePipe,
    SampleRequestAccessionCodesPipe,
    SampleRequestAccessionCodesPipe,
    SelectPlateComponent,
    SelectPlateItemComponent,
    SelectPlateSnippetComponent,
    SelectPlateSnippetItemComponent,
    WellSelectionInputComponent,
  ],
  exports: [
    BioMaterialAccessionCodePipe,
    BioMaterialAssignedIndexI5Pipe,
    BioMaterialAssignedIndexI7Pipe,
    BioMaterialCoordinatesPipe,
    BioMaterialNamesPipe,
    FlowCellExportPipelineButtonComponent,
    FlowCellNotifyButtonComponent,
    FlowCellSelectedContainerComponent,
    FlowCellTableComponent,
    FlowCellViewerComponent,
    NonEmptyBioMaterialMappingsPipe,
    PlateExportButtonComponent,
    PlateIconComponent,
    PlateListComponent,
    PlateSamplesPipe,
    PlateTableDetailComponent,
    PlateTasksButtonsComponent,
    PlateViewer2DComponent,
    PlateViewerContainerComponent,
    RequestAccessionCodePipe,
    SampleRequestAccessionCodesPipe,
    SelectPlateComponent,
    SelectPlateSnippetComponent,
    SelectPlateSnippetItemComponent,
    WellSelectionInputComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RequestSharedModule,
    RouterModule,
    SharedSharedModule,
    TableModule,
  ],
  providers: [
    BioMaterialAssignedIndexI5Pipe,
    BioMaterialAssignedIndexI7Pipe,
  ]
})
export class LabWareSharedModule {
}
