import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nemo-switch-button',
  template: `
      <button mat-raised-button
              color="{{archived ? 'secondary' : 'primary'}}"
              (click)="changeStatus()">{{archived ? 'Unarchive' : 'Archive'}}
      </button>
  `
})
export class SwitchButtonComponent {

  @Input() archived: boolean;
  @Output() event = new EventEmitter<boolean>();

  changeStatus() {
    this.archived = !this.archived;
    this.event.emit(this.archived);
  }
}
