export class FormType {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly lab: string,
    public readonly createdAt: Date,
    public readonly archived: boolean
  ) {
  }
}

export class FormTypeWithSections {
  constructor(
    public readonly name: string,
    public readonly lab: string,
    public readonly title: string,
    public readonly description: string,
    public readonly sections: FormSectionSummary[]
  ) {
  }
}

export class FormSectionSummary {
  constructor(
    public readonly key: string,
    public readonly title: string,
    public readonly description: string
  ) {
  }
}
