import { Component, Input } from '@angular/core';
import { SimpleIndexKit } from '../../models/indexKit';

@Component({
  selector: 'nemo-select-index-kit-item',
  template: `
    <nemo-plate-icon
      class="dropdown"
      [dimensions]="indexKit.dimensions"
      [occupancy]="indexKit.occupancy"
      [small]="true"></nemo-plate-icon>
    <div>
      <p class="indexKit-line">
        <strong class="accession-code">{{indexKit.barcode}}</strong> ({{indexKit.numberOfAvailableIndexes}} available)
      </p>
      <p class="indexKitModel-line">{{indexKit.indexKitModel}}</p>
    </div>

  `,
  styles: [`
    :host {
      display: flex;
    }

    div {
      margin-top: auto;
      margin-bottom: auto;
    }

    .indexKit-line {
      font: normal 14px Roboto, sans-serif;
      margin: 0;
    }

    .indexKitModel-line {
      font: normal 12px Roboto, sans-serif;
      margin: 0;
    }

    nemo-plate-icon.dropdown {
      zoom: 33%;
      margin-right: 1em;
    }
  `]
})
export class SelectIndexKitItemComponent {
  @Input() indexKit: SimpleIndexKit;
}
