<div class="container">

  <div *ngIf="plate | nonEmptyBioMaterialMappings as mappings">
    <nemo-index-assignment-export-csv
      [mappings]="mappings"
      [requests]="requestList$ | async"
      [plate]="plate"
    >
    </nemo-index-assignment-export-csv>
  </div>

  <nemo-plate-table-detail [plate]="plate"></nemo-plate-table-detail>
</div>
