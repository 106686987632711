import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import * as _ from 'lodash';

import { AppState } from '../../../store/app.reducers';
import EntityPage from '../../../global-context/models/entity-page';
import { actionNewComment } from '../../store/comment.actions';
import { User } from '../../../user/models/user.model';

@Component({
  selector: 'nemo-comment-message-new',
  template: `
    <div
      class="container"
    >
      <div
        #recipientList
        class="autocomplete-recipient-list"
        [ngStyle]="{top:(-autocompletedUserList.length*24)+'px'}"
      >
        <div
          *ngFor="let user of autocompletedUserList"
          class="autocomplete-one-recipient"
          (click)="insertUserInText(user)"
        >
          {{user.name}}
        </div>
      </div>
      <mat-form-field>
              <textarea
                #textarea
                matInput
                placeholder="add a comment (with @user to email)"
                (keyup)="textChanged($event)"
              ></textarea>
      </mat-form-field>
    </div>
  `,
  styles: [`
             div.container {
               padding-left: 10px;
               padding-right: 10px;
               position: relative;
             }

             div.autocomplete-recipient-list {
               position: absolute;
               width: 100%;
               background: white;
               left: 0px;
             }

             div.autocomplete-one-recipient {
               padding-left: 0.5em;
               width: 100%;
               height: 24px;
               cursor: pointer;
             }

             div.autocomplete-one-recipient:hover {
               background: lightgrey;
             }

             div.container mat-form-field {
               display: block;
             }
           `]
})
export class CommentMessageNewComponent implements OnInit {
  @Input()
  entityPage: EntityPage;

  @Input() userDictionary: { [key: string]: User };

  @ViewChild('recipientList', {static: true}) recipientListDiv: ElementRef;
  @ViewChild('textarea', {static: true}) textareaComment: ElementRef;

  userList: User[];

  autocompletedUserList: User[] = [];

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.userList = _.sortBy(this.userDictionary, 'email');
    this.clearAutocompleteUserList();
  }

  buildAutocompleteUserList(prefix: string) {
    prefix = prefix.toLowerCase();
    this.autocompletedUserList = this.userList.filter(u =>
      u.name.toLowerCase().startsWith(prefix) ||
      u.email.toLowerCase().startsWith(prefix)
    );
  }

  clearAutocompleteUserList() {
    if (this.autocompletedUserList.length === 0) {
      return;
    }
    this.autocompletedUserList = [];
  }

  submit(evt) {
    const content = evt.target.value.trim();
    if (content === '') {
      return;
    }
    evt.target.value = '';
    this.store.dispatch(actionNewComment({entityPage: this.entityPage, content: content}));
  }

  textChanged(evt: KeyboardEvent) {
    const regexpAt = /@(\w*)$/;
    if (evt.code === 'Enter') {
      return this.submit(evt);
    }
    const ta = this.textareaComment.nativeElement;
    if (ta.selectionEnd !== ta.selectionStart) {
      return;
    }
    const caretPosition = ta.selectionStart;
    const content = ta.value;
    const leftContent = content.substr(0, caretPosition);
    const matched = leftContent.match(regexpAt);
    if (matched) {
      const prefix = matched[1];
      this.buildAutocompleteUserList(prefix);
    } else {
      this.clearAutocompleteUserList();
    }
  }

  insertUserInText(user: User) {
    const ta = this.textareaComment.nativeElement;
    if (ta.selectionEnd !== ta.selectionStart) {
      return;
    }
    const caretPosition = ta.selectionStart;
    const content = ta.value;
    const leftContent = content.substr(0, caretPosition);
    const iAt = leftContent.lastIndexOf('@');

    ta.value = content.substr(0, iAt + 1) +
      user.emailName +
      ' ' +
      content.substr(caretPosition).trimLeft();
    this.clearAutocompleteUserList();
  }
}
