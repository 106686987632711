import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {  Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { Observable } from 'rxjs';
import { selectGlobalContextUserAndRoleAndLab } from '../../global-context/store/global-context.selectors';
import { filter } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { HasGlobalContextUserRole } from '../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { UserRole } from '../../global-context/models/user-role';
import { User } from '../../user/models/user.model';
import { Lab } from '../../company/models/Lab';

@Component({
  selector: 'nemo-home',
  template: `
    <div *ngIf="isLoggedIn$ | async">
      <nemo-home-title></nemo-home-title>
      <nemo-home-genomics-requests
        *ngIf="isLab$('genomics') | async"
        [context]="context$ | async"
      ></nemo-home-genomics-requests>
      <nemo-home-form-requests
        *ngIf="isLab$('!genomics') | async"
        [context]="context$ | async"
      ></nemo-home-form-requests>
    </div>
  `
})
export class HomeComponent extends HasGlobalContextUserRole implements OnInit {

  context$: Observable<{ user: User, role: UserRole, lab: Lab }>;

  isLoggedIn$: Observable<boolean>;

  constructor(
    private titleService: Title,
    private store: Store<AppState>,
    private authService: AuthService
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.titleService.setTitle('Nemo');

    this.isLoggedIn$ = this.authService.isAuthenticated$;

    this.context$ = this.store.select(selectGlobalContextUserAndRoleAndLab).pipe(
      
      filter((g) => Boolean(g)),
      filter(({user, role, lab}) => Boolean(user && role && lab)),
    );
  }
}

