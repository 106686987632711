import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InvalidPlateQCInstrumentDirectoryError } from '../models/invalid-plate-qc-instrument-directory-error';
import { catchError, map } from 'rxjs/operators';
import { invalidPlateQCInstrumentDirectoryErrorFromDto } from './plate-qc-assigner-mapper';
import { InvalidPlateQCInstrumentDirectoryErrorDto } from '../dtos/invalid-plate-qc-instrument-directory-error.dto';
import { Observable, of } from 'rxjs';
import { PLATE_BASE_URI } from '../../../labware/services/plate.service';

@Injectable({providedIn: 'root'})
export class PlateQcAssignmentService {
  constructor(private http: HttpClient) {
  }

  validateAssignment(plateAccessionCode: string, instrumentDirectoryAccessionCode: string):
    Observable<InvalidPlateQCInstrumentDirectoryError> {
    const payload = {instrumentDirectoryAccessionCode};
    const url = `${PLATE_BASE_URI}/${plateAccessionCode}/validate-qc-instrument-directory-assignment`;
    return this.http.post<InvalidPlateQCInstrumentDirectoryErrorDto>(url, payload).pipe(
      map(() => null),
      catchError(exc => {
        const dto = exc.error as InvalidPlateQCInstrumentDirectoryErrorDto;
        return of(invalidPlateQCInstrumentDirectoryErrorFromDto(dto));
      })
    );
  }

  completeAssignment(plateAccessionCode: string, instrumentDirectoryAccessionCode: string):
    Observable<void> {
    const payload = {instrumentDirectoryAccessionCode};
    const url = `${PLATE_BASE_URI}/${plateAccessionCode}/complete-qc-instrument-directory-assignment`;
    return this.http.post<void>(url, payload);
  }

  unassign(plateAccessionCode: string, instrumentDirectoryAccessionCode: string):
    Observable<void> {
    const payload = {instrumentDirectoryAccessionCode};
    const url = `${PLATE_BASE_URI}/${plateAccessionCode}/complete-qc-instrument-directory-unassignment`;
    return this.http.post<void>(url, payload);
  }
}
