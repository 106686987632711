import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Lab } from '../../../../company/models/Lab';
import { selectFormAllFormTypes } from '../../../store/form/form.selector';
import { selectCompanyAllLabs } from '../../../../company/store';
import { actionFormCreateNewFormType } from '../../../store/form';
import { take, tap } from 'rxjs/operators';

export const regexpListOfInt = /^(\s*\d+\s*,)*\s*\d+\s*$/;

export function parseListOfInt(str: String): number[] {
  return str.split(',').map((d) => parseInt(d, 10));
}

@Component({
  selector: 'nemo-form-settings-add',
  template: `
    <div class="container"
         [formGroup]="formGroup"
    >
      <mat-form-field>
        <mat-label>Lab</mat-label>
        <mat-select formControlName="lab">
          <mat-option *ngFor="let lab of labs$ | async"
                      [value]="lab.alias">
            {{lab.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
        >
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!formGroup.valid"
          (click)="submit()"
        >Add
        </button>
      </div>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: column;
    }

    mat-form-field {
      width: 40em;
      margin-right: 1em;
    }
  `]
})
export class FormSettingsAddComponent implements OnInit {
  formGroup: UntypedFormGroup;
  labs$: Observable<Lab[]>;

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.labs$ = this.store.select(selectCompanyAllLabs);
    this.formGroup = new UntypedFormGroup({
      lab: new UntypedFormControl('', [
        Validators.required
      ]),
      name: new UntypedFormControl('', [
        Validators.required
      ])
    });
    this.store.select(selectFormAllFormTypes).pipe(take(1), tap(() => this.formGroup.reset()));
  }

  submit() {
    this.store.dispatch(actionFormCreateNewFormType({
      lab: this.formGroup.get('lab').value,
      name: this.formGroup.get('name').value
    }));
  }
}
