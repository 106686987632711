import { FlexiblePlainColumnDef } from '../../../../table/model/flexible-columm-def';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';
import { Sample } from '../../../models/sample';
import { TerminusTypes } from '../../../models/barcode-index';
import { formatIndexSequence } from '../../../pipes/samples.pipe';

function hasIndexModel(sample: Sample): boolean {
  return sample.isIndexed() && sample.assignedIndex.kit != null;
}

function formatIndexModel(sample: Sample): string {
  if (sample.assignedIndex.kit) {
    return sample.assignedIndex.kit.barcode;
  } else {
    return '';
  }
}

export const poolSampleTableFlexibleColumnsDefinition = [
  new FlexiblePlainColumnDef<Sample>(
    'request_accession_code',
    'Request',
    () => true,
    (sample) => formatAccessionCode(sample.requestAccessionCode),
    null,
    (sample) => `/request/${sample.requestAccessionCode}`
  ),
  new FlexiblePlainColumnDef<Sample>(
    'sample_name',
    'Sample name',
    () => true,
    (sample) => sample.name
  ),
  new FlexiblePlainColumnDef<Sample>(
    'sample_accession_code',
    'Accession code',
    () => true,
    (sample) => formatAccessionCode(sample.accessionCode)
  ),
  new FlexiblePlainColumnDef<Sample>(
    'index_kit',
    'Index kit',
    (sample) => hasIndexModel(sample),
    (sample) => formatIndexModel(sample)
  ),
  new FlexiblePlainColumnDef<Sample>(
    'i7',
    'i7',
    (sample) => sample.hasIndexOn(TerminusTypes.I7),
    (sample) => formatIndexSequence(sample,
      TerminusTypes.I7),
    null,
    null,
    ['index-sequence']
  ),
  new FlexiblePlainColumnDef<Sample>(
    'i5',
    'i5',
    (sample) => sample.hasIndexOn(TerminusTypes.I5),
    (sample) => formatIndexSequence(sample,
      TerminusTypes.I5),
    null,
    null,
    ['index-sequence']
  )
];
