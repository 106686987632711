import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FlexiblePlainColumnDef } from '../../model/flexible-columm-def';

@Component({
  selector: 'nemo-flexible-plain-cell',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div [ngClass]="columnDefinition.clazz"
         [ngStyle]="columnDefinition.style"
    >{{field['display']}}</div>
  `,
  styles: [`
  `]
})
export class FlexiblePlainCellComponent<T> {
  @Input()
  columnDefinition: FlexiblePlainColumnDef<T>;

  @Input()
  field: {};

}
