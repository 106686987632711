import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { changeGlobalConcentration } from '../../../labware/store/flow-cells';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-edit-global-concentration-dialog',
  template: `
    <h1 mat-dialog-title>Edit concentration for all lanes</h1>
    <div mat-dialog-content>
      <form [formGroup]="form" class="text-center">
        <mat-form-field appearance="fill" floatLabel="never" class="half-width">
          <input matInput type="number"
                 id="concentration"
                 formControlName="concentration"
                 class="align-end"
                 max="3.0" min="0.5" step="0.01">
          <span matPrefix>
              <strong>concentration</strong>
            </span>
          <span matSuffix>nM</span>
        </mat-form-field>
      </form>
      <mat-error>{{errorMessage}}</mat-error>
    </div>
    <div mat-dialog-actions class="right-align">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" (click)="changeGlobalConcentration()"
              [disabled]="!this.form.valid">Valid
      </button>
    </div>
  `
})
export class EditGlobalConcentrationDialogComponent implements OnInit {

  form: UntypedFormGroup;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditGlobalConcentrationDialogComponent>
  ) {
  }

  ngOnInit() {
    this.errorMessage = '';
    this.form = this.buildForm(this.data.concentration);
    console.log(this.data);
  }

  buildForm(concentration: number): UntypedFormGroup {
    const fb = this.fb;
    const controlsConfig = {};
    controlsConfig['concentration'] = fb.control(
      concentration > 0 ? concentration : null,
      [Validators.required, Validators.min(0.50), Validators.max(3.00)]
    );
    return fb.group(controlsConfig);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeGlobalConcentration() {
    const concentration = this.form.get('concentration').value;
    if (concentration < 0.5 || concentration > 3) {
      this.errorMessage = 'The concentration must be between 0.50 and 3.00';
    } else {
      this.store.dispatch(changeGlobalConcentration({
        concentration
      }));
      this.dialogRef.close();
    }
  }

}
