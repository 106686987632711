import { Component, Input } from '@angular/core';
import { NRequest } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-page-title',
  template: `
    <div class="title-line">
      <div class="accession-code">{{request?.accessionCode | accessionCode}}</div>
      <div class="title-author">
        submitted by <strong>{{request?.requester.name}}</strong>
        on <strong>{{request?.createdAt | date : "short"}}</strong>
      </div>

      <div class="assigned-users">
        <nemo-request-user-assignment-container
          [request]="request"
        ></nemo-request-user-assignment-container>
      </div>
    </div>
  `, styles: [`
    div.title-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid #dadce0;
    }

    div.title-line .accession-code {
      flex-grow: 0.3;
      font-family: monospace;
      font-size: 2rem;
      font-weight: bold;
    }

    div.title-line .title-author {
      flex-grow: 0.3;
      margin-left: 50px;
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 0.3em;
    }

    div.assigned-users {
      flex-grow: 1;
      margin-right: 10px;
    }

    div.action-buttons {
      margin: 0 2em 1em 2em;
    }

    div.stages-line {
      margin: 2em 0;
    }

  `]
})
export class RequestPageTitleComponent {
  @Input() request: NRequest;
}
