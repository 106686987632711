import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QcTablePageComponent } from './components/qc-table-page.component';
import { QcTableComponent } from './components/qc-table.component';
import { RouterModule } from '@angular/router';
import { qcRoutes } from './qc.routes';
import { TableModule } from '../table/table.module';
import { MaterialModule } from '../material.module';
import { SharedSharedModule } from '../shared/shared/shared-shared.module';
import {
  PlateQcMeasuresPageComponent
} from './components/instrument-directory/instrument-directory-page/plate-qc-measures-page.component';
import {
  PlateQcMeasuresHeaderComponent
} from './components/instrument-directory/instrument-directory-page/plate-qc-measures-header.component';
import {
  InstrumentDirectoryFileListComponent
} from './components/instrument-directory/instrument-directory-page/instrument-directory-file-list.component';
import { PlateQCComponent } from './components/instrument-directory/plate-qc/plate-qc.component';
import { QCSharedModule } from './shared/qc-shared.module';
import { LabWareSharedModule } from '../labware/shared/lab-ware-shared.module';

@NgModule({
  declarations: [
    QcTablePageComponent,
    QcTableComponent,
    PlateQcMeasuresPageComponent,
    PlateQcMeasuresHeaderComponent,
    InstrumentDirectoryFileListComponent,
    PlateQCComponent
  ],
  exports: [
    QcTableComponent,
    PlateQcMeasuresPageComponent,
    PlateQCComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(qcRoutes),
    TableModule,
    MaterialModule,
    SharedSharedModule,
    QCSharedModule,
    LabWareSharedModule
  ]
})
export class QcModule {
}
