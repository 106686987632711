import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import IndexKitState from './index-kit.reducer';
import { SimpleIndexKitModel } from '../models/indexKitModel';

export const getIndexKitState = (state: AppState) => state.indexKit;

export const getIndexKits = createSelector(getIndexKitState, (state: IndexKitState) => state.indexKits);
export const getPageInfo = createSelector(getIndexKitState, (state: IndexKitState) => state.pageInfo);
export const getIndexKitModels = createSelector(
  getIndexKitState,
  (state: IndexKitState) => state.indexKitModels.slice().sort(
    (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
);
export const getActiveIndexKitModels = createSelector(
  getIndexKitModels,
  (indexKitModels: SimpleIndexKitModel[]) => indexKitModels.filter(indexKitModel => !indexKitModel.archived)
);
