import { Action, createReducer, on } from '@ngrx/store';
import {
  actionFindAllFormRequestsByAssignedToSuccess,
  actionFindAllFormRequestsByRequesterSuccess,
  actionFindFormRequestByAccessionCodeFromRequestPage,
  actionFindFormRequestByAccessionCodeSuccess,
  actionModifyFormRequest,
  actionReopenFormRequestStage,
  actionReopenFormRequestStageSuccess, actionSearchAllFormRequests,
  actionSubmitFormRequest,
  archiveFormRequestFromListSuccess,
  restoreFormRequestFromListSuccess
} from './form-request.actions';
import { FormRequest, FormRequestSummary } from '../../models/form-request';
import {
  assignUserToFormRequestSuccess,
  unassignUserFromFormRequestSuccess
} from '../../../commons/common-request/store/common-request.actions';
import {
  actionCompleteFormRequestStage,
  actionCompleteFormRequestStageSuccess,
  actionFindAllFormRequests,
  actionFindAllFormRequestsByAssignedTo,
  actionFindAllFormRequestsByRequester,
  actionFindAllFormRequestsSuccess
} from './form-request.actions';

export interface FormRequestState {
  current: FormRequest;
  all: FormRequestSummary[];
  pending: boolean;
}

export const initialState: FormRequestState = {
  current: null,
  all: null,
  pending: false
};

const formRequestReducer = createReducer(
  initialState,
  on(actionSubmitFormRequest,
    actionModifyFormRequest,
    actionFindFormRequestByAccessionCodeFromRequestPage,
    actionCompleteFormRequestStage,
    actionReopenFormRequestStage,
    (state) => (
    {...state, current: null}
  )),
  on(actionFindFormRequestByAccessionCodeSuccess,
    actionCompleteFormRequestStageSuccess,
    actionReopenFormRequestStageSuccess,
    assignUserToFormRequestSuccess,
    unassignUserFromFormRequestSuccess,
    (state, {formRequest}) => (
    {...state, current: formRequest}
  )),
  on(actionFindAllFormRequests,
    actionFindAllFormRequestsByAssignedTo,
    actionFindAllFormRequestsByRequester,
    actionSearchAllFormRequests,
    (state) => (
    {...state, all: null}
  )),
  on(actionFindAllFormRequestsSuccess,
    actionFindAllFormRequestsByAssignedToSuccess,
    actionFindAllFormRequestsByRequesterSuccess,
    (state, {formRequests}) => (
    {...state, all: formRequests}
  )),
  on(archiveFormRequestFromListSuccess, (state, {accessionCode}) => {
    const newRequestlist = toggleArchivedInRequestList(state, accessionCode, true);
    return {...state, all: newRequestlist};
  }),
  on(restoreFormRequestFromListSuccess, (state, {accessionCode}) => {
    const newRequestList = toggleArchivedInRequestList(state, accessionCode, false);
    return {...state, all: newRequestList};
  })
);

export function reducer(state: FormRequestState | undefined, action: Action) {
  return formRequestReducer(state, action);
}

function toggleArchivedInRequestList(
  state: FormRequestState,
  accessionCode: string,
  archived: boolean
): FormRequestSummary[] {
  return state.all.map(request =>
    (request.accessionCode === accessionCode) ?
      {...request, archived} :
    request
  );
}
