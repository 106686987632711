import { Component, OnDestroy, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { Title } from '@angular/platform-browser';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';
import { Observable, Subscription } from 'rxjs';
import { findPlateQCByAccessionCodeFromInstrumentDirectoryPage } from '../../../store/actions/qc-api.actions';
import { ActivatedRoute } from '@angular/router';
import { getCurrentPlateQC } from '../../../store/qc.selectors';
import { PlateQC } from '../../../models/plate-qc-measures';

@Component({
  selector: 'nemo-plate-qc-measures-with-files-page',
  template: `
    <div
      *ngIf="plateQC$| async"
    >
      <nemo-plate-qc-measures-header
        [plateQC]="plateQC$| async"
      >
      </nemo-plate-qc-measures-header>
      <nemo-plate-qc
        [plateQC]="(plateQC$ | async)"
      ></nemo-plate-qc>
      <nemo-instrument-directory-file-list
        [instrumentDirectory]="(plateQC$| async).instrumentDirectory"
      >
      </nemo-instrument-directory-file-list>
    </div>
  `,
  styles: []
})
export class PlateQcMeasuresPageComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;
  plateQC$: Observable<PlateQC>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.plateQC$ = this.store.select((getCurrentPlateQC));
    this.paramSubscription = this.route.params.subscribe(params => {
      const accessionCode = params['accessionCode'];
      this.titleService.setTitle(`Nemo Instrument directory ${formatAccessionCode(accessionCode)}`);
      this.store.dispatch(findPlateQCByAccessionCodeFromInstrumentDirectoryPage({accessionCode}));
    });
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }
}
