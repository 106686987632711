import { AssignedIndexDto } from '../../index/models/assigned-index.dto';
import { SimpleRequestDto } from '../../genomics-request/models/genomics-request.dto';
import { PoolSource } from '../models/pool';
import { FragmentAnalyzerQCMeasureDto } from '../../qc/dto/qc.dto';

export class SampleDto {
  id: number;
  name: string;
  assignedIndex?: AssignedIndexDto;
  type?: string;
  accessionCode?: string;
  requestAccessionCode?: string;
  metadata?: { [key: string]: any };
}

export class PoolSamplePlaceholderDto {
  public readonly accessionCode?: string;
  title: string;
  sample: SampleDto;
  archived: boolean;
  creationDate: Date;
  fragmentAnalyzerQc?: FragmentAnalyzerQCMeasureDto;
}

export class PoolSampleMixDto {
  public readonly accessionCode?: string;
  title: string;
  samples: SampleDto[];
  archived: boolean;
  poolSources: PoolSource[];
  creationDate: Date;
  fragmentAnalyzerQc?: FragmentAnalyzerQCMeasureDto;
}

export class SimplePoolDto {
  accessionCode: string;
  title: string;
  numberOfSamples: number;
  archived: boolean;
  creationDate: Date;
}

export class PoolCounts {
  totalPoolCount: number;
  totalRequestCount: number;
  totalSampleCount: number;
}

export const poolCountsZero: PoolCounts = {
  totalPoolCount: 0,
  totalRequestCount: 0,
  totalSampleCount: 0
};


export class SimplePoolWithRequestDto extends SimplePoolDto {
  requests: SimpleRequestDto[];
}

export class PoolWithRequestDto {
  accessionCode: string;
  title: string;
  creationDate: Date;
  samples: SampleDto[];
  fragmentAnalyzerQc: FragmentAnalyzerQCMeasureDto;
  archived: boolean;
  requests: SimpleRequestDto[];
}
