import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LabWareModule } from '../labware/labware.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TableModule } from '../table/table.module';
import { IndexKitTablePageComponent } from './components/index-kit-table/index-kit-table-page.component';
import { IndexKitTableComponent } from './components/index-kit-table/index-kit-table.component';

@NgModule({
  declarations: [
    IndexKitTableComponent,
    IndexKitTablePageComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    LabWareModule,
    SharedModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    TableModule
  ]
})
export class IndexModule {
}
