export class InstrumentFile {
  readonly basename: string;
  readonly suffix: string;

  constructor(
    public readonly accessionCode: string,
    public readonly directoryAccessionCode: string,
    public readonly path: string,
    public readonly fileType: string,
    public readonly createdAt: Date,
    public readonly gcsUrl: string
  ) {
    this.basename = this.path.replace(/.*\//, '');
    this.suffix = this.path.replace(/.*\./, '').toLowerCase();
  }
}

