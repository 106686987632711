import { Pipe, PipeTransform } from '@angular/core';
import { PoolWithRequest } from '../../models/pool';
import { Sample } from '../../models/sample';

@Pipe({
  name: 'extractSampleList'
})
export class PoolSampleListPipe implements PipeTransform {

  transform(value: PoolWithRequest, args?: any): Sample[] {
    if (value) {
      return value.sampleList();
    }
  }
}
