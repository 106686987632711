import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FlexibleHtmlColumnDef, FlexiblePlainColumnDef } from '../../../../table/model/flexible-columm-def';
import { PlateQC } from '../../../models/plate-qc-measures';
import { FragmentAnalyzerQCMeasure } from '../../../models/fragment-analyzer-qc-measure';
import { qcDataColumnOrder } from '../../../../labware/shared/components/plate-table-detail/plate-table-flexible-columns-definition';

@Component({
  selector: 'nemo-plate-qc',
  template: `
    <h3>QC Measures</h3>
    <nemo-plate-icon
      [occupancy]="plateQC.occupancy"
      [dimensions]="plateQC.dimensions"
    ></nemo-plate-icon>
    <nemo-flexible-table
      [values]="plateQC.measures"
      [columnDefinitions]="columnDefinitions"
    ></nemo-flexible-table>
  `,
  styles: []
})
export class PlateQCComponent implements OnChanges {
  @Input()
  plateQC: PlateQC;

  columnDefinitions: FlexiblePlainColumnDef<FragmentAnalyzerQCMeasure>[];
  baseColumnDefinitions = [
    new FlexiblePlainColumnDef<FragmentAnalyzerQCMeasure>(
      'well',
      'Well',
      () => true,
      (m) => m.plateCoordinates.toString(),
      {'flex-grow': 0.2}
    ),
    new FlexibleHtmlColumnDef<FragmentAnalyzerQCMeasure>(
      'plotInstrumentFile',
      'trace',
      (m) => m.plotInstrumentFile !== null,
      (m) => ` <a href = '${m.plotInstrumentFile.gcsUrl}' target = '__NEMO_GCS__' >
  <img
    class = 'plot-tracexx'
           src = '${m.plotInstrumentFile.gcsUrl}'
           style = 'height:100px'> </img>
             </a>`
    )
  ];

  ngOnChanges(changes: SimpleChanges): void {
    this.columnDefinitions = [
      ...this.baseColumnDefinitions,
      ...qcDataFlexibleColumnDef(this.plateQC.measures)
    ];
  }

}

function qcDataFlexibleColumnDef(qcMeasures: FragmentAnalyzerQCMeasure[])
  : FlexiblePlainColumnDef<FragmentAnalyzerQCMeasure>[] {
  return qcDataColumnOrder(qcMeasures).map((key: string) =>
    new FlexibleHtmlColumnDef<FragmentAnalyzerQCMeasure>(
      `qc_${key}`,
      key,
      () => true,
      (x) => {
        const value = x.data[key];
        return Array.isArray(value) ? (value.join('\n')) : value;
      },
      null,
      null,
      ['qc-values']
    )
  );
}
