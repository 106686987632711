import { Pipe, PipeTransform } from '@angular/core';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { BioMaterialPlateMapping } from '../../models/bio-material-plate-mapping';
import { Pool } from '../../../bio/models/pool';
import { Plate96 } from '../../models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { TerminusTypes } from '../../../bio/models/barcode-index';
import * as _ from 'lodash';
import { Sample } from '../../../bio/models/sample';

@Pipe({
  name: 'getOccupancy'
})
export class GetOccupancyPipe implements PipeTransform {

  transform(occupancy: boolean[], column: number, numberOfRows: number): boolean[] {
    const start = column * numberOfRows;
    const end = start + numberOfRows;
    return occupancy.slice(start, end);
  }
}

export function formatSampleRequestAccessionCodes(mapping: BioMaterialPlateMapping<Pool>): string {
  return mapping.biomaterial.listRequestAccessionCode().map(ac => formatAccessionCode(ac)).join(',');
}

@Pipe({
  name: 'sampleRequestAccessionCodes'
})
export class SampleRequestAccessionCodesPipe implements PipeTransform {
  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatSampleRequestAccessionCodes(value);
  }
}


@Pipe({
  name: 'requestAccessionCode'
})
export class RequestAccessionCodePipe implements PipeTransform {

  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return value.biomaterial.listRequestAccessionCode();
  }
}

@Pipe({
  name: 'BioMaterialAccessionCode'
})
export class BioMaterialAccessionCodePipe implements PipeTransform {

  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return value.biomaterial.accessionCode;
  }
}

@Pipe({
  name: 'nonEmptyBioMaterialMappings'
})
export class NonEmptyBioMaterialMappingsPipe implements PipeTransform {

  transform(value: Plate96<BioMaterial>, args?: any): BioMaterialPlateMapping<BioMaterial>[] {
    if (value && value._bioMaterialContents) {
      return value._bioMaterialContents
        .map((bm, i) => ({bm: bm, i: i}))
        .filter(p => p.bm)
        .map((p) => new BioMaterialPlateMapping(p.bm, value.idx2coordinate(p.i)));
    }
    return null;
  }
}

export function formatBioMaterialNames(mapping: BioMaterialPlateMapping<Pool>): string {
  return mapping.biomaterial.sampleList().map(s => s.name.trim()).join(', ');
}

export function formatBioMaterialVolUl(mapping: BioMaterialPlateMapping<Pool>): string {
  return mapping.biomaterial.sampleList().map(s => s.metadata['Vol(uL)']).join(', ');
}

export function formatBioMaterialRequestedReads(mapping: BioMaterialPlateMapping<Pool>): string {
  return mapping.biomaterial.sampleList().map(s => s.metadata['Requested_reads(Million)']).join(', ');
}

@Pipe({
  name: 'bioMaterialNames'
})
export class BioMaterialNamesPipe implements PipeTransform {
  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatBioMaterialNames(value);
  }
}

export function formatBioMaterialCoordinates(mapping: BioMaterialPlateMapping<Pool>): string {
  return mapping.plateCoordinates.toString();
}

@Pipe({
  name: 'bioMaterialCoordinates'
})
export class BioMaterialCoordinatesPipe implements PipeTransform {
  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatBioMaterialCoordinates(value);
  }
}

function poolIndexes(pool: Pool) {
  return pool.sampleList()
    .filter(s => s.isIndexed())
    .map(s => s.assignedIndex);
}

export function formatBioMaterialAssignedIndex(mapping: BioMaterialPlateMapping<Pool>): string {
  return poolIndexes(mapping.biomaterial)
    .map(index => index.model ? index.model.name.trim() : '')
    .filter((s) => s !== '')
    .join(', ');
}

@Pipe({
  name: 'bioMaterialAssignedIndex'
})
export class BioMaterialAssignedIndexPipe implements PipeTransform {
  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatBioMaterialAssignedIndex(value);
  }
}

export function formatBioMaterialAssignedIndexKit(mapping: BioMaterialPlateMapping<Pool>): string {
  return poolIndexes(mapping.biomaterial)
    .map(index => index.kit ? index.kit.barcode.trim() : '')
    .filter((s) => s !== '')
    .join(', ');
}

@Pipe({
  name: 'bioMaterialAssignedIndexKit'
})
export class BioMaterialAssignedIndexKitPipe implements PipeTransform {
  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatBioMaterialAssignedIndexKit(value);
  }
}

export function formatBioMaterialAssignedIndexPosition(value: BioMaterialPlateMapping<Pool>): any {
  return poolIndexes(value.biomaterial)
    .filter(index => index.model)
    .map(index => index.model.plateCoordinates.toString())
    .join(', ');
}

@Pipe({
  name: 'bioMaterialAssignedIndexPosition'
})
export class BioMaterialAssignedIndexPositionPipe implements PipeTransform {
  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatBioMaterialAssignedIndexPosition(value);
  }
}

export function formatBioMaterialAssignedIndexI5(value: BioMaterialPlateMapping<Pool>) {
  return poolIndexes(value.biomaterial)
    .map(index => index.sequences.filter(seq => seq.terminus === TerminusTypes.I5).map(seq => seq.sequence).join(';'))
    .filter((s) => s !== '')
    .join(', ');
}

@Pipe({
  name: 'bioMaterialAssignedIndexI5'
})
export class BioMaterialAssignedIndexI5Pipe implements PipeTransform {
  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatBioMaterialAssignedIndexI5(value);
  }
}

export function formatBioMaterialAssignedIndexI7(value: BioMaterialPlateMapping<Pool>): any {
  return poolIndexes(value.biomaterial)
    .map(index => index.sequences.filter(seq => seq.terminus === TerminusTypes.I7).map(seq => seq.sequence).join(';'))
    .filter((s) => s !== '')
    .join(', ');
}

@Pipe({
  name: 'bioMaterialAssignedIndexI7'
})
export class BioMaterialAssignedIndexI7Pipe implements PipeTransform {

  transform(value: BioMaterialPlateMapping<Pool>, args?: any): any {
    return formatBioMaterialAssignedIndexI7(value);
  }
}

@Pipe({
  name: 'plateSamples'
})
export class PlateSamplesPipe implements PipeTransform {

  transform(value: Plate96<Pool>, args?: any): Sample[] {
    return value._bioMaterialContents
      .filter(Boolean)
      .reduce(
        (sampleAcc: Sample[], pool: Pool) => sampleAcc.concat(pool.sampleList()), []
      );
  }
}
