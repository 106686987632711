import { Injectable } from '@angular/core';
import { Plate96 } from '../models/plate-96';
import { BioMaterialTypes } from '../../bio/models/bio-material';
import { BioMaterialPlateMapping } from '../models/bio-material-plate-mapping';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PlateSnippet } from '../dto/labware.dto';
import { environment } from '../../../environments/environment';
import { PlateCounts } from '../dto/plate.dto';
import { Index } from '../../bio/models/barcode-index';
import { Pool, PoolSamplePlaceholder } from '../../bio/models/pool';
import * as _ from 'lodash';
import { JSON_CONTENT } from '../../constants/http';
import { AccessionCodeDto } from '../../shared/models/accession-code-dto';

export const PLATE_BASE_URI = environment.apiUrl + '/plates';

@Injectable({providedIn: 'root'})
export class PlateService {

  constructor(private readonly http: HttpClient) {
  }

  static indexUniquenessKey(index: Index): string {
    return index.sequences.map((seq) => `${seq.terminus}-${seq.sequence}`).slice().sort().join('|');
  }

  /**
   * Return a validation object with the positions of the duplicate index assignments that have been found,
   * i.e. the same index sequence from different kits has been assigned twice.
   */
  findDuplicateIndexes(plate: Plate96<PoolSamplePlaceholder>): DuplicateIndex[] {
    const indexedMaterials: BioMaterialPlateMapping<PoolSamplePlaceholder>[] = plate.getNonEmptyBioMaterialMappings()
      .filter((mapping) => mapping.biomaterial.type === BioMaterialTypes.poolSamplePlaceholder)
      .filter((mapping) => (mapping.biomaterial as PoolSamplePlaceholder).sample.isIndexed())
      .map((mapping) => mapping as BioMaterialPlateMapping<PoolSamplePlaceholder>);


    // Count the number of occurrences of each index model ID
    const indexSequencesCount = indexedMaterials
      .reduce((acc, mapping) => {
        const indexModelSequences = PlateService.indexUniquenessKey(mapping.biomaterial.sample.assignedIndex);
        acc[indexModelSequences] = (acc[indexModelSequences] || 0) + 1;
        return acc;
      }, {}); // there is no classic counter in JS

    // For each count > 1, return a validation object
    return indexedMaterials
      .filter((mapping) => indexSequencesCount[PlateService.indexUniquenessKey(mapping.biomaterial.sample.assignedIndex)] >
        1)
      .map((mapping) => ({
        position: mapping.plateCoordinates.toString(),
        indexModelId: (mapping.biomaterial.sample.assignedIndex.model ?
                       mapping.biomaterial.sample.assignedIndex.model.id : null),
      }));
  }

  findAll(showArchived: boolean | null, availableTask?: string): Observable<PlateSnippet[]> {
    const queryParams = {};

    if (showArchived !== null && showArchived !== undefined) {
      queryParams['show_archived'] = showArchived;
    }

    if (availableTask) {
      queryParams['available_for'] = availableTask;
    }

    return this.http.get<PlateSnippet[]>(PLATE_BASE_URI, {params: queryParams});
  }

  /*
    retrieves a brutal list of all plate snippets (lighter for performance)
    The filtering (task available, for example) as to be done afterwards
    The model is exposed in a straightforward way by the backend for optimization. Thus no DTO transformation
   */
  findAllSnippetsNotArchived(): Observable<PlateSnippet[]> {
    return this.http.get<PlateSnippet[]>(`${PLATE_BASE_URI}/snippets`, {params: {show_archived: 'false'}});
  }

  count(availableTask: string | undefined): Observable<PlateCounts> {
    const queryParams = {};
    if (availableTask) {
      queryParams['available_for'] = availableTask;
    }
    return this.http.get<PlateCounts>(PLATE_BASE_URI + '/count', {params: queryParams});
  }

  search(query: string, showArchived: boolean): Observable<PlateSnippet[]> {
    if (query == null || query.length <= 0) {
      return this.findAll(showArchived);
    }
    return this.http.get<PlateSnippet[]>(PLATE_BASE_URI, {params: {q: query, show_archived: showArchived.toString()}});
  }

  processReArray(accessionCode: string): Observable<void> {
    const uri = PLATE_BASE_URI + '/' + accessionCode + '/re-array-processed';
    return this.http.post<void>(uri, null);
  }

  processEchoPooling(accessionCode: string): Observable<void> {
    const uri = PLATE_BASE_URI + '/' + accessionCode + '/echo-pooling-processed';
    return this.http.post<void>(uri, null);
  }

  echoPoolingImport(accessionCode: string, file: any): Observable<void> {
    const uri = PLATE_BASE_URI + '/' + accessionCode + '/echo-pooling';
    return this.http.post<void>(uri, file, {headers: JSON_CONTENT});
  }

  completeIndexAssignment(accessionCode: string): Observable<AccessionCodeDto> {
    const uri = PLATE_BASE_URI + '/' + accessionCode + '/index-assignment-done';
    return this.http.post<AccessionCodeDto>(uri, null);
  }

  archive(accessionCode: string): Observable<PlateSnippet> {
    return this.http.delete<PlateSnippet>(PLATE_BASE_URI + '/' + accessionCode);
  }

  restore(accessionCode: string): Observable<PlateSnippet> {
    return this.http.post<PlateSnippet>(PLATE_BASE_URI + '/' + accessionCode + '/restore', null);
  }
}

export interface DuplicateIndex {
  position: string;
  indexModelId: number;
}

export function getAllRequestsAccessionCode(plate: Plate96<Pool>): string[] {
  if (!plate) {
    return [];
  }
  return _.chain(plate.getNonEmptyBioMaterialMappings())
    .flatMap((mapping: BioMaterialPlateMapping<Pool>) => mapping.biomaterial.listRequestAccessionCode())
    .uniqBy()
    .filter()
    .value();
}

export function getAllPoolsByRequestsAccessionCode(
  plate: Plate96<Pool>,
  accessionCode?: string
): BioMaterialPlateMapping<Pool>[] {
  if (!plate) {
    return [];
  }
  if (!accessionCode) {
    return plate.getNonEmptyBioMaterialMappings();
  }
  return plate.getNonEmptyBioMaterialMappings()
    .filter((mapping: BioMaterialPlateMapping<Pool>) =>
      mapping.biomaterial.containsRequestAccessionCode(accessionCode)
    );
}
