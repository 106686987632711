export enum ValidationStatusType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  OK = 'OK',
}

export enum ValidationErrorType {
  INDEX_PLATE_IS_EMPTY_AT_COORDINATES = '[IndexToSamplePlateMapping ValidationErrorType] Index plate is empty at coordinates',
  SAMPLE_PLATE_IS_EMPTY_AT_COORDINATES = '[IndexToSamplePlateMapping ValidationErrorType] sample plate is empty at coordinates',
  SAMPLE_PLATE_IS_ALREADY_INDEXED_AT_COORDINATES =
    '[IndexToSamplePlateMapping ValidationErrorType] sample plate already indexed at coordinates',
  INCOHERENT_INDEX_SAMPLE_SELECTION_SIZE = '[IndexToSamplePlateMapping ValidationErrorType] incoherent index-sample selection size',
  DUPLICATE_INDEX = '[IndexToSamplePlateMapping ValidationErrorType] the same index was applied twice (e.g. from different kits)',
}

/** The failure of a particular validator */
export class ValidationError {
  constructor(
    public readonly type: string,
    public readonly message: string,
  ) {
  }
}

export class ValidationStatus {
  constructor(
    public readonly isValid,
    public readonly status: ValidationStatusType,
    public readonly error?: ValidationError,
  ) {
  }
}

export function buildErrorValidationStatus(validationError: ValidationError): ValidationStatus {
  return new ValidationStatus(false, ValidationStatusType.ERROR, validationError);
}

export function buildWarningValidationStatus(validationError: ValidationError): ValidationStatus {
  return new ValidationStatus(false, ValidationStatusType.WARNING, validationError);
}

export function buildOkValidationStatus(): ValidationStatus {
  return new ValidationStatus(true, ValidationStatusType.OK);
}
