import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { Plate96 } from '../../models/plate-96';
import { of } from 'rxjs';
import { navigateToEchoPooling, navigateToReArray, newPlateError, newPlateSuccess } from './actions/plate-api.actions';
import { PlateApiService } from '../../services/plate-api.service';
import {
  findPlateByAccessionCode,
  findPlateByAccessionCodeThenSelect,
  findPlateByAccessionCodeThenSelectFromEchoPooling,
  findPlateByAccessionCodeThenSelectFromIA,
  findPlateByAccessionCodeThenSelectFromPlateApi,
  findPlateByAccessionCodeThenSelectFromReArray,
  findPlateByAccessionCodeThenSelectFromTaskApi,
  putPlate
} from './actions/plates-details.actions';
import { findAllRequestByAccessionCodesFromAPI } from '../../../genomics-request/store';
import {
  createNewEchoPoolingPlate,
  createNewEchoPoolingTransferPlate,
  createNewPlateFromDialog,
  findAllPlatesFromReArray
} from './actions/plate-list.action';
import { Pool } from '../../../bio/models/pool';
import { getAllRequestsAccessionCode } from '../../services/plate.service';
import { Router } from '@angular/router';
import {
  findPlateByAccessionCodeError,
  findPlateByAccessionCodeSuccess,
  updatePlateField,
  updatePlateFieldError,
  updatePlateFieldSuccess
} from './actions/plates-api.action';
import { findIndexKitsFromIA } from '../../../index/store';

const REGEX_ECHO_POOLING_URL = '\\/tasks\\/echo-pooling\\/(.*)\\/(.*)';

@Injectable()
export class PlatesEffects {

  constructor(
    private actions$: Actions,
    private plateApiService: PlateApiService,
    private router: Router,
  ) {
  }

  /**
   * Retrieve the info for a plate from backend,
   * then initialize a Plate component here.
   */
  
  FindPlateByAccessionCodeThenSelect = createEffect(() => { return this.actions$.pipe(
    ofType(
      findPlateByAccessionCodeThenSelect.type,
      findPlateByAccessionCodeThenSelectFromTaskApi.type,
      findPlateByAccessionCodeThenSelectFromReArray.type,
      findPlateByAccessionCodeThenSelectFromEchoPooling.type,
      findPlateByAccessionCodeThenSelectFromPlateApi.type
    ),
    mergeMap((action: ReturnType<typeof findPlateByAccessionCodeThenSelect>) =>
      this.plateApiService.findByAccessionCode(action.accessionCode).pipe(
        concatMap((plate: Plate96<Pool>) => ([
            findAllRequestByAccessionCodesFromAPI({accessionCodes: getAllRequestsAccessionCode(plate)}),
            putPlate({plateAccessionCode: action.putPlateIdentifier, plate: plate, readonly: false})
          ])
        )
      ),
    )
  ) });

  
  findPlateByAccessionCodeThenSelectAndFindIndexKits = createEffect(() => { return this.actions$.pipe(
    ofType(findPlateByAccessionCodeThenSelectFromIA.type),
    mergeMap((action: ReturnType<typeof findPlateByAccessionCodeThenSelectFromIA>) =>
      this.plateApiService.findByAccessionCode(action.accessionCode).pipe(
        concatMap((plate: Plate96<Pool>) => ([
            findIndexKitsFromIA({plateAccessionCode: plate.accessionCode}),
            findAllRequestByAccessionCodesFromAPI({accessionCodes: getAllRequestsAccessionCode(plate)}),
            putPlate({plateAccessionCode: action.putPlateIdentifier, plate: plate, readonly: false})
          ])
        )
      ),
    )
  ) });

  
  FindPlateByAccessionCode = createEffect(() => { return this.actions$.pipe(
    ofType(findPlateByAccessionCode.type),
    mergeMap((action: ReturnType<typeof findPlateByAccessionCode>) =>
      this.plateApiService.findByAccessionCode(action.accessionCode)
    ),
    map(plate => findPlateByAccessionCodeSuccess({plate})),
    catchError(error => of(findPlateByAccessionCodeError({message: error.message}))),
  ) });

  
  CreatePlate = createEffect(() => { return this.actions$.pipe(
    ofType(createNewPlateFromDialog.type),
    mergeMap((action: ReturnType<typeof createNewPlateFromDialog>) => {
        return this.plateApiService.createPlate({title: action.title, dimensions: action.dimensions}).pipe(
          concatMap((plate) => {
            if (action.availableTask) {
              return [
                findAllPlatesFromReArray({availableTask: action.availableTask}),
                navigateToReArray({
                  destinationAccessionCode: plate.accessionCode,
                  sourceAccessionCode: action.sourceAccessionCode
                }),
                newPlateSuccess({plate})
              ];
            } else {
              return [newPlateSuccess({plate})];
            }
          }),
          catchError(error => of(newPlateError({message: error.message}))),
        );
      }
    )
  ) });

  
  createEchoPoolingPlate = createEffect(() => { return this.actions$.pipe(
    ofType(createNewEchoPoolingPlate.type),
    mergeMap((action: ReturnType<typeof createNewEchoPoolingPlate>) =>
      this.plateApiService.createPlate({title: action['title'], dimensions: action['dimensions']}).pipe(
        concatMap((plate) => {
          const match = this.router.url.match(REGEX_ECHO_POOLING_URL);
          return [
            newPlateSuccess({plate}),
            navigateToEchoPooling({destinationAccessionCode: plate.accessionCode, sourceAccessionCode: match[2]})
          ];
        }),
        catchError(error => of(newPlateError({message: error.message}))),
      )
    )
  ) });

  
  createEchoPoolingTransferPlate = createEffect(() => { return this.actions$.pipe(
    ofType(createNewEchoPoolingTransferPlate.type),
    mergeMap((action: ReturnType<typeof createNewEchoPoolingTransferPlate>) =>
      this.plateApiService.createPlate({title: action['title'], dimensions: action['dimensions']}).pipe(
        concatMap((plate) => {
          if (action.availableTask) {
            return [
              findAllPlatesFromReArray({availableTask: action.availableTask}),
              navigateToReArray({
                destinationAccessionCode: plate.accessionCode,
                sourceAccessionCode: action.sourceAccessionCode
              }),
              newPlateSuccess({plate})
            ];
          } else {
            return [newPlateSuccess({plate})];
          }
        }),
        catchError(error => of(newPlateError({message: 'Echo transfer pool already exists. ' + error.message}))),
      )
    )
  ) });

  
  updatePlateField$ = createEffect(() => { return this.actions$.pipe(
    ofType(updatePlateField.type),
    mergeMap((action: ReturnType<typeof updatePlateField>) =>
      this.plateApiService.patchOneField(action.accessionCode, action.field, action.value)
    ),
    map(plate => updatePlateFieldSuccess({plate})),
    catchError(error => {
      const message = (error.message) ? error.message : 'Impossible to update plate field';
      return of(updatePlateFieldError({message}));
    }),
  ) });

  
  navigateToEchoPooling = createEffect(() => { return this.actions$.pipe(
    ofType(navigateToEchoPooling.type),
    tap((action: ReturnType<typeof navigateToEchoPooling>) => {
        this.router.navigate(['/tasks/echo-pooling/' + action.destinationAccessionCode + '/' + action.sourceAccessionCode]);
      }
    )
  ) }, {dispatch: false});

  
  navigateToReArray = createEffect(() => { return this.actions$.pipe(
    ofType(navigateToReArray.type),
    tap((action: ReturnType<typeof navigateToReArray>) => {
        this.router.navigate(['/tasks/re-array/' + action.destinationAccessionCode + '/' + action.sourceAccessionCode]);
      }
    )
  ) }, {dispatch: false});
}
