import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingSharedModule } from './shared/reporting-shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material.module';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    ReportingSharedModule,
    MatExpansionModule,
  ]
})
export class ReportingModule {
}
