import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Graph } from '../models/graph';

export const GRAPH_BASE_URI = environment.apiUrl + '/graph';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private readonly http: HttpClient) {
  }

  findByAccessionCode(accessionCode: string): Observable<Graph> {
    return this.http.get<Graph>(
      `${GRAPH_BASE_URI}/${accessionCode}`,
      {params: {edge_type: ['CONTAINS_DATA_FROM', 'IS_MIXED_IN', 'IS_LAYOUT_ON']}}
    );
  }
}
