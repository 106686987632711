<mat-form-field>
  <input matInput
         [(value)]="selectionString"
         placeholder="well range (e.g. A1-B6,D7,E3-E8)"
         (focusout)="updateSelectionString($event.target.value)"
         (keydown.enter)="updateSelectionString($event.target.value)"
  ><span matSuffix>({{countSelected}})</span>
</mat-form-field>
<mat-error *ngIf="!!errorMessage">{{errorMessage}}</mat-error>

