import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'nemo-collaborator-select',
  template: `
    <mat-form-field [formGroup]="formGroup">
      <mat-label>Collaborator</mat-label>
      <input type="text"
             matInput
             formControlName="collaborator"
             (change)="changedValue()"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changedValue()">
        <mat-option *ngFor="let collaboratorOption of collaboratorOptions" [value]="collaboratorOption">
          {{ collaboratorOption }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styleUrls: ['../select.scss'],
  styles: [`
    mat-option {
      font-size: 13px;
    }
  `]
})
export class CollaboratorSelectComponent {
  @Input() formGroup: FormGroup;

  @Output() changeValue = new EventEmitter<string>();

  collaboratorOptions: string[] = [
    'Sponsor Research Agreement - SRA',
    'Contract Research Organization - CRO',
    'Internal Calico Research'
  ];

  changedValue() {
    this.changeValue.emit();
  }
}
