import { Component } from '@angular/core';

@Component({
  selector: 'nemo-studies',
  template: `
    <div class="container">
      <h3>Add a new study</h3>
      <nemo-study-add></nemo-study-add>
      <h3>Edit the list</h3>
      <nemo-study-list></nemo-study-list>
    </div>
  `,
  styles: [`
               table {
                 width: 100%;
               }
             `]
})
export class StudiesComponent {
  constructor() {
  }

}
