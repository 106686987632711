import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nemo-context-switch-button',
  template: `

    <div class="main">
      <div
        [class]="extraClass+' button'"
        [class.active]="isActive"
        [class.inactive]="!isActive"
        (click)="clickButton()">

        <div class="text">
          <div
            *ngFor="let l of text"
          >
            {{l}}
          </div>
        </div>
      </div>
    </div>

  `,
  styles: [`
             :host {
               flex-grow: 1;
               margin-left: 1em;
               margin-right: 1em;
             }

             div.main {
               width: 100%;
               height: 100%;
             }

             div.button {
               vertical-align: middle;
               border-color: #C0C0C0;
               border-radius: 1em;
               border-width: 1px;
               border-style: solid;
               width: 100%;
               height: 100%;
               cursor: pointer;
               box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
             }

             div.button.inactive:hover {
               background: rgb(235, 235, 235);
             }

             div.button.inactive {
               cursor: pointer;
             }

             div.button.active {
               cursor: default;
               background: rgb(220, 220, 220);
             }

             div.text {
               font-size: 150%;
               vertical-align: middle;
               text-align: center;
               margin: 2em;
             }

             div.button.active div.text {
               font-weight: bold;
             }

             div.button.inactive div.text {
               color: lightslategrey;
               opacity: 0.7;
             }

           `]
})
export class ContextSwitchButtonComponent {
  @Input()
  header: string;

  @Input()
  text: string[];

  @Input()
  extraClass: string;

  @Input()
  isActive: boolean;

  @Output()
  buttonSelected = new EventEmitter<void>();


  clickButton() {
    this.buttonSelected.emit();
  }
}
