import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { authConfig } from './auth.config';
import { User } from 'src/app/user/models/user.model';
import { AppState } from 'src/app/store/app.reducers';
import { Store } from '@ngrx/store';
import { loggedInSuccess } from 'src/app/user/store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private isAuthenticatedSubject$: BehaviorSubject<boolean>;
  public isAuthenticated$: Observable<boolean>;
  private isDoneLoadingSubject$: ReplaySubject<boolean>;
  public isDoneLoading$: Observable<boolean>;
  private userSubject$: ReplaySubject<User>;
  public user$: Observable<User>;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private store: Store<AppState>
  ) {

    // immediately set value, if user is authenticated or not
    this.isAuthenticatedSubject$ = new BehaviorSubject<boolean>(this.oauthService.hasValidAccessToken());
    this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    this.isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    this.isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    this.userSubject$ = new ReplaySubject<User>();
    this.user$ = this.userSubject$.asObservable();

    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin({
      onTokenReceived: context => {
        // if user tried to access protected page before login
        if (this.oauthService.state) {
          this.router.navigateByUrl(this.oauthService.state);
        }
      }
    });

    // is auth info loaded ?
    this.oauthService.events
      .pipe(filter(e => 'discovery_document_loaded' === e.type))
      .subscribe(e => this.isDoneLoadingSubject$.next(true));

    // hangle auth events
    this.oauthService.events
      .subscribe(_ => this.handleAuthServiceEvent());
  }

  public get pictureUrl() {
    return this.user$.pipe(map((ud) => ud.picture));
  }

  public login(targetUrl?: string) {
    this.oauthService.initImplicitFlow(encodeURIComponent(targetUrl || this.router.url));
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  private handleAuthServiceEvent() {
    const auth = this.oauthService.hasValidAccessToken();

    this.isAuthenticatedSubject$.next(auth);

    if (auth) {
      const claims = this.oauthService.getIdentityClaims();
      const user = new User({
        id: claims['sub'],
        name: claims['name'],
        email: claims['email'],
        picture: claims['picture']
      });
      this.userSubject$.next(user);
      this.store.dispatch(loggedInSuccess({user}));
    }
  }
}
