import { createAction, props } from '@ngrx/store';
import { PlateQC, PlateQCSummary } from '../../models/plate-qc-measures';

export const reimportPlateQCFromPage = createAction(
  '[Plate QC Page] reimport plate QC',
  props<{ accessionCode: string }>()
);

export const dismissPlateQCFromList = createAction(
  '[Plate QC List] dismiss Plate QC',
  props<{ accessionCode: string }>()
);

export const dismissPlateQCFromPage = createAction(
  '[Plate QC Page] dismiss plate QC',
  props<{ accessionCode: string }>()
);

export const recoverPlateQCFromList = createAction(
  '[Plate QC List] recover plate QC',
  props<{ accessionCode: string }>()
);

export const recoverPlateQCFromPage = createAction(
  '[Plate QC Page] recover directory',
  props<{ accessionCode: string }>()
);

export const updatePlateQCInListSuccess = createAction(
  '[QC Task API] update Plate QC in list success',
  props<{ plateQC: PlateQCSummary }>()
);

export const decrementPlateQCTodoCount = createAction(
  '[QC Task API] decrement Plate QC todo counter'
);

export const incrementPlateQCTodoCount = createAction(
  '[QC Task API] increment plate QC todo counter'
);

export const updatePlateQCSuccess = createAction(
  '[QC Task API] update plate QC success',
  props<{ plateQC: PlateQC }>()
);

export const dismissPlateQCError = createAction(
  '[QC Task API] dismiss plate QC error',
  props<{ message: string }>()
);
export const recoverplateQCError = createAction(
  '[QC Task API] recover plate QC error',
  props<{ message: string }>()
);

export type QcTasksAction = ReturnType<| typeof dismissPlateQCFromList
  | typeof dismissPlateQCFromPage
  | typeof recoverPlateQCFromList
  | typeof recoverPlateQCFromPage
  | typeof updatePlateQCInListSuccess
  | typeof updatePlateQCSuccess
  | typeof dismissPlateQCError
  | typeof recoverplateQCError
  | typeof decrementPlateQCTodoCount
  | typeof incrementPlateQCTodoCount>;
