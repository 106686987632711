import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodoCardComponent } from './components/todo-card/todo-card.component';
import { TodoPageComponent } from './components/todo-page.component';
import { MaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { TodoCountersBoxesComponent } from './components/todo-counters-boxes.component';
import { TodoIndexAssignmentComponent } from './components/todo-index-assignment.component';
import { TodoLaneAssignmentComponent } from './components/todo-lane-assignment.component';
import { TodoSelectCardComponent } from './components/todo-select-card/todo-select-card.component';
import { TableModule } from '../table/table.module';
import { LabWareSharedModule } from '../labware/shared/lab-ware-shared.module';
import { TodoEchoPoolingComponent } from './components/todo-echo-pooling.component';
import { TodoQcResultComponent } from './components/todo-qc-result.component';
import { QcModule } from '../qc/qc.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    TodoCardComponent,
    TodoPageComponent,
    TodoCountersBoxesComponent,
    TodoIndexAssignmentComponent,
    TodoLaneAssignmentComponent,
    TodoSelectCardComponent,
    TodoEchoPoolingComponent,
    TodoQcResultComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TableModule,
    SharedModule,
    LabWareSharedModule,
    QcModule
  ]
})
export class TodoPageModule {
}
