import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TaskApiService } from '../services/task-api.service';
import {
  assignIndexes,
  completeIndexAssignment,
  completeIndexAssignmentError,
  completeIndexAssignmentSuccess,
  completePlateQCAssignment,
  completePlateQCAssignmentError,
  completePlateQCAssignmentSuccess,
  findTaskByRequest,
  findTaskByRequestError,
  findTaskByRequestSuccess,
  reArray,
  reArrayError,
  unassignIndexes,
  unassignPlateQCError,
  unassignPlateQCFromInstrumentDirectoryList,
  unassignPlateQCFromInstrumentDirectoryPage,
  unassignPlateQCSuccess,
  undoReArray,
  undoReArrayError,
  validatePlateQCAssignment,
  validatePlateQCAssignmentError,
  validatePlateQCAssignmentInvalid,
  validatePlateQCAssignmentSuccess
} from './task.action';
import { SampleService } from '../../bio/services/sample.service';
import {
  clearPlateSelectionFromTaskApi,
  findPlateByAccessionCodeThenSelectFromTaskApi
} from '../../labware/store/plates';
import { findAllIndexKitsFromTaskApi, findIndexKitByIdThenSelectFromTaskApi } from '../../index/store';
import { EVENT_LISTENER_ID_INDEXES, EVENT_LISTENER_ID_SAMPLES } from '../index-assignment/constants';
import { RearrayApiService } from '../re-array/services/rearray-api.service';
import { RE_ARRAY_DESTINATION_PLATE_ID } from '../re-array/components/re-array-left-zone.component';
import { PlateService } from '../../labware/services/plate.service';
import { Router } from '@angular/router';
import { navigateToPlate } from '../../labware/store/plates/actions/plates-api.action';
import { processEchoPoolingSuccess, processReArraySuccess } from '../../labware/store/plates/actions/plate-list.action';
import { PlateQcAssignmentService } from '../plate-qc-assignment/services/plate-qc-assignment.service';

@Injectable()
export class TaskEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private taskApiService: TaskApiService,
    private plateQcAssignmentService: PlateQcAssignmentService,
    private sampleService: SampleService,
    private reArrayApiService: RearrayApiService,
    private plateService: PlateService
  ) {
  }

  
  findByRequest = createEffect(() => { return this.actions$.pipe(
    ofType(findTaskByRequest.type),
    mergeMap((action: ReturnType<typeof findTaskByRequest>) =>
      this.taskApiService.findByRequest(action.requestAccessionCode)
    ),
    map(tasks => findTaskByRequestSuccess({tasks})),
    catchError(error => of(findTaskByRequestError({message: error.message}))),
  ) });


  
  assignIndexes = createEffect(() => { return this.actions$.pipe(
    ofType(assignIndexes.type),
    mergeMap((action: ReturnType<typeof assignIndexes>) =>
      this.sampleService.assignIndexes(action.indexAssignments).pipe(
        switchMap(() => [
            findPlateByAccessionCodeThenSelectFromTaskApi({
              accessionCode: action.accessionCode,
              putPlateIdentifier: EVENT_LISTENER_ID_SAMPLES
            }),
            findIndexKitByIdThenSelectFromTaskApi({id: action.indexKitId}),
            findAllIndexKitsFromTaskApi({pageSize: null, page: null}),
            clearPlateSelectionFromTaskApi({plateAccessionCode: EVENT_LISTENER_ID_SAMPLES}),
            clearPlateSelectionFromTaskApi({plateAccessionCode: EVENT_LISTENER_ID_INDEXES})
          ]
        ),
        catchError(error => of(findTaskByRequestError({message: error.message}))),
      )
    )
  ) });

  
  unassignIndexes = createEffect(() => { return this.actions$.pipe(
    ofType(unassignIndexes.type),
    mergeMap((action: ReturnType<typeof unassignIndexes>) =>
      this.sampleService.unassignIndexes(action.indexAssignments).pipe(
        switchMap(() => {
          if (action.indexKitId) {
            return [
              findAllIndexKitsFromTaskApi({pageSize: null, page: null}),
              findPlateByAccessionCodeThenSelectFromTaskApi({
                accessionCode: action.accessionCode,
                putPlateIdentifier: EVENT_LISTENER_ID_SAMPLES
              }),
              findIndexKitByIdThenSelectFromTaskApi({id: action.indexKitId})
            ];
          } else {
            return [
              findAllIndexKitsFromTaskApi({pageSize: null, page: null}),
              findPlateByAccessionCodeThenSelectFromTaskApi({
                accessionCode: action.accessionCode,
                putPlateIdentifier: EVENT_LISTENER_ID_SAMPLES
              })];
          }
        }),
        catchError(error => of(findTaskByRequestError({message: error.message}))),
      )
    ),
  ) });

  
  reArray = createEffect(() => { return this.actions$.pipe(
    ofType(reArray.type),
    mergeMap((action: ReturnType<typeof reArray>) =>
      this.reArrayApiService.reArray(action.plateAccessionCode, action.reArrayDtos).pipe(
        map(() => (
            findPlateByAccessionCodeThenSelectFromTaskApi({
              accessionCode: action.plateAccessionCode,
              putPlateIdentifier: RE_ARRAY_DESTINATION_PLATE_ID
            })
          )
        ),
        catchError(error => of(reArrayError({message: error.message})))
      )
    )
  ) });

  
  undoReArray = createEffect(() => { return this.actions$.pipe(
    ofType(undoReArray.type),
    mergeMap((action: ReturnType<typeof undoReArray>) =>
      this.reArrayApiService.undoReArray(action.plateAccessionCode, action.plateCoordinates).pipe(
        map(() => (
            findPlateByAccessionCodeThenSelectFromTaskApi({
              accessionCode: action.plateAccessionCode,
              putPlateIdentifier: RE_ARRAY_DESTINATION_PLATE_ID
            })
          )
        ),
        catchError(error => of(undoReArrayError({message: error.message})))
      )
    )
  ) });

  
  completeIndexAssignment = createEffect(() => { return this.actions$.pipe(
    ofType(completeIndexAssignment.type),
    mergeMap((action: ReturnType<typeof completeIndexAssignment>) =>
      this.plateService.completeIndexAssignment(action.accessionCode).pipe(
        map(newPlateAccessionCode => (
            completeIndexAssignmentSuccess({
              accessionCode: newPlateAccessionCode.accessionCode
            })
          )
        )
      )),
    catchError(error => of(completeIndexAssignmentError({message: error.message})))
  ) });

  /* QC assignment */
  
  validatePlateQCAssignment$ = createEffect(() => { return this.actions$.pipe(
    ofType(validatePlateQCAssignment.type),
    mergeMap((action: ReturnType<typeof validatePlateQCAssignment>) =>
      this.plateQcAssignmentService.validateAssignment(action.plateAccessionCode, action.instrumentDirectoryAccessionCode)
    ),
    map(conflict => {
      if (conflict === null) {
        return validatePlateQCAssignmentSuccess();
      }
      return validatePlateQCAssignmentInvalid({error: conflict});
    }),
    catchError(error => of(validatePlateQCAssignmentError({message: error.message}))),
  ) });

  
  completePlateQCAssignment$ = createEffect(() => { return this.actions$.pipe(
    ofType(completePlateQCAssignment.type),
    mergeMap((action: ReturnType<typeof completePlateQCAssignment>) =>
      this.plateQcAssignmentService.completeAssignment(action.plateAccessionCode, action.instrumentDirectoryAccessionCode)
        .pipe(
          map(() => completePlateQCAssignmentSuccess({accessionCode: action.plateAccessionCode}))
        )
    ),
    catchError(error => of(completePlateQCAssignmentError({message: error.message})))
  ) });

  
  unassignPlateQCFromInstrumentDirectory$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      unassignPlateQCFromInstrumentDirectoryPage.type,
      unassignPlateQCFromInstrumentDirectoryList.type
    ),
    mergeMap((action: ReturnType<typeof unassignPlateQCFromInstrumentDirectoryPage>) =>
      this.plateQcAssignmentService.unassign(action.plateAccessionCode, action.instrumentDirectoryAccessionCode)
        .pipe(
          map(() => unassignPlateQCSuccess({accessionCode: action.plateAccessionCode}))
        )
    ),
    catchError(error => of(unassignPlateQCError({message: error.message})))
  ) });

  /* Common */

  
  redirectToPlateAfterSuccess = createEffect(() => { return this.actions$.pipe(
    ofType(
      completeIndexAssignmentSuccess.type,
      processReArraySuccess.type,
      processEchoPoolingSuccess.type,
      completePlateQCAssignmentSuccess.type,
      unassignPlateQCSuccess.type
    ),
    tap((action: ReturnType<typeof navigateToPlate>) => {
        this.router.navigate(['/plate/' + action.accessionCode]);
      }
    )
  ) }, {dispatch: false});
}
