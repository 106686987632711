import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GraphService } from '../services/graph.service';
import {
  findGraphByEntityAccessionCodeError,
  findGraphByEntityAccessionCodeFromPage,
  findGraphByEntityAccessionCodeSuccess
} from './graph.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class GraphEffects {
  constructor(
    private actions$: Actions,
    private graphService: GraphService
  ) {
  }

  findGraphQuery$ = createEffect(() => { return this.actions$.pipe(
    ofType(findGraphByEntityAccessionCodeFromPage.type),
    mergeMap((action: ReturnType<typeof findGraphByEntityAccessionCodeFromPage>) => {
        const {accessionCode} = action;
        return this.graphService.findByAccessionCode(accessionCode).pipe(
          map((graph) => findGraphByEntityAccessionCodeSuccess({accessionCode, graph}))
        );
      }
    ),
    catchError((err) => of(findGraphByEntityAccessionCodeError({message: err.message})))
    ) }
  );
}
