import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexAssignerComponent } from './index-assignment/components/index-assigner/index-assigner.component';
import { MaterialModule } from '../material.module';
import { RequestIndexAssignmentComponent } from './index-assignment/components/index-assignment-step/request-index-assignment.component';
import { IndexAssignmentTableComponent } from './index-assignment/components/index-assignment-table/index-assignment-table.component';
import { SharedModule } from '../shared/shared.module';
import { LaneAssignerComponent } from './lane-assignment/components/lane-assigner/lane-assigner.component';
import { BioModule } from '../bio/bio.module';
import { LaneAssignerPageComponent } from './lane-assignment/components/lane-assigner-page.component';
import { IndexAssignerPageComponent } from './index-assignment/components/index-assigner-page/index-assigner-page.component';
import { RouterModule } from '@angular/router';
import { LaneAssignmentConflictViewerComponent } from './lane-assignment/components/lane-assignment-conflict-viewer.component';
import {
  IndexAssignmentExportCsvComponent
} from './index-assignment/components/index-assignment-export-csv/index-assignment-export-csv.component';
import { TableModule } from '../table/table.module';
import { LaneAssignerCompleteButtonComponent } from './lane-assignment/components/lane-assigner-complete-button.component';
import { LaneAssignerHeaderComponent } from './lane-assignment/components/lane-assigner-header.component';
import { LaneAssignerSaveButtonComponent } from './lane-assignment/components/lane-assigner-save-button.component';
import { LaneAssignerContainerComponent } from './lane-assignment/components/lane-assigner-container.component';
import { IndexAssignerLeftZoneComponent } from './index-assignment/components/index-assigner-left-zone.component';
import { IndexAssignerMiddleZoneComponent } from './index-assignment/components/index-assigner-middle-zone.component';
import { ResetIndexAssignmentButtonsComponent } from './index-assignment/components/reset-index-assignment-buttons.component';
import { IndexAssignmentButtonComponent } from './index-assignment/components/index-assignment-button.component';
import { IndexAssignerRightZoneComponent } from './index-assignment/components/index-assigner-right-zone.component';
import { IndexAssignerTitleComponent } from './index-assignment/components/index-assigner-title.component';
import { EditOccupancyConcentrationDialogComponent } from './lane-assignment/components/edit-occupancy-concentration-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReArrayPageComponent } from './re-array/components/re-array-page.component';
import { ReArrayLeftZoneComponent } from './re-array/components/re-array-left-zone.component';
import { ReArrayMiddleZoneComponent } from './re-array/components/re-array-middle-zone.component';
import { ReArrayRightZoneComponent } from './re-array/components/re-array-right-zone.component';
import { ReArrayTitleComponent } from './re-array/components/re-array-title.component';
import { EchoPoolingPageComponent } from './echo-pooling/components/echo-pooling-page.component';
import { EchoPoolingTitleComponent } from './echo-pooling/components/echo-pooling-title.component';
import { EchoPoolingRightZoneComponent } from './echo-pooling/components/echo-pooling-right-zone.component';
import { EchoPoolingLeftZoneComponent } from './echo-pooling/components/echo-pooling-left-zone.component';
import { EchoPoolingTableComponent } from './echo-pooling/components/echo-pooling-table/echo-pooling-table.component';
import { TasksSharedModule } from './shared/tasks-shared.module';
import { EchoPoolingMiddleZoneComponent } from './echo-pooling/components/echo-pooling-middle-zone.component';
import { EchoPoolingProcessedPageComponent } from './echo-pooling/components/echo-pooling-processed-page.component';
import { EchoPoolingGlobalExportComponent } from './echo-pooling/components/echo-pooling-global-export.component';
import { EchoPoolingExportForEchoComponent } from './echo-pooling/components/echo-pooling-export-for-echo.component';
import { LaneAssignmentConflictItemComponent } from './lane-assignment/components/lane-assignment-conflict-item.component';
import {
  PlateQcAssignerPageComponent
} from './plate-qc-assignment/components/plate-qc-assigner-page/plate-qc-assigner-page.component';
import { QcModule } from '../qc/qc.module';
import { PlateQCAssignmentErrorComponent } from './plate-qc-assignment/components/plate-qc-assignment-error';
import { UserSharedModule } from '../user/shared/user-shared.module';
import { RequestSharedModule } from '../request-shared/request-shared.module';
import {
  PlateQcAssignerPageUIComponent
} from './plate-qc-assignment/components/plate-qc-assigner-page/plate-qc-assigner-page-ui.component';
import { EditGlobalConcentrationDialogComponent } from './lane-assignment/components/edit-global-concentration-dialog.component';
import { EditFlowCellTypeComponent } from './lane-assignment/components/edit-flow-cell-type.component';
import { EditFlowCellLoadingTypeComponent } from './lane-assignment/components/edit-flow-cell-loading-type.component';

@NgModule({
  declarations: [
    RequestIndexAssignmentComponent,
    IndexAssignmentTableComponent,
    IndexAssignerComponent,
    LaneAssignerComponent,
    LaneAssignerPageComponent,
    LaneAssignerContainerComponent,
    IndexAssignerPageComponent,
    LaneAssignmentConflictViewerComponent,
    IndexAssignmentExportCsvComponent,
    LaneAssignerCompleteButtonComponent,
    LaneAssignerHeaderComponent,
    LaneAssignerHeaderComponent,
    LaneAssignerSaveButtonComponent,
    IndexAssignerLeftZoneComponent,
    IndexAssignerMiddleZoneComponent,
    ResetIndexAssignmentButtonsComponent,
    IndexAssignmentButtonComponent,
    IndexAssignerRightZoneComponent,
    IndexAssignerRightZoneComponent,
    IndexAssignerRightZoneComponent,
    IndexAssignerTitleComponent,
    EditOccupancyConcentrationDialogComponent,
    EditGlobalConcentrationDialogComponent,
    EditFlowCellTypeComponent,
    EditFlowCellLoadingTypeComponent,
    ReArrayPageComponent,
    ReArrayLeftZoneComponent,
    ReArrayMiddleZoneComponent,
    ReArrayRightZoneComponent,
    ReArrayTitleComponent,
    EchoPoolingPageComponent,
    EchoPoolingTitleComponent,
    EchoPoolingRightZoneComponent,
    EchoPoolingLeftZoneComponent,
    EchoPoolingTableComponent,
    EchoPoolingMiddleZoneComponent,
    EchoPoolingProcessedPageComponent,
    EchoPoolingGlobalExportComponent,
    EchoPoolingExportForEchoComponent,
    LaneAssignmentConflictItemComponent,
    PlateQcAssignerPageComponent,
    PlateQcAssignerPageUIComponent,
    PlateQCAssignmentErrorComponent
  ],
  exports: [
    IndexAssignerComponent,
    RequestIndexAssignmentComponent,
    LaneAssignerComponent,
    LaneAssignerContainerComponent,
    LaneAssignerCompleteButtonComponent,
    IndexAssignmentTableComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    BioModule,
    RouterModule,
    UserSharedModule,
    TableModule,
    ReactiveFormsModule,
    TasksSharedModule,
    QcModule,
    RequestSharedModule
  ],
})
export class TasksModule {
}
