import { Component, Input, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { Plate96 } from '../../../../labware/models/plate-96';
import { AppState } from '../../../../store/app.reducers';
import { BioMaterial } from '../../../../bio/models/bio-material';
import { NRequest } from '../../../../genomics-request/models/genomics-request';
import { map } from 'rxjs/operators';
import { getGenomicsRequestDictionary } from '../../../../genomics-request/store';

@Component({
  selector: 'nemo-request-index-assignment-table',
  templateUrl: './index-assignment-table.component.html',
  styleUrls: ['./index-assignment-table.component.scss'],
})
export class IndexAssignmentTableComponent implements OnInit {

  @Input() plate: Plate96<BioMaterial>;
  requestList$: Observable<NRequest[]>;

  constructor(
    private readonly store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.requestList$ =
      this.store.select(getGenomicsRequestDictionary).pipe(
        
        map(reqDict => {
          const plateRequestAC = _.chain(this.plate.getNonEmptyBioMaterialMappings())
            .map('biomaterial.requestAccessionCode')
            .uniqBy()
            .orderBy()
            .value();
          const matchedAC = _.chain(reqDict)
            .keys()
            .intersection(plateRequestAC)
            .value();
          const gotThemAll = matchedAC.length === plateRequestAC.length;
          if (gotThemAll) {
            return plateRequestAC.map(ac => reqDict[ac]);
          }
          return [];
        })
      );
  }
}
