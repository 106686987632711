import { Component, Input } from '@angular/core';
import { NRequest } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-samples',
  template: `
    <ng-container *ngIf="request?.tubes.length > 0; else noTubes">
      <h3>{{ request.tubes.length }} tube(s)</h3>
      <nemo-tubes-sample-table [tubes]="request.tubes"></nemo-tubes-sample-table>
    </ng-container>
    <ng-template #noTubes>
      <h3>Samples</h3>
      <div class="sample-containers">
        <nemo-request-sample-containers [requestAccessionCode]="request?.accessionCode"
                                        [containers]="request"
                                        [isLaber]="isLaber"
        ></nemo-request-sample-containers>
      </div>
    </ng-template>
  `,
  styles: [`
    div.sample-containers {
      display: flex;
      flex-direction: row;
    }
  `]
})
export class RequestSamplesComponent {
  @Input() request: NRequest;
  @Input() isLaber: boolean;
}
