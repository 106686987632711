import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FlexibleColumnDef } from '../../model/flexible-columm-def';
import { FlexibleExportGroupDef } from '../../model/flexible-export-group-def';
import * as _ from 'lodash';
import { CsvData, CsvWriterService } from '../../../core/services/csv-writer.service';
import { FlexibleTableContent } from '../../model/flexible-table-content';

class ExportSubset<T> {
  constructor(
    public readonly key: string,
    public readonly groupDef: FlexibleExportGroupDef<T>
  ) {
  }
}

// if some cell contain array of value, we must exploded the one entry as a multi line

@Component({
  selector: 'nemo-flexible-export-csv',
  template: `
    <mat-form-field>
      <mat-label>Export to CSV</mat-label>
      <mat-select>
        <mat-option *ngFor="let subset of exportSubsets"
                    (click)="exportToCsv(subset)">
          {{subset.groupDef.menuDisplay(subset.key)}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: []
})
export class FlexibleExportCsvComponent<T> implements OnChanges {
  @Input()
  columnDefinitions: FlexibleColumnDef<T>[];

  @Input()
  exportGroupDefinitions: FlexibleExportGroupDef<T>[];

  @Input()
  values: T[];

  exportSubsets: ExportSubset<T>[];

  content: FlexibleTableContent<T>;

  /**
   * some fields can contains arrays, some other simple scale
   * If we have array values, they shall be spread on individual lines, while the scalar values shall be repeated
   * In fact, the data passed to the function ar e the displayed ones, therefore the arrays are string with \n
   */
  static flatMapDataRow = (
    headers: { [key: string]: string },
    dataDisplayed: { [key: string]: any }[]
  ) => _.flatMap(dataDisplayed, d => {
    const arrayFields = {};
    _.each(headers, (undefined, k) => {
      // TODO d[k].display might be null (no data in that cell, maybe take care of that earlier
      if (Boolean(d[k].display) && d[k].display.toString().indexOf('\n') > 0) {
        arrayFields[k] = true;
      }
    });
    if (_.size(arrayFields) === 0) {
      const ret = {};
      _.each(headers, (header, k) => ret[header] = d[k].display);
      return [ret];
    } else {
      const nbValMax = _.chain(arrayFields).keys().map((k) => d[k].display.split(/\n/).length).max().value();
      return _.map(_.range(nbValMax), (i) => {
          const ret = {};
          _.each(headers, (header, k) => ret[header] = arrayFields[k] ? d[k].display.split(/\n/)[i] : d[k].display);
          return ret;
        }
      );
    }
  })

  constructor(
    private readonly csvWriter: CsvWriterService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.values) {
      return;
    }
    this.buildExportSubsets();
    this.content = new FlexibleTableContent(
      null,
      this.columnDefinitions,
      this.values)
    ;
  }

  buildExportSubsets() {
    this.exportSubsets = _.flatMap(this.exportGroupDefinitions,
      (groupDef: FlexibleExportGroupDef<T>) => {
        return _.chain(this.values)
          .flatMap((v) => groupDef.extractGroupKeys(v))
          .uniq()
          .sortBy()
          .map((key: string) => new ExportSubset(key, groupDef))
          .value();
      }
    );
  }

  exportToCsv(subset: ExportSubset<T>) {
    const filename = subset.groupDef.filename(subset.key);
    const subsetData = _.filter(this.content.displayedValues,
      (d) => subset.groupDef.filterBy(d._orig, subset.key));
    const data = FlexibleExportCsvComponent.flatMapDataRow(
      this.content.displayedHeaders,
      subsetData);

    const csvData: CsvData = {data};
    this.csvWriter.downloadAsCsv(csvData, filename);
  }
}
