import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { LoginComponent } from './login/login.component';
import { LoginButtonComponent } from './login-button/login-button.component';
import { LogoutButtonComponent } from './logout-button/logout-button.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';


@NgModule({
  declarations: [
    LoginComponent,
    LoginButtonComponent,
    LogoutButtonComponent,
    UnauthorizedComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    LoginComponent,
    LoginButtonComponent,
    LogoutButtonComponent,
  ],
})
export class LoginModule {
}
