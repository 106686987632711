import { createAction, props } from '@ngrx/store';
import { FormRequest, FormRequestSummary } from '../../models/form-request';
import { FormRequestEditionDto } from '../../dtos/form-request.dto';
import { Lab } from '../../../company/models/Lab';
import { User } from '../../../user/models/user.model';

export const actionSubmitFormRequest = createAction(
  '[Submission form] submit formRequest',
  props<{ formRequestEdition: FormRequestEditionDto }>()
);

export const actionSubmitFormRequestSuccess = createAction(
  '[API] submit formRequest success',
  props<{ formRequest: FormRequest }>()
);

export const actionModifyFormRequest = createAction(
  '[Acceptance form] modify formRequest',
  props<{ formRequestEdition: FormRequestEditionDto }>()
);

export const actionModifyFormRequestSuccess = createAction(
  '[API] modify formRequest success',
  props<{ formRequest: FormRequest }>()
);

export const actionFindFormRequestByAccessionCodeFromRequestPage = createAction(
  '[Form Request Page] find formRequest by accession code',
  props<{ accessionCode: string }>()
);

export const actionFindFormRequestByAccessionCodeSuccess = createAction(
  '[API] load formRequest success',
  props<{ formRequest: FormRequest }>()
);

export const actionFindAllFormRequests = createAction(
  '[FormRequest] find all form requests'
);

export const actionFindAllFormRequestsByRequester = createAction(
  '[FormRequest] find all form requests by requester',
  props<{ lab: Lab, requester: User, hideArchived: boolean }>()
);

export const actionFindAllFormRequestsByAssignedTo = createAction(
  '[FormRequest] find all form requests by assignedTo',
  props<{ lab: Lab, assignedTo: User, hideArchived: boolean }>()
);

export const actionSearchAllFormRequests = createAction(
  '[FormRequest] search form requests',
  props<{ requesterId?: string, assignedTo?: string, q?: string }>()
);

export const actionFindAllFormRequestsSuccess = createAction(
  '[FormRequest API] find all success',
  props<{ formRequests: FormRequestSummary[] }>()
);

export const actionFindAllFormRequestsByRequesterSuccess = createAction(
  '[FormRequest API] find all by requester success',
  props<{ formRequests: FormRequestSummary[] }>()
);

export const actionFindAllFormRequestsByAssignedToSuccess = createAction(
  '[FormRequest API] find all by assignedTo success',
  props<{ formRequests: FormRequestSummary[] }>()
);

export const actionFindAllFormRequestsError = createAction(
  '[FormRequest API] find all error',
  props<{ message: string }>()
);

export const actionCompleteFormRequestStage = createAction(
  '[FormRequest Page] complete stage',
  props<{ accessionCode: string, stageKey: string }>()
);

export const actionCompleteFormRequestStageSuccess = createAction(
  '[FormRequest API] complete stage success',
  props<{ formRequest: FormRequest }>()
);

export const actionReopenFormRequestStage = createAction(
  '[FormRequest Page] reopen stage stage',
  props<{ accessionCode: string, stageKey: string }>()
);

export const actionReopenFormRequestStageSuccess = createAction(
  '[FormRequest API] reopen stage success',
  props<{ formRequest: FormRequest }>()
);

export const archiveFormRequestFromList = createAction(
  '[Form Request List] archive request',
  props<{ accessionCode: string }>()
);

export const restoreFormRequestFromList = createAction(
  '[Form Request List] restore request',
  props<{ accessionCode: string }>()
);


export const archiveFormRequestFromListSuccess = createAction(
  '[Form Request API] archive request from list success',
  props<{ accessionCode: string }>()
);

export const restoreFormRequestFromListSuccess = createAction(
  '[Form Request API] restore request from list success',
  props<{ accessionCode: string }>()
);
