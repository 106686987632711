import { Component, Input } from '@angular/core';
import { SimpleRequest } from '../../genomics-request/models/genomics-request';
import { formatAccessionCode } from '../../shared/pipes/accession-code.pipe';
import * as moment from 'moment';
import { RequestSnippet } from '../../labware/dto/labware.dto';

export function requestCardsHtml(requests: RequestSnippet[]): string {
  const innerDivs = requests.map((request) =>
    `
  <div>
    <a href="/request/${request.accessionCode}">
      <span style="font-family: monospace">${formatAccessionCode(request.accessionCode)}</span></a>
    </a>
  [${request.summary}]
    on ${moment(request.createdAt).format('YY/MM/DD')} by ${request.requesterName}
  </div>
  `
  );
  return `<div>${innerDivs.join('')}</div>`;
}

@Component({
  selector: 'nemo-request-card',
  template: `
    <div routerLink="/request/{{request.accessionCode}}">
      <div class="request-card">
        <div class="header">
          <div class="accession-code">{{request.accessionCode | accessionCode}}</div>
          <div class="date">{{request.createdAt | date : "short"}}</div>

          <div class="library">
            {{request.libraryMethod}}
          </div>
          <nemo-phi-x class="phi-x" [request]="request"></nemo-phi-x>
          <div class="user">
            <nemo-user-summary [userId]="request.requesterId"></nemo-user-summary>
          </div>
        </div>
        <div>
          {{request.title}}
        </div>
      </div>
    </div>
  `,
  styles: [`
             div.request-card {
               margin-bottom: 10px;
               color: #5f6368;
             }

             div.header {
               display: flex;
               flex-direction: row;
               align-items: center;
             }

             div.header div {
               margin-right: 1em;
             }

             .request-card:hover {
               color: #1967d2;
               background-color: #e8f0fe;
               cursor: pointer;
             }

             .request-card p {
               font-size: 13px;
               font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
             }

             div.title {
               font-style: italic;
             }
           `]
})
export class RequestCardComponent {
  @Input() request: SimpleRequest;
}
