import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JSON_CONTENT } from '../../constants/http';
import { environment } from '../../../environments/environment';
import { IndexAssignmentDto } from '../../index/models/assigned-index.dto';

export const SAMPLE_BASE_URI = environment.apiUrl + '/samples';

@Injectable({
  providedIn: 'root',
})
export class SampleService {

  constructor(private readonly http: HttpClient) {
  }

  assignIndexes(indexAssignmentDtos: IndexAssignmentDto[]) {
    return this.http.post(SAMPLE_BASE_URI + '/assignIndexes', indexAssignmentDtos, {headers: JSON_CONTENT});
  }

  unassignIndexes(indexAssignmentDtos: IndexAssignmentDto[]) {
    return this.http.post(SAMPLE_BASE_URI + '/unassignIndexes', indexAssignmentDtos, {headers: JSON_CONTENT});
  }
}
