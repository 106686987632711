import { User } from '../../user/models/user.model';

export class Task {
  constructor(
    public readonly action: TaskType,
    public readonly requestsAccessionCode: string[],
    public readonly payload: any,
    public readonly date: Date,
    public readonly user: User
  ) {
  }
}

export enum TaskType {
  ACCEPT_REQUEST = 'accepted',
  ASSIGN_QC = 'QC assigned',
  COMPLETE_REQUEST = 'completed',
  DRAFT_REQUEST = 'drafted',
  ECHO_POOLING = 'processed echo pooling to',
  FLOW_CELL_TO_DRAFT = 'reopened flow cell',
  INDEX_ASSIGNMENT = 'indexed',
  LANE_ASSIGNMENT = 'assigned lanes',
  QC_DIRECTORY_DISMISS = 'QC directory dismiss',
  QC_DIRECTORY_RECOVER = 'QC directory recover',
  RE_ARRAY = 're arrayed',
  REOPEN_REQUEST = 'reopened',
  SEQUENCING = 'sequenced',
  UNASSIGN_QC = 'QC unassigned',
  FLOW_CELL_TO_NOTIFIED = 'notify requester'
}

export function isStage(task: TaskType): boolean {
  const requestTasks = [
    'ACCEPT_REQUEST',
    'DRAFT_REQUEST',
    'COMPLETE_REQUEST',
    'REOPEN_REQUEST'
  ];
  return requestTasks.indexOf(task) > -1;
}

export enum TaskAvailable {
  RE_ARRAY_FROM = 'RE_ARRAY_FROM',
  RE_ARRAY_TO = 'RE_ARRAY_TO',
  INDEX_ASSIGNMENT = 'INDEX_ASSIGNMENT',
  LANE_ASSIGNMENT = 'LANE_ASSIGNMENT',
  ECHO_POOLING_FROM = 'ECHO_POOLING_FROM',
  ECHO_POOLING_TO = 'ECHO_POOLING_TO',
  ECHO_POOLING_READ = 'ECHO_POOLING_READ',
  QC_PLATE_ASSIGN_TO_FILE = 'QC_PLATE_ASSIGN_TO_FILE',
  QC_PLATE_UNASSIGN_FROM_FILE = 'QC_PLATE_UNASSIGN_FROM_FILE',
  QC_PLATE_DISMISS = 'QC_PLATE_DISMISS',
  QC_PLATE_RECOVER = 'QC_PLATE_RECOVER',
  QC_PLATE_REIMPORT = 'QC_PLATE_REIMPORT',
  QC_PLATE_ASSIGN_DIRECTORY_TO = 'QC_PLATE_ASSIGN_DIRECTORY_TO'
}
