<button
  *ngIf="isUniqueRequest"
  mat-raised-button
  (click)="exportFullToCsv()"
  color="basic"
>
  Export full Plate {{plate.accessionCode | accessionCode}} to CSV
</button>

<button
  *ngFor="let request of requests"
  mat-raised-button
  (click)="exportRequestToCsv(request)"
  color="basic"
>
  Export {{request.accessionCode|accessionCode}} to CSV
</button>
