import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import * as fromRequest from '../../store';
import { changeShowDetail, findAllRequests, getRequests, searchAllRequests } from '../../store';
import { ActivatedRoute } from '@angular/router';
import { NRequestSummary } from '../../models/genomics-request';

@Component({
  selector: 'nemo-request-search-result',
  templateUrl: './request-search-result.component.html',
  styleUrls: ['./request-search-result.component.scss']
})
export class RequestSearchResultComponent implements OnInit, OnDestroy {

  paramSubscription: Subscription;
  showDetailSubscription: Subscription;

  showDetailedTable: Boolean;
  genomicsRequests$: Observable<NRequestSummary[]>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.genomicsRequests$ = this.store.select((getRequests));

    this.showDetailSubscription = this.store
      .select((fromRequest.getShowDetail))
      .subscribe(showDetail => {
        this.showDetailedTable = showDetail;
      });
    this.paramSubscription = this.route.params.subscribe(params => {
      this.store.dispatch(searchAllRequests({
        requesterId: params['requester'],
        assignedTo: params['assigned_to'],
        q: params['q']
      }));
    });
  }

  changeShowDetail() {
    this.store.dispatch(changeShowDetail());
  }

  ngOnDestroy(): void {
    this.showDetailSubscription.unsubscribe();
    this.paramSubscription.unsubscribe();
  }
}
