import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { FormRequestDto, FormRequestEditionDto, FormRequestSummaryDto } from '../dtos/form-request.dto';
import { FormRequest, FormRequestSummary } from '../models/form-request';
import { dtoToFormRequest, dtoToFormRequestSummary } from './form-request.mapper';
import { FormRequestListFilter } from '../models/form-request-list-filter';
import { Page } from '../../shared/models/page.model';
import { User } from '../../user/models/user.model';

export const FORM_REQUEST_BASE_URI = environment.apiUrl + '/form-requests';

@Injectable({
  providedIn: 'root'
})
export class FormRequestService {
  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  create(formRequestCreation: FormRequestEditionDto): Observable<FormRequest> {
    return this.httpClient.post<FormRequestDto>(FORM_REQUEST_BASE_URI, formRequestCreation).pipe(
      map(dtoToFormRequest)
    );
  }

  modify(formRequestCreation: FormRequestEditionDto): Observable<FormRequest> {
    return this.httpClient.put<FormRequestDto>(
      FORM_REQUEST_BASE_URI, formRequestCreation
    ).pipe(
      map(dtoToFormRequest)
    );
  }

  findByAccessionCode(accessionCode: string): Observable<FormRequest> {
    return this.httpClient.get<FormRequestDto>(`${FORM_REQUEST_BASE_URI}/${accessionCode}`).pipe(
      map(dtoToFormRequest)
    );
  }

  buildFindAllQueryParams(filter: FormRequestListFilter): any {
    const queryParams = {};
    if (filter.lab) {
      queryParams['lab'] = filter.lab.alias;
    }
    if (filter.requester) {
      queryParams['requester'] = filter.requester.id;
    }
    if (filter.assignedTo) {
      queryParams['assigned_to'] = filter.assignedTo.id;
    }
    queryParams['hide_archived'] = filter.hideArchived != null ? filter.hideArchived : false;
    return queryParams;
  }

  findAll(filter: FormRequestListFilter): Observable<FormRequestSummary[]> {
    const queryParams = this.buildFindAllQueryParams(filter);
    return this.httpClient.get<Page<FormRequestSummaryDto>>(FORM_REQUEST_BASE_URI, {params: queryParams}).pipe(
      map(page => page.content.map(dtoToFormRequestSummary))
    );
  }

  find(requesterId?: string, assignedTo?: string, q?: string): Observable<FormRequestSummary[]> {
    if (q && q.length > 0) {
      return this.search(q);
    }
    const queryParams = {};
    if (requesterId) {
      queryParams['requester'] = requesterId;
    }
    if (assignedTo) {
      queryParams['assigned_to'] = assignedTo;
    }
    return this.httpClient.get<Page<FormRequestSummaryDto>>(FORM_REQUEST_BASE_URI, {params: queryParams}).pipe(
      map(page => page.content.map(dtoToFormRequestSummary))
    );
  }

  search(research: string): Observable<FormRequestSummary[]> {
    const uri = FORM_REQUEST_BASE_URI + '?q=' + research;
    return this.httpClient.get<Page<FormRequestSummaryDto>>(uri).pipe(
      map(page => page.content.map(dtoToFormRequestSummary))
    );
  }

  complete(accessionCode: string, stageKey: string): Observable<FormRequest> {
    const queryParams = {};
    queryParams['stageKey'] = stageKey;
    return this.httpClient.post<FormRequestDto>(
      `${FORM_REQUEST_BASE_URI}/${accessionCode}/complete`,
      {},
      {params: queryParams}
    ).pipe(
      map(dtoToFormRequest)
    );
  }

  reopen(accessionCode: string, stageKey: string): Observable<FormRequest> {
    const queryParams = {};
    queryParams['stageKey'] = stageKey;
    return this.httpClient.delete<FormRequestDto>(
      `${FORM_REQUEST_BASE_URI}/${accessionCode}/complete`,
      {params: queryParams}
    ).pipe(
      map(dtoToFormRequest)
    );
  }

  assignUser(accessionCode: string, user: User): Observable<FormRequest> {
    const url = `${FORM_REQUEST_BASE_URI}/${accessionCode}/assign/${user.id}`;
    return this.httpClient.put<FormRequestDto>(url, {}).pipe(
      map(dtoToFormRequest)
    );
  }

  unassignUser(accessionCode: string, user: User): Observable<FormRequest> {
    const url = `${FORM_REQUEST_BASE_URI}/${accessionCode}/assign/${user.id}`;
    return this.httpClient.delete<FormRequestDto>(url).pipe(
      map(dtoToFormRequest)
    );
  }

  archive(accessionCode: string): Observable<void> {
    return this.httpClient.put<void>(FORM_REQUEST_BASE_URI + '/' + accessionCode + '/archive', null);
  }

  restore(accessionCode: string): Observable<void> {
    return this.httpClient.put<void>(FORM_REQUEST_BASE_URI + '/' + accessionCode + '/restore', null);
  }

}
