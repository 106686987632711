import { User } from '../../user/models/user.model';
import { FormValues } from './form-values';
import { Form } from './form';
import { Department } from '../../company/models/Department';
import { Project } from '../../company/models/Project';
import { Study } from '../../company/models/Study';

export class FormSubmission {
  constructor(
    public readonly id: number,
    public readonly form: Form,
    public readonly values: FormValues
  ) {
  }
}

export class SampleCount {
  constructor(
    public readonly total: number,
    public readonly bySection: { [key: string]: number }
  ) {
  }
}

export class FormRequest {
  constructor(
    public readonly accessionCode: string,
    public readonly title: string,
    public readonly description: string,
    public readonly department: Department,
    public readonly project: Project,
    public readonly study: Study,
    public readonly requester: User,
    public readonly createdAt: Date,
    public readonly assignedTo: User[],
    public readonly stages: FormRequestStage[],
    public readonly currentOpenStageKey: string,
    public readonly submission: FormSubmission,
    public readonly archived: boolean
  ) {
  }
}

export class FormRequestStage {
  constructor(
    public readonly key: string,
    public readonly name: string,
    public readonly completedAt?: Date,
    public readonly completedBy?: User,
  ) {
  }
}

export class FormRequestSummary {
  constructor(
    public readonly accessionCode: string,
    public readonly title: string,
    public readonly study: Study,
    public readonly createdAt: Date,
    public readonly requester: User,
    public readonly assignedTo: User[],
    public readonly stages: FormRequestStage[],
    public readonly sampleCount: SampleCount,
    public readonly archived: boolean
  ) {
  }
}
