import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { selectActiveLibraryMethods } from '../../../../sequencing/store';
import { Observable } from 'rxjs';
import { LibraryMethod } from '../../../../sequencing/models/library-method';
import { AppState } from '../../../../store/app.reducers';

@Component({
  selector: 'nemo-index-kit-model-settings',
  template: `
    <div class="container">
      <h3>Add a new index kit model</h3>
      <nemo-index-kit-model-settings-add [libraryMethods]="libraryMethods$ | async"></nemo-index-kit-model-settings-add>
      <br/>
      <h3>Existing index kit models</h3>
      <nemo-index-kit-model-settings-list></nemo-index-kit-model-settings-list>
    </div>
  `,
  styles: [`
    table {
      width: 100%;
    }
  `]
})
export class IndexKitModelSettingsComponent implements OnInit {
  libraryMethods$: Observable<LibraryMethod[]>;

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.libraryMethods$ = this.store.
      select((selectActiveLibraryMethods)
    );
  }

}
