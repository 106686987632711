import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Index } from '../../../../bio/models/barcode-index';
import { Plate96 } from '../../../../labware/models/plate-96';
import { AppState } from '../../../../store/app.reducers';
import {
  selectIndexPlateByIdentifier,
  selectPoolSamplePlateByIdentifier,
  selectRangeSelectionsByIdentifier,
} from '../../../../labware/store/plates';
import { EVENT_LISTENER_ID_INDEXES, EVENT_LISTENER_ID_SAMPLES } from '../../constants';
import { PlateCoordinatesSelection } from '../../../../labware/models/plate-coordinates-selection';
import { PoolSamplePlaceholder } from '../../../../bio/models/pool';

@Component({
  selector: 'nemo-request-index-assignment',
  templateUrl: './request-index-assignment.component.html',
  styleUrls: ['./request-index-assignment.component.scss']
})
export class RequestIndexAssignmentComponent implements OnInit {

  samplePoolPlate$: Observable<Plate96<PoolSamplePlaceholder>>;
  indexPlate$: Observable<Plate96<Index>>;

  currentSelectionSamples$: Observable<PlateCoordinatesSelection>;
  currentSelectionIndexes$: Observable<PlateCoordinatesSelection>;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.samplePoolPlate$ = this.store.select(selectPoolSamplePlateByIdentifier(EVENT_LISTENER_ID_SAMPLES));
    this.indexPlate$ = this.store.select(selectIndexPlateByIdentifier(EVENT_LISTENER_ID_INDEXES));

    this.currentSelectionSamples$ = this.store.select(selectRangeSelectionsByIdentifier(EVENT_LISTENER_ID_SAMPLES));
    this.currentSelectionIndexes$ = this.store.select(selectRangeSelectionsByIdentifier(EVENT_LISTENER_ID_INDEXES));
  }
}
