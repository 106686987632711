import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createLab } from '../../../store/lab.actions';

@Component({
  selector: 'nemo-labs-add',
  template: `
    <div class="container"
         [formGroup]="formGroup"
    >
      <mat-form-field>
        <mat-label>Alias</mat-label>
        <input
          matInput
          formControlName="alias"
          placeholder="no space string, such as 'biochem' etc."
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Displayed lab name, such as 'Biochemistry'"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Home page</mat-label>
        <input
          matInput
          formControlName="homePage"
          placeholder="The intranet group home page"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Where new request emails are delivered"
        >
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!formGroup.valid"
          (click)="submit()"
        >Add
        </button>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               display: flex;
               flex-direction: column;
               gap: 8px;
             }

             mat-form-field {
               width: 40em;
               margin-right: 1em;
             }
           `]
})
export class LabsAddComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      alias: new UntypedFormControl('', [
        Validators.required
      ]),
      name: new UntypedFormControl('', [
        Validators.required
      ]),
      homePage: new UntypedFormControl('', [
        Validators.required
      ]),
      email: new UntypedFormControl('', [
        Validators.required, Validators.email
      ])
    });
  }

  submit() {
    this.store.dispatch(createLab({
      alias: this.formGroup.get('alias').value,
      name: this.formGroup.get('name').value,
      homePage: this.formGroup.get('homePage').value,
      email: this.formGroup.get('email').value
    }));
  }
}
