import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { LibraryMethod } from '../../../../sequencing/models/library-method';
import { GenomicFormRequestService } from '../../../services/genomic-form-request.service';

@Component({
  selector: 'nemo-request-submission-form-info',
  template: `
    <div [formGroup]="formGroup">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Title</mat-label>
        <input matInput #title type="text" formControlName="title"/>
        <mat-hint align="end">{{ title.value.length }} / {{ titleMaxLength }}</mat-hint>
      </mat-form-field>

      <div>
        <h4>Request origin</h4>
        <div class="field-group">
          <nemo-department-select [formGroup]="formGroup"></nemo-department-select>
          <nemo-project-select [formGroup]="formGroup"></nemo-project-select>
          <nemo-study-select [formGroup]="formGroup"></nemo-study-select>
          <nemo-collaborator-select [formGroup]="formGroup"></nemo-collaborator-select>
        </div>
      </div>

      <nemo-request-submission-form-library-method [formGroup]="formGroup"
                                                   [simpleOptionList]="!displaySubmissionTypes"
                                                   [selectedLibraryMethod]="selectedLibraryMethod"
                                                   (selectedLibraryMethodChange)="selectedSubmissionTypeChanged($event)"/>

      <ng-container *ngIf="selectedLibraryMethod">
        <nemo-request-submission-form-ref-transcriptome *ngIf="isRefTranscriptomeSetAndRequired"
                                                        [formGroup]="formGroup"/>

        <nemo-request-submission-form-species *ngIf="isSpeciesSetAndRequired"
                                              [formGroup]="formGroup"/>

        <nemo-request-submission-form-sample-type *ngIf="isSampleTypeSetAndRequired"
                                                  [formGroup]="formGroup"
                                                  [selectedLibraryMethod]="selectedLibraryMethod"/>

        <nemo-request-submission-form-phix *ngIf="isPhixSet"
                                           [formGroup]="formGroup"/>

        <nemo-request-submission-form-demux-parameters *ngIf="isDemuxParametersSet"
                                                       [formGroup]="formGroup"/>

        <!-- Comment -->
        <h4>Description</h4>
        <span class="description">General information on the request, special comments</span>
        <mat-form-field class="full-width" appearance="outline">
          <textarea rows="5" matInput #description type="text" formControlName="description"
                    placeholder="Description"></textarea>
          <mat-hint align="end">{{ description.value.length }} / {{ descriptionMaxLength }}</mat-hint>
        </mat-form-field>

        <div *ngIf="isRestrictedAccessSet">
          <mat-checkbox formControlName="isRestrictedAccessCtl">
            Check this box if the samples are from Donors or Patients to enable restricted access.
          </mat-checkbox>
        </div>

        <div *ngIf="formGroup.get('isRestrictedAccessCtl')?.value" class="notice">
          <p>Notice: Generated data is accessible only to specified Calico accounts.</p>
          <div>
            <label for="needAccessEmails">Please enter the Calico email addresses that require access to the
              data:</label>
            <input id="needAccessEmails"
                   type="text"
                   formControlName="needAccessEmailsCtl"
                   placeholder="Enter email addresses separated by commas"
                   [class.invalid]="formGroup.get('needAccessEmailsCtl')?.invalid && formGroup.get('needAccessEmailsCtl')?.touched"/>
          </div>
          <div *ngIf="formGroup.get('needAccessEmailsCtl')?.hasError('invalidEmails')">
            <small style="color: red;">Invalid
              emails: {{ formGroup.get('needAccessEmailsCtl')?.getError('invalidEmails') }}</small>
          </div>
          <div *ngIf="formGroup.get('needAccessEmailsCtl')?.hasError('required')">
            <small style="color: red;">This field is required.</small>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./request-submission-form-info.component.scss']
})
export class RequestSubmissionFormInfoComponent {
  @Input() formGroup: FormGroup;
  @Input() displaySubmissionTypes: boolean = true;

  titleMaxLength: number;
  descriptionMaxLength: number;

  constructor(
    readonly genomicsRequestService: GenomicFormRequestService,
  ) {
    this.titleMaxLength = genomicsRequestService.titleMaxLength;
    this.descriptionMaxLength = genomicsRequestService.descriptionMaxLength;
  }

  get selectedLibraryMethod(): LibraryMethod | null | undefined {
    return this.formGroup.get('libraryMethod').value;
  }

  get isRefTranscriptomeSetAndRequired(): boolean {
    return this.formGroup.get('refTransCtl') !== null && this.formGroup.get('refTransCtl').hasValidator(Validators.required);
  }

  get isSpeciesSetAndRequired(): boolean {
    return this.formGroup.get('organismCtl') !== null && this.formGroup.get('organismCtl').hasValidator(Validators.required);
  }

  get isSampleTypeSetAndRequired(): boolean {
    return this.formGroup.get('sampleTypeCtl') !== null && this.formGroup.get('sampleTypeCtl').hasValidator(Validators.required);
  }

  get isPhixSet(): boolean {
    return this.formGroup.get('phiXPercentage') !== null;
  }

  get isDemuxParametersSet(): boolean {
    return this.formGroup.get('param10xRead1') !== null;
  }

  get isRestrictedAccessSet(): boolean {
    return this.formGroup.get('isRestrictedAccessCtl') !== null;
  }

  selectedSubmissionTypeChanged(libraryMethod: LibraryMethod | null) {
    this.formGroup.patchValue({
      libraryMethod: libraryMethod,
      param10xRead1: libraryMethod?.read1 ?? 150,
      param10xIndex1: libraryMethod?.index1 ?? 8,
      param10xIndex2: libraryMethod?.index2 ?? 8,
      param10xRead2: libraryMethod?.read2 ?? 150,
      refTransCtl: null,
      organismCtl: null,
      sampleTypeCtl: null,
    });
    this.formGroup.updateValueAndValidity();
  }
}
